import React, { FC } from 'react'
import { Grid } from '@mui/material'
import { Table, DynamicColumn } from 'src/components/ui'
import { Sensor, SensorCurrentData } from 'src/api'
import { SensorLastReadingsColumns } from 'src/components/columns/sensor'
import { ReadingType } from 'src/enums'

const lastReadingColumns: DynamicColumn<Reading>[] = [
  SensorLastReadingsColumns.ReadingName,
  SensorLastReadingsColumns.ReadingValue,
  SensorLastReadingsColumns.ReadingLastPing,
]
interface SensorLastReadingsProps {
  sensor: Sensor;
}
export interface Reading {
  name: string;
  value: string | number | undefined;
  lastPing: string | undefined;
  type: ReadingType;
}
type GetReadingRow = (data: SensorCurrentData) => Reading
interface ReadingRows {
  accelerationRms: GetReadingRow
  accelerationX: GetReadingRow
  accelerationY: GetReadingRow
  accelerationZ: GetReadingRow
  battery: GetReadingRow
  temperature: GetReadingRow
  humidity: GetReadingRow
  locationCellular: GetReadingRow
  locationGps: GetReadingRow
  light: GetReadingRow
  pressure: GetReadingRow
  dewpoint: GetReadingRow
  signalStrength: GetReadingRow
  orientationMotionX: GetReadingRow
  orientationMotionY: GetReadingRow
  orientationMotionZ: GetReadingRow
  orientationTiltZ: GetReadingRow
  firmwareVersion: GetReadingRow
  configurationTime: GetReadingRow
}

const rows: ReadingRows = {
  accelerationRms: (data) => {
    return {
      name: 'Acceleration RMS',
      value: data.accelerationRms,
      lastPing: data.accelerationTime,
      type: ReadingType.Acceleration
    }
  },
  accelerationX: (data) => {
    return {
      name: 'Acceleration X',
      value: data.accelerationX,
      lastPing: data.accelerationTime,
      type: ReadingType.Acceleration
    }
  },
  accelerationY: (data) => {
    return {
      name: 'Acceleration Y',
      value: data.accelerationY,
      lastPing: data.accelerationTime,
      type: ReadingType.Acceleration
    }
  },
  accelerationZ: (data) => {
    return {
      name: 'Acceleration Z',
      value: data.accelerationZ,
      lastPing: data.accelerationTime,
      type: ReadingType.Acceleration
    }
  },
  battery: (data) => {
    return {
      name: 'Battery',
      value: data.batteryLevel ? `${data.batteryLevel}%` : '',
      lastPing: data.batteryLevelTime,
      type: ReadingType.Battery
    }
  },
  dewpoint: (data) => {
    return {
      name: 'Dew Point',
      value: data.dewPointF ? `${data.dewPointF}°F` : '',
      lastPing: data.dewPointTime,
      type: ReadingType.Dewpoint
    }
  },
  humidity: (data) => {
    return {
      name: 'Humidity',
      value: data.humidity ? `${data.humidity}%` : '',
      lastPing: data.humidityTime,
      type: ReadingType.Humidity
    }
  },
  light: (data) => {
    return {
      name: 'Light',
      value: data.light,
      lastPing: data.lightTime,
      type: ReadingType.Light
    }
  },
  locationCellular: (data) => {
    return {
      name: 'Location Cellular',
      value: data.latitudeCellural && data.longitudeCellural ? `${data.latitudeCellural}, ${data.longitudeCellural}` : '',
      lastPing: data.locationUpdatedAt,
      type: ReadingType.LocationCellular
    }
  },
  locationGps: (data) => {
    return {
      name: 'Location GPS',
      value: data.latitudeGps && data.longitudeGps ? `${data.latitudeGps}, ${data.longitudeGps}` : '',
      lastPing: data.locationGpsUpdatedAt,
      type: ReadingType.LocationGps
    }
  },
  orientationMotionX: (data) => {
    return {
      name: 'Orientation Motion X',
      value: data.orientationMotionX,
      lastPing: data.orientationTime,
      type: ReadingType.Orientation
    }
  },
  orientationMotionY: (data) => {
    return {
      name: 'Orientation Motion Y',
      value: data.orientationMotionY,
      lastPing: data.orientationTime,
      type: ReadingType.Orientation
    }
  },
  orientationMotionZ: (data) => {
    return {
      name: 'Orientation Motion Z',
      value: data.orientationMotionZ,
      lastPing: data.orientationTime,
      type: ReadingType.Orientation
    }
  },
  orientationTiltZ: (data) => {
    return {
      name: 'Orientation Tilt Z',
      value: data.orientationTiltZ,
      lastPing: data.orientationTime,
      type: ReadingType.Orientation
    }
  },
  pressure: (data) => {
    return {
      name: 'Pressure',
      value: data.pressure,
      lastPing: data.pressureTime,
      type: ReadingType.Pressure
    }
  },
  signalStrength: (data) => {
    return {
      name: 'Signal Strength',
      value: data.signalStrength,
      lastPing: data.signalStrengthTime,
      type: ReadingType.SignalStrength
    }
  },
  temperature: (data) => {
    return {
      name: 'Temperature',
      value: data.temperatureF ? `${data.temperatureF}°F` : '',
      lastPing: data.temperatureTime,
      type: ReadingType.Temperature
    }
  },
  firmwareVersion: (data) => {
    return {
      name: 'Firmware version',
      value: data.firmwareVersion || '',
      lastPing: data.firmwareVersionTime,
      type: ReadingType.Diagnostic
    }
  },
  configurationTime: (data) => ({
    name: 'Last received configuration',
    value: '-',
    lastPing: data.configurationTime,
    type: ReadingType.Configuration
  }),
}

export const SensorLastReadings: FC<SensorLastReadingsProps> = (props) => {
  const { sensor } = props
  if (!sensor) return null

  const data: Reading[] = Object.values(rows).map((value: GetReadingRow) => value(sensor.currentData as SensorCurrentData || {}))

  return (
    <Grid container spacing={4}>
      <Grid item container>
        <Table
          columns={lastReadingColumns}
          data={data}
          showFooter={false}
          sx={{ width: '100%' }}
        />
      </Grid>
    </Grid>
  )
}

import React, { FC } from 'react'
import { LinkProps } from '@mui/material'
import { StyledOuterLink } from './styledOuterLink'

export const OuterLink: FC<Omit<LinkProps, 'href'> & { to: string }> = ({
  children,
  to,
  onClick = () => {},
  ...rest
}) => {
  return (
    <StyledOuterLink target="_blank" {...rest} href={to} onClick={onClick}>
      {children}
    </StyledOuterLink>
  )
}

import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { Entities } from 'src/enums'
import { ProductImport } from 'src/api'
import { useApiInstance } from './useApiInstance'

interface RequestParams {
  id: number;
  options?: Omit<
    UseQueryOptions<ProductImport, Error>,
    'queryKey' | 'queryFn'
  >;
}

export const useProductImportRequest = ({ id, options }: RequestParams) => {
  const api = useApiInstance()

  return useQuery<ProductImport, Error>(
    [Entities.ProductImport, id],
    async () => {
      const { data } = await api.v1AdminProductsImportHistoryImportHistoryIdGet(id)
      return data as ProductImport
    },
    options,
  )
}

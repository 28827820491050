import { useMutation, UseMutationOptions } from '@tanstack/react-query'
import { Entities } from 'src/enums'
import { SensorConfiguration, SensorDefaultConfiguration } from 'src/api'
import { useApiInstance } from './useApiInstance'

interface RequestParams {
  options?: Omit<
    UseMutationOptions<unknown, Error, SensorConfiguration, SensorDefaultConfiguration>,
    'mutationKey' | 'mutationFn'
  >;
}

export const useEditSensorDefaultConfigurationRequest = ({ options }: RequestParams = {}) => {
  const api = useApiInstance()

  return useMutation<unknown, Error, SensorConfiguration, SensorDefaultConfiguration>(
    [Entities.SensorConfiguration, 'edit_default_configuration'],
    async (payload: SensorConfiguration) => {
      const { data } = await api.v1AdminSensorsDefaultConfigurationPut(
        payload,
      )
      return data
    },
    {
      ...options,
    },
  )
}

import { FC } from 'react'
import NumberFormat from 'react-number-format'
import { LinkProps } from '@mui/material'
import { AsYouType } from 'libphonenumber-js'
import { OuterLink } from './outerLink'

interface PhoneLinkProps {
  value: string;
}

export const PhoneLink: FC<PhoneLinkProps & Omit<LinkProps, 'value>'>> = ({
  value,
  ...rest
}) => {
  return (
    <OuterLink {...rest} to={`tel:${value}`} target="_blank">
      <NumberFormat
        value={value}
        displayType="text"
        format={(val) => new AsYouType().input('+' + val)}
      />
    </OuterLink>
  )
}

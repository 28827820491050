import { useMutation, UseMutationOptions } from '@tanstack/react-query'
import { Entities } from 'src/enums'
import { UpdateStagingDevices } from 'src/api'
import { useApiInstance } from './useApiInstance'

interface RequestParams {
  options?: Omit<
    UseMutationOptions<boolean, Error, UpdateStagingDevices>,
    'mutationKey' | 'mutationFn'
  >;
}

export const useEditStagingDevicesRequest = ({ options }: RequestParams = {}) => {
  const api = useApiInstance()

  return useMutation<boolean, Error, UpdateStagingDevices>(
    [Entities.Configuration, 'staging-devices'],
    async (payload: UpdateStagingDevices) => {
      const { data }: { data: unknown } = await api.v1AdminConfigurationSensorAllocationPut(payload)
      return data as boolean
    },
    options
  )
}

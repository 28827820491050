import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { Box, Grid, IconButton, Paper, Typography } from '@mui/material'
import CloseIcon from '@mui/icons-material/CloseRounded'
import {
  DataGridPremium,
  useGridApiRef,
  gridFilterModelSelector,
  gridFilteredTopLevelRowCountSelector,
  GridPaginationModel,
} from '@mui/x-data-grid-premium'
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker'
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers-pro/LocalizationProvider'
import { DateRange } from '@mui/x-date-pickers-pro'
import { UnstableSensor } from 'src/api'
import { useUnstableSensorsListRequest } from 'src/hooks/api'
import { useQueryParams } from 'src/hooks/ui'
import { SearchParamsKeys } from 'src/enums'
import { UnstableSensorsColumns } from 'src/components/columns/sensor'
import { defaultRowsValuePerPage } from 'src/config'
import { SearchForm } from 'src/components/forms'

const unstableSensorColumns = [
  UnstableSensorsColumns.DateTime,
  UnstableSensorsColumns.IMEI,
  UnstableSensorsColumns.NumberMissedUplinks,
]
const defaultPerPage = 50
const defaultPage = 1
const defaultRowsPerPageOptions = defaultRowsValuePerPage
const initialQueryParams = {
  page: `${defaultPage}`,
  rowsPerPage: `${defaultPerPage}`,
}

export const UnstableSensorsTable: FC = () => {
  const apiRef = useGridApiRef()
  const [queryParams, setQueryParams, removeQueryParams] = useQueryParams(initialQueryParams)
  const { data, isInitialLoading } = useUnstableSensorsListRequest({
    params: queryParams,
  })
  const numberOfUnstableSensors = useCallback(() => data?.count || 0, [data])()
  const unstableSensorList = useCallback(() => data?.rows || [], [data])()
  const [rowCountState, setRowCountState] = useState(numberOfUnstableSensors)
  const [dateRange, setDateRange] = useState<DateRange<Date>>(() => {
    const datetimeFrom = queryParams[SearchParamsKeys.DatetimeFrom] ? new Date(queryParams[SearchParamsKeys.DatetimeFrom]) : null
    const datetimeTo = queryParams[SearchParamsKeys.DatetimeTo] ? new Date(queryParams[SearchParamsKeys.DatetimeTo]) : null
    return [datetimeFrom, datetimeTo]
  })

  useEffect(() => {
    setRowCountState((prevRowCountState) =>
      numberOfUnstableSensors !== undefined ? numberOfUnstableSensors : prevRowCountState
    )
  }, [numberOfUnstableSensors, setRowCountState])

  const handleChangePaginationModel = async (pagination: GridPaginationModel): Promise<void> => {
    const { page, pageSize } = pagination
    const newPage = page + 1
    if (setQueryParams) {
      setQueryParams({
        [SearchParamsKeys.Page]: `${newPage}`,
        [SearchParamsKeys.RowsPerPage]: `${pageSize}`,
      }, [
        SearchParamsKeys.Page,
        SearchParamsKeys.RowsPerPage,
      ])
    }
  }

  const handleChangeState = async () => {
    const filterModel = gridFilterModelSelector(apiRef.current.state)
    if (filterModel.items.length > 0 && filterModel.items[0].value) {
      const visibleRowCount = gridFilteredTopLevelRowCountSelector(apiRef.current.state)
      if (visibleRowCount) setRowCountState(visibleRowCount)
    }
    else setRowCountState(numberOfUnstableSensors)
  }

  const page = queryParams["page"] ? parseInt(queryParams["page"], 10) - 1 : defaultPage - 1
  const pageSize = queryParams["rowsPerPage"]
    ? parseInt(queryParams["rowsPerPage"], 10)
    : defaultPerPage

  const onChangeDatePicker = (value: DateRange<Date>) => {
    const [start, end] = value
    setDateRange(value)
    setQueryParams({
      ...queryParams,
      ...(start && { [SearchParamsKeys.DatetimeFrom]: start.toISOString() }),
      ...(end && { [SearchParamsKeys.DatetimeTo]: end.toISOString() }),
    })
  }

  const resetRange = () => {
    const datetimeFrom = null
    const datetimeTo = null
    setDateRange([datetimeFrom, datetimeTo])
    removeQueryParams([
      SearchParamsKeys.DatetimeFrom,
      SearchParamsKeys.DatetimeTo,
    ])
  }

  const isDisabledResetButton = useMemo(() => !dateRange[0] && !dateRange[1], [dateRange])

  return (
    <Grid container direction='column' spacing={2}>
      <Grid item>
        <Typography variant='h6'>Unstable sensors</Typography>
      </Grid>
      <Grid item>
        <SearchForm />
      </Grid>
      <Grid item container width="500px" alignItems="center" spacing={1}>
        <Grid item xs={10}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DateRangePicker
              localeText={{ start: 'From', end: 'To' }}
              value={dateRange}
              onChange={onChangeDatePicker}
              disableFuture
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={2}>
          <IconButton aria-label="reset" color="primary" onClick={resetRange} disabled={isDisabledResetButton}>
            <CloseIcon />
          </IconButton>
        </Grid>
      </Grid>
      <Grid container item>
        <Paper sx={{ flexGrow: 1, minHeight: '200px', maxHeight: '500px' }}>
          <DataGridPremium<UnstableSensor>
            apiRef={apiRef}
            pagination
            disableRowSelectionOnClick
            rows={unstableSensorList}
            columns={unstableSensorColumns}
            rowCount={rowCountState}
            loading={isInitialLoading}
            paginationModel={{
              page: page as number,
              pageSize: pageSize as number
            }}
            rowThreshold={2}
            columnThreshold={2}
            paginationMode="server"
            pageSizeOptions={defaultRowsPerPageOptions}
            onPaginationModelChange={handleChangePaginationModel}
            onStateChange={handleChangeState}
            localeText={{
              columnMenuSortAsc: "Sort A-Z",
              columnMenuSortDesc: "Sort Z-A",
            }}
            sx={(theme) => ({
              border: "none",
              "& .MuiDataGrid-columnHeaderTitleContainerContent": {
                paddingLeft: "5px",
                overflow: "visible",
              },
              "& .MuiDataGrid-columnHeaderTitle": {
                whiteSpace: "normal",
                lineHeight: 1,
                fontSize: "13px",
                fontWeight: 400,
                color: "rgba(0, 0, 0, 0.6)",
                overflow: "visible",
              },
            })}
          />
        </Paper>
      </Grid>
    </Grid>
  )
}

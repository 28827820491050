import React, { FC } from 'react'
import { Grid } from '@mui/material'
import { Table, DynamicColumn } from 'src/components/ui'
import { Sensor } from 'src/api'
import SensorColumns from 'src/components/columns/sensor'

const verizonSensorColumns: DynamicColumn<Sensor>[] = [
  SensorColumns.TypeName,
  SensorColumns.IMEI,
  SensorColumns.FirmwareVersion,
  SensorColumns.EnabledBluetooth,
  SensorColumns.LastPing,
  SensorColumns.Contractor,
  SensorColumns.Comment,
]

const tektelikSensorColumns: DynamicColumn<Sensor>[] = [
  SensorColumns.TypeName,
  SensorColumns.DevEUI,
  SensorColumns.ApplicationEUI,
  SensorColumns.ApplicationKey,
  SensorColumns.BatteryLevel,
  SensorColumns.LastPing,
]

interface SensorInfoProps {
  sensor: Sensor;
}

export const SensorInfo: FC<SensorInfoProps> = (props) => {
  const { sensor } = props

  const columns = sensor.imei ? verizonSensorColumns : tektelikSensorColumns

  return (
    <Grid container spacing={4}>
      <Grid item container>
        <Table
          columns={columns}
          data={[sensor]}
          showFooter={false}
          sx={{ width: '100%' }}
        />
      </Grid>
    </Grid>
  )
}

import { Grid, TableCell } from "@mui/material"
import CheckIcon from "@mui/icons-material/Check"
import CloseIcon from "@mui/icons-material/Close"
import { GridColDef } from '@mui/x-data-grid-premium'
import { format } from "date-fns"
import { DynamicColumn, InnerLink } from "src/components/ui"
import { Contractor, ContractorActivationHistory } from "src/api"

export namespace ContractorColumns {
  export const Name: DynamicColumn<Contractor> = {
    field: "name",
    headerName: "Name",
    type: "string",
    sort: true,
    innerLink: ({ id }) => `contractors/${id}`,
  }
  export const Address: DynamicColumn<Contractor> = {
    field: "address1",
    headerName: "Address",
    type: "string",
  }
  export const Phone: DynamicColumn<Contractor> = {
    field: "phone",
    headerName: "Phone",
    type: "phone",
    sort: true,
  }
  export const Email: DynamicColumn<Contractor> = {
    field: "email",
    headerName: "Email",
    type: "email",
    sort: true,
  }
  export const SensorsCount: DynamicColumn<Contractor> = {
    field: "sensors",
    headerName: "Sensors, active/all",
    type: "custom",
    cellProps: { align: "right" },
    component: ({
      row: { usingSensorsAmount, allSensorsAmount },
      cellProps,
    }) => {
      return (
        <TableCell align={cellProps?.align}>
          {allSensorsAmount === 0
            ? "-"
            : `${usingSensorsAmount}/${allSensorsAmount}`}
        </TableCell>
      )
    },
  }
  export const ProjectsCount: DynamicColumn<Contractor> = {
    field: "projects",
    headerName: "Projects, active/all",
    type: "custom",
    cellProps: { align: "right" },
    component: ({
      row: { activeProjectsAmount, allProjectsAmount },
      cellProps,
    }) => {
      return (
        <TableCell align={cellProps?.align}>
          {allProjectsAmount === 0
            ? "-"
            : `${activeProjectsAmount}/${allProjectsAmount}`}
        </TableCell>
      )
    },
  }
  export const UsersCount: DynamicColumn<Contractor> = {
    field: "users",
    headerName: "Users",
    type: "custom",
    cellProps: { align: "right" },
    component: ({ row: { usersAmount }, cellProps }) => {
      return (
        <TableCell align={cellProps?.align}>{usersAmount || "-"}</TableCell>
      )
    },
  }
  export const Activation: DynamicColumn<Contractor> = {
    field: "activation",
    headerName: "Activation",
    type: "custom",
    component: ({ row: { isAgreementAccepted } }) => {
      return (
        <TableCell align="center">
          {isAgreementAccepted ? <CheckIcon /> : <CloseIcon color="error" />}
        </TableCell>
      )
    },
  }
  export const SubscriptionStatus: DynamicColumn<Contractor> = {
    field: "",
    headerName: "Subscription",
    type: "custom",
    cellProps: { align: "right" },
    component: ({ row, cellProps }) => {
      const status = () => {
        if (row.isActivePaidPlan) {
          return "Paid plan"
        }
        if (row.isActiveTrial) {
          return "Trial is active"
        }
        if (row.isActiveTrial === false && row.trialEndedAt) {
          return "Trial is ended"
        }
        return "Trial not started"
      }
      return (
        <TableCell align={cellProps?.align}>
          {status()}
        </TableCell>
      )
    }
  }
}

export namespace ContractorColumnsDataGrid {
  export const Name: GridColDef<Contractor> = {
    field: "name",
    headerName: "Name",
    type: "string",
    minWidth: 180,
    editable: false,
    renderCell: ({ value, row }) => (
      <InnerLink to={`/contractors/${row.id}`}>{value}</InnerLink>
    )
  }

  export const Photo: GridColDef<Contractor> = {
    field: "photo",
    headerName: "Photo",
    minWidth: 60,
    editable: false,
    align: "center",
    renderCell: ({ value, row }) => (
      <InnerLink to={`/contractors/${row.id}`}>
        <Grid container alignItems="center">
          <img
            style={{
              objectFit: "scale-down",
              width: 50,
              height: 50
            }}
            alt="Contractor logo"
            src={value || "../../../../no_image.png"}
          />
        </Grid>
      </InnerLink>
    ),
  }

  export const Address: GridColDef<Contractor> = {
    field: "address1",
    headerName: "Address",
    type: "string",
    minWidth: 180,
    editable: false,
  }

  export const Phone: GridColDef<Contractor> = {
    field: "phone",
    headerName: "Phone",
    type: "string",
    minWidth: 130,
    editable: false,
  }

  export const Email: GridColDef<Contractor> = {
    field: "email",
    headerName: "Email",
    type: "string",
    minWidth: 180,
    editable: false,
  }

  export const Projects: GridColDef<Contractor> = {
    field: "projects",
    headerName: "Projects, active/all",
    minWidth: 80,
    editable: false,
    valueGetter: ({ row: { allProjectsAmount, activeProjectsAmount } }) => (
      allProjectsAmount === 0 ? "-" : `${activeProjectsAmount}/${allProjectsAmount}`
    ),
  }

  export const Sensors: GridColDef<Contractor> = {
    field: "sensors",
    headerName: "Sensors, active/all",
    minWidth: 80,
    editable: false,
    valueGetter: ({ row: { allSensorsAmount, usingSensorsAmount } }) => (
      allSensorsAmount === 0 ? "-" : `${usingSensorsAmount}/${allSensorsAmount}`
    ),
  }

  export const UsersAmount: GridColDef<Contractor> = {
    field: "usersAmount",
    headerName: "Users",
    type: "number",
    minWidth: 50,
    editable: false,
  }

  export const Activation: GridColDef<Contractor> = {
    field: "activation",
    headerName: "Activation",
    minWidth: 80,
    editable: false,
    valueGetter: ({ row: { isAgreementAccepted } }) => isAgreementAccepted ? 'Activated' : 'Not activated',
    renderCell: ({ row: { isAgreementAccepted } }) => {
      return isAgreementAccepted ? <CheckIcon /> : <CloseIcon color="error" />
    }
  }

  export const Subscription: GridColDef<Contractor> = {
    field: "subscription",
    headerName: "Subscription",
    minWidth: 80,
    editable: false,
    valueGetter: ({ row }) => {
      if (row.isActivePaidPlan) {
        return "Paid plan"
      }
      if (row.isActiveTrial) {
        return "Trial is active"
      }
      if (row.isActiveTrial === false && row.trialEndedAt) {
        return "Trial is ended"
      }
      return "Trial not started"
    },
  }

  export const Labels: GridColDef<Contractor> = {
    field: "labels",
    headerName: "Labels",
    type: "string",
    minWidth: 180,
    editable: false,
    valueGetter: ({ row }) => row?.labels?.length ? row.labels.map((label) => label.name).join(", ") : null,
  }

  export const CreatedAt: GridColDef<Contractor> = {
    field: "createdAt",
    headerName: "Created At",
    type: "string",
    minWidth: 200,
    editable: false,
    valueFormatter: ({ value }) => value ? format(new Date(value), 'PPpp') : null
  }

  export const ContractorType: GridColDef<Contractor> = {
    field: "isRoot",
    headerName: "Type",
    minWidth: 120,
    editable: false,
    valueGetter: ({ row }) => {
      if (row.isRoot === true) {
        return "HQ"
      }
      if (row.isRoot === false && typeof row.rootContractorId === 'number') {
        return "Branch"
      }
      return "Standalone"
    },
  }

  export const RootContractor: GridColDef<Contractor> = {
    field: "rootContractor",
    headerName: "HQ",
    minWidth: 120,
    editable: false,
    valueGetter: ({ row }) => row.rootContractor?.name || null,
    renderCell: ({ value, row }) => {
      const { rootContractor } = row
      if (typeof row.rootContractorId === "number" && rootContractor) {
        return <InnerLink to={`/contractors/${rootContractor.id}`}>{value}</InnerLink>
      }
      return "-"
    }
  }

  export const DashboardStatus: GridColDef<Contractor> = {
    field: "isActiveDashboard",
    headerName: "Dashboard",
    minWidth: 120,
    editable: false,
    valueGetter: ({ row }) => row.isActiveDashboard ? "Enabled" : "Disabled",
  }
}

export namespace ContractorActivationHistoryColumns {
  export const Status: GridColDef<ContractorActivationHistory> = {
    field: "status",
    headerName: "Status",
    minWidth: 200,
    editable: false,
    valueFormatter: ({ value }) => value ? value.charAt(0).toUpperCase() + value.slice(1) : null
  }

  export const AuthorName: GridColDef<ContractorActivationHistory> = {
    field: "authorName",
    headerName: "Author name",
    minWidth: 200,
    editable: false,
  }

  export const AuthorPhone: GridColDef<ContractorActivationHistory> = {
    field: "authorPhone",
    headerName: "Author phone",
    minWidth: 200,
    editable: false,
  }

  export const Datetime: GridColDef<ContractorActivationHistory> = {
    field: "datetime",
    headerName: "Datetime",
    minWidth: 200,
    editable: false,
    valueFormatter: ({ value }) => value ? format(new Date(value), 'PPpp') : null
  }
}

export default ContractorColumns

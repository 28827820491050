import React, { FC, useState, Fragment } from 'react'
import {
  Grid,
  Divider,
} from '@mui/material'
import {
  useDeleteManufacturerAnnouncementTypeRequest,
  useEditManufacturerAnnouncementTypeRequest,
} from 'src/hooks/api'
import { ManufacturerAnnouncementType } from 'src/api'
import { useAddPopupMessage, useDialog } from 'src/hooks/ui'
import { DialogNames } from 'src/enums'
import { InlineType } from './inlineType'
import { EditableType } from './editableType'
import { RemoveTypeDialog } from './removeTypeDialog'

interface Props {
  data: ManufacturerAnnouncementType[];
  onChange: () => void;
}

export const PostTypeList: FC<Props> = ({ data, onChange }) => {
  const [editId, setEditId] = useState<number | null>(null)
  const [removeItem, setRemoveItem] = useState<{
    id: number;
    name: string;
  } | null>(null)
  const addMessage = useAddPopupMessage()
  const { openDialog, closeDialog } = useDialog(DialogNames.DeleteManufacturerAnnouncementType)

  const removePostTypeRequest = useDeleteManufacturerAnnouncementTypeRequest({
    options: {
      onSuccess: onChange,
      onError: (error) => addMessage({ type: 'error', text: error.message }),
    }
  })
  const updatePostTypeRequest = useEditManufacturerAnnouncementTypeRequest({
    id: editId as number,
    options: {
      onSuccess: () => {
        updateEditId(null)
        onChange()
      },
      onError: (error) => addMessage({ type: 'error', text: error.message }),
    }
  })

  const openRemoveDialog = (type: { id: number; name: string }) => {
    setRemoveItem(type)
    openDialog()
  }

  const closeRemoveDialog = () => {
    closeDialog(() => setRemoveItem(null))
  }

  const onRemove = () => {
    if (removeItem !== null) {
      removePostTypeRequest.mutate({ id: removeItem.id })
      closeRemoveDialog()
    }
  }

  const onEdit = ({ displayName }: { displayName: string }) => {
    updatePostTypeRequest.mutate({ displayName })
  }

  const updateEditId = (id: number | null) => {
    setEditId(id)
  }

  return (
    <>
      <Grid container direction="column" spacing={2}>
        {data.map(({ id, displayName }, idx, list) => (
          <Fragment key={idx}>
            <Grid item container spacing={1} alignItems="center">
              {editId === id ? (
                <EditableType
                  placeholder='New Post Type Name'
                  name={displayName || ''}
                  isLoading={updatePostTypeRequest.isLoading}
                  onChange={onEdit}
                  onCancel={() => updateEditId(null)}
                />
              ) : (
                <InlineType
                  id={id as number}
                  name={displayName || ''}
                  onEdit={updateEditId}
                  onDelete={openRemoveDialog}
                />
              )}
            </Grid>
            {idx !== list.length - 1 && (
              <Divider flexItem={true} sx={{ ml: 2, mt: 2 }} />
            )}
          </Fragment>
        ))}
      </Grid>
      <RemoveTypeDialog
        dialogName={DialogNames.DeleteManufacturerAnnouncementType}
        name={removeItem?.name || ''}
        onDelete={onRemove}
        onCancel={closeRemoveDialog}
      />
    </>
  )
}
import { FC, useEffect, useMemo, useState } from 'react'
import FormGroup from '@mui/material/FormGroup'
import { useCombinedReportIncludedEventsListRequest, useEditCombinedReportIncludedEventsRequest } from 'src/hooks/api'
import { CombinedReportIncludedEvent, EventTypeName } from 'src/api'
import { DataLoad, Switch } from 'src/components'
import { Box, Grid, Typography } from '@mui/material'
import { useAddPopupMessage } from 'src/hooks/ui'

export const CombinedReportEvents: FC = () => {
  const [includedEvents, setIncludedEvents] = useState<CombinedReportIncludedEvent[]>([])
  const addPopupMessage = useAddPopupMessage()
  const { data, isInitialLoading, isError, error } = useCombinedReportIncludedEventsListRequest()
  const updateCombinedReportIncludedEventsRequest = useEditCombinedReportIncludedEventsRequest({
    options: {
      onSuccess: (response) => {
        setIncludedEvents(response)
        addPopupMessage({ text: 'Succefully updated', type: 'success' })
      }
    }
  })
  useEffect(() => {
    setIncludedEvents(data || [])
  }, [data])

  const onChange = (name: string, isActive: boolean) => {
    setIncludedEvents((prev) => prev.map((event) => {
      if (event.name === name) {
        return {
          ...event,
          isActive,
        }
      }
      return event
    }))
    updateEvent(name, isActive)
  }
  const updateEvent = (eventName: string, isActive: boolean) => {
    updateCombinedReportIncludedEventsRequest.mutate({ includedEvents: [
      { name: eventName, isActive },
    ] })
  }
  const alertSensorEvents = useMemo(() => {
    return includedEvents.filter((event) => ([
      EventTypeName.AlertAppears,
      EventTypeName.AlertClosed,
      EventTypeName.AlertCreated,
      EventTypeName.AlertIgnored,
      EventTypeName.AlertShared,
      EventTypeName.AllProductsInRange,
      EventTypeName.SensorConnected,
      EventTypeName.SensorDisconnected,
      EventTypeName.SensorNewRangeValues,
      EventTypeName.SensorUpdated,
      EventTypeName.ClimateConditionsShared,
      EventTypeName.SlabConditionsShared,
      EventTypeName.SlabReadingAdded,
      EventTypeName.SlabReadingUpdated,
      EventTypeName.SlabConditionsListShared,
    ] as string[]).includes(event.name as string))
  }, [includedEvents])
  const documentTaskEvents = useMemo(() => {
    return includedEvents.filter((event) => ([
      EventTypeName.DocumentAdded,
      EventTypeName.DocumentRemoved,
      EventTypeName.DocumentUpdated,
      EventTypeName.TaskCreated,
      EventTypeName.TaskUpdated,
      EventTypeName.TaskArchived,
      EventTypeName.TaskUnarchived,
    ] as string[]).includes(event.name as string))
  }, [includedEvents])
  const variousEvents = useMemo(() => {
    return includedEvents.filter((event) => ([
      EventTypeName.CheckConnectivity,
      EventTypeName.NewNoteAdded,
      'events_with_photos',
      'shared_events',

    ] as string[]).includes(event.name as string))
  }, [includedEvents])
  const productEvents = useMemo(() => {
    return includedEvents.filter((event) => ([
      EventTypeName.ProductAdded,
      EventTypeName.ProductUsedOnTask,
      EventTypeName.ProductUpdated,
      EventTypeName.ProductAdditionRequested,
      EventTypeName.ProductArchived,
      EventTypeName.ProductUnarchived,
      EventTypeName.ProductInSpecification,
      EventTypeName.ProductOutOfSpecification,
      EventTypeName.ProductMonitoringTurnedOn,
      EventTypeName.ProductMonitoringTurnedOff,

    ] as string[]).includes(event.name as string))
  }, [includedEvents])
  const projectEvents = useMemo(() => {
    return includedEvents.filter((event) => ([
      EventTypeName.ProjectCreated,
      EventTypeName.ProjectUpdated,
      EventTypeName.ProjectArchived,
      EventTypeName.ProjectUnarchived,
      EventTypeName.SectionCreated,
      EventTypeName.SectionUpdated,
      EventTypeName.ProjectSectionArchived,
      EventTypeName.ProjectSectionUnarchived,
      EventTypeName.UserAdded,
      EventTypeName.UserUpdated,
      EventTypeName.UserArchived,
      EventTypeName.UserUnarchived,
      EventTypeName.UserAssignedToProject,
      EventTypeName.UserRemovedFromProject,
      EventTypeName.ContractorUserAdded,
      EventTypeName.ContractorUserRemoved,

    ] as string[]).includes(event.name as string))
  }, [includedEvents])
  const groupedEvents = useMemo(() => {
    return [
      { title: 'Alerts, sensors', events: alertSensorEvents },
      { title: 'Documents, tasks', events: documentTaskEvents },
      { title: 'Products events', events: productEvents },
      { title: 'Project configuration, users', events: projectEvents },
      { title: 'Various', events: variousEvents },
    ]
  }, [alertSensorEvents, documentTaskEvents, variousEvents, productEvents, projectEvents])

  return (
    <DataLoad
      isLoading={isInitialLoading}
      isError={isError}
      errorMessage={error?.message}
    >
      <Grid container direction="column" flexWrap="wrap">
        {groupedEvents.map(({ title, events }) => (
          <>
            <Typography variant="h6" mb={1}>{title}</Typography>
            <FormGroup sx={{ display: 'flex', height: '300px', flexDirection: 'column', flexWrap: 'wrap', mb: 2 }}>
              {events.map((event) => (
                <Box key={event.id} mb={1}>
                  <Switch
                    label={event.displayName as string}
                    name={event.name as string}
                    SwitchProps={{
                      checked: event.isActive,
                      onChange: () => onChange(event.name as string, !event.isActive),
                      size: 'small',
                    }}
                  />
                </Box>
              ))}
            </FormGroup>
          </>
        ))}
      </Grid>
    </DataLoad>
  )
}

import React, { FC, useState } from 'react'
import {
  Button,
  Grid,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  TextField,
  CircularProgress,
} from '@mui/material'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { useDialog, useDownloadingFile } from 'src/hooks/ui'
import { DialogNames, FloorcloudSensorLocationType } from 'src/enums'
import { StatefulDialog } from 'src/components/ui'

interface Props {
  sensorUniqueId: string
}

export const SensorGpxDataModal: FC<Props> = ({ sensorUniqueId }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [dateFrom, setDateFrom] = useState<Date | null>(null)
  const [dateTo, setDateTo] = useState<Date | null>(null)
  const dialogName = DialogNames.SensorGpxData
  const { openDialog, closeDialog } = useDialog(dialogName)
  const { downloadFile } = useDownloadingFile()
  const handleOpen = () => {
    openDialog()
  }
  const handleClose = () => {
    closeDialog()
  }
  const downloadGpxData = async () => {
    const types: FloorcloudSensorLocationType[] = [FloorcloudSensorLocationType.GPS, FloorcloudSensorLocationType.CELLULAR]
    let error = false
    setIsLoading(true)
    for (const type of types) {
      const urlPath = `v1/admin/sensors/${sensorUniqueId}/gpx-data`
      const queryParams = { type, dateFrom: dateFrom?.toISOString(), dateTo: dateTo?.toISOString() }
      const { isError } = await downloadFile({ urlPath, queryParams })
      if (isError) {
        error = true
        continue
      }
    }
    setIsLoading(false)
    if (!error) {
      handleClose()
    }
  }
  const onChangeDate = (newDate: Date | null, type: 'from' | 'to') => {
    const setDate = type === 'from' ? setDateFrom : setDateTo
    const preparedDate = newDate
      ? new Date(new Date(newDate).setHours(0, 0, 0))
      : newDate
    setDate(preparedDate)
  }

  return (
    <Grid>
      <Button
        variant="outlined"
        onClick={handleOpen}
        size="large"
      >
        Download GPX track
      </Button>
      <StatefulDialog
        name={dialogName}
        onClose={handleClose}
        actions={
          <DialogActions>
            <Button onClick={handleClose}>
              Close
            </Button>
            <Button
              onClick={downloadGpxData}
              disabled={isLoading}
            >
              {isLoading ? <CircularProgress size={22} /> : "Download"}
            </Button>
          </DialogActions>
        }
      >
        <DialogTitle id="alert-dialog-title">
          Download GPX track
        </DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ mb: 2 }}>
            Please select a date range
          </DialogContentText>
          <Grid container alignItems='center' spacing={1}>
            <Grid item>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  value={dateFrom}
                  onChange={(date) => onChangeDate(date, 'from')}
                  renderInput={(props: any) => <TextField {...props} />}
                  disableFuture
                  {...(dateTo ? { maxDate: dateTo } : {})}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item>-</Grid>
            <Grid item>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  value={dateTo}
                  onChange={(date) => onChangeDate(date, 'to')}
                  renderInput={(props: any) => <TextField {...props} />}
                  disableFuture
                  {...(dateFrom ? { minDate: dateFrom } : {})}
                />
              </LocalizationProvider>
            </Grid>
          </Grid>
        </DialogContent>
      </StatefulDialog>
    </Grid>
  )
}

import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { Entities } from 'src/enums'
import {
  Sensor, SensorCurrentConfig,
} from 'src/api'
import { useApiInstance } from './useApiInstance'

interface RequestParams {
  uniqueId: string;
  options?: Omit<
    UseQueryOptions<unknown, Error, SensorCurrentConfig>,
    'queryKey' | 'queryFn'
  >;
}

export const useSensorConfiguration = ({
  uniqueId,
  options,
}: RequestParams) => {
  const api = useApiInstance()

  return useQuery<unknown, Error, SensorCurrentConfig>(
    [Entities.Sensors, `sensor-configuration-${uniqueId}`],
    async () => {
      const { data } = await api.v1AdminSensorsUniqueIdConfigurationGet(uniqueId)
      return data as Sensor
    },
    { ...options },
  )
}

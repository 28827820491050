import { Box } from "@mui/system"
import React, { FC } from "react"
import { EntityHeader, HasSidebarLayout } from "src/components"
import { ReleaseNewForm } from "src/components/forms/release"

export const ReleaseNew: FC = () => {
  document.title = 'Floorcloud Admin Panel - New release'
  return (
    <HasSidebarLayout>
      <EntityHeader title="Add new release" />
      <Box width="500px" mt={2}>
        <ReleaseNewForm />
      </Box>
    </HasSidebarLayout>
  )
}

export default ReleaseNew

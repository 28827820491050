import { FC, SyntheticEvent, useEffect, useState } from "react"
import { Form, useFormik, FormikProvider } from "formik"
import * as Yup from "yup"
import { useNavigate } from "react-router-dom"
import { Autocomplete, Box, Button, TextField as MuiTextField, CircularProgress, Divider, Grid } from "@mui/material"
import { FormCheckbox, FormDropdown, TextField } from "src/components/ui"
import { useNewManufacturerAnnouncementRequest, useUploadPhotoRequest } from "src/hooks/api"
import { UploadPhotoResult, CreateManufacturerAnnouncementPostPayload, CreateManufacturerAnnouncementPayload, ManufacturerAnnouncementType, Manufacturer, ManufacturerAnnouncementCategory } from "src/api"
import { useAddPopupMessage, useDialog, useDropdownItemFactory } from "src/hooks/ui"
import { DialogNames } from "src/enums"
import { ManufacturerAnnouncementPostNewForm } from "./manufacturerAnnouncementPostNewForm"
import { hasDuplicates } from "./helpers"

export interface ManufacturerAnnouncementPostPayload {
  id: string | number
  title?: string
  description: string
  videoLink?: string
  photo?: File | null
  link?: string
}

interface NewManufacturerAnnouncementPayload {
  name: string
  email?: string
  typeId: number
  categoryIds: number[]
  isVisibleContactMeButton: boolean
}

interface Props {
  announcementTypes: ManufacturerAnnouncementType[]
  announcementCategories: ManufacturerAnnouncementCategory[]
  manufacturers: Manufacturer[]
  searchManufacturer: (name: string) => void
}

const validationSchema = Yup.object().shape({
  name: Yup.string(),
  email: Yup.string().email('Please provide a valid email'),
  typeId: Yup.number().required('Post type is required'),
  categoryIds: Yup.array().of(Yup.number()).required('Category is required').min(1, 'Category is required'),
  postName: Yup.string(),
})

const initialValues = {
  name: "",
  email: "",
  typeId: 0,
  categoryIds: [0],
  postName: "",
  isVisibleContactMeButton: true,
}

export const ManufacturerAnnouncementNewForm: FC<Props> = ({ announcementCategories, announcementTypes, manufacturers, searchManufacturer }) => {
  const [posts, setPosts] = useState<ManufacturerAnnouncementPostPayload[]>([])
  const [selectedManufacturer, setSelectedManufacturer] = useState<Manufacturer | null>(null)
  const [lastUsedPostId, setLastUsedPostId] = useState<number>(0)
  const photoUploadRequest = useUploadPhotoRequest()
  const newManufacturerAnnouncementRequest = useNewManufacturerAnnouncementRequest()
  const addMessage = useAddPopupMessage()
  const navigate = useNavigate()
  const { openDialog: openCancelDialog } = useDialog(DialogNames.CancelManufacturerAnnouncement)

  const saveAnnouncement = (data: CreateManufacturerAnnouncementPayload) => {
    newManufacturerAnnouncementRequest.mutate({
      ...data,
      manufacturerId: selectedManufacturer ? selectedManufacturer.id : undefined,
    }, {
      onSuccess: () => {
        addMessage({ text: 'Announcement was successfully created', type: 'success' })
        navigate(-1)
      },
      onError: ({ message: text }) => {
        addMessage({ text, type: 'error' })
      }
    })
  }
  const onSubmit = (data: NewManufacturerAnnouncementPayload) => {
    if (!selectedManufacturer) {
      addMessage({ text: "Please select a manufacturer", type: 'warning' })
      return
    }
    if (posts.length === 0) {
      addMessage({ text: "Please add at least one post", type: 'warning' })
      return
    }
    if (hasDuplicates(posts)) {
      addMessage({ text: "There are posts with the same title in the announcement", type: 'error' })
      return
    }

    let preparedPosts: CreateManufacturerAnnouncementPostPayload[] = []
    const images = posts
      .filter((post) => Boolean(post.photo))
      .map((post) => post.photo as File)
    
    if (images.length > 0) {
      photoUploadRequest.mutate({
        photos: images,
      }, {
        onSuccess: (result) => {
          (result as UploadPhotoResult[]).forEach((image) => {
            const post = posts.find((item) => item.photo?.name === image.originalFileName)
            if (post) {
              preparedPosts.push({
                title: post.title,
                description: post.description,
                videoLink: post.videoLink,
                image: image.original,
              })
            }
          })

          const preparedPostsWithoutImage: CreateManufacturerAnnouncementPostPayload[] = posts
            .filter((post) => !post.photo)
            .map((post) => ({
              title: post.title,
              description: post.description,
              videoLink: post.videoLink,
            }))
          saveAnnouncement({
            name: data.name || '',
            email: data.email || '',
            posts: [...preparedPosts, ...preparedPostsWithoutImage],
            typeId: data.typeId,
            categoryIds: data.categoryIds,
            isVisibleContactMeButton: data.isVisibleContactMeButton,
          })
        }
      })
    } else {
      preparedPosts = posts.map((post) => ({
        title: post.title,
        description: post.description,
        videoLink: post.videoLink,
      }))
      saveAnnouncement({
        name: data.name || '',
        email: data.email || '',
        posts: preparedPosts,
        typeId: data.typeId,
        categoryIds: data.categoryIds,
        isVisibleContactMeButton: data.isVisibleContactMeButton,
      })
    }
  }
  const formik = useFormik({
    initialValues,
    onSubmit: onSubmit,
    validationSchema,
  })
  const { values, setValues, submitForm } = formik

  useEffect(() => {
    setValues({
      ...values,
      typeId: announcementTypes[0]?.id || 0,
      categoryIds: [announcementCategories[0]?.id || 0]
    })
  }, [announcementTypes, announcementCategories])

  const getNewPostId = (): string => {
    const newPostId: number = lastUsedPostId + 1
    return `manual_id_${newPostId}`
  }

  const addPost = () => {
    const id = getNewPostId()
    const isDuplicate = posts.some((post) => {
      const preparedPostName = (post.title || '').toLowerCase().replace(' ', '_')
      return preparedPostName !== '' && values.postName !== '' && preparedPostName === values.postName
    })
    if (isDuplicate) {
      addMessage({ text: "The post with this name already exists", type: 'warning' })
      return
    }
    setPosts([
      ...posts,
      { id, title: values.postName || '', description: '', videoLink: '' }
    ])
    setValues({
      ...values,
      postName: ''
    })
    setLastUsedPostId(lastUsedPostId + 1)
  }
  const updatePost = (post: Partial<ManufacturerAnnouncementPostPayload>) => {
    const index = posts.findIndex((item) => item.id === post.id)
    if (index === -1) return

    const isDuplicate = posts.filter((item) => item.id !== post.id).some((item) => {
      const preparedPostName = (item.title || '').toLowerCase().replace(' ', '_')
      const preparedUpdatedPostName = (post.title || '').toLowerCase().replace(' ', '_')
      return preparedPostName !== '' && preparedUpdatedPostName !== '' && preparedPostName === preparedUpdatedPostName
    })
    if (isDuplicate) {
      addMessage({ text: "The post with this name already exists", type: 'warning' })
    }
    const copy = posts.slice()
    copy[index] = {
      ...copy[index],
      ...post,
    }
    setPosts(copy)
  }
  const deletePost = (id: string | number) => {
    const filteredPosts = posts.filter((post) => post.id !== id)
    setPosts(filteredPosts)
  }
  const dropdownItemListMap = useDropdownItemFactory({
    params: { labelProp: 'displayName', valueProp: 'id' },
  })
  const onChangeManufacturerHandler = (event: SyntheticEvent, manufacturer: Manufacturer | null) => {
    setSelectedManufacturer(manufacturer)
  }

  return (
    <FormikProvider
      value={formik}
    >
      <Form>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Autocomplete
              id="manufacturers"
              options={manufacturers || []}
              getOptionLabel={(option) => option.name || ''}
              style={{ width: 500 }}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              renderInput={(params) => (
                <MuiTextField
                  {...params}
                  label="Manufacturer"
                  placeholder="Manufacturer"
                  required
                />
              )}
              value={selectedManufacturer}
              onChange={onChangeManufacturerHandler}
              onInputChange={(_, newValue) => searchManufacturer(newValue)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              name="name"
              label="Main header"
              TextFieldProps={{ placeholder: "Main header" }}
            />
          </Grid>
          <Grid item container>
            <Grid item xs={12}>
              {posts.map((post) => (
                <ManufacturerAnnouncementPostNewForm
                  key={post.id}
                  defaultValues={post}
                  onChangePost={updatePost}
                  onDeletePost={deletePost}
                />
              ))}
            </Grid>
            <Grid
              item
              container
              alignItems="center"
              justifyContent="space-between"
              spacing={1}
              xs={12}
              position="relative"
            >
              <Grid item xs={12}>
                <TextField
                  name="postName"
                  label="Subheader"
                  TextFieldProps={{ placeholder: "Subheader" }}
                />
              </Grid>
              <Grid
                item
                xs={2}
                position="absolute"
                right={-100}
              >
                <Button
                  size="large"
                  variant="outlined"
                  color="primary"
                  onClick={addPost}
                >
                  Add
                </Button>
              </Grid>
            </Grid>
            <Grid item xs={12} my={5}>
              <Divider />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <FormDropdown
              name="typeId"
              label="Post type"
              list={announcementTypes}
              menuItemFactory={dropdownItemListMap}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <FormDropdown
              name="categoryIds"
              label="Category"
              list={announcementCategories}
              menuItemFactory={dropdownItemListMap}
              multiple
              required
            />
          </Grid>
          <Grid item xs={12}>
            <FormCheckbox
              name="isVisibleContactMeButton"
              label="Have A Local Rep Contact Me button"
              required
              view="switch"
            />
          </Grid>
          <Grid item xs={12} mt={5} mb={2}>
            <Divider />
          </Grid>
          <Grid item container xs={6}>
            <Grid item xs={6}>
              <Box>
                <Button
                  size="large"
                  variant="contained"
                  color="primary"
                  type="button"
                  onClick={submitForm}
                  disabled={photoUploadRequest.isLoading || newManufacturerAnnouncementRequest.isLoading}
                >
                  {
                    photoUploadRequest.isLoading || newManufacturerAnnouncementRequest.isLoading ? <CircularProgress color="info" size={26} /> : 'Save'
                  }
                </Button>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box>
                <Button
                  size="large"
                  variant="outlined"
                  color="primary"
                  type="button"
                  onClick={openCancelDialog}
                >
                  Cancel
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Form>
    </FormikProvider>
  )
}

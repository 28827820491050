import React, { FC, useEffect, useState } from "react"
import { DialogActions, Button, DialogTitle, TextField } from "@mui/material"
import { Dialog } from "src/components/ui"
import { ProductDraft } from "../../../api"

interface EditProductManufacturerCommentState {
  id: number;
  comment: string;
}

interface EditProductManufacturerCommentModalProps {
  open: boolean;
  productDraft: ProductDraft | null;
  onSave: (value: EditProductManufacturerCommentState) => void;
  onCancel: () => void;
}

export const EditProductManufacturerCommentModal: FC<EditProductManufacturerCommentModalProps> = ({
  open,
  productDraft,
  onSave,
  onCancel,
}) => {
  const [value, setValue] = useState(productDraft?.manufacturerComment || "")

  useEffect(() => {
    setValue(productDraft?.manufacturerComment || "")
  }, [productDraft])

  const handleSave = () => {
    if (productDraft) {
      onSave({
        id: productDraft.id,
        comment: value,
      })
    }
  }

  return (
    <Dialog
      open={open}
      title={<DialogTitle>Manufacturer comment</DialogTitle>}
      scroll="paper"
      DialogProps={{ fullWidth: true, maxWidth: "xs" }}
      actions={
        <DialogActions>
          <Button variant="contained" size="large" onClick={handleSave}>
            Save
          </Button>
          <Button variant="outlined" size="large" onClick={onCancel}>
            Cancel
          </Button>
        </DialogActions>
      }
    >
      <TextField
        multiline
        fullWidth
        minRows={6}
        placeholder="Please provide details..."
        value={value}
        onChange={(e) => setValue(e.target.value)}
      />
    </Dialog>
  )
}

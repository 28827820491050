import { FC } from 'react'
import {
  DataGridPremium,
} from '@mui/x-data-grid-premium'
import Paper from '@mui/material/Paper'
import { Contractor } from 'src/api'
import { ContractorColumnsDataGrid } from 'src/components/columns/contractor'

type Props = {
  branches: Contractor[]
}

export const ContractorBranches: FC<Props> = ({
  branches,
}) => {
  return (
    <Paper sx={{ flexGrow: 1, minHeight: '200px', height: '100%', maxHeight: '800px' }}>
      <DataGridPremium<Contractor>
        disableRowSelectionOnClick
        rows={branches}
        columns={[
          ContractorColumnsDataGrid.Photo,
          ContractorColumnsDataGrid.Name,
          ContractorColumnsDataGrid.Address,
          ContractorColumnsDataGrid.Phone,
          ContractorColumnsDataGrid.Email,
          ContractorColumnsDataGrid.Subscription,
          ContractorColumnsDataGrid.Activation,
          ContractorColumnsDataGrid.CreatedAt,
        ]}
        rowThreshold={2}
        columnThreshold={2}
        rowHeight={65}
        paginationMode="server"
        localeText={{
          columnMenuSortAsc: "Sort A-Z",
          columnMenuSortDesc: "Sort Z-A",
        }}
        sx={(theme) => ({
          border: "none",
          "& .MuiDataGrid-columnHeaderTitleContainerContent": {
            paddingLeft: "5px",
            overflow: "visible",
          },
          "& .MuiDataGrid-columnHeaderTitle": {
            whiteSpace: "normal",
            lineHeight: 1,
            fontSize: "13px",
            fontWeight: 400,
            color: "rgba(0, 0, 0, 0.6)",
            overflow: "visible",
          },
        })}
      />
    </Paper>
  )
}

import { FC, useCallback } from 'react'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import { format } from "date-fns"
import { ProductImport, ProductImportStatusEnum } from 'src/api'
import { InnerLink, OuterLink } from 'src/components/ui'
import { AccordionItem, AccordionList } from 'src/components/ui/accordion'
import { getProductImportStatus } from './helpers'
import { ImportedProductList } from './importedProductList'

type Props = {
  productImport: ProductImport;
}

export const ProductImportInfo: FC<Props> = ({ productImport }) => {
  const {
    fileName,
    fileLink,
    status,
    author,
    createdAt,
    createdProducts,
    updatedProducts,
    errorProducts,
    commonErrorMessages,
  } = productImport
  const getStatus = useCallback(() => {
    return getProductImportStatus(status as ProductImportStatusEnum)
  }, [status])

  const accordionItems = useCallback((): AccordionItem[] => {
    return [
      {
        title: <Typography variant="body1" fontWeight={500}>Structure errors: {commonErrorMessages?.length}</Typography>,
        content: (
          <List sx={{ listStyleType: "disc", paddingLeft: '15px' }}>
            {(commonErrorMessages || []).map((error, index) => (
              <ListItem key={index} sx={{ display: 'list-item' }}>
                <Typography variant="body2" color="error">{error}</Typography>
              </ListItem>
            ))}
          </List>
        )
      },
      {
        title: <Typography variant="body1" fontWeight={500}>Created products: {createdProducts?.length}</Typography>,
        content: <ImportedProductList importedProducts={createdProducts || []} />
      },
      {
        title: <Typography variant="body1" fontWeight={500}>Updated products: {updatedProducts?.length}</Typography>,
        content: <ImportedProductList importedProducts={updatedProducts || []} />
      },
      {
        title: <Typography variant="body1" fontWeight={500}>Error products: {errorProducts?.length}</Typography>,
        content: <ImportedProductList importedProducts={errorProducts || []} />
      }
    ]
  }, [commonErrorMessages, createdProducts, updatedProducts, errorProducts])

  const amountOfProcessedProducts = useCallback(() => (
    (createdProducts?.length || 0) + (updatedProducts?.length || 0) + (errorProducts?.length || 0)
  ), [createdProducts, updatedProducts, errorProducts])

  return (
    <Grid container>
      <Grid item container sx={{ mb: 5 }}>
        <Grid item xs={12}>
          <Typography variant="h6" sx={{ mb: 2 }}>Common information</Typography>
        </Grid>
        <Grid item xs={12} sx={{ py: 0.5 }}>
          <Typography variant="body1" fontWeight={500} display="inline">File name:</Typography>
          {
            fileLink ? (
              <OuterLink to={fileLink}>{` ${fileName}`}</OuterLink>
            ) : (
              <Typography variant="body1" fontWeight={400} display="inline">{` ${fileName}`}</Typography>
            )
          }
        </Grid>
        <Grid item xs={12} sx={{ py: 0.5 }}>
          <Typography variant="body1" fontWeight={500} display="inline">Author:</Typography>
          {
            author && author.id ? (
              <InnerLink to={`/users/${author.id}`}>{` ${author.name}`}</InnerLink>
            ) : (
              <Typography variant="body1" fontWeight={400} display="inline">{` ${author?.name || ''}`}</Typography>
            )
          }
        </Grid>
        <Grid item xs={12} sx={{ py: 0.5 }}>
          <Typography variant="body1" fontWeight={500} display="inline">Status:</Typography>
          <Typography variant="body1" fontWeight={400} display="inline">{` ${getStatus()}`}</Typography>
        </Grid>
        <Grid item xs={12} sx={{ py: 0.5 }}>
          <Typography variant="body1" fontWeight={500} display="inline">Imported at:</Typography>
          <Typography variant="body1" fontWeight={400} display="inline">{` ${format(new Date(createdAt as string), 'PPpp')}`}</Typography>
        </Grid>
      </Grid>
      <Grid item container>
        <Grid item xs={12}>
          <Typography variant="h6" sx={{ mb: 2 }}>Imported products</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body2" sx={{ mb: 2 }}>Amount of processed products: {amountOfProcessedProducts()}</Typography>
        </Grid>
        <Grid item xs={12}>
          <AccordionList items={accordionItems()} />
        </Grid>
      </Grid>
    </Grid>
  )
}

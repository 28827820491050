import React from "react"
import { GridColDef } from '@mui/x-data-grid-premium'
import format from "date-fns/format"
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import { InnerLink, OuterLink } from "src/components/ui"
import { Document } from "src/api"

export namespace DocumentColumns {
  export const Name: GridColDef<Document> = {
    field: "name",
    headerName: "Name",
    type: "string",
    minWidth: 250,
    editable: false,
  }

  export const Description: GridColDef<Document> = {
    field: "description",
    headerName: "Description",
    type: "string",
    minWidth: 200,
    editable: false,
    valueGetter: ({ row }) => row?.description || "-",
  }

  export const Type: GridColDef<Document> = {
    field: "fileType",
    headerName: "Type",
    type: "string",
    minWidth: 100,
    editable: false,
  }

  export const Project: GridColDef<Document> = {
    field: "project.name",
    headerName: "Project",
    type: "string",
    minWidth: 200,
    editable: false,
    valueGetter: ({ row }) => row?.project?.name || null,
    renderCell: ({ value, row }) => {
      if (!value) return '-'
      return (
        <InnerLink to={`/projects/${row.project?.id}`}>{value}</InnerLink>
      )
    }
  }

  export const Contractor: GridColDef<Document> = {
    field: "contractor.name",
    headerName: "Contractor",
    type: "string",
    minWidth: 200,
    editable: false,
    valueGetter: ({ row }) => row?.contractor?.name || row?.project?.contractor?.name || null,
    renderCell: ({ value, row }) => {
      if (!value) return '-'
      const contractorId = row.contractor?.id || row.project?.contractor?.id
      return (
        <InnerLink to={`/contractors/${contractorId}`}>{value}</InnerLink>
      )
    }
  }

  export const Author: GridColDef<Document> = {
    field: "author.name",
    headerName: "Author",
    type: "string",
    minWidth: 150,
    editable: false,
    valueGetter: ({ row }) => row?.author?.name || '-',
    renderCell: ({ value, row }) => {
      if (!value) return '-'
      return (
        <InnerLink to={`/users/${row.authorId}`}>{value}</InnerLink>
      )
    }
  }

  export const CreatedAt: GridColDef<Document> = {
    field: "createdAt",
    headerName: "Created At",
    type: "string",
    minWidth: 200,
    editable: false,
    valueFormatter: ({ value }) => value ? format(new Date(value), "PPpp") : "-",
  }

  export const Links: GridColDef<Document> = {
    field: "attachments",
    headerName: "Files",
    type: "string",
    minWidth: 200,
    editable: false,
    renderCell: ({ row }) => {
      const files = (row.attachments || []).map((attachment) => ({
        link: attachment.link || '',
        name: attachment.originalFileName || 'File name is not specified',
      }))
      return (
        <List dense sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
          {files.map((file, idx, arr) => (
            <ListItem
              key={idx}
              sx={{ display: "list-item" }}
              divider={idx === arr.length - 1 ? false : true}
            >
              <ListItemText
                primary={<OuterLink to={file.link}>{file.name}</OuterLink>}
              />
            </ListItem>
          ))}
        </List>
      )
    }
  }
}

import { useMutation, UseMutationOptions } from '@tanstack/react-query'
import { Entities } from 'src/enums'
import {
  ProductDraft,
  ProductsDraftsUpdate,
  ProductsDraftsUpdateResponse,
} from 'src/api'
import { useApiInstance } from './useApiInstance'

interface RequestParams {
  options?: Omit<
    UseMutationOptions<unknown, Error, ProductsDraftsUpdate[], ProductDraft>,
    'mutationKey' | 'mutationFn'
  >;
}

export const useEditProductDraftRequest = ({ options }: RequestParams) => {
  const api = useApiInstance()

  return useMutation<
    ProductsDraftsUpdateResponse[] | undefined,
    Error,
    ProductsDraftsUpdate[],
    ProductDraft
  >(
    [Entities.ProductsDrafts, 'edit'],
    async (payload: ProductsDraftsUpdate[]) => {
      const { data } = await api.v1AdminProductsDraftsPut(payload)
      return data as ProductsDraftsUpdateResponse[]
    },
    { ...options },
  )
}

import React, { FC } from 'react'
import { Grid, Typography } from '@mui/material'
import { Table, DynamicColumn } from 'src/components/ui'
import { Sensor, SensorCurrentConfig } from 'src/api'
import SensorColumns, { SensorConfigurationMeasurments } from 'src/components/columns/sensor'
import { getOpModeName, getReportTypeName } from 'src/components/columns/helpers'

const bleColumns: DynamicColumn<Sensor>[] = [
  SensorColumns.ConfigBleReportPeriod,
  SensorColumns.ConfigBleMonitorPeriod,
  SensorColumns.ConfigBleReportOffset,
  SensorColumns.ConfigBleOpMode,
  SensorColumns.ConfigBleReportType,
  SensorColumns.ConfigBleScanDuration,
  SensorColumns.ConfigBleMinSigStrength,
  SensorColumns.ConfigBleDataMode,
  SensorColumns.ConfigBleMaxNumScan,
  SensorColumns.ConfigBleManufacturerId,
]
const measurmentColumns: DynamicColumn<Measurment>[] = [
  SensorConfigurationMeasurments.MeasurmentName,
  SensorConfigurationMeasurments.MeasurmentMonitorPeriod,
  SensorConfigurationMeasurments.MeasurmentReportPeriod,
  SensorConfigurationMeasurments.MeasurmentReportOffset,
  SensorConfigurationMeasurments.MeasurmentOpMode,
  SensorConfigurationMeasurments.MeasurmentReportType
]

interface SensorConfigurationInfoProps {
  sensor: Sensor;
}
export interface Measurment {
  name: string;
  monitorPeriod: number | undefined | null;
  opMode: string;
  reportOffset: number | undefined | null;
  reportPeriod: number | undefined | null;
  reportType: string;
}
type GetMeasurmentRow = (data: SensorCurrentConfig) => Measurment
interface MeasurmentRows {
  temperature: GetMeasurmentRow
  humidity: GetMeasurmentRow
  pressure: GetMeasurmentRow
  light: GetMeasurmentRow
  location: GetMeasurmentRow
  accelerometer: GetMeasurmentRow
  gyro: GetMeasurmentRow
  battery: GetMeasurmentRow
  rfSignal: GetMeasurmentRow
}

const measurmentRows: MeasurmentRows = {
  temperature: (data) => ({
    name: 'Temperature',
    monitorPeriod: data.temperatureMonitorPeriod,
    opMode: getOpModeName(data.temperatureOpMode),
    reportType: getReportTypeName(data.temperatureReportType),
    reportOffset: data.temperatureReportOffset,
    reportPeriod: data.temperatureReportPeriod,
  }),
  humidity: (data) => ({
    name: 'Humidity',
    monitorPeriod: data.humidityMonitorPeriod,
    opMode: getOpModeName(data.humidityOpMode),
    reportType: getReportTypeName(data.humidityReportType),
    reportOffset: data.humidityReportOffset,
    reportPeriod: data.humidityReportPeriod,
  }),
  pressure: (data) => ({
    name: 'Pressure',
    monitorPeriod: data.pressureMonitorPeriod,
    opMode: getOpModeName(data.pressureOpMode),
    reportType: getReportTypeName(data.pressureReportType),
    reportOffset: data.pressureReportOffset,
    reportPeriod: data.pressureReportPeriod,
  }),
  light: (data) => ({
    name: 'Light',
    monitorPeriod: data.lightMonitorPeriod,
    opMode: getOpModeName(data.lightOpMode),
    reportType: getReportTypeName(data.lightReportType),
    reportOffset: data.lightReportOffset,
    reportPeriod: data.lightReportPeriod,
  }),
  location: (data) => ({
    name: 'Location',
    monitorPeriod: data.locationMonitorPeriod,
    opMode: getOpModeName(data.locationOpMode),
    reportType: getReportTypeName(data.locationReportType),
    reportOffset: data.locationReportOffset,
    reportPeriod: data.locationReportPeriod,
  }),
  accelerometer: (data) => ({
    name: 'Accelerometer',
    monitorPeriod: data.accelerometerMonitorPeriod,
    opMode: getOpModeName(data.accelerometerOpMode),
    reportType: getReportTypeName(data.accelerometerReportType),
    reportOffset: data.accelerometerReportOffset,
    reportPeriod: data.accelerometerReportPeriod,
  }),
  gyro: (data) => ({
    name: 'Gyroscope',
    monitorPeriod: data.gyroMonitorPeriod,
    opMode: getOpModeName(data.gyroOpMode),
    reportType: getReportTypeName(data.gyroReportType),
    reportOffset: data.gyroReportOffset,
    reportPeriod: data.gyroReportPeriod,
  }),
  battery: (data) => ({
    name: 'Battery',
    monitorPeriod: data.batteryMonitorPeriod,
    opMode: getOpModeName(data.batteryOpMode),
    reportType: getReportTypeName(data.batteryReportType),
    reportOffset: data.batteryReportOffset,
    reportPeriod: data.batteryReportPeriod,
  }),
  rfSignal: (data) => ({
    name: 'Singla Strength',
    monitorPeriod: data.rfSignalMonitorPeriod,
    opMode: getOpModeName(data.rfSignalOpMode),
    reportType: getReportTypeName(data.rfSignalReportType),
    reportOffset: data.rfSignalReportOffset,
    reportPeriod: data.rfSignalReportPeriod,
  }),
}

export const SensorConfigurationInfo: FC<SensorConfigurationInfoProps> = (props) => {
  const { sensor } = props
  if (!sensor) return null

  const measurmentData: Measurment[] = Object.values(measurmentRows).map((value: GetMeasurmentRow) => value(sensor.currentConfig as SensorCurrentConfig || {}))

  return (
    <Grid container spacing={3}>
      <Grid item container sx={{ mt: 2 }}>
        <Typography variant="body1" sx={{ fontWeight: 500 }}>Status of the sensor firmware - {sensor.lastSentConfig?.firmware === true ? "Requested" : "Not requested"}</Typography>
      </Grid>
      <Grid item container>
        <Typography variant="body1" sx={{ my: 2, fontWeight: 500 }}>BLE configuration</Typography>
        <Table
          columns={bleColumns}
          data={[sensor]}
          showFooter={false}
          sx={{ width: '100%' }}
        />
      </Grid>
      <Grid item container>
        <Typography variant="body1" sx={{ my: 2, fontWeight: 500 }}>Measurment values</Typography>
        <Table
          columns={measurmentColumns}
          data={measurmentData}
          showFooter={false}
          sx={{ width: '100%' }}
        />
      </Grid>
    </Grid>
  )
}

import { FC, ChangeEvent, useState, useEffect } from 'react'
import {
  GridRenderCellParams,
  useGridApiContext,
} from "@mui/x-data-grid-premium"
import { Grid, TextField, Autocomplete } from "@mui/material"

interface Props extends GridRenderCellParams<any, string> {}

export const BoardWidthEditInputCell: FC<Props> = ({
  id,
  value,
  field,
}) => {
  const [integer, setInteger] = useState<number>(0)
  const [fraction, setFraction] = useState<string>('')
  useEffect(() => {
    if (value) {
      const matchInteger = value.match(/^(\d{1,2}\s)|^\d{1,2}$/)
      const matchFraction = value.match(/\d\/\d{1,2}/)
      if (matchInteger) setInteger(parseInt(matchInteger[0], 10))
      if (matchFraction) setFraction(matchFraction[0])
    }
  }, [value])
  const apiRef = useGridApiContext()
  const onChangeInteger = (event: ChangeEvent<HTMLInputElement>) => {
    let newValue = event.target.value || '0'
    if (parseInt(newValue, 10) < 0) {
      newValue = '0'
    }
    setInteger(parseInt(newValue, 10))
    apiRef.current.setEditCellValue({ id, field, value: `${newValue} ${fraction}` })
  }
  const onChangeFraction = (rawValue: string | null) => {
    let finalValue = `${integer}`
    let fractionValue = ''
    if (rawValue !== null) {
      const fractionRegexp = new RegExp(/^\d{1}\/\d{1,2}$/)
      const newValue = rawValue || ''
      if (!fractionRegexp.test(newValue)) return

      fractionValue = newValue
      finalValue = `${integer} ${newValue}`
    }

    setFraction(fractionValue)
    apiRef.current.setEditCellValue({ id, field, value: finalValue })
  }
  const onBlur = () => {}
  const options = [
    '1/16',
    '1/8',
    '3/16',
    '1/4',
    '5/16',
    '3/8',
    '7/16',
    '1/2',
    '9/16',
    '5/8',
    '11/16',
    '3/4',
    '13/16',
    '7/8',
    '15/16',
  ]

  return (
    <Grid container flexDirection="row">
      <Grid item xs={4}>
        <TextField
          variant="outlined"
          margin="dense"
          type='number'
          onChange={onChangeInteger}
          onBlur={onBlur}
          value={integer}
        />
      </Grid>
      <Grid item xs={8}>
        <Autocomplete
          fullWidth
          freeSolo
          value={fraction}
          onChange={(_, newValue) => onChangeFraction(newValue)}
          onInputChange={(_, newValue) => onChangeFraction(newValue)}
          options={options}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              margin="dense"
            />
          )}
        />
      </Grid>
    </Grid>
  )
}

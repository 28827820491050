import React, { FC } from 'react'
import { TableCell } from '@mui/material'
import { PhoneLink } from '../link'

export interface PhoneCellProps {
  value: string;
  last: boolean;
  empty?: string;
}

export const PhoneCell: FC<PhoneCellProps> = ({ value, last, empty = '-' }) => {
  const align = last ? 'right' : 'left'
  return (
    <TableCell scope="row" align={align}>
      {value ? <PhoneLink value={value} /> : empty}
    </TableCell>
  )
}

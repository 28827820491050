import React, { FC, useState } from 'react'
import { Box, Button, Chip, FormControl, Grid, InputLabel, MenuItem, OutlinedInput, Select, SelectChangeEvent } from '@mui/material'
import { useAddPopupMessage } from 'src/hooks/ui'
import { useEditConfigurationParamRequest } from 'src/hooks/api'

interface EditableMobileSessionParamProps {
  id: number;
  value: string;
  configParamName?: string | null;
  onChange: () => void;
  onCancel: () => void;
}

export const EditableMobileSessionParam: FC<EditableMobileSessionParamProps> = ({
  id,
  value,
  configParamName,
  onChange,
  onCancel,
}) => {
  const [selectedMobileSession, setMobileSession] = useState<string>(value)
  const addMessage = useAddPopupMessage()
  const editConfigurationParamRequest = useEditConfigurationParamRequest({ id })
  const mobileSessionOptions = [
    { name: 'One', value: '1' },
    { name: 'Two', value: '2' },
  ]

  const onChangeMobileSession = (event: SelectChangeEvent<string>) => {
    setMobileSession(event.target.value)
  }

  const update = () => {
    editConfigurationParamRequest.mutate(
      { value: selectedMobileSession },
      {
        onSuccess: onChange,
        onError: (error) => addMessage({ type: 'error', text: error.message }),
      }
    )
  }

  return (
    <>
      <Grid item flexGrow={1}>
        <FormControl sx={{ m: 1, width: 400 }}>
          <InputLabel id={`${configParamName}-chip-label`}>{configParamName}</InputLabel>
          <Select
            labelId={`${configParamName}-chip-label`}
            id={`${configParamName}-chip`}
            value={selectedMobileSession}
            onChange={onChangeMobileSession}
            input={<OutlinedInput id="select-chip" label="Availability in the system" />}
            renderValue={(selected) => {
              const selectedOption = mobileSessionOptions.find((item) => `${item.value}` === selected)
              return (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                  <Chip key={`${selectedOption?.value}`} label={selectedOption?.name} />
                </Box>
              )
            }}
          >
            {
              mobileSessionOptions.map((option) => (
                <MenuItem
                  key={`${option?.value}`}
                  value={`${option?.value}`}
                >
                  {option?.name}
                </MenuItem>
              ))
            }
          </Select>
        </FormControl>
      </Grid>
      <Grid item flexShrink={0}>
        <Grid container spacing={1}>
          <Grid item>
            <Box pt={2}>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                onClick={update}
              >
                Save
              </Button>
            </Box>
          </Grid>
          {onCancel && <Grid item>
            <Box pt={2}>
              <Button
                variant="outlined"
                onClick={onCancel}
              >
                Cancel
              </Button>
            </Box>
          </Grid>}
        </Grid>
      </Grid>
    </>
  )
}

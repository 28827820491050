import React, { FC, useCallback, useEffect, useState } from 'react'
import * as Yup from 'yup'
import { Box, Button, CircularProgress, Grid, Typography } from '@mui/material'
import {
  useFormik,
  FormikContext,
  Form,
} from 'formik'
import { AutocompleteTextField, FormDropdown, HasSidebarLayout, TextField } from 'src/components'
import {
  useFilteredContractorListRequest,
  useFilteredManufacturerListRequest,
  useFilteredProductListRequest,
  useMessageTransportTypeListRequest,
  useMessageTypeListRequest,
  useSendNewMessage,
  useUserListRequest,
  useUserRoleListRequest,
} from 'src/hooks/api'
import {
  Contractor,
  Manufacturer,
  Product,
  SystemMessageAudienceType,
  SystemMessagePayload,
  SystemMessagePayloadMessageAudienceEnum,
  SystemMessagePayloadMessageTypeEnum,
  SystemMessagePayloadTransportTypeEnum,
  SystemMessageTransportType,
  SystemMessageType,
  User,
} from 'src/api'
import { useAddPopupMessage, useDropdownItemFactory } from 'src/hooks/ui'

interface NewMessageFormParams {
  messageTypes: SystemMessageType[]
  audienceTypes: SystemMessageAudienceType[]
  transportTypes: SystemMessageTransportType[]
  users: User[]
  sendMessage: (payload: SystemMessagePayload) => void
  isSending: boolean
  findProductByName: (name: string) => void
  isLoadingProduct: boolean
  products: Product[]
  contractors: Contractor[]
  manufacturers: Manufacturer[]
  findManufacturerByName: (name: string) => void
  findContractorByName: (name: string) => void
  isLoadingManufacturer: boolean
  isLoadingContractor: boolean
  loadContractors: () => void
  loadManufacturers: () => void
  loadProducts: () => void
  loadUsers: () => void
}

const validationSchema = () => {
  return Yup.object().shape(
    {
      title: Yup.string().required('Title is required'),
      message: Yup.string().required('Message is required'),
      transportType: Yup.string().required('Transport type is required'),
      messageType: Yup.string().required('Message type is required'),
      messageAudience: Yup.string().required('Audience type is required'),
      customParams: Yup.object().when('messageAudience', (messageAudience, schema) => {
        if (messageAudience === SystemMessagePayloadMessageAudienceEnum.CustomSet) {
          return schema.shape({
            userIds: Yup.array(Yup.number()).min(1, 'At least one user is required')
          }).required()
        }
        if (messageAudience === SystemMessagePayloadMessageAudienceEnum.UsersBySpecificProduct) {
          return schema.shape({
            productId: Yup.number().moreThan(0, 'Product is required')
          }).required()
        }
        if (messageAudience === SystemMessagePayloadMessageAudienceEnum.ManufacturerProductUsers) {
          return schema.shape({
            manufacturerId: Yup.number().moreThan(0, 'Manufacturer is required'),
          }).required()
        }
        if (messageAudience === SystemMessagePayloadMessageAudienceEnum.ContractorUsers) {
          return schema.shape({
            contractorId: Yup.number().moreThan(0, 'Contractor is required')
          }).required()
        }
      }),
    },
  )
}

const NewMessageForm: FC<NewMessageFormParams> = ({
  messageTypes,
  audienceTypes,
  transportTypes,
  sendMessage,
  isSending,
  users,
  isLoadingProduct,
  products,
  manufacturers,
  contractors,
  isLoadingContractor,
  isLoadingManufacturer,
  loadContractors,
  loadManufacturers,
  loadProducts,
  loadUsers,
}) => {
  const formik = useFormik<SystemMessagePayload>({
    validationSchema: validationSchema,
    initialValues: {
      title: '',
      message: '',
      transportType: SystemMessagePayloadTransportTypeEnum.Push,
      messageType: SystemMessagePayloadMessageTypeEnum.System,
      messageAudience: SystemMessagePayloadMessageAudienceEnum.All,
      customParams: {
        userIds: [],
        productId: 0,
        manufacturerId: 0,
        contractorId: 0,
      }

    },
    onSubmit: sendMessage,
    validateOnChange: true,
    validateOnBlur: true,
  })

  const { errors, values, setFieldValue } = formik
  const audience = useCallback(() => {
    const messageType = messageTypes.find((type) => type.name === values.messageType)
    if (!messageType) return []
    return audienceTypes.filter((type) => type.messageTypeId === messageType.id)
  }, [audienceTypes, messageTypes, values.messageType])()
  const isCustomSetOfUsers = useCallback(() => {
    return values.messageAudience === SystemMessagePayloadMessageAudienceEnum.CustomSet
  }, [values.messageAudience])()
  const isUserSetByProduct = useCallback(() => {
    return values.messageAudience === SystemMessagePayloadMessageAudienceEnum.UsersBySpecificProduct
  }, [values.messageAudience])()
  const isUserByManufacturerProduct = useCallback(() => {
    return values.messageAudience === SystemMessagePayloadMessageAudienceEnum.ManufacturerProductUsers
  }, [values.messageAudience])()
  const isUsersByContractor = useCallback(() => {
    return values.messageAudience === SystemMessagePayloadMessageAudienceEnum.ContractorUsers
  }, [values.messageAudience])()

  useEffect(() => {
    if (isCustomSetOfUsers && users.length === 0) loadUsers()
  }, [isCustomSetOfUsers, users])

  const dropdownItemListMap = useDropdownItemFactory({
    params: { labelProp: 'displayName', valueProp: 'name' },
  })
  const dropdownUsersItemListMap = useDropdownItemFactory({
    params: { labelProp: 'name', valueProp: 'id' },
  })

  const onChangeAudience = () => {
    setFieldValue('customParams.userIds', [])
    setFieldValue('customParams.manufacturerId', 0)
    setFieldValue('customParams.contractorId', 0)
  }
  const onChangeMessageType = () => {
    setFieldValue('messageAudience', '')
  }
  const onProductNameChange = (product: unknown) => {
    setFieldValue(
      'customParams.productId',
      product === null ? 0 : (product as Product).id,
      true
    )
  }
  const onManufacturerNameChange = (manufacturer: unknown) => {
    setFieldValue(
      'customParams.manufacturerId',
      manufacturer === null ? 0 : (manufacturer as Manufacturer).id,
      true
    )
  }
  const onContractorNameChange = (contractor: unknown) => {
    setFieldValue(
      'customParams.contractorId',
      contractor === null ? 0 : (contractor as Contractor).id,
      true
    )
  }
  const onOpenContractorList = () => {
    if (contractors.length === 0) loadContractors()
  }
  const onOpenManufacturerList = () => {
    if (manufacturers.length === 0) loadManufacturers()
  }
  const onOpenProductList = () => {
    if (products.length === 0) loadProducts()
  }

  return (
    <FormikContext.Provider value={formik}>
      <Form>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <TextField
              name="title"
              label="Title"
              required
              TextFieldProps={{
                helperText:
                  'Specify a title for the message',
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              name="message"
              label="Message"
              required
              TextFieldProps={{
                helperText: 'Specify a message',
                multiline: true,
                rows: 4
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <FormDropdown
              list={transportTypes}
              name="transportType"
              label="Transport type"
              required
              menuItemFactory={dropdownItemListMap}
            />
          </Grid>
          <Grid item xs={12}>
            <FormDropdown
              list={messageTypes}
              name="messageType"
              label="Message type"
              required
              menuItemFactory={dropdownItemListMap}
              onChange={onChangeMessageType}
            />
          </Grid>
          <Grid item xs={12}>
            <FormDropdown
              list={audience}
              name="messageAudience"
              label="Target Audience"
              required
              menuItemFactory={dropdownItemListMap}
              onChange={onChangeAudience}
            />
          </Grid>
          {isCustomSetOfUsers && (
            <Grid item xs={12}>
              <FormDropdown
                list={users}
                name="customParams.userIds"
                label="Users"
                menuItemFactory={dropdownUsersItemListMap}
                required
                multiple
              />
            </Grid>
          )}
          {isUserSetByProduct && (
            <Grid item xs={12}>
              <AutocompleteTextField
                label="Products"
                name="customParams.productId"
                labelField="name"
                valueField="id"
                options={products}
                onChange={onProductNameChange}
                isLoading={isLoadingProduct}
                initialValue={null}
                width="100%"
                required
                AutocompleteProps={{
                  onOpen: onOpenProductList,
                }}
              />
            </Grid>
          )}
          {isUserByManufacturerProduct && (
            <Grid item xs={12}>
              <AutocompleteTextField
                label="Manufacturers"
                name="customParams.manufacturerId"
                labelField="name"
                valueField="id"
                options={manufacturers}
                onChange={onManufacturerNameChange}
                isLoading={isLoadingManufacturer}
                initialValue={null}
                width="100%"
                required
                AutocompleteProps={{
                  onOpen: onOpenManufacturerList,
                }}
              />
            </Grid>
          )}
          {isUsersByContractor && (
            <Grid item xs={12}>
              <AutocompleteTextField
                label="Contractors"
                name="customParams.contractorId"
                labelField="name"
                valueField="id"
                options={contractors}
                onChange={onContractorNameChange}
                isLoading={isLoadingContractor}
                initialValue={null}
                width="100%"
                required
                AutocompleteProps={{
                  onOpen: onOpenContractorList,
                }}
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <Box pt={2}>
              <Button
                size="large"
                variant="contained"
                color="primary"
                type="submit"
                disabled={isSending || Object.keys(errors).length > 0}
              >
                {isSending ? <CircularProgress size={26} /> : "Send"}
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Form>
    </FormikContext.Provider>
  )
}

const NewMessage: FC = () => {
  document.title = 'Floorcloud Admin Panel - New message'
  const [contractors, setContractors] = useState<Contractor[]>([])
  const [manufacturers, setManufacturers] = useState<Manufacturer[]>([])
  const [products, setProducts] = useState<Product[]>([])
  const messageTypesRequest = useMessageTypeListRequest()
  const messageTransportTypeRequest = useMessageTransportTypeListRequest()
  const sendMessageRequest = useSendNewMessage()
  const messageTypes = messageTypesRequest.data || []
  const audienceTypes = messageTypes.map((type) => type.audience || []).flat()
  const transportTypes = messageTransportTypeRequest.data || []
  const payload = { limit: 99999, offset: 0, sort: 'name:asc' }
  const { data: userRolesResponse } = useUserRoleListRequest({
    params: { limit: 9999 },
  })
  const userRoles = userRolesResponse?.rows || []
  const { data: usersResponse, refetch: refetchUsers } = useUserListRequest({
    params: {
      ...(
        userRoles.length
          ? { roleIds: userRoles.filter((role) => !role.isAdmin).map((role) => role.id) }
          : {}
      ),
      ...payload,
    },
    options: {
      enabled: false,
    }
  })
  const users = usersResponse?.rows || []
  const productListRequest = useFilteredProductListRequest({
    options: {
      onSuccess(data) {
        const _products = (data?.rows || []).map((product) => (
          {
            ...product,
            name: `${product.manufacturer?.name}, ${product.name}`
          }
        ))
        setProducts(_products)
      },
    }
  })
  const manufacturerListRequest = useFilteredManufacturerListRequest({
    options: {
      onSuccess(data) {
        setManufacturers(data?.rows || [])
      },
    }
  })
  const contractorListRequest = useFilteredContractorListRequest({
    options: {
      onSuccess(data) {
        setContractors(data?.rows || [])
      },
    }
  })

  const addPopupMessage = useAddPopupMessage()
  const sendMessage = (payload: SystemMessagePayload) => {
    sendMessageRequest.mutate(payload, {
      onSuccess: () => {
        addPopupMessage({ text: "The message successfully sent", type: "success" })
      },
      onError: ({ message: text }) => {
        addPopupMessage({ text, type: "error" })
      }
    })
  }
  const findProductByName = (name: string) => {
    if (name?.length >= 3) productListRequest.mutate({ name })
  }
  const findManufacturerByName = (name: string) => {
    if (name?.length >= 3) manufacturerListRequest.mutate({ name })
  }
  const findContractorByName = (name: string) => {
    if (name?.length >= 3) contractorListRequest.mutate({ name })
  }

  return (
    <HasSidebarLayout>
      <Typography variant="h4">New Message</Typography>
      <Box width="500px" mt={2}>
        <NewMessageForm
          messageTypes={messageTypes.filter((item) => item.isActive)}
          audienceTypes={audienceTypes.filter((item) => item.isActive)}
          transportTypes={transportTypes.filter((item) => item.isActive)}
          sendMessage={sendMessage}
          isSending={sendMessageRequest.isLoading}
          users={users}
          findProductByName={findProductByName}
          isLoadingProduct={productListRequest.isLoading}
          products={products}
          manufacturers={manufacturers}
          findManufacturerByName={findManufacturerByName}
          isLoadingManufacturer={manufacturerListRequest.isLoading}
          contractors={contractors}
          findContractorByName={findContractorByName}
          isLoadingContractor={contractorListRequest.isLoading}
          loadContractors={() => contractorListRequest.mutate(payload)}
          loadManufacturers={() => manufacturerListRequest.mutate(payload)}
          loadProducts={() => productListRequest.mutate(payload)}
          loadUsers={refetchUsers}
        />
      </Box>
    </HasSidebarLayout>
  )
}

export default NewMessage

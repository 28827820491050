import React, { FC, MouseEvent } from "react"
import {
  ToggleButtonGroup,
  ToggleButton,
  ToggleButtonGroupProps as ToggleButtonGroupPropsInterface,
  ToggleButtonProps as ToggleButtonPropsInterface,
} from "@mui/material"
import { Field } from "formik"

export interface ButtonGroupItem {
  displayName: string;
  value: string;
  disabled?: boolean;
}

interface FormButtonGroupProps {
  list: ButtonGroupItem[];
  name: string;
  ToggleButtonGroupProps?: Omit<
    ToggleButtonGroupPropsInterface,
    "exclusive" | "color" | "value" | "onChange"
  >;
  ToggleButtonProps?: Omit<ToggleButtonPropsInterface, "disabled" | "value">;
  onChange?: (value: string) => void;
}

export const FormButtonGroup: FC<FormButtonGroupProps> = ({
  list,
  name,
  onChange,
  ToggleButtonGroupProps,
  ToggleButtonProps,
}) => {
  return (
    <Field name={name}>
      {({ field }: any) => {
        const commonChange = async (e: MouseEvent, result: string) => {
          if (!result) return
          await field.onChange(field.name)(result)
          if (onChange) onChange(result)
        }
        return (
          <ToggleButtonGroup
            value={field.value}
            exclusive
            color="primary"
            onChange={commonChange}
            sx={ToggleButtonGroupProps?.sx}
            {...ToggleButtonGroupProps}
          >
            {list.map(({ displayName, value, disabled }) => (
              <ToggleButton
                key={value}
                disabled={disabled}
                value={value}
                {...ToggleButtonProps}
              >
                {displayName}
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
        )
      }}
    </Field>
  )
}

import React, { FC, useCallback } from "react"
import { Box, Grid, Typography } from "@mui/material"
import { useNavigate, useParams } from "react-router-dom"
import {
  HasSidebarLayout,
  DataLoad,
  EntityHeader,
  WagnerProbeInfo,
  WagnerProbeChartTabs,
  WagnerProbeLastReadings,
  DisconnectProbeModal,
} from "src/components"
import { useDeleteRapidRhProbeRequest, useWagnerProbeSingleRequest } from "src/hooks/api"
import { WagnerProbe } from "src/api"
import { useAddPopupMessage, useDialog } from "src/hooks/ui"
import { DialogNames } from "src/enums"

interface WagnerProbeShowContentProps {
  wagnerProbe: WagnerProbe | undefined;
}

const WagnerProbeShowContent: FC<WagnerProbeShowContentProps> = ({ wagnerProbe }) => {
  const dialogName = DialogNames.GetSensorHistoricalData
  const { closeDialog } = useDialog(dialogName)
  const addMessage = useAddPopupMessage()
  const navigate = useNavigate()
  const deleteRapidRhProbeRequest = useDeleteRapidRhProbeRequest()

  const isOnlineProbe = useCallback(() => {
    let result: boolean | null = null
    if (wagnerProbe?.currentData?.readingTime) {
      const twoHours = 1000 * 60 * 60 * 2 // two hours in ms
      const differnce = Date.now() - new Date(wagnerProbe?.currentData?.readingTime).getTime()
      result = differnce > 0 && differnce <= twoHours
    }
    return result
  }, [wagnerProbe])()
  if (!wagnerProbe) return null

  const deleteProbe = (probeId: string): void => {
    deleteRapidRhProbeRequest.mutate({
      probeId
    }, {
      onSuccess: () => {
        addMessage({ text: 'The probe deleted successfully', type: 'success' })
        closeDialog()
        navigate(-1)
      },
      onError: ({ message }: { message: string }) => {
        addMessage({ text: message, type: 'error' })
      }
    })
  }

  const title = `Wagner Probe: ${wagnerProbe.probeId}`
  return (
    <>
      <Grid container direction="column" spacing={4}>
        <Grid item>
          <EntityHeader
            title={title}
            rightIcon={
              <Box
                sx={{
                  width: 15,
                  height: 15,
                  borderRadius: "50%",
                  display: isOnlineProbe === null ? "none" : "inline-block",
                  backgroundColor: isOnlineProbe ? "green" : "red",
                  borderColor: isOnlineProbe ? "green" : "red",
                  ...(isOnlineProbe ? { animation: "pulsate 1.1s infinite alternate" } : {}),
                  "@keyframes pulsate": {
                    from: {
                      opacity: 1,
                    },
                    to: {
                      opacity: 0.1,
                    }
                  }
                }}
              />
            }
            actionBlockOnTheRight={<DisconnectProbeModal
              rapidRhProbe={wagnerProbe}
              onDisconnectProbe={deleteProbe}
              isLoading={false}
            />}
          />
        </Grid>
        <Grid item>
          <Typography variant="h6" sx={{ mb: 1 }}>
            Wagner Probe Information
          </Typography>
          <WagnerProbeInfo wagnerProbe={wagnerProbe} />
        </Grid>
        <Grid item>
          <Typography variant="h6" sx={{ mb: 1 }}>
            Wagner Probe Chart Of Readings
          </Typography>
          <WagnerProbeChartTabs wagnerProbe={wagnerProbe} />
        </Grid>
        <Grid item>
          <Typography variant="h6" sx={{ mb: 1 }}>
            Wagner Probe Last Readings
          </Typography>
          <WagnerProbeLastReadings wagnerProbe={wagnerProbe} />
        </Grid>
      </Grid>
    </>
  )
}

const WagnerProbeShow: FC = () => {
  const params = useParams()
  const { probeId } = params as { probeId: string }
  const { data, isInitialLoading, isError, error } = useWagnerProbeSingleRequest({ probeId })
  document.title = `Floorcloud Admin Panel - Rapid RH probe ${data?.probeId || ''}`

  return (
    <HasSidebarLayout>
      <DataLoad
        isLoading={isInitialLoading}
        isError={isError}
        errorMessage={error?.message}
      >
        <WagnerProbeShowContent wagnerProbe={data} />
      </DataLoad>
    </HasSidebarLayout>
  )
}

export default WagnerProbeShow

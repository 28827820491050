import React, { FC } from 'react'
import { Box, BoxProps } from '@mui/material'

export const RootContainer: FC<BoxProps> = ({ children, ...restProps }) => {
  return (
    <Box display="flex" width="100vw" minHeight="100vh" {...restProps}>
      {children}
    </Box>
  )
}

import React, { FC, useState, SyntheticEvent, useEffect } from 'react'
import * as Yup from 'yup'
import {
  Typography,
  Grid,
  Button,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  IconButton,
} from '@mui/material'
import { ExpandMore } from '@mui/icons-material'
import {
  useFormik,
  FormikContext,
} from 'formik'
import { ProductType, ProductTipType, ProductTypeTipsUpdateTips } from 'src/api'
import { TipList } from 'src/components/forms/product'
import { useEditProductTypeRequest, useUpdateProductTypeTipRequest } from 'src/hooks/api'
import { useAddPopupMessage } from 'src/hooks/ui'
import { Switch } from 'src/components'

const validationSchema = Yup.object().shape(
  {
    commonTipList: Yup.array().of(
      Yup.object().shape({
        content: Yup.string().required('Tip field should be filled'),
      })
    ),
    safetyTipList: Yup.array().of(
      Yup.object().shape({
        content: Yup.string().required('Safety tip field should be filled'),
      })
    ),
    newInstallationTip: Yup.string(),
    newSafetyTip: Yup.string(),
  },
  []
)

interface InlineProductTypeProps {
  productType: ProductType
  productTipTypes: ProductTipType[]
  canChange: boolean;
  onEdit: (id: number) => void;
  onDelete: (params: { id: number; name: string }) => void;
  refetchProductTypes: () => void
}

export const InlineProductType: FC<InlineProductTypeProps> = (props) => {
  const [expanded, setExpanded] = useState<boolean>(false)
  const [isSuitableForWood, setIsSuitableForWood] = useState<boolean>(false)
  const addMessage = useAddPopupMessage()
  const { productType, canChange, productTipTypes, onEdit, onDelete, refetchProductTypes } = props
  const { id, name, tips } = productType
  const commonTipType = productTipTypes.find((type) => type.name === 'common')
  const safetyTipType = productTipTypes.find((type) => type.name === 'safety')
  const formik = useFormik({
    validationSchema: validationSchema,
    initialValues: {
      commonTipList: tips?.filter((tip) => tip.type?.name === 'common') || [],
      safetyTipList: tips?.filter((tip) => tip.type?.name === 'safety') || [],
    },
    onSubmit: () => { },
    validateOnChange: false,
    validateOnBlur: true,
  })
  const updateProductTypeTipRequest = useUpdateProductTypeTipRequest({
    productTypeId: productType.id,
  })
  const editProductTypeRequest = useEditProductTypeRequest({ id })

  useEffect(() => {
    setIsSuitableForWood(productType.isSuitableForWoodMeasurements)
  }, [productType])

  const onSaveTips = () => {
    const { values } = formik
    const installTips: ProductTypeTipsUpdateTips[] = values.commonTipList.map((tip) => ({
      id: tip.id,
      content: tip.content,
      productTypeId: tip.productTypeId || productType.id,
      typeId: (tip.typeId || commonTipType?.id) as number,
    }))
    const safetyTips: ProductTypeTipsUpdateTips[] = values.safetyTipList.map((tip) => ({
      id: tip.id,
      content: tip.content,
      productTypeId: tip.productTypeId || productType.id,
      typeId: (tip.typeId || safetyTipType?.id) as number,
    }))
    const _tips = [...installTips, ...safetyTips]
    updateProductTypeTipRequest.mutate(
      { tips: _tips },
      {
        onSuccess: () => {
          addMessage({ type: 'success', text: 'The tips were successfully saved' })
          setExpanded(false)
          refetchProductTypes()
        },
        onError: (error) => addMessage({ type: 'error', text: error.message }),
      }
    )
  }
  const removeProductType = (event: SyntheticEvent) => {
    event.stopPropagation()
    onDelete({ id, name })
  }
  const editProductType = (event: SyntheticEvent) => {
    event.stopPropagation()
    onEdit(id)
  }
  const onChangeWoodMeasurmenets = (_: any, checked: boolean) => {
    setIsSuitableForWood(checked)
    editProductTypeRequest.mutate(
      { isSuitableForWoodMeasurements: checked },
      {
        onError: (error) => {
          addMessage({ type: 'error', text: error.message })
          setIsSuitableForWood(!checked)
        },
      }
    )
  }

  return (
    <FormikContext.Provider value={formik}>
      <Box width="100%">
        <Accordion expanded={expanded} onChange={() => setExpanded(!expanded)}>
          <AccordionSummary
            expandIcon={<IconButton
              role="button"
              size="large"
            >
              <ExpandMore />
            </IconButton>}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <Typography sx={{ width: '85%', flexShrink: 0 }}>
              {name}
            </Typography>
            <Grid container justifyContent="space-around">
              <Grid item>
                <Button
                  variant="contained"
                  disabled={!canChange}
                  onClick={editProductType}
                >
                  Edit
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="outlined"
                  color="error"
                  disabled={!canChange}
                  onClick={removeProductType}
                >
                  Delete
                </Button>
              </Grid>
            </Grid>
          </AccordionSummary>
          <AccordionDetails>
            <Box my={2}>
              <Typography variant="h6" sx={{ mb: 1 }}>
                Wood measurements
                <Typography variant="body2" component="span">
                  {' '}(applied immediately)
                </Typography>
              </Typography>
              <Switch
                name={`${name}-visibility`}
                label={isSuitableForWood ? 'Yes' : 'No'}
                SwitchProps={{
                  checked: isSuitableForWood,
                  onChange: onChangeWoodMeasurmenets
                }}
              />
            </Box>
            {commonTipType && (
              <>
                <Typography variant="h6" sx={{ mb: 2 }}>
                  Install Tips
                </Typography>
                <TipList
                  typeId={commonTipType.id}
                  type="common"
                  inputPlaceholder="Tip description"
                />
              </>
            )}
            {commonTipType && safetyTipType && <Box mt={2} />}
            {safetyTipType && (
              <>
                <Typography variant="h6" sx={{ mb: 2 }}>
                  Safety Tips
                </Typography>
                <TipList
                  typeId={safetyTipType.id}
                  type="safety"
                  inputPlaceholder="Safety tip description"
                />
              </>

            )}
            <Grid item mt={2}>
              <Button
                variant="contained"
                disabled={updateProductTypeTipRequest.isLoading}
                onClick={onSaveTips}
              >
                Save
              </Button>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Box>
    </FormikContext.Provider>
  )
}
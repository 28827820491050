import React, { FC, useState, Fragment } from 'react'
import {
  Grid,
} from '@mui/material'
import {
  useDeleteProductTypeRequest,
} from 'src/hooks/api'
import { ProductTipType, ProductType } from 'src/api'
import { useAddPopupMessage, useDialog } from 'src/hooks/ui'
import { DialogNames } from 'src/enums'

import { InlineProductType } from './inlineProductType'
import { EditableProductType } from './editableProductType'
import { RemoveProductTypeDialog } from './removeProductTypeDialog'

interface ProductTypeListProps {
  productTypes: ProductType[];
  productTipTypes: ProductTipType[];
  onChange: () => void;
}

export const ProductTypeList: FC<ProductTypeListProps> = ({ productTypes, productTipTypes, onChange }) => {
  const [editId, setEditId] = useState<number | null>(null)
  const [removeItem, setRemoveItem] = useState<{
    id: number;
    name: string;
  } | null>(null)
  const addMessage = useAddPopupMessage()
  const { openDialog, closeDialog } = useDialog(DialogNames.DeleteProductType)

  const removeProductTypeRequest = useDeleteProductTypeRequest()

  const openRemoveDialog = (type: { id: number; name: string }) => {
    setRemoveItem(type)
    openDialog()
  }

  const closeRemoveDialog = () => {
    closeDialog(() => setRemoveItem(null))
  }

  const onRemove = () => {
    if (removeItem !== null) {
      removeProductTypeRequest.mutate(
        { id: removeItem.id },
        {
          onSuccess: onChange,
          onError: (error) =>
            addMessage({ type: 'error', text: error.message }),
        }
      )
      closeRemoveDialog()
    }
  }

  const updateEditId = (id: number | null) => {
    setEditId(id)
  }

  const saveChanges = () => {
    updateEditId(null)
    onChange()
  }

  return (
    <>
      <Grid container direction="column" spacing={2}>
        {productTypes.map((productType) => (
          <Fragment key={productType.id}>
            <Grid item container spacing={1} alignItems="center" mt={1}>
              {editId === productType.id ? (
                <EditableProductType
                  id={productType.id}
                  name={productType.name || ''}
                  onChange={saveChanges}
                  onCancel={() => updateEditId(null)}
                />
              ) : (
                <InlineProductType
                  productType={productType}
                  productTipTypes={productTipTypes}
                  canChange={productType.productsCount === 0}
                  onEdit={updateEditId}
                  onDelete={openRemoveDialog}
                  refetchProductTypes={onChange}
                />
              )}
            </Grid>
          </Fragment>
        ))}
      </Grid>
      <RemoveProductTypeDialog
        name={removeItem?.name || ''}
        onDelete={onRemove}
        onCancel={closeRemoveDialog}
      />
    </>
  )
}
import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { Entities } from 'src/enums'
import { WagnerProbeReading } from 'src/api'
import { useApiInstance } from './useApiInstance'

interface RequestParams {
  wagnerProbeId: string;
  params?: {
    timeFrom?: string;
    timeTo?: string;
  };
  options?: Omit<
    UseQueryOptions<WagnerProbeReading[], Error>,
    'queryKey' | 'queryFn'
  >;
}

export const useWagnerProbeReadingsRequest = ({
  wagnerProbeId,
  params = {},
  options,
}: RequestParams) => {
  const api = useApiInstance()
  return useQuery<WagnerProbeReading[], Error>(
    [Entities.WagnerProbeReadings, wagnerProbeId],
    async () => {
      const { timeFrom, timeTo } = params
      const { data } = await api.v1AdminWagnerProbesProbeIdReadingsGet(
        wagnerProbeId,
        timeFrom,
        timeTo,
      )
      return data as WagnerProbeReading[]
    },
    options,
  )
}

import { FC } from 'react'
import Grid from '@mui/material/Grid'
import { SearchForm } from 'src/components/forms'

export const AlertFilters: FC = () => {
  return (
    <Grid item container direction="column" alignItems="stretch" spacing={2}>
      <Grid item>
        <SearchForm />
      </Grid>
    </Grid>
  )
}

import { GridColDef } from "@mui/x-data-grid-premium"
import format from "date-fns/format"
import { InnerLink } from "src/components/ui"
import { SectionSensorRow } from './sensorInfo'

export const useSectionSensorColumns = (): GridColDef<SectionSensorRow>[] => ([
  {
    field: "deviceId",
    headerName: "Device ID",
    type: "string",
    minWidth: 200,
    editable: false,
    renderCell: ({ value, row }) => (
      <InnerLink to={`/sensors/${row.uniqueId}`}>{value}</InnerLink>
    )
  },
  {
    field: "event",
    headerName: "Event",
    type: "string",
    minWidth: 200,
    editable: false,
  },
  {
    field: "time",
    headerName: "Datetime",
    type: "string",
    minWidth: 200,
    editable: false,
    valueFormatter: ({ value }) => value ? format(new Date(value), "PPpp") : "-",
  },
])

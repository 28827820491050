import React, { FC } from 'react'
import { Grid } from '@mui/material'
import { useAddPopupMessage } from 'src/hooks/ui'
import { useNewProductTypeRequest } from 'src/hooks/api'
import { ProductTypeNewForm } from 'src/components/forms'

interface NewBuildingTypeProps {
  onCreate: () => void;
}

export const NewProductType: FC<NewBuildingTypeProps> = ({ onCreate }) => {
  const addMessage = useAddPopupMessage()

  const newProductTypeRequest = useNewProductTypeRequest()
  const createProductType = ({ displayName }: { displayName: string }) => {
    newProductTypeRequest.mutate(
      { name: displayName },
      {
        onSuccess: () => onCreate(),
        onError: (error) => addMessage({ type: 'error', text: error.message }),
      }
    )
  }

  return (
    <>
      <Grid item flexGrow={1} sx={{ pt: 1, pb: 2 }}>
        <ProductTypeNewForm onSubmit={createProductType} />
      </Grid>
    </>
  )
}

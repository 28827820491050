import React, { useCallback, useContext, useState } from "react"
import { Button, Grid } from "@mui/material"
import pluralize from "pluralize"
import { DownloadImportTemplateButton, UploadXlsxButton } from "src/components"
import { useMergeProductsRequest } from "src/hooks/api"
import { useAddPopupMessage } from "src/hooks/ui"
import { ProductDraft } from "src/api"
import { MergeProductsConfirmationModal } from "./mergeProductsConfirmationModal"
import { MergeProductsModal } from "./mergeProductsModal"
import { ProductPublishConfirmationModal } from "./productPublishConfirmationModal"
import { AddNewProductDraft } from "./addNewProductDraft"
import { ManufacturerSettingsIconButton } from "./manufacturerSettingsIconButton"
import { ManufacturerDropdown } from "./manufacturerDropdown"
import { EditSelectedProductDrafts } from "../../forms/productDraft/editSelectedProductDrafts"
import { ProductDraftsContext } from "../../../contexts/productDraftsContext"

export const ProductDraftListActionsPanel = () => {
  const {
    manufacturerId,
    manufacturers,
    changeManufacturerId,
    selectedProductDraftIds,
    setSelectedProductDraftIds,
    publishSelectedDrafts,
    productDrafts,
    refetchProductDrafts,
  } = useContext(ProductDraftsContext)

  const mergeProductsRequest = useMergeProductsRequest()

  const [targetProductDraftId, setTargetProductDraftId] = useState<number | null>(null)

  const [confirmationModalOpened, setConfirmationModalOpened] = useState(false)

  const [mergeModalOpened, setMergeModalOpened] = useState<boolean>(false)

  const [mergeConfirmationModalOpened, setMergeConfirmationModalOpened] = useState<boolean>(false)

  const hasSelection = selectedProductDraftIds.length > 0

  const moreThanOneSelectedProducts = selectedProductDraftIds.length > 1

  const addMessage = useAddPopupMessage()

  const handlePublishAll = () => {
    setConfirmationModalOpened(true)
  }

  const handleConfirmPublishAll = () => {
    publishSelectedDrafts()
    setSelectedProductDraftIds([])
    setConfirmationModalOpened(false)
  }

  const handleRejectPublishAll = () => {
    setConfirmationModalOpened(false)
  }

  const handleMergeProducts = () => {
    setMergeModalOpened(true)
  }

  const handleRejectMergeProducts = () => {
    setMergeModalOpened(false)
    setTargetProductDraftId(null)
  }

  const handleConfirmMergeModal = () => {
    setMergeModalOpened(false)
    setMergeConfirmationModalOpened(true)
  }

  const handleRejectMergeProductsConfirmation = () => {
    setMergeConfirmationModalOpened(false)
    setMergeModalOpened(true)
  }

  const handleConfirmMergeConfirmationModal = () => {
    if (!targetProductDraftId) {
      addMessage({ text: 'The target product is not selected', type: 'error' })
      return
    }
    mergeProductsRequest.mutate({
      targetProductDraftId,
      mergedProductDraftsIds: mergedProductDrafts.map((item) => item.id)
    }, {
      onSuccess: () => {
        setMergeConfirmationModalOpened(false)
        addMessage({ text: 'The products successfully merged', type: 'success' })
        setTargetProductDraftId(null)
        refetchProductDrafts()
      },
      onError: ({ message: text }) => {
        addMessage({ text, type: 'error' })
      }
    })
  }

  const assignManufacturer = useCallback((productDraft: ProductDraft): ProductDraft => {
    const manufacturer = manufacturers.find((_manufacturer) => productDraft.manufacturerId === _manufacturer.id)
    Object.assign(productDraft, {
      original: {
        ...productDraft.original,
        manufacturer: manufacturer || {}
      }
    })
    return productDraft
  }, [manufacturers])

  const selectedProductDraft: ProductDraft | null = useCallback(() => {
    const productDraft = productDrafts.find((draft) => draft.id === targetProductDraftId) || null
    if (!productDraft) return productDraft

    return assignManufacturer(productDraft)
  }, [productDrafts, targetProductDraftId, assignManufacturer])()

  const mergedProductDrafts: ProductDraft[] = useCallback(() => {
    let _selectedProductDraftIds = selectedProductDraftIds.slice()
    if (targetProductDraftId) {
      _selectedProductDraftIds = _selectedProductDraftIds.filter((id) => id !== targetProductDraftId)
    }
    return productDrafts.filter((productDraft) => (
      _selectedProductDraftIds.includes(productDraft.id)
    )).map((productDraft) => assignManufacturer(productDraft))
  }, [productDrafts, selectedProductDraftIds, targetProductDraftId, assignManufacturer])()

  return (
    <>
      <Grid
        container
        alignItems="center"
        justifyContent="space-between"
        spacing={2}
      >
        <Grid item>
          <Grid container alignItems="center" spacing={1}>
            <Grid item>
              <ManufacturerDropdown
                value={manufacturerId}
                list={manufacturers}
                onChange={changeManufacturerId}
              />
            </Grid>
            {manufacturerId && (
              <Grid item>
                <ManufacturerSettingsIconButton
                  manufacturerId={manufacturerId}
                />
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid item>
          <Grid container alignItems="center" spacing={1}>
            {hasSelection && (
              <>
                <Grid item>
                  {pluralize("product", selectedProductDraftIds.length, true)}{" "}
                  selected
                </Grid>
                {moreThanOneSelectedProducts && (
                  <Grid item>
                    <Button variant="text" onClick={handleMergeProducts}>
                      Merge products
                    </Button>
                  </Grid>
                )}
                <Grid item>
                  <Button variant="contained" onClick={handlePublishAll}>
                    Publish all
                  </Button>
                </Grid>
                <Grid item>
                  <EditSelectedProductDrafts />
                </Grid>
              </>
            )}
            {!hasSelection && (
              <>
                <Grid item>
                  <AddNewProductDraft />
                </Grid>
                <Grid item>
                  <UploadXlsxButton />
                </Grid>
                <Grid item>
                  <DownloadImportTemplateButton />
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
      </Grid>
      <ProductPublishConfirmationModal
        open={confirmationModalOpened}
        productsCount={selectedProductDraftIds.length}
        onSave={handleConfirmPublishAll}
        onCancel={handleRejectPublishAll}
      />
      <MergeProductsModal
        open={mergeModalOpened}
        onSave={handleConfirmMergeModal}
        onCancel={handleRejectMergeProducts}
        productDrafts={productDrafts}
        selectedProductDraftIds={selectedProductDraftIds}
        manufacturers={manufacturers}
        targetProductDraftId={targetProductDraftId}
        setTargetProductDraftId={setTargetProductDraftId}
      />
      <MergeProductsConfirmationModal
        open={mergeConfirmationModalOpened}
        onSave={handleConfirmMergeConfirmationModal}
        onCancel={handleRejectMergeProductsConfirmation}
        selectedProductDraft={selectedProductDraft}
        mergedProductDrafts={mergedProductDrafts}
        isLoading={mergeProductsRequest.isLoading}
      />
    </>
  )
}

import { FC } from 'react'
import Grid from '@mui/material/Grid'
import { SearchForm } from 'src/components/forms'
import { HideArchivedTasksFilter } from './hideArchivedTasksFilter'

export const TaskFilters: FC = () => {
  return (
    <Grid item container direction="column" alignItems="stretch" spacing={2}>
      <Grid item>
        <SearchForm />
      </Grid>
      <Grid item>
        <HideArchivedTasksFilter />
      </Grid>
    </Grid>
  )
}

import { useNavigate, useLocation } from 'react-router-dom'

export const useSignInRedirect = () => {
  const location = useLocation()
  const navigate = useNavigate()
  return () => {
    const { search, pathname } = location
    const params = new URLSearchParams(search)
    let redirect = params.get('redirect')
    if (redirect === null) redirect = pathname !== '/sign-in' ? `${pathname}${search || ''}` : ''
    navigate(
      `/sign-in${redirect ? `?redirect=${encodeURIComponent(redirect)}` : ''}`,
      { replace: true }
    )
  }
}

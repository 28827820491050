import { useMutation, UseMutationOptions } from '@tanstack/react-query'
import { Entities } from 'src/enums'
import { Label, LabelCreation } from 'src/api'
import { useApiInstance } from './useApiInstance'

interface RequestParams {
  options?: Omit<UseMutationOptions<Label, Error, LabelCreation, unknown>, 'mutationKey' | 'mutationFn'>
}

export const useNewLabelRequest = ({
  options,
}: RequestParams = {}) => {
  const api = useApiInstance()

  return useMutation<Label, Error, LabelCreation, unknown>(
    [Entities.Contractors, 'create'],
    async (payload: LabelCreation) => {
      const { data } = await api.v1AdminLabelsPost(payload)
      return data as Label
    },
    { ...options },
  )
}

import React, { FC, useEffect } from "react"
import Button from "@mui/material/Button"
import CircularProgress from "@mui/material/CircularProgress"
import Grid from "@mui/material/Grid"
import Typography from "@mui/material/Typography"
import { GridColDef } from "@mui/x-data-grid-premium"
import { SensorColumnsDataGrid } from "src/components/columns/sensor"
import { Sensor } from "src/api"
import { useAddPopupMessage, useQueryParams } from "src/hooks/ui"
import {
  SearchParamsKeys,
  ViewTypes,
} from "src/enums"
import { usePatchSensorRequest, useSensorTechnicalStatusRequest } from "src/hooks/api"
import { CommonSensorList } from "./commonSensorsList"

interface SensorDiagnosticsListProps {
  isLoading: boolean;
  count: number;
  data: Sensor[];
  refetchSensorList: () => void;
}

export const SensorDiagnosticsList: FC<SensorDiagnosticsListProps> = ({
  data,
  isLoading,
  count,
  refetchSensorList,
}) => {
  const [queryParams, setParams] = useQueryParams()
  const addMessage = useAddPopupMessage()
  const technicalStatusRequest = useSensorTechnicalStatusRequest()
  const updateSensorRequest = usePatchSensorRequest()

  const columns: GridColDef<Sensor>[] = [
    SensorColumnsDataGrid.Id,
    SensorColumnsDataGrid.IMEI,
    SensorColumnsDataGrid.Contractor,
    SensorColumnsDataGrid.Project,
    SensorColumnsDataGrid.FirmwareVersion,
    SensorColumnsDataGrid.TechnicalStatus,
    SensorColumnsDataGrid.EnabledBluetooth,
    SensorColumnsDataGrid.Comment,
  ]

  useEffect(() => {
    setParams({
      ...queryParams,
      [SearchParamsKeys.ViewType]: ViewTypes.List,
    })
  }, [])

  const calculateTechnicalStatus = () => {
    addMessage({ text: "In the process, it can take a few minutes, no problem you can return later", type: "info" })
    technicalStatusRequest.mutate(void 0, {
      onSuccess: () => {
        addMessage({ text: "Success", type: "success" })
        refetchSensorList()
      },
      onError: (error) => {
        addMessage({ text: error?.message, type: "error" })
      }
    })
  }

  const processRowUpdate = async (
    newRow: Sensor,
    oldRow: Sensor,
  ) => {
    const { comment: newComment } = newRow
    const { comment: oldComment } = oldRow
    const updatedRow: Sensor = await new Promise((resolve) => {
      if (newComment !== oldComment) {
        updateSensorRequest.mutate({
          uniqueId: newRow.uniqueId,
          payload: { comment: newComment },
        }, {
          onSuccess: () => {
            addMessage({ text: "The comment updated", type: "success" })
            resolve(newRow)
          },
          onError: (error) => {
            addMessage({ text: error?.message, type: "error" })
            resolve(oldRow)
          }
        })
      }
      return newRow
    })
    return updatedRow
  }

  return (
    <>
      <Grid container justifyContent="space-between" py={2}>
        <Grid item>
          <Typography variant="h6" mb={1}>Legend</Typography>
          <Typography variant="body2" mb={0.5}><strong>OK</strong> - The sensor does not have any gaps in the last 12 hours</Typography>
          <Typography variant="body2" mb={0.5}><strong>Not bad</strong> - The sensor has at least 10 received readings in the last 12 hours</Typography>
          <Typography variant="body2" mb={0.5}><strong>Unstable</strong> - The sensor has at least 6 received readings in the last 12 hours</Typography>
          <Typography variant="body2" mb={0.5}><strong>Offline</strong> - The sensor does not have any readings in the last 12 hours</Typography>
          <Typography variant="body2" mb={0.5}><strong>Failed</strong> - The sensor does not have any readings in the last 2 hours</Typography>
        </Grid>
        <Grid item>
          <Button
            size="large"
            variant="contained"
            color="primary"
            onClick={calculateTechnicalStatus}
            disabled={technicalStatusRequest.isLoading}
          >
            {
              technicalStatusRequest.isLoading ? <CircularProgress color="info" size={26} /> : 'Check status'
            }
          </Button>
        </Grid>
      </Grid>
      <CommonSensorList
        columns={columns}
        count={count}
        data={data}
        isLoading={isLoading}
        processRowUpdate={processRowUpdate}
      />
    </>
  )
}

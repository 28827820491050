import {
  GridColDef,
} from "@mui/x-data-grid-premium"
import { Contractor } from "src/api"
import { ContractorColumnsDataGrid } from "src/components/columns/contractor"

export const contractorTableColumns = (): GridColDef<Contractor>[] => {
  return [
    ContractorColumnsDataGrid.Photo,
    ContractorColumnsDataGrid.Name,
    ContractorColumnsDataGrid.ContractorType,
    ContractorColumnsDataGrid.RootContractor,
    ContractorColumnsDataGrid.DashboardStatus,
    ContractorColumnsDataGrid.Address,
    ContractorColumnsDataGrid.Phone,
    ContractorColumnsDataGrid.Email,
    ContractorColumnsDataGrid.Projects,
    ContractorColumnsDataGrid.Sensors,
    ContractorColumnsDataGrid.UsersAmount,
    ContractorColumnsDataGrid.Activation,
    ContractorColumnsDataGrid.Subscription,
    ContractorColumnsDataGrid.Labels,
    ContractorColumnsDataGrid.CreatedAt,
  ]
}

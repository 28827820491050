import React, { FC, useCallback, useEffect, useRef, useState } from "react"
import { ApexOptions } from "apexcharts"
import { Box } from "@mui/system"
import { Grid, IconButton, Typography, Button } from "@mui/material"
import ArrowBackIcon from '@mui/icons-material/ArrowBackRounded'
import ArrowForwardIcon from '@mui/icons-material/ArrowForwardRounded'
import CloseIcon from '@mui/icons-material/CloseRounded'
import format from 'date-fns/format'
import endOfWeek from 'date-fns/endOfWeek'
import startOfWeek from 'date-fns/startOfWeek'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { TabGroupFilter, SensorChart, getCustomTooltip } from "src/components"
import { WagnerProbe, WagnerProbeReading } from "src/api"
import { SearchParamsKeys } from "src/enums"
import { useWagnerProbeReadingsRequest } from "src/hooks/api"
import { useOutsideAlerter } from "src/hooks/ui"

type ApexChartDataObj = {
  x: any;
  y: any;
  fillColor?: string;
  strokeColor?: string;
  meta?: any;
  goals?: any;
};

type ApexChartData =
  | (number | null)[]
  | ApexChartDataObj[]
  | [number, number | null][]
  | [number, (number | null)[]][];

const chartOfReadingsTabs = ({
  humidityData,
  temperatureData,
  signalStrength,
  batteryLevel,
  repeaterBatteryLevel,
  isLoading,
}: {
  humidityData: ApexOptions["series"];
  temperatureData: ApexOptions["series"];
  signalStrength: ApexOptions["series"];
  batteryLevel: ApexOptions["series"];
  repeaterBatteryLevel: ApexOptions["series"];
  isLoading: boolean;
}) => {
  return [
    {
      status: "Temperature",
      content: <SensorChart
        data={temperatureData}
        isLoading={isLoading}
        options={{
          tooltip: {
            custom: getCustomTooltip
          }
        }}
      />,
    },
    {
      status: "Humidity",
      content: <SensorChart
        data={humidityData}
        options={{
          yaxis: {
            min: 0,
            max: 100,
          },
          tooltip: {
            custom: getCustomTooltip
          }
        }}
        isLoading={isLoading}
      />,
    },
    {
      status: "Signal Strength",
      content: <SensorChart
        data={signalStrength}
        options={{
          yaxis: {
            min: -150,
            max: 0,
          },
          tooltip: {
            custom: getCustomTooltip
          }
        }}
        isLoading={isLoading}
      />,
    },
    {
      status: "Battery Level",
      content: <SensorChart
        data={batteryLevel}
        options={{
          yaxis: {
            min: 0,
            max: 100,
          },
          tooltip: {
            custom: getCustomTooltip
          }
        }}
        isLoading={isLoading}
      />,
    },
    {
      status: "Repeater Battery Level",
      content: <SensorChart
        data={repeaterBatteryLevel}
        options={{
          yaxis: {
            min: 0,
            max: 100,
          },
          tooltip: {
            custom: getCustomTooltip
          }
        }}
        isLoading={isLoading}
      />,
    }
  ]
}

interface WagnerProbeChartTabsProps {
  wagnerProbe: WagnerProbe;
}

const STEP = 7

export const WagnerProbeChartTabs: FC<WagnerProbeChartTabsProps> = ({ wagnerProbe }) => {
  const [timeFrom, setTimeFrom] = useState<Date>(startOfWeek(new Date(), { weekStartsOn: 1 }))
  const [timeTo, setTimeTo] = useState<Date>(endOfWeek(new Date(), { weekStartsOn: 1 }))
  const [datetime, setDatetime] = useState<Date | null>(startOfWeek(new Date(), { weekStartsOn: 1 }))
  const [isOpenDatePicker, setIsOpenDatePicker] = useState(false)
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const wrapperRef = useRef(null)
  useOutsideAlerter({ ref: wrapperRef, callback: () => setIsOpenDatePicker(false)})
  const { data: wagnerProbeReadingsResponse, refetch: refetchReadings, isRefetching, isInitialLoading } = useWagnerProbeReadingsRequest({
    wagnerProbeId: wagnerProbe.probeId,
    params: { timeFrom: timeFrom.toISOString(), timeTo: timeTo.toISOString() },
    options: { enabled: false }
  })
  const [readings, setReadings] = useState<WagnerProbeReading[]>(wagnerProbeReadingsResponse || [])

  useEffect(() => {
    setReadings(wagnerProbeReadingsResponse || [])
  }, [wagnerProbeReadingsResponse])

  useEffect(() => {
    if (timeFrom && timeTo) refetchReadings()
  }, [timeFrom, timeTo, refetchReadings])

  const getColor = (isHistorical: boolean): string => {
    if (isHistorical) {
      return "#a8dafe" // light blue
    }
    return "#26a2fc"
  }

  const getSensorBarChartData = useCallback(
    ({
      readout,
      length,
    }: {
      readout: {
        time: string;
        value: number;
        isHistorical: boolean;
        contractor: string | null;
        project: string | null;
        projectSection: string | null;
        gapInMinutes: number | null;
      }[];
      length: number;
    }): ApexChartData => {
      const data: ApexChartDataObj[] = []
      for (let i = 0; i <= length; i++) {
        const shiftedTime = new Date(new Date(timeTo).setUTCHours(new Date(timeTo).getUTCHours() - i, 59, 59))
        const reading = readout.find((_reading) => {
          const readingTime = new Date((_reading as any).time).getTime()
          const diff = shiftedTime.getTime() - readingTime
          const diffInMinutes = Number((diff / 1000 / 60).toFixed(0))
          return diffInMinutes >= 0 && diffInMinutes < 60
        })
        data.unshift({
          x: shiftedTime.getTime(),
          y: reading?.value || 0,
          fillColor: getColor(Boolean(reading?.isHistorical)),
          meta: {
            contractor: reading?.contractor,
            project: reading?.project,
            projectSection: reading?.projectSection,
            gapInMinutes: reading?.gapInMinutes,
          }
        })
      }
      return data
    },
    [timeTo]
  )

  const getHumidityChartData = (): ApexOptions["series"] => {
    const oneWeek = STEP * 24 // one week in hours
    const data = getSensorBarChartData({
      readout: readings.map((reading) => ({
        time: reading.readingTime,
        value: reading.slabHumidity,
        isHistorical: reading.isHistorical || false,
        contractor: reading.contractor?.name || null,
        project: reading.project?.name || null,
        projectSection: reading.projectSection?.name || null,
        gapInMinutes: reading.gapInMinutes || null,
      })),
      length: oneWeek,
    })
    return [
      {
        name: "Humidity",
        data,
      },
    ]
  }

  const getTemperatureChartData = (): ApexOptions["series"] => {
    const oneWeek = STEP * 24 // one week in hours
    const data = getSensorBarChartData({
      readout: readings.map((reading) => ({
        time: reading.readingTime,
        value: reading.slabTemperatureF,
        isHistorical: reading.isHistorical || false,
        contractor: reading.contractor?.name || null,
        project: reading.project?.name || null,
        projectSection: reading.projectSection?.name || null,
        gapInMinutes: reading.gapInMinutes || null,
      })),
      length: oneWeek,
    })
    return [
      {
        name: "Temperature",
        data,
      },
    ]
  }

  const getSignalStrengthChartData = (): ApexOptions["series"] => {
    const oneWeek = STEP * 24 // one week in hours
    const data = getSensorBarChartData({
      readout: readings.map((reading) => ({
        time: reading.readingTime,
        value: reading.signalStrength || 0,
        isHistorical: reading.isHistorical || false,
        contractor: reading.contractor?.name || null,
        project: reading.project?.name || null,
        projectSection: reading.projectSection?.name || null,
        gapInMinutes: reading.gapInMinutes || null,
      })),
      length: oneWeek,
    })
    return [
      {
        name: "Signal Strength",
        data,
      },
    ]
  }

  const getBatteryLevelChartData = (): ApexOptions["series"] => {
    const oneWeek = STEP * 24 // one week in hours
    const data = getSensorBarChartData({
      readout: readings.map((reading) => ({
        time: reading.readingTime,
        value: reading.batteryLevel || 0,
        isHistorical: reading.isHistorical || false,
        contractor: reading.contractor?.name || null,
        project: reading.project?.name || null,
        projectSection: reading.projectSection?.name || null,
        gapInMinutes: reading.gapInMinutes || null,
      })),
      length: oneWeek,
    })
    return [
      {
        name: "Battery Level",
        data,
      },
    ]
  }

  const getRepeaterBatteryLevelChartData = (): ApexOptions["series"] => {
    const oneWeek = STEP * 24 // one week in hours
    const data = getSensorBarChartData({
      readout: readings.map((reading) => ({
        time: reading.readingTime,
        value: reading.repeaterBatteryLevel || 0,
        isHistorical: reading.isHistorical || false,
        contractor: reading.contractor?.name || null,
        project: reading.project?.name || null,
        projectSection: reading.projectSection?.name || null,
        gapInMinutes: reading.gapInMinutes || null,
      })),
      length: oneWeek,
    })
    return [
      {
        name: "Repeater Battery Level",
        data,
      },
    ]
  }

  const isLastWeek = useCallback(() => {
    const endOfWeekDate = endOfWeek(new Date(), { weekStartsOn: 1 })
    return timeTo.getDate() === endOfWeekDate.getDate()
      && timeTo.getMonth() === endOfWeekDate.getMonth()
      && timeTo.getFullYear() === endOfWeekDate.getFullYear()
  }, [timeTo])()

  const handleGoBack = () => {
    if (isRefetching) return

    const newTimeFrom = new Date(new Date(timeFrom).setDate(new Date(timeFrom).getDate() - STEP))
    const newTimeTo = new Date(new Date(timeTo).setDate(new Date(timeTo).getDate() - STEP))
    setTimeFrom(newTimeFrom)
    setTimeTo(newTimeTo)
    setDatetime(newTimeFrom)
  }

  const handleGoForward = () => {
    if (isLastWeek || isRefetching) return

    const newTimeFrom = new Date(new Date(timeFrom).setDate(new Date(timeFrom).getDate() + STEP))
    const newTimeTo = new Date(new Date(timeTo).setDate(new Date(timeTo).getDate() + STEP))
    setTimeFrom(newTimeFrom)
    setTimeTo(newTimeTo)
    setDatetime(newTimeFrom)
  }

  const resetRange = () => {
    const newTimeFrom = startOfWeek(new Date(), { weekStartsOn: 1 })
    const newTimeTo = endOfWeek(new Date(), { weekStartsOn: 1 })
    setTimeFrom(newTimeFrom)
    setTimeTo(newTimeTo)
    setDatetime(newTimeFrom)
  }

  const onAcceptDatetime = (newDatetime: Date | null) => {
    const startOfWeekDate = startOfWeek(newDatetime || timeFrom, { weekStartsOn: 1 })
    const endOfWeekDate = endOfWeek(newDatetime || timeTo, { weekStartsOn: 1 })
    setDatetime(startOfWeekDate)
    setTimeFrom(startOfWeekDate)
    setTimeTo(endOfWeekDate)
    setIsOpenDatePicker(false)
  }

  const toggleDatePicker = (event: React.MouseEvent<HTMLButtonElement>) => {
    setIsOpenDatePicker(!isOpenDatePicker)
    setAnchorEl(!isOpenDatePicker ? event.currentTarget : null)
  }

  return (
    <Box>
      <Grid>
        <Grid container alignItems="center">
          <Grid item>
            <IconButton aria-label="go-back" color="primary" onClick={handleGoBack} disabled={isRefetching}>
              <ArrowBackIcon />
            </IconButton>
          </Grid>
          <Grid item px={1}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                open={isOpenDatePicker}
                value={datetime}
                onChange={() => {}}
                onAccept={onAcceptDatetime}
                renderInput={(props: any) => (
                  <Button variant="text" onClick={toggleDatePicker}>
                    <Typography variant="body1">
                      {`${format(timeFrom, 'PP')} - ${format(timeTo, 'PP')}`}
                    </Typography>
                  </Button>
                )}
                PopperProps={{
                  placement: "bottom-end",
                  anchorEl: anchorEl,
                  ref: wrapperRef,
                }}
                disableFuture
                closeOnSelect
                disableHighlightToday
              />
            </LocalizationProvider>
          </Grid>
          <Grid item>
            <IconButton aria-label="go-forward" color="primary" onClick={handleGoForward} disabled={isLastWeek || isRefetching}>
              <ArrowForwardIcon />
            </IconButton>
          </Grid>
          <Grid item>
            <IconButton aria-label="reset" color="primary" onClick={resetRange} disabled={isLastWeek || isRefetching}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </Grid>
      <Grid>
        <TabGroupFilter
          name={SearchParamsKeys.Chart}
          statusList={chartOfReadingsTabs({
            humidityData: getHumidityChartData(),
            temperatureData: getTemperatureChartData(),
            signalStrength: getSignalStrengthChartData(),
            batteryLevel: getBatteryLevelChartData(),
            repeaterBatteryLevel: getRepeaterBatteryLevelChartData(),
            isLoading: isRefetching || isInitialLoading,
          })}
          paramsToRemove={[SearchParamsKeys.Page, SearchParamsKeys.RowsPerPage]}
        />
      </Grid>
    </Box>
  )
}

import React, { FC, PropsWithChildren } from "react"
import * as Yup from "yup"
import { Button, Grid, Box } from "@mui/material"
import { Formik, Form, useFormikContext } from "formik"
import { PhoneInput } from "src/components"
import { phoneRequiredValidation } from "../../../utils/validators"

const validationSchema = Yup.object().shape({
  phone: phoneRequiredValidation,
})

export interface GetCodeFormState {
  phone: string;
}

const initialValues: GetCodeFormState = {
  phone: "",
}

interface GetCodeFormProps {
  disableSubmit?: boolean;
  SubmitContent?: FC;
  onSubmit: (formState: GetCodeFormState) => void;
}

const SubmitButton: FC<PropsWithChildren<{ disabled?: boolean }>> = ({ disabled, children }) => {
  const { errors, touched } = useFormikContext()

  return (
    <Button
      size="large"
      variant="contained"
      color="primary"
      fullWidth
      type="submit"
      disabled={disabled || (Object.keys(errors).length > 0 && !!touched)}
    >
      {children}
    </Button>
  )
}

export const GetCodeForm: FC<GetCodeFormProps> = ({
  disableSubmit = false,
  SubmitContent = () => <>Get Code</>,
  onSubmit,
}) => {
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(data) => {
        onSubmit(data)
      }}
      validationSchema={validationSchema}
    >
      <Form>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <PhoneInput name="phone" />
          </Grid>
          <Grid item xs={12}>
            <Box pt={2}>
              <SubmitButton disabled={disableSubmit}>
                <SubmitContent />
              </SubmitButton>
            </Box>
          </Grid>
        </Grid>
      </Form>
    </Formik>
  )
}

import React, { createContext } from 'react'
import { AlertColor } from '@mui/material'
import { PopupMessage } from 'src/interfaces'

export interface AddMessage {
  (message: PopupMessage): void;
  (messages: PopupMessage[]): void;
}

export type PopupMessageContextState = {
  message: {
    type: AlertColor;
    text: string | React.ReactNode;
  } | null;
  onClose: () => void;
  add: AddMessage;
};

export const PopupMessageContext = createContext<PopupMessageContextState>({
  message: null,
  onClose: () => {},
  add: () => {},
})

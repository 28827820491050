import React, { FC, useState } from 'react'
import { TableCell } from '@mui/material'
import { DynamicColumn } from 'src/components/ui'
import { EventType } from 'src/api'
import { Switch } from 'src/components'
import { useUpdateEventTypeRequest } from 'src/hooks/api'

interface EventTypeVisibilitySwitchProps {
  name: string;
  eventId: number;
  isVisible: boolean;
}
const EventTypeVisibilitySwitch: FC<EventTypeVisibilitySwitchProps> = ({
  name,
  eventId,
  isVisible,
}) => {
  const [checked, setChecked] = useState(isVisible)

  const mutation = useUpdateEventTypeRequest()

  const onChange = (_: any, _checked: boolean) => {
    setChecked(_checked)
    mutation.mutate({ eventTypeId: eventId, payload: { isVisible: _checked } })
  }

  return (
    <Switch
      name={`${name}-visibility`}
      label={checked ? 'Yes' : 'No'}
      SwitchProps={{ checked, onChange }}
    />
  )
}

export namespace ContractorColumns {
  export const Name: DynamicColumn<EventType> = {
    field: 'displayName',
    headerName: 'Name',
    type: 'string',
    sort: true,
  }
  export const IsVisible: DynamicColumn<EventType> = {
    field: 'isVisible',
    headerName: 'Is Visible',
    type: 'custom',
    cellProps: { align: 'right' },
    component: ({ row: { id, name, isVisible }, cellProps }) => {
      return (
        <TableCell align={cellProps?.align}>
          <EventTypeVisibilitySwitch
            name={name}
            isVisible={isVisible}
            eventId={id}
          />
        </TableCell>
      )
    },
  }
}

export default ContractorColumns

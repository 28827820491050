import React, { FC } from 'react'
import { Grid } from '@mui/material'
import { useAddPopupMessage } from 'src/hooks/ui'
import { useEditBuildingTypeRequest } from 'src/hooks/api'
import { BuildingTypeNewForm } from 'src/components/forms'

interface EditableBuildingTypeProps {
  id: number;
  name: string;
  onChange: () => void;
  onCancel: () => void;
}

export const EditableBuildingType: FC<EditableBuildingTypeProps> = ({
  id,
  name,
  onChange,
  onCancel,
}) => {
  const addMessage = useAddPopupMessage()
  const editBuildingTypeRequest = useEditBuildingTypeRequest({ id })

  const update = ({ displayName }: { displayName: string }) => {
    editBuildingTypeRequest.mutate(
      { displayName },
      {
        onSuccess: onChange,
        onError: (error) => addMessage({ type: 'error', text: error.message }),
      }
    )
  }

  return (
    <>
      <Grid item flexGrow={1}>
        <BuildingTypeNewForm
          onSubmit={update}
          initialValues={{ displayName: name }}
          onCancel={onCancel}
        />
      </Grid>
    </>
  )
}
import React, { FC, useState } from "react"
import { Form, useFormik, FormikProvider } from "formik"
import * as Yup from "yup"
import { Box, Button, CircularProgress, Divider, Grid } from "@mui/material"
import { useQueryClient } from '@tanstack/react-query'
import { TextField } from "src/components/ui"
import { useDeleteReleaseRequest, useEditReleaseRequest, useUploadPhotoRequest } from "src/hooks/api"
import { FeatureEditingParams, Release, ReleaseEditing, UploadPhotoResult } from "src/api"
import { useAddPopupMessage, useDialog } from "src/hooks/ui"
import { useNavigate } from "react-router-dom"
import { DialogNames, Entities } from "src/enums"
import { DeleteReleaseModal } from "src/components/sections/release"
import { ReleaseFeaturePayload } from "./releaseNewForm"
import { ReleaseFeatureNewForm } from "./releaseFeatureNewForm"
import { hasDuplicates } from "./helpers"

interface EditReleasePayload {
  description: string;
  number: string;
  featureName: string;
}

interface ReleaseEditFormProps {
  release: Release;
}

const validationSchema = Yup.object().shape({
  description: Yup.string().required("Release name is required"),
  number: Yup.string().required("Release version is required"),
  featureName: Yup.string(),
})

const initialValues = {
  number: "",
  description: "",
  featureName: ""
}

export const ReleaseEditForm: FC<ReleaseEditFormProps> = ({ release }) => {
  const [features, setFeatures] = useState<ReleaseFeaturePayload[]>(release.features.map((feature) => ({
    id: feature.id,
    header: feature.header,
    description: feature.description,
    link: feature.image,
  })) || [])
  const photoUploadRequest = useUploadPhotoRequest()
  const editReleaseRequest = useEditReleaseRequest({ id: release.id || 0 })
  const deleteReleaseRequest = useDeleteReleaseRequest({ id: release.id || 0 })
  const addMessage = useAddPopupMessage()
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const { openDialog, closeDialog } = useDialog(DialogNames.DeleteRelease)

  const editRelease = (data: ReleaseEditing) => {
    editReleaseRequest.mutate(data, {
      onSuccess: () => {
        queryClient.invalidateQueries([Entities.Release, release.id])
        addMessage({ text: 'Release was successfully edited', type: 'success' })
        navigate(-1)
      },
      onError: ({ message: text }) => {
        addMessage({ text, type: 'error' })
      }
    })
  }
  const onSubmit = async (data: EditReleasePayload) => {
    if (hasDuplicates(features)) {
      addMessage({ text: "There are features with the same names in the release", type: 'error' })
      return
    }

    let preparedFeatures: FeatureEditingParams[] = []
    const imagesToUpload: File[] = []

    for await (const feature of features) {
      if (feature.image) {
        imagesToUpload.push(feature.image)
      } else if (feature.image === null) {
        preparedFeatures.push({
          header: feature.header,
          description: feature.description,
          image: {
            bucket: "",
            fileName: ""
          },
          ...(typeof feature.id === 'string'
            ? {}
            : { id: feature.id as number }
          )
        })
      } else {
        preparedFeatures.push({
          ...(typeof feature.id === 'string'
            ? {}
            : { id: feature.id as number }
          ),
          header: feature.header,
          description: feature.description,
        })
      }
    }
    
    if (imagesToUpload.length > 0) {
      photoUploadRequest.mutate({
        photos: imagesToUpload,
      }, {
        onSuccess: (result) => {
          (result as UploadPhotoResult[]).forEach((image) => {
            const feature = features.find((item) => item.image?.name === image.originalFileName)
            if (feature) {
              preparedFeatures.push({
                ...(typeof feature.id === 'string'
                  ? {}
                  : { id: feature.id as number }
                ),
                header: feature.header,
                description: feature.description,
                image: image.original,
              })
            }
          })

          editRelease({
            description: data.description,
            number: data.number,
            features: preparedFeatures
          })
        }
      })
    } else {
      editRelease({
        description: data.description,
        number: data.number,
        features: preparedFeatures
      })
    }
  }
  const formik = useFormik({
    initialValues: {
      ...initialValues,
      number: release.number || "",
      description: release.description || "",
    },
    onSubmit: onSubmit,
    validationSchema,
  })
  const { values, setValues, submitForm } = formik

  const addFeature = () => {
    if (!values.featureName) return

    const id = values.featureName.toLowerCase().replace(' ', '_')
    const isDuplicate = features.some((feature) => {
      const preparedFeatureName = feature.header.toLowerCase().replace(' ', '_')
      return preparedFeatureName === id
    })
    if (isDuplicate) {
      addMessage({ text: "The feature with this name already exists", type: 'warning' })
      return
    }
    setFeatures([
      ...features,
      { id, header: values.featureName }
    ])
    setValues({
      ...values,
      featureName: ""
    })
  }
  const updateFeature = (feature: Partial<ReleaseFeaturePayload>) => {
    const index = features.findIndex((item) => item.id === feature.id)
    if (index === -1) return

    const isDuplicate = features.filter((item) => item.id !== feature.id).some((item) => {
      const preparedFeatureName = (item.header || '').toLowerCase().replace(' ', '_')
      const preparedUpdatedFeatureName = (feature.header || '').toLowerCase().replace(' ', '_')
      return preparedFeatureName === preparedUpdatedFeatureName
    })
    if (isDuplicate) {
      addMessage({ text: "The feature with this name already exists", type: 'warning' })
    }
    const copy = features.slice()
    copy[index] = {
      ...copy[index],
      ...feature,
    }
    setFeatures(copy)
  }
  const deleteFeature = (id: string | number) => {
    const filteredFeatures = features.filter((feature) => feature.id !== id)
    setFeatures(filteredFeatures)
  }
  const deleteRelease = () => {
    deleteReleaseRequest.mutate({}, {
      onSuccess: () => {
        closeDialog()
        addMessage({ text: 'Release was successfully deleted', type: 'success' })
        navigate(-1)
      },
      onError: ({ message: text }) => {
        addMessage({ text, type: 'error' })
      }
    })
  }

  return (
    <FormikProvider
      value={formik}
    >
      <Form>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <TextField
              name="description"
              label="Release name"
              required
              TextFieldProps={{ placeholder: "Release name" }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              name="number"
              label="Release version"
              required
              TextFieldProps={{ placeholder: "Release version" }}
            />
          </Grid>
        </Grid>
      </Form>
      <Grid container>
        <Grid item xs={12}>
          {features.map((feature) => (
            <ReleaseFeatureNewForm
              key={feature.id}
              defaultValues={feature}
              onChangeFeature={updateFeature}
              onDeleteFeature={deleteFeature}
            />
          ))}
        </Grid>
        <Grid
          item
          container
          alignItems="center"
          justifyContent="space-between"
          spacing={1}
          xs={12}
          position="relative"
          mt={10}
        >
          <Grid item xs={12}>
            <TextField
              name="featureName"
              label="Add feature"
              TextFieldProps={{ placeholder: "Feature name" }}
            />
          </Grid>
          <Grid
            item
            xs={2}
            position="absolute"
            right={-100}
          >
            <Button
              size="large"
              variant="outlined"
              color="primary"
              onClick={addFeature}
            >
              Add
            </Button>
          </Grid>
        </Grid>
        <Grid item xs={12} mt={5}>
          <Divider />
        </Grid>
        <Grid item container xs={12}>
          <Grid item xs={3}>
            <Box pt={2}>
              <Button
                size="large"
                variant="contained"
                color="primary"
                type="button"
                onClick={submitForm}
                disabled={photoUploadRequest.isLoading || editReleaseRequest.isLoading}
              >
                {
                  photoUploadRequest.isLoading || editReleaseRequest.isLoading ? <CircularProgress color="info" size={26} /> : 'Save'
                }
              </Button>
            </Box>
          </Grid>
          <Grid item xs={3}>
            <Box pt={2}>
              <Button
                size="large"
                variant="outlined"
                color="primary"
                type="button"
                onClick={() => navigate(-1)}
              >
                Cancel
              </Button>
            </Box>
          </Grid>
          <Grid container item xs={6} justifyContent="end">
            <Box pt={2}>
              <Button
                size="large"
                variant="text"
                color="error"
                type="button"
                onClick={openDialog}
              >
                Delete release
              </Button>
            </Box>
          </Grid>
        </Grid>
        <DeleteReleaseModal
          releaseName={release.description || ""}
          onCancel={closeDialog}
          onDelete={deleteRelease}
        />
      </Grid>
    </FormikProvider>
  )
}

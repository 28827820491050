import React, { FC } from 'react'
import { Grid } from '@mui/material'
import { Project, User, UserRole } from 'src/api'
import { DynamicColumn, Table } from 'src/components'
import UserColumns from 'src/components/columns/user'
import { AssignForeman } from 'src/components/forms'
import { UserRoles } from 'src/enums'

const getUserListColumns = ({
  pmRoleId,
  ownerRoleId,
}: {
  pmRoleId: number | undefined;
  ownerRoleId: number | undefined;
}): DynamicColumn<User>[] => [
  UserColumns.NameWithIcon(pmRoleId, ownerRoleId),
  UserColumns.Phone,
  UserColumns.Email,
  UserColumns.LastActive,
  UserColumns.CreatedAt,
  UserColumns.RemoveFromProject,
]
interface ProjectUsersProps {
  users: User[];
  project: Project;
  updateProject: () => void;
  userRoles: UserRole[];
}

export const ProjectUsers: FC<ProjectUsersProps> = ({
  users,
  project,
  updateProject,
  userRoles,
}) => {
  const enrichedUsers: User[] = users.map((user) => ({
    ...user,
    projects: [
      {
        ...project,
        update: updateProject,
        countOfUsers: users.length,
      },
    ],
  }))
  const pmRoleId = userRoles.find((el) => el.name === UserRoles.PM)?.id
  const ownerRoleId = userRoles.find((el) => el.name === UserRoles.Owner)?.id
  const columnsGroups = getUserListColumns({ pmRoleId, ownerRoleId })
  return (
    <Grid container item spacing={2} direction="column">
      <Grid item>
        <Table
          columns={columnsGroups}
          data={enrichedUsers}
          showFooter={false}
          sx={{ width: '100%' }}
          noResultText="No user belongs to the selected project"
        />
      </Grid>
      <Grid item>
        <AssignForeman
          contractorId={project.contractorId as number}
          projectId={project.id}
          updateProject={updateProject}
        />
      </Grid>
    </Grid>
  )
}

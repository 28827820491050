import React, { FC, PropsWithChildren } from "react"
import { Sidebar, ContentBox, RootContainer } from "src/components"

export const HasSidebarLayout: FC<PropsWithChildren> = ({ children }) => {
  return (
    <RootContainer>
      <Sidebar />
      <ContentBox
        flexGrow={1}
        width="calc(100% - 247px)"
        display="flex"
        flexDirection="column"
        boxSizing="border-box"
        height="100vh"
        sx={{ overflowY: "auto" }}
      >
        {children}
      </ContentBox>
    </RootContainer>
  )
}

import { useContext } from 'react'
import { ImagesListItem, ImagesViewerStateContext } from 'src/contexts'

export const useImagesViewer = (): {
  isOpen: boolean
  currentImageIndex: number
  images: ImagesListItem[]
  setImages: (images: ImagesListItem[]) => void
  openGallery: (images?: ImagesListItem[]) => void
  closeGallery: () => void
  goToNext: () => void
  goToPrev: () => void
} => {
  const {
    isOpen,
    images,
    closeViewer,
    openViewer,
    setImages,
    setCurrentImageIndex,
    currentImageIndex,
  } = useContext(ImagesViewerStateContext)
  const goToPrev = () => currentImageIndex > 0 && setCurrentImageIndex(currentImageIndex - 1)
  const goToNext = () => currentImageIndex + 1 < images.length && setCurrentImageIndex(currentImageIndex + 1)
  const openGallery = (imgs?: ImagesListItem[]) => {
    if (Array.isArray(imgs)) {
      setImages(imgs)
    }
    openViewer()
  }

  return {
    isOpen,
    currentImageIndex,
    images,
    setImages,
    goToNext,
    goToPrev,
    openGallery,
    closeGallery: closeViewer,
  }
}

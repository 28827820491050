import { FC } from 'react'
import { Typography, DialogActions, Button, Box } from '@mui/material'
import { StatefulDialog } from 'src/components/ui'
import { DialogNames } from 'src/enums'

interface ArchiveSensorModalProps {
  sensorDeviceId: string;
  onArchive: () => void;
  onCancel: () => void;
  isLoading?: boolean;
}

export const ArchiveSensorModal: FC<ArchiveSensorModalProps> = ({
  sensorDeviceId,
  onArchive,
  onCancel,
  isLoading = false,
}) => {
  return (
    <StatefulDialog
      name={DialogNames.ArchiveSensor}
      actions={
        <Box mb={2} mx={2}>
          <DialogActions>
            <Button variant="outlined" onClick={onCancel}>
              Cancel
            </Button>
            <Button variant="contained" color="error" onClick={onArchive} disabled={isLoading}>
              Archive
            </Button>
          </DialogActions>
        </Box>
      }
    >
      <Box minWidth="300px">
        <Typography variant="h6">Are you sure you want to archive the sensor "{sensorDeviceId}"?</Typography>
      </Box>
    </StatefulDialog>
  )
}

import React, { FC } from 'react'
import { Typography, DialogActions, Button, Box } from '@mui/material'
import { StatefulDialog } from 'src/components/ui'
import { DialogNames } from 'src/enums'

interface DeleteProductModalProps {
  productName: string;
  onDelete: () => void;
  onCancel: () => void;
}

export const DeleteProductModal: FC<DeleteProductModalProps> = ({
  productName,
  onDelete,
  onCancel,
}) => {
  return (
    <StatefulDialog
      name={DialogNames.DeleteProduct}
      actions={
        <DialogActions>
          <Button variant="outlined" onClick={onCancel}>
            Cancel
          </Button>
          <Button variant="contained" color="error" onClick={onDelete}>
            Delete
          </Button>
        </DialogActions>
      }
    >
      <Box minWidth="300px">
        <Typography variant="h6">Delete {productName}?</Typography>
      </Box>
    </StatefulDialog>
  )
}

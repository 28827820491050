import { useMutation, UseMutationOptions } from '@tanstack/react-query'
import { Entities } from 'src/enums'
import { AdminMobileSettings } from 'src/api'
import { useApiInstance } from './useApiInstance'

interface RequestParams {
  options?: Omit<
    UseMutationOptions<unknown, Error, AdminMobileSettings, AdminMobileSettings>,
    'mutationKey' | 'mutationFn'
  >;
}

export const useEditMobileSettingsRequest = ({ options }: RequestParams = {}) => {
  const api = useApiInstance()

  return useMutation<unknown, Error, AdminMobileSettings, AdminMobileSettings>(
    [Entities.MobileSettings],
    async (payload: AdminMobileSettings) => {
      const { data } = await api.v1AdminConfigurationMobileSettingsPut(
        payload,
      )
      return data
    },
    {
      ...options,
    },
  )
}

import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { APIList } from 'src/interfaces'
import { Entities } from 'src/enums'
import { SystemMessageHistory } from 'src/api'
import { useApiInstance } from './useApiInstance'
import {
  buildRequestParams,
  ObjectType,
  transformPage,
  transformPerPage,
} from './helpers'

interface RequestParams {
  params?: Partial<SystemMessagesHistoryGetParams>;
  paramsTransform?: Array<(params: Record<string, any>) => Record<string, any>>;
  options?: Omit<
    UseQueryOptions<unknown, Error, APIList<SystemMessageHistory>>,
    'queryKey' | 'queryFn'
  >;
}

interface SystemMessagesHistoryGetParams {
  limit: number;
  offset: number;
  sort: string;
}

const schema: ObjectType['schema'] = {
  limit: 'number',
  offset: 'number',
  sort: 'string',
}

export const useSystemMessagesHistoryRequest = ({
  params = {},
  paramsTransform = [],
  options,
}: RequestParams = {}) => {
  const api = useApiInstance()

  const transformedParams = [
    transformPerPage,
    transformPage,
    ...paramsTransform,
  ].reduce((param, transform) => transform(param), params)

  const queryParams = buildRequestParams<SystemMessagesHistoryGetParams>(
    transformedParams,
    schema,
  )

  return useQuery<unknown, Error, APIList<SystemMessageHistory>>(
    [Entities.SystemMessage, 'history', JSON.stringify(transformedParams)],
    async () => {
      const {
        limit,
        offset,
        sort,
      } = queryParams
      const { data } = await api.v1AdminSystemMessagesHistoryGet(
        limit,
        offset,
        sort,
      )
      return data
    },
    {
      enabled: Object.keys(transformedParams).length > 0,
      ...options,
    },
  )
}

import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { APIList } from 'src/interfaces'
import { Entities, SearchParamsKeys } from 'src/enums'
import { User } from 'src/api'
import { useApiInstance } from './useApiInstance'
import {
  buildRequestParams,
  ObjectType,
  transformPage,
  transformPerPage,
  transformOneToList,
} from './helpers'

interface AdminUserGetParams {
  limit: number;
  offset: number;
  sort: string;
  roleIds: number[];
  contractorIds: number[];
  manufacturerIds: number[];
  search: string;
  lastActive: number;
}

interface RequestParams {
  params?: Partial<AdminUserGetParams>;
  paramsTransform?: Array<(params: Record<string, any>) => Record<string, any>>;
  options?: Omit<
    UseQueryOptions<unknown, Error, APIList<User>>,
    'queryKey' | 'queryFn'
  >;
}

const requiredParams: Partial<keyof AdminUserGetParams>[] = ['limit', 'offset']

const schema: ObjectType['schema'] = {
  limit: 'number',
  offset: 'number',
  sort: 'string',
  roleIds: { type: 'array', schema: 'number' },
  contractorIds: { type: 'array', schema: 'number' },
  manufacturerIds: { type: 'array', schema: 'number' },
  search: 'string',
  lastActive: 'number',
}

export const useUserListRequest = ({
  params,
  paramsTransform = [],
  options,
}: RequestParams = {}) => {
  const api = useApiInstance()

  const transformedParams = [
    transformPerPage,
    transformPage,
    transformOneToList(SearchParamsKeys.Contractor),
    ...paramsTransform,
  ].reduce((param, transform) => transform(param), params || {})

  const queryParams = buildRequestParams<Partial<AdminUserGetParams>>(
    transformedParams,
    schema,
  )

  return useQuery<unknown, Error, APIList<User>>(
    [Entities.Users, JSON.stringify(transformedParams)],
    async () => {
      const {
        limit,
        offset,
        sort,
        roleIds,
        contractorIds,
        manufacturerIds,
        search,
        lastActive,
      } = queryParams
      const { data } = await api.v1AdminUsersGet(
        limit,
        offset,
        sort,
        roleIds,
        contractorIds,
        manufacturerIds,
        search,
        lastActive,
      )
      return data
    },
    {
      enabled: requiredParams.every((param) => transformedParams[param] !== undefined),
      ...options,
    },
  )
}

import { useMutation, UseMutationOptions } from '@tanstack/react-query'
import { useApiInstance } from './useApiInstance'

interface SensorRetrieveFirmwareRequestParams {
  options?: Omit<
  UseMutationOptions<boolean, Error, unknown, unknown>,
    'mutationKey' | 'mutationFn'
  >;
}

export const useSensorRetrieveFirmwareRequest = ({
  options,
}: SensorRetrieveFirmwareRequestParams = {}) => {
  const api = useApiInstance()

  return useMutation(
    async (sensorUniqueId: string) => {
      const { data }: { data: unknown } = await api.v1AdminSensorsUniqueIdRetrieveSensorFirmwarePost(sensorUniqueId)
      return data as boolean
    },
    options,
  )
}

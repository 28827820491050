import React, { FC, PropsWithChildren } from "react"
import { BrowserRouter } from "react-router-dom"
import { ThemeProvider } from "@mui/system"
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import theme from "src/theme"
import { QueryParamsProvider } from "./queryParamsProvider"
import { AuthorizationProvider } from "./authorizationProvider"
import { PopupMessageProvider } from "./popupMessageProvider"
import { DialogStateProvider } from "./dialogStateProvider"
import { NewProductRequestNumberProvider } from './newProductRequestNumberProvider'

const queryClient = new QueryClient()

export const Providers: FC<PropsWithChildren> = ({ children }) => {
  return (
    <BrowserRouter>
      <AuthorizationProvider>
        <ThemeProvider theme={theme}>
          <QueryClientProvider client={queryClient}>
            <NewProductRequestNumberProvider>
              <QueryParamsProvider>
                <PopupMessageProvider>
                  <DialogStateProvider>{children}</DialogStateProvider>
                </PopupMessageProvider>
              </QueryParamsProvider>
            </NewProductRequestNumberProvider>
            {process.env.NODE_ENV === "development" && (
              <ReactQueryDevtools initialIsOpen={false} />
            )}
          </QueryClientProvider>
        </ThemeProvider>
      </AuthorizationProvider>
    </BrowserRouter>
  )
}

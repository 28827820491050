import React, { FC, useMemo, useState } from "react"
import { Menu, MenuItem } from "@mui/material"
import MoreVertIcon from "@mui/icons-material/MoreVert"
import { GridColDef, GridActionsCellItem } from "@mui/x-data-grid-premium"
import { SensorColumnsDataGrid } from "src/components/columns/sensor"
import { Sensor, SensorState } from "src/api"
import { SensorsMapView } from "src/components/ui"
import { useSensorLabel, useQueryParamsState, useSensorStateUpdate } from "src/hooks/ui"
import {
  SensorStateList,
  SearchParamsKeys,
  ViewTypes,
  SensorFilters,
  SensorButtons,
} from "src/enums"
import { SensorListFilters } from "src/components/forms/sensor"
import { SensorMapFilters } from "src/components/forms/sensor/sensorMapFilters"
import { ContractorsDataLoader } from "./contractorsDataLoader"
import { CommonSensorList } from "./commonSensorsList"
import { ArchiveSensorModal } from "../archiveSensorModal"

interface NewSensorListProps {
  isLoading: boolean;
  count: number;
  data: Sensor[];
  states: SensorState[];
  refetchSensorList: () => void;
}

export const NewSensorList: FC<NewSensorListProps> = ({
  data,
  isLoading,
  count,
  states,
  refetchSensorList,
}) => {
  const [currentRow, setCurrentRow] = useState<Sensor | null>(null)
  const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLElement | null>(null)
  const { generatingLabel, downloadLabel } = useSensorLabel()
  const {
    openSensorArchivingDialog,
    closeSensorArchivingDialog,
    updateSensorState,
    isLoadingChangingSensorState,
  } = useSensorStateUpdate()
  const availableState = states.find(
    (state) => state.name === SensorStateList.Available
  )
  const archivedState = useMemo(() => states.find(
    (state) => state.name === SensorStateList.Archived
  ), [states])
  const disabled = !availableState || isLoadingChangingSensorState
  const queryParams = useQueryParamsState()
  const viewType = queryParams[SearchParamsKeys.ViewType] || ViewTypes.List

  const handleOpenMenu = (
    event: React.MouseEvent<HTMLElement>,
    row: Sensor
  ) => {
    setCurrentRow(row)
    setMenuAnchorEl(event.currentTarget)
  }

  const handleCloseMenu = () => {
    setCurrentRow(null)
    setMenuAnchorEl(null)
  }

  const columns: GridColDef<Sensor>[] = [
    SensorColumnsDataGrid.Id,
    SensorColumnsDataGrid.Model,
    SensorColumnsDataGrid.IMEI,
    SensorColumnsDataGrid.DevEUI,
    SensorColumnsDataGrid.LastPing,
    SensorColumnsDataGrid.BatteryLevel,
    SensorColumnsDataGrid.DateAdded,
    {
      field: "actions",
      headerName: "",
      type: "actions",
      width: 30,
      getActions: ({ row }) => [
        <GridActionsCellItem
          icon={<MoreVertIcon />}
          label="Actions"
          className="textPrimary"
          onClick={(e: any) => handleOpenMenu(e, row)}
          color="inherit"
        />,
      ],
    },
  ]

  return viewType === ViewTypes.List ? (
    <>
      <CommonSensorList
        columns={columns}
        count={count}
        data={data}
        isLoading={isLoading}
        filters={
          <SensorListFilters
            activeFilters={[
              SensorFilters.LowBattery,
              SensorFilters.WeakSignal,
              SensorFilters.LastPingMoreThanHour,
              SensorFilters.SyncPeriod,
              SensorFilters.ReadingsAge,
              SensorFilters.CurrentSensorConfiguration,
              SensorFilters.BleStatus,
            ]}
          />
        }
        buttons={[SensorButtons.DownloadLabels]}
        checkboxSelection
      />
      <Menu
        anchorEl={menuAnchorEl}
        open={!!menuAnchorEl}
        onClose={handleCloseMenu}
      >
        <MenuItem
          disabled={generatingLabel}
          onClick={() => {
            downloadLabel(
              currentRow?.deviceId as string
            )
            handleCloseMenu()
          }}
        >
          Download label
        </MenuItem>
        <MenuItem
          disabled={disabled}
          onClick={() => {
            if (!disabled) {
              updateSensorState({
                uniqueId: currentRow?.uniqueId as string,
                state: availableState as SensorState,
                onSuccess: () => {
                  refetchSensorList()
                  handleCloseMenu()
                },
              })
            }
          }}
        >
          Ready to use
        </MenuItem>
        <MenuItem
          disabled={isLoadingChangingSensorState}
          onClick={openSensorArchivingDialog}
        >
          Archive
        </MenuItem>
      </Menu>
      <ArchiveSensorModal
        sensorDeviceId={currentRow?.deviceId as string}
        isLoading={isLoadingChangingSensorState}
        onCancel={() => {
          closeSensorArchivingDialog()
          handleCloseMenu()
        }}
        onArchive={() => {
          updateSensorState({
            uniqueId: currentRow?.uniqueId as string,
            state: archivedState as SensorState,
            onSuccess: () => {
              refetchSensorList()
              handleCloseMenu()
            },
          })
        }}
      />
    </>
  ) : (
    <SensorsMapView
      data={data}
      isLoading={isLoading}
      filters={
        <ContractorsDataLoader>
          <SensorMapFilters
            activeFilters={[
              SensorFilters.Contractor,
              SensorFilters.LowBattery,
              SensorFilters.WeakSignal,
              SensorFilters.LastPingMoreThanHour,
              SensorFilters.SyncPeriod,
              SensorFilters.ReadingsAge,
              SensorFilters.LocationType,
            ]}
          />
        </ContractorsDataLoader>
      }
    />
  )
}

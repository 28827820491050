import { useAuthorized, useSignInRedirect } from 'src/hooks/ui'
import { useSignOutRequest } from '../api'

export const useSignOut = () => {
  const redirect = useSignInRedirect()
  const [, setAuthorized] = useAuthorized()
  const signOutRequest = useSignOutRequest()
  return () => {
    signOutRequest.mutate(undefined, {
      onSuccess: () => {
        setAuthorized(null)
        redirect()
      },
    })
  }
}

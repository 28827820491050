import React, { FC } from "react"
import { Grid, Typography } from "@mui/material"
import { ManufacturerAnnouncementPost } from "src/api"
import { OuterLink } from "src/components/ui"

interface ManufacturerAnnouncementPostCardProps {
  post: ManufacturerAnnouncementPost;
}

export const ManufacturerAnnouncementPostCard: FC<ManufacturerAnnouncementPostCardProps> = ({
  post
}) => {
  return (
    <Grid container direction="column" mt={2}>
      <Grid item>
        <Typography variant="body2" fontWeight={500}>{post.title}</Typography>
      </Grid>
      <Grid item mt={2}>
        <Typography variant="body2" whiteSpace="pre-line">{post.description}</Typography>
      </Grid>
      <Grid item mt={2}>
        <Typography variant="body2" whiteSpace="pre-line">
          YouTube link: {post.videoLink ? <OuterLink to={post.videoLink || ''}>{post.videoLink}</OuterLink> : '-'}
        </Typography>
      </Grid>
      {
        post.photo ? (
          <Grid item mt={3}>
            <img
              style={{
                width: "auto",
                maxWidth: "500px",
                height: "auto"
              }}
              src={post.photo || "../../no_image.png"}
              alt={post.title}
            />
          </Grid>
        ) : null
      }
    </Grid>
  )
}

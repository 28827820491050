import { FC } from 'react'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import {
  DataGridPremium,
  GridColDef,
} from '@mui/x-data-grid-premium'
import { useContractorRequest } from 'src/hooks/api'
import { EntityHeader, HasSidebarLayout } from 'src/components'
import { ContractorActivationHistoryColumns } from 'src/components/columns/contractor'
import { ContractorActivationHistory as ContractorActivationHistoryItem } from 'src/api'
import { useParams } from 'react-router-dom'

const columns: GridColDef[] = [
  ContractorActivationHistoryColumns.Status,
  ContractorActivationHistoryColumns.AuthorName,
  ContractorActivationHistoryColumns.AuthorPhone,
  ContractorActivationHistoryColumns.Datetime,
]

export const ContractorActivationHistory: FC = () => {
  const params = useParams()
  const id = parseInt((params as any).id, 10)
  const { data: contractor, isInitialLoading } = useContractorRequest({ id })
  const activationHistory = contractor?.activationHistory || []
  
  return (
    <HasSidebarLayout>
      <Grid container flexDirection="column">
        <Grid item>
          <EntityHeader
            title={`Activation history: ${contractor?.name || 'No contractor'}`}
          />
        </Grid>
        <Grid item>
          <Paper sx={{ flexGrow: 1, minHeight: '200px', height: "100%", width: "100%" }}>
            <DataGridPremium<ContractorActivationHistoryItem>
              rows={activationHistory}
              columns={columns}
              loading={isInitialLoading}
            />
          </Paper>
        </Grid>
      </Grid>
    </HasSidebarLayout>
  )
}

export default ContractorActivationHistory

import { useMutation, UseMutationOptions } from '@tanstack/react-query'
import { Entities } from 'src/enums'
import { FillMissedReadingsPayload, FillMissedReadingsResponse } from 'src/api'
import { useApiInstance } from './useApiInstance'

interface RequestParams {
  options?: Omit<UseMutationOptions<FillMissedReadingsResponse, Error, ExtendedFillMissedReadingsPayload>, 'mutationKey' | 'mutationFn'>
}
interface ExtendedFillMissedReadingsPayload extends FillMissedReadingsPayload {
  sensorUniqueId: string
}

export const useFillSensorMissedReadingsRequest = ({
  options,
}: RequestParams) => {
  const api = useApiInstance()

  return useMutation<FillMissedReadingsResponse, Error, ExtendedFillMissedReadingsPayload>(
    [Entities.SensorMissedReadings],
    async (payload: ExtendedFillMissedReadingsPayload) => {
      const { sensorUniqueId, ...rest } = payload
      const { data } = await api.v1AdminSensorsUniqueIdFillMissedReadingsPost(sensorUniqueId, rest)
      return data as FillMissedReadingsResponse
    },
    options,
  )
}

import React, { FC, useCallback } from 'react'
import { Grid, Typography } from '@mui/material'
import format from "date-fns/format"
import {
  InnerLink,
  PhoneLink,
  EmailLink,
  ReadonlyTextField,
} from 'src/components/ui'
import distanceInWords from "date-fns/formatDistance"
import { UserMessageChannel } from 'src/api'

interface UserInfoProps {
  phone: string;
  email: string;
  lastActive?: Date;
  lastAction?: { action: string, date: string };
  contractorId?: number;
  contractorName?: string;
  lastAppVersion?: string;
  messageChannel: UserMessageChannel;
}

export const UserInfo: FC<UserInfoProps> = (props) => {
  const {
    phone,
    email,
    lastActive,
    contractorId,
    contractorName,
    lastAppVersion,
    lastAction,
    messageChannel,
  } = props

  const messageChannelName = useCallback(() => messageChannel?.displayName || 'Not selected', [messageChannel])()

  return (
    <Grid container spacing={4}>
      <Grid item>
        <Typography variant="caption" color="gray">
          Organization
        </Typography>
        <Typography variant="body1">
          {contractorId ? (
            <InnerLink to={`/contractors/${contractorId}`} display="block">
              {contractorName}
            </InnerLink>
          ) : (
            '-'
          )}
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant="caption" color="gray">
          Phone Number
        </Typography>
        <Typography variant="body1">
          {phone ? <PhoneLink value={phone} /> : '-'}
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant="caption" color="gray">
          Email
        </Typography>
        <Typography variant="body1">
          {email ? <EmailLink value={email} /> : '-'}
        </Typography>
      </Grid>
      <Grid item>
        <ReadonlyTextField
          label="Mobile App Version"
          value={lastAppVersion || null}
        />
      </Grid>
      <Grid item>
        <ReadonlyTextField
          label="Last Active"
          value={lastActive ? format(new Date(lastActive), "PPpp") : null}
        />
      </Grid>
      <Grid item>
        <ReadonlyTextField
          label="Last Action"
          value={lastAction ? `${lastAction.action} (${distanceInWords(new Date(lastAction.date), new Date())})` : null}
        />
      </Grid>
      <Grid item>
        <ReadonlyTextField
          label="Sign in message channel"
          value={messageChannelName}
        />
      </Grid>
    </Grid>
  )
}

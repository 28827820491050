import { FC } from 'react'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import { ImportedProductItem } from 'src/api'

type Props = {
  importedProduct: ImportedProductItem
}

export const ImportedProduct: FC<Props> = ({ importedProduct }) => {
  const { manufacturerName, productName, errors } = importedProduct
  const fullName = [manufacturerName, productName].filter(Boolean).join(', ')
  return (
    <Box>
      <Typography variant="body1">{fullName}</Typography>
      <List sx={{ listStyleType: "disc", paddingLeft: '15px' }}>
        {(errors || []).map((error, index) => (
          <ListItem key={index} sx={{ display: 'list-item' }}>
            <Typography variant="body2" color="error">{error}</Typography>
          </ListItem>
        ))}
      </List>
    </Box>
  )
}

import { FC } from 'react'
import { Grid, Typography } from '@mui/material'
import { Table, DynamicColumn } from 'src/components/ui'
import { RapidRHSensorDashboardStatistics } from 'src/api'
import { RapidRHSensorDashboardColumns } from 'src/components/columns/dashboard'

interface SensorDashboardProps {
  rapidRHSensorStatistics: RapidRHSensorDashboardStatistics;
}

const columns: DynamicColumn<RapidRHSensorDashboardStatistics>[] = [
  RapidRHSensorDashboardColumns.SensorsNumber,
  RapidRHSensorDashboardColumns.InUseSensorsNumber,
]

export const RapidRHSensorDashboard: FC<SensorDashboardProps> = (props) => {
  const { rapidRHSensorStatistics } = props
  return (
    <Grid container spacing={2}>
      <Grid item>
        <Typography variant="h5">RapidRH Sensors</Typography>
      </Grid>
      <Grid item container>
        <Table
          columns={columns}
          data={[rapidRHSensorStatistics]}
          showFooter={false}
          sx={{ width: '100%' }}
        />
      </Grid>
    </Grid>

  )
}

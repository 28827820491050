import { FC, PropsWithChildren, useCallback, useState } from 'react'
import {
  DialogTitle,
  DialogActions,
  DialogContent,
  Button,
  Box,
} from '@mui/material'
import { Contractor } from 'src/api'
import { DataLoad, PropsInjector, Dropdown } from 'src/components'
import { useContractorListRequest } from 'src/hooks/api'
import { useDropdownItemFactory, useDialog } from 'src/hooks/ui'
import { DialogNames } from 'src/enums'

const ContractorsLoader: FC<PropsWithChildren> = ({ children }) => {
  const { isInitialLoading, isError, data } = useContractorListRequest({
    params: { limit: 9999 },
  })

  if (isInitialLoading || isError)
    return (
      <Box p={8}>
        <DataLoad isLoading={isInitialLoading} isError={isError} />
      </Box>
    )

  const props = { contractors: data?.rows || [] }

  return <PropsInjector props={props}>{children}</PropsInjector>
}

interface AssignSensorToContractorDialogProps {
  contractors?: Contractor[];
  onSubmit?: (contractorId: number | null) => void;
}

const NO_CONTRACTOR_ID = 'no_contractor'

const AssignSensorToContractorDialog: FC<AssignSensorToContractorDialogProps> =
  ({ contractors = [], onSubmit = () => {} }) => {
    const { closeDialog } = useDialog(DialogNames.AssignSensorToContractor)
    const [selectedContractorId, setSelectedIdContractor] = useState<any>(null)
    const update = (event: any, id: number) => {
      if (selectedContractorId !== id) setSelectedIdContractor(id)
    }
    const submit = () => {
      if (selectedContractorId !== null) {
        onSubmit(selectedContractorId === NO_CONTRACTOR_ID ? null : selectedContractorId as number)
        closeDialog()
      }
    }
    const dropdownItemListMap = useDropdownItemFactory({
      params: { labelProp: 'name', valueProp: 'id' },
    })
    const options = useCallback(() => {
      const _options: { id: number | string; name: string }[] = contractors.map(
        (contractor) => ({ id: contractor.id, name: contractor.name })
      )
      _options.unshift({ id: NO_CONTRACTOR_ID, name: 'No contractor' })
      return _options
    }, [contractors])()

    return (
      <>
        <DialogTitle>Select contractor</DialogTitle>
        <DialogContent>
          <Box width="400px">
            <Dropdown
              list={options}
              name="contractors-list"
              label="Contractors"
              value={selectedContractorId}
              menuItemFactory={dropdownItemListMap}
              onChange={update}
              onBlur={update}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={() => closeDialog()}>
            Cancel
          </Button>
          <Button
            variant="contained"
            disabled={selectedContractorId === null}
            onClick={submit}
          >
            Assign
          </Button>
        </DialogActions>
      </>
    )
  }

export const AssignSensorToContractor: FC<{
  onSubmit: (contractorId: number | null) => void;
}> = ({ onSubmit }) => {
  return (
    <ContractorsLoader>
      <AssignSensorToContractorDialog onSubmit={onSubmit} />
    </ContractorsLoader>
  )
}

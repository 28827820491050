import { FC } from 'react'
import { Task } from 'src/api'
import { useImagesViewer, useQueryParams } from 'src/hooks/ui'
import { useTaskListRequest } from 'src/hooks/api'
import { Box, Grid } from '@mui/material'
import { ImagesListItem } from 'src/contexts'
import { ImagesViewerStateProvider } from 'src/components/providers'
import { defaultRowsValuePerPage } from 'src/config'
import { TaskFilters, TaskList } from '../task'
import { TaskColumnsDataGrid } from '../task/tableColumns'

type Props = {
  tasks: Task[]
  count: number
  queryParams: Record<string, string>
  setQueryParams: (params: Record<string, string>, paramsToRemove?: string[]) => void
}

const defaultPerPage = 25
const defaultPage = 1
const defaultRowsPerPageOptions = defaultRowsValuePerPage
const initialQueryParams = {
  page: `${defaultPage}`,
  rowsPerPage: `${defaultPerPage}`,
}
const columns = ({
  openGallery,
}: {
  openGallery: (imgs: ImagesListItem[]) => void
}) => [
  TaskColumnsDataGrid.NameWithIcon,
  TaskColumnsDataGrid.Description,
  TaskColumnsDataGrid.Status,
  TaskColumnsDataGrid.Author,
  TaskColumnsDataGrid.Assignee,
  TaskColumnsDataGrid.Project,
  TaskColumnsDataGrid.ProjectSection,
  TaskColumnsDataGrid.CreatedAt,
  TaskColumnsDataGrid.Photo(openGallery)
]

const ContractorTasksContent: FC<Props> = ({
  tasks,
  count,
  queryParams,
  setQueryParams
}) => {
  const { openGallery } = useImagesViewer()
  return (
    <Grid container>
      <Grid item>
        <TaskFilters />
      </Grid>
      <Grid item width="100%">
        <Box height="500px">
          <TaskList
            data={tasks}
            columns={columns({ openGallery })}
            count={count}
            defaultRowsPerPageOptions={defaultRowsPerPageOptions}
            queryParams={queryParams}
            setQueryParams={setQueryParams}
          />
        </Box>
      </Grid>
    </Grid>
  )
}

export const ContractorTasks: FC<{ contractorId: number }> = ({
  contractorId,
}) => {
  const [queryParams, setQueryParams] = useQueryParams(initialQueryParams)
  const contractorTasksRequest = useTaskListRequest({
    params: { ...queryParams, contractorId },
  })

  return (
    <ImagesViewerStateProvider>
      <ContractorTasksContent
        tasks={contractorTasksRequest.data?.rows || []}
        count={contractorTasksRequest.data?.count || 0}
        queryParams={queryParams}
        setQueryParams={setQueryParams}
      />
    </ImagesViewerStateProvider>
  )
}

import {
  GridColDef,
} from "@mui/x-data-grid-premium"
import { ProductImport } from "src/api"
import { ProductImportColumns } from "src/components/columns/productImport"

export const productImportDefaultTableColumns = (): GridColDef<ProductImport>[] => {
  return [
    ProductImportColumns.Name,
    ProductImportColumns.Author,
    ProductImportColumns.Status,
    ProductImportColumns.CreatedAt,
  ]
}

import {
  getCountries,
  getCountryCallingCode,
  parseDigits,
} from 'libphonenumber-js'

const countryCodes = getCountries().map((e) => getCountryCallingCode(e))

export const formatPhone = (value: string | undefined | null) => {
  const digits = value ? parseDigits(value) : undefined
  if (!digits || countryCodes.includes(digits)) {
    return undefined
  }
  return digits
}

export const capitalizeFirstLetter = (value: string): string => {
  if (!value) return value

  return `${value.slice(0, 1).toUpperCase()}${value.slice(1)}`
}

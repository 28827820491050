import React, { FC } from 'react'
import { Grid } from '@mui/material'
import { useAddPopupMessage } from 'src/hooks/ui'
import { useNewProjectTypeRequest } from 'src/hooks/api'
import { ProjectTypeNewForm } from 'src/components/forms'

interface NewProjectTypeProps {
  onCreate: () => void;
}

export const NewProjectType: FC<NewProjectTypeProps> = ({ onCreate }) => {
  const addMessage = useAddPopupMessage()

  const newProjectTypeRequest = useNewProjectTypeRequest()
  const createProjectType = ({ displayName }: { displayName: string }) => {
    newProjectTypeRequest.mutate(
      { displayName },
      {
        onSuccess: () => onCreate(),
        onError: (error) => addMessage({ type: 'error', text: error.message }),
      }
    )
  }

  return (
    <>
      <Grid item flexGrow={1} sx={{ pt: 1, pb: 2 }}>
        <ProjectTypeNewForm onSubmit={createProjectType} />
      </Grid>
    </>
  )
}
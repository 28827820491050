import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { Entities } from 'src/enums'
import {
  SensorExtendedDiagnostics,
} from 'src/api'
import { useApiInstance } from './useApiInstance'

interface RequestParams {
  uniqueId: string;
  options?: Omit<
    UseQueryOptions<SensorExtendedDiagnostics, Error>,
    'queryKey' | 'queryFn'
  >;
}

export const useSensorDiagnostics = ({
  uniqueId,
  options,
}: RequestParams) => {
  const api = useApiInstance()

  return useQuery<SensorExtendedDiagnostics, Error>(
    [Entities.Sensors, `get-extended-diagnostics-${uniqueId}`],
    async () => {
      const { data } = await api.v1AdminSensorsUniqueIdExtendedDiagnosticsGet(uniqueId)
      return data as SensorExtendedDiagnostics
    },
    { ...options },
  )
}

import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { Entities } from 'src/enums'
import { useApiInstance } from './useApiInstance'

interface RequestParams {
  timestamp: number,
  options?: Omit<
    UseQueryOptions<unknown, Error, string>,
    'queryKey' | 'queryFn'
  >;
}

export const useImportProductTemplateLinkRequest = ({
  timestamp,
  options,
}: RequestParams = { timestamp: new Date().getDate() }) => {
  const api = useApiInstance()

  return useQuery<unknown, Error, string>(
    [Entities.ImportProductTemplateLink, timestamp],
    async () => {
      const { data } = await api.v1AdminProductsImportTemplateLinkGet()
      return data
    },
    options,
  )
}

import { useMutation, UseMutationOptions } from '@tanstack/react-query'
import { Entities } from 'src/enums'
import { ProductTypeTipsUpdate, ProductTypeTip } from 'src/api'
import { useApiInstance } from './useApiInstance'

interface RequestParams {
  productTypeId: number;
  options?: Omit<
    UseMutationOptions<unknown, Error, ProductTypeTipsUpdate, ProductTypeTip>,
    'mutationKey' | 'mutationFn'
  >;
}

export const useUpdateProductTypeTipRequest = ({ productTypeId, options }: RequestParams) => {
  const api = useApiInstance()

  return useMutation<unknown, Error, ProductTypeTipsUpdate, ProductTypeTip>(
    [Entities.ProductTypesTips, 'update'],
    async (payload: ProductTypeTipsUpdate) => {
      const { data } = await api.v1AdminProductsTypesProductTypeIdTipsPut(
        productTypeId,
        payload,
      )
      return data
    },
    {
      ...options,
    },
  )
}

import React, { FC, useMemo } from 'react'
import {
  DialogTitle,
  DialogActions,
  DialogContent,
  Button,
  CircularProgress,
  DialogContentText,
} from '@mui/material'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import { StatefulDialog } from 'src/components'
import { DialogNames } from 'src/enums'
import { BleManageState } from 'src/interfaces'

export const ManageSensorBleModal: FC<{
  onSubmit: (state: BleManageState) => void
  onClose: () => void
  bleState: BleManageState
  deviceId: string | string[]
  isLoading?: boolean
}> = ({ onSubmit, onClose, isLoading, bleState, deviceId }) => {
  const dialogName = DialogNames.ManageSensorBle
  const handleClose = () => onClose()
  const deviceIds = useMemo(() => (Array.isArray(deviceId) ? deviceId : [deviceId]), [deviceId])

  return (
    <StatefulDialog
      name={dialogName}
      onClose={handleClose}
      actions={
        <DialogActions>
          <Button onClick={handleClose} disabled={isLoading}>
            Close
          </Button>
          <Button
            onClick={() => onSubmit(bleState)}
            color="error"
            disabled={isLoading}
          >
            {isLoading ? <CircularProgress size={22} /> : bleState === BleManageState.ON ? 'Enable' : 'Disable'}
          </Button>
        </DialogActions>
      }
    >
      <DialogTitle id="alert-dialog-title">
        Manage sensor BLE
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure you want to <strong>{bleState === BleManageState.ON ? 'enable' : 'disable'}</strong> BLE for the following GAT sensors?
        </DialogContentText>
        <List dense>
          {deviceIds.map((id) => (
            <ListItem key={id}>
              <ListItemText primary={id} />
            </ListItem>
          ))}
        </List>
      </DialogContent>
    </StatefulDialog>
  )
}

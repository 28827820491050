import { useMutation, UseMutationOptions } from '@tanstack/react-query'
import { Entities } from 'src/enums'
import { useApiInstance } from './useApiInstance'

interface RequestParams {
  id: number;
  options?: Omit<
    UseMutationOptions<unknown, Error, {}, boolean>,
    'mutationKey' | 'mutationFn'
  >;
}

export const useDeleteManufacturerAnnouncementRequest = ({ id, options }: RequestParams) => {
  const api = useApiInstance()

  return useMutation<unknown, Error, {}, boolean>(
    [Entities.ManufacturerAnnouncement, 'delete'],
    async () => {
      const { data } = await api.v1AdminManufacturersAnnouncementsManufacturerAnnouncementIdDelete(id)
      return data
    },
    {
      ...options,
    },
  )
}

import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { Entities } from 'src/enums'
import { UserGlobalNotification } from 'src/api'
import { useApiInstance } from './useApiInstance'

interface RequestParams {
  userId: number
  options?: Omit<
    UseQueryOptions<UserGlobalNotification[], Error>,
    'queryKey' | 'queryFn'
  >;
}

export const useUserGlobalNotificationsRequest = ({
  userId,
  options,
}: RequestParams) => {
  const api = useApiInstance()

  return useQuery<UserGlobalNotification[], Error>(
    [Entities.UserNotifications, 'by-type', userId],
    async () => {
      const { data } = await api.v1AdminUsersUserIdNotificationsByTypeGet(userId)
      return data as unknown as UserGlobalNotification[]
    },
    options,
  )
}

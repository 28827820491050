import React, { FC, PropsWithChildren } from "react"
import { Button, Grid, Typography } from "@mui/material"
import { useNavigate, useParams } from "react-router-dom"
import { APIList } from "src/interfaces"
import {
  HasSidebarLayout,
  DataLoad,
  EntityHeader,
  InnerLink,
  ProductInfo,
  PropsInjector,
  DeleteProductModal,
  ProjectList,
} from "src/components"
import {
  useDeleteProductRequest,
  useProductRequest,
  useProductsTipTypesRequest,
  useProjectListRequest,
} from "src/hooks/api"
import { Product, ProductTipType, Project } from "src/api"
import {
  useDialog,
  useAddPopupMessage,
  useQueryParamsState,
} from "src/hooks/ui"
import { DialogNames } from "src/enums"

interface ProductShowContentProps {
  product?: Product;
  productsTipTypes?: APIList<ProductTipType>;
  projects?: Project[];
  projectsCount?: number;
  projectsLoading?: boolean;
  onDeleteProduct?: (id: number) => void;
}

const ProductShowContent: FC<ProductShowContentProps> = ({
  product = null,
  productsTipTypes = null,
  projects = [],
  projectsCount = 0,
  projectsLoading = false,
  onDeleteProduct = () => {},
}) => {
  const { openDialog, closeDialog } = useDialog(DialogNames.DeleteProduct)

  if (!product) return null

  const { name, manufacturer } = product
  document.title = `Floorcloud Admin Panel - Product ${name}`

  const onDelete = () => {
    onDeleteProduct(product.id)
    closeDialog()
  }

  return (
    <>
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <EntityHeader
            title={name}
            actionBlockOnTheRight={<Button
              variant="outlined"
              size="large"
              color="error"
              onClick={openDialog}
              sx={{ width: 210 }}
            >
              Delete Product
            </Button>}
          />
        </Grid>
        {manufacturer && (
          <Grid item>
            <InnerLink to={`/manufacturers/${manufacturer.id}`} variant="h6">
              {manufacturer.name}
            </InnerLink>
          </Grid>
        )}
        <Grid item container>
          <Typography variant="h6" sx={{ mb: 1 }}>
            Product Information
          </Typography>
          <ProductInfo
            product={product}
            productTipTypes={productsTipTypes?.rows || []}
          />
        </Grid>
        <Grid item container>
          <Typography variant="h6" sx={{ mb: 1 }}>
            Projects
          </Typography>
          <ProjectList
            count={projectsCount}
            data={projects}
            loading={projectsLoading}
          />
        </Grid>
      </Grid>
      <DeleteProductModal
        productName={name}
        onCancel={() => closeDialog()}
        onDelete={onDelete}
      />
    </>
  )
}

const ProductDataLoader: FC<PropsWithChildren<{ productId: number }>> = ({
  productId,
  children,
}) => {
  const navigate = useNavigate()
  const addMessage = useAddPopupMessage()
  const params = useQueryParamsState()

  const productRequest = useProductRequest({
    id: productId,
  })

  const productsTipTypesRequest = useProductsTipTypesRequest({
    params: { limit: 9999, sort: "name:asc" },
  })

  const projectsRequest = useProjectListRequest({
    params: {
      productId,
      ...params,
    },
  })

  const deleteProductRequest = useDeleteProductRequest({
    options: {
      onError: ({ message: text }) => {
        addMessage({ type: "error", text })
      },
      onSuccess: () => {
        navigate(-1)
        addMessage({ type: "success", text: "Product successfully deleted" })
      },
    },
  })

  const onDeleteProduct = (id: number) => {
    deleteProductRequest.mutate({ id })
  }

  const isLoading =
    productRequest.isInitialLoading || productsTipTypesRequest.isInitialLoading

  const isError =
    productRequest.isError ||
    productsTipTypesRequest.isError ||
    projectsRequest.isError

  const props = {
    onDeleteProduct,
    product: productRequest.data || null,
    productsTipTypes: productsTipTypesRequest.data || null,
    projects: projectsRequest?.data?.rows || [],
    projectsCount: projectsRequest?.data?.count || 0,
    projectsLoading: projectsRequest.isInitialLoading,
  }

  return (
    <DataLoad isLoading={isLoading} isError={isError}>
      <PropsInjector props={props}>{children}</PropsInjector>
    </DataLoad>
  )
}

const ProductShow: FC = () => {
  const params = useParams()
  const { id } = params as { id: string }

  return (
    <HasSidebarLayout>
      <ProductDataLoader productId={parseInt(id, 10)}>
        <ProductShowContent />
      </ProductDataLoader>
    </HasSidebarLayout>
  )
}

export default ProductShow

import React from 'react'

export const LogoVertical = () => {
  return (
    <svg viewBox="0 0 235 171" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_645:15119)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M109.6 0.300049C122.5 0.300049 134.1 5.60005 142.4 14.2C148.1 11.2 154.7 9.40005 161.6 9.40005C184.3 9.40005 202.6 27.8001 202.6 50.4001C202.6 71.5001 186.6 88.9 166.1 91.2C165.3 115.7 145.2 135.2 120.5 135.2C107.6 135.2 96 129.9 87.7 121.3C82 124.3 75.4 126.1 68.5 126.1C45.8 126.1 27.5 107.7 27.5 85.1C27.5 64 43.5 46.6 64 44.3C64.8 19.8 84.9 0.300049 109.6 0.300049Z"
          fill="#0071CE"
        />
        <path
          d="M161.6 9.1C154.9 9.1 148.3 10.7 142.4 13.8C133.7 4.9 122.1 0 109.6 0C97.5998 0 86.2998 4.6 77.6998 12.9C69.1998 21.2 64.1998 32.2 63.7998 44C42.9998 46.4 27.2998 64 27.2998 85C27.2998 107.8 45.7998 126.3 68.5998 126.3C75.2998 126.3 81.8998 124.7 87.7998 121.6C96.4998 130.5 108.2 135.4 120.6 135.4C132.6 135.4 143.9 130.8 152.5 122.5C161 114.2 166 103.2 166.4 91.4C187.2 89 202.9 71.4 202.9 50.4C202.9 27.6 184.3 9.1 161.6 9.1ZM107.1 73.2C106.3 72.8 105.3 72.9 104.6 73.4L88.6998 85.5C88.0998 86 87.7998 86.6 87.7998 87.4C87.7998 88.2 88.0998 88.8 88.6998 89.3L104.6 101.4C105.3 102 106.3 102 107.1 101.6C107.9 101.2 108.4 100.4 108.4 99.5V91.7H158.5C157.4 111.9 140.8 127.7 120.5 127.7C109.3 127.7 98.6998 122.8 91.3998 114.2L89.1998 111.6L86.2998 113.4C80.9998 116.8 74.7998 118.6 68.3998 118.6C49.8998 118.6 34.8998 103.6 34.8998 85.1C34.8998 66.6 49.8998 51.6 68.3998 51.6H116.2V59.8C116.2 60.7 116.7 61.5 117.5 61.9C118.3 62.3 119.3 62.2 120 61.7L135.9 49.6C136.5 49.1 136.8 48.5 136.8 47.7C136.8 46.9 136.5 46.3 135.9 45.8L120 33.7C119.3 33.1 118.3 33.1 117.5 33.5C116.7 33.9 116.2 34.7 116.2 35.6V43.7H71.5998C72.6998 23.5 89.2998 7.7 109.6 7.7C120.8 7.7 131.4 12.6 138.7 21.2L140.9 23.8L143.8 22C149.1 18.6 155.3 16.8 161.7 16.8C180.2 16.8 195.2 31.8 195.2 50.3C195.2 68.8 180.2 83.8 161.7 83.8H108.5V75.2C108.4 74.4 107.9 73.6 107.1 73.2Z"
          fill="white"
        />
        <path
          d="M0 168.5V150C0 149.6 0.1 149.3 0.4 149C0.7 148.7 1 148.6 1.4 148.6H18.5C18.9 148.6 19.2 148.7 19.5 149C19.8 149.3 19.9 149.6 19.9 150V151.4C19.9 151.8 19.8 152.1 19.5 152.4C19.2 152.7 18.9 152.8 18.5 152.8H5.6V158H16.1C16.5 158 16.8 158.1 17.1 158.4C17.4 158.7 17.5 159 17.5 159.4V160.8C17.5 161.2 17.4 161.5 17.1 161.8C16.8 162.1 16.5 162.2 16.1 162.2H5.6V168.4C5.6 168.8 5.5 169.1 5.2 169.4C4.9 169.7 4.6 169.8 4.2 169.8H1.4C1 169.8 0.7 169.7 0.4 169.4C0.1 169.1 0 169 0 168.5Z"
          fill="#2D2A26"
        />
        <path
          d="M21.4004 168.6V150.1C21.4004 149.7 21.5004 149.4 21.8004 149.1C22.1004 148.8 22.4004 148.7 22.8004 148.7H25.6004C26.0004 148.7 26.3004 148.8 26.6004 149.1C26.9004 149.4 27.0004 149.7 27.0004 150.1V165.8H40.0004C40.4004 165.8 40.7004 165.9 41.0004 166.2C41.3004 166.5 41.4004 166.8 41.4004 167.2V168.6C41.4004 169 41.3004 169.3 41.0004 169.6C40.7004 169.9 40.4004 170 40.0004 170H22.9004C22.5004 170 22.2004 169.9 21.9004 169.6C21.6004 169.3 21.4004 169 21.4004 168.6Z"
          fill="#2D2A26"
        />
        <path
          d="M61.8996 167.8C59.8996 169.6 56.8996 170.5 52.9996 170.5C49.0996 170.5 46.0996 169.6 44.0996 167.8C42.0996 166 41.0996 163.2 41.0996 159.4C41.0996 155.6 42.0996 152.7 44.0996 151C46.0996 149.3 49.0996 148.3 52.9996 148.3C56.8996 148.3 59.8996 149.2 61.8996 151C63.8996 152.8 64.8996 155.6 64.8996 159.4C64.8996 163.2 63.8996 166 61.8996 167.8ZM57.7996 154C56.7996 152.8 55.1996 152.2 52.8996 152.2C50.5996 152.2 48.9996 152.8 47.9996 154C46.9996 155.2 46.4996 157 46.4996 159.3C46.4996 161.7 46.9996 163.4 47.9996 164.6C48.9996 165.8 50.5996 166.4 52.8996 166.4C55.1996 166.4 56.6996 165.8 57.7996 164.6C58.7996 163.4 59.2996 161.6 59.2996 159.3C59.2996 157 58.7996 155.2 57.7996 154Z"
          fill="#2D2A26"
        />
        <path
          d="M86.8 167.8C84.8 169.6 81.8 170.5 77.9 170.5C74 170.5 71 169.6 69 167.8C67 166 66 163.2 66 159.4C66 155.6 67 152.7 69 151C71 149.3 74 148.3 77.9 148.3C81.8 148.3 84.8 149.2 86.8 151C88.8 152.8 89.8 155.6 89.8 159.4C89.8 163.2 88.8 166 86.8 167.8ZM82.7 154C81.7 152.8 80.1 152.2 77.8 152.2C75.5 152.2 74 152.8 73 154C72 155.2 71.5 157 71.5 159.3C71.5 161.7 72 163.4 73 164.6C74 165.8 75.6 166.4 77.9 166.4C80.2 166.4 81.7 165.8 82.8 164.6C83.8 163.4 84.3 161.6 84.3 159.3C84.2 157 83.7 155.2 82.7 154Z"
          fill="#2D2A26"
        />
        <path
          d="M113.6 169.3C113.6 169.7 113.3 169.9 112.8 169.9H109.2C108.8 169.9 108.4 169.8 108 169.5C107.5 169.2 107.2 168.9 107 168.5L104.5 164.5C103.5 162.9 102.2 162.1 100.7 162.1H97.1V168.5C97.1 168.9 97 169.2 96.7 169.5C96.4 169.8 96.1 169.9 95.7 169.9H92.9C92.5 169.9 92.2 169.8 91.9 169.5C91.6 169.2 91.5 168.9 91.5 168.5V150C91.5 149.6 91.6 149.3 91.9 149C92.2 148.7 92.5 148.6 92.9 148.6H105.2C107.5 148.6 109.3 149.2 110.8 150.4C112.3 151.6 113 153.3 113 155.3C113 158.2 111.1 160 107.4 160.7C108 160.9 108.5 161.3 109 161.8C109.5 162.3 110.1 163.1 110.7 164.1L113.4 168.4C113.5 168.9 113.6 169.1 113.6 169.3ZM97.1 158H103.4C104.4 158 105.3 157.8 106.1 157.4C106.9 157 107.3 156.3 107.3 155.5C107.3 154.6 106.9 154 106.1 153.6C105.3 153.2 104.4 153 103.4 153H97.1V158Z"
          fill="#2D2A26"
        />
        <path
          d="M116.9 167.5C115.1 165.7 114.1 163 114.1 159.4C114.1 155.8 115 153 116.9 151.3C118.8 149.6 121.5 148.7 125.2 148.7H128.5C130.7 148.7 132.7 149.3 134.5 150.5C136.2 151.7 137.1 153.3 137.1 155.3C137.1 155.9 136.9 156.3 136.6 156.6C136.3 156.9 135.9 157 135.6 157H133.8C132.9 157 132.2 156.5 131.7 155.4C131 153.8 129.7 153.1 128 153.1H125.4C123.2 153.1 121.8 153.6 121 154.5C120.2 155.4 119.8 157.2 119.8 159.7C119.8 162.2 120.2 163.9 121 164.9C121.8 165.8 123.2 166.3 125.4 166.3H128.4C130.1 166.3 131.3 165.5 132 164C132.5 162.9 133.2 162.4 134.1 162.4H135.9C136.4 162.4 136.7 162.6 137 162.9C137.3 163.2 137.4 163.6 137.4 164C137.4 166 136.5 167.6 134.8 168.8C133.1 170 131.1 170.5 128.8 170.5H125.2C121.4 170.1 118.7 169.2 116.9 167.5Z"
          fill="#2D2A26"
        />
        <path
          d="M138.8 168.6V150.1C138.8 149.7 138.9 149.4 139.2 149.1C139.5 148.8 139.8 148.7 140.2 148.7H143C143.4 148.7 143.7 148.8 144 149.1C144.3 149.4 144.4 149.7 144.4 150.1V165.8H157.3C157.7 165.8 158 165.9 158.3 166.2C158.6 166.5 158.7 166.8 158.7 167.2V168.6C158.7 169 158.6 169.3 158.3 169.6C158 169.9 157.7 170 157.3 170H140.2C139.8 170 139.5 169.9 139.2 169.6C138.9 169.3 138.8 169 138.8 168.6Z"
          fill="#2D2A26"
        />
        <path
          d="M179.2 167.8C177.2 169.6 174.2 170.5 170.3 170.5C166.4 170.5 163.4 169.6 161.4 167.8C159.4 166 158.4 163.2 158.4 159.4C158.4 155.6 159.4 152.7 161.4 151C163.4 149.3 166.4 148.3 170.3 148.3C174.2 148.3 177.2 149.2 179.2 151C181.2 152.8 182.2 155.6 182.2 159.4C182.2 163.2 181.3 166 179.2 167.8ZM175.2 154C174.2 152.8 172.6 152.2 170.3 152.2C168 152.2 166.4 152.8 165.4 154C164.4 155.2 163.9 157 163.9 159.3C163.9 161.7 164.4 163.4 165.4 164.6C166.4 165.8 168 166.4 170.3 166.4C172.6 166.4 174.1 165.8 175.2 164.6C176.2 163.4 176.7 161.6 176.7 159.3C176.7 157 176.2 155.2 175.2 154Z"
          fill="#2D2A26"
        />
        <path
          d="M183.9 161.6V150.1C183.9 149.7 184 149.4 184.3 149.1C184.6 148.8 184.9 148.7 185.3 148.7H188.1C188.5 148.7 188.8 148.8 189.1 149.1C189.4 149.4 189.5 149.7 189.5 150.1V161.6C189.5 163.1 189.8 164.1 190.5 164.9C191.2 165.7 192.1 166 193.5 166H195.9C197.2 166 198.2 165.6 198.9 164.9C199.5 164.2 199.9 163.1 199.9 161.6V150.1C199.9 149.7 200 149.4 200.3 149.1C200.6 148.8 200.9 148.7 201.3 148.7H204.1C204.5 148.7 204.8 148.8 205.1 149.1C205.4 149.4 205.5 149.7 205.5 150.1V161.6C205.5 164.3 204.7 166.4 203.1 167.9C201.5 169.4 199.2 170.1 196.1 170.1H193.4C190.3 170.1 188 169.4 186.4 167.9C184.7 166.4 183.9 164.3 183.9 161.6Z"
          fill="#2D2A26"
        />
        <path
          d="M207.2 168.6V150.1C207.2 149.7 207.3 149.4 207.6 149.1C207.9 148.8 208.2 148.7 208.6 148.7H216.9C221.2 148.7 224.3 149.6 226.3 151.3C228.3 153 229.2 155.7 229.2 159.4C229.2 163.1 228.2 165.8 226.3 167.5C224.3 169.2 221.2 170.1 216.9 170.1H208.6C208.2 170.1 207.9 170 207.6 169.7C207.3 169.4 207.2 169 207.2 168.6ZM212.8 165.8H216.8C219.5 165.8 221.3 165.3 222.2 164.3C223.2 163.3 223.6 161.7 223.6 159.3C223.6 156.9 223.1 155.3 222.2 154.3C221.2 153.3 219.4 152.8 216.8 152.8H212.8V165.8Z"
          fill="#2D2A26"
        />
        <path
          d="M227.9 148.8V148.5C227.9 148.4 228 148.3 228.1 148.3H230.7C230.8 148.3 230.9 148.4 230.9 148.5V148.8C230.9 148.9 230.8 149 230.7 149H229.8V151.1V151.2L229.7 151.3H229.1C229.1 151.3 229 151.3 229 151.2V151.1V149H228C227.9 149 227.9 148.9 227.9 148.8ZM231.1 151.1V148.5C231.1 148.4 231.2 148.3 231.3 148.3H232.2C232.3 148.3 232.4 148.4 232.4 148.5L233.1 150.1L233.8 148.5C233.9 148.4 233.9 148.3 234 148.3H234.9C235 148.3 235.1 148.4 235.1 148.5V151.1V151.2L235 151.3H234.4C234.4 151.3 234.3 151.3 234.3 151.2L234.2 151.1V149.4L233.5 151.1C233.4 151.2 233.3 151.3 233.2 151.3H232.9C232.8 151.3 232.7 151.2 232.6 151.1L231.9 149.4V151.1V151.2L231.8 151.3H231.2C231.2 151.3 231.1 151.3 231.1 151.2C231.1 151.2 231.1 151.1 231.1 151.1Z"
          fill="#2D2A26"
        />
      </g>
      <defs>
        <clipPath id="clip0_645:15119">
          <rect width="235" height="170.4" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

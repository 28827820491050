import React, { FC, PropsWithChildren, ReactElement } from 'react'
import { TableCell, TableCellProps } from '@mui/material'
import { TextCell, TextCellProps } from './textCell'
import { DigitCell, DigitCellProps } from './digitCell'
import { DateCell, DateCellProps } from './dateCell'
import { EmailCell, EmailCellProps } from './emailCell'
import { PhoneCell, PhoneCellProps } from './phoneCell'
import { InnerLink, OuterLink } from '../link'

interface DynamicCellData<T> {
  field: string;
  row: T;
  first: boolean;
  last: boolean;
  innerLink?: (row: T) => string;
  outerLink?: (row: T) => string;
}

interface DynamicTextCell<T>
  extends DynamicCellData<T>,
    Omit<TextCellProps, 'value'> {
  type: 'string';
}

interface DynamicDigitCell<T>
  extends DynamicCellData<T>,
    Omit<DigitCellProps, 'value'> {
  type: 'digit';
}

interface DynamicDateCell<T>
  extends DynamicCellData<T>,
    Omit<DateCellProps, 'value'> {
  type: 'date';
}

interface DynamicEmailCell<T>
  extends DynamicCellData<T>,
    Omit<EmailCellProps, 'value'> {
  type: 'email';
}

interface DynamicPhoneCell<T>
  extends DynamicCellData<T>,
    Omit<PhoneCellProps, 'value'> {
  type: 'phone';
}

interface DynamicCustomCell<T> extends DynamicCellData<T> {
  type: 'custom';
  component: (props: { row: T } & TableCellProps) => ReactElement;
}

type DynamicCellProps<T> =
  | DynamicTextCell<T>
  | DynamicDigitCell<T>
  | DynamicDateCell<T>
  | DynamicEmailCell<T>
  | DynamicPhoneCell<T>
  | DynamicCustomCell<T>;

export const DynamicCell = <T extends Record<string, any>>({
  type,
  row,
  field,
  innerLink,
  outerLink,
  ...rest
}: DynamicCellProps<T>) => {
  const path = field.split('.')
  let value: any
  try {
    value = path.reduce((record, chank) => {
      if (record === null) return record
      return record[chank]
    }, row)
  } catch (e) {
    return <TableCell>-</TableCell>
  }

  let Link: FC<PropsWithChildren> | undefined = void 0
  if (innerLink) {
    const to = innerLink(row)
    if (to) {
      Link = ({ children }) => (
        <InnerLink to={innerLink(row)}>{children}</InnerLink>
      )
    }
  }
  else if (outerLink) {
    const to = outerLink(row)
    if (to) {
      Link = ({ children }) => (
        <OuterLink to={outerLink(row)}>{children}</OuterLink>
      )
    }
  }
  switch (type) {
  case 'string':
    return (
      <TextCell
        {...(rest as Omit<TextCellProps, 'value'>)}
        Link={Link}
        value={value}
      />
    )
  case 'digit':
    return (
      <DigitCell
        {...(rest as Omit<DigitCellProps, 'value'>)}
        Link={Link}
        value={value}
      />
    )
  case 'date':
    return (
      <DateCell
        {...(rest as Omit<DateCellProps, 'value'>)}
        Link={Link}
        value={value}
      />
    )
  case 'email':
    return (
      <EmailCell {...(rest as Omit<EmailCellProps, 'value'>)} value={value} />
    )
  case 'phone':
    return (
      <PhoneCell {...(rest as Omit<PhoneCellProps, 'value'>)} value={value} />
    )
  case 'custom':
    const { component: Component, ...restProps } =
        rest as DynamicCustomCell<T> & TableCellProps
    if (!Component) return <TableCell>Invalid dynamic cell</TableCell>
    return <Component {...restProps} row={row} />
  default:
    return <TableCell>Invalid cell</TableCell>
  }
}

import { FC } from 'react'
import { ImagesViewerStateProvider } from 'src/components'
import { useEventListRequest } from 'src/hooks/api'
import { useQueryParams } from 'src/hooks/ui'
import Grid from '@mui/material/Grid'
import { EventList, EventListFilters } from '../event'

interface ProjectEventListProps {
  projectId: number;
}

const defaultPerPage = 50
const defaultPage = 1
const initialQueryParams = {
  page: `${defaultPage}`,
  rowsPerPage: `${defaultPerPage}`,
}

export const ProjectEventList: FC<ProjectEventListProps> = ({
  projectId,
}) => {
  const [queryParams, setQueryParams] = useQueryParams(initialQueryParams)
  const { isInitialLoading, data } = useEventListRequest({
    params: {
      projectId,
      ...queryParams,
    },
  })
  const exportedFileName = `${document.title}_events`

  return (
    <Grid container flexDirection="column" spacing={2}>
      <Grid item xs={12}>
        <EventListFilters />
      </Grid>
      <Grid item xs={12}>
        <ImagesViewerStateProvider>
          <EventList
            eventCount={data?.count || 0}
            events={data?.rows || []}
            isLoadingEvents={isInitialLoading}
            setQueryParams={setQueryParams}
            queryParams={queryParams}
            height='700px'
            exportedFileName={exportedFileName}
          />
        </ImagesViewerStateProvider>
      </Grid>
    </Grid>
  )
}

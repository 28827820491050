import React, { FC, useState } from 'react'
import { useFormik, FormikProvider, Form } from 'formik'
import { useQueryParams } from 'src/hooks/ui'
import { SearchParamsKeys, RapidRhFilters } from 'src/enums'
import { Box, Chip, FormControl, Grid, InputLabel, MenuItem, OutlinedInput, Select, SelectChangeEvent } from '@mui/material'

interface RapidRhListFiltersProps {
  activeFilters: RapidRhFilters[];
}

export const RapidRhListFilters: FC<RapidRhListFiltersProps> = ({ activeFilters }) => {
  const [queryParams, setQueryParams, removeQueryParams] = useQueryParams()
  const [hasConnectivityToProjectFilter] = useState(
    activeFilters.indexOf(RapidRhFilters.ConnectivityToProject) !== -1
  )

  const formik = useFormik({
    initialValues: {
      ...(hasConnectivityToProjectFilter
        ? {
          [SearchParamsKeys.ConnectivityToProject]: queryParams[SearchParamsKeys.ConnectivityToProject] || 'all'
        }
        : {}
      ),
    },
    onSubmit: async (formState) => {
      const connectedToProject = formState[SearchParamsKeys.ConnectivityToProject] || null
      if (connectedToProject === 'all') {
        await removeQueryParams([SearchParamsKeys.ConnectivityToProject])
      } else {
        await setQueryParams(
          {
            ...(hasConnectivityToProjectFilter && connectedToProject ? { [SearchParamsKeys.ConnectivityToProject]: `${connectedToProject}` } : {}),
          },
          [
            SearchParamsKeys.ConnectivityToProject,
          ]
        )
      }
    },
  })

  const { submitForm, values, setValues } = formik

  const connectivityToProjectOptions = [
    { name: 'All', value: 'all' },
    { name: 'Connected', value: 'true' },
    { name: 'Not connected', value: 'false' },
  ]

  const onChangeConnectivity = (event: SelectChangeEvent<typeof values['connectedToProject']>) => {
    const { target: { value } } = event
    setValues({
      connectedToProject: `${value}`
    })
    submitForm()
  }

  return (
    <FormikProvider value={formik}>
      <Form>
        <Grid container alignItems="center" spacing={4}>
          {hasConnectivityToProjectFilter && (
            <Grid item>
              <FormControl sx={{ mb: 1, width: 400 }}>
                <InputLabel id="connectivity-to-project-chip-label">Connectivity to project</InputLabel>
                <Select
                  labelId="connectivity-to-project-chip-label"
                  id="connectivity-to-project-chip"
                  value={values[SearchParamsKeys.ConnectivityToProject] || ''}
                  onChange={onChangeConnectivity}
                  input={<OutlinedInput id="select-chip" label="Connectivity to project" />}
                  renderValue={(selected) => {
                    const connectivityItem = connectivityToProjectOptions.find((item) => `${item.value}` === selected)
                    return (
                      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                        <Chip key={`${connectivityItem?.value}`} label={connectivityItem?.name} />
                      </Box>
                    )
                  }}
                >
                  {
                    connectivityToProjectOptions.map((item) => (
                      <MenuItem
                        key={`${item?.value}`}
                        value={`${item?.value}`}
                      >
                        {item?.name}
                      </MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            </Grid>
          )}
        </Grid>
      </Form>
    </FormikProvider>
  )
}

import { useMutation, UseMutationOptions } from '@tanstack/react-query'
import { Entities } from 'src/enums'
import { UploadedFileResult } from 'src/api'
import axios, { AxiosResponseTransformer } from 'axios'
import { useApiInstance } from './useApiInstance'
import { useSignInRedirect } from '../ui'

interface RequestParams {
  options?: Omit<UseMutationOptions<UploadedFileResult[], Error, RequestUpload, Array<UploadedFileResult>>, 'mutationKey' | 'mutationFn'>
}

interface RequestUpload {
  files?: File[]
}

export const useUploadFileRequest = ({
  options = {},
}: RequestParams = {}) => {
  const api = useApiInstance()
  const redirect = useSignInRedirect()

  return useMutation<UploadedFileResult[], Error, RequestUpload, Array<UploadedFileResult>>(
    [Entities.Photo, 'upload'],
    async (payload: RequestUpload) => {
      const { files } = payload
      let fileResult: Array<UploadedFileResult> = []
      if (files && files.length > 0) {
        await api.v1MediaFilePut(files, undefined, {
          transformResponse: [
            ...(axios.defaults.transformResponse as AxiosResponseTransformer[]),
            ({ error, result, statusCode }) => {
              if (error) {
                if (statusCode === 401) redirect()
                throw new Error(error)
              }
              fileResult = result
            },
          ],
        })
      }
      return fileResult
    },
    {
      ...options,
    },
  )
}

import React, { FC } from 'react'
import { Typography } from '@mui/material'

interface ReadonlyTextFieldProps {
  label: string;
  value: string | number | null | undefined;
  empty?: string;
  prefix?: string;
  suffix?: string;
}

export const ReadonlyTextField: FC<ReadonlyTextFieldProps> = ({
  label,
  value,
  empty = '-',
  prefix: prefixValue,
  suffix: suffixValue,
}) => {
  const hasValue = value !== null && value !== void 0
  const result = hasValue ? value : empty
  const prefix = hasValue ? prefixValue : ''
  const suffix = hasValue ? suffixValue : ''
  return (
    <>
      <Typography variant="caption" color="gray">
        {label}
      </Typography>
      <Typography variant="body1">
        {prefix}
        {result}
        {suffix}
      </Typography>
    </>
  )
}

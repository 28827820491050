import React, { FC, MouseEvent } from "react"
import { Button, Grid, ButtonProps as ButtonPropsInterface } from "@mui/material"
import { FieldArray, useFormikContext } from "formik"
import { TextField } from "src/components/ui"
import { ProductTipCreation, ProductTipEditing } from "src/api"

interface TipListProps {
  inputPlaceholder: string;
  title?: string;
  type: string;
  typeId: number;
}

interface TipInputProps {
  fieldName: string;
  placeholder: string;
  buttonTitle: string;
  onButtonClick: (event: MouseEvent) => void;
  ButtonProps?: ButtonPropsInterface;
}

const TipInput: FC<TipInputProps> = ({
  fieldName,
  placeholder,
  buttonTitle,
  onButtonClick,
  ButtonProps,
}) => {
  return (
    <Grid item container xs={12} spacing={2}>
      <Grid item xs>
        <TextField
          name={fieldName}
          TextFieldProps={{
            size: "small",
            margin: "none",
            placeholder: placeholder,
          }}
        />
      </Grid>
      <Grid item flexShrink={0}>
        <Button
          variant="outlined"
          {...ButtonProps}
          sx={{ height: "40px", ...ButtonProps?.sx }}
          onClick={onButtonClick}
        >
          {buttonTitle}
        </Button>
      </Grid>
    </Grid>
  )
}

export const TipList: FC<TipListProps> = ({
  type,
  typeId,
  inputPlaceholder,
}) => {
  const newFieldName = `${type}NewTip`
  const listFieldName = `${type}TipList`

  const { values, errors, setFieldValue, setFieldTouched, setFieldError } =
    useFormikContext()

  const list: ProductTipCreation[] | ProductTipEditing[] = (
    values as Record<string, any>
  )[listFieldName]
  return (
    <Grid
      container
      item
      flexDirection="column"
      flexWrap="nowrap"
      spacing={1}
      xs={12}
    >
      <FieldArray
        name={listFieldName}
        render={(arrayHelpers) => (
          <>
            <Grid container item xs={12} spacing={1}>
              {list.map((tip, index) => {
                const fieldName = `${listFieldName}.${index}.content`
                const disabled =
                  (values as Record<string, any>)[fieldName] === 0

                return (
                  <TipInput
                    key={index}
                    buttonTitle="Remove tip"
                    fieldName={fieldName}
                    placeholder={inputPlaceholder}
                    onButtonClick={() => {
                      if (!disabled) arrayHelpers.remove(index)
                    }}
                    ButtonProps={{ disabled }}
                  />
                )
              })}
            </Grid>
            <Grid container item xs={12} spacing={1}>
              <TipInput
                buttonTitle="Add tip"
                fieldName={newFieldName}
                placeholder={inputPlaceholder}
                onButtonClick={() => {
                  const content = (values as Record<string, any>)[newFieldName]
                  if (!!(errors as Record<string, any>)[newFieldName]) return
                  arrayHelpers.insert(list.length, {
                    typeId,
                    content,
                  } as ProductTipCreation)
                  setFieldValue(newFieldName, "")
                  setFieldTouched(newFieldName, false)
                  setFieldError(newFieldName, void 0)
                }}
                ButtonProps={{
                  disabled: !!(errors as Record<string, any>)[newFieldName],
                }}
              />
            </Grid>
          </>
        )}
      />
    </Grid>
  )
}

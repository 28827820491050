import React, { FC } from 'react'
import { Typography, Grid, Button } from '@mui/material'

interface InlineBuildingTypeProps {
  id: number;
  name: string;
  canChange: boolean;
  onEdit: (id: number) => void;
  onDelete: (params: { id: number; name: string }) => void;
}

export const InlineBuildingType: FC<InlineBuildingTypeProps> = (props) => {
  const { id, name, canChange, onEdit, onDelete } = props

  const onRemove = () => {
    onDelete({ id, name })
  }

  return (
    <>
      <Grid item flexGrow={1}>
        <Typography>{name}</Typography>
      </Grid>
      <Grid item>
        <Button
          variant="contained"
          disabled={!canChange}
          onClick={() => onEdit(id)}
        >
          Edit
        </Button>
      </Grid>
      <Grid item>
        <Button
          variant="outlined"
          color="error"
          disabled={!canChange}
          onClick={onRemove}
        >
          Delete
        </Button>
      </Grid>
    </>
  )
}
import { useState } from "react"
import { config } from "src/config"
import { useAddPopupMessage } from "./useAddPopupMessage"
import { useAuthorizedState } from "./useAuthorizedState"

type QueryParams = Record<string, string | number | undefined>
export type DownloadingFilePayload = { urlPath: string, queryParams: QueryParams }

export const useDownloadingFile = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const addMessage = useAddPopupMessage()
  const token = useAuthorizedState()

  const buildQueryParams = (queryParams: QueryParams): string => {
    Object.assign(queryParams, { Authorization: token })
    const nonEmptyKeys = Object.keys(queryParams).filter((key) => Boolean(queryParams[key]))
    return nonEmptyKeys.map((key) => `${key}=${queryParams[key]}`).join('&')
  }

  const getFileName = (contentDisposition: string): string => {
    const parts = contentDisposition.split(';')
    let fileName: string = ''
    for (const part of parts) {
      if (part.includes('filename')) {
        const [, value] = part.split('=')
        fileName = value.replaceAll('"', '')
      }
    }
    return fileName
  }

  const buildUrl = (payload: DownloadingFilePayload) => {
    const { apiPath, host } = config
    const basePath = `${host}/${apiPath}`
    const queryParams = buildQueryParams(payload.queryParams)
    return `${basePath}/${payload.urlPath.replace(/^\//, '')}?${queryParams}`
  }

  const downloadFile = async (payload: DownloadingFilePayload): Promise<{ isError: boolean }> => {
    let isError = false
    try {
      setIsLoading(true)
      const url = buildUrl(payload)
      const response = await fetch(url, {
        headers: {
          'User-Agent': navigator.userAgent,
          'client-platform': 'admin'
        }
      })

      if (response.status === 200) {
        const file = await response.blob()
        const contentDisposition = response.headers.get('Content-Disposition')
        const fileName = getFileName(contentDisposition || '')
        const href = window.URL.createObjectURL(file)
        const link = document.createElement('a')
        link.href = href
        link.setAttribute('download', fileName)
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      } else {
        const json = await response.json()
        addMessage({ text: json.error, type: 'error' })
        isError = true
      }
    } catch (error) {
      addMessage({ text: (error as Error).message, type: 'error' })
      isError = true
    } finally {
      setIsLoading(false)
    }
    return {
      isError
    }
  }

  return { downloadFile, isLoading }
}

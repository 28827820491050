import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { APIList } from 'src/interfaces'
import { Entities } from 'src/enums'
import { ManufacturerAnnouncementType } from 'src/api'
import { useApiInstance } from './useApiInstance'
import {
  buildRequestParams,
  ObjectType,
  transformPage,
  transformPerPage,
} from './helpers'

interface RequestParams {
  params?: Partial<AdminManufacturerAnnouncementTypesGetParams>
  paramsTransform?: Array<(params: Record<string, any>) => Record<string, any>>
  options?: Omit<
    UseQueryOptions<APIList<ManufacturerAnnouncementType>, Error>,
    'queryKey' | 'queryFn'
  >;
}

interface AdminManufacturerAnnouncementTypesGetParams {
  limit: number
  offset: number
  sort: string
}

const schema: ObjectType['schema'] = {
  limit: 'number',
  offset: 'number',
  sort: 'string',
}

export const useManufacturerAnnouncementTypeListRequest = ({
  params = {},
  paramsTransform = [],
  options,
}: RequestParams = {}) => {
  const api = useApiInstance()

  const transformedParams = [
    transformPerPage,
    transformPage,
    ...paramsTransform,
  ].reduce((param, transform) => transform(param), params)

  const queryParams = buildRequestParams<AdminManufacturerAnnouncementTypesGetParams>(
    transformedParams,
    schema,
  )

  return useQuery<APIList<ManufacturerAnnouncementType>, Error>(
    [Entities.ManufacturerAnnouncementTypes, JSON.stringify(transformedParams)],
    async () => {
      const {
        limit,
        offset,
        sort,
      } = queryParams
      const { data } = await api.v1AdminManufacturersAnnouncementsTypesGet(
        limit,
        offset,
        sort,
      )
      return data as APIList<ManufacturerAnnouncementType>
    },
    {
      enabled: Object.keys(transformedParams).length > 0,
      ...options,
    },
  )
}

import { FC, useState, ChangeEvent } from 'react'
import {
  Button,
  Grid,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  CircularProgress,
  TextField,
  Typography,
} from '@mui/material'
import { DialogNames } from 'src/enums'
import { StatefulDialog } from 'src/components/ui'
import { ProductRequest, ProductRequestSummaryPayload } from 'src/api'
import { useDialog } from 'src/hooks/ui'

interface Props {
  onSendSummary: (payload: ProductRequestSummaryPayload & { productRequestId: number }) => void
  productRequest: ProductRequest
  isLoading: boolean
}

export const ProductRequestSendSummaryModal: FC<Props> = ({
  onSendSummary,
  productRequest,
  isLoading,
}) => {
  const [summary, setSummary] = useState<string>('')
  const productRequestSummaryDialogName = DialogNames.ProductRequestSummary
  const productRequestConfirmationDialogName = DialogNames.ProductRequestSummaryConfirmation
  const { openDialog: openDialogSummary, closeDialog: closeDialogSummary } = useDialog(productRequestSummaryDialogName)
  const { openDialog: openDialogConfirmation, closeDialog: closeDialogConfirmation } = useDialog(productRequestConfirmationDialogName)

  const onChangeSummary = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    setSummary(value)
  }
  const handleOpenSummary = () => {
    openDialogSummary()
  }
  const handleCloseSummary = () => {
    closeDialogSummary()
  }
  const handleOpenConfirmation = () => {
    handleCloseSummary()
    openDialogConfirmation()
  }
  const handleCloseConfirmation = () => {
    closeDialogConfirmation()
    handleOpenSummary()
  }
  const sendSummary = () => {
    onSendSummary({
      message: summary,
      recipient: productRequest.author?.email || '',
      productRequestId: productRequest.id,
    })
    setSummary('')
  }

  if (!productRequest) return null

  return (
    <Grid>
      <StatefulDialog
        name={productRequestSummaryDialogName}
        onClose={handleCloseSummary}
        actions={
          <DialogActions>
            <Button onClick={handleCloseSummary}>
              Close
            </Button>
            <Button
              onClick={handleOpenConfirmation}
              color='error'
              disabled={!summary}
            >
              Send an email
            </Button>
          </DialogActions>
        }
      >
        <DialogTitle id="alert-dialog-title">
          Do you want to notify the user?
        </DialogTitle>
        <DialogContent>
          <DialogContentText component='div' sx={{ mb: 2 }}>
            <Typography variant="body1" gutterBottom>
              { productRequest.author?.name } requested the following products:
            </Typography>
            <Typography variant="body1" gutterBottom>
              { productRequest.request }
            </Typography>
            <Typography variant="body1" gutterBottom>
              Please provide some details if you want to notify { productRequest.author?.name } that the product is added to the system.
            </Typography>
          </DialogContentText>
          <TextField
            autoFocus
            multiline
            minRows={2}
            maxRows={4}
            margin="dense"
            id="summary"
            label="Response summary"
            type="text"
            fullWidth
            onChange={onChangeSummary}
            value={summary}
            variant="outlined"
          />
        </DialogContent>
      </StatefulDialog>
      <StatefulDialog
        name={productRequestConfirmationDialogName}
        onClose={handleCloseConfirmation}
        actions={
          <DialogActions>
            <Button onClick={handleCloseConfirmation} disabled={isLoading}>
              No
            </Button>
            <Button
              onClick={sendSummary}
              color='error'
              disabled={isLoading}
            >
              {isLoading ? <CircularProgress size={22} /> : "Yes"}
            </Button>
          </DialogActions>
        }
      >
        <DialogTitle id="alert-dialog-title">
          Confirmation
        </DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ mb: 2 }}>
            The message will be sent to the user via email immediately. Do you want to proceed?
          </DialogContentText>
        </DialogContent>
      </StatefulDialog>
    </Grid>
  )
}

import React, { FC } from 'react'
import { Grid } from '@mui/material'
import { useFormik, FormikProvider, Form } from 'formik'
import {
  FormDropdown,
  ComplexDropdownItemFactory,
  AutocompleteTextField,
} from 'src/components/ui'
import { useQueryParams } from 'src/hooks/ui'
import { SearchParamsKeys } from 'src/enums'
import { ProjectType, Contractor } from 'src/api'
import { HideArchivedProjectsFilter } from './hideArchivedProjectsFilter'

const factory = ComplexDropdownItemFactory({
  labelProp: 'displayName',
  valueProp: 'id',
})

interface ProjectListFiltersProps {
  currentContractor?: Contractor | null;
  projectTypes?: ProjectType[];
  contractors?: Contractor[];
  contractorsLoading?: boolean;
  findContractorsByName?: (name: string) => void;
}

export const ProjectListFilters: FC<ProjectListFiltersProps> = ({
  projectTypes = [],
  contractors = [],
  contractorsLoading = false,
  findContractorsByName = () => {},
  currentContractor = null,
}) => {
  const [queryParams, setQueryParams] = useQueryParams()

  const formik = useFormik({
    initialValues: {
      [SearchParamsKeys.Contractor]: queryParams[SearchParamsKeys.Contractor] || null,
      [SearchParamsKeys.Type]: queryParams[SearchParamsKeys.Type] || null,
      [SearchParamsKeys.HideArchivedProjects]: queryParams[SearchParamsKeys.HideArchivedProjects] || undefined,
    },
    onSubmit: async (formState) => {
      const type = formState[SearchParamsKeys.Type] || null
      const contractor = formState[SearchParamsKeys.Contractor] || null
      await setQueryParams({
        ...(type ? { [SearchParamsKeys.Type]: `${type}` } : {}),
        ...(contractor ? { [SearchParamsKeys.Contractor]: `${contractor}` } : {}),
      }, [SearchParamsKeys.Type, SearchParamsKeys.Contractor])
    },
  })

  const { submitForm } = formik

  return (
    <FormikProvider value={formik}>
      <Form>
        <Grid container alignItems="center" spacing={4}>
          <Grid item>
            <AutocompleteTextField
              label="Contractor"
              name={SearchParamsKeys.Contractor}
              labelField="name"
              valueField="id"
              options={contractors}
              onInputChange={findContractorsByName}
              isLoading={contractorsLoading}
              onChange={submitForm}
              initialValue={currentContractor}
            />
          </Grid>
          <Grid item>
            <FormDropdown
              label="Type"
              menuItemFactory={factory}
              list={[{ id: null, displayName: 'All' }, ...projectTypes]}
              name={SearchParamsKeys.Type}
              SelectProps={{ sx: { width: 200 }, defaultValue: null }}
              onChange={submitForm}
            />
          </Grid>
          <Grid item>
            <HideArchivedProjectsFilter />
          </Grid>
        </Grid>
      </Form>
    </FormikProvider>
  )
}

import React, { FC, useMemo } from "react"

type Props = {
  size?: 24 | 36 | 48 | 96
}

export const FileIcon: FC<Props> = ({ size = 96 }) => {
  const iconSizeParams = useMemo(() => ({
    width: `${size}px`,
    height: `${size}px`,
  }), [size])
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width={iconSizeParams.width} height={iconSizeParams.height}>
      <path fill="#50e6ff" d="M39,16v25c0,1.105-0.895,2-2,2H11c-1.105,0-2-0.895-2-2V7c0-1.105,0.895-2,2-2h17L39,16z" />
      <linearGradient id="F8F33TU9HxDNWNbQYRyY3a" x1="28.529" x2="33.6" y1="15.472" y2="10.4" gradientUnits="userSpaceOnUse">
        <stop offset="0" stopColor="#3079d6" />
        <stop offset="1" stopColor="#297cd2" />
      </linearGradient>
      <path fill="url(#F8F33TU9HxDNWNbQYRyY3a)" d="M28,5v9c0,1.105,0.895,2,2,2h9L28,5z" />
    </svg>
  )
}

import { FC } from 'react'
import { FormCheckbox } from 'src/components/ui'
import { SearchParamsKeys } from 'src/enums'
import { useQueryParams } from 'src/hooks/ui'

export const HideArchivedProjectsFilter: FC = () => {
  const [, setQueryParams, removeQueryParams] = useQueryParams()
  const onChangeProjectStatus = async (checked: boolean) => {
    if (checked === false) {
      removeQueryParams([SearchParamsKeys.HideArchivedProjects])
    }
    if (checked === true) {
      await setQueryParams({
        [SearchParamsKeys.HideArchivedProjects]: 'true',
      })
    }
  }

  return (
    <FormCheckbox
      name={SearchParamsKeys.HideArchivedProjects}
      label="Hide Archived Projects"
      view="switch"
      onChange={onChangeProjectStatus}
    />
  )
}

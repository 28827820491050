import React, { FC, PropsWithChildren } from 'react'
import Box from '@mui/material/Box'
import { DataLoad, PropsInjector } from 'src/components'
import { useSensorListRequest } from 'src/hooks/api'
import { useQueryParamsState } from 'src/hooks/ui'
import { Sensor } from 'src/api'
import { SensorColumnsDataGrid } from 'src/components/columns/sensor'
import { SensorButtons } from 'src/enums'
import { CommonSensorList } from '../sensor/sensorList/commonSensorsList'

const getColumns = () => [
  SensorColumnsDataGrid.Id,
  SensorColumnsDataGrid.Model,
  SensorColumnsDataGrid.IMEI,
  SensorColumnsDataGrid.DevEUI,
  SensorColumnsDataGrid.LastPing,
  SensorColumnsDataGrid.BatteryLevel,
  SensorColumnsDataGrid.DateAssigned,
  SensorColumnsDataGrid.EnabledBluetooth,
]

interface ContractorSensorsListProps {
  sensorListLoading?: boolean;
  count?: number;
  data?: Sensor[];
}

const ContractorSensorsList: FC<ContractorSensorsListProps> = ({
  sensorListLoading = false,
  count = 0,
  data = [],
}) => {
  const exportedFileName = `${document.title}_sensors`
  return (
    <Box height="500px">
      <CommonSensorList
        columns={getColumns()}
        count={count}
        data={data}
        isLoading={sensorListLoading}
        buttons={[SensorButtons.GetHistoricalData, SensorButtons.ManageSensorBleState]}
        exportedFileName={exportedFileName}
        checkboxSelection
      />
    </Box>
  )
}

interface ContractorSensorLoaderProps {
  contractorId: number;
}

const ContractorSensorLoader: FC<PropsWithChildren<ContractorSensorLoaderProps>> = ({
  contractorId,
  children,
}) => {
  const params = useQueryParamsState()

  const { isInitialLoading, isError, data, error } = useSensorListRequest({
    params: {
      ...params,
      contractorId,
    },
  })

  const props = {
    sensorListLoading: isInitialLoading,
    count: data?.count || 0,
    data: data?.rows || [],
  }

  return (
    <DataLoad isLoading={false} isError={isError} errorMessage={error?.message}>
      <PropsInjector props={props}>{children}</PropsInjector>
    </DataLoad>
  )
}

interface ContractorSensorsProps {
  contractorId: number;
}

export const ContractorSensors: FC<ContractorSensorsProps> = ({
  contractorId,
}) => {
  return (
    <ContractorSensorLoader contractorId={contractorId}>
      <ContractorSensorsList />
    </ContractorSensorLoader>
  )
}

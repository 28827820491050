import { DialogNames } from "src/enums"
import { SensorState } from "src/api"
import { useDialog } from "./useDialog"
import { useAddPopupMessage } from "./useAddPopupMessage"
import { usePatchSensorRequest } from "../api"

export const useSensorStateUpdate = () => {
  const {
    openDialog: openSensorArchivingDialog,
    closeDialog: closeSensorArchivingDialog,
  } = useDialog(DialogNames.ArchiveSensor)
  const {
    openDialog: openSensorUnarchivingDialog,
    closeDialog: closeSensorUnarchivingDialog,
  } = useDialog(DialogNames.UnarchiveSensor)
  const addPopupMessage = useAddPopupMessage()
  const changeSensorStateRequest = usePatchSensorRequest()
  const updateState = (payload: {
    uniqueId: string
    state: SensorState
    onSuccess?: () => void
    onError?: () => void
  }) => {
    const { uniqueId, state, onSuccess, onError } = payload
    changeSensorStateRequest.mutate({
      uniqueId,
      payload: { stateId: state.id },
    }, {
      onSuccess: () => {
        addPopupMessage({ text: "The sensor state has been successfully changed", type: "success" })
        closeSensorArchivingDialog()
        closeSensorUnarchivingDialog()
        if (typeof onSuccess === 'function') {
          onSuccess()
        }
      },
      onError: ({ message: text }) => {
        addPopupMessage({ text, type: "error" })
        if (typeof onError === 'function') {
          onError()
        }
      },
    },)
  }

  return {
    updateSensorState: updateState,
    openSensorArchivingDialog,
    closeSensorArchivingDialog,
    openSensorUnarchivingDialog,
    closeSensorUnarchivingDialog,
    isLoadingChangingSensorState: changeSensorStateRequest.isLoading,
  }
}
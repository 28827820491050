import { FC } from 'react'
import { Box, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import {
  HasSidebarLayout,
  ManufacturerNewForm,
  ManufacturerNewState,
} from 'src/components'
import { useNewManufacturerRequest } from 'src/hooks/api'
import { useAddPopupMessage } from 'src/hooks/ui'

const NewManufacturer: FC = () => {
  document.title = 'Floorcloud Admin Panel - New manufacturer'
  const navigate = useNavigate()
  const addMessage = useAddPopupMessage()
  const newManufacturerRequest = useNewManufacturerRequest({
    options: {
      onSuccess: () => {
        navigate(-1)
      },
      onError: ({ message: text }) => {
        addMessage({ text, type: 'error' })
      },
    },
  })

  const onSubmit = (submitData: ManufacturerNewState, photo: File | null) => {
    if (photo) {
      newManufacturerRequest.mutate({...submitData, photos: [photo]})
    } else {
      newManufacturerRequest.mutate(submitData)
    }
  }
  return (
    <HasSidebarLayout>
      <Typography variant="h4">Create new manufacturer</Typography>
      <Box width="1000px" mt={2}>
        <ManufacturerNewForm onSubmit={onSubmit} isLoading={newManufacturerRequest.isLoading} />
      </Box>
    </HasSidebarLayout>
  )
}

export default NewManufacturer

import { useMutation, UseMutationOptions } from '@tanstack/react-query'
import { Entities } from 'src/enums'
import { MergeProductsPayload } from 'src/api'
import { useApiInstance } from './useApiInstance'

interface RequestParams {
  options?: Omit<
    UseMutationOptions<boolean, Error, MergeProductsPayload>,
    'mutationKey' | 'mutationFn'
  >;
}

export const useMergeProductsRequest = ({ options }: RequestParams = {}) => {
  const api = useApiInstance()

  return useMutation<boolean, Error, MergeProductsPayload>(
    [Entities.Products, 'merge'],
    async (payload: MergeProductsPayload) => {
      const { data }: { data: unknown } = await api.v1AdminProductsMergePost(payload)
      return data as boolean
    },
    options,
  )
}

export type PrimitiveType = 'string' | 'number' | 'boolean';
export type ComplexType = ObjectType | ArrayType;
export type RecursiveType =
  | PrimitiveType
  | ComplexType
  | PrimitiveType[]
  | ComplexType[];

export type ObjectType = {
  type: 'object';
  schema: { [key: string]: RecursiveType };
};
export type ArrayType = { type: 'array'; schema: RecursiveType };

const parsePrimitiveType = (
  value: any,
  schema: PrimitiveType,
): string | number | boolean | undefined => {
  if (schema === 'number') return parseInt(value, 10)
  if (schema === 'string') return `${value}`
  if (schema === 'boolean') return value === 'true' || value === true
  return undefined
}

const parseObjectType = (value: any, schema: ObjectType['schema']) => {
  const result: Record<string, any> = {}
  for (const key in schema) {
    if (key in (value as Object)) {
      const childValue = value[key]
      const { [key]: childSchema } = schema
      const childResult = disassembleValue(childValue, childSchema)
      result[key] = childResult
    }
  }
  return result
}

const parseArrayType = (value: any, schema: RecursiveType) => {
  const result: Array<any> = []

  const isSchemaArray = Array.isArray(schema)

  const list = isSchemaArray ? schema : value

  for (let idx = 0; idx < list.length; idx++) {
    let childSchema = list
    let childValue = value

    if (isSchemaArray) {
      childSchema = list[idx]
      childValue = value[idx]
    } else if (!isSchemaArray && typeof list === 'object') {
      childSchema = schema
      childValue = value[idx]
    }

    const item = disassembleValue(childValue, childSchema)
    result.push(item)
  }
  return result
}

const parseComplexType = (value: any, schema: RecursiveType) => {
  let result: Record<string, any> | Array<any>

  if (Array.isArray(schema)) {
    result = parseArrayType(value, schema as RecursiveType)
  } else {
    const { type, schema: childSchema } = schema as ComplexType

    if (type === 'object') {
      result = parseObjectType(value, childSchema as ObjectType['schema'])
    } else {
      result = parseArrayType(value, childSchema as RecursiveType)
    }
  }
  return result
}

const disassembleValue = (value: any, schema: RecursiveType) => {
  if (typeof schema === 'string' || typeof schema === 'boolean') return parsePrimitiveType(value, schema)
  return parseComplexType(value, schema)
}

export const buildRequestParams = <T>(
  value: Record<string, any>,
  schema: ObjectType['schema'],
): T => disassembleValue(value, { type: 'object', schema }) as T
// buildRequestParams
// console.log('number', buildRequestParams('1', 'number'));
// console.log('string', buildRequestParams('qwe', 'string'));
// console.log(
//   'object',
//   buildRequestParams({ a: '111' }, { type: 'object', schema: { a: 'number' } })
// );
// console.log(
//   'object',
//   buildRequestParams({ a: 'asd' }, { type: 'object', schema: { a: 'string' } })
// );
// console.log(
//   'number array',
//   buildRequestParams(['123'], { type: 'array', schema: 'number' })
// );
// console.log(
//   'string array',
//   buildRequestParams(['qwe'], { type: 'array', schema: 'string' })
// );
// console.log(
//   'tuple array',
//   buildRequestParams(['qwe', '123'], {
//     type: 'array',
//     schema: ['string', 'number'],
//   })
// );
// console.log(
//   'object with string array',
//   buildRequestParams([{ a: '111' }, { a: '222' }], {
//     type: 'array',
//     schema: { type: 'object', schema: { a: 'number' } },
//   })
// );
// console.log(
//   'object with number array',
//   buildRequestParams([{ a: 'zxc' }, { a: 'asd' }], {
//     type: 'array',
//     schema: { type: 'object', schema: { a: 'string' } },
//   })
// );
// console.log(
//   'object with string array',
//   buildRequestParams([{ a: '111' }], {
//     type: 'array',
//     schema: [{ type: 'object', schema: { a: 'number'} }],
//   })
// );

import { useMutation, UseMutationOptions } from '@tanstack/react-query'
import { Entities } from 'src/enums'
import { User, UserEditing } from 'src/api'
import { useApiInstance } from './useApiInstance'

interface RequestParams {
  id: number
  options?: Omit<UseMutationOptions<unknown, Error, UserEditing, User>, 'mutationKey' | 'mutationFn'>
}

export const useEditUserRequest = ({
  id,
  options,
}: RequestParams) => {
  const api = useApiInstance()

  return useMutation<unknown, Error, UserEditing, User>(
    [Entities.Users, 'edit'],
    async (payload: UserEditing) => {
      const { data } = await api.v1AdminUsersUserIdPut(id, payload)
      return data
    },
    {
      ...options,
    },
  )
}

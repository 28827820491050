import React, { FC, useMemo } from "react"
import { Grid, Typography, Button, Divider, Chip } from "@mui/material"
import DoneIcon from '@mui/icons-material/Done'
import { Link } from "react-router-dom"
import { ManufacturerAnnouncement } from "src/api"
import { ManufacturerAnnouncementPostCard } from "./manufacturerAnnouncementPostCard"

interface ManufacturerAnnouncementCardProps {
  announcement: ManufacturerAnnouncement;
}
type Tag = {
  name: string;
  icon?: React.ReactElement;
}

export const ManufacturerAnnouncementCard: FC<ManufacturerAnnouncementCardProps> = ({
  announcement
}) => {
  const tags: Tag[] = useMemo(() => {
    const result: Tag[] = []
    if (announcement.type) {
      result.push({ name: announcement.type.displayName || announcement.type.name || '' })
    }
    if (announcement.categories && announcement.categories.length) {
      result.push(...announcement.categories.map((category) => ({ name: category.displayName })))
    }
    if (announcement.isVisibleContactMeButton) {
      result.push({ name: 'Contact rep button', icon: <DoneIcon />})
    }
    return result
  }, [announcement])
  return (
    <Grid container direction="column" alignItems="stretch" p={4} border={1} borderRadius={3} borderColor="lightgray">
      <Grid item container justifyContent="space-between" sx={{ mb: 1 }} xs>
        <Grid container item flexDirection="column" xs={9}>
          <Grid item>
            <Typography variant="h6" fontWeight={600}>Manufacturer: {announcement.manufacturer?.name || '-'}</Typography>
          </Grid>
          <Grid item>
            <Typography>
              <Typography variant="body1" fontWeight={400} component="span">Name: </Typography>
              <Typography variant="body1" fontWeight={400} textTransform="uppercase" component="span">{announcement.name || '-'}</Typography>
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={3} textAlign="end">
          <Link to={`/manufacturer-announcements/${announcement.id}/edit`} style={{ textDecoration: "none" }}>
            <Button role="link" variant="outlined">
              Edit
            </Button>
          </Link>
        </Grid>
      </Grid>
      <Grid item>
        {(announcement.posts || []).map((post) => (
          <ManufacturerAnnouncementPostCard post={post} key={`post_${post.id}`} />
        ))}
      </Grid>
      <Grid item my={3}>
        <Divider />
      </Grid>
      <Grid item>
        {tags.map((tag, index) => (
          <Chip
            key={index}
            label={tag.name}
            sx={{ marginRight: 1 }}
            {...(tag.icon ? { icon: tag.icon } : {})}
          />
        ))}
      </Grid>
    </Grid>
  )
}

import React, { FC } from 'react'
import { TableCell } from '@mui/material'
import { EmailLink } from '../link'

export interface EmailCellProps {
  value: string;
  last: boolean;
  empty?: string;
}

export const EmailCell: FC<EmailCellProps> = ({ value, last, empty = '-' }) => {
  const align = last ? 'right' : 'left'
  return (
    <TableCell scope="row" align={align}>
      {value ? <EmailLink value={value} /> : empty}
    </TableCell>
  )
}

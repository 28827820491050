import React, { FC } from 'react'
import { Grid } from '@mui/material'
import { Table, DynamicColumn } from 'src/components/ui'
import { Sensor } from 'src/api'
import SensorColumns from 'src/components/columns/sensor'

const currentConfigColumns: DynamicColumn<Sensor>[] = [
  SensorColumns.MaxAwakeDuration,
  SensorColumns.MinAwakeDuration,
  SensorColumns.PeriodSet,
  SensorColumns.SynchPeriod,
  SensorColumns.ConfigType,
  SensorColumns.ConfigTypeUpdate,
  SensorColumns.ConfigLastUpdate,
]

interface SensorCurrentConfigProps {
  sensor: Sensor;
}

export const SensorCurrentConfig: FC<SensorCurrentConfigProps> = (props) => {
  const { sensor } = props
  if (!sensor) return null

  return (
    <Grid container spacing={4}>
      <Grid item container>
        <Table
          columns={currentConfigColumns}
          data={[sensor]}
          showFooter={false}
          sx={{ width: '100%' }}
        />
      </Grid>
    </Grid>
  )
}
import React from "react"

export const Verified = () => (
  <svg
    width="24"
    height="19"
    viewBox="0 0 24 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.2356 0C12.9778 0 14.5778 0.711111 15.7156 1.88444C16.4978 1.45778 17.3867 1.24444 18.3467 1.24444C21.44 1.24444 23.9644 3.76889 23.9644 6.86222C23.9644 9.74222 21.7956 12.1244 18.9867 12.4444C18.8444 15.7511 16.1067 18.4533 12.7289 18.4533C10.9867 18.4533 9.38667 17.7422 8.24889 16.5689C7.46667 16.9956 6.57778 17.2089 5.61778 17.2089C2.52444 17.2089 0 14.6844 0 11.5911C0 8.71111 2.16889 6.32889 4.97778 6.00889C5.12 2.66667 7.89333 0 11.2356 0Z"
      fill="#0070CE"
    />
    <path
      d="M15.9527 6.49662L10.568 11.8813C10.5289 11.9204 10.4656 11.9204 10.4266 11.8813L8.23698 9.69173"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
)

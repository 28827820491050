import React, { useContext } from "react"
import { ProductDraftsContext } from "../../../contexts/productDraftsContext"
import { ListHeader } from "../../ui"

export const ProductDraftListTitle = () => {
  const { currentManufacturer } = useContext(ProductDraftsContext)

  const title = ["Products Data Base", currentManufacturer?.name]
    .filter(Boolean)
    .join(" - ")

  return <ListHeader title={title} />
}

import React, { FC } from "react"
import {
  Typography,
  DialogActions,
  Button,
  Box,
  DialogTitle,
} from "@mui/material"
import { Dialog } from "src/components/ui"
import pluralize from "pluralize"

interface ProductUpdateConfirmationModalProps {
  open: boolean;
  productsCount: number;
  onSave: () => void;
  onCancel: () => void;
}

export const ProductPublishConfirmationModal: FC<ProductUpdateConfirmationModalProps> =
  ({ open, productsCount, onSave, onCancel }) => {
    return (
      <Dialog
        open={open}
        onClose={onCancel}
        title={
          <DialogTitle>
            {pluralize("Product", productsCount)} update
          </DialogTitle>
        }
        DialogProps={{ fullWidth: true, maxWidth: "xs" }}
        actions={
          <DialogActions>
            <Button variant="contained" onClick={onSave}>
              Save
            </Button>
            <Button variant="outlined" onClick={onCancel}>
              Cancel
            </Button>
          </DialogActions>
        }
      >
        <Typography>
          Heads up! You are going to publish the product information to
          DataBase. Please doublecheck all updated values, highlighted in table
          with yellow color.
        </Typography>
        <Box mt={2} />
        <Typography fontWeight={600}>
          Changes you make for {pluralize("product", productsCount, true)} will
          be populated to user’s application immediately.
        </Typography>
      </Dialog>
    )
  }

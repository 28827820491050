import React, { FC, forwardRef } from 'react'
import { useLocation, useNavigate, Link as RouterLink, LinkProps } from 'react-router-dom'

export const StyledRouterLink: FC<LinkProps> = forwardRef(
  ({ to, children, onClick, ...restProps }, ref) => {
    const { pathname } = useLocation()
    const navigate = useNavigate()
    return (
      <RouterLink
        to={to as string}
        onClick={(event) => {
          event.preventDefault()
          event.stopPropagation()
          if (onClick) onClick(event)
          if (to !== pathname) navigate(to)
        }}
        {...restProps}
      >
        {children}
      </RouterLink>
    )
  }
)

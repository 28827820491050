import React, { FC, PropsWithChildren } from 'react'
import { TableCell } from '@mui/material'

export interface DateCellProps {
  value: Date;
  first: boolean;
  format: (value: Date) => string;
  Link?: FC<PropsWithChildren>;
  empty?: string;
}

export const DateCell: FC<DateCellProps> = ({
  value,
  format,
  first,
  Link,
  empty = '-',
}) => {
  const align = first ? 'left' : 'right'
  const result = format(value)
  return (
    <TableCell scope="row" align={align}>
      {value ? Link ? <Link>{result}</Link> : result : empty}
    </TableCell>
  )
}

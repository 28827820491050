import { FC } from 'react'
import { Typography, DialogActions, Button, Box } from '@mui/material'
import { StatefulDialog } from 'src/components/ui'
import { DialogNames } from 'src/enums'

interface UnarchiveSensorModalProps {
  sensorDeviceId: string;
  onUnarchive: () => void;
  onCancel: () => void;
  isLoading?: boolean;
}

export const UnarchiveSensorModal: FC<UnarchiveSensorModalProps> = ({
  sensorDeviceId,
  onUnarchive,
  onCancel,
  isLoading = false,
}) => {
  return (
    <StatefulDialog
      name={DialogNames.UnarchiveSensor}
      actions={
        <Box mb={2} mx={2}>
          <DialogActions>
            <Button variant="outlined" onClick={onCancel}>
              Cancel
            </Button>
            <Button variant="contained" color="error" onClick={onUnarchive} disabled={isLoading}>
              Unarchive
            </Button>
          </DialogActions>
        </Box>
      }
    >
      <Box minWidth="300px">
        <Typography variant="h6">Are you sure you want to unarchive the sensor "{sensorDeviceId}"? It will be moved to "new" status.</Typography>
      </Box>
    </StatefulDialog>
  )
}

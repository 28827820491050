import { FC, useRef, useEffect, PropsWithChildren } from 'react'
import { styled } from '@mui/system'
import mapboxgl, { Map as MMap, AnyLayer, NavigationControl } from 'mapbox-gl'
import PitchToggle from '@watergis/mapbox-gl-pitch-toggle-control'
import { config } from 'src/config'
import { HiddenMapContext } from 'src/contexts'

mapboxgl.accessToken = config.mapboxKey

const MapWrapper = styled('div')`
  position: relative;
  // display: none;

  & > .mapboxgl-map {
    height: inherit;
    width: inherit;
  }
`

const treeDLayer: AnyLayer = {
  id: 'add-3d-buildings',
  source: 'composite',
  'source-layer': 'building',
  filter: ['==', 'extrude', 'true'],
  type: 'fill-extrusion',
  minzoom: 15,
  paint: {
    'fill-extrusion-color': '#aaa',

    // Use an 'interpolate' expression to
    // add a smooth transition effect to
    // the buildings as the user zooms in.
    'fill-extrusion-height': [
      'interpolate',
      ['linear'],
      ['zoom'],
      15,
      0,
      15.05,
      ['get', 'height'],
    ],
    'fill-extrusion-base': [
      'interpolate',
      ['linear'],
      ['zoom'],
      15,
      0,
      15.05,
      ['get', 'min_height'],
    ],
    'fill-extrusion-opacity': 0.6,
  },
}

export const HiddenMapProvider: FC<PropsWithChildren> = ({ children }) => {
  const map = useRef<MMap | null>(null)
  const mapContainer = useRef<string | HTMLElement>('')

  useEffect(() => {
    if (map.current) return

    map.current = new MMap({
      container: mapContainer.current,
      style: 'mapbox://styles/mapbox/streets-v11',
      center: [-100, 45],
      zoom: 2,
      pitch: 0,
      antialias: true,
    })

    const canvas = map.current?.getCanvas()
    const webglContext = canvas?.getContext('webgl')
    webglContext?.pixelStorei(webglContext.UNPACK_FLIP_Y_WEBGL, true)
    webglContext?.pixelStorei(
      webglContext.UNPACK_PREMULTIPLY_ALPHA_WEBGL,
      true
    )
    const webgl2Context = canvas?.getContext('webgl2')
    webgl2Context?.pixelStorei(webgl2Context.UNPACK_FLIP_Y_WEBGL, true)
    webgl2Context?.pixelStorei(
      webgl2Context.UNPACK_PREMULTIPLY_ALPHA_WEBGL,
      true
    )

    map.current.on('load', () => {
      // Insert the layer beneath any symbol layer.
      const { layers = [] } = (map.current as MMap).getStyle()
      const { id: labelLayerId } =
        layers.find(
          (layer: AnyLayer) =>
            layer.type === 'symbol' &&
            layer.layout &&
            layer.layout['text-field']
        ) || ({} as AnyLayer);

      // The 'building' layer in the Mapbox Streets
      // vector tileset contains building height data
      // from OpenStreetMap.
      (map.current as MMap).addLayer(treeDLayer, labelLayerId);
      (map.current as MMap).addControl(
        new NavigationControl({
          showCompass: true,
          showZoom: true,
          visualizePitch: true,
        })
      );
      (map.current as MMap).addControl(
        new PitchToggle({ bearing: 0, pitch: 60 })
      )
    })
    return () => {
      map.current?.remove()
      map.current = null
    }
  }, [])

  return (
    <HiddenMapContext.Provider value={{ map, mapContainer }}>
      {children}
      <MapWrapper>
        <div ref={mapContainer as any} />
      </MapWrapper>
    </HiddenMapContext.Provider>
  )
}

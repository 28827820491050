import React, { FC } from 'react'
import * as Yup from 'yup'
import { Button, Grid, Box, CircularProgress } from '@mui/material'
import { Formik, Form } from 'formik'
import { TextField } from 'src/components/ui'

const getValidationSchema = (isRequired: boolean = true) => Yup.object().shape({
  displayName: isRequired ? Yup.string().required('Value is required') : Yup.string(),
})

interface TypeNewState {
  displayName: string
  description?: string
}

const defaultInitial: TypeNewState = {
  displayName: '',
  description: undefined,
}

export interface TypeNewFormProps {
  placeholder?: string
  initialValues?: TypeNewState | null;
  label?: string | null;
  isRequiredField?: boolean;
  isLoading?: boolean;
  needToResetForm?: boolean;
  onSubmit: (formState: TypeNewState) => void;
  onCancel?: () => void;
}

export const TypeNewForm: FC<TypeNewFormProps> = ({
  initialValues,
  placeholder,
  label,
  isRequiredField = true,
  isLoading = false,
  needToResetForm = true,
  onSubmit,
  onCancel,
}) => {
  const validationSchema = getValidationSchema(isRequiredField)
  return (
    <Formik
      initialValues={{ ...defaultInitial, ...initialValues }}
      onSubmit={(data, { resetForm }) => {
        onSubmit(data)
        if (needToResetForm) resetForm()
      }}
      validationSchema={validationSchema}
    >
      <Form>
        <Grid container spacing={4} direction="row">
          <Grid item flexGrow={1}>
            <TextField
              name="displayName"
              label={label || "Name"}
              required={isRequiredField}
              TextFieldProps={{ placeholder }}
            />
          </Grid>
          {
            initialValues?.description !== undefined
              ? (<Grid item flexGrow={1}>
                <TextField
                  name="description"
                  label="Description"
                  TextFieldProps={{ placeholder: 'Description' }}
                />
              </Grid>)
              : null
          }
          <Grid item flexShrink={0}>
            <Grid container spacing={1}>
              <Grid item>
                <Box pt={2}>
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={isLoading}
                  >
                    {
                      isLoading ? <CircularProgress color="info" size={26} /> : 'Save'
                    }
                  </Button>
                </Box>
              </Grid>
              {onCancel && <Grid item>
                <Box pt={2}>
                  <Button
                    variant="outlined"
                    onClick={onCancel}
                  >
                    Cancel
                  </Button>
                </Box>
              </Grid>}
            </Grid>
          </Grid>
        </Grid>
      </Form>
    </Formik>
  )
}

import React, { FC } from 'react'
import { Box, BoxProps } from '@mui/material'

export const ContentBox: FC<BoxProps> = ({ children, ...restProps }) => {
  return (
    <Box px={8} py={6} width="100%" {...restProps}>
      {children}
    </Box>
  )
}

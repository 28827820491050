import React, { FC } from 'react'
import { Grid, Typography } from '@mui/material'
import { useManufacturerAnnouncementTypeListRequest, useNewManufacturerAnnouncementTypeRequest } from 'src/hooks/api'
import { DataLoad } from 'src/components/ui'
import { useAddPopupMessage } from 'src/hooks/ui'
import { TypeNewForm } from 'src/components/forms'
import { PostTypeList } from './postTypeList'

export const ManufacturerAnnouncementPostTypeList: FC = () => {
  const addMessage = useAddPopupMessage()
  const { data, error, isInitialLoading, isError, refetch } = useManufacturerAnnouncementTypeListRequest({
    params: { limit: 9999 },
  })
  const newTypeRequest = useNewManufacturerAnnouncementTypeRequest({
    options: {
      onSuccess: () => refetch(),
      onError: (err) => addMessage({ type: 'error', text: err.message }),
    }
  })
  const createNewType = ({ displayName }: { displayName: string }) => {
    newTypeRequest.mutate({ displayName })
  }

  return (
    <Grid container direction="column" spacing={4}>
      <Grid item container direction="column">
        <Grid item>
          <Typography variant="h6" sx={{ pt: 2 }}>
            Create New GangBox Type
          </Typography>
        </Grid>
        <Grid item>
          <TypeNewForm
            onSubmit={createNewType}
            isLoading={newTypeRequest.isLoading}
            placeholder="New GangBox Type Name"
          />
        </Grid>
      </Grid>
      <Grid item>
        <Grid item sx={{ pb: 2 }}>
          <Grid item>
            <Typography variant="h6">GangBox Type List</Typography>
          </Grid>
          <Grid>
            <Typography color="gray">
              Forbidden to delete type if it used for any post
            </Typography>
          </Grid>
        </Grid>
        <Grid item>
          <DataLoad
            isLoading={isInitialLoading}
            isError={isError}
            errorMessage={error?.message}
          >
            <PostTypeList data={data?.rows || []} onChange={refetch} />
          </DataLoad>
        </Grid>
      </Grid>
    </Grid>
  )
}

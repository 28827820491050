import { FC, useMemo, useState } from 'react'
import * as Yup from "yup"
import { Formik, Form, FormikHelpers } from "formik"
import { useNavigate, useSearchParams } from 'react-router-dom'
import {
  Attachment,
  CreateFirmwarePayload,
  UpdateFirmwarePayload,
  UploadedFileResult,
} from 'src/api'
import { RadioGroup, TextField } from 'src/components/ui'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import { useUploadFileRequest } from 'src/hooks/api'
import { useAddPopupMessage } from 'src/hooks/ui'
import { SearchParamsKeys, WagnerDeviceFirmwareDeviceTypeEnum } from 'src/enums'
import { FileUploadCard } from '../media'

export type InitialValues = {
  version: string
  deviceType: WagnerDeviceFirmwareDeviceTypeEnum | string
  description?: string
  attachments: Attachment[]
}

type Props = {
  initialValues?: InitialValues
  onSubmit: (data: CreateFirmwarePayload | UpdateFirmwarePayload) => void
}

const validationSchema = Yup.object().shape({
  version: Yup.string().required("Version is required"),
  deviceType: Yup.string().required("Device type is required"),
  description: Yup.string().nullable(),
})

export const WagnerDeviceFirmwareForm: FC<Props> = ({ initialValues, onSubmit }) => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null)
  const [queryParams] = useSearchParams()
  const navigate = useNavigate()
  const addMessage = useAddPopupMessage()
  const uploadFileRequest = useUploadFileRequest()
  const queryDeviceType = useMemo(() => {
    const deviceType = queryParams.get(SearchParamsKeys.WagnerDeviceType)
    if (!deviceType) {
      return WagnerDeviceFirmwareDeviceTypeEnum.DataGrabber
    }
    const map: Record<string, WagnerDeviceFirmwareDeviceTypeEnum> = {
      'DataGrabber': WagnerDeviceFirmwareDeviceTypeEnum.DataGrabber,
      'RangeExtender': WagnerDeviceFirmwareDeviceTypeEnum.RangeExtender,
    }
    return map[deviceType] || WagnerDeviceFirmwareDeviceTypeEnum.DataGrabber
  }, [queryParams])
  const defaultValues = useMemo((): InitialValues => ({
    version: '',
    deviceType: queryDeviceType,
    description: '',
    attachments: [],
  }), [queryDeviceType])
  const handleSubmit = (data: InitialValues, helpers: FormikHelpers<InitialValues>) => {
    const { setSubmitting } = helpers
    if (!selectedFile && !initialValues?.attachments.length) {
      addMessage({ text: 'Please select a file', type: 'error' })
      setSubmitting(false)
      return
    }
    if (!selectedFile && initialValues?.attachments?.length) {
      const payload = {
        version: data.version,
        deviceType: data.deviceType as WagnerDeviceFirmwareDeviceTypeEnum,
        description: data.description,
        attachmentIds: initialValues.attachments.map((attachment) => attachment.id),
      }
      onSubmit(payload)
      return
    }
    if (selectedFile) {
      uploadFileRequest.mutate({
        files: [selectedFile]
      }, {
        onSuccess: (response) => {
          const payload = {
            version: data.version,
            deviceType: data.deviceType as WagnerDeviceFirmwareDeviceTypeEnum,
            description: data.description,
            attachmentIds: response.map((file: UploadedFileResult) => parseInt(file.id, 10)),
          }
          onSubmit(payload)
        },
        onError: (error) => {
          addMessage({ text: error.message, type: 'error' })
        },
      })
    }
  }
  const updateFile = (value: File | null) => {
    setSelectedFile(value)
  }
  const fileLink = useMemo(() => {
    return initialValues?.attachments[0]?.link
  }, [initialValues])
  const fileName = useMemo(() => {
    return initialValues?.attachments[0]?.fileName
  }, [initialValues])
  return (
    <Formik
      initialValues={initialValues || defaultValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting }) => (
        <Form>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField name="version" label="Version" />
            </Grid>
            <Grid item xs={12}>
              <TextField name="description" label="Description" TextFieldProps={{ multiline: true, rows: 4 }} />
            </Grid>
            <Grid item xs={12}>
              <RadioGroup name="deviceType" label="Device Type" options={[
                { label: 'Data Grabber', value: WagnerDeviceFirmwareDeviceTypeEnum.DataGrabber },
                { label: 'Range Extender', value: WagnerDeviceFirmwareDeviceTypeEnum.RangeExtender },
              ]} />
            </Grid>
            <Grid item xs={12}>
              <FileUploadCard
                updateFile={updateFile}
                link={fileLink}
                fileName={fileName}
              />
            </Grid>
            <Grid item>
              <Button type="submit" variant="contained" disabled={isSubmitting}>
                {isSubmitting ? <CircularProgress size={24} /> : 'Submit'}
              </Button>
            </Grid>
            <Grid item>
              <Button type="button" variant="outlined" onClick={() => navigate(-1)}>
                Cancel
              </Button>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  )
}

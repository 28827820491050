import React, { FC, PropsWithChildren } from 'react'
import { useProjectRequest } from 'src/hooks/api'
import { DataLoad, PropsInjector } from 'src/components'
import { ProjectList } from './projectList'

interface ProjectLoaderProps {
  projectId: number | null;
}

const ProjectLoader: FC<PropsWithChildren<ProjectLoaderProps>> = ({ projectId, children }) => {
  const { data, error, isInitialLoading, isError } = useProjectRequest({
    id: projectId as number,
    options: {
      enabled: projectId !== null,
    },
  })

  const props = {
    data: data ? [data] : [],
    loading: isInitialLoading,
  }

  return (
    <DataLoad isLoading={false} isError={isError} errorMessage={error?.message}>
      <PropsInjector props={props}>{children}</PropsInjector>
    </DataLoad>
  )
}

interface ProjectsCurrentProjectProps {
  noResultText?: string;
  showFooter?: boolean;
  projectId: number | null;
}

export const ProjectsCurrentProject: FC<ProjectsCurrentProjectProps> = (
  props
) => {
  const { noResultText, showFooter, projectId } = props
  return (
    <ProjectLoader projectId={projectId}>
      <ProjectList
        count={0}
        noResultText={noResultText}
        showFooter={showFooter}
      />
    </ProjectLoader>
  )
}

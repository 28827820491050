import React, { FC } from 'react'
import { useField, ErrorMessage } from 'formik'
import { Typography } from '@mui/material'
import { Checkbox, CheckboxProps as CheckboxPropsInterface } from './checkbox'
import { Switch, SwitchProps as SwitchPropsInterface } from './switch'

interface BaseCheckboxProps {
  name: string;
  label?: string;
  error?: string;
  required?: boolean;
  disabled?: boolean;
  onChange?: (checked: boolean) => void;
}

interface CheckboxViewProps extends BaseCheckboxProps {
  view?: 'checkbox';
  CheckboxProps?: Omit<
  CheckboxPropsInterface,
    'name' | 'label' | 'required' | 'disabled' | 'onChange'
  >;
}

interface SwitchViewProps extends BaseCheckboxProps {
  view?: 'switch';
  SwitchProps?: Omit<
    SwitchPropsInterface,
    'name' | 'label' | 'required' | 'disabled' | 'onChange'
  >;
}

type FormCheckboxProps = CheckboxViewProps | SwitchViewProps;

export const FormCheckbox: FC<FormCheckboxProps> = (props) => {
  const {
    name,
    label,
    required,
    disabled,
    error,
    onChange,
    view = 'checkbox',
  } = props
  const [field] = useField({ name, type: 'checkbox' })
  const change = (...params: any[]) => {
    const [event, checked] = params
    if (onChange) onChange(checked)
    field.onChange(event)
  }
  let Field = null
  let fieldProps = null
  let fieldPropsKey = null
  switch (view) {
  case 'switch': {
    Field = Switch
    const { SwitchProps } = props as SwitchViewProps
    fieldProps = { ...SwitchProps, ...field, onChange: change }
    fieldPropsKey = 'SwitchProps'
    break
  }
  case 'checkbox':
  default: {
    Field = Checkbox
    const { CheckboxProps } = props as CheckboxViewProps
    fieldProps = { ...CheckboxProps, ...field, onChange: change }
    fieldPropsKey = 'CheckboxProps'
    break
  }
  }
  return (
    <>
      <Field
        label={label || ''}
        required={required}
        disabled={disabled}
        name={field.name}
        {...{ [fieldPropsKey]: fieldProps }}
      />
      <ErrorMessage
        name={name}
        render={(schemeError: string) => (
          <Typography variant="caption" sx={{ color: 'error.main' }}>
            {error || schemeError}
          </Typography>
        )}
      />
    </>
  )
}

import React, { FC, useState, useCallback, ChangeEvent } from "react"
import { DialogActions, Button, DialogTitle, Typography } from "@mui/material"
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'
import FormHelperText from '@mui/material/FormHelperText'
import { GridRowId } from "@mui/x-data-grid-premium"
import { Dialog } from "src/components/ui"
import format from "date-fns/format"
import { Manufacturer, ProductDraft } from "../../../api"

interface MergeProductsModalProps {
  open: boolean;
  productDrafts: ProductDraft[];
  selectedProductDraftIds: GridRowId[];
  manufacturers: Manufacturer[];
  targetProductDraftId: number | null;
  onSave: () => void;
  onCancel: () => void;
  setTargetProductDraftId: React.Dispatch<React.SetStateAction<number | null>>;
}

export const MergeProductsModal: FC<MergeProductsModalProps> = ({
  open,
  onSave,
  onCancel,
  setTargetProductDraftId,
  productDrafts,
  selectedProductDraftIds,
  manufacturers,
  targetProductDraftId,
}) => {
  const [error, setError] = useState<boolean>(false)
  const [helperText, setHelperText] = useState<string>('')

  const handleRadioGroupChange = (event: ChangeEvent<HTMLInputElement>) => {
    setTargetProductDraftId(parseInt(event.target.value))
    setHelperText('')
    setError(false)
  }

  const handleSave = () => {
    if (!targetProductDraftId) {
      setHelperText('Please choose the product')
      setError(true)
      return
    }
    onSave()
  }

  const selectedProductDrafts = useCallback(() => (
    productDrafts
      .filter((productDraft) => selectedProductDraftIds.includes(productDraft.id))
      .map((productDraft) => {
        const manufacturer = manufacturers.find((item) => item.id === productDraft.manufacturerId)
        return {
          ...productDraft,
          original: {
            ...productDraft.original,
            manufacturer: manufacturer || null
          }
        }
      })
  ), [selectedProductDraftIds, productDrafts, manufacturers])()

  return (
    <Dialog
      open={open}
      title={<DialogTitle>Merge products</DialogTitle>}
      scroll="paper"
      DialogProps={{ fullWidth: true, maxWidth: "md" }}
      actions={
        <DialogActions sx={{ paddingX: 3, paddingBottom: 3 }}>
          <Button variant="contained" size="large" onClick={handleSave}>
            Merge products
          </Button>
          <Button variant="outlined" size="large" onClick={onCancel}>
            Cancel
          </Button>
        </DialogActions>
      }
    >
      {
        selectedProductDraftIds.length > 3 && (
          <Typography
            variant="subtitle2"
            sx={{
              backgroundColor: "yellow",
              padding: 1,
              borderRadius: 2,
            }}
          >
            {`${selectedProductDraftIds.length} products selected. Please make sure that you're working with duplicates.`}
          </Typography>
        )
      }
      <Typography variant="body1">Please select the product you want to keep</Typography>
      <FormControl error={error}>
        <RadioGroup
          aria-labelledby="demo-radio-buttons-group-label"
          name="radio-buttons-group"
          onChange={handleRadioGroupChange}
          value={targetProductDraftId}
        >
          {selectedProductDrafts.map((productDraft) => (
            <FormControlLabel
              key={productDraft.id}
              value={productDraft.id}
              control={<Radio />}
              label={`${productDraft.original?.manufacturer?.name || ''}, ${productDraft.name || ''} (last updated on ${format(new Date(productDraft.updatedAt), "MMMM dd, yyyy")}; used on ${productDraft.original?.productsProjects?.length || 0} projects)`}
            />
          ))}
        </RadioGroup>
        <FormHelperText>{helperText}</FormHelperText>
      </FormControl>
    </Dialog>
  )
}

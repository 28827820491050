import React, { FC, MouseEvent, ChangeEvent } from 'react'
import {
  Table as MuiTable,
  TableProps as MuiTableProps,
  TableBody,
  TableCell,
  TableCellProps,
  TableContainer,
  TableHead,
  TableRow,
  TableFooter as MuiTableFooter,
  TablePagination as MuiTablePagination,
  Paper,
  CircularProgress,
  Typography,
  Grid,
} from '@mui/material'
import { ArrowUpward, ArrowDownward } from '@mui/icons-material'
import { useQueryParams } from 'src/hooks/ui'
import { SearchParamsKeys, SortDirection } from 'src/enums'
import { DynamicCell } from './dynamicCell'
import { PaginationActions } from './paginationActions'
import { DynamicColumn } from './interfaces'

const defaultPerPage = 25
const defaultPage = 1
const defaultCount = 0
const defaultRowsPerPageOptions = [25, 50, 100]
const noop = () => {}
const directionList = [null, SortDirection.DESC, SortDirection.ASC]

interface TableFooterProps {
  count: number;
  rowsPerPage: number;
  page: number;
  rowsPerPageOptions: number[];
  onPageChange: (
    event: MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => void;
  onRowsPerPageChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

const DefaultTableFooter: FC<Partial<TableFooterProps>> = ({
  count,
  rowsPerPage,
  page,
  rowsPerPageOptions,
  onPageChange = noop,
  onRowsPerPageChange = noop,
}) => {
  return (
    <MuiTableFooter>
      <TableRow>
        <MuiTablePagination
          rowsPerPageOptions={rowsPerPageOptions || defaultRowsPerPageOptions}
          count={count || defaultCount}
          rowsPerPage={rowsPerPage || defaultPerPage}
          page={(page || defaultPage) - 1}
          SelectProps={{
            inputProps: {
              'aria-label': 'rows per page',
            },
            native: true,
          }}
          onPageChange={onPageChange}
          onRowsPerPageChange={onRowsPerPageChange}
          ActionsComponent={PaginationActions as any}
        />
      </TableRow>
    </MuiTableFooter>
  )
}

export interface TableProps<T> {
  showFooter?: boolean;
  count?: number;
  loading?: boolean;
  noResultText?: string;
  data: T[];
  columns: DynamicColumn<T>[];
  onPageChange?: (newPage: number) => void;
  onPerPageChange?: (count: number) => void;
  TableFooter?: FC<Partial<TableFooterProps>>;
  sx?: MuiTableProps['sx'];
}

export const Table = <T extends Record<string, any>>({
  data = [],
  loading,
  columns,
  count,
  showFooter = true,
  onPageChange,
  onPerPageChange,
  sx,
  noResultText = 'No Result',
  TableFooter = DefaultTableFooter,
}: TableProps<T>) => {
  const initial: Record<string, string> = showFooter
    ? {
      page: `${defaultPage}`,
      rowsPerPage: `${defaultPerPage}`,
    }
    : {}
  const [params, setParams, removeParams] = useQueryParams(initial)

  const handleChangePage = async (
    _: MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    const result = newPage + 1
    await setParams({ [SearchParamsKeys.Page]: `${result}` }, [
      SearchParamsKeys.Page,
    ])
    if (onPageChange) onPageChange(result)
  }

  const handleChangeRowsPerPage = async ({ target: { value } }: any) => {
    const result = parseInt(value, 10)
    await setParams({ [SearchParamsKeys.RowsPerPage]: `${result}` }, [
      SearchParamsKeys.RowsPerPage,
    ])
    if (onPerPageChange) onPerPageChange(result)
  }

  const [sortColumn, sortDirection = null] = (
    params[SearchParamsKeys.Sort] || ''
  ).split(':')

  const handleChangeSort = (field: string) => async () => {
    const index = directionList.indexOf(sortDirection as SortDirection)
    const nextDirection = directionList[index + 1]
    if (nextDirection) {
      await setParams(
        { [SearchParamsKeys.Sort]: `${field}:${nextDirection}` },
        [SearchParamsKeys.Sort]
      )
    } else await removeParams([SearchParamsKeys.Sort])
  }

  let tableBody = null

  if (loading) {
    tableBody = (
      <TableRow>
        <TableCell colSpan={columns.length} align="center">
          <CircularProgress />
        </TableCell>
      </TableRow>
    )
  } else if (data.length === 0) {
    tableBody = (
      <TableRow>
        <TableCell colSpan={columns.length} align="center">
          <Typography>{noResultText}</Typography>
        </TableCell>
      </TableRow>
    )
  } else {
    tableBody = (
      <>
        {data.map((row: T, idx: number) => (
          <TableRow key={`${row.name}-${idx} `}>
            {columns.map((column: DynamicColumn<T>, columnIdx: number) => (
              <DynamicCell
                {...column}
                key={`${column.field}-${columnIdx}`}
                row={row}
                first={columnIdx === 0}
                last={columnIdx === columns.length - 1}
              />
            ))}
          </TableRow>
        ))}
      </>
    )
  }

  return (
    <TableContainer component={Paper}>
      <MuiTable sx={{ minWidth: 650, ...sx }} aria-label="table">
        <TableHead>
          <TableRow>
            {columns.map(
              ({ headerName, type, cellProps, sort, field }, idx) => {
                let align: TableCellProps['align'] =
                  type === 'string' || type === 'phone' || type === 'email'
                    ? 'left'
                    : 'right'
                if (idx === columns.length - 1) align = 'right'
                if (idx === 0) align = 'left'
                if (type === 'custom') align = cellProps?.align
                const sortable = Boolean(sort)
                return (
                  <TableCell key={idx} sx={cellProps?.sx || {}}>
                    <Grid
                      flexWrap="nowrap"
                      container
                      sx={sortable ? { cursor: 'pointer' } : {}}
                      onClick={
                        sortable
                          ? handleChangeSort(
                            typeof sort === 'boolean'
                              ? field
                              : (sort as string)
                          )
                          : noop
                      }
                      spacing={1}
                    >
                      {sortable &&
                        (typeof sort === 'boolean'
                          ? field === sortColumn
                          : sort === sortColumn) && (
                        <Grid
                          item
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                          }}
                        >
                          {sortDirection === SortDirection.DESC ? (
                            <ArrowDownward fontSize="small" />
                          ) : (
                            <ArrowUpward fontSize="small" />
                          )}
                        </Grid>
                      )}
                      <Grid item flexGrow={1} sx={{ textAlign: align }}>
                        {headerName}
                      </Grid>
                    </Grid>
                  </TableCell>
                )
              }
            )}
          </TableRow>
        </TableHead>
        <TableBody>{tableBody}</TableBody>
        {showFooter && !loading && data.length > 0 && (
          <TableFooter
            count={count}
            page={parseInt(params['page'], 10)}
            rowsPerPage={parseInt(params['rowsPerPage'], 10)}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )}
      </MuiTable>
    </TableContainer>
  )
}

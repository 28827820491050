import React, { FC, PropsWithChildren } from "react"
import { Grid, Typography } from "@mui/material"
import {
  HasSidebarLayout,
  ListHeader,
  TabGroupFilter,
  AdminNotificationList,
  DataLoad,
  PropsInjector,
  PhoneLink,
  EmailLink,
} from "src/components"
import { SearchParamsKeys } from "src/enums"
import { useAdminUserMeRequest } from "src/hooks/api"
import { User } from "src/api"
import { Box } from "@mui/material"

interface ProfileViewProps {
  user?: User;
  updateUser?: () => void;
}

const getUserListTabs = ({
  user,
  updateUser,
}: {
  user: User;
  updateUser: () => void;
}) => [
  {
    status: "Notifications",
    content: (
      <AdminNotificationList
        notifications={user?.adminNotifications || []}
        updateUser={updateUser}
        showFooter={false}
      />
    ),
  },
]

const ProfileView: FC<ProfileViewProps> = ({ user, updateUser }) => {
  return (
    <Box>
      <ListHeader title="My Profile" />
      <Grid item>
        <Typography variant="body1" sx={{ mb: 1, textTransform: "capitalize" }}>
          {user?.name}
        </Typography>
        <Grid container spacing={4}>
          <Grid item>
            <Typography variant="caption" color="gray">
              Role
            </Typography>
            <Typography variant="body1">
              {user?.role?.name ? user.role.displayName : "-"}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="caption" color="gray">
              Phone Number
            </Typography>
            <Typography variant="body1">
              {user?.phone ? <PhoneLink value={user?.phone} /> : "-"}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="caption" color="gray">
              Email
            </Typography>
            <Typography variant="body1">
              {user?.email ? <EmailLink value={user?.email} /> : "-"}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <TabGroupFilter
        name={SearchParamsKeys.Tab}
        statusList={getUserListTabs({
          user: user as User,
          updateUser: updateUser as () => void,
        })}
      />
    </Box>
  )
}

const ProfileDataLoader: FC<PropsWithChildren> = ({ children }) => {
  const { isError, error, data, refetch } = useAdminUserMeRequest({})

  const props = {
    user: data,
    updateUser: refetch,
  }

  return (
    <DataLoad isLoading={false} isError={isError} errorMessage={error?.message}>
      <PropsInjector props={props}>{children}</PropsInjector>
    </DataLoad>
  )
}

const Profile: FC = () => {
  document.title = 'Floorcloud Admin Panel - Profile'
  return (
    <HasSidebarLayout>
      <ProfileDataLoader>
        <ProfileView />
      </ProfileDataLoader>
    </HasSidebarLayout>
  )
}

export default Profile

import React from "react"
import { GridColDef } from '@mui/x-data-grid-premium'
import format from "date-fns/format"
import formatDistance from "date-fns/formatDistance"
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import { InnerLink } from "src/components/ui"
import { AdminAlert, ProductProjectSection } from "src/api"

export namespace AlertColumns {
  export const Name: GridColDef<AdminAlert> = {
    field: "title",
    headerName: "Name",
    type: "string",
    minWidth: 250,
    editable: false,
  }

  export const Status: GridColDef<AdminAlert> = {
    field: "isActive",
    headerName: "Status",
    type: "string",
    minWidth: 100,
    editable: false,
    valueFormatter: ({ value }) => value === true ? 'Active' : 'Past',
  }

  export const Type: GridColDef<AdminAlert> = {
    field: "type.name",
    headerName: "Type",
    type: "string",
    minWidth: 130,
    editable: false,
    valueGetter: ({ row }) => row.type?.name || null,
    valueFormatter: ({ value }) => value || '-',
  }

  export const Contractor: GridColDef<AdminAlert> = {
    field: "project.contractor.name",
    headerName: "Contractor",
    type: "string",
    minWidth: 200,
    editable: false,
    valueGetter: ({ row }) => row?.project?.contractor?.name || null,
    renderCell: ({ value, row }) => {
      if (!value) return '-'
      const contractorId = row.project?.contractorId
      return (
        <InnerLink to={`/contractors/${contractorId}`}>{value}</InnerLink>
      )
    }
  }

  export const Project: GridColDef<AdminAlert> = {
    field: "project.name",
    headerName: "Project",
    type: "string",
    minWidth: 200,
    editable: false,
    valueGetter: ({ row }) => row?.project?.name || null,
    renderCell: ({ value, row }) => {
      if (!value) return '-'
      return (
        <InnerLink to={`/projects/${row.project?.id}`}>{value}</InnerLink>
      )
    }
  }

  export const Section: GridColDef<AdminAlert> = {
    field: "projectSection.name",
    headerName: "Section",
    type: "string",
    minWidth: 200,
    editable: false,
    valueGetter: ({ row }) => row?.projectSection?.name || null,
    renderCell: ({ value, row }) => {
      if (!value) return '-'
      return (
        <InnerLink to={`/sections/${row.projectSection?.id}`}>{value}</InnerLink>
      )
    }
  }

  export const Sensor: GridColDef<AdminAlert> = {
    field: "projectSectionsSensors.sensor.imei",
    headerName: "Sensor IMEI",
    type: "string",
    minWidth: 200,
    editable: false,
    valueGetter: ({ row }) => {
      const alertProjectSectionsSensors = row.alertsProjectSectionsSensors || []
      const projectSectionSensor = alertProjectSectionsSensors[0]?.projectSectionSensor
      const sensor = projectSectionSensor?.sensor
      return sensor?.imei || '-'
    },
    renderCell: ({ row }) => {
      const alertProjectSectionsSensors = row.alertsProjectSectionsSensors || []
      const projectSectionSensor = alertProjectSectionsSensors[0]?.projectSectionSensor
      const sensor = projectSectionSensor?.sensor
      if (!sensor) return '-'
      return (
        <InnerLink to={`/sensors/${sensor.uniqueId}`}>{sensor.imei}</InnerLink>
      )
    }
  }

  export const StartedAt: GridColDef<AdminAlert> = {
    field: "createdAt",
    headerName: "Start date",
    type: "string",
    minWidth: 200,
    editable: false,
    valueFormatter: ({ value }) => value ? format(new Date(value), "PPpp") : "-",
  }

  export const EndedAt: GridColDef<AdminAlert> = {
    field: "endTime",
    headerName: "End date",
    type: "string",
    minWidth: 200,
    editable: false,
    valueFormatter: ({ value }) => value ? format(new Date(value), "PPpp") : "-",
  }

  export const Duration: GridColDef<AdminAlert> = {
    field: "duration",
    headerName: "Duration",
    type: "string",
    minWidth: 200,
    editable: false,
    valueGetter: ({ row }) => row.createdAt && row.endTime
      ? formatDistance(new Date(row.createdAt), new Date(row.endTime))
      : '-',
  }

  export const RealtedProducts: GridColDef<AdminAlert> = {
    field: "productsProjectSections",
    headerName: "Related products",
    type: "string",
    minWidth: 200,
    editable: false,
    valueGetter: ({ row }) => {
      const alertProductsProjectSections = row.alertsProductsProjectSections || []
      const productsProjectSections: ProductProjectSection[] = alertProductsProjectSections.map((apps) => apps.productProjectSection as ProductProjectSection).filter(Boolean)
      const products = (productsProjectSections || []).map((pps) => `${pps.product?.manufacturer?.name}, ${pps.product?.name}`)
      return products
    },
    valueFormatter: ({ value }) => value.join(';'),
    renderCell: ({ row }) => {
      const alertProductsProjectSections = row.alertsProductsProjectSections || []
      const productsProjectSections: ProductProjectSection[] = alertProductsProjectSections.map((apps) => apps.productProjectSection as ProductProjectSection).filter(Boolean)
      const products = (productsProjectSections || []).map((pps) => ({
        name: `${pps.product?.manufacturer?.name}, ${pps.product?.name}`,
      }))
      return (
        <List dense sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
          {products.map((product, idx, arr) => (
            <ListItem
              key={idx}
              sx={{ display: "list-item" }}
              divider={idx === arr.length - 1 ? false : true}
            >
              <ListItemText
                primary={product.name}
              />
            </ListItem>
          ))}
        </List>
      )
    }
  }
}

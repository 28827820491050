import React, { FC } from 'react'
import * as Yup from 'yup'
import { Button, Grid, Box, Typography } from '@mui/material'
import {
  useFormik,
  FormikContext,
  Form,
} from 'formik'
import { TextField, FormDropdown, NumberTextField } from 'src/components/ui'
import { useDropdownItemFactory } from 'src/hooks/ui'
import {
  Manufacturer,
  ProductType,
  ProductPackageType,
  ProductTipType,
  ProductTipCreation,
  ProductTipEditing,
} from 'src/api'
import { TipList } from '.'

const validationSchema = Yup.object().shape(
  {
    name: Yup.string().required('Name is required'),
    manufacturerId: Yup.number().required('Manufacturer is required'),
    typeId: Yup.number().nullable(),
    packageTypeId: Yup.number().nullable(),
    shelfLife: Yup.number()
      .nullable()
      .integer('Shelf Life must be a positive number')
      .positive('Shelf Life must be a positive number'),
    temperatureFrom: Yup.number()
      .integer()
      .nullable()
      .when('temperatureTo', (temperatureTo, schema) =>
        Number.isNaN(parseInt(temperatureTo, 10))
          ? schema
          : schema.max(temperatureTo, 'Low Temp should be less than High Temp')
      ),
    temperatureTo: Yup.number()
      .integer()
      .nullable()
      .when('temperatureFrom', (temperatureFrom, schema) =>
        Number.isNaN(parseInt(temperatureFrom, 10))
          ? schema
          : schema.min(
            temperatureFrom,
            'High Temp should be greater than Low Temp'
          )
      ),
    humidityFrom: Yup.number()
      .integer()
      .nullable()
      .moreThan(0, 'Humidity must be greater than 0')
      .lessThan(101, 'Humidity must be less or equal 100')
      .when('humidityTo', (humidityTo, schema) =>
        Number.isNaN(parseInt(humidityTo, 10))
          ? schema
          : schema.max(
            humidityTo,
            'Humidity Low should be less than Humidity High'
          )
      ),
    humidityTo: Yup.number()
      .integer()
      .nullable()
      .moreThan(0, 'Humidity must be greater than 0')
      .lessThan(101, 'Humidity must be less or equal 100')
      .when('humidityFrom', (humidityFrom, schema) =>
        Number.isNaN(parseInt(humidityFrom, 10))
          ? schema
          : schema.min(
            humidityFrom,
            'Humidity High should be greater than Humidity Low'
          )
      ),
    commonTipList: Yup.array().of(
      Yup.object().shape({
        content: Yup.string().required('Tip field should be filled'),
      })
    ),
    safetyTipList: Yup.array().of(
      Yup.object().shape({
        content: Yup.string().required('Safety tip field should be filled'),
      })
    ),
    newInstallationTip: Yup.string(),
    newSafetyTip: Yup.string(),
  },
  [
    ['humidityTo', 'humidityFrom'],
    ['temperatureFrom', 'temperatureTo'],
  ]
)

export interface ProductNewState {
  name: string;
  manufacturerId: number;
  typeId: number;
  packageTypeId: number;
  shelfLife: number;
  temperatureFrom: number;
  temperatureTo: number;
  humidityFrom: number;
  humidityTo: number;
  tips: ProductTipCreation[] | ProductTipEditing[];
}

type TechProductNewState = {
  commonTipList: ProductTipCreation[] | ProductTipEditing[];
  safetyTipList: ProductTipCreation[] | ProductTipEditing[];
  commonNewTip?: string;
  safetyNewTip?: string;
};

const defaultInitial: Partial<ProductNewState> & TechProductNewState = {
  name: '',
  manufacturerId: void 0,
  typeId: void 0,
  packageTypeId: void 0,
  shelfLife: void 0,
  temperatureFrom: void 0,
  temperatureTo: void 0,
  humidityFrom: void 0,
  humidityTo: void 0,
  tips: [],
  commonNewTip: '',
  safetyNewTip: '',
  commonTipList: [],
  safetyTipList: [],
}

interface ProductFormProps {
  manufacturers?: Manufacturer[];
  productTypes?: ProductType[];
  productTipTypes?: ProductTipType[];
  productPackageTypes?: ProductPackageType[];
  initialValues?: Partial<ProductNewState>;
  onSubmit?: (formState: ProductNewState) => void;
}

export const ProductNewForm: FC<ProductFormProps> = (props) => {
  const {
    manufacturers = [],
    productTypes = [],
    productTipTypes = [],
    productPackageTypes = [],
    initialValues: defaultValues = {},
    onSubmit: formOnSubmit = () => {},
  } = props
  const values = { ...defaultInitial, ...defaultValues }
  const { tips, ...rest } = values

  const commonTipType = productTipTypes.find(({ name }) => name === 'common')
  const safetyTipType = productTipTypes.find(({ name }) => name === 'safety')

  const commonTipList =
    tips?.filter((tip) => tip.typeId === commonTipType?.id) || []
  const safetyTipList =
    tips?.filter((tip) => tip.typeId === safetyTipType?.id) || []

  const initialValues: Omit<Partial<ProductNewState>, 'tips'> &
    TechProductNewState = {
      ...rest,
      commonTipList,
      safetyTipList,
    }

  const dropdownItemListMap = useDropdownItemFactory({
    params: { labelProp: 'name', valueProp: 'id' },
  })

  const onSubmit = (
    data: Omit<Partial<ProductNewState>, 'tips'> & TechProductNewState
  ) => {
    const cleanData: Record<string, any> = { ...data }
    const _tips = [...cleanData.commonTipList, ...cleanData.safetyTipList]
    delete cleanData.commonNewTip
    delete cleanData.safetyNewTip
    delete (cleanData as any).commonTipList
    delete (cleanData as any).safetyTipList
    for (const field in cleanData)
      if (cleanData[field] === void 0 || cleanData[field] === '')
        cleanData[field] = null
    cleanData['tips'] = _tips
    formOnSubmit(cleanData as ProductNewState)
  }

  const formik = useFormik({
    validationSchema: validationSchema,
    initialValues,
    onSubmit,
    validateOnChange: false,
    validateOnBlur: true,
  })

  const { errors } = formik
  return (
    <FormikContext.Provider value={formik}>
      <Form>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography variant="h6" sx={{ mt: 2 }}>
              Product information
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <FormDropdown
              list={manufacturers}
              name="manufacturerId"
              label="Manufacturer"
              required
              menuItemFactory={dropdownItemListMap}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              name="name"
              label="Name"
              required
              TextFieldProps={{
                helperText:
                  'Specify package size in product name to differentiate this entry from similar items',
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <FormDropdown
              list={productTypes}
              name="typeId"
              label="Type"
              menuItemFactory={dropdownItemListMap}
            />
          </Grid>
          <Grid item xs={12}>
            <FormDropdown
              list={productPackageTypes}
              name="packageTypeId"
              label="Package type"
              menuItemFactory={dropdownItemListMap}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              name="shelfLife"
              label="Shelf Life"
              TextFieldProps={{
                InputProps: {
                  inputComponent: NumberTextField as any
                },
                placeholder: 'Days',
              }}
            />
          </Grid>
          <Grid item container spacing={2} xs={12}>
            <Grid item xs={6}>
              <TextField
                name="temperatureFrom"
                label="Ambient Min Temp"
                TextFieldProps={{
                  InputProps: {
                    inputComponent: NumberTextField as any
                  },
                  placeholder: '°F',
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                name="temperatureTo"
                label="Ambient Max Temp"
                TextFieldProps={{
                  InputProps: {
                    inputComponent: NumberTextField as any
                  },
                  placeholder: '°F',
                }}
              />
            </Grid>
          </Grid>
          <Grid item container spacing={2} xs={12}>
            <Grid item xs={6}>
              <TextField
                name="humidityFrom"
                label="Ambient Min Humidity"
                TextFieldProps={{
                  InputProps: {
                    inputComponent: NumberTextField as any
                  },
                  placeholder: '%',
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                name="humidityTo"
                label="Ambient Max Humidity"
                TextFieldProps={{
                  InputProps: {
                    inputComponent: NumberTextField as any
                  },
                  placeholder: '%',
                }}
              />
            </Grid>
          </Grid>
          {commonTipType && (
            <TipList
              typeId={commonTipType.id}
              type="common"
              title="Install Tips"
              inputPlaceholder="Tip description"
            />
          )}
          {safetyTipType && (
            <TipList
              typeId={safetyTipType.id}
              type="safety"
              title="Safety Tips"
              inputPlaceholder="Safety tip description"
            />
          )}
          <Grid item xs={12}>
            <Box pt={2}>
              <Button
                size="large"
                variant="contained"
                color="primary"
                type="submit"
                disabled={Object.keys(errors).length > 0}
              >
                Save
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Form>
    </FormikContext.Provider>
  )
}

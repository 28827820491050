import { FC } from 'react'
import ArchiveIcon from '@mui/icons-material/Archive'
import Chip from '@mui/material/Chip'
import { SxProps } from '@mui/material'
import { Theme } from '@mui/material'

type Props = {
  size?: "small" | "medium"
  sx?: SxProps<Theme>
  isShortLabel?: boolean
}

export const SensorArchivedLabel: FC<Props> = ({ size = 'small', sx = {}, isShortLabel = false }) => (
  <Chip
    variant="outlined"
    color="error"
    label={isShortLabel ? "A" : "Archived"}
    avatar={<ArchiveIcon />}
    size={size}
    sx={sx}
  />
)

import { useMutation, UseMutationOptions } from '@tanstack/react-query'
import { Entities } from 'src/enums'
import { Contractor, UpdateContractorPaidPlan } from 'src/api'
import { useApiInstance } from './useApiInstance'

interface RequestParams {
  id: number;
  options?: Omit<
    UseMutationOptions<Contractor, Error, UpdateContractorPaidPlan>,
    'mutationKey' | 'mutationFn'
  >;
}

export const useEditContractorPaidPlanRequest = ({ id, options }: RequestParams) => {
  const api = useApiInstance()

  return useMutation<Contractor, Error, UpdateContractorPaidPlan>(
    [Entities.Contractors, 'edit-paid-plan', id],
    async (payload: UpdateContractorPaidPlan) => {
      const { data } = await api.v1AdminContractorsContractorIdPaidPlanPut(id, payload)
      return data as Contractor
    },
    options,
  )
}

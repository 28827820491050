import React, { FC, useState } from 'react'
import { useFormik, FormikProvider, Form } from 'formik'
import { useQueryParams } from 'src/hooks/ui'
import { SearchParamsKeys, ContractorFilters } from 'src/enums'
import { Box, Chip, FormControl, Grid, InputLabel, MenuItem, OutlinedInput, Select, SelectChangeEvent } from '@mui/material'

interface ContractorListFiltersProps {
  activeFilters: ContractorFilters[];
}

export const ContractorListFilters: FC<ContractorListFiltersProps> = ({ activeFilters }) => {
  const [queryParams, setQueryParams] = useQueryParams()
  const [hasSubscriptionStatusFilter] = useState(
    activeFilters.indexOf(ContractorFilters.Subscription) !== -1
  )

  const formik = useFormik({
    initialValues: {
      ...(hasSubscriptionStatusFilter
        ? { [SearchParamsKeys.SubscriptionStatus]: queryParams[SearchParamsKeys.SubscriptionStatus] || null }
        : {}
      ),
    },
    onSubmit: async (formState) => {
      const subscriptionStatus = formState[SearchParamsKeys.SubscriptionStatus] || null
      await setQueryParams(
        {
          ...(hasSubscriptionStatusFilter && subscriptionStatus ? { [SearchParamsKeys.SubscriptionStatus]: `${subscriptionStatus}` } : {}),
        },
        [
          SearchParamsKeys.SubscriptionStatus,
        ]
      )
    },
  })

  const { submitForm, values, setValues } = formik

  const subscriptionStatusOptions = [
    { name: 'All', value: 'all' },
    { name: 'Paid plan', value: 'paidPlan' },
    { name: 'Trial is active', value: 'trialIsActive' },
    { name: 'Trial is ended', value: 'trialIsEnded' },
  ]

  const handleSubscriptionStatusChanges = (event: SelectChangeEvent<typeof values['subscriptionStatus']>) => {
    const { target: { value } } = event
    setValues({
      subscriptionStatus: `${value}`
    })
    submitForm()
  }

  return (
    <FormikProvider value={formik}>
      <Form>
        <Grid container alignItems="center" spacing={4}>
          {hasSubscriptionStatusFilter && (
            <Grid item>
              <FormControl sx={{ mb: 1, width: 400 }}>
                <InputLabel id="subscription-status-chip-label">Subsription status</InputLabel>
                <Select
                  labelId="subscription-status-chip-label"
                  id="subscription-status-chip"
                  value={values[SearchParamsKeys.SubscriptionStatus] || ''}
                  onChange={handleSubscriptionStatusChanges}
                  input={<OutlinedInput id="select-chip" label="Subsription status" />}
                  renderValue={(selected) => {
                    const subscriptionItem = subscriptionStatusOptions.find((item) => `${item.value}` === selected)
                    return (
                      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                        <Chip key={`${subscriptionItem?.value}`} label={subscriptionItem?.name} />
                      </Box>
                    )
                  }}
                >
                  {
                    subscriptionStatusOptions.map((subscriptionItem) => (
                      <MenuItem
                        key={`${subscriptionItem?.value}`}
                        value={`${subscriptionItem?.value}`}
                      >
                        {subscriptionItem?.name}
                      </MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            </Grid>
          )}
        </Grid>
      </Form>
    </FormikProvider>
  )
}

import { useMutation, UseMutationOptions } from '@tanstack/react-query'
import { Entities } from 'src/enums'
import { ProjectType, UpdateProjectTypePayload } from 'src/api'
import { useApiInstance } from './useApiInstance'

interface RequestParams {
  id: number;
  options?: Omit<
    UseMutationOptions<unknown, Error, UpdateProjectTypePayload, ProjectType>,
    'mutationKey' | 'mutationFn'
  >;
}

export const useEditProjectTypeRequest = ({ id, options }: RequestParams) => {
  const api = useApiInstance()

  return useMutation<unknown, Error, UpdateProjectTypePayload, ProjectType>(
    [Entities.ProjectTypes, 'edit'],
    async (payload: UpdateProjectTypePayload) => {
      const { data } = await api.v1AdminProjectsTypesTypeIdPatch(
        id,
        payload,
      )
      return data
    },
    {
      ...options,
    },
  )
}

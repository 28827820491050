import { createContext } from 'react'
import { DialogNames } from 'src/enums'

export type DialogContextState = [
  Record<string, boolean>,
  (name: DialogNames | string, state: boolean) => void
];

export const DialogStateContext = createContext<DialogContextState>([
  {},
  () => {},
])

import React, { FC, useCallback, useState, PropsWithChildren, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { QueryParamsContext } from 'src/contexts'

export const QueryParamsProvider: FC<PropsWithChildren> = ({ children }) => {
  const navigate = useNavigate()
  const location = useLocation()
  const initial = Array.from(
    new URLSearchParams(location.search).entries()
  ).reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {})
  const [state, setState] = useState<Record<string, string>>(initial)

  useEffect(() => {
    const searchParams = Object.keys(state)
      .sort()
      .reduce(
        (acc, key) => ({
          ...acc,
          [key]: state[key],
        }),
        {},
      )
    const params = new URLSearchParams(searchParams).toString()
    const { pathname } = location
    const to = `${pathname}${params.length ? `?${params}` : ''}`
    navigate(to, { replace: true })
  }, [state])

  const updateState = useCallback(
    async (newState: Record<string, string>, paramsToRemove: string[] = []): Promise<void> => {
      setState((prev) => {
        const prevState = { ...prev }
        for (const param of paramsToRemove) delete prevState[param]

        const result = { ...prevState, ...newState }
        return result
      })
    },
    []
  )

  const remove = useCallback(
    async (paramsToRemove?: string[]) => {
      if (!paramsToRemove || paramsToRemove.length === 0) return
      await updateState({}, paramsToRemove)
    },
    [updateState]
  )

  const resetState = useCallback(async (): Promise<void> => new Promise((resolve) => {
    setState({})
    resolve()
  }), [])
  return (
    <QueryParamsContext.Provider value={[state, updateState, remove, resetState]}>
      {children}
    </QueryParamsContext.Provider>
  )
}

import { useMutation, UseMutationOptions } from '@tanstack/react-query'
import { APIList } from 'src/interfaces'
import { Entities } from 'src/enums'
import { Contractor } from 'src/api'
import { useApiInstance } from './useApiInstance'
import {
  buildRequestParams,
  ObjectType,
  transformPage,
  transformPerPage,
} from './helpers'

interface RequestParams {
  paramsTransform?: Array<(params: Record<string, any>) => Record<string, any>>;
  options?: Omit<
    UseMutationOptions<
      APIList<Contractor>,
      Error,
      Partial<AdminContractorGetParams>,
      APIList<Contractor>
    >,
    'mutationKey' | 'mutationFn'
  >;
}

interface AdminContractorGetParams {
  limit: number;
  offset: number;
  sort: string;
  name?: string;
}

const schema: ObjectType['schema'] = {
  limit: 'number',
  offset: 'number',
  sort: 'string',
  name: 'string',
}

export const useFilteredContractorListRequest = ({
  paramsTransform = [],
  options,
}: RequestParams = {}) => {
  const api = useApiInstance()

  return useMutation<
    APIList<Contractor>,
    Error,
    Partial<AdminContractorGetParams>,
    APIList<Contractor>
  >(
    [Entities.Contractors, 'list'],
    async (payload: Partial<AdminContractorGetParams>) => {
      const transformedParams = [
        transformPerPage,
        transformPage,
        ...paramsTransform,
      ].reduce((param, transform) => transform(param), payload)

      const queryParams = buildRequestParams<Partial<AdminContractorGetParams>>(
        transformedParams,
        schema,
      )

      const { limit, offset, sort, name } = queryParams
      const { data } = await api.v1AdminContractorsGet(limit, offset, sort, name)
      return data as APIList<Contractor>
    },
    { ...options },
  )
}

import { useMutation, UseMutationOptions } from '@tanstack/react-query'
import { Entities } from 'src/enums'
import { ReportType, UpdateReportTypePayload } from 'src/api'
import { useApiInstance } from './useApiInstance'

interface RequestParams {
  id: number;
  options?: Omit<
    UseMutationOptions<ReportType, Error, UpdateReportTypePayload>,
    'mutationKey' | 'mutationFn'
  >;
}

export const useEditReportTypeRequest = ({ id, options }: RequestParams) => {
  const api = useApiInstance()

  return useMutation<ReportType, Error, UpdateReportTypePayload>(
    [Entities.ReportTypes, 'edit', id],
    async (payload: UpdateReportTypePayload) => {
      const { data } = await api.v1AdminReportsTypesReportTypeIdPut(
        id,
        payload,
      )
      return data as ReportType
    },
    options,
  )
}

import React, { FC, FocusEvent, ReactElement } from 'react'
import {
  InputLabel,
  InputLabelProps,
  FormControl,
  Select,
  SelectProps as SelectPropsInterface,
  SelectChangeEvent,
  FormHelperText,
  FormHelperTextProps,
} from '@mui/material'
import { getRandomInt } from 'src/utils/getRandomInt'

export interface DropdownProps {
  list: any;
  name: string;
  label: string;
  required?: boolean;
  error?: boolean;
  multiple?: boolean;
  value: any;
  helperText?: string;
  menuItemFactory: (listItem: any, index: number) => ReactElement;
  renderValue?: (value: any) => any;
  LabelProps?: Omit<InputLabelProps, 'error'>;
  HelperTextProps?: FormHelperTextProps;
  SelectProps?: Omit<
    SelectPropsInterface<any>,
    | 'name'
    | 'label'
    | 'required'
    | 'value'
    | 'error'
    | 'renderValue'
    | 'fullWidth'
    | 'onChange'
    | 'onBlur'
  >;
  onChange: (event: SelectChangeEvent, value: any) => void;
  onBlur: (
    event: FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>,
    value: any
  ) => void;
}

export const Dropdown: FC<DropdownProps> = ({
  name,
  list,
  label,
  required,
  value,
  error,
  helperText,
  renderValue,
  menuItemFactory,
  onChange,
  onBlur,
  LabelProps,
  SelectProps,
  HelperTextProps,
  multiple,
}) => {
  const handleChange = (event: SelectChangeEvent) => {
    onChange(event, event.target.value)
  }

  const handleBlur = (
    event: FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>
  ) => {
    onBlur(event, event.target.value)
  }
  const labelId = `select-label-${getRandomInt(1, 500)}`

  return (
    <FormControl
      fullWidth
      margin="dense"
      variant="outlined"
      required={required}
    >
      <InputLabel
        shrink
        {...LabelProps}
        error={error}
        required={required}
        id={labelId}
        sx={{ bgcolor: 'common.white', left: -4, px: 1 }}
      >
        {label}
      </InputLabel>
      <Select
        variant="outlined"
        labelId={labelId}
        {...SelectProps}
        label={required ? `${label} *` : label}
        name={name}
        value={value || ''}
        error={error}
        onBlur={handleBlur}
        onChange={handleChange}
        required={required}
        renderValue={renderValue}
        fullWidth
        multiple={multiple}
      >
        {list.map(menuItemFactory)}
      </Select>
      {helperText && (
        <FormHelperText {...HelperTextProps}>{helperText}</FormHelperText>
      )}
    </FormControl>
  )
}

import React, { FC, useCallback } from 'react'
import { ProjectSectionSensor } from 'src/api'
import { Paper } from '@mui/material'
import {
  DataGridPremium,
} from '@mui/x-data-grid-premium'
import { useSectionSensorColumns } from './useSectionSensorColumns'

export interface SectionSensorRow {
  id: string;
  uniqueId: string;
  deviceId: string;
  event: string;
  time: string;
}

interface Event {
  name: string;
  field: keyof ProjectSectionSensor;
}

interface SectionSensorInfoProps {
  projectSectionsSenors: ProjectSectionSensor[];
}

export const SectionSensorInfo: FC<SectionSensorInfoProps> = ({ projectSectionsSenors }) => {
  const columns = useSectionSensorColumns()
  const rows: SectionSensorRow[] = useCallback(() => {
    const events: Event[] = [
      { name: 'Connected At', field: 'createdAt' },
      { name: 'Started Sending Data At', field: 'firstReadingAt' },
      { name: 'Finished Sending Data At', field: 'lastReadingAt' },
      { name: 'Disconnected At', field: 'disconnectTime' },
    ]
    const _rows: SectionSensorRow[] = []
    events.forEach((event) => {
      projectSectionsSenors.forEach((pss) => {
        if (pss.sensor) {
          _rows.push({
            id: `${event.field}_${pss.id}`,
            event: event.name,
            deviceId: pss.sensor.deviceId || '',
            uniqueId: pss.sensor.uniqueId,
            time: (pss[event.field] || '') as string
          })
        }
      })
    })
    return _rows
  }, [projectSectionsSenors])()
  return (
    <Paper sx={{ flexGrow: 1 }}>
      <DataGridPremium<SectionSensorRow>
        autoHeight
        rows={rows}
        columns={columns}
        rowThreshold={2}
        columnThreshold={2}
        disableRowSelectionOnClick
        localeText={{
          columnMenuSortAsc: "Sort A-Z",
          columnMenuSortDesc: "Sort Z-A",
        }}
        sx={(theme) => ({
          border: "none",
          "& .MuiDataGrid-columnHeaderTitleContainerContent": {
            paddingLeft: "5px",
            overflow: "visible",
          },
          "& .MuiDataGrid-columnHeaderTitle": {
            whiteSpace: "normal",
            lineHeight: 1,
            fontSize: "13px",
            fontWeight: 400,
            color: "rgba(0, 0, 0, 0.6)",
            overflow: "visible",
          },
        })}
      />
    </Paper>
  )
}

import { FC } from 'react'
import { UserGlobalNotificationColumns } from 'src/components/columns/user'
import { UserGlobalNotification, UserGlobalNotificationPayloadByTypeTypesNameEnum } from 'src/api'
import { useAddPopupMessage } from 'src/hooks/ui'
import { useSetupUserGlobalNotificationsRequest, useUserGlobalNotificationsRequest } from 'src/hooks/api'
import { Table } from '../../ui'

type Props = {
  userId: number
}

const columns = [
  UserGlobalNotificationColumns.NotificationName,
  UserGlobalNotificationColumns.Active
]

export interface EnrichedUserGlobalNotification extends UserGlobalNotification {
  toggle: () => void
}

export const UserGlobalNotificationList: FC<Props> = ({ userId }) => {
  const addMessage = useAddPopupMessage()
  const globalNotificationRequest = useUserGlobalNotificationsRequest({ userId })
  const notifications: UserGlobalNotification[] = globalNotificationRequest.data || []
  const setupGlobalNotificationRequest = useSetupUserGlobalNotificationsRequest({
    userId,
    options: {
      onSuccess: () => globalNotificationRequest.refetch(),
      onError: ({ message: text }) => {
        addMessage({ text, type: 'error' })
      },
    }
  })
  const toggleNotification = (notificationValue: {
    name: UserGlobalNotificationPayloadByTypeTypesNameEnum
    value: boolean
  }) => {
    const pairedNotificaions: UserGlobalNotificationPayloadByTypeTypesNameEnum[] = [
      'alert_closed',
      'alert_created',
      'climate_sensor_first_reading',
      'rapid_rh_first_reading',
      'product_in_specification',
      'product_out_of_specification'
    ]
    if (pairedNotificaions.includes(notificationValue.name)) {
      addMessage({
        text: 'Please double-check you make a proper changes',
        type: 'warning'
      })
    }
    setupGlobalNotificationRequest.mutate({
      types: [notificationValue]
    })
  }
  const enrichedNotifications: EnrichedUserGlobalNotification[] = notifications?.map<EnrichedUserGlobalNotification>((notification) => ({
    ...notification,
    toggle: () => {
      toggleNotification({
        name: notification.globalNotification?.name as UserGlobalNotificationPayloadByTypeTypesNameEnum,
        value: !notification.isActive
      })
      notification.isActive = !notification.isActive
    }
  })) || []
  return (
    <Table
      loading={false}
      data={enrichedNotifications || []}
      columns={columns}
      showFooter={false}
    />
  )
}

import React, { FC } from 'react'
import Grid from '@mui/material/Grid'
import { useFormik, FormikProvider, Form } from 'formik'
import { useProjectListRequest } from 'src/hooks/api'
import { useQueryParams } from 'src/hooks/ui'
import { SearchParamsKeys } from 'src/enums'
import { DataLoad, HideArchivedProjectsFilter, Table } from 'src/components'
import ProjectColumns from '../../columns/project'

const columns = [
  ProjectColumns.NameWithIcon,
  ProjectColumns.Location,
  ProjectColumns.ContractorName,
  ProjectColumns.UserName,
  ProjectColumns.ProjectTypeName,
  ProjectColumns.Size,
  ProjectColumns.ConnectedSensors,
  ProjectColumns.CreatedAt,
]

interface ContractorProjectsProps {
  contractorId: number;
}

export const ContractorProjects: FC<ContractorProjectsProps> = ({
  contractorId,
}) => {
  const [queryParams] = useQueryParams()
  const { isInitialLoading, isError, error, data } = useProjectListRequest({
    params: {
      contractorId,
      ...queryParams,
    },
  })

  const formik = useFormik({
    initialValues: {
      [SearchParamsKeys.HideArchivedProjects]: queryParams[SearchParamsKeys.HideArchivedProjects] || undefined,
    },
    onSubmit: async (formState) => {},
  })
  return (
    <Grid container flexDirection="column" spacing={2}>
      <Grid item>
        <FormikProvider value={formik}>
          <Form>
            <HideArchivedProjectsFilter />
          </Form>
        </FormikProvider>
      </Grid>
      <Grid item>
        <DataLoad isLoading={false} isError={isError} errorMessage={error?.message}>
          <Table
            loading={isInitialLoading}
            data={data?.rows || []}
            columns={columns}
            count={data?.count || 0}
          />
        </DataLoad>
      </Grid>
    </Grid>
  )
}

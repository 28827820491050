import React, { FC, useState, ChangeEvent } from "react"
import { FormikProvider, Form, useFormik } from "formik"
import * as Yup from "yup"
import { Grid, IconButton, Typography } from "@mui/material"
import { Delete } from '@mui/icons-material'
import { TextField } from "src/components/ui"
import { ImageUploadCard } from "src/components/forms/manufacturer/imageUploadCard"
import { ReleaseFeaturePayload } from "./releaseNewForm"

const validationSchema = Yup.object().shape({
  description: Yup.string().required("Feature description is required"),
  header: Yup.string().required("Feature name is required"),
})

const initialValues = {
  header: "",
  description: "",
}

interface ReleaseFeatureNewFormProps {
  defaultValues: {
    id: string | number;
    header: string;
    description?: string;
    link?: string;
  }
  onChangeFeature: (payload: Partial<ReleaseFeaturePayload>) => void
  onDeleteFeature: (id: string | number) => void
}

export const ReleaseFeatureNewForm: FC<ReleaseFeatureNewFormProps> = ({
  defaultValues,
  onChangeFeature,
  onDeleteFeature,
}) => {
  const [, setSelectedFile] = useState<File | null>(null)
  const formik = useFormik({
    initialValues: { ...initialValues, ...defaultValues },
    onSubmit: () => {},
    validationSchema,
  })
  const { values } = formik

  const updateFile = (value: File | null) => {
    setSelectedFile(value || null)
    onChangeFeature({
      ...values,
      image: value
    })
  }
  const onRemoveFile = (value: boolean) => {
    if (value === true) {
      setSelectedFile(null)
      onChangeFeature({
        ...values,
        image: null,
      })
    }
  }
  const onChange = (event: ChangeEvent<HTMLInputElement>, field: 'header' | 'description') => {
    const { value } = event.target
    onChangeFeature({
      [field]: value,
      id: values.id,
    })
  }
  const onDelete = () => {
    onDeleteFeature(values.id)
  }

  return (
    <FormikProvider value={formik}>
      <Form>
        <Grid container spacing={1} mt={5}>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            item
            xs={12}
            position="relative"
          >
            <Grid item xs={12}>
              <TextField
                name="header"
                label="Feature name"
                required
                TextFieldProps={{ placeholder: "Feature name" }}
                onBlur={(event) => onChange(event, 'header')}
              />
            </Grid>
            <Grid
              container
              item
              position="absolute"
              xs={2}
              right={-100}
            >
              <IconButton
                role="button"
                size="large"
                onClick={onDelete}
              >
                <Delete sx={{ color: 'common.black' }} />
              </IconButton>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <TextField
              name="description"
              label="Description"
              required
              TextFieldProps={{ placeholder: "Description", multiline: true, rows: 4 }}
              onChange={(event) => onChange(event, 'description')}
              onBlur={(event) => onChange(event, 'description')}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2">Photos</Typography>
            <ImageUploadCard
              updateFile={updateFile}
              setIsRemoved={onRemoveFile}
              buttonTitle="Upload photo"
              link={values.link || ""}
            />
          </Grid>
        </Grid>
      </Form>
    </FormikProvider>
  )
}

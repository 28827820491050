import React, { FC } from 'react'
import { Typography, Grid, Button } from '@mui/material'

interface InlineConfigurationParamProps {
  id: number;
  name: string;
  value: string;
  onEdit: (id: number) => void;
}

export const InlineConfigurationParam: FC<InlineConfigurationParamProps> = (props) => {
  const { id, name, value, onEdit } = props

  return (
    <>
      <Grid item container alignItems="center">
        <Grid item flexGrow={1}>
          <Typography fontWeight={500}>{name}</Typography>
          <Typography fontSize={15}>{value}</Typography>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            onClick={() => onEdit(id)}
          >
          Edit
          </Button>
        </Grid>
      </Grid>
    </>
  )
}

import { FC } from "react"
import { Grid, SelectChangeEvent, Typography } from "@mui/material"
import { GridRenderCellParams, useGridApiContext } from "@mui/x-data-grid-premium"
import { Dropdown } from "src/components/ui"
import { ProductRequestStatus } from "src/api"

interface Props extends GridRenderCellParams<any, any> {
  statuses: ProductRequestStatus[];
  statusDropdownItemListMap: any;
}

export const StatusEditInputCell: FC<Props> = ({
  id,
  value,
  field,
  statuses,
  statusDropdownItemListMap,
}) => {
  const apiRef = useGridApiContext()
  const onChange = (event: SelectChangeEvent, newValue: number | null) => {
    apiRef.current.setEditCellValue({ id, field, value: newValue })
  }
  const onBlur = () => {}
  const renderValue = (_value: number) => {
    const status = statuses.find((item) => item.id === _value)
    const statusName = status?.displayName || '-'
    return <Typography variant="body2">{statusName}</Typography>
  }

  return (
    <Grid container justifyContent="flex-start">
      <Grid item sx={{ width: '90%' }}>
        <Dropdown
          onChange={onChange}
          onBlur={onBlur}
          value={value}
          list={statuses}
          renderValue={renderValue}
          name="status"
          label="Status"
          menuItemFactory={statusDropdownItemListMap}
          SelectProps={{
            sx: { padding: 0, height: '40px' }
          }}
        />
      </Grid>
    </Grid>
  )
}



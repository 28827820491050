import React, { FC, useCallback } from "react"
import * as Yup from "yup"
import { Button, Grid, Box, CircularProgress } from "@mui/material"
import { Formik, Form, useFormikContext } from "formik"
import {
  TextField,
  PhoneInput,
  FormDropdown,
  RolesComplexDropdownItemFactory,
} from "src/components/ui"
import { Contractor, UserRole, UserCreation } from "src/api"
import { UserRoles } from "src/enums"
import { formatPhone } from "../../../utils/formatters"
import { phoneRequiredValidation } from "../../../utils/validators"

const createValidationSchema = ({ adminRoleId }: { adminRoleId: number }) =>
  Yup.object().shape({
    name: Yup.string().required("Name is required"),
    phone: phoneRequiredValidation,
    roleId: Yup.mixed().required("User role is required"),
    contractorId: Yup.number().when("roleId", {
      is: adminRoleId,
      then: Yup.number().nullable(),
      otherwise: Yup.number().required("Company is required"),
    }),
    email: Yup.string().required("Email is required").email("Email is invalid"),
  })

export interface UserNewState extends UserCreation {}

const defaultInitial: Partial<UserNewState> = {
  name: void 0,
  roleId: void 0,
  contractorId: void 0,
  phone: void 0,
  email: void 0,
}

interface UserNewFormProps {
  contractors?: Contractor[];
  userRoles?: UserRole[];
  initialValues?: Partial<UserNewState> | null;
  onSubmit?: (formState: UserNewState) => void;
  isCreating?: boolean;
}

interface UserFieldsProps {
  adminRoleId: number;
  roles: UserRole[];
  contractors: Contractor[];
  isCreating?: boolean;
}

const UserFields: FC<UserFieldsProps> = ({
  adminRoleId,
  roles,
  contractors,
  isCreating,
}) => {
  const context = useFormikContext<UserNewState>()
  const { values, setFieldValue, setFieldTouched, errors } = context
  const hasErrors = useCallback(() => Object.keys(errors).length > 0, [errors])()

  const resetCompanyId = () => {
    if (adminRoleId && values?.roleId === adminRoleId) {
      setFieldTouched("contractorId", false)
      setFieldValue("contractorId", defaultInitial.contractorId)
    }
  }

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <TextField name="name" label="Name" required />
        </Grid>
        <Grid item xs={12}>
          <FormDropdown
            list={roles}
            name="roleId"
            label="Role"
            required
            onChange={resetCompanyId}
            menuItemFactory={RolesComplexDropdownItemFactory({
              labelProp: "displayName",
              valueProp: "id",
            })}
          />
        </Grid>
        {adminRoleId && values?.roleId !== adminRoleId && (
          <Grid item xs={12}>
            <FormDropdown
              list={contractors}
              name="contractorId"
              label="Company"
              required
              menuItemFactory={RolesComplexDropdownItemFactory({
                labelProp: "name",
                valueProp: "id",
              })}
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <PhoneInput name="phone" label="Phone number" required />
        </Grid>
        <Grid item xs={12}>
          <TextField name="email" label="Email" required />
        </Grid>
        <Grid item xs={12}>
          <Box pt={2}>
            <Button
              size="large"
              variant="contained"
              color="primary"
              type="submit"
              disabled={isCreating || !!hasErrors}
            >
              { isCreating ? <CircularProgress color="info" size={26} /> : 'Save' }
            </Button>
          </Box>
        </Grid>
      </Grid>
    </>
  )
}

export const UserNewForm: FC<UserNewFormProps> = ({
  contractors = [],
  userRoles = [],
  initialValues,
  onSubmit = () => {},
  isCreating,
}) => {
  const adminRole = userRoles.find((role) => role.name === UserRoles.Admin)
  const adminRoleId = adminRole?.id || NaN

  return (
    <Formik
      initialValues={{ ...defaultInitial, ...initialValues }}
      onSubmit={(data) => {
        const cleanData: Record<string, any> = { ...data }
        if (data.roleId === adminRoleId) delete cleanData.contractorId
        cleanData.phone = formatPhone(data.phone)
        onSubmit(cleanData as UserNewState)
      }}
      validationSchema={createValidationSchema({ adminRoleId })}
    >
      <Form>
        <UserFields
          adminRoleId={adminRoleId}
          roles={userRoles}
          contractors={contractors}
          isCreating={isCreating}
        />
      </Form>
    </Formik>
  )
}

import React, { FC } from 'react'
import { Button } from '@mui/material'
import { OuterLink } from 'src/components/ui'
import { useImportProductTemplateLinkRequest } from 'src/hooks/api'

export const DownloadImportTemplateButton: FC = () => {
  const { data } = useImportProductTemplateLinkRequest()

  return (
    <OuterLink
      to={data || ''}
      style={{ textDecoration: "none" }}
    >
      <Button variant="contained">Download template</Button>
    </OuterLink>
  )
}

import { FC } from 'react'
import { Grid, Typography } from '@mui/material'
import { Table, DynamicColumn } from 'src/components/ui'
import { ProjectTypeItemDashboardStatistics } from 'src/api'
import { ProjectTypeDashboardColumns } from 'src/components/columns/dashboard'

interface ProjectTypeDashboardProps {
  projectTypeStatistics: ProjectTypeItemDashboardStatistics[];
  projectsNumber: number;
}

const columns: DynamicColumn<{ name: string, percent: number }>[] = [
  ProjectTypeDashboardColumns.ProjectType,
  ProjectTypeDashboardColumns.ProjectPercent,
]

export const ProjectTypeDashboard: FC<ProjectTypeDashboardProps> = (props) => {
  const { projectTypeStatistics, projectsNumber } = props
  const data = projectTypeStatistics.map((projectType) => {
    const percent = Math.round((projectType.projectsNumber * 100) / projectsNumber)
    return { name: projectType.name, percent }
  })
  return (
    <Grid container spacing={2}>
      <Grid item>
        <Typography variant="h5">Project Types</Typography>
      </Grid>
      <Grid item container>
        <Table
          columns={columns}
          data={data}
          showFooter={false}
          sx={{ width: '100%' }}
        />
      </Grid>
    </Grid>

  )
}

import { useMutation, UseMutationOptions } from '@tanstack/react-query'
import { Entities } from 'src/enums'
import { useApiInstance } from './useApiInstance'

interface RequestParams {
  options?: Omit<
    UseMutationOptions<boolean, Error, { probeId: string }>,
    'mutationKey' | 'mutationFn'
  >;
}

export const useDeleteRapidRhProbeRequest = ({ options }: RequestParams = {}) => {
  const api = useApiInstance()

  return useMutation<boolean, Error, { probeId: string }>(
    [Entities.WagnerProbes, 'delete'],
    async ({ probeId }) => {
      const { data } = await api.v1AdminWagnerProbesProbeIdDelete(probeId)
      return data as unknown as boolean
    },
    options,
  )
}

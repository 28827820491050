import { AlertColor } from '@mui/material'
import { DialogNames } from 'src/enums'
import { useDialog } from './useDialog'
import { useAddPopupMessage } from './useAddPopupMessage'
import { useFillSensorMissedReadingsBulkRequest, useFillSensorMissedReadingsRequest } from '../api'

export const useFillMissedUplinks = (): {
  fillMissedUplinks: (date: string, sensorUniqueId: string) => Promise<void>,
  fillMissedUplinksBulk: (date: string, imeis: string[]) => Promise<void>,
  isLoading: boolean
  isLoadingBulk: boolean
} => {
  const dialogName = DialogNames.GetSensorHistoricalData
  const { closeDialog } = useDialog(dialogName)
  const addMessage = useAddPopupMessage()
  const requestFillMissedUplinks = useFillSensorMissedReadingsRequest({
    options: {
      onSuccess: ({ status, message }) => {
        const alertColor: Record<string, AlertColor> = {
          ok: 'success',
          warn: 'warning',
          error: 'error'
        }
        const type: AlertColor = alertColor[status] || alertColor.warn
        addMessage({ text: message, type })
        if (type === 'success') closeDialog()
      },
      onError: ({ message }: { message: string }) => {
        addMessage({ text: message, type: 'error' })
      },
    },
  })
  const requestFillMissedUplinksBulk = useFillSensorMissedReadingsBulkRequest({
    options: {
      onSuccess: ({ status, message }) => {
        const alertColor: Record<string, AlertColor> = {
          ok: 'success',
          warn: 'warning',
          error: 'error'
        }
        const type: AlertColor = alertColor[status] || alertColor.warn
        addMessage({ text: message, type })
        if (type === 'success') closeDialog()
      },
      onError: ({ message }: { message: string }) => {
        addMessage({ text: message, type: 'error' })
      },
    },
  })
  const fillMissedUplinks = async (date: string, sensorUniqueId: string): Promise<void> => {
    requestFillMissedUplinks.mutate({ dateFrom: date, sensorUniqueId })
  }
  const fillMissedUplinksBulk = async (date: string, imeis: string[]): Promise<void> => {
    requestFillMissedUplinksBulk.mutate({ dateFrom: date, imeis })
  }
  return {
    fillMissedUplinks,
    fillMissedUplinksBulk,
    isLoading: requestFillMissedUplinks.isLoading,
    isLoadingBulk: requestFillMissedUplinksBulk.isLoading,
  }
}

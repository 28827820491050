import { FC, useCallback, useState } from 'react'
import { useField } from 'formik'
import { AutocompleteTextField } from 'src/components/ui'
import { useContractorListRequest, useContractorRequest } from 'src/hooks/api'
import { Contractor } from 'src/api'

type Props = {
  label: string
  name: string
  nameContractorTypeField: string
  currentContractorId?: number
  isRootContractor?: boolean
  contractor?: Contractor
}

export const RootContractorSelector: FC<Props> = ({
  label,
  name,
  nameContractorTypeField,
  currentContractorId,
  isRootContractor,
  contractor,
}) => {
  const [searchText, setSearchText] = useState<string>('')
  const [{ value: contractorType }] = useField(nameContractorTypeField)
  const contractorsRequest = useContractorListRequest({
    params: {
      isRoot: true,
      ...(searchText ? { search: searchText } : {})
    }
  })
  const currentContractorRequest = useContractorRequest({
    id: currentContractorId || 0,
    options: {
      enabled: Boolean(currentContractorId)
    }
  })
  const contractors = useCallback(() => {
    const data = contractorsRequest.data?.rows || []
    if (isRootContractor === true) {
      return data.filter((c) => c.id !== contractor?.id)
    }
    return data
  }, [contractorsRequest, contractor, isRootContractor])()
  const findContractorsByName = (search: string) => {
    if (search?.length >= 3) setSearchText(search)
  }
  
  if (contractorType !== 'branch') {
    return null
  }

  return (
    <AutocompleteTextField
      label={label}
      name={name}
      labelField="name"
      valueField="id"
      options={contractors}
      onInputChange={findContractorsByName}
      isLoading={contractorsRequest.isInitialLoading}
      initialValue={currentContractorRequest?.data || null}
    />
  )
}

import { createContext, FC, useMemo, useState, PropsWithChildren } from "react"
import {
  Manufacturer,
  ProductDraft,
  ProductDraftCreation,
  ProductPackageType,
  ProductsDraftsUpdate,
  ProductTipType,
  ProductType,
  ProductTypeTip,
} from "src/api"
import { GridRowId } from "@mui/x-data-grid-premium"
import pluralize from "pluralize"
import { useAddPopupMessage, useQueryParams } from "../hooks/ui"
import { SearchParamsKeys } from "../enums"
import {
  useDeleteProductDraftRequest,
  useManufacturerListRequest,
  useNewProductDraftRequest,
  useProductPackageTypeListRequest,
  useProductsTipTypesRequest,
  useProductTypeListRequest,
} from "../hooks/api"
import { useProductDraftListRequest } from "../hooks/api/useProductDraftList"
import { useDefaultTipsRequest } from "../hooks/api/useDefaultTipList"
import { usePublishProductDrafts } from "../hooks/api/usePublishProductDrafts"
import { useEditProductDraftRequest } from "../hooks/api/useEditProductDraft"

const defaultLimit = 9999
const sortNameAsc = "name:asc"
const params = { limit: defaultLimit, sort: sortNameAsc }

export type ProductDraftsContextState = {
  manufacturerId?: number;
  currentManufacturer?: Manufacturer;
  manufacturers: Manufacturer[];
  changeManufacturerId: (value: number) => void;

  productTypes: ProductType[];
  productPackageTypes: ProductPackageType[];
  productTipTypes: ProductTipType[];
  commonTipTypeId?: number;
  safetyTipTypeId?: number;
  defaultTips: ProductTypeTip[];

  productDrafts: ProductDraft[];
  productDraftsCount?: number;
  isProductDraftsLoading: boolean;

  selectedProductDraftIds: GridRowId[];
  setSelectedProductDraftIds: (selection: GridRowId[]) => void;

  publishProductDrafts: (draftIds: number[]) => void;
  publishSelectedDrafts: () => void;
  deleteProductDraft: (draftId: number) => void;

  editProductDrafts: (
    payload: ProductsDraftsUpdate[],
    refetch?: boolean
  ) => Promise<ProductDraft[]>;
  createNewProductDraft: (payload: ProductDraftCreation) => Promise<unknown>;

  refetchProductDrafts: () => Promise<any>;
};

const emptyProductDraftsContextState = {
  manufacturers: [],
  changeManufacturerId: () => {},

  productTypes: [],
  productPackageTypes: [],
  productTipTypes: [],
  defaultTips: [],

  productDrafts: [],
  isProductDraftsLoading: false,

  selectedProductDraftIds: [],
  setSelectedProductDraftIds: () => {},

  publishProductDrafts: () => {},
  publishSelectedDrafts: () => {},
  deleteProductDraft: () => {},

  editProductDrafts: () => Promise.resolve([]),
  createNewProductDraft: () => Promise.resolve(),

  refetchProductDrafts: () => Promise.resolve(),
}

export const ProductDraftsContext = createContext<ProductDraftsContextState>(
  emptyProductDraftsContextState
)

export const ProductDraftsProvider: FC<PropsWithChildren> = ({ children }) => {
  const [queryParams, setQueryParams] = useQueryParams()
  const addPopupMessage = useAddPopupMessage()

  const manufacturersRequest = useManufacturerListRequest({
    params,
    options: {
      onSuccess: (data) => {
        if (data?.rows?.length > 0 && manufacturerId == null) {
          setQueryParams(
            { [SearchParamsKeys.Manufacturer]: `${data?.rows[0].id}` },
            [SearchParamsKeys.Manufacturer]
          )
        }
      },
    },
  })

  const { data: { rows: manufacturers = [] } = {} } = manufacturersRequest

  const manufacturerId = useMemo(() => {
    const value = parseInt(queryParams[SearchParamsKeys.Manufacturer], 10)
    return isNaN(value) ? undefined : value
  }, [queryParams])

  const currentManufacturer = useMemo(() => {
    return manufacturers.find(({ id }) => id === manufacturerId)
  }, [manufacturerId, manufacturers])

  const changeManufacturerId = (value: number) => {
    setQueryParams(
      { ...queryParams, [SearchParamsKeys.Manufacturer]: `${value}` },
      [SearchParamsKeys.Manufacturer]
    )
  }

  const productTypesRequest = useProductTypeListRequest({ params })
  const { data: { rows: productTypes = [] } = {} } = productTypesRequest

  const productPackageTypeRequest = useProductPackageTypeListRequest({
    params,
  })
  const { data: { rows: productPackageTypes = [] } = {} } =
    productPackageTypeRequest

  const productTipTypeRequest = useProductsTipTypesRequest({ params })
  const { data: { rows: productTipTypes = [] } = {} } = productTipTypeRequest

  const commonTipTypeId = productTipTypes.find(
    ({ name }) => name === "common"
  )?.id
  const safetyTipTypeId = productTipTypes.find(
    ({ name }) => name === "safety"
  )?.id

  const defaultTipsRequest = useDefaultTipsRequest({
    params: { limit: defaultLimit },
  })
  const { data: { rows: defaultTips = [] } = {} } = defaultTipsRequest

  const {
    refetch: refetchProductDrafts,
    isInitialLoading: isProductDraftsLoading,
    data: { rows: productDrafts = [], count: productDraftsCount = 0 } = {},
  } = useProductDraftListRequest({
    params: queryParams,
    options: { enabled: !!currentManufacturer },
  })

  const [selectedProductDraftIds, setSelectedProductDraftIds] = useState<
    GridRowId[]
  >([])

  const publishProductDraftsRequest = usePublishProductDrafts()

  const publishProductDrafts = (draftIds: number[]) => {
    publishProductDraftsRequest.mutate(
      { draftIds: draftIds },
      {
        onSuccess: (data) => {
          const publishedProducts = data
            .map((response) => {
              if (response.result && response.productDraft) {
                return response.productDraft
              } else {
                addPopupMessage({ text: response.message, type: "error" })
                return null
              }
            })
            .filter((e): e is ProductDraft => !!e)

          if (publishedProducts.length > 0) {
            const productText =
              draftIds.length > 1
                ? pluralize("product", publishedProducts.length, true)
                : `Product ${publishedProducts[0].name}`
            addPopupMessage({
              text: `${productText} was successfully published`,
              type: "success",
            })
          }
          refetchProductDrafts()
        },
        onError: ({ message: text }) => {
          addPopupMessage({ text, type: "error" })
        },
      }
    )
  }

  const publishSelectedDrafts = () => {
    publishProductDrafts(selectedProductDraftIds.map((id) => +id))
    setSelectedProductDraftIds([])
  }

  const deleteProductDraftRequest = useDeleteProductDraftRequest({})

  const deleteProductDraft = (draftId: number) => {
    deleteProductDraftRequest.mutate({
      draftId,
    }, {
      onSuccess: () => {
        refetchProductDrafts()
        addPopupMessage({
          text: `The product was successfully deleted`,
          type: "success",
        })
      },
      onError: ({ message: text }) => {
        addPopupMessage({ text, type: "error" })
      },
    })
  }

  const editProductDraftRequest = useEditProductDraftRequest({})

  const editProductDrafts = (
    payload: ProductsDraftsUpdate[],
    refetch?: boolean
  ) => {
    return new Promise<ProductDraft[]>((resolve, reject) => {
      editProductDraftRequest.mutate(payload, {
        onSuccess: (data) => {
          const results = data
            ?.map((response) => {
              if (response.result && response.product) {
                return response.product
              } else {
                addPopupMessage({ text: response.message, type: "error" })
                return null
              }
            })
            .filter((e) => e)
          const filteredResults = results?.filter(
            (e): e is ProductDraft => !!e
          )

          if (refetch) {
            refetchProductDrafts()
          }
          if (filteredResults?.length) {
            resolve(filteredResults)
            const productText =
              payload.length > 1
                ? pluralize("product", filteredResults.length, true)
                : `Product ${filteredResults[0].name}`
            addPopupMessage({
              text: `${productText} was successfully updated`,
              type: "success",
            })
          } else {
            resolve([])
          }
        },
        onError: ({ message: text }) => {
          addPopupMessage({ text, type: "error" })
          resolve([])
        },
      })
    })
  }

  const newProductDraftRequest = useNewProductDraftRequest({
    options: {
      onSuccess: () => {
        addPopupMessage({
          text: "Product was successfully created",
          type: "success",
        })
        refetchProductDrafts()
      },
      onError: ({ message: text }) => {
        addPopupMessage({ text, type: "error" })
      },
    },
  })

  const createNewProductDraft = (payload: ProductDraftCreation) => {
    return new Promise((resolve, reject) => {
      newProductDraftRequest.mutate(payload, {
        onSuccess: (data) => {
          resolve(data)
        },
        onError: () => {
          reject()
        },
      })
    })
  }

  return (
    <ProductDraftsContext.Provider
      value={{
        manufacturerId,
        currentManufacturer,
        manufacturers,
        changeManufacturerId,

        productTypes,
        productPackageTypes,
        productTipTypes,
        commonTipTypeId,
        safetyTipTypeId,
        defaultTips,

        productDrafts,
        productDraftsCount,
        isProductDraftsLoading,

        selectedProductDraftIds,
        setSelectedProductDraftIds,

        publishProductDrafts,
        publishSelectedDrafts,

        deleteProductDraft,

        editProductDrafts: editProductDrafts,
        createNewProductDraft,

        refetchProductDrafts,
      }}
    >
      {children}
    </ProductDraftsContext.Provider>
  )
}

import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { APIList } from 'src/interfaces'
import { Entities } from 'src/enums'
import { Report } from 'src/api'
import { useApiInstance } from './useApiInstance'
import {
  buildRequestParams,
  ObjectType,
  transformPage,
  transformPerPage,
} from './helpers'

interface RequestParams {
  params?: Partial<ReportsGetParams>
  paramsTransform?: Array<(params: Record<string, any>) => Record<string, any>>
  options?: Omit<
    UseQueryOptions<unknown, Error, APIList<Report>>,
    'queryKey' | 'queryFn'
  >
}

interface ReportsGetParams {
  limit?: number
  offset?: number
  sort?: string
  contractorId?: number
  projectId?: number
}

const schema: ObjectType['schema'] = {
  limit: 'number',
  offset: 'number',
  sort: 'string',
  contractorId: 'number',
  projectId: 'number',
}

export const useReportListRequest = ({
  params = {},
  paramsTransform = [],
  options,
}: RequestParams = {}) => {
  const api = useApiInstance()

  const transformedParams = [
    transformPerPage,
    transformPage,
    ...paramsTransform,
  ].reduce((param, transform) => transform(param), params)
  const queryParams = buildRequestParams<ReportsGetParams>(
    transformedParams,
    schema,
  )
  return useQuery<unknown, Error, APIList<Report>>(
    [Entities.Reports, JSON.stringify(transformedParams)],
    async () => {
      const {
        limit,
        offset,
        sort,
        contractorId,
        projectId,
      } = queryParams
      const { data } = await api.v1AdminReportsGet(
        limit,
        offset,
        sort,
        contractorId,
        projectId,
      )
      return data
    },
    {
      enabled: Object.keys(transformedParams).length > 0,
      ...options,
    },
  )
}

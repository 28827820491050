import React, { FC, ReactElement, PropsWithChildren } from "react"
import {
  Dialog as MDialog,
  DialogProps as MDialogProps,
  DialogContent,
  DialogContentProps as DialogContentPropsInterface,
} from "@mui/material"

export interface DialogProps {
  open: boolean;
  onClose?: () => void;
  wrapContent?: boolean;
  title?: ReactElement;
  actions?: ReactElement;
  scroll?: "body" | "paper";
  DialogProps?: Omit<MDialogProps, "open" | "onClose">;
  DialogContentProps?: DialogContentPropsInterface;
}

export const Dialog: FC<PropsWithChildren<DialogProps>> = ({
  open,
  onClose,
  wrapContent = true,
  title = null,
  actions = null,
  children,
  DialogProps,
  DialogContentProps,
}) => {
  return (
    <MDialog {...DialogProps} open={open} onClose={onClose}>
      {title}
      {wrapContent ? (
        <DialogContent {...DialogContentProps}>{children}</DialogContent>
      ) : (
        <>{children}</>
      )}
      {actions}
    </MDialog>
  )
}

import React, { useContext, useState } from "react"
import { Button } from "@mui/material"
import {
  ProductDraftMassEditDialogForm,
  ProductDraftMassEditState,
} from "./massEditModal"
import { ProductDraftsContext } from "../../../contexts/productDraftsContext"

export const EditSelectedProductDrafts = () => {
  const [massEditProductsDialogOpened, showMassEditProductsDialogOpened] =
    useState(false)

  const {
    selectedProductDraftIds,
    editProductDrafts,
    setSelectedProductDraftIds,
  } = useContext(ProductDraftsContext)

  const handleSubmit = async (data: ProductDraftMassEditState) => {
    const hasValues = Object.values(data).filter((e) => e).length > 0
    if (hasValues) {
      await editProductDrafts(
        selectedProductDraftIds.map((id) => ({ id: +id, ...data })),
        true
      )
      setSelectedProductDraftIds([])
    }

    showMassEditProductsDialogOpened(false)
  }

  return (
    <>
      <Button
        variant="contained"
        onClick={() => showMassEditProductsDialogOpened(true)}
      >
        Mass Edit Products
      </Button>
      <ProductDraftMassEditDialogForm
        open={massEditProductsDialogOpened}
        onCancel={() => showMassEditProductsDialogOpened(false)}
        onSave={handleSubmit}
      />
    </>
  )
}

import { useMutation, UseMutationOptions } from '@tanstack/react-query'
import { EventTypeUpdatePayload } from 'src/api'
import { useApiInstance } from './useApiInstance'

interface UpdateEventTypeParams {
  eventTypeId: number;
  payload: EventTypeUpdatePayload
}

interface UpdateEventTypeRequestParams {
  options?: Omit<
    UseMutationOptions<unknown, Error | null, UpdateEventTypeParams>,
    'queryKey' | 'queryFn'
  >;
}

export const useUpdateEventTypeRequest = ({
  options,
}: UpdateEventTypeRequestParams = {}) => {
  const api = useApiInstance()
  return useMutation(
    ({
      eventTypeId,
      payload,
    }: UpdateEventTypeParams) => api.v1AdminEventsTypesEventTypeIdPatch(eventTypeId, payload),
    { ...options },
  )
}

import React, { FC } from 'react'
import { Field, ErrorMessage } from 'formik'
import { Typography } from '@mui/material'
import { Dropdown, DropdownProps } from './dropdown'

interface FormDropdownProps {
  error?: string;
  onChange?: (...rest: any) => void;
  onBlur?: (...rest: any) => void;
}

export const FormDropdown: FC<
  Omit<DropdownProps, 'value' | 'onChange' | 'onBlur' | 'error'> &
    FormDropdownProps
> = (props) => {
  const { error, onChange, onBlur, ...rest } = props
  const { name } = rest

  return (
    <>
      <Field name={name}>
        {({ field, meta: { touched, error: fieldError } }: any) => {
          const { onChange: change, onBlur: blur } = field
          const commonChange = async (...params: any[]) => {
            await change(...params)
            if (onChange) onChange(params)
          }
          const commonBlur = async (...params: any[]) => {
            await blur(...params)
            if (onBlur) onBlur(params)
          }
          return (
            <Dropdown
              {...field}
              onChange={commonChange}
              onBlur={commonBlur}
              {...rest}
              error={touched && !!fieldError}
            />
          )
        }}
      </Field>
      <ErrorMessage
        name={name}
        render={(schemeError: string) => (
          <Typography variant="caption" sx={{ color: 'error.main' }}>
            {error || schemeError}
          </Typography>
        )}
      />
    </>
  )
}

import { FC, useCallback, useEffect, useState } from 'react'
import Paper from '@mui/material/Paper'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import { DataGridPremium, GridColDef, GridPaginationModel, useGridApiRef } from '@mui/x-data-grid-premium'
import { DatePicker } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import format from "date-fns/format"
import { SensorBackupUplinkItem } from 'src/api'
import { SensorBackupUplinkColumns } from 'src/components/columns/sensor'
import { useSensorBackupUplinks } from 'src/hooks/api'

type SensorBackupUplinksProps = {
  sensorUniqueId: string
}
type TableToolbarProps = {
  downloadLatestUplinks: () => void
  onChangeDate: (value: Date | null) => void
  selectedDate: Date | null
  isLoading?: boolean
}

const defaultRowsPerPageOptions = [100, 200, 500, 1000]
const columns: GridColDef[] = [
  SensorBackupUplinkColumns.FileName,
  SensorBackupUplinkColumns.LastModifiedAt,
  SensorBackupUplinkColumns.Size,
]

const TableToolbar: FC<TableToolbarProps> = ({ downloadLatestUplinks, onChangeDate, isLoading, selectedDate }) => {
  return (
    <Grid container p={1} alignItems="center">
      <Grid item width="220px">
        <Button
          onClick={() => downloadLatestUplinks()}
          disabled={isLoading}
        >
          Download latest uplinks
        </Button>
      </Grid>
      <Grid item>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            label="Date"
            value={selectedDate}
            onChange={onChangeDate}
            inputFormat="MM/dd/yyyy"
            renderInput={(props: any) => <TextField {...props} />}
          />
        </LocalizationProvider>
      </Grid>
    </Grid>
  )
}

export const SensorBackupUplinks: FC<SensorBackupUplinksProps> = (props) => {
  const [page, setPage] = useState<number>(0)
  const [pageSize, setPageSize] = useState<number>(defaultRowsPerPageOptions[0])
  const [selectedDate, setSelectedDate] = useState<Date | null>(null)
  const { sensorUniqueId } = props
  const apiRef = useGridApiRef()
  const getUTCDate = (date: Date | null): string | undefined => {
    if (!date) return undefined
    return format(date, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'")
  }
  const { data, isInitialLoading, isRefetching, refetch } = useSensorBackupUplinks({
    params: { uniqueId: sensorUniqueId, date: getUTCDate(selectedDate) },
    options: {
      enabled: false,
    }
  })

  useEffect(() => {
    downloadUplinks()
  }, [selectedDate])

  const handleChangePaginationModel = async (pagination: GridPaginationModel): Promise<void> => {
    setPage(pagination.page)
    setPageSize(pagination.pageSize)
  }
  const downloadLatestUplinks = () => {
    setSelectedDate(null)
  }
  const downloadUplinks = () => {
    refetch()
  }
  const getUplinksDatetime = useCallback((): string => {
    if (!data?.rows || data.rows.length === 0) {
      return ''
    }
    const firstUplink = data.rows[0]
    if (!firstUplink) {
      return ''
    }
    return firstUplink.datetime
  }, [data])()
  const onChangeDate = (value: Date | null) => {
    setSelectedDate(value)
  }

  return (
    <>
      <Grid container spacing={2} alignItems="center" mb={1}>
        <Grid item>
          <Typography variant="h6">
            Last received raw data
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="subtitle1">
            {getUplinksDatetime ? `Date - ${format(new Date(getUplinksDatetime), "PP")}` : null}
          </Typography>
        </Grid>
      </Grid>
      <Paper sx={{ height: '500px' }}>
        <DataGridPremium<SensorBackupUplinkItem>
          apiRef={apiRef}
          pagination
          autoHeight={false}
          disableRowSelectionOnClick
          rows={data?.rows || []}
          columns={columns}
          rowCount={data?.count || 0}
          loading={isInitialLoading || isRefetching}
          paginationModel={{
            page: page as number,
            pageSize: pageSize as number
          }}
          rowThreshold={2}
          columnThreshold={2}
          paginationMode="client"
          pageSizeOptions={defaultRowsPerPageOptions}
          onPaginationModelChange={handleChangePaginationModel}
          localeText={{
            columnMenuSortAsc: "Sort A-Z",
            columnMenuSortDesc: "Sort Z-A",
          }}
          slots={{
            toolbar: () => <TableToolbar downloadLatestUplinks={downloadLatestUplinks} isLoading={isInitialLoading} onChangeDate={onChangeDate} selectedDate={selectedDate} />
          }}
          getRowId={(row) => row.fileName}
          sx={(theme) => ({
            border: "none",
            "& .MuiDataGrid-columnHeaderTitleContainerContent": {
              paddingLeft: "5px",
              overflow: "visible",
            },
            "& .MuiDataGrid-columnHeaderTitle": {
              whiteSpace: "normal",
              lineHeight: 1,
              fontSize: "13px",
              fontWeight: 400,
              color: "rgba(0, 0, 0, 0.6)",
              overflow: "visible",
            },
          })}
        />
      </Paper>
    </>
  )
}
import React, { FC } from 'react'
import { Typography, DialogActions, Button, Box } from '@mui/material'
import { StatefulDialog } from 'src/components/ui'
import { DialogNames } from 'src/enums'

interface DeleteReleaseModalProps {
  releaseName: string;
  onDelete: () => void;
  onCancel: () => void;
}

export const DeleteReleaseModal: FC<DeleteReleaseModalProps> = ({
  releaseName,
  onDelete,
  onCancel,
}) => {
  return (
    <StatefulDialog
      name={DialogNames.DeleteRelease}
      actions={
        <Box mb={2} mx={2}>
          <DialogActions>
            <Button variant="outlined" onClick={onCancel}>
              Cancel
            </Button>
            <Button variant="contained" color="error" onClick={onDelete}>
              Delete
            </Button>
          </DialogActions>
        </Box>
      }
    >
      <Box minWidth="300px">
        <Typography variant="h6">Are you sure you want to delete the release "{releaseName}"?</Typography>
      </Box>
    </StatefulDialog>
  )
}

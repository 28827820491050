import React, { FC, useState, useCallback, PropsWithChildren } from 'react'
import { PopupMessageContext } from 'src/contexts'
import { PopupMessage } from 'src/interfaces'

export const PopupMessageProvider: FC<PropsWithChildren> = ({ children }) => {
  const [messageList, setMessageList] = useState<PopupMessage[]>([])
  const [current, setCurrent] = useState<PopupMessage | null>(null)
  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | null>(null)

  const onClose = useCallback(() => {
    const [first = null, ...rest] = messageList
    setCurrent(null)
    setMessageList(rest)
    if (first !== null) {
      clearTimeout(timeoutId as NodeJS.Timeout)
      const id = setTimeout(() => {
        setCurrent(first)
        setTimeoutId(null)
      }, 100)
      setTimeoutId(id)
    }
  }, [messageList, timeoutId])

  const add = useCallback(
    (message: PopupMessage | PopupMessage[]) => {
      let newList = Array.isArray(message) ? message : [message]
      const list = [...messageList, ...newList]
      const [first, ...rest] = list
      setMessageList(rest)
      if (current === null) setCurrent(first)
    },
    [current, messageList]
  )

  return (
    <PopupMessageContext.Provider value={{ message: current, add, onClose }}>
      {children}
    </PopupMessageContext.Provider>
  )
}

import React, { FC } from 'react'
import { Grid, Typography } from '@mui/material'
import { useProjectTypeListRequest } from 'src/hooks/api'
import { DataLoad } from 'src/components/ui'
import { NewProjectType } from './newProjectType'
import { ProjectTypeList } from './projectTypeList'

export const LoadableProjectTypeList: FC = () => {
  const { data, error, isInitialLoading, isError, refetch } =
    useProjectTypeListRequest({
      params: { limit: 9999 },
    })

  return (
    <Grid container direction="column" spacing={4}>
      <Grid item container direction="column">
        <Grid item>
          <Typography variant="h6" sx={{ pt: 2 }}>
            Create New Project Type
          </Typography>
        </Grid>
        <Grid item>
          <NewProjectType onCreate={refetch} />
        </Grid>
      </Grid>
      <Grid item>
        <Grid item sx={{ pb: 2 }}>
          <Grid item>
            <Typography variant="h6">Project Type List</Typography>
          </Grid>
          <Grid>
            <Typography color="gray">
              Forbidden to edit or delete project type if it used on any
              project
            </Typography>
          </Grid>
        </Grid>
        <Grid item>
          <DataLoad
            isLoading={isInitialLoading}
            isError={isError}
            errorMessage={error?.message}
          >
            <ProjectTypeList data={data?.rows || []} onChange={refetch} />
          </DataLoad>
        </Grid>
      </Grid>
    </Grid>
  )
}

import { createContext } from 'react'

export type AuthorizationContextState = [
  string | null,
  (state: string | null) => void
];

export const AuthorizationContext = createContext<AuthorizationContextState>([
  '',
  () => {},
])

import { FC, useState } from 'react'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import {
  DataGridPremium,
  GridColDef,
  GridPaginationModel,
  gridFilterModelSelector,
  gridFilteredTopLevelRowCountSelector,
  useGridApiRef,
} from '@mui/x-data-grid-premium'
import { useSystemMessagesHistoryRequest } from 'src/hooks/api'
import { EntityHeader, HasSidebarLayout, TableToolbar } from 'src/components'
import { SystemMessageHistoryColumns } from 'src/components/columns/systemMessage'
import { SystemMessageHistory } from 'src/api'
import { DownloadingFilePayload, useDownloadingFile, useQueryParams } from 'src/hooks/ui'
import { SearchParamsKeys } from 'src/enums'
import { defaultRowsValuePerPage } from 'src/config'

export type ExtendedSystemMessageHistory = SystemMessageHistory & {
  downloadUsers?: {
    urlPath?: string
    queryParams?: Record<string, string>
  }
}

const columns = (downloadFile: (payload: DownloadingFilePayload) => void): GridColDef[] => [
  SystemMessageHistoryColumns.Author,
  SystemMessageHistoryColumns.Status,
  SystemMessageHistoryColumns.Title,
  SystemMessageHistoryColumns.Message,
  SystemMessageHistoryColumns.MessageType,
  SystemMessageHistoryColumns.TransportType,
  SystemMessageHistoryColumns.MessageAudience,
  SystemMessageHistoryColumns.CustomParams,
  SystemMessageHistoryColumns.Recipients(downloadFile),
]
const defaultPerPage = 50
const defaultPage = 1
const defaultRowsPerPageOptions = defaultRowsValuePerPage
const initialQueryParams = {
  page: `${defaultPage}`,
  rowsPerPage: `${defaultPerPage}`,
}

export const MessageHistory: FC = () => {
  document.title = 'System Messages History'
  const [queryParams, setQueryParams] = useQueryParams(initialQueryParams)
  const apiRef = useGridApiRef()
  const { data, isInitialLoading } = useSystemMessagesHistoryRequest({
    params: queryParams,
  })
  const [rowCountState, setRowCountState] = useState(data?.count || 0)
  const { downloadFile } = useDownloadingFile()
  const clientTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
  const history = data?.rows || []
  const extendedHistory: ExtendedSystemMessageHistory[] = history.map((item) => {
    return {
      ...item,
      downloadUsers: {
        urlPath: `v1/admin/system-messages/history/${item.id}/export-users`,
        ueryParams: { timeZone: clientTimeZone },
      },
    }
  })
  const page = queryParams["page"] ? parseInt(queryParams["page"], 10) - 1 : defaultPage - 1
  const pageSize = queryParams["rowsPerPage"]
    ? parseInt(queryParams["rowsPerPage"], 10)
    : defaultPerPage

  const handleChangePaginationModel = async (pagination: GridPaginationModel): Promise<void> => {
    const newPage = pagination.page + 1
    setQueryParams({
      [SearchParamsKeys.Page]: `${newPage}`,
      [SearchParamsKeys.RowsPerPage]: `${pagination.pageSize}`,
    }, [
      SearchParamsKeys.Page,
      SearchParamsKeys.RowsPerPage,
    ])
  }

  const handleChangeState = async () => {
    const filterModel = gridFilterModelSelector(apiRef.current.state)
    if (filterModel.items.length > 0 && filterModel.items[0].value) {
      const visibleRowCount = gridFilteredTopLevelRowCountSelector(apiRef.current.state)
      if (visibleRowCount) setRowCountState(visibleRowCount)
    }
    else setRowCountState(data?.count || 0)
  }
  
  return (
    <HasSidebarLayout>
      <Grid container flexDirection="column">
        <Grid item>
          <EntityHeader
            title="Message History"
          />
        </Grid>
        <Grid item width="100%">
          <Paper sx={{ flexGrow: 1, minHeight: '200px', height: '100%' }}>
            <DataGridPremium<ExtendedSystemMessageHistory>
              apiRef={apiRef}
              pagination
              autoHeight={false}
              disableRowSelectionOnClick
              rows={extendedHistory}
              columns={columns(downloadFile)}
              rowCount={rowCountState}
              loading={isInitialLoading}
              getRowHeight={() => 'auto'}
              paginationModel={{
                page: page as number,
                pageSize: pageSize as number
              }}
              rowThreshold={2}
              columnThreshold={2}
              paginationMode="server"
              pageSizeOptions={defaultRowsPerPageOptions}
              onPaginationModelChange={handleChangePaginationModel}
              onStateChange={handleChangeState}
              localeText={{
                columnMenuSortAsc: "Sort A-Z",
                columnMenuSortDesc: "Sort Z-A",
              }}
              slots={{
                toolbar: () => <TableToolbar />
              }}
              sx={(theme) => ({
                border: "none",
                "& .MuiDataGrid-columnHeaderTitleContainerContent": {
                  paddingLeft: "5px",
                  overflow: "visible",
                },
                "& .MuiDataGrid-columnHeaderTitle": {
                  whiteSpace: "normal",
                  lineHeight: 1,
                  fontSize: "13px",
                  fontWeight: 400,
                  color: "rgba(0, 0, 0, 0.6)",
                  overflow: "visible",
                },
              })}
            />
          </Paper>
        </Grid>
      </Grid>
    </HasSidebarLayout>
  )
}

export default MessageHistory

import React, { FC, useState, ChangeEvent } from "react"
import { FormikProvider, Form, useFormik } from "formik"
import * as Yup from "yup"
import { Grid, IconButton, Typography } from "@mui/material"
import { Delete } from '@mui/icons-material'
import { TextField } from "src/components/ui"
import { ImageUploadCard } from "src/components/forms/manufacturer/imageUploadCard"
import { ManufacturerAnnouncementPostPayload } from "./manufacturerAnnouncementNewForm"

const validationSchema = Yup.object().shape({
  description: Yup.string().required("Post description is required"),
  title: Yup.string(),
  videoLink: Yup.string().url("Invalid URL"),
})

const initialValues = {
  title: "",
  description: "",
  videoLink: "",
}

interface Props {
  defaultValues: {
    id: string | number;
    title?: string;
    description?: string;
    videoLink?: string;
    link?: string;
  }
  onChangePost: (payload: Partial<ManufacturerAnnouncementPostPayload>) => void
  onDeletePost: (id: string | number) => void
}

const DEFAULT_ROW_NUMBER = 4
const EXTENDED_ROW_NUMBER = 15

export const ManufacturerAnnouncementPostNewForm: FC<Props> = ({
  defaultValues,
  onChangePost,
  onDeletePost,
}) => {
  const [descriptionRows, setDescriptionRows] = useState<number>(DEFAULT_ROW_NUMBER)
  const [, setSelectedFile] = useState<File | null>(null)
  const formik = useFormik({
    initialValues: { ...initialValues, ...defaultValues },
    onSubmit: () => {},
    validationSchema,
  })
  const { values } = formik

  const updateFile = (value: File | null) => {
    setSelectedFile(value || null)
    onChangePost({
      ...values,
      photo: value
    })
  }
  const onRemoveFile = (value: boolean) => {
    if (value === true) {
      setSelectedFile(null)
      onChangePost({
        ...values,
        photo: null,
      })
    }
  }
  const onChange = (event: ChangeEvent<HTMLInputElement>, field: 'title' | 'description' | 'videoLink') => {
    const { value } = event.target
    onChangePost({
      [field]: value,
      id: values.id,
    })
  }
  const onDelete = () => {
    onDeletePost(values.id)
  }
  const onDescriptionFocus = () => {
    setDescriptionRows(EXTENDED_ROW_NUMBER)
  }
  const onDescriptionBlur = (event: ChangeEvent<HTMLInputElement>) => {
    onChange(event, 'description')
    setDescriptionRows(DEFAULT_ROW_NUMBER)
  }

  return (
    <FormikProvider value={formik}>
      <Form>
        <Grid container spacing={1}>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            item
            xs={12}
            position="relative"
          >
            <Grid item xs={12}>
              <TextField
                name="title"
                label="Subheader"
                TextFieldProps={{ placeholder: "Subheader" }}
                onBlur={(event) => onChange(event, 'title')}
              />
            </Grid>
            <Grid
              container
              item
              position="absolute"
              xs={2}
              right={-100}
            >
              <IconButton
                role="button"
                size="large"
                onClick={onDelete}
              >
                <Delete sx={{ color: 'common.black' }} />
              </IconButton>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <TextField
              name="description"
              label="Description"
              required
              TextFieldProps={{
                placeholder: "Description",
                multiline: true,
                rows: descriptionRows,
                onFocus: onDescriptionFocus,
              }}
              onChange={(event) => onChange(event, 'description')}
              onBlur={onDescriptionBlur}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              name="videoLink"
              label="YouTube video link"
              TextFieldProps={{
                placeholder: "Link",
                multiline: false,
              }}
              onChange={(event) => onChange(event, 'videoLink')}
            />
          </Grid>
          <Grid item xs={12} mb={5}>
            <Typography variant="body2">Photos</Typography>
            <ImageUploadCard
              updateFile={updateFile}
              setIsRemoved={onRemoveFile}
              buttonTitle="Upload photo"
              link={values.link || ""}
            />
          </Grid>
        </Grid>
      </Form>
    </FormikProvider>
  )
}

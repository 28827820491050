import React, { FC, MouseEvent, useCallback } from 'react'
import {
  ToggleButtonGroup,
  ToggleButton,
  ToggleButtonGroupProps as ToggleButtonGroupPropsInterface,
  ToggleButtonProps as ToggleButtonPropsInterface,
} from '@mui/material'
import { useQueryParams } from 'src/hooks/ui'

export interface ButtonGroupItem {
  displayName: string;
  value: string;
  disabled?: boolean;
}

interface ButtonGroupFilterProps {
  list: ButtonGroupItem[];
  name: string;
  paramsToRemove?: string[];
  ToggleButtonGroupProps?: Omit<
    ToggleButtonGroupPropsInterface,
    'exclusive' | 'color' | 'value' | 'onChange'
  >;
  ToggleButtonProps?: Omit<ToggleButtonPropsInterface, 'disabled' | 'value'>;
}

export const ButtonGroupFilter: FC<ButtonGroupFilterProps> = ({
  list,
  name,
  paramsToRemove = [],
  ToggleButtonGroupProps,
  ToggleButtonProps,
}) => {
  const initialValue = list.length > 0 ? { [name]: list[0].value } : {}
  const [params, setParams] = useQueryParams(initialValue)
  const updateTab = useCallback(
    async (_: MouseEvent, result: string) => {
      if (!result) return
      await setParams({ [name]: result }, [name, ...paramsToRemove])
    },
    [name, paramsToRemove, setParams]
  )

  return (
    <ToggleButtonGroup
      exclusive
      color="primary"
      value={params[name]}
      onChange={updateTab}
      sx={{ height: '68px', pt: 1, pb: 0.5, ...(ToggleButtonGroupProps || {})?.sx }}
      {...ToggleButtonGroupProps}
    >
      {list.map(({ displayName, value, disabled }) => (
        <ToggleButton
          key={value}
          disabled={disabled}
          value={value}
          {...ToggleButtonProps}
        >
          {displayName}
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  )
}

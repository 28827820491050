import React, { FC } from "react"
import { ApexOptions } from "apexcharts"
import { merge } from 'lodash'
import { SensorChart } from "src/components/ui"
import { SignalStrengths } from "../../../../enums"
import { getSignalStrengthColor } from "../../../ui/map/sensorsMapLegend"

const weakSignalColor = getSignalStrengthColor(SignalStrengths.Weak)
const fairSignalColor = getSignalStrengthColor(SignalStrengths.Fair)
const goodSignalColor = getSignalStrengthColor(SignalStrengths.Good)

const signalStrengthOptions: Partial<Omit<ApexOptions, "series">> = {
  annotations: {
    yaxis: [
      {
        y: -100,
        borderColor: weakSignalColor,
        borderWidth: 2,
        strokeDashArray: 0,
        offsetX: 8,
        offsetY: -1,
        label: {
          position: "left",
          text: "Weak, -100 dBm",
          borderColor: weakSignalColor,
          textAnchor: "start",
          style: {
            color: "#fff",
            background: weakSignalColor,
          },
        },
      },
      {
        y: -85,
        borderColor: fairSignalColor,
        borderWidth: 2,
        strokeDashArray: 0,
        offsetX: 8,
        offsetY: -1,
        label: {
          position: "left",
          text: "Fair, -85 dBm",
          borderColor: fairSignalColor,
          textAnchor: "start",
          style: {
            color: "#000",
            background: fairSignalColor,
          },
        },
      },
      {
        y: -70,
        borderColor: goodSignalColor,
        borderWidth: 2,
        strokeDashArray: 0,
        offsetX: 8,
        offsetY: -1,
        label: {
          position: "left",
          text: "Good, -70 dBm",
          borderColor: goodSignalColor,
          textAnchor: "start",
          style: {
            color: "#fff",
            background: goodSignalColor,
          },
        },
      },
    ],
  },
}

interface SignalStrengthChartProps {
  data: ApexOptions["series"];
  isLoading?: boolean;
  options?: Partial<Omit<ApexOptions, 'series'>>;
}

export const SignalStrengthChart: FC<SignalStrengthChartProps> = ({ data, options, isLoading = false }) => {
  const mergedOptions = merge(signalStrengthOptions, options)
  return <SensorChart data={data} options={mergedOptions} isLoading={isLoading} />
}

import { FC } from "react"
import { Grid, Typography, IconButton } from "@mui/material"
import EditIcon from '@mui/icons-material/Edit'
import { GridRenderCellParams, useGridApiContext } from "@mui/x-data-grid-premium"
import { ProductRequestStatus } from "src/api"

interface Props extends GridRenderCellParams<any, any> {
  statuses: ProductRequestStatus[];
}

export const StatusInlineCell: FC<Props> = ({
  id,
  value,
  field,
  statuses,
}) => {
  const apiRef = useGridApiContext()
  if (!value) return null

  const status = statuses.find((item) => item.id === value)
  if (!status) return null

  const onClick = () => {
    apiRef.current.startCellEditMode({ id, field })
  }

  return (
    <Grid container justifyContent="space-between" alignItems="center">
      <Grid>
        <Typography variant="body2">{status.displayName}</Typography>
      </Grid>
      <Grid>
        <IconButton aria-label="edit" size="small" onClick={onClick}>
          <EditIcon fontSize="small" />
        </IconButton>
      </Grid>
    </Grid>
  )
}



import React, { FC } from 'react'
import { HasSidebarLayout, Wip } from 'src/components'

const Gateways: FC = () => {
  return (
    <HasSidebarLayout>
      <Wip title="Gateways" redirectTitle="Sensors" redirectTo="/sensors" />
    </HasSidebarLayout>
  )
}

export default Gateways

import { useMutation, UseMutationOptions } from '@tanstack/react-query'
import { Entities } from 'src/enums'
import { UserCreation, CreateUserResponseV2 } from 'src/api'
import { useApiInstance } from './useApiInstance'
import { buildRequestParams, ObjectType } from './helpers'

interface RequestParams {
  options?: Omit<UseMutationOptions<CreateUserResponseV2, Error, UserCreation, CreateUserResponseV2>, 'mutationKey' | 'mutationFn'>
}

const schema: ObjectType['schema'] = {
  name: 'string',
  roleId: 'number',
  contractorId: 'number',
  email: 'string',
  phone: 'string',
}

export const useNewUserRequestV2 = ({
  options,
}: RequestParams) => {
  const api = useApiInstance()

  return useMutation<CreateUserResponseV2, Error, UserCreation, CreateUserResponseV2>(
    [Entities.Users, 'createV2'],
    async (payload: UserCreation) => {
      const params = buildRequestParams<UserCreation>(payload, schema)
      const { data } = await api.v2AdminUsersPost(params)
      return data as CreateUserResponseV2
    },
    { ...options },
  )
}

import { FC } from 'react'
import { Grid } from '@mui/material'
import { TypeNewForm } from 'src/components/forms'

interface Props {
  name: string;
  onChange: (payload: { displayName: string }) => void;
  onCancel: () => void;
  placeholder: string;
  isLoading?: boolean;
}

export const EditableType: FC<Props> = ({
  name,
  placeholder = 'New GangBox Type Name',
  isLoading,
  onChange,
  onCancel,
}) => {
  return (
    <>
      <Grid item flexGrow={1}>
        <TypeNewForm
          onSubmit={onChange}
          initialValues={{ displayName: name }}
          onCancel={onCancel}
          placeholder={placeholder}
          isLoading={isLoading}
        />
      </Grid>
    </>
  )
}
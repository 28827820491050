import { useMutation, UseMutationOptions } from '@tanstack/react-query'
import { Entities } from 'src/enums'
import { BuildingType, UpdateBuildingTypePayload } from 'src/api'
import { useApiInstance } from './useApiInstance'

interface RequestParams {
  id: number;
  options?: Omit<
    UseMutationOptions<unknown, Error, UpdateBuildingTypePayload, BuildingType>,
    'mutationKey' | 'mutationFn'
  >;
}

export const useEditBuildingTypeRequest = ({ id, options }: RequestParams) => {
  const api = useApiInstance()

  return useMutation<unknown, Error, UpdateBuildingTypePayload, BuildingType>(
    [Entities.BuildingTypes, 'edit', id],
    async (payload: UpdateBuildingTypePayload) => {
      const { data } = await api.v1AdminBuildingTypesTypeIdPatch(
        id,
        payload,
      )
      return data
    },
    options,
  )
}

import { FC } from "react"
import { Grid, Typography, IconButton, Tooltip } from "@mui/material"
import EditIcon from '@mui/icons-material/Edit'
import { GridRenderCellParams, useGridApiContext } from "@mui/x-data-grid-premium"

interface Props extends GridRenderCellParams<any, any> {}

export const CommentInlineCell: FC<Props> = ({
  id,
  value,
  field,
}) => {
  const apiRef = useGridApiContext()
  if (!value) return null

  const onClick = () => {
    apiRef.current.startCellEditMode({ id, field })
  }

  return (
    <Tooltip title={value}>
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid sx={{ width: '70%' }}>
          <Typography variant="body2" textOverflow="ellipsis" overflow="hidden">{value}</Typography>
        </Grid>
        <Grid>
          <IconButton aria-label="edit" size="small" onClick={onClick}>
            <EditIcon fontSize="small" />
          </IconButton>
        </Grid>
      </Grid>
    </Tooltip>
  )
}



import axios, { AxiosRequestConfig } from 'axios'

import { config as appConfig } from 'src/config'
import { HTTPMethods } from 'src/enums'

const DEFAULT_HEADERS = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
}

interface RequestArgs<T> {
  method?: HTTPMethods;
  query: string;
  headers?: Record<string, string>;
  params?: any;
  data?: any;
  host?: string;
  fake?: boolean;
  fakeFactory?: (config: AxiosRequestConfig) => Promise<T>;
}

export const request = async <T>({
  method = HTTPMethods.GET,
  query,
  headers: customHeaders,
  params,
  data,
  fake,
  fakeFactory,
}: RequestArgs<T>) => {
  const { apiPath } = appConfig

  const config: AxiosRequestConfig = {
    method,
    url: `${apiPath}/${query}`,
    headers: { ...DEFAULT_HEADERS, ...customHeaders },
    params,
    data,
  }

  try {
    if (fake && fakeFactory) {
      const fakeData = await fakeFactory(config)
      return fakeData
    }
    const response = await axios(config)
    return response.data
  } catch (error: any) {
    return Promise.reject(new Error({
      ...error?.response?.data,
      statusCode: error?.response?.status,
    }))
  }
}

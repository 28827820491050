import React, { FC, useMemo, useState } from "react"
import { Menu, MenuItem } from "@mui/material"
import MoreVertIcon from "@mui/icons-material/MoreVert"
import { GridActionsCellItem, GridColDef } from "@mui/x-data-grid-premium"
import { SensorColumnsDataGrid } from "src/components/columns/sensor"
import { Sensor, SensorState } from "src/api"
import {
  StatefulDialog,
  SensorsMapView,
} from "src/components/ui"
import {
  DialogNames,
  SensorStateList,
  SearchParamsKeys,
  ViewTypes,
  SensorFilters,
  SensorButtons,
} from "src/enums"
import { useDialog, useSensorStateUpdate } from "src/hooks/ui"
import { useAssignSensorRequest } from "src/hooks/api"
import { useQueryParamsState } from "src/hooks/ui"
import { SensorListFilters } from "src/components/forms/sensor"
import { CommonSensorList } from "./commonSensorsList"
import { ContractorsDataLoader } from "./contractorsDataLoader"
import { SensorMapFilters } from "../../../forms/sensor/sensorMapFilters"
import { AssignSensorToContractor } from "../assignSensorToContractor"
import { ArchiveSensorModal } from "../archiveSensorModal"

interface AvailableSensorListProps {
  isLoading: boolean;
  count: number;
  data: Sensor[];
  states: SensorState[];
  refetchSensorList: () => void;
}

export const AvailableSensorList: FC<AvailableSensorListProps> = ({
  data,
  isLoading,
  count,
  states,
  refetchSensorList,
}) => {
  const [currentRow, setCurrentRow] = useState<Sensor | null>(null)
  const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLElement | null>(null)
  const queryParams = useQueryParamsState()
  const viewType = queryParams[SearchParamsKeys.ViewType] || ViewTypes.List
  const [sensorUniqueId, setSensorUniqueId] = useState<string | null>(null)
  const { openDialog, closeDialog } = useDialog(
    DialogNames.AssignSensorToContractor
  )
  const {
    openSensorArchivingDialog,
    closeSensorArchivingDialog,
    updateSensorState,
    isLoadingChangingSensorState,
  } = useSensorStateUpdate()

  const assignSensorRequest = useAssignSensorRequest({
    options: {
      onSettled: () => closeDialog(),
      onSuccess: () => refetchSensorList(),
    },
  })

  const assignSensor = (contractorId: number | null) => {
    if (sensorUniqueId !== null) {
      assignSensorRequest.mutate({
        uniqueId: sensorUniqueId,
        payload: { contractorId },
      })
    }
  }

  const handleOpenMenu = (
    event: React.MouseEvent<HTMLElement>,
    row: Sensor
  ) => {
    setCurrentRow(row)
    setMenuAnchorEl(event.currentTarget)
  }

  const handleCloseMenu = () => {
    setCurrentRow(null)
    setMenuAnchorEl(null)
  }

  const columns: GridColDef<Sensor>[] = [
    SensorColumnsDataGrid.Id,
    SensorColumnsDataGrid.Model,
    SensorColumnsDataGrid.IMEI,
    SensorColumnsDataGrid.DevEUI,
    SensorColumnsDataGrid.LastPing,
    SensorColumnsDataGrid.BatteryLevel,
    SensorColumnsDataGrid.DateAdded,
    SensorColumnsDataGrid.Description,
    {
      field: "actions",
      headerName: "",
      type: "actions",
      width: 30,
      getActions: ({ row }) => [
        <GridActionsCellItem
          icon={<MoreVertIcon />}
          label="Actions"
          className="textPrimary"
          onClick={(e: any) => handleOpenMenu(e, row)}
          color="inherit"
        />,
      ],
    },
  ]

  const assignedState = useMemo(() => states.find(
    (state) => state.name === SensorStateList.Assigned
  ), [states])
  const archivedState = useMemo(() => states.find(
    (state) => state.name === SensorStateList.Archived
  ), [states])

  return (
    <>
      {viewType === ViewTypes.List ? (
        <>
          <CommonSensorList
            columns={columns}
            count={count}
            data={data}
            isLoading={isLoading}
            filters={
              <SensorListFilters
                activeFilters={[
                  SensorFilters.LowBattery,
                  SensorFilters.WeakSignal,
                  SensorFilters.LastPingMoreThanHour,
                  SensorFilters.SyncPeriod,
                  SensorFilters.ReadingsAge,
                  SensorFilters.CurrentSensorConfiguration,
                  SensorFilters.BleStatus,
                ]}
              />
            }
            buttons={[SensorButtons.DownloadLabels]}
            checkboxSelection
          />
          <Menu
            anchorEl={menuAnchorEl}
            open={!!menuAnchorEl}
            onClose={handleCloseMenu}
          >
            <MenuItem
              disabled={!assignedState}
              onClick={() => {
                if (assignedState) {
                  setSensorUniqueId(currentRow?.uniqueId as string)
                  openDialog()
                  handleCloseMenu()
                }
              }}
            >
              Assign
            </MenuItem>
            <MenuItem
              disabled={isLoadingChangingSensorState}
              onClick={openSensorArchivingDialog}
            >
              Archive
            </MenuItem>
          </Menu>
          <ArchiveSensorModal
            sensorDeviceId={currentRow?.deviceId as string}
            isLoading={isLoadingChangingSensorState}
            onCancel={() => {
              closeSensorArchivingDialog()
              handleCloseMenu()
            }}
            onArchive={() => {
              updateSensorState({
                uniqueId: currentRow?.uniqueId as string,
                state: archivedState as SensorState,
                onSuccess: () => {
                  refetchSensorList()
                  handleCloseMenu()
                },
              })
            }}
          />
        </>
      ) : (
        <SensorsMapView
          data={data}
          isLoading={isLoading}
          filters={
            <ContractorsDataLoader>
              <SensorMapFilters
                activeFilters={[
                  SensorFilters.Contractor,
                  SensorFilters.LowBattery,
                  SensorFilters.WeakSignal,
                  SensorFilters.LastPingMoreThanHour,
                  SensorFilters.SyncPeriod,
                  SensorFilters.ReadingsAge,
                  SensorFilters.LocationType,
                ]}
              />
            </ContractorsDataLoader>
          }
        />
      )}
      <StatefulDialog
        name={DialogNames.AssignSensorToContractor}
        wrapContent={false}
      >
        <AssignSensorToContractor onSubmit={assignSensor} />
      </StatefulDialog>
    </>
  )
}

import React from 'react'

export const ArrowLeft = () => {
  return (
    <svg
      width="22"
      height="14"
      viewBox="0 0 22 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.2782 6H20.2782C20.8305 6 21.2782 6.44772 21.2782 7C21.2782 7.55228 20.8305 8 20.2782 8H2.2782V6Z"
        fill="currentColor"
      />
      <path
        d="M7.7782 1.22182L2.00002 7L7.7782 12.7782"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  )
}

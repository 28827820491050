import React, { FC } from 'react'
import ReactApexChart from 'react-apexcharts'
import { ApexOptions } from 'apexcharts'

interface ChartProps {
  options: ApexOptions;
  data: ApexOptions['series'];
}

export const Chart: FC<ChartProps> = ({ options, data }) => {
  return (
    <ReactApexChart options={options} series={data} type={options.chart?.type || "bar"} height="100%" />
  )
}

import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { Entities } from 'src/enums'
import {
  SensorBackupUplinkItem,
} from 'src/api'
import { APIList } from 'src/interfaces'
import { useApiInstance } from './useApiInstance'

interface RequestParams {
  params: {
    uniqueId: string;
    date?: string;
  }
  options?: Omit<
    UseQueryOptions<APIList<SensorBackupUplinkItem>, Error>,
    'queryKey' | 'queryFn'
  >;
}

export const useSensorBackupUplinks = ({
  params,
  options,
}: RequestParams) => {
  const api = useApiInstance()
  const { uniqueId, date } = params
  return useQuery<APIList<SensorBackupUplinkItem>, Error>(
    [Entities.Sensors, `sensor-backup-uplinks-${uniqueId}`, date],
    async () => {
      const { data } = await api.v1AdminSensorsUniqueIdBackupUplinksGet(uniqueId, date)
      return data as APIList<SensorBackupUplinkItem>
    },
    { ...options },
  )
}

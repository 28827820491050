import { Card, Grid } from "@mui/material"
import { Box } from "@mui/system"
import { FC } from "react"
import {
  BatteryLevels,
  MapLegendTypes,
  SearchParamsKeys,
  SignalStrengths,
} from "../../../enums"
import { useQueryParams } from "../../../hooks/ui"

interface LegendItem<T> {
  value: T;
  color: string;
  text: string;
}

export const getBatteryLevelColor = (value: BatteryLevels) => {
  switch (value) {
  case BatteryLevels.Low:
    return "#cccccc"
  case BatteryLevels.Good:
    return "#333333"
  }
}

const getBatteryLevelText = (value: BatteryLevels) => {
  switch (value) {
  case BatteryLevels.Low:
    return "Low"
  case BatteryLevels.Good:
    return "Good"
  }
}

export const getSignalStrengthColor = (value: SignalStrengths) => {
  switch (value) {
  case SignalStrengths.Good:
    return "#009700"
  case SignalStrengths.Fair:
    return "#f0ec00"
  case SignalStrengths.Weak:
    return "#f00"
  }
}

const getSignalStrengthText = (value: SignalStrengths) => {
  switch (value) {
  case SignalStrengths.Good:
    return "Good"
  case SignalStrengths.Fair:
    return "Fair"
  case SignalStrengths.Weak:
    return "Weak"
  }
}

const batteryLevelLegendItems: LegendItem<BatteryLevels>[] = Object.values(
  BatteryLevels
).map((value) => ({
  value,
  color: getBatteryLevelColor(value),
  text: getBatteryLevelText(value),
}))

const signalStrengthLegendItems: LegendItem<SignalStrengths>[] = Object.values(
  SignalStrengths
).map((value) => ({
  value,
  color: getSignalStrengthColor(value),
  text: getSignalStrengthText(value),
}))

interface SensorsMapLegendItemProps {
  color: string;
  text: string;
}

const SensorsMapLegendItem: FC<SensorsMapLegendItemProps> = ({
  color,
  text,
}) => (
  <Grid container alignItems="center">
    <Box
      sx={{
        width: 40,
        height: 20,
        backgroundColor: color,
        marginRight: 1,
      }}
    />
    - {text}
  </Grid>
)

interface SensorsMapLegendCardProps {
  title: string;
  items: SensorsMapLegendItemProps[];
}

const SensorsMapLegendCard: FC<SensorsMapLegendCardProps> = ({
  title,
  items,
}) => (
  <Card variant="outlined">
    <Grid container flexDirection="column" spacing={1} p={1}>
      <Grid item>{title}</Grid>
      {items.map(({ color, text }) => (
        <Grid key={text} item>
          <SensorsMapLegendItem color={color} text={text} />
        </Grid>
      ))}
    </Grid>
  </Card>
)

export const SensorsMapLegend = () => {
  const [queryParams] = useQueryParams()
  const legendType =
    queryParams[SearchParamsKeys.MapLegendType] || MapLegendTypes.BatteryLevel

  if (legendType === MapLegendTypes.BatteryLevel) {
    return (
      <SensorsMapLegendCard
        title="Battery level:"
        items={batteryLevelLegendItems}
      />
    )
  }

  if (legendType === MapLegendTypes.SignalStrength) {
    return (
      <SensorsMapLegendCard
        title="Signal strength:"
        items={signalStrengthLegendItems}
      />
    )
  }

  return null
}

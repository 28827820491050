import { FC, useState, PropsWithChildren } from 'react'
import { AuthorizationContext } from 'src/contexts'
import { LocalStorageKey } from 'src/enums'

export const AuthorizationProvider: FC<PropsWithChildren> = ({ children }) => {
  const initial = localStorage.getItem(LocalStorageKey.Auth)
  const [state, setState] = useState<string | null>(initial)
  const updateState = (newState: string | null) => {
    setState(() => {
      newState === null
        ? localStorage.removeItem(LocalStorageKey.Auth)
        : localStorage.setItem(LocalStorageKey.Auth, `${newState}`)
      return newState
    })
  }
  return (
    <AuthorizationContext.Provider value={[state, updateState]}>
      {children}
    </AuthorizationContext.Provider>
  )
}

import React, { FC, PropsWithChildren } from "react"
import { Typography, Grid, Box } from "@mui/material"

interface EntityHeaderProps {
  title: string;
}

export const ListHeader: FC<PropsWithChildren<EntityHeaderProps>> = ({ title, children }) => {
  return (
    <Box flexGrow={0}>
      <Grid item container justifyContent="space-between" alignItems="center" sx={{ mb: 2 }} xs>
        <Grid item>
          <Grid item>
            <Typography variant="h4">{title}</Typography>
          </Grid>
        </Grid>
        <Grid item>{children}</Grid>
      </Grid>
    </Box>
  )
}

import React, { FC } from "react"
import { List, ListItem, ListItemText, ListItemIcon } from "@mui/material"
import { Circle } from "@mui/icons-material"

interface TipListViewProps {
  tips: { content: string }[];
}

export const TipListView: FC<TipListViewProps> = ({ tips }) => {
  return (
    <List disablePadding={true}>
      {tips.map(({ content }, idx) => (
        <ListItem key={idx} sx={{ alignItems: "baseline" }}>
          <ListItemIcon
            sx={{ minWidth: 6, width: 6, height: 10, pr: "0.875rem" }}
          >
            <Circle
              sx={{ width: "inherit", height: "inherit", color: "grey.400" }}
            />
          </ListItemIcon>
          <ListItemText primary={content} />
        </ListItem>
      ))}
    </List>
  )
}

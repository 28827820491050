import React, { FC, useCallback } from 'react'
import { Snackbar as MSnackbar, Alert, Slide, SlideProps } from '@mui/material'
import { useCurrentPopupMessage } from 'src/hooks/ui'
import { config } from 'src/config'

const SlideTransition = (props: SlideProps) => (
  <Slide {...props} direction="left" />
)

export const PopupMessage: FC = () => {
  const { message, onClose } = useCurrentPopupMessage()
  const handleClose = useCallback(
    (event: any, reason?: string) => {
      if (reason === 'clickaway') return
      onClose()
    },
    [onClose]
  )

  return (
    <MSnackbar
      open={!!message}
      autoHideDuration={config.popupMessageTimeout}
      onClose={handleClose}
      TransitionComponent={SlideTransition}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
    >
      <Alert
        onClose={handleClose}
        severity={message?.type}
        sx={{ width: '100%' }}
      >
        {message?.text}
      </Alert>
    </MSnackbar>
  )
}

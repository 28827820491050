import React, { useEffect } from "react"
import { LicenseInfo } from "@mui/x-license-pro"
import { PopupMessage } from "src/components"
import Routes from "./routes"
import { config } from "./config"
import { Bootstrap } from './Bootstrap'

function App() {
  useEffect(() => {
    LicenseInfo.setLicenseKey(config.muiLicenseKey)
  }, [])

  return (
    <>
      <Bootstrap>
        <Routes />
        <PopupMessage />
      </Bootstrap>
    </>
  )
}

export default App

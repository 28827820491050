import { useMutation, UseMutationOptions } from '@tanstack/react-query'
import { useAuthorized } from 'src/hooks/ui'
import { useApiInstance } from './useApiInstance'

interface SignInSendCodeParams {
  to: string;
  code: string;
  termsAndPrivacyAccepted?: boolean;
}

interface SignInSendCodeRequestParams {
  options?: Omit<
    UseMutationOptions<unknown, unknown, SignInSendCodeParams>,
    'queryKey' | 'queryFn'
  >;
}

export const useSignInSendCodeRequest = ({
  options,
}: SignInSendCodeRequestParams = {}) => {
  const api = useApiInstance()
  const [, setAuthorized] = useAuthorized()
  return useMutation(
    ({
      to,
      code,
      termsAndPrivacyAccepted,
    }: SignInSendCodeParams) => api.v1UsersSignInPost({ to, code, termsAndPrivacyAccepted }),
    {
      ...options,
      onSuccess: (...rest) => {
        const [{ headers }] = rest
        const token = headers['x-auth-token'] || null
        setAuthorized(token)
        if (options?.onSuccess) options.onSuccess(...rest)
      },
    },
  )
}

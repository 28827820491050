import React from 'react'
import { AlertColor } from '@mui/material'
import { SensorConfiguration } from './api'

export interface List<T> {
  total: number;
  data: T[];
}

export interface APIList<T> {
  count: number;
  rows: T[];
}

export interface PopupMessage {
  text: string | React.ReactNode;
  type: AlertColor;
}

export interface Sensor {
  id: number;
  name: string;
  model: string;
  added: Date | null;
  lastHeard: Date | null;
  batteryLevel: number | null;
  timeOnProject: Date;
  contractor: Contractor | null;
}

export interface Contractor {
  id: number;
  name: string;
  city: string;
  address: string;
  phone: string;
  email: string;
  activeProjects: number;
  allProjects: number;
  activeSensors: number;
  allSensors: number;
  activeUsers: number;
  allUsers: number;
}

export interface User {
  id: number;
  name: string;
  contractorId: number | null;
  contractor: Contractor | null;
  organization: string;
  email: string;
  phone: string;
  currentProject: string;
  status: string | null;
  lastActive: Date | null;
  isManager: boolean;
  userType: string;
}

export interface Tip {
  id: number;
  type: string;
  value: string;
}

export interface Product {
  id: number;
  manufacturer: Manufacturer;
  name: string;
  type: string;
  packageType: string;
  shelfLife: number;
  lowTemp: number | null;
  hightTemp: number | null;
  lowHumidity: number | null;
  hightHumidity: number | null;
  tips: Tip[];
}

export interface Manufacturer {
  id: number;
  name: string;
  address1: string | null;
  phone: string | null;
  email: string | null;
  productsAmount: number | null;
}

export interface SensorConfigurationEditState extends SensorConfiguration {
  firmware?: string;
}

export enum BleManageState {
  ON = 'on',
  OFF = 'off',
}

export type ReadingType = 'temperature' | 'humidity' | 'battery' | 'signalStrength' | 'light' | 'pressure' | 'cellularLocation' | 'gpsLocation'

export interface MobileSettings {
  wagnerProbeSettings: {
    signalStrengthThreshold: number | string
    connectivitySignalLevel: number | string
    connectivityCheckPacketFreshnessThreshold: number | string
  }
}

import { useMutation, UseMutationOptions } from '@tanstack/react-query'
import { Entities } from 'src/enums'
import { useApiInstance } from './useApiInstance'

interface RequestParams {
  options?: Omit<
    UseMutationOptions<boolean, Error, number>,
    'mutationKey' | 'mutationFn'
  >;
}

export const useDeleteManufacturerRequest = ({ options }: RequestParams = {}) => {
  const api = useApiInstance()

  return useMutation<boolean, Error, number>(
    [Entities.Manufacturers, 'delete'],
    async (id: number) => {
      const { data }: { data: unknown } = await api.v1AdminManufacturersManufacturerIdDelete(id)
      return data as boolean
    },
    options,
  )
}

import { useContext } from 'react'
import { DialogStateContext } from 'src/contexts'
import { DialogNames } from 'src/enums'

export const useDialog = (
  name: DialogNames | string,
): {
  open: boolean;
  openDialog: () => void;
  closeDialog: (cb?: () => void
) => Promise<void>;
} => {
  const [commonState, handleChange] = useContext(DialogStateContext)
  const open = commonState[name] || false
  const openDialog = () => handleChange(name, true)
  const closeDialog = async (cb?: () => void) => {
    handleChange(name, false)
    if (cb) setTimeout(cb, 200)
  }
  return {
    open,
    openDialog,
    closeDialog,
  }
}

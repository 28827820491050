import React, { FC, useCallback } from 'react'
import { WagnerProbe } from 'src/api'
import { Paper } from '@mui/material'
import {
  DataGridPremium,
} from '@mui/x-data-grid-premium'
import { useSectionRapidRHColumns } from './useSectionRapidRHColumns'

export interface SectionRapidRHRow {
  id: string;
  deviceId: string;
  event: string;
  time: string;
}

interface Event {
  name: string;
  field: keyof WagnerProbe;
}

interface SectionRapidRHInfoProps {
  rapidRHProbes: WagnerProbe[];
}

export const SectionRapidRHInfo: FC<SectionRapidRHInfoProps> = ({ rapidRHProbes }) => {
  const columns = useSectionRapidRHColumns()
  const rows: SectionRapidRHRow[] = useCallback(() => {
    const events: Event[] = [
      { name: 'Connected At', field: 'createdAt' },
      { name: 'Started Sending Data At', field: 'firstReadingAt' },
      { name: 'Finished Sending Data At', field: 'lastReadingAt' },
    ]
    const _rows: SectionRapidRHRow[] = []
    events.forEach((event) => {
      rapidRHProbes.forEach((rapidRHProbe) => {
        _rows.push({
          id: `${event.field}_${rapidRHProbe.id}`,
          event: event.name,
          deviceId: rapidRHProbe.probeId || '',
          time: (rapidRHProbe[event.field] || '') as string
        })
      })
    })
    return _rows
  }, [rapidRHProbes])()
  return (
    <Paper sx={{ flexGrow: 1 }}>
      <DataGridPremium<SectionRapidRHRow>
        autoHeight
        rows={rows}
        columns={columns}
        rowThreshold={2}
        columnThreshold={2}
        disableRowSelectionOnClick
        localeText={{
          columnMenuSortAsc: "Sort A-Z",
          columnMenuSortDesc: "Sort Z-A",
        }}
        sx={(theme) => ({
          border: "none",
          "& .MuiDataGrid-columnHeaderTitleContainerContent": {
            paddingLeft: "5px",
            overflow: "visible",
          },
          "& .MuiDataGrid-columnHeaderTitle": {
            whiteSpace: "normal",
            lineHeight: 1,
            fontSize: "13px",
            fontWeight: 400,
            color: "rgba(0, 0, 0, 0.6)",
            overflow: "visible",
          },
        })}
      />
    </Paper>
  )
}

import { useMutation, UseMutationOptions } from '@tanstack/react-query'
import { Entities } from 'src/enums'
import { CreateManufacturerAnnouncementPayload, ManufacturerAnnouncement } from 'src/api'
import { useApiInstance } from './useApiInstance'

interface RequestParams {
  options?: Omit<UseMutationOptions<unknown, Error, CreateManufacturerAnnouncementPayload, ManufacturerAnnouncement>, 'mutationKey' | 'mutationFn'>
}

export const useNewManufacturerAnnouncementRequest = ({
  options = {},
}: RequestParams = {}) => {
  const api = useApiInstance()

  return useMutation<unknown, Error, CreateManufacturerAnnouncementPayload, ManufacturerAnnouncement>(
    [Entities.ManufacturerAnnouncement, 'create'],
    async (payload: CreateManufacturerAnnouncementPayload) => {
      const { data } = await api.v1AdminManufacturersAnnouncementsPost(payload)
      return data
    },
    { ...options },
  )
}

import { Engineering } from "@mui/icons-material"
import { Grid } from "@mui/material"
import {
  GridColDef as GridEnrichedColDef,
} from '@mui/x-data-grid-premium'
import { format } from "date-fns"
import distanceInWords from "date-fns/formatDistance"
import { User } from "src/api"
import { ClosedEye, Crown } from "src/components/icons"
import { InnerLink } from "src/components/ui"

export namespace UserColumnsDataGrid {

  export const NameWithIcon: (
    pmRoleId: number | undefined,
    ownerRoleId: number | undefined
  ) => GridEnrichedColDef<User> =
    (pmRoleId, ownerRoleId) => ({
      field: "name",
      headerName: "Name",
      type: "string",
      minWidth: 250,
      editable: false,
      cellProps: { align: "left" },
      valueGetter: (params) => params.row?.name || null,
      renderCell: ({ row: { id, name, roleId, isArchived } }) => {
        const isPM = pmRoleId === roleId
        const isOwner = ownerRoleId === roleId
        return (
          <Grid container alignItems="center" spacing={1}>
            <Grid item>
              <InnerLink to={`/users/${id}`}>{name}</InnerLink>
            </Grid>
            {isArchived && (
              <Grid container item title="Archived User" xs={2}>
                <ClosedEye />
              </Grid>
            )}
            {isPM && (
              <Grid container item title="Project Manager" xs={2}>
                <Engineering />
              </Grid>
            )}
            {isOwner && (
              <Grid container item title="Owner" xs={2}>
                <Crown />
              </Grid>
            )}
          </Grid>
        )
      },
    })

  export const Email: GridEnrichedColDef<User> =
  {
    field: "email",
    headerName: "Email",
    type: "string",
    minWidth: 250,
    editable: false,
  }
  export const Phone: GridEnrichedColDef<User> =
  {
    field: "phone",
    headerName: "Phone",
    type: "string",
    minWidth: 180,
    editable: false,
  }
  export const LastActive: GridEnrichedColDef<User> =
  {
    field: "lastActive",
    headerName: "Last Active",
    type: "string",
    minWidth: 180,
    editable: false,
    valueFormatter: ({ value }) => value ? distanceInWords(new Date(value), new Date()) : '-',
  }
  export const ContractorName: GridEnrichedColDef<User> =
  {
    field: "contractor.name",
    headerName: "Contractor",
    type: "string",
    minWidth: 250,
    editable: false,
    valueGetter: ({row}) => row.contractor?.name || null,
    renderCell: ({ row }) => {
      if (row?.contractor) {
        const contractorId = row?.contractor.id
        const contractorName = row?.contractor.name
        return <InnerLink to={`/contractors/${contractorId}`}>{contractorName}</InnerLink>
      }
      return null

    }
  }
  export const LastAppVersion: GridEnrichedColDef<User> =
  {
    field: "lastVersionApp",
    headerName: "Mobile App Version",
    type: "string",
    minWidth: 180,
    editable: false,
  }
  export const CreatedAt: GridEnrichedColDef<User> = {
    field: "createdAt",
    headerName: "Created At",
    type: "string",
    minWidth: 200,
    editable: false,
    valueFormatter: ({ value }) => value ? format(new Date(value), 'PPpp') : '-',
  }
  export const UserRole: GridEnrichedColDef<User> = {
    field: "role.displayName",
    headerName: "Role",
    type: "string",
    minWidth: 150,
    editable: false,
    valueGetter: ({row}) => row.role?.displayName || null,
  }
  export const UserStatus: GridEnrichedColDef<User> = {
    field: "isArchived",
    headerName: "Status",
    type: "string",
    minWidth: 150,
    editable: false,
    valueFormatter: ({ value }) => value === true ? "Archived" : "Active"
  }
};

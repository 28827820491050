import React, { FC, ReactElement, useContext, useEffect, useRef } from "react"
import { render } from "react-dom"
import { styled } from "@mui/system"
import { Grid } from "@mui/material"
import { Map as MMap, Marker, Popup } from "mapbox-gl"
import { useQueryParamsState } from "src/hooks/ui"
import { Sensor, SensorCurrentData } from "src/api"
import {
  BatteryLevels,
  LocationTypes,
  MapLegendTypes,
  SearchParamsKeys,
  SignalStrengths,
} from "src/enums"
import { HiddenMapContext } from "src/contexts"
import { SensorMarker } from "./sensorMarker"
import {
  getBatteryLevelColor,
  getSignalStrengthColor,
  SensorsMapLegend,
} from "./sensorsMapLegend"

const MapWrapper = styled("div")`
  width: 100%;
  height: max(calc(100vh - 300px), 500px);
  position: relative;

  & > .mapboxgl-map {
    height: inherit;
    width: inherit;
  }
`

const LocationTypeMapping: Record<
  LocationTypes,
  [keyof SensorCurrentData, keyof SensorCurrentData]
> = {
  [LocationTypes.Cellural]: ["longitudeCellural", "latitudeCellural"],
  [LocationTypes.GPS]: ["longitudeGps", "latitudeGps"],
}

const getMinMaxLocation = (
  key: keyof SensorCurrentData,
  sensorList: Sensor[]
) => {
  const [sensor = {} as Sensor] = sensorList
  const { currentData = { [key]: 0 } } = sensor
  const initialMin = currentData[key] as number
  const initialMax = currentData[key] as number
  return sensorList.reduce(
    ([min, max], _sensor) => {
      return [
        Math.min(min, (_sensor.currentData as SensorCurrentData)[key] as number),
        Math.max(max, (_sensor.currentData as SensorCurrentData)[key] as number),
      ]
    },
    [initialMin, initialMax]
  )
}

interface SensorsMapProps {
  isLoading: boolean;
  sensors: Sensor[];
}

const getMarkerColor = (
  currentData: SensorCurrentData | undefined,
  legendType: MapLegendTypes
) => {
  let color = "#cccccc"

  if (
    legendType === MapLegendTypes.SignalStrength &&
    currentData?.signalStrength
  ) {
    if (currentData?.signalStrength <= -100) {
      return getSignalStrengthColor(SignalStrengths.Weak)
    }
    if (currentData?.signalStrength <= -85) {
      return getSignalStrengthColor(SignalStrengths.Fair)
    }
    if (currentData?.signalStrength > -85) {
      return getSignalStrengthColor(SignalStrengths.Good)
    }
  }

  if (legendType === MapLegendTypes.BatteryLevel && currentData?.batteryLevel) {
    color = getBatteryLevelColor(
      currentData.batteryLevel >= 25 ? BatteryLevels.Good : BatteryLevels.Low
    )
  }

  return color
}

const SensorsMap: FC<SensorsMapProps> = ({ sensors, isLoading }) => {
  const mapChildContainer = useRef<HTMLElement | null>(null)
  const { map, mapContainer } = useContext(HiddenMapContext)
  const state = useQueryParamsState()
  const locationType =
    (state[SearchParamsKeys.LocationType] as LocationTypes) ||
    LocationTypes.Cellural
  const legendType =
    (state[SearchParamsKeys.MapLegendType] as MapLegendTypes) ||
    MapLegendTypes.BatteryLevel

  useEffect(() => {
    const currentList: Marker[] = []

    const [latitude, longitude] = LocationTypeMapping[locationType]

    const sensorList = sensors.filter(({ currentData }) => {
      return currentData && currentData[latitude] && currentData[longitude]
    })

    const [minLatitude, maxLatitude] = getMinMaxLocation(latitude, sensorList)
    const [minLongitude, maxLongitude] = getMinMaxLocation(
      longitude,
      sensorList
    )

    const initialLng = (minLatitude + maxLatitude) / 2
    const initialLat = (minLongitude + maxLongitude) / 2
    sensorList.forEach((sensor) => {
      const { currentData } = sensor
      const [_latitude, _longitude] = LocationTypeMapping[locationType]

      const marker = new Marker({
        color: getMarkerColor(currentData, legendType),
      }).setLngLat([
        (currentData as SensorCurrentData)[_latitude] as number,
        (currentData as SensorCurrentData)[_longitude] as number,
      ])

      const tempWrapper = document.createElement("div")

      render(<SensorMarker sensor={sensor} />, tempWrapper, () => {
        marker?.setPopup(new Popup().setHTML(tempWrapper.innerHTML))
      })

      marker.addTo(map.current as MMap)
      currentList.push(marker)
    })

    if (map.current) {
      if (currentList.length === 0 && !isLoading) {
        (map.current as MMap).setCenter([-100, 45]);
        (map.current as MMap).setZoom(2)
      } else if (currentList.length > 0) {
        (map.current as MMap).setCenter([initialLng, initialLat]);
        (map.current as MMap).fitBounds(
          [minLatitude, minLongitude, maxLatitude, maxLongitude],
          {
            animate: false,
            padding: 100,
            maxZoom: 16,
          }
        )
      }
    }

    const childContainer = mapChildContainer.current as HTMLElement
    const rootContainer = mapContainer.current as HTMLElement
    const rootContainerParent = rootContainer.parentElement

    if (childContainer && rootContainer) {
      childContainer.insertAdjacentElement("beforeend", rootContainer)
      map.current?.resize()
    }
    return () => {
      while (currentList.length !== 0) {
        const marker = currentList.pop();
        (marker as Marker).remove()
      }

      if (rootContainer && rootContainerParent) {
        rootContainerParent.insertAdjacentElement("beforeend", rootContainer)
      }
    }
    // eslint-disable-next-line
  }, [sensors]);

  return <MapWrapper ref={mapChildContainer as any} />
}

interface SensorsMapViewProps {
  data?: Sensor[];
  isLoading: boolean;
  filters?: ReactElement;
}

export const SensorsMapView: FC<SensorsMapViewProps> = ({
  filters = null,
  isLoading,
  data = [],
}) => {
  return (
    <Grid container alignItems="stretch" flexWrap="nowrap" spacing={3}>
      <Grid item flexGrow={1}>
        <SensorsMap sensors={data} isLoading={isLoading} />
      </Grid>
      <Grid
        item
        container
        width={300}
        flexDirection="column"
        justifyContent="space-between"
        sx={{ overflow: "hidden" }}
      >
        <Grid item>{filters}</Grid>
        <Grid item mt={3}>
          <SensorsMapLegend />
        </Grid>
      </Grid>
    </Grid>
  )
}

import React, { FC } from 'react'
import { Grid } from '@mui/material'
import { Table, DynamicColumn } from 'src/components/ui'
import { ProductTips } from 'src/components/sections'
import { Product, ProductTipType } from 'src/api'
import { ProductColumns } from 'src/components/columns/product'

const columns: DynamicColumn<Product>[] = [
  ProductColumns.ProductTypeName,
  ProductColumns.ProductPackageTypeName,
  ProductColumns.ShelfLife,
  ProductColumns.LowTempF,
  ProductColumns.HighTempF,
  ProductColumns.LowHumidity,
  ProductColumns.HighHumidity,
]

interface ProductInfoProps {
  product: Product;
  productTipTypes: ProductTipType[];
}

export const ProductInfo: FC<ProductInfoProps> = (props) => {
  const { product, productTipTypes } = props

  const commonTip = productTipTypes.find((type) => type.name === 'common')
  const safetyTip = productTipTypes.find((type) => type.name === 'safety')

  const commonTips =
    product.tips?.filter(({ typeId }) => typeId === commonTip?.id) || []
  const safetyTips =
    product.tips?.filter(({ typeId }) => typeId === safetyTip?.id) || []

  return (
    <Grid container spacing={4}>
      <Grid item container>
        <Table
          columns={columns}
          data={[product]}
          showFooter={false}
          sx={{ width: '100%' }}
        />
      </Grid>
      <Grid item container xs={8} spacing={4}>
        {commonTips.length > 0 && (
          <Grid item xs>
            <ProductTips title="Install Tips" tips={commonTips} />
          </Grid>
        )}
        {safetyTips.length > 0 && (
          <Grid item xs>
            <ProductTips title="Safety Tips" tips={safetyTips} />
          </Grid>
        )}
      </Grid>
    </Grid>
  )
}

import { useMutation, UseMutationOptions } from '@tanstack/react-query'
import { Report } from 'src/api'
import { useApiInstance } from './useApiInstance'

interface RegenerateReportLinkRequestParams {
  options?: Omit<
    UseMutationOptions<unknown, Error | null, number, Report>,
    'mutationKey' | 'mutationFn'
  >;
}

export const useRegenerateReportLinkRequest = ({
  options,
}: RegenerateReportLinkRequestParams = {}) => {
  const api = useApiInstance()
  return useMutation(
    (reportId: number) => api.v1AdminReportsReportIdRegenerateLinkPost(reportId),
    options,
  )
}

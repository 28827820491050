import { useMutation, UseMutationOptions } from '@tanstack/react-query'
import { Entities } from 'src/enums'
import { Manufacturer, UploadPhotoResult } from 'src/api'
import { ManufacturerEditState } from 'src/components/forms/manufacturer/edit'
import axios, { AxiosResponseTransformer } from 'axios'
import { ValuesToResize } from 'src/config'
import { useApiInstance } from './useApiInstance'
import { useSignInRedirect } from '../ui'

interface RequestParams {
  id: number;
  options?: Omit<
    UseMutationOptions<Manufacturer, Error, RequestUpload>,
    'mutationKey' | 'mutationFn'
  >;
}

interface RequestUpload extends ManufacturerEditState {
  photos?: Array<any> | null
}

export const useEditManufacturerRequest = ({ id, options }: RequestParams) => {
  const api = useApiInstance()
  const redirect = useSignInRedirect()

  return useMutation<Manufacturer, Error, RequestUpload>(
    [Entities.Manufacturers, 'edit', id],
    async (payload: RequestUpload) => {
      const { photos, ...editPayload } = payload
      if (photos !== undefined && photos !== null) {
        let photoResult: Array<UploadPhotoResult> = []
        try {
          await api.v1MediaPhotoPut(photos, ValuesToResize.manufacturer, {
            transformResponse: [
              ...(axios.defaults.transformResponse as AxiosResponseTransformer[]),
              ({ error, result, statusCode }) => {
                if (error) {
                  if (statusCode === 401) redirect()
                  throw new Error(error)
                }
                photoResult = result
              },
            ],
          })
        } catch (e) {
          console.log(e)
        }

        if (photoResult !== undefined && photoResult?.length > 0) {
          const uploadedPhoto = photoResult[0].original
          editPayload.photo = uploadedPhoto
        }

        const { data } = await api.v1AdminManufacturersManufacturerIdPut(
          id,
          editPayload,
        )
        return data as Manufacturer
      }

      const { data } = await api.v1AdminManufacturersManufacturerIdPut(
        id,
        { ...editPayload, photo: photos === null ? { bucket: '', fileName: '' } : undefined },
      )
      return data as Manufacturer
    },
    options,
  )
}

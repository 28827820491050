import React, { FC } from 'react'
import { Grid, Typography } from '@mui/material'
import { useBuildingTypeListRequest } from 'src/hooks/api'
import { DataLoad } from 'src/components/ui'
import { NewBuildingType } from './newBuildingType'
import { BuildingTypeList } from './buildingTypeList'

export const LoadableBuildingTypeList: FC = () => {
  const { data, error, isInitialLoading, isError, refetch } =
    useBuildingTypeListRequest({
      params: { limit: 9999 },
    })

  return (
    <Grid container direction="column" spacing={4}>
      <Grid item container direction="column">
        <Grid item>
          <Typography variant="h6" sx={{ pt: 2 }}>
            Create New Building Type
          </Typography>
        </Grid>
        <Grid item>
          <NewBuildingType onCreate={refetch} />
        </Grid>
      </Grid>
      <Grid item>
        <Grid item sx={{ pb: 2 }}>
          <Grid item>
            <Typography variant="h6">Building Type List</Typography>
          </Grid>
          <Grid>
            <Typography color="gray">
              Forbidden to edit or delete building type if it used on any
              project
            </Typography>
          </Grid>
        </Grid>
        <Grid item>
          <DataLoad
            isLoading={isInitialLoading}
            isError={isError}
            errorMessage={error?.message}
          >
            <BuildingTypeList data={data?.rows || []} onChange={refetch} />
          </DataLoad>
        </Grid>
      </Grid>
    </Grid>
  )
}

import { FC } from 'react'
import { Grid, Typography } from '@mui/material'
import { Table, DynamicColumn } from 'src/components/ui'
import { ProjectBuildingTypeItemDashboardStatistics } from 'src/api'
import { ProjectBuildingTypeDashboardColumns } from 'src/components/columns/dashboard'

interface ProjectBuildingTypeDashboardProps {
  projectBuildingTypeStatistics: ProjectBuildingTypeItemDashboardStatistics[];
  projectsNumber: number;
}

const columns: DynamicColumn<{ name: string, percent: number }>[] = [
  ProjectBuildingTypeDashboardColumns.ProjectBuildingType,
  ProjectBuildingTypeDashboardColumns.ProjectPercent,
]

export const ProjectBuildingTypeDashboard: FC<ProjectBuildingTypeDashboardProps> = (props) => {
  const { projectBuildingTypeStatistics, projectsNumber } = props
  const data = projectBuildingTypeStatistics.map((projectBuildingType) => {
    const percent = Math.round((projectBuildingType.projectsNumber * 100) / projectsNumber)
    return { name: projectBuildingType.name, percent }
  })
  return (
    <Grid container spacing={2}>
      <Grid item>
        <Typography variant="h5">Building Types</Typography>
      </Grid>
      <Grid item container>
        <Table
          columns={columns}
          data={data}
          showFooter={false}
          sx={{ width: '100%' }}
        />
      </Grid>
    </Grid>

  )
}

import React, { PropsWithChildren } from "react"
import { Grid } from "@mui/material"

interface SimpleTableCellProps {
  capitalize?: boolean;
}

export const SimpleTableCell: React.FC<PropsWithChildren<SimpleTableCellProps>> = ({
  capitalize,
  children,
}) => {
  return (
    <Grid
      container
      alignItems="center"
      sx={{
        textTransform: capitalize ? "capitalize" : "inherit",
      }}
    >
      {children}
    </Grid>
  )
}

import React, { FC } from 'react'
import { Grid } from '@mui/material'
import { useAddPopupMessage } from 'src/hooks/ui'
import { useEditConfigurationParamRequest } from 'src/hooks/api'
import { TypeNewForm } from 'src/components/forms'

interface EditableConfigurationParamProps {
  id: number;
  value: string;
  configParamName?: string | null;
  isRequiredField?: boolean;
  onChange: () => void;
  onCancel: () => void;
}

export const EditableConfigurationParam: FC<EditableConfigurationParamProps> = ({
  id,
  value,
  configParamName,
  isRequiredField,
  onChange,
  onCancel,
}) => {
  const addMessage = useAddPopupMessage()
  const editConfigurationParamRequest = useEditConfigurationParamRequest({ id })

  const update = ({ displayName }: { displayName: string }) => {
    editConfigurationParamRequest.mutate(
      { value: displayName },
      {
        onSuccess: onChange,
        onError: (error) => addMessage({ type: 'error', text: error.message }),
      }
    )
  }

  return (
    <>
      <Grid item flexGrow={1}>
        <TypeNewForm
          placeholder="Configuration value"
          label={configParamName}
          onSubmit={update}
          initialValues={{ displayName: value }}
          onCancel={onCancel}
          isRequiredField={isRequiredField}
          isLoading={editConfigurationParamRequest.isLoading}
          needToResetForm={false}
        />
      </Grid>
    </>
  )
}

import React, { FC } from 'react'
import { Table } from 'src/components'
import { EventType } from 'src/api'
import { ContractorColumns } from 'src/components/columns/eventTypes'

const getColumns = () => [
  ContractorColumns.Name,
  ContractorColumns.IsVisible
]

interface EventTypeListProps {
  data: EventType[];
  loading?: boolean;
  count: number;
  showFooter?: boolean;
}

export const EventTypeList: FC<EventTypeListProps> = ({
  data,
  loading,
  count,
  showFooter = true,
}) => {
  return (
    <Table
      columns={getColumns()}
      data={data}
      count={count}
      loading={loading}
      showFooter={showFooter}
    />
  )
}

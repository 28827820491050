import { useMutation, UseMutationOptions } from '@tanstack/react-query'
import { APIList } from 'src/interfaces'
import { Entities } from 'src/enums'
import { Product } from 'src/api'
import { useApiInstance } from './useApiInstance'
import {
  buildRequestParams,
  ObjectType,
  transformPage,
  transformPerPage,
  transformSearch,
} from './helpers'

interface RequestParams {
  params?: Partial<AdminProductsGetParams>;
  paramsTransform?: Array<(params: Record<string, any>) => Record<string, any>>;
  options?: Omit<
    UseMutationOptions<
      APIList<Product>,
      Error,
      Partial<AdminProductsGetParams>,
      APIList<Product>
    >,
    'mutationKey' | 'mutationFn'
  >;
}

interface AdminProductsGetParams {
  limit: number;
  offset: number;
  sort: string;
  manufacturerId: number;
  typeId: number;
  name: string;
  projectId: number;
  noShelfLife: boolean;
  noRanges: boolean;
  noTips: boolean;
  inUse: boolean;
}

const schema: ObjectType['schema'] = {
  limit: 'number',
  offset: 'number',
  sort: 'string',
  manufacturerId: 'number',
  typeId: 'number',
  name: 'string',
  projectId: 'number',
  noShelfLife: 'boolean',
  noRanges: 'boolean',
  noTips: 'boolean',
  inUse: 'boolean',
}

export const useFilteredProductListRequest = ({
  paramsTransform = [],
  options,
}: RequestParams = {}) => {
  const api = useApiInstance()

  return useMutation<
    APIList<Product>,
    Error,
    Partial<AdminProductsGetParams>,
    APIList<Product>
  >(
    [Entities.Products, 'filtered-list'],
    async (payload: Partial<AdminProductsGetParams>) => {
      const transformedParams = [
        transformPerPage,
        transformPage,
        transformSearch('name'),
        ...paramsTransform,
      ].reduce((param, transform) => transform(param), payload)

      const queryParams = buildRequestParams<AdminProductsGetParams>(
        transformedParams,
        schema,
      )

      const {
        limit,
        offset,
        sort,
        manufacturerId,
        typeId,
        name,
        projectId,
        noShelfLife,
        noRanges,
        noTips,
        inUse,
      } = queryParams
      const { data } = await api.v1AdminProductsGet(
        limit,
        offset,
        sort,
        manufacturerId,
        typeId,
        name,
        projectId,
        noShelfLife,
        noRanges,
        noTips,
        inUse,
      )
      return data as APIList<Product>
    },
    { ...options },
  )
}

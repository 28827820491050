import React, { FC, useState, Fragment } from 'react'
import {
  Grid,
  Divider,
} from '@mui/material'
import {
  useDeleteProjectTypeRequest,
} from 'src/hooks/api'
import { AdminProjectTypeList as ApiProjectTypeList } from 'src/api'
import { useAddPopupMessage, useDialog } from 'src/hooks/ui'
import { DialogNames } from 'src/enums'

import { InlineProjectType } from './inlineProjectType'
import { EditableProjectType } from './editableProjectType'
import { RemoveProjectTypeDialog } from './removeProjectTypeDialog'

interface ProjectTypeListProps {
  data: ApiProjectTypeList[];
  onChange: () => void;
}

export const ProjectTypeList: FC<ProjectTypeListProps> = ({ data, onChange }) => {
  const [editId, setEditId] = useState<number | null>(null)
  const [removeItem, setRemoveItem] = useState<{
    id: number;
    name: string;
  } | null>(null)
  const addMessage = useAddPopupMessage()
  const { openDialog, closeDialog } = useDialog(DialogNames.DeleteProjectType)

  const removeProjectTypeRequest = useDeleteProjectTypeRequest()

  const openRemoveDialog = (type: { id: number; name: string }) => {
    setRemoveItem(type)
    openDialog()
  }

  const closeRemoveDialog = () => {
    closeDialog(() => setRemoveItem(null))
  }

  const onRemove = () => {
    if (removeItem !== null) {
      removeProjectTypeRequest.mutate(
        { id: removeItem.id },
        {
          onSuccess: onChange,
          onError: (error) =>
            addMessage({ type: 'error', text: error.message }),
        }
      )
      closeRemoveDialog()
    }
  }

  const updateEditId = (id: number | null) => {
    setEditId(id)
  }

  const saveChanges = () => {
    updateEditId(null)
    onChange()
  }

  return (
    <>
      <Grid container direction="column" spacing={2}>
        {data.map(({ id, displayName, projectsCount }, idx, list) => (
          <Fragment key={idx}>
            <Grid item container spacing={1} alignItems="center">
              {editId === id ? (
                <EditableProjectType
                  id={id}
                  name={displayName || ''}
                  onChange={saveChanges}
                  onCancel={() => updateEditId(null)}
                />
              ) : (
                <InlineProjectType
                  id={id}
                  name={displayName || ''}
                  canChange={projectsCount === 0}
                  onEdit={updateEditId}
                  onDelete={openRemoveDialog}
                />
              )}
            </Grid>
            {idx !== list.length - 1 && (
              <Divider flexItem={true} sx={{ ml: 2, mt: 2 }} />
            )}
          </Fragment>
        ))}
      </Grid>
      <RemoveProjectTypeDialog
        name={removeItem?.name || ''}
        onDelete={onRemove}
        onCancel={closeRemoveDialog}
      />
    </>
  )
}
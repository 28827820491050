import React, { FC, useCallback } from "react"
import { Grid, Typography } from "@mui/material"
import { CustomSingleEvent } from "src/api"
import { RapidRHConnectedInfo } from './rapidRHConnectedInfo'
import { WoodEquilibriumInfo } from './woodEquilibriumInfo'
import { ProductUpdatedInfo } from './productUpdatedInfo'
import { ProjectUpdatedInfo } from './projectUpdatedInfo'
import { CheckConnectivityInfo } from './checkConnectivityInfo'

interface SourceInformationProps {
  event: CustomSingleEvent
}

export const SourceInformation: FC<SourceInformationProps> = ({ event }) => {
  const needToShowSourceInfo = useCallback(() => {
    const eventsWithSourceInfo = ['wagner_probe_assigned', 'wood_equilibrium_measured', 'product_updated', 'project_updated', 'check_connectivity']
    return eventsWithSourceInfo.includes(event.type?.name || '')
  }, [event])()
  if (!event || !needToShowSourceInfo) return null

  const renderAdditionalSourceInfo = () => {
    if (event.type?.name === 'wagner_probe_assigned') {
      return <RapidRHConnectedInfo event={event} />
    }
    if (event.type?.name === 'wood_equilibrium_measured') {
      return <WoodEquilibriumInfo event={event} />
    }
    if (event.type?.name === 'product_updated') {
      return <ProductUpdatedInfo event={event} />
    }
    if (event.type?.name === 'project_updated') {
      return <ProjectUpdatedInfo event={event} />
    }
    if (event.type?.name === 'check_connectivity') {
      return <CheckConnectivityInfo event={event} />
    }
    return null
  }

  return (
    <Grid container>
      <Grid item xs={12} sx={{ py: 1 }}>
        <Typography variant="h6" fontWeight={500}>Source information</Typography>
      </Grid>
      <Grid item xs={12} sx={{ py: 1 }}>
        {renderAdditionalSourceInfo()}
      </Grid>
    </Grid>
  )
}

import { useMutation, UseMutationOptions } from '@tanstack/react-query'
import { APIList } from 'src/interfaces'
import { Entities } from 'src/enums'
import { Manufacturer } from 'src/api'
import { useApiInstance } from './useApiInstance'
import {
  buildRequestParams,
  ObjectType,
  transformPage,
  transformPerPage,
} from './helpers'

interface RequestParams {
  paramsTransform?: Array<(params: Record<string, any>) => Record<string, any>>;
  options?: Omit<
    UseMutationOptions<
      APIList<Manufacturer>,
      Error,
      Partial<AdminManufacturerGetParams>,
      APIList<Manufacturer>
    >,
    'mutationKey' | 'mutationFn'
  >;
}

interface AdminManufacturerGetParams {
  limit: number;
  offset: number;
  sort: string;
  name: string;
}

const schema: ObjectType['schema'] = {
  limit: 'number',
  offset: 'number',
  sort: 'string',
  name: 'string',
}

export const useFilteredManufacturerListRequest = ({
  paramsTransform = [],
  options,
}: RequestParams = {}) => {
  const api = useApiInstance()

  return useMutation<
    APIList<Manufacturer>,
    Error,
    Partial<AdminManufacturerGetParams>,
    APIList<Manufacturer>
  >(
    [Entities.Manufacturers, 'list'],
    async (payload: Partial<AdminManufacturerGetParams>) => {
      const transformedParams = [
        transformPerPage,
        transformPage,
        ...paramsTransform,
      ].reduce((param, transform) => transform(param), payload)

      const queryParams = buildRequestParams<Partial<AdminManufacturerGetParams>>(
        transformedParams,
        schema,
      )

      const { limit, offset, sort, name } = queryParams
      const { data } = await api.v1AdminManufacturersGet(limit, offset, sort, name)
      return data as APIList<Manufacturer>
    },
    { ...options },
  )
}

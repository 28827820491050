import { useCallback } from 'react'
import {
  PrimitiveDropdownItemFactoryProps,
  ComplexDropdownItemFactoryProps,
  PrimitiveDropdownItemFactory,
  ComplexDropdownItemFactory,
} from 'src/components'

export interface DropdownItemFactoryParams {
  type?: 'complex' | 'primitive';
  params: PrimitiveDropdownItemFactoryProps | ComplexDropdownItemFactoryProps;
}

export const useDropdownItemFactory = ({
  type,
  params = {},
}: DropdownItemFactoryParams) => {
  const primitiveFactory = useCallback(PrimitiveDropdownItemFactory, [])
  const complexFactory = useCallback(ComplexDropdownItemFactory, [])
  let factory
  switch (type) {
  case 'primitive':
    factory = primitiveFactory(params as PrimitiveDropdownItemFactoryProps)
    break
  case 'complex':
  default:
    factory = complexFactory(params as ComplexDropdownItemFactoryProps)
    break
  }
  return useCallback(factory, [factory])
}

import { useMutation, UseMutationOptions } from '@tanstack/react-query'
import { Entities } from 'src/enums'
import {
  ProductsDraftsPublishBulk,
  ProductsDraftsPublishResponse,
} from 'src/api'
import { useApiInstance } from './useApiInstance'

interface RequestParams {
  options?: Omit<
    UseMutationOptions<unknown, Error, ProductsDraftsPublishBulk, unknown>,
    'mutationKey' | 'mutationFn'
  >;
}

export const usePublishProductDrafts = ({ options }: RequestParams = {}) => {
  const api = useApiInstance()

  return useMutation<
    ProductsDraftsPublishResponse[],
    Error,
    ProductsDraftsPublishBulk,
    unknown
  >(
    [Entities.ProductsDrafts, 'publish'],
    async ({ draftIds }) => {
      const { data } = await api.v1AdminProductsDraftsPublishPost({ draftIds })
      return data as ProductsDraftsPublishResponse[]
    },
    { ...options },
  )
}

import React, { FC, PropsWithChildren } from 'react'
import { styled } from '@mui/system'

const StyledList = styled('ul')`
  padding: 0;
  margin: 0;
  list-style-type: none;
  position: sticky;
  top: 16px;
`

export const List: FC<PropsWithChildren> = ({ children }) => {
  return <StyledList>{children}</StyledList>
}

import React from "react"

export const Crown = () => (
  <svg
    width="18"
    height="17"
    viewBox="0 0 18 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.5 17L0.5 7.5L5.5 9.5L8.5 0.5L11.5 9.5L18 7.5L14 17H3.5Z"
      fill="#F7AC3A"
    />
  </svg>
)

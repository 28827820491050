import { useEffect, FC } from 'react'
import { useLocation, useNavigate } from "react-router-dom"

const Root: FC = () => {
  const location = useLocation()
  const navigate = useNavigate()
  useEffect(() => {
    const { search } = location
    const params = new URLSearchParams(search)
    const redirectTo = params.get('redirect')
    navigate(redirectTo || '/dashboard', { replace: true })
  })
  return null
}

export default Root

import { useContext, useEffect, useState, useRef } from 'react'
import { useLocation } from 'react-router-dom'
import { QueryParamsContext } from 'src/contexts'

export type QueryParams = Record<string, string>;

export const useQueryParams = (
  initialParams: QueryParams = {},
): [
    QueryParams,
    (params: QueryParams, paramsToRemove?: string[]) => Promise<void>,
    (paramsToRemove?: string[]) => Promise<void>,
    () => void,
] => {
  // eslint-disable-next-line no-undef
  const resetTimer = useRef<NodeJS.Timeout | null>(null)
  const location = useLocation()
  const [path, setPath] = useState(location.pathname)
  const [params, setParams, remove, reset] = useContext(QueryParamsContext)

  useEffect(() => {
    const initial = { ...initialParams, ...params }

    const equal = Object.keys(initial).reduce(
      (isEqual, key) => key in params && isEqual,
      true,
    )

    if (!equal) setParams(initialParams)

    setPath(location.pathname)
    return () => {
      if (path !== location.pathname) {
        if (resetTimer?.current) clearTimeout(resetTimer.current)
        resetTimer.current = setTimeout(async () => {
          await remove(Object.keys(initialParams))
        }, 0)
      }
    }
  }, [
    location.pathname,
    initialParams,
    params,
    path,
    remove,
    setParams,
    setPath,
  ])

  return [params, setParams, remove, reset]
}

import React, { FC } from 'react'
import { Grid, Typography } from '@mui/material'
import { useManufacturerAnnouncementCategoryListRequest, useNewManufacturerAnnouncementCategoryRequest } from 'src/hooks/api'
import { DataLoad } from 'src/components/ui'
import { useAddPopupMessage } from 'src/hooks/ui'
import { TypeNewForm } from 'src/components/forms'
import { CategoryList } from './categoryList'

export const ManufacturerAnnouncementCategoryList: FC = () => {
  const addMessage = useAddPopupMessage()
  const { data, error, isInitialLoading, isError, refetch } = useManufacturerAnnouncementCategoryListRequest({
    params: { limit: 9999 },
  })
  const newCategoryRequest = useNewManufacturerAnnouncementCategoryRequest({
    options: {
      onSuccess: () => refetch(),
      onError: (err) => addMessage({ type: 'error', text: err.message }),
    }
  })
  const createNewCategory = ({ displayName }: { displayName: string }) => {
    newCategoryRequest.mutate({ displayName })
  }

  return (
    <Grid container direction="column" spacing={4}>
      <Grid item container direction="column">
        <Grid item>
          <Typography variant="h6" sx={{ pt: 2 }}>
            Create New GangBox Category
          </Typography>
        </Grid>
        <Grid item>
          <TypeNewForm
            onSubmit={createNewCategory}
            isLoading={newCategoryRequest.isLoading}
            placeholder="New GangBox Category Name"
          />
        </Grid>
      </Grid>
      <Grid item>
        <Grid item sx={{ pb: 2 }}>
          <Grid item>
            <Typography variant="h6">GangBox Category List</Typography>
          </Grid>
          <Grid>
            <Typography color="gray">
              Forbidden to delete category if it used for any post
            </Typography>
          </Grid>
        </Grid>
        <Grid item>
          <DataLoad
            isLoading={isInitialLoading}
            isError={isError}
            errorMessage={error?.message}
          >
            <CategoryList data={data?.rows || []} onChange={refetch} />
          </DataLoad>
        </Grid>
      </Grid>
    </Grid>
  )
}

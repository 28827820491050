import React from "react"
import { Grid } from "@mui/material"
import { useFormikContext } from "formik"
import { FormDropdown } from "src/components/ui"
import { ProductTypeTip } from "src/api"
import { TipList } from "../product"
import { useDropdownItemFactory } from "../../../hooks/ui"
import { TipListView } from "../../sections/productDraft/tipListView"

interface DropdownOption<T> {
  id: T;
  name: string;
}

interface ProductTipFormProps<T> {
  label: string;
  type: string;
  typeId: number;
  typeOptions: DropdownOption<T>[];
  defaultTipList: ProductTypeTip[];
  checkIfEditable: (value: T) => boolean;
  checkIfDefault: (value: T) => boolean;
}

export const ProductTipForm = <T extends unknown>({
  label,
  type,
  typeId,
  typeOptions,
  defaultTipList,
  checkIfEditable,
  checkIfDefault,
}: ProductTipFormProps<T>) => {
  const dropdownFieldName = `${type}TipType`

  const { values } = useFormikContext()

  const dropdownValue: T = (values as Record<string, any>)[dropdownFieldName]

  const dropdownItemListMap = useDropdownItemFactory({
    params: { labelProp: "name", valueProp: "id" },
  })

  const isListEditable = checkIfEditable(dropdownValue)
  const isListDefault = checkIfDefault(dropdownValue)

  return (
    <Grid container flexDirection="column" flexWrap="nowrap" spacing={1}>
      <Grid item xs={12} mt={1}>
        <FormDropdown
          list={typeOptions}
          label={label}
          name={dropdownFieldName}
          menuItemFactory={dropdownItemListMap}
        />
      </Grid>
      {isListEditable && (
        <Grid item xs={12} mt={1}>
          <TipList
            typeId={typeId}
            type={type}
            inputPlaceholder="Tip description"
          />
        </Grid>
      )}
      {isListDefault && (
        <Grid item xs={12} mt={1}>
          <TipListView tips={defaultTipList} />
        </Grid>
      )}
    </Grid>
  )
}

import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { Entities } from 'src/enums'
import { ManufacturerAnnouncement } from 'src/api'
import { useApiInstance } from './useApiInstance'

interface RequestParams {
  id: number;
  options?: Omit<
    UseQueryOptions<ManufacturerAnnouncement, Error>,
    'queryKey' | 'queryFn'
  >;
}

export const useManufacturerAnnouncementSingleRequest = ({ id, options }: RequestParams) => {
  const api = useApiInstance()

  return useQuery<ManufacturerAnnouncement, Error>(
    [Entities.ManufacturerAnnouncement, id],
    async () => {
      const { data } = await api.v1AdminManufacturersAnnouncementsManufacturerAnnouncementIdGet(id)
      return data as ManufacturerAnnouncement
    },
    { ...options },
  )
}

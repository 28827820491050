import { FC } from 'react'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

export type AccordionItem = {
  title: string | JSX.Element
  content: string | JSX.Element
}

type Props = {
  items: AccordionItem[]
}

export const AccordionList: FC<Props> = ({ items }) => {
  return (
    <>
      {items.map((item, index) => (
        <Accordion key={index}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            {item.title}
          </AccordionSummary>
          <AccordionDetails>
            {item.content}
          </AccordionDetails>
        </Accordion>
      ))}
    </>
  )
}
import React, { FC, useCallback } from "react"
import { Box, Grid, List, ListItem, ListItemText, Typography } from "@mui/material"
import { CustomSingleEvent, CustomSingleEventMessages } from "src/api"
import { InnerLink } from "src/components/ui"

interface WoodEquilibriumInfoProps {
  event: CustomSingleEvent
}

export const WoodEquilibriumInfo: FC<WoodEquilibriumInfoProps> = ({ event }) => {
  const { messages, sections, measurements, precondition, requirements } = event
  const { equilibriumMc, measuredMc, subfloorMc } = messages as CustomSingleEventMessages
  const renderSensorInfo = useCallback((): React.ReactNode => {
    const currentSection = sections.find((section) => section.current)
    if (!currentSection) return <Typography variant="body2">No sensors</Typography>

    const { sensors } = currentSection
    if (sensors?.length === 0) return <Typography variant="body2">No sensors</Typography>

    return (
      <List sx={{ pl: 2 }}>
        {(sensors || []).map((sensor) => {
          const data: { name: string, value: any }[] = [
            { name: 'Temperature, F', value: sensor.temperatureF },
            { name: 'Humidity, %', value: sensor.humidity },
            { name: 'In range temperature', value: sensor.isInRangeTemperature === true ? 'Yes' : 'No' },
            { name: 'In range humidity', value: sensor.isInRangeHumidity === true ? 'Yes' : 'No' },
          ]
          return (
            <Box key={sensor.sensorUniqueId} pb={1}>
              <InnerLink to={`/sensors/${sensor.sensorUniqueId}`}>{sensor.sensorUniqueId}</InnerLink>
              <ListItem sx={{ pl: 2 }}>
                <List>
                  {data.map(({ name, value }, index) => (
                    <ListItem key={index} divider>
                      <ListItemText
                        primary={<Typography variant="body2">{name}</Typography>}
                        secondary={value || '-'}
                      />
                    </ListItem>
                  ))}
                </List>
              </ListItem>
            </Box>
          )
        })}
      </List>
    )
  }, [sections])
  const renderMeasurements = useCallback(() => {
    if (!measurements) return null

    const preparedData = [
      {
        name: 'Equilibrium MC',
        values: [
          { name: 'Value', value: measurements.equilibriumMc.value || '-' },
          { name: 'Is out of spec', value: measurements.equilibriumMc.isOutOfSpec || '-' },
        ]
      },
      {
        name: 'Measured MC',
        values: [
          { name: 'Value', value: measurements.measuredMc.value || '-' },
          { name: 'User values', value: measurements.measuredMc.userValues?.join(', ') },
          { name: 'Is out of spec', value: measurements.measuredMc.isOutOfSpec || '-' },
        ]
      },
      {
        name: 'Subfloor MC',
        values: [
          { name: 'Value', value: measurements.subfloorMc.value || '-' },
          { name: 'User values', value: measurements.subfloorMc.userValues?.join(', ') },
          { name: 'Is out of spec', value: measurements.subfloorMc.isOutOfSpec || '-' },
        ]
      },
    ]

    return (
      <List sx={{ pl: 2 }}>
        {preparedData.map((item) => {
          return (
            <Box key={item.name} pb={1}>
              <Typography variant="body2">{item.name}</Typography>
              <ListItem sx={{ pl: 2 }}>
                <List>
                  {item.values.map(({ name, value }, index) => (
                    <ListItem key={index} divider>
                      <ListItemText
                        primary={<Typography variant="body2">{name}</Typography>}
                        secondary={value || '-'}
                      />
                    </ListItem>
                  ))}
                </List>
              </ListItem>
            </Box>
          )
        })}
      </List>
    )
  }, [measurements])
  const renderPreconditions = useCallback(() => {
    if (!precondition) return null

    const preparedData = [
      {
        name: 'Ambient conditions',
        values: [
          { name: 'Temperature, F', value: precondition.ambientConditionValues.temperature || '-' },
          { name: 'Humidity, %', value: precondition.ambientConditionValues.humidity || '-' },
          {
            name: 'Sensor unique ID',
            value: precondition.ambientConditionValues.sensorUniqueId
              ? <InnerLink to={`/sensors/${precondition.ambientConditionValues.sensorUniqueId}`}>{precondition.ambientConditionValues.sensorUniqueId}</InnerLink>
              : '-'
          },
        ]
      },
      {
        name: 'Board',
        values: [
          { name: 'Width less than 3', value: precondition.boardWidthLessThan3 === true ? 'Yes' : 'No' },
        ]
      },
      {
        name: 'Subfloor',
        values: [
          { name: 'Type', value: precondition.subfloorType || '-' },
        ]
      },
    ]

    return (
      <List sx={{ pl: 2 }}>
        {preparedData.map((item) => {
          return (
            <Box key={item.name} pb={1}>
              <Typography variant="body2">{item.name}</Typography>
              <ListItem sx={{ pl: 2 }}>
                <List>
                  {item.values.map(({ name, value }, index) => (
                    <ListItem key={index} divider>
                      <ListItemText
                        primary={<Typography variant="body2">{name}</Typography>}
                        secondary={value || '-'}
                      />
                    </ListItem>
                  ))}
                </List>
              </ListItem>
            </Box>
          )
        })}
      </List>
    )
  }, [precondition])
  const renderRequirements = useCallback(() => {
    if (!requirements) return null

    const { manufacturerSpecs, nwfaTargetMcRange, subfloorRequiredMc } = requirements
    const { humidityRange, temperatureRange, targetMc } = manufacturerSpecs
    const preparedData = [
      {
        name: 'Manufacturer specs',
        values: [
          { name: 'Temperature range', value: `Min: ${temperatureRange?.min}, Max: ${temperatureRange?.max}` },
          { name: 'Humidity range', value: `Min: ${humidityRange?.min}, Max: ${humidityRange?.max}` },
          { name: 'Target MC', value: `Min: ${targetMc?.min}, Max: ${targetMc?.max}` },
        ]
      },
      {
        name: 'NWFA',
        values: [
          { name: 'Target MC range', value: `Min: ${nwfaTargetMcRange?.min}, Max: ${nwfaTargetMcRange?.max}` },
        ]
      },
      {
        name: 'Subfloor',
        values: [
          { name: 'Required MC', value: subfloorRequiredMc || '-' },
        ]
      },
    ]

    return (
      <List sx={{ pl: 2 }}>
        {preparedData.map((item) => {
          return (
            <Box key={item.name} pb={1}>
              <Typography variant="body2">{item.name}</Typography>
              <ListItem sx={{ pl: 2 }}>
                <List>
                  {item.values.map(({ name, value }, index) => (
                    <ListItem key={index} divider>
                      <ListItemText
                        primary={<Typography variant="body2">{name}</Typography>}
                        secondary={value || '-'}
                      />
                    </ListItem>
                  ))}
                </List>
              </ListItem>
            </Box>
          )
        })}
      </List>
    )
  }, [requirements])

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6} sx={{ py: 1 }}>
        <Typography variant="body1" fontWeight={500}>Messages:</Typography>
        <List sx={{ pl: 2 }}>
          <Box pb={2}>
            <Typography variant="body2">Equilibrium MC:</Typography>
            <ListItem sx={{ pl: 2 }}>
              <List>
                {
                  equilibriumMc.length > 0
                    ? (equilibriumMc || []).map((message, index) => (
                      <ListItem key={index} divider>
                        <ListItemText
                          secondary={message}
                        />
                      </ListItem>
                    ))
                    : <Typography variant="body2">No messages</Typography>
                }
              </List>
            </ListItem>
          </Box>
          <Box pb={2}>
            <Typography variant="body2">Measured MC:</Typography>
            <ListItem sx={{ pl: 2 }}>
              <List>
                {
                  measuredMc.length > 0
                    ? (measuredMc || []).map((message, index) => (
                      <ListItem key={index} divider>
                        <ListItemText
                          secondary={message}
                        />
                      </ListItem>
                    ))
                    : <Typography variant="body2">No messages</Typography>
                }
              </List>
            </ListItem>
          </Box>
          <Box pb={2}>
            <Typography variant="body2">Subfloor MC:</Typography>
            <ListItem sx={{ pl: 2 }}>
              <List>
                {
                  subfloorMc.length > 0
                    ? (subfloorMc || []).map((message, index) => (
                      <ListItem key={index} divider>
                        <ListItemText
                          secondary={message}
                        />
                      </ListItem>
                    ))
                    : <Typography variant="body2">No messages</Typography>
                }
              </List>
            </ListItem>
          </Box>
        </List>
      </Grid>
      <Grid item xs={12} sm={6} sx={{ py: 1 }}>
        <Typography variant="body1" fontWeight={500}>Sensors:</Typography>
        {renderSensorInfo()}
      </Grid>
      <Grid item xs={12} sm={6} sx={{ py: 1 }}>
        <Typography variant="body1" fontWeight={500}>Measurements:</Typography>
        {renderMeasurements()}
      </Grid>
      <Grid item xs={12} sm={6} sx={{ py: 1 }}>
        <Typography variant="body1" fontWeight={500}>Preconditions:</Typography>
        {renderPreconditions()}
      </Grid>
      <Grid item xs={12} sm={6} sx={{ py: 1 }}>
        <Typography variant="body1" fontWeight={500}>Requirements:</Typography>
        {renderRequirements()}
      </Grid>
    </Grid>
  )
}

import React, { FC, PropsWithChildren, useCallback } from 'react'
import { Typography, Grid, IconButton } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { ArrowLeft } from 'src/components/icons'
import { useQueryParams } from 'src/hooks/ui'

interface EntityHeaderProps {
  title: string;
  onBack?: () => void;
  rightIcon?: React.ReactNode;
  actionBlockOnTheRight?: React.ReactNode;
  showBackButton?: boolean;
}

export const EntityHeader: FC<PropsWithChildren<EntityHeaderProps>> = ({
  title,
  onBack,
  children,
  rightIcon,
  actionBlockOnTheRight,
  showBackButton = true,
}) => {
  const navigate = useNavigate()
  const [params,, removeParams] = useQueryParams()
  const defaultOnBack = async () => {
    removeParams(Object.keys(params))
    navigate(-1)
  }
  const hasBlockOnTheRight = useCallback(() => Boolean(actionBlockOnTheRight), [actionBlockOnTheRight])()
  const renderChildren = () => {
    if (!children) return null

    return (
      <Grid item mt={1.5}>{children}</Grid>
    )
  }
  const renderBlockOnTheRight = () => {
    if (!actionBlockOnTheRight) return null

    return (
      <Grid item container xs={5} justifyContent="flex-end">
        {actionBlockOnTheRight}
      </Grid>
    )
  }
  return (
    <Grid container alignItems="center" justifyContent="space-between" sx={{ mb: 2 }}>
      <Grid item width="100%">
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid container item alignItems="center" spacing={1} {...hasBlockOnTheRight ? { xs: 7 } : {}}>
            {
              showBackButton ? (
                <Grid item sx={{ mr: 1, ml: -1 }}>
                  <IconButton
                    size="large"
                    color="primary"
                    onClick={onBack || defaultOnBack}
                    sx={{ width: 48, height: 48 }}
                  >
                    <ArrowLeft />
                  </IconButton>
                </Grid>
              ) : null
            }
            <Grid item>
              <Typography variant="h4">{title}</Typography>
            </Grid>
            <Grid item>
              {rightIcon || null}
            </Grid>
          </Grid>
          {renderBlockOnTheRight()}
        </Grid>
      </Grid>
      {renderChildren()}
    </Grid>
  )
}

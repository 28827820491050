import React, { FC } from "react"
import { Grid, Typography } from "@mui/material"
import { Feature } from "src/api"

interface ReleaseFeatureCardProps {
  feature: Feature;
}

export const ReleaseFeatureCard: FC<ReleaseFeatureCardProps> = ({
  feature
}) => {
  return (
    <Grid container direction="column" mt={3}>
      <Grid item>
        <Typography variant="body2" fontWeight={500}>{feature.header}</Typography>
      </Grid>
      <Grid item mt={2}>
        <Typography variant="body2">{feature.description}</Typography>
      </Grid>
      {
        feature.image ? (
          <Grid item mt={3} height={250}>
            <img
              style={{
                objectFit: "scale-down",
                width: "auto",
                height: "100%"
              }}
              src={feature.image || "../../no_image.png"}
              alt={feature.header}
            />
          </Grid>
        ) : null
      }
    </Grid>
  )
}

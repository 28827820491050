import { useMutation, UseMutationOptions } from '@tanstack/react-query'
import axios, { AxiosResponseTransformer } from 'axios'
import { Entities } from 'src/enums'
import { Contractor, ContractorCreationV2, UploadPhotoResult } from 'src/api'
import { ValuesToResize } from 'src/config'
import { useApiInstance } from './useApiInstance'
import { useSignInRedirect } from '../ui'

interface RequestUpload extends ContractorCreationV2 {
  photos?: Array<any> | null
}

interface RequestParams {
  options?: Omit<UseMutationOptions<Contractor | null, Error, RequestUpload>, 'mutationKey' | 'mutationFn'>
}

export const useNewContractorRequest = ({
  options,
}: RequestParams) => {
  const api = useApiInstance()
  const redirect = useSignInRedirect()

  return useMutation<Contractor | null, Error, RequestUpload>(
    [Entities.Contractors, 'create'],
    async (payload: RequestUpload) => {
      const { photos, ...creationPayload } = payload

      if (Array.isArray(photos) && photos.length > 0) {
        let photoResult: Array<UploadPhotoResult> = []
        try {
          await api.v1MediaPhotoPut(photos, ValuesToResize.contractor, {
            transformResponse: [
              ...(axios.defaults.transformResponse as AxiosResponseTransformer[]),
              ({ error, result, statusCode }) => {
                if (error) {
                  if (statusCode === 401) redirect()
                  throw new Error(error)
                }
                photoResult = result
              },
            ],
          })
        } catch (e) {
          console.log(e)
        }

        if (photoResult?.length > 0) {
          const uploadedPhoto = photoResult[0].original
          creationPayload.photo = uploadedPhoto
        }

        const { data } = await api.v2AdminContractorsPost(creationPayload)
        return data as Contractor
      }

      const { data } = await api.v2AdminContractorsPost(creationPayload)
      return data as Contractor
    },
    options,
  )
}

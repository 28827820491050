import React, { FC, PropsWithChildren } from 'react'
import { RootContainer, ContentBox } from '../ui'

export const FullscreenLayout: FC<PropsWithChildren> = ({ children }) => {
  return (
    <RootContainer>
      <ContentBox>{children}</ContentBox>
    </RootContainer>
  )
}

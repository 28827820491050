import React, { FC, PropsWithChildren } from 'react'
import { Box } from '@mui/material'

export const Centralize: FC<PropsWithChildren> = ({ children }) => {
  return (
    <Box
      width="100%"
      height="100%"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      {children}
    </Box>
  )
}

import React, { FC, PropsWithChildren } from "react"
import format from "date-fns/format"
import { Sensor, SensorState } from "src/api"
import { TabGroupFilter, PropsInjector, DataLoad } from "src/components"
import {
  useSensorListRequest,
  useSensorMapViewListRequest,
} from "src/hooks/api"
import { useQueryParamsState } from "src/hooks/ui"
import {
  transformSensorsState,
  transformBoolean as transformKeyAndValue,
  transformAgeOfReadings,
  transformSensorCurrentConfiguration,
} from "src/hooks/api/helpers"
import { SensorStateList, SearchParamsKeys, ViewTypes } from "src/enums"
import {
  ArchivedSensorList,
  AssignedSensorList,
  AvailableSensorList,
  InUseSensorList,
  NewSensorList,
  AllSensorList,
  ThingspaceSensorsList,
  SensorDiagnosticsList,
} from "./sensorList"

const getStatusList = ({
  data,
  count,
  isLoading,
  states,
  refetchSensorList,
}: {
  data: Sensor[];
  count: number;
  isLoading: boolean;
  states: SensorState[];
  refetchSensorList: () => void;
}) => [
  {
    status: SensorStateList.All,
    content: (
      <AllSensorList
        data={data}
        count={count}
        isLoading={isLoading}
        states={states}
        refetchSensorList={refetchSensorList}
      />
    ),
  },
  {
    status: SensorStateList.New,
    content: (
      <NewSensorList
        data={data}
        count={count}
        isLoading={isLoading}
        states={states}
        refetchSensorList={refetchSensorList}
      />
    ),
  },
  {
    status: SensorStateList.Available,
    content: (
      <AvailableSensorList
        data={data}
        count={count}
        isLoading={isLoading}
        states={states}
        refetchSensorList={refetchSensorList}
      />
    ),
  },
  {
    status: SensorStateList.Assigned,
    content: (
      <AssignedSensorList
        data={data}
        count={count}
        isLoading={isLoading}
        states={states}
        refetchSensorList={refetchSensorList}
      />
    ),
  },
  {
    status: SensorStateList.InUse,
    content: (
      <InUseSensorList data={data} count={count} isLoading={isLoading} />
    ),
  },
  {
    status: SensorStateList.Archived,
    content: (
      <ArchivedSensorList
        data={data}
        count={count}
        isLoading={isLoading}
        states={states}
        refetchSensorList={refetchSensorList}
      />
    ),
  },
  {
    status: SensorStateList.Thingspace,
    content: (
      <ThingspaceSensorsList />
    )
  },
  {
    status: SensorStateList.Diagnostics,
    content: (
      <SensorDiagnosticsList
        data={data}
        count={count}
        isLoading={isLoading}
        refetchSensorList={refetchSensorList}
      />
    )
  }
]

interface SensorTabsProps {
  data?: Sensor[];
  count?: number;
  isLoading?: boolean;
  refetchSensorList?: () => void;
  states?: SensorState[];
}

const SensorTabs: FC<SensorTabsProps> = ({
  data = [],
  count = 0,
  isLoading = false,
  refetchSensorList = () => {},
  states = [],
}) => {
  return (
    <TabGroupFilter
      statusList={getStatusList({
        data,
        count,
        isLoading,
        refetchSensorList,
        states,
      })}
      name={SearchParamsKeys.State}
      paramsToRemove={[
        SearchParamsKeys.Page,
        SearchParamsKeys.RowsPerPage,
        SearchParamsKeys.Sort,
        SearchParamsKeys.IsAvailableInSystem,
        SearchParamsKeys.CurrentSensorConfiguration,
        SearchParamsKeys.AgeOfReadingsMoreThanHour,
      ]}
    />
  )
}

interface SensorListLoaderProps {
  states: SensorState[];
}

const SensorListLoader: FC<PropsWithChildren<SensorListLoaderProps>> = ({ states, children }) => {
  const state = useQueryParamsState()
  const viewType = state[SearchParamsKeys.ViewType] || ViewTypes.List

  const useRequest =
    viewType === ViewTypes.List
      ? useSensorListRequest
      : useSensorMapViewListRequest

  const { data, error, isInitialLoading, isError, refetch } = useRequest({
    params: {
      ...state,
      ...(viewType === ViewTypes.Map
        ? {
          limit: 10000,
          offset: 0,
        }
        : null),
    },
    paramsTransform: [
      transformSensorsState(states || []),
      transformKeyAndValue(
        SearchParamsKeys.LowBattery,
        SearchParamsKeys.BatteryLevel,
        25
      ),
      transformKeyAndValue(
        SearchParamsKeys.WeakSignal,
        SearchParamsKeys.SignalStrength,
        -100
      ),
      transformKeyAndValue(
        SearchParamsKeys.LastPingMoreThanHour,
        SearchParamsKeys.LastPing,
        JSON.stringify({
          lt: format(
            new Date(new Date().setHours(new Date().getHours() - 1)),
            "yyyy-MM-dd'T'HH:mm:ssxxx"
          ),
        })
      ),
      transformKeyAndValue(
        SearchParamsKeys.SyncPeriodLessThanHour,
        SearchParamsKeys.SyncPeriod,
        JSON.stringify({ lt: 3600 })
      ),
      transformAgeOfReadings(
        state[SearchParamsKeys.AgeOfReadingsMoreThanHour] || ""
      ),
      transformSensorCurrentConfiguration(state[SearchParamsKeys.CurrentSensorConfiguration] || "")
    ],
    options: {
      ...(state.state === SensorStateList.Thingspace ? {
        enabled: false
      } : {})
    }
  })

  const props = {
    data: data?.rows || [],
    count: data?.count || 0,
    isLoading: isInitialLoading,
    states,
    refetchSensorList: refetch,
  }

  return (
    <DataLoad isLoading={false} isError={isError} errorMessage={error?.message}>
      <PropsInjector props={props}>{children}</PropsInjector>
    </DataLoad>
  )
}

interface SensorListProps {
  states?: SensorState[];
}

export const SensorList: FC<SensorListProps> = ({ states = [] }) => {
  return (
    <SensorListLoader states={states}>
      <SensorTabs />
    </SensorListLoader>
  )
}

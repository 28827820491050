import React, { Fragment } from 'react'
import { Grid, TableCell } from '@mui/material'
import { format } from 'date-fns'
import { Project, Sensor } from 'src/api'
import { DynamicColumn, InnerLink } from 'src/components/ui'
import { SensorStateList } from 'src/enums'
import { ClosedEye } from "../icons"

export namespace ProjectColumns {
  export const Name: DynamicColumn<Project> = {
    field: 'name',
    headerName: 'Name',
    type: 'string',
    innerLink: ({ id }) => `/projects/${id}`,
    sort: true,
  }
  export const NameWithIcon: DynamicColumn<Project> = {
    field: "name",
    sort: true,
    headerName: "Name",
    cellProps: { align: "left" },
    type: "custom",
    component: ({ row: { id, name, isActive }, cellProps }) => {
      const isArchived = !isActive
      return (
        <TableCell align={cellProps?.align}>
          <Grid container alignItems="center" spacing={1}>
            <Grid item>
              <InnerLink to={`/projects/${id}`}>{name}</InnerLink>
            </Grid>
            {isArchived && (
              <Grid container item title="Archived project" xs={2}>
                <ClosedEye />
              </Grid>
            )}
          </Grid>
        </TableCell>
      )
    },
  }
  export const Location: DynamicColumn<Project> = {
    field: 'fullAddress',
    headerName: 'Location',
    type: 'string',
  }
  export const ContractorName: DynamicColumn<Project> = {
    field: 'contractor.name',
    headerName: 'Contractor',
    type: 'string',
    innerLink: ({ contractorId }) => `/contractors/${contractorId}`,
  }
  export const UserName: DynamicColumn<Project> = {
    field: 'users',
    headerName: 'User',
    type: 'custom',
    component: ({ row, cellProps }) => {
      const { users } = row
      if (!users || users.length === 0)
        return <TableCell align={cellProps?.align}>-</TableCell>
      else
        return (
          <TableCell align={cellProps?.align}>
            {users.map(({ id, name }, idx) => (
              <Fragment key={id}>
                <InnerLink to={`/users/${id}`}>{name}</InnerLink>
                {idx !== users.length - 1 && <br />}
              </Fragment>
            ))}
          </TableCell>
        )
    },
  }
  export const ProjectTypeName: DynamicColumn<Project> = {
    field: 'projectType.displayName',
    headerName: 'Type',
    type: 'string',
  }
  export const Size: DynamicColumn<Project> = {
    field: 'area',
    headerName: 'Size',
    type: 'digit',
  }
  export const CreatedAt: DynamicColumn<Project> = {
    field: 'createdAt',
    headerName: 'Created At',
    type: "date",
    format: (value) => value ? format(new Date(value), 'PPpp') : '',
    sort: true,
  }
  export const ConnectedSensors: DynamicColumn<Project> = {
    field: 'sections.sensors',
    headerName: 'Sensors Connected',
    type: 'custom',
    cellProps: { align: 'right' },
    component: ({ row, cellProps }) => {
      const list = []
      for (const section of row.sections || []) {
        for (const sensor of section.sensors || []) {
          const { projectSectionsSensors = [] } = sensor
          const index = projectSectionsSensors.findIndex(
            (el) => el.projectId === row.id && el.isActive
          )
          if (index !== -1 && sensor.state?.name === SensorStateList.InUse)
            list.push(sensor.uniqueId)
        }
      }
      const count = list.filter(
        (uid, index, arr) => arr.indexOf(uid) === index
      ).length
      return <TableCell align={cellProps?.align}>{count}</TableCell>
    },
  }

  export const NumberOfActiveSensors: DynamicColumn<Project> = {
    field: 'sections.sensors',
    headerName: 'Number of sensors',
    type: 'custom',
    cellProps: { align: 'right' },
    component: ({ row, cellProps }) => {
      const list = []
      for (const section of row.sections || []) {
        for (const sensor of section.sensors || []) {
          const { projectSectionsSensors = [] } = sensor
          const index = projectSectionsSensors.findIndex(
            (el) => el.isActive
          )
          if (index !== -1 && sensor.state?.name === SensorStateList.InUse)
            list.push(sensor.uniqueId)
        }
      }
      const count = list.filter(
        (uid, index, arr) => arr.indexOf(uid) === index
      ).length
      return <TableCell align={cellProps?.align}>{count}</TableCell>
    },
  }

  export const SensorStartDate = (senosrUniqueId: string): DynamicColumn<Project> => {
    return {
      field: 'sections.sensors',
      headerName: 'Current Sensor Started At',
      type: 'custom',
      cellProps: { align: 'right' },
      component: ({ row, cellProps }) => {
        const sensors: Sensor[] = [];
        (row.sections || []).forEach((section) => sensors.push(...(section.sensors || [])))
        const currentSensor = sensors.find((sensor) => sensor.uniqueId === senosrUniqueId)
        if (!currentSensor) return <TableCell align={cellProps?.align}>-</TableCell>

        const binding = (currentSensor.projectSectionsSensors || []).find((item) => item.sensorId === currentSensor.id && item.projectId === row.id)
        if (!binding) return <TableCell align={cellProps?.align}>-</TableCell>

        const startDate = format(new Date(binding.createdAt), "P")
        return <TableCell align={cellProps?.align}>{startDate}</TableCell>
      },
    }
  }

  export const SensorFinishDate = (senosrUniqueId: string): DynamicColumn<Project> => {
    return {
      field: 'sections.sensors',
      headerName: 'Current Sensor Finished At',
      type: 'custom',
      cellProps: { align: 'right' },
      component: ({ row, cellProps }) => {
        const sensors: Sensor[] = [];
        (row.sections || []).forEach((section) => sensors.push(...(section.sensors || [])))
        const currentSensor = sensors.find((sensor) => sensor.uniqueId === senosrUniqueId)
        if (!currentSensor) return <TableCell align={cellProps?.align}>-</TableCell>

        const binding = (currentSensor.projectSectionsSensors || []).find((item) => item.sensorId === currentSensor.id && item.projectId === row.id)
        if (!binding) return <TableCell align={cellProps?.align}>-</TableCell>

        const endDate = binding.disconnectTime ? format(new Date(binding.disconnectTime), "P") : '-'
        return <TableCell align={cellProps?.align}>{endDate}</TableCell>
      },
    }
  }

  export const Status: DynamicColumn<Project> = {
    field: 'isActive',
    headerName: 'Status',
    type: 'custom',
    cellProps: { align: 'right' },
    component: ({ row, cellProps }) => {
      return <TableCell align={cellProps?.align}>{row.isActive ? 'Active' : 'Archived'}</TableCell>
    },
  }
}

export default ProjectColumns

import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { Entities } from 'src/enums'
import { useApiInstance } from './useApiInstance'

interface RequestParams {
  options?: Omit<
    UseQueryOptions<number, Error>,
    'queryKey' | 'queryFn'
  >;
}

export const useNewProductRequestNumberRequest = ({
  options,
}: RequestParams = {}) => {
  const api = useApiInstance()

  return useQuery<number, Error>(
    [Entities.ProductRequest, 'number-of-new'],
    async () => {
      const { data } = await api.v1AdminProductsRequestsNumberOfNewGet()
      return data as number
    },
    {
      ...options,
    },
  )
}

import { useMutation, UseMutationOptions } from '@tanstack/react-query'
import { Entities } from 'src/enums'
import {
  SystemMessagePayload,
} from 'src/api'
import { useApiInstance } from './useApiInstance'

interface RequestParams {
  options?: Omit<
    UseMutationOptions<unknown, Error, SystemMessagePayload>,
    'mutationKey' | 'mutationFn'
  >;
}

export const useSendNewMessage = ({ options }: RequestParams = {}) => {
  const api = useApiInstance()

  return useMutation<
    boolean,
    Error,
    SystemMessagePayload,
    unknown
  >(
    [Entities.SystemMessage, 'send'],
    async (payload) => {
      const { data }: { data: unknown } = await api.v1AdminSystemMessagesSendPost(payload)
      return data as boolean
    },
    { ...options },
  )
}

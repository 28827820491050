import React, { useContext, useState } from "react"
import { Button } from "@mui/material"
import { ProductDraftNewDialogForm } from "src/components/forms/productDraft/newProductDraftModal"
import { ProductDraftsContext } from "../../../contexts/productDraftsContext"

export const AddNewProductDraft = () => {
  const [addProductDialogOpened, setAddProductDialogOpened] = useState(false)

  const { manufacturerId, createNewProductDraft } =
    useContext(ProductDraftsContext)

  return (
    <>
      <Button
        variant="contained"
        onClick={() => setAddProductDialogOpened(true)}
      >
        Add New Product
      </Button>
      <ProductDraftNewDialogForm
        open={addProductDialogOpened}
        defaultValues={{ manufacturerId }}
        onCancel={() => setAddProductDialogOpened(false)}
        onSubmit={createNewProductDraft}
      />
    </>
  )
}

import React, { FC, useMemo, useState } from "react"
import Menu from "@mui/material/Menu"
import MenuItem from "@mui/material/MenuItem"
import { GridActionsCellItem } from "@mui/x-data-grid-premium"
import MoreVertIcon from "@mui/icons-material/MoreVert"
import { SensorColumnsDataGrid } from "src/components/columns/sensor"
import { Sensor, SensorState } from "src/api"
import { useQueryParamsState } from "src/hooks/ui"
import { SearchParamsKeys, ViewTypes, SensorFilters, SensorStateList } from "src/enums"
import { SensorsMapView } from "src/components/ui"
import { SensorListFilters } from "src/components/forms/sensor"
import { SensorMapFilters } from "src/components/forms/sensor/sensorMapFilters"
import { useSensorStateUpdate } from "src/hooks/ui/useSensorStateUpdate"
import { CommonSensorList } from "./commonSensorsList"
import { ContractorsDataLoader } from "./contractorsDataLoader"
import { UnarchiveSensorModal } from "../unarchiveSensorModal"

const columns = ({
  handleOpenMenu,
}: {
  handleOpenMenu: (e: any, row: any) => void
}) => [
  SensorColumnsDataGrid.Id,
  SensorColumnsDataGrid.Model,
  SensorColumnsDataGrid.IMEI,
  SensorColumnsDataGrid.DevEUI,
  SensorColumnsDataGrid.LastPing,
  SensorColumnsDataGrid.BatteryLevel,
  SensorColumnsDataGrid.Description,
  {
    field: "actions",
    headerName: "",
    type: "actions",
    width: 30,
    getActions: ({ row }: { row: any }) => [
      <GridActionsCellItem
        icon={<MoreVertIcon />}
        label="Actions"
        className="textPrimary"
        onClick={(e: any) => handleOpenMenu(e, row)}
        color="inherit"
      />,
    ],
  },
]

interface ArchivedSensorListProps {
  isLoading: boolean;
  count: number;
  data: Sensor[];
  states: SensorState[];
  refetchSensorList: () => void;
}

export const ArchivedSensorList: FC<ArchivedSensorListProps> = ({
  data,
  isLoading,
  count,
  refetchSensorList,
  states,
}) => {
  const [currentRow, setCurrentRow] = useState<Sensor | null>(null)
  const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLElement | null>(null)
  const queryParams = useQueryParamsState()
  const viewType = queryParams[SearchParamsKeys.ViewType] || ViewTypes.List
  const {
    openSensorUnarchivingDialog,
    closeSensorUnarchivingDialog,
    updateSensorState,
    isLoadingChangingSensorState: isLoadingUnarchivingSensor,
  } = useSensorStateUpdate()
  const newState = useMemo(() => states.find(
    (state) => state.name === SensorStateList.New
  ), [states])
  const handleOpenMenu = (
    event: React.MouseEvent<HTMLElement>,
    row: Sensor
  ) => {
    setCurrentRow(row)
    setMenuAnchorEl(event.currentTarget)
  }

  const handleCloseMenu = () => {
    setCurrentRow(null)
    setMenuAnchorEl(null)
  }

  return viewType === ViewTypes.List ? (
    <>
      <CommonSensorList
        columns={columns({ handleOpenMenu })}
        count={count}
        data={data}
        isLoading={isLoading}
        filters={
          <SensorListFilters
            activeFilters={[
              SensorFilters.LowBattery,
              SensorFilters.WeakSignal,
              SensorFilters.LastPingMoreThanHour,
              SensorFilters.SyncPeriod,
              SensorFilters.ReadingsAge,
              SensorFilters.CurrentSensorConfiguration,
              SensorFilters.BleStatus,
            ]}
          />
        }
      />
      <Menu
        anchorEl={menuAnchorEl}
        open={!!menuAnchorEl}
        onClose={handleCloseMenu}
      >
        <MenuItem
          disabled={isLoadingUnarchivingSensor}
          onClick={openSensorUnarchivingDialog}
        >
          Unarchive
        </MenuItem>
      </Menu>
      <UnarchiveSensorModal
        sensorDeviceId={currentRow?.deviceId as string}
        isLoading={isLoadingUnarchivingSensor}
        onCancel={() => {
          closeSensorUnarchivingDialog()
          handleCloseMenu()
        }}
        onUnarchive={() => {
          updateSensorState({
            uniqueId: currentRow?.uniqueId as string,
            state: newState as SensorState,
            onSuccess: () => {
              refetchSensorList()
              handleCloseMenu()
            },
          })
        }}
      />
    </>
  ) : (
    <SensorsMapView
      data={data}
      isLoading={isLoading}
      filters={
        <ContractorsDataLoader>
          <SensorMapFilters
            activeFilters={[
              SensorFilters.Contractor,
              SensorFilters.LowBattery,
              SensorFilters.WeakSignal,
              SensorFilters.LastPingMoreThanHour,
              SensorFilters.SyncPeriod,
              SensorFilters.ReadingsAge,
              SensorFilters.LocationType,
            ]}
          />
        </ContractorsDataLoader>
      }
    />
  )
}

import React, { FC } from 'react'
import { Typography, DialogActions, Button, Box, CircularProgress } from '@mui/material'
import { StatefulDialog } from 'src/components/ui'
import { DialogNames } from 'src/enums'

interface DeleteUserModalProps {
  userName: string;
  onDelete: () => void;
  onCancel: () => void;
  isLoading: boolean;
}

export const DeleteUserModal: FC<DeleteUserModalProps> = ({
  userName,
  onDelete,
  onCancel,
  isLoading = false,
}) => {
  return (
    <StatefulDialog
      name={DialogNames.DeleteUser}
      actions={
        <Box mb={2} mx={2}>
          <DialogActions>
            <Button variant="outlined" onClick={onCancel}>
              Cancel
            </Button>
            <Button
              variant="contained"
              color="error"
              onClick={onDelete}
              disabled={isLoading}
            >
              { isLoading ? <CircularProgress color="info" size={26} /> : 'Delete' }
            </Button>
          </DialogActions>
        </Box>
      }
    >
      <Box minWidth="300px">
        <Typography variant="h6">Are you sure you want to delete the user {userName}?</Typography>
      </Box>
    </StatefulDialog>
  )
}

import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { Entities } from 'src/enums'
import { CombinedReportIncludedEvent } from 'src/api'
import { useApiInstance } from './useApiInstance'

interface RequestParams {
  options?: Omit<
    UseQueryOptions<unknown, Error, CombinedReportIncludedEvent[]>,
    'queryKey' | 'queryFn'
  >;
}

export const useCombinedReportIncludedEventsListRequest = ({
  options,
}: RequestParams = {}) => {
  const api = useApiInstance()

  return useQuery<unknown, Error, CombinedReportIncludedEvent[]>(
    [Entities.CombinedReportIncludedEvent],
    async () => {
      const { data } = await api.v1AdminReportsCombinedReportsIncludedEventsGet()
      return data
    },
    options,
  )
}

import React, { FC } from 'react'
import { Grid } from '@mui/material'
import format from "date-fns/format"
import { Table, DynamicColumn } from 'src/components/ui'
import { WagnerProbe, WagnerProbeCurrentData } from 'src/api'
import { WagnerProbeLastReadingsColumns } from 'src/components/columns/wagnerProbe'
import { capitalizeFirstLetter } from 'src/utils/formatters'

const lastReadingColumns: DynamicColumn<AppWagnerProbeReading>[] = [
  WagnerProbeLastReadingsColumns.ReadingName,
  WagnerProbeLastReadingsColumns.ReadingValue,
  WagnerProbeLastReadingsColumns.ReadingTime,
]
interface WagnerProbeLastReadingsProps {
  wagnerProbe: WagnerProbe;
}
export interface AppWagnerProbeReading {
  name: string;
  value: string | number | undefined;
  readingTime: string | undefined;
}
type GetReadingRow = (data: WagnerProbeCurrentData) => AppWagnerProbeReading
interface ReadingRows {
  slabTemperatureF: GetReadingRow
  slabHumidity: GetReadingRow
  signalStrength: GetReadingRow
  grabberBatteryLevel: GetReadingRow
  repeaterBatteryLevel: GetReadingRow
  deviceType: GetReadingRow
  source: GetReadingRow
  receivedFromDataGrabberAt: GetReadingRow
  receivedFromMiniGrabberAt: GetReadingRow
  receivedFromTotalReaderAt: GetReadingRow
  receivedFromRepeaterAt: GetReadingRow
  receivedViaPhoneAt: GetReadingRow
  receivedViaGatAt: GetReadingRow
}

const rows: ReadingRows = {
  slabTemperatureF: (data) => {
    return {
      name: 'Slab Temperature',
      value: data.slabTemperatureF ? `${data.slabTemperatureF}°F` : "-",
      readingTime: data.readingTime,
    }
  },
  slabHumidity: (data) => {
    return {
      name: 'Slab Humidity',
      value: data.slabHumidity ? `${data.slabHumidity}%` : "-",
      readingTime: data.readingTime,
    }
  },
  signalStrength: (data) => {
    return {
      name: 'Signal Strength',
      value: data.signalStrength || "-",
      readingTime: data.readingTime,
    }
  },
  grabberBatteryLevel: (data) => {
    return {
      name: 'Grabber Battery Level',
      value: data.batteryLevel ? `${data.batteryLevel}%` : "-",
      readingTime: data.readingTime,
    }
  },
  repeaterBatteryLevel: (data) => {
    return {
      name: 'Repeater Battery Level',
      value: data.repeaterBatteryLevel ? `${data.repeaterBatteryLevel}%` : "-",
      readingTime: data.readingTime,
    }
  },
  deviceType: (data) => {
    return {
      name: 'Device Type',
      value: data.deviceType ? capitalizeFirstLetter(data.deviceType as string).replace("_", " ") : "-",
      readingTime: data.readingTime,
    }
  },
  source: (data) => {
    return {
      name: 'Source',
      value: capitalizeFirstLetter(data.source as string) || "-",
      readingTime: data.readingTime,
    }
  },
  receivedFromDataGrabberAt: (data) => {
    return {
      name: 'Received From Data Grabber',
      value: data.receivedFromDataGrabberAt ? format(new Date(data.receivedFromDataGrabberAt), "PPpp") : "-",
      readingTime: undefined,
    }
  },
  receivedFromMiniGrabberAt: (data) => {
    return {
      name: 'Received From Mini Grabber',
      value: data.receivedFromMiniGrabberAt ? format(new Date(data.receivedFromMiniGrabberAt), "PPpp") : "-",
      readingTime: undefined,
    }
  },
  receivedFromTotalReaderAt: (data) => {
    return {
      name: 'Received From Total Reader',
      value: data.receivedFromTotalReaderAt ? format(new Date(data.receivedFromTotalReaderAt), "PPpp") : "-",
      readingTime: undefined,
    }
  },
  receivedFromRepeaterAt: (data) => {
    return {
      name: 'Received From Repeater',
      value: data.receivedFromRepeaterAt ? format(new Date(data.receivedFromRepeaterAt), "PPpp") : "-",
      readingTime: undefined,
    }
  },
  receivedViaPhoneAt: (data) => {
    return {
      name: 'Received Via Phone',
      value: data.receivedViaPhoneAt ? format(new Date(data.receivedViaPhoneAt), "PPpp") : "-",
      readingTime: undefined,
    }
  },
  receivedViaGatAt: (data) => {
    return {
      name: 'Received Via GAT',
      value: data.receivedViaGatAt ? format(new Date(data.receivedViaGatAt), "PPpp") : "-",
      readingTime: undefined,
    }
  },
}

export const WagnerProbeLastReadings: FC<WagnerProbeLastReadingsProps> = (props) => {
  const { wagnerProbe } = props
  if (!wagnerProbe) return null

  const data: AppWagnerProbeReading[] = Object.values(rows).map((value: GetReadingRow) => value(wagnerProbe.currentData as WagnerProbeCurrentData || {}))

  return (
    <Grid container spacing={4}>
      <Grid item container>
        <Table
          columns={lastReadingColumns}
          data={data}
          showFooter={false}
          sx={{ width: '100%' }}
        />
      </Grid>
    </Grid>
  )
}

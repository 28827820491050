import React, { FC } from 'react'
import { Box, Typography } from '@mui/material'
import { Link } from 'react-router-dom'

interface WipProps {
  title: string;
  redirectTo?: string;
  redirectTitle?: string;
}

export const Wip: FC<WipProps> = ({ title, redirectTitle, redirectTo }) => {
  return (
    <Box>
      <Typography variant="h4">{title}: Work in progress</Typography>
      {redirectTo && redirectTitle && (
        <Typography variant="body1">
          Go back to{' '}
          <Typography component={Link} to={redirectTo}>
            {redirectTitle}
          </Typography>
        </Typography>
      )}
    </Box>
  )
}

import React, { FC, useState, useCallback, useEffect } from 'react'
import { ApexOptions } from 'apexcharts'
import { format } from 'date-fns'
import formatDuration from "date-fns/formatDuration"
import secondsToMinutes from "date-fns/secondsToMinutes"
import secondsToHours from "date-fns/secondsToHours"
import { Box, CircularProgress, Grid, IconButton, Typography, Tooltip } from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBackRounded'
import ArrowForwardIcon from '@mui/icons-material/ArrowForwardRounded'
import CloseIcon from '@mui/icons-material/CloseRounded'
import QuestionIcon from '@mui/icons-material/HelpOutline'
import { useAggregatedTimeseriesGapRequest } from 'src/hooks/api'
import { Chart } from 'src/components/ui'
import { AggregatedTimeseriesGap } from 'src/api'

type ApexChartDataObj = {
  x: any;
  y: any;
  fillColor?: string;
  strokeColor?: string;
  meta?: any;
  goals?: any;
};

const STEP = 7

export const TimeseriesGapChart: FC = () => {
  const [dateFrom, setDateFrom] = useState<Date>(new Date(new Date().setDate(new Date().getDate() - STEP)))
  const [dateTo, setDateTo] = useState<Date>(new Date())
  const [timeseriesGapList, setTimeseriesGapList] = useState<AggregatedTimeseriesGap[]>([])

  const {
    data: timeseriesGapData,
    isInitialLoading,
    isRefetching,
    refetch: refetchTimeseriesGap,
  } = useAggregatedTimeseriesGapRequest({
    params: {
      dateFrom: new Date(new Date(dateFrom).setUTCMinutes(0, 0)).toISOString(),
      dateTo: new Date(new Date(dateTo).setUTCMinutes(59, 59)).toISOString(),
    },
    options: { enabled: false },
  })

  useEffect(() => {
    setTimeseriesGapList(timeseriesGapData || [])
  }, [timeseriesGapData])

  useEffect(() => {
    if (dateFrom && dateTo) refetchTimeseriesGap()
  }, [dateFrom, dateTo, refetchTimeseriesGap])

  const isLastPeriod = useCallback(() => {
    return dateTo.getDate() === new Date().getDate()
      && dateTo.getMonth() === new Date().getMonth()
      && dateTo.getFullYear() === new Date().getFullYear()
  }, [dateTo])()

  const handleGoBack = () => {
    if (isRefetching) return

    const newTimeFrom = new Date(new Date(dateFrom).setDate(new Date(dateFrom).getDate() - STEP))
    const newTimeTo = new Date(new Date(dateTo).setDate(new Date(dateTo).getDate() - STEP))
    setDateFrom(newTimeFrom)
    setDateTo(newTimeTo)
  }

  const handleGoForward = () => {
    if (isLastPeriod || isRefetching) return

    const newTimeFrom = new Date(new Date(dateFrom).setDate(new Date(dateFrom).getDate() + STEP))
    const newTimeTo = new Date(new Date(dateTo).setDate(new Date(dateTo).getDate() + STEP))
    setDateFrom(newTimeFrom)
    setDateTo(newTimeTo)
  }

  const resetRange = () => {
    const newTimeFrom = new Date(new Date(new Date().setDate(new Date().getDate() - STEP)))
    const newTimeTo = new Date()
    setDateFrom(newTimeFrom)
    setDateTo(newTimeTo)
  }

  const getDuration = (value: number) => {
    const seconds = value > 60
      ? value - (Math.floor(value / 60) * 60)
      : value
    const minutes = secondsToMinutes(value) > 60
      ? secondsToMinutes(value) - (Math.floor(secondsToMinutes(value) / 60) * 60)
      : secondsToMinutes(value)
    const hours = secondsToHours(value) > 24
      ? secondsToHours(value) - (Math.floor(secondsToHours(value) / 24) * 24)
      : secondsToHours(value)
    const days = Math.floor(value / 60 / 60 / 24)
    return {
      seconds,
      minutes,
      hours,
      days,
    }
  }

  const commonAverageGapForWeek = useCallback((): string => {
    const numberOfReadings = timeseriesGapList.length
    const sum = timeseriesGapList.reduce((acc, currentValue) => {
      return acc + currentValue.minGap + currentValue.avgGap + currentValue.maxGap
    }, 0)
    const avg = Math.round(sum / numberOfReadings)
    return formatDuration(
      getDuration(avg),
      {
        format: ['days', 'hours', 'minutes', 'seconds'],
        zero: false
      }
    )
  }, [timeseriesGapList])

  const minimalGapForWeek = useCallback((): string => {
    const numberOfReadings = timeseriesGapList.length
    const sum = timeseriesGapList.reduce((acc, currentValue) => {
      return acc + currentValue.minGap
    }, 0)
    const avg = Math.round(sum / numberOfReadings)
    return formatDuration(
      getDuration(avg),
      {
        format: ['days', 'hours', 'minutes', 'seconds'],
        zero: false
      }
    )
  }, [timeseriesGapList])

  const averageGapForWeek = useCallback((): string => {
    const numberOfReadings = timeseriesGapList.length
    const sum = timeseriesGapList.reduce((acc, currentValue) => {
      return acc + currentValue.avgGap
    }, 0)
    const avg = Math.round(sum / numberOfReadings)
    return formatDuration(
      getDuration(avg),
      {
        format: ['days', 'hours', 'minutes', 'seconds'],
        zero: false
      }
    )
  }, [timeseriesGapList])

  const maximalGapForWeek = useCallback((): string => {
    const numberOfReadings = timeseriesGapList.length
    const sum = timeseriesGapList.reduce((acc, currentValue) => {
      return acc + currentValue.maxGap
    }, 0)
    const avg = Math.round(sum / numberOfReadings)
    return formatDuration(
      getDuration(avg),
      {
        format: ['days', 'hours', 'minutes', 'seconds'],
        zero: false
      }
    )
  }, [timeseriesGapList])

  const getSensorBarChartData = useCallback(
    (): ApexOptions['series'] => {
      const minGatData: ApexChartDataObj[] = []
      const avgGapData: ApexChartDataObj[] = []
      const maxGapData: ApexChartDataObj[] = []
      const devicesData: ApexChartDataObj[] = []
      for (let i = 0; i < STEP * 24; i++) {
        const shiftedTime = new Date(new Date(dateTo).setUTCHours(new Date(dateTo).getUTCHours() - i))
        const timeseriesGap = timeseriesGapList.find((item) => (
          new Date(item.bucket).getUTCFullYear() === shiftedTime.getUTCFullYear()
          && new Date(item.bucket).getUTCMonth() === shiftedTime.getUTCMonth()
          && new Date(item.bucket).getUTCDate() === shiftedTime.getUTCDate()
          && new Date(item.bucket).getUTCHours() === shiftedTime.getUTCHours()
        ))
        minGatData.unshift({
          x: shiftedTime.getTime(),
          y: timeseriesGap?.minGap ? Math.round(timeseriesGap.minGap) : 0,
        })
        avgGapData.unshift({
          x: shiftedTime.getTime(),
          y: timeseriesGap?.avgGap ? Math.round(timeseriesGap.avgGap) : 0,
        })
        maxGapData.unshift({
          x: shiftedTime.getTime(),
          y: timeseriesGap?.maxGap ? Math.round(timeseriesGap.maxGap) : 0,
        })
        devicesData.unshift({
          x: shiftedTime.getTime(),
          y: timeseriesGap?.numberOfDevices || 0,
        })
      }
      return [
        {
          name: 'Minimal gap',
          data: minGatData,
        },
        {
          name: 'Average gap',
          data: avgGapData,
        },
        {
          name: 'Maximal gap',
          data: maxGapData,
        },
        {
          name: 'Number of devices',
          data: devicesData,
        }
      ]
    },
    [timeseriesGapList, dateTo]
  )

  const chartOptions: ApexOptions = {
    chart: {
      type: 'line',
      toolbar: {
        tools: {
          download: true,
          selection: false,
          zoom: false,
          zoomin: false,
          zoomout: false,
          pan: false,
          reset: false,
        },
        export: {
          csv: {
            headerCategory: 'Time',
          },
        },
      },
    },
    colors: ['#13d501', '#f4cf01', '#ff0000', '#1060fe'],
    stroke: {
      curve: 'smooth',
      width: [3, 3, 3, 0],
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      type: "datetime",
      labels: {
        offsetX: 0,
        formatter: (timestamp) => {
          return format(Number(timestamp), 'MMM dd - h a')
        },
      },
    },
    tooltip: {
      x: {
        formatter: (timestamp) => {
          return format(timestamp, 'MMM dd - h a')
        },
      },
      y: {
        formatter: (value, { seriesIndex }) => {
          if (seriesIndex === 3) return String(value)

          return formatDuration(
            getDuration(value),
            {
              format: ['days', 'hours', 'minutes', 'seconds'],
              zero: false
            }
          )
        }
      }
    },
  }

  return (
    <Grid container spacing={2} direction='column'>
      <Grid item>
        <Typography variant='h6'>TimeseriesDB GAP</Typography>
      </Grid>
      <Grid item container alignItems="center">
        <Grid item>
          <IconButton aria-label="go-back" color="primary" onClick={handleGoBack} disabled={isRefetching}>
            <ArrowBackIcon />
          </IconButton>
        </Grid>
        <Grid item px={1}>
          <Typography variant="body1">
            {`${format(dateFrom, 'PP')} - ${format(dateTo, 'PP')}`}
          </Typography>
        </Grid>
        <Grid item>
          <IconButton aria-label="go-forward" color="primary" onClick={handleGoForward} disabled={isLastPeriod || isRefetching}>
            <ArrowForwardIcon />
          </IconButton>
        </Grid>
        <Grid item>
          <IconButton aria-label="reset" color="primary" onClick={resetRange} disabled={isLastPeriod || isRefetching}>
            <CloseIcon />
          </IconButton>
        </Grid>
      </Grid>
      <Grid item container flexDirection="column" spacing={1}>
        <Grid item container>
          <Typography variant="subtitle2" fontSize={16}>Average gaps for the week</Typography>
        </Grid>
        <Grid item container spacing={2}>
          <Grid item>
            <Grid item container alignItems="center" spacing={1}>
              <Grid item>
                <Typography variant="subtitle2">Common</Typography>
              </Grid>
              <Grid item>
                <Tooltip title="Average value of the sum of minimal + average + maximal gaps for the week">
                  <QuestionIcon fontSize="small" />
                </Tooltip>
              </Grid>
            </Grid>
            <Typography variant="body2">{commonAverageGapForWeek()}</Typography>
          </Grid>
          <Grid item>
            <Grid item container alignItems="center" spacing={1}>
              <Grid item>
                <Typography variant="subtitle2">Minimal</Typography>
              </Grid>
              <Grid item>
                <Tooltip title="Average value of the sum of minimal gaps for the week">
                  <QuestionIcon fontSize="small" />
                </Tooltip>
              </Grid>
            </Grid>
            <Typography variant="body2">{minimalGapForWeek()}</Typography>
          </Grid>
          <Grid item>
            <Grid item container alignItems="center" spacing={1}>
              <Grid item>
                <Typography variant="subtitle2">Average</Typography>
              </Grid>
              <Grid item>
                <Tooltip title="Average value of the sum of average gaps for the week">
                  <QuestionIcon fontSize="small" />
                </Tooltip>
              </Grid>
            </Grid>
            <Typography variant="body2">{averageGapForWeek()}</Typography>
          </Grid>
          <Grid item>
            <Grid item container alignItems="center" spacing={1}>
              <Grid item>
                <Typography variant="subtitle2">Maximal</Typography>
              </Grid>
              <Grid item>
                <Tooltip title="Average value of the sum of maximal gaps for the week">
                  <QuestionIcon fontSize="small" />
                </Tooltip>
              </Grid>
            </Grid>
            <Typography variant="body2">{maximalGapForWeek()}</Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item container>
        <Box height="400px" ml={-2} width="calc(100% + 16px)" position="relative">
          {
            isInitialLoading && (
              <>
                <Box
                  position="absolute"
                  width="100%"
                  height="100%"
                  zIndex={1}
                  sx={{
                    background: "grey",
                    opacity: 0.1,
                  }}
                />
                <Box
                  position="absolute"
                  width="100%"
                  height="100%"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <CircularProgress color="primary" size={26} />
                </Box>
              </>
            )
          }
          <Chart options={chartOptions} data={getSensorBarChartData()} />
        </Box>
      </Grid>
    </Grid>
  )
}

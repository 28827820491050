import format from 'date-fns/format'
import { SensorState } from 'src/api'
import { SearchParamsKeys } from 'src/enums'

export const transformPage = (searchParams: Record<string, any>) => {
  const page = searchParams[SearchParamsKeys.Page]
  if (page === undefined) return searchParams
  const params = { ...searchParams }
  const perPage = params[SearchParamsKeys.RowsPerPage] || params.limit || '10'
  params.offset = `${(parseInt(page, 10) - 1) * parseInt(perPage, 10)}`
  delete params[SearchParamsKeys.Page]
  return params
}

export const transformPerPage = (searchParams: Record<string, any>) => {
  const perPage = searchParams[SearchParamsKeys.RowsPerPage]
  if (perPage === undefined) return searchParams
  const params = { ...searchParams }
  params.limit = `${perPage}`
  delete params[SearchParamsKeys.RowsPerPage]
  return params
}

export const transformSensorsState = (
  sensorStates: SensorState[],
) => (searchParams: Record<string, any>) => {
  const state = searchParams[SearchParamsKeys.State]
  if (state === undefined) return searchParams
  const params = { ...searchParams }
  const sensorState = sensorStates.find(
    (s) => s.name.split(' ').join('') === state,
  )
  if (sensorState !== undefined) params.stateId = sensorState?.id
  delete params[SearchParamsKeys.State]
  return params
}

export const transformAgeOfReadings = (value: string) => (searchParams: Record<string, any>) => {
  if (!value) return searchParams

  const valueArr = value.split(',')
  if (valueArr.length === 0) return searchParams

  const params = { ...searchParams }
  for (const item of valueArr) {
    params[item] = JSON.stringify({
      lt: format(new Date(new Date().setHours(new Date().getHours() - 1)), "yyyy-MM-dd'T'HH:mm:ssxxx"),
    })
  }
  delete params[SearchParamsKeys.AgeOfReadingsMoreThanHour]
  return params
}

export const transformSensorCurrentConfiguration = (
  value: string,
) => (searchParams: Record<string, any>) => {
  const params = { ...searchParams }
  if (!value || value === 'all') {
    delete params[SearchParamsKeys.CurrentSensorConfiguration]
  }

  return params
}

export const transformSearch = (
  name: string = SearchParamsKeys.Search,
) => (searchParams: Record<string, any>) => {
  const search = searchParams[SearchParamsKeys.Search]
  if (search === undefined) return searchParams
  const params = { ...searchParams }
  params[name] = search
  delete params[SearchParamsKeys.Search]
  return params
}

export const transformOneToList = (name: string) => (searchParams: Record<string, any>) => {
  const value = searchParams[name]
  if (value === undefined) return searchParams
  const params = { ...searchParams }
  params[`${name}s`] = [value]
  delete params[name]
  return params
}

export const transformBoolean = (
  from: SearchParamsKeys,
  to: SearchParamsKeys,
  value: any,
) => (searchParams: Record<string, any>) => {
  const exist = searchParams[from] !== undefined
  if (!exist) return searchParams
  const params = { ...searchParams }
  params[to] = [value]
  delete params[from]
  return params
}

export const transformSubscriptionStatus = () => (searchParams: Record<string, any>) => {
  const subscriptionStatus = searchParams[SearchParamsKeys.SubscriptionStatus]
  if (subscriptionStatus === undefined) return searchParams
  const params = { ...searchParams }
  if (subscriptionStatus === 'paidPlan') {
    params.isActivePaidPlan = 'true'
  }
  if (subscriptionStatus === 'trialIsActive') {
    params.isActiveTrial = 'true'
  }
  if (subscriptionStatus === 'trialIsEnded') {
    params.isActiveTrial = 'false'
  }
  delete params[SearchParamsKeys.SubscriptionStatus]
  return params
}

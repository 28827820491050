import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { Entities } from 'src/enums'
import { AggregatedUnstableSensor } from 'src/api'
import { useApiInstance } from './useApiInstance'

interface RequestParams {
  params?: {
    timeFrom?: string;
    timeTo?: string;
  };
  options?: Omit<
    UseQueryOptions<AggregatedUnstableSensor[], Error>,
    'queryKey' | 'queryFn'
  >;
}

export const useUnstableSensorsChartRequest = ({
  params = {},
  options,
}: RequestParams = {}) => {
  const api = useApiInstance()

  return useQuery<AggregatedUnstableSensor[], Error>(
    [Entities.Sensors, JSON.stringify(params)],
    async () => {
      const {
        timeFrom,
        timeTo,
      } = params
      const { data } = await api.v1AdminSensorsUnstableSensorsChartGet(timeFrom, timeTo)
      return data as AggregatedUnstableSensor[]
    },
    options,
  )
}

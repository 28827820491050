import React, { FC } from "react"
import * as Yup from "yup"
import { Button, Grid, Box, CircularProgress} from "@mui/material"
import { Formik, Form } from "formik"
import {
  TextField,
  PhoneInput,
  FormDropdown,
  RolesComplexDropdownItemFactory,
} from "src/components/ui"
import { UserEditing, UserRole } from "src/api"
import { useNavigate } from "react-router-dom"
import { phoneRequiredValidation } from "../../../utils/validators"
import { formatPhone } from "../../../utils/formatters"

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  phone: phoneRequiredValidation,
  roleId: Yup.mixed().required("User role is required"),
  email: Yup.string().required("Email is required").email("Email is invalid"),
})

export interface UserEditState extends UserEditing { }

const defaultInitial: Partial<UserEditState> = {
  name: void 0,
  roleId: void 0,
  phone: void 0,
  email: void 0,
  messageChannel: void 0,
}

interface UserEditFormProps {
  roles?: UserRole[];
  initialValues?: Partial<UserEditState> | null;
  onSubmit?: (formState: UserEditState) => void;
  isEditing?: boolean;
  messageChannels?: { displayName: string, value: string }[];
}

export const UserEditForm: FC<UserEditFormProps> = ({
  initialValues = {},
  roles = [],
  onSubmit = () => { },
  isEditing,
  messageChannels = [],
}) => {
  const navigate = useNavigate()

  return (
    <Formik
      initialValues={{ ...defaultInitial, ...initialValues }}
      onSubmit={(data) => {
        onSubmit({
          ...data,
          phone: formatPhone(data.phone) || "",
        } as UserEditState)
      }}
      validationSchema={validationSchema}
    >
      <Form>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <TextField name="name" label="Name" required />
          </Grid>
          <Grid item xs={12}>
            <FormDropdown
              list={roles}
              name="roleId"
              label="Role"
              required
              menuItemFactory={RolesComplexDropdownItemFactory({
                labelProp: "displayName",
                valueProp: "id",
              })}
            />
          </Grid>
          <Grid item xs={12}>
            <FormDropdown
              list={messageChannels}
              name="messageChannel"
              label="Sign in message channel"
              required
              menuItemFactory={RolesComplexDropdownItemFactory({
                labelProp: "displayName",
                valueProp: "value",
              })}
            />
          </Grid>
          <Grid item xs={12}>
            <PhoneInput name="phone" label="Phone number" required />
          </Grid>
          <Grid item xs={12}>
            <TextField name="email" label="Email" required />
          </Grid>
          <Grid container item xs={12}>
            <Grid item xs={3}>
              <Box pt={2}>
                <Button
                  size="large"
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={isEditing}
                >
                  {isEditing ? <CircularProgress color="info" size={26} /> : 'Save'}
                </Button>
              </Box>
            </Grid>
            <Grid item xs={3}>
              <Box pt={2}>
                <Button
                  size="large"
                  variant="outlined"
                  color="primary"
                  onClick={() => navigate(-1)}
                >
                  Cancel
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Form>
    </Formik>
  )
}

import React from 'react'
import { MenuItem, MenuItemProps as MenuItemPropsInterface } from '@mui/material'

export interface PrimitiveDropdownItemFactoryProps {
  MenuItemProps?: MenuItemPropsInterface;
}

export const PrimitiveDropdownItemFactory =
  ({ MenuItemProps }: PrimitiveDropdownItemFactoryProps = {}) =>
    (value: string | number, idx: number) =>
      (
        <MenuItem key={idx} {...MenuItemProps} value={value}>
          {value}
        </MenuItem>
      )

export interface ComplexDropdownItemFactoryProps {
  MenuItemProps?: MenuItemPropsInterface;
  labelProp: string;
  valueProp: string;
}

export const ComplexDropdownItemFactory =
  ({ MenuItemProps, labelProp, valueProp }: ComplexDropdownItemFactoryProps) =>
    (element: Record<string, any>, idx: number) => {
      const value = element[valueProp]
      const label = element[labelProp]
      return (
        <MenuItem key={idx} {...MenuItemProps} value={value}>
          {label}
        </MenuItem>
      )
    }

export const RolesComplexDropdownItemFactory =
  ({ MenuItemProps, labelProp, valueProp }: ComplexDropdownItemFactoryProps) =>
    (element: Record<string, any>, idx: number) => {
      const value = element[valueProp]
      const label = element[labelProp]
      if (label === "Admin") {
        return (
          <MenuItem key={idx} {...MenuItemProps} value={value}>
            {label} <b style={{ marginLeft: '5px' }}>(Do not use!)</b>
          </MenuItem>
        )
      }
      else {
        return (
          <MenuItem key={idx} {...MenuItemProps} value={value}>
            {label}
          </MenuItem>
        )
      }
    }

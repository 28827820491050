import React, { FC, PropsWithChildren } from 'react'
import { useSensorPastProjectListRequest } from 'src/hooks/api'
import { DataLoad, PropsInjector } from 'src/components'
import ProjectColumns from 'src/components/columns/project'
import { ProjectList } from '../project/projectList'

interface PastProjectsLoaderProps {
  sensorUniqueId: string;
}

const ProjectLoader: FC<PropsWithChildren<PastProjectsLoaderProps>> = ({ sensorUniqueId, children }) => {
  const { data, error, isInitialLoading, isError } = useSensorPastProjectListRequest({
    sensorUniqueId,
    params: { sort: 'id:desc' },
  })

  const props = {
    data: data || [],
    loading: isInitialLoading,
  }

  return (
    <DataLoad isLoading={false} isError={isError} errorMessage={error?.message}>
      <PropsInjector props={props}>{children}</PropsInjector>
    </DataLoad>
  )
}

interface SensorPastProjectsProps {
  noResultText?: string;
  showFooter?: boolean;
  sensorUniqueId: string;
}

export const SensorPastProjects: FC<SensorPastProjectsProps> = (
  props
) => {
  const { noResultText, showFooter, sensorUniqueId } = props
  const columns = [
    ProjectColumns.Name,
    ProjectColumns.Location,
    ProjectColumns.ContractorName,
    ProjectColumns.UserName,
    ProjectColumns.ProjectTypeName,
    ProjectColumns.Size,
    ProjectColumns.NumberOfActiveSensors,
    ProjectColumns.SensorStartDate(sensorUniqueId),
    ProjectColumns.SensorFinishDate(sensorUniqueId),
  ]
  return (
    <ProjectLoader sensorUniqueId={sensorUniqueId}>
      <ProjectList
        count={0}
        noResultText={noResultText}
        showFooter={showFooter}
        columns={columns}
      />
    </ProjectLoader>
  )
}

import { FC } from 'react'
import { Grid, Typography } from '@mui/material'
import { Table, DynamicColumn } from 'src/components/ui'
import { ProjectDashboardStatistics } from 'src/api'
import { ProjectDashboardColumns } from 'src/components/columns/dashboard'

interface ProjectDashboardProps {
  projectStatistics: ProjectDashboardStatistics;
}

const columns: DynamicColumn<ProjectDashboardStatistics>[] = [
  ProjectDashboardColumns.StatesNumber,
  ProjectDashboardColumns.ContractorsNumber,
  ProjectDashboardColumns.UsersNumber,
  ProjectDashboardColumns.ProjectsNumber,
  ProjectDashboardColumns.SectionsNumber,
]

export const ProjectDashboard: FC<ProjectDashboardProps> = (props) => {
  const { projectStatistics } = props
  return (
    <Grid container spacing={2}>
      <Grid item>
        <Typography variant="h5">Projects</Typography>
      </Grid>
      <Grid item container>
        <Table
          columns={columns}
          data={[projectStatistics]}
          showFooter={false}
          sx={{ width: '100%' }}
        />
      </Grid>
    </Grid>

  )
}

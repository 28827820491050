import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { Entities } from 'src/enums'
import { SensorState } from 'src/api'
import { useApiInstance } from './useApiInstance'

interface RequestParams {
  params?: {
    limit?: number;
    offset?: number;
    sort?: string;
  };
  options?: Omit<
    UseQueryOptions<unknown, Error, SensorState[]>,
    'queryKey' | 'queryFn'
  >;
}

export const useSensorStatesRequest = ({
  params = {},
  options,
}: RequestParams = {}) => {
  const api = useApiInstance()
  return useQuery<unknown, Error, SensorState[]>(
    [Entities.SensorState],
    async () => {
      const { limit, offset, sort } = params
      const { data } = await api.v1AdminSensorsStatesGet(limit, offset, sort)
      return data
    },
    { ...options },
  )
}

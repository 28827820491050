import { Box, List, ListItem, ListItemText, Typography } from "@mui/material"
import React, { FC } from "react"
import { CustomSingleEvent } from "src/api"
import format from "date-fns/format"
import { InnerLink } from "src/components/ui"
import { RapidRHPacketDecoder } from "./helpers"

interface CheckConnectivityInfoProps {
  event: CustomSingleEvent
}
interface RapidRHProbeData {
  label: string
  probeId: string
  isInRange: boolean
  signalFrom?: 'dataGrabber' | 'repeater' | 'both'
  advPacket?: {
    dataGrabber: string | null
    repeater: string | null
  }
  signalStrength?: {
    dataGrabber: number | null
    repeater: number | null
  }
}

export const CheckConnectivityInfo: FC<CheckConnectivityInfoProps> = ({ event }) => {
  if (!event.eventData) return null

  const { rapidRHProbes }: { rapidRHProbes?: RapidRHProbeData[] } = event.eventData
  if (!rapidRHProbes) return null

  const getDecodedData = (advPacket: string): { name: string, value: any }[] => {
    if (!advPacket) return []

    const decoder = new RapidRHPacketDecoder(advPacket as string)
    const data = decoder.transformRawDataToArray()
    const decodedData = [
      { value: decoder.getLastTemperatureF(data), name: 'Temperature, F' },
      { value: decoder.getLastHumidity(data), name: 'Humidity, %' },
      { value: format(decoder.getLastReadingTime(data), 'PPpp'), name: 'Reading time' },
      { value: decoder.getDataGrabberBatteryLevel(data) || '-', name: 'Data Grabber battery level, %' },
      { value: decoder.getRepeaterBatteryLevel(data) || '-', name: 'Repeater battery level, %' },
      { value: decoder.getDeviceType(data), name: 'Device type' },
    ]
    return decodedData
  }

  const renderProbeData = (probe: RapidRHProbeData): React.ReactNode => {
    const preparedData = [
      { name: 'Device ID', value: <InnerLink to={`/wagner-probes/${probe.probeId}`}>{probe.probeId}</InnerLink> },
      { name: 'In range', value: probe.isInRange === true ? 'Yes' : 'No' },
      { name: 'Signal from', value: probe.signalFrom === 'both' ? 'Data Grabber and Repeater' : (probe.signalFrom || '-') },
      { name: 'Signal strength, data grabber', value: probe.signalStrength?.dataGrabber || '-' },
      { name: 'Signal strength, repeater', value: probe.signalStrength?.repeater || '-' },
      {
        name: 'Raw packet, data grabber',
        value: probe.advPacket?.dataGrabber || '-',
        children: (): React.ReactNode => {
          if (!probe.advPacket?.dataGrabber) return null

          return (
            <List sx={{ pl: 2 }}>
              {getDecodedData(probe.advPacket.dataGrabber).map(({ name, value }, index) => (
                <ListItem key={index} divider>
                  <ListItemText
                    primary={<Typography variant="body2">{name}</Typography>}
                    secondary={value || '-'}
                  />
                </ListItem>
              ))}
            </List>
          )
        },
      },
      {
        name: 'Raw packet, repeater',
        value: probe.advPacket?.repeater || '-',
        children: (): React.ReactNode => {
          if (!probe.advPacket?.repeater) return null

          return (
            <List sx={{ pl: 2 }}>
              {getDecodedData(probe.advPacket.repeater).map(({ name, value }, index) => (
                <ListItem key={index} divider>
                  <ListItemText
                    primary={<Typography variant="body2">{name}</Typography>}
                    secondary={value || '-'}
                  />
                </ListItem>
              ))}
            </List>
          )
        },
      },
    ]

    return (
      <List>
        {preparedData.map(({ name, value, children }, index) => (
          <Box key={index}>
            <ListItem divider>
              <ListItemText
                primary={<Typography variant="body2">{name}</Typography>}
                secondary={value || '-'}
              />
            </ListItem>
            { typeof children === 'function' ? children() : null }
          </Box>
        ))}
      </List>
    )
  }

  return (
    <Box>
      <Typography variant="body1" fontWeight={500}>Rapid RH probes:</Typography>
      <List sx={{ pl: 2 }}>
        {rapidRHProbes.map((probe) => (
          <Box key={probe.probeId} py={1}>
            <Typography variant="body2">Label - {probe.label}</Typography>
            <ListItem sx={{ pl: 2 }}>
              {renderProbeData(probe)}
            </ListItem>
          </Box>
        ))}
      </List>
    </Box>
  )
}

import { ReleaseFeaturePayload } from '.'

export function hasDuplicates(features: ReleaseFeaturePayload[]) {
  const arrLength = features.length
  for (let i = 0; i < arrLength; i++) {
    const currentFeatureName = features[i].header.toLowerCase().replace(' ', '_')
    for (let j = i + 1; j < arrLength; j++) {
      const nextFeatureName = features[j].header.toLowerCase().replace(' ', '_')
      if (currentFeatureName === nextFeatureName) return true
    }
  }
  return false
}

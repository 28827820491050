import React, { useState, useRef, useCallback } from "react"
import { SearchParamsKeys, ViewTypes } from "src/enums"
import { Switch } from "src/components/ui"
import { useQueryParams } from "src/hooks/ui"
import { useQueryClient } from '@tanstack/react-query'

export const SwitchView = () => {
  const timer = useRef<NodeJS.Timeout | null>(null)
  const queryClient = useQueryClient()
  const [queryParams, setQueryParams] = useQueryParams()
  const [checked, setChecked] = useState(() => {
    const state = queryParams[SearchParamsKeys.ViewType] === ViewTypes.Map
    queryClient.setDefaultOptions({
      queries: {
        refetchOnWindowFocus: !state,
      },
    })
    return state
  })

  const onChange = useCallback(() => {
    const newState = !checked
    setChecked(newState)
    queryClient.setDefaultOptions({
      queries: {
        refetchOnWindowFocus: checked,
      },
    })
    if (timer.current) clearTimeout(timer.current)
    timer.current = setTimeout(() => {
      setQueryParams(
        {
          [SearchParamsKeys.ViewType]: newState
            ? ViewTypes.Map
            : ViewTypes.List,
        },
        Object.values(SearchParamsKeys)
      )
      timer.current = null
    }, 0)
  }, [checked, queryClient, setQueryParams])

  const props = {
    checked,
    value: checked,
    onChange,
  }

  return <Switch label="Show on map" name="show-on-map" SwitchProps={props} />
}

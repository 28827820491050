import { useMutation, UseMutationOptions } from '@tanstack/react-query'
import { useApiInstance } from './useApiInstance'

interface SensorTechnicalStatusRequestParams {
  options?: Omit<
  UseMutationOptions<boolean, Error | null, unknown, unknown>,
    'mutationKey' | 'mutationFn'
  >;
}

export const useSensorTechnicalStatusRequest = ({
  options,
}: SensorTechnicalStatusRequestParams = {}) => {
  const api = useApiInstance()

  return useMutation(
    async () => {
      const { data }: { data: unknown } = await api.v1AdminSensorsCalculateTechnicalStatusPost()
      return data as boolean
    },
    options,
  )
}

import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { Entities } from 'src/enums'
import { Session } from 'src/api'
import { useApiInstance } from './useApiInstance'

interface RequestParams {
  userId: number;
  options?: Omit<
    UseQueryOptions<unknown, Error, Session[]>,
    'queryKey' | 'queryFn'
  >;
}

export const useUserActiveSessionListRequest = ({
  userId,
  options = {},
}: RequestParams) => {
  const api = useApiInstance()

  return useQuery<unknown, Error, Session[]>(
    [Entities.UserSessions, userId],
    async () => {
      const { data } = await api.v1AdminUsersUserIdSessionsGet(userId)
      return data
    },
    {
      ...options,
    },
  )
}

import React, { FC } from 'react'
import { Project } from 'src/api'
import { Table } from 'src/components'
import ProjectColumns from 'src/components/columns/project'

const projectColumns = [
  ProjectColumns.ContractorName,
  ProjectColumns.Location,
  ProjectColumns.ProjectTypeName,
  ProjectColumns.Size,
  ProjectColumns.Status,
]

interface ProjectInfoProps {
  project: Project;
}

export const ProjectInfo: FC<ProjectInfoProps> = ({ project }) => {
  return (
    <Table
      columns={projectColumns}
      data={[project]}
      showFooter={false}
      sx={{ width: '100%' }}
    />
  )
}

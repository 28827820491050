import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { Entities } from 'src/enums'
import { Project } from 'src/api'
import { useApiInstance } from './useApiInstance'

interface RequestParams {
  id: number;
  increment?: number
  options?: Omit<
    UseQueryOptions<unknown, Error, Project>,
    'queryKey' | 'queryFn'
  >;
}

export const useProjectRequest = ({
  id,
  options,
}: RequestParams) => {
  const api = useApiInstance()

  return useQuery<unknown, Error, Project>(
    [Entities.Projects, id],
    async () => {
      const { data } = await api.v1AdminProjectsProjectIdGet(id)
      return data
    },
    {
      ...options,
    },
  )
}

import React, { FC } from 'react'
import { Table } from 'src/components'
import { AdminNotification, User } from 'src/api'
import { AdminNotificationColumns } from 'src/components/columns/user'
import { useUserAdminNotificationRequest } from 'src/hooks/api'
import { useAddPopupMessage } from 'src/hooks/ui'

const getColumns = () => [
  AdminNotificationColumns.NotificationName,
  AdminNotificationColumns.Active
]

interface AdminNotificationListProps {
  user: User
  notifications: AdminNotification[]
  updateUser: () => void
  showFooter?: boolean
}

export interface EnrichedUserAdminNotification extends AdminNotification {
  toggle: () => void
}

export const UserAdminNotificationList: FC<AdminNotificationListProps> = ({
  user,
  notifications,
  updateUser,
  showFooter = true,
}) => {
  const addMessage = useAddPopupMessage()
  const request = useUserAdminNotificationRequest({
    userId: user.id,
    options: {
      onSuccess: () => {
        updateUser()
      },
      onError: ({ message: text }) => {
        addMessage({ text, type: 'error' })
        updateUser()
      },
    },
  })
  const enrichedNotifications: EnrichedUserAdminNotification[] = notifications?.map<EnrichedUserAdminNotification>((notification) => ({
    ...notification,
    toggle: () => {
      request.mutate({
        notificationType: notification.notificationType,
        isActive: !notification.isActive
      })
      notification.isActive = !notification.isActive
    }
  })) || []
  return (
    <Table
      columns={getColumns()}
      data={enrichedNotifications}
      showFooter={showFooter}
    />
  )
}

import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { APIList } from 'src/interfaces'
import { Entities } from 'src/enums'
import { WagnerProbe } from 'src/api'
import { useApiInstance } from './useApiInstance'
import {
  buildRequestParams,
  ObjectType,
  transformPage,
  transformPerPage,
} from './helpers'

interface RequestParams {
  params?: Partial<AdminWagnerProbesGetParams>;
  paramsTransform?: Array<(params: Record<string, any>) => Record<string, any>>;
  options?: Omit<
    UseQueryOptions<unknown, Error, APIList<WagnerProbe>>,
    'queryKey' | 'queryFn'
  >;
}

interface AdminWagnerProbesGetParams {
  limit: number;
  offset: number;
  sort: string;
  contractorId: number;
  projectId?: number;
  projectSectionID?: number;
  connectedToProject?: boolean;
  search?: string;
}

const schema: ObjectType['schema'] = {
  limit: 'number',
  offset: 'number',
  sort: 'string',
  contractorId: 'number',
  projectId: 'number',
  projectSectionID: 'number',
  connectedToProject: 'boolean',
  search: 'string',
}

export const useWagnerProbeListRequest = ({
  params = {},
  paramsTransform = [],
  options,
}: RequestParams = {}) => {
  const api = useApiInstance()

  const transformedParams = [
    transformPerPage,
    transformPage,
    ...paramsTransform,
  ].reduce((param, transform) => transform(param), params)

  const queryParams = buildRequestParams<AdminWagnerProbesGetParams>(
    transformedParams,
    schema,
  )

  return useQuery<unknown, Error, APIList<WagnerProbe>>(
    [Entities.WagnerProbes, JSON.stringify(transformedParams)],
    async () => {
      const {
        limit,
        offset,
        sort,
        projectId,
        projectSectionID,
        contractorId,
        connectedToProject,
        search,
      } = queryParams
      const { data } = await api.v1AdminWagnerProbesGet(
        limit,
        offset,
        sort,
        contractorId,
        projectId,
        projectSectionID,
        connectedToProject,
        search,
      )

      return data
    },
    {
      enabled: Object.keys(transformedParams).length > 0,
      ...options,
    },
  )
}

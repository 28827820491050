import React, { FC, useState, useRef, useEffect } from "react"
import { useField } from "formik"
import { Autocomplete, AutocompleteProps as AutocompletePropsInterface, CircularProgress, TextField } from "@mui/material"

interface AutocompleteTextFieldProps {
  name: string;
  labelField?: string;
  valueField?: string;
  initialValue?: unknown;
  onInputChange?: (value: string) => void;
  options?: readonly any[];
  isLoading?: boolean;
  label?: string;
  width?: string | number;
  onChange?: (newValue: unknown) => void;
  placeholder?: string;
  required?: boolean;
  AutocompleteProps?: Omit<AutocompletePropsInterface<any, any, any, any>, 'options' | 'renderInput'>
}

export const AutocompleteTextField: FC<AutocompleteTextFieldProps> = ({
  name,
  label,
  onInputChange,
  labelField = '',
  valueField = '',
  options = [],
  isLoading = false,
  width = 300,
  onChange,
  initialValue = null,
  placeholder = "Start entering the name",
  required = false,
  AutocompleteProps = {},
}) => {
  const timerRef = useRef<NodeJS.Timeout | null>(null)
  const [loading, setLoading] = useState(false)
  const [autocompleteState, setAutocompleteState] = useState<unknown>(initialValue)
  const [textFieldValue, setTextFieldValue] = useState("")
  const [inputProps, { touched, error }] = useField(name)

  useEffect(() => {
    setAutocompleteState(initialValue)
  }, [initialValue])

  const createRequest = (value: string) => {
    setTextFieldValue(value)
    if (!loading) setLoading(true)
    if (timerRef.current) clearTimeout(timerRef.current)

    timerRef.current = setTimeout(() => {
      if (onInputChange) onInputChange(value)
      setLoading(false)
      timerRef.current = null
    }, 300)
  }

  const updateAutocompleteValue = (newValue: unknown) => {
    setAutocompleteState(newValue)
    let value: any = null
    if (Array.isArray(newValue)) {
      value = newValue
    } else {
      value = newValue === null ? newValue : (newValue as Record<string, any>)[valueField]
    }
    inputProps.onChange({ target: { value, name } })
    if (onChange) onChange(newValue)
  }

  return (
    <Autocomplete
      sx={{ width }}
      value={autocompleteState}
      isOptionEqualToValue={(option, value) => {
        return (option as Record<string, any>)[valueField] === (value as Record<string, any>)[valueField]
      }}
      onChange={(_, newValue) => updateAutocompleteValue(newValue)}
      getOptionLabel={(option) => (option as Record<string, any>)[labelField]}
      options={options}
      loading={isLoading || loading}
      {...AutocompleteProps}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          value={textFieldValue}
          sx={{ mt: 1, mb: 0.5 }}
          onChange={({ target: { value } }) => createRequest(value)}
          InputLabelProps={{ shrink: true }}
          required={required}
          error={touched && Boolean(error)}
          helperText={touched && error ? error : null}
          InputProps={{
            ...params.InputProps,
            placeholder,
            endAdornment: (
              <>
                {isLoading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  )
}

import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { Entities } from 'src/enums'
import { SystemMessageTransportType } from 'src/api'
import { useApiInstance } from './useApiInstance'

interface RequestParams {
  options?: Omit<
    UseQueryOptions<unknown, Error, SystemMessageTransportType[]>,
    'queryKey' | 'queryFn'
  >;
}

export const useMessageTransportTypeListRequest = ({
  options,
}: RequestParams = {}) => {
  const api = useApiInstance()

  return useQuery<unknown, Error, SystemMessageTransportType[]>(
    [Entities.SystemMessageTransportTypes],
    async () => {
      const { data } = await api.v1AdminSystemMessagesTransportTypesGet()
      return data
    },
    {
      ...options,
    },
  )
}

import { FC, useState } from "react"
import * as Yup from "yup"
import { Button, Grid, Box, CircularProgress } from "@mui/material"
import { Formik, Form } from "formik"
import { TextField, PhoneInput, FormCheckbox } from "src/components/ui"
import { AttachmentParams } from "src/api"
import { ImageUploadCard } from "./imageUploadCard"
import { phoneNullableValidation } from "../../../utils/validators"
import { formatPhone } from "../../../utils/formatters"

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  address: Yup.string(),
  phone: phoneNullableValidation,
  email: Yup.string().email("Email is invalid"),
})

export interface ManufacturerEditState {
  name: string;
  address1?: string;
  phone?: string | null;
  email?: string;
  isVerified: boolean;
  link?: string;
  photo?: AttachmentParams;
}
const defaultInitial: ManufacturerEditState = {
  name: "",
  address1: void 0,
  phone: void 0,
  email: void 0,
  isVerified: false,
  photo: undefined,
  link: ""
}

interface ManufacturerEditFormProps {
  initialValues?: Partial<ManufacturerEditState> | null;
  isLoading?: boolean;
  onSubmit: (formState: ManufacturerEditState, photo: File | null) => void;
  setIsRemoved: (value: boolean) => void;
}

export const ManufacturerEditForm: FC<ManufacturerEditFormProps> = ({
  initialValues,
  isLoading = false,
  onSubmit,
  setIsRemoved
}) => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null)

  const updateFile = (value: File | null) => {
    setSelectedFile(value)
  }

  return (
    <Formik
      initialValues={{ ...defaultInitial, ...initialValues }}
      onSubmit={(data) => {
        onSubmit({
          ...data,
          link: undefined,
          phone: formatPhone(data.phone) || null,
        },
        selectedFile,
        )
      }}
      validationSchema={validationSchema}
      enableReinitialize={true}
    >
      <Form>
        <Grid container spacing={2}>
          <Grid item xs={5}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <TextField name="name" label="Name" required />
              </Grid>
              <Grid item xs={12}>
                <TextField name="address1" label="Address" />
              </Grid>
              <Grid item xs={12}>
                <TextField name="email" label="Email" />
              </Grid>
              <Grid item xs={12}>
                <PhoneInput name="phone" label="Phone number" />
              </Grid>
              <Grid item xs={12}>
                <FormCheckbox name="isVerified" label="Is verified manufacturer" />
              </Grid>
              <Grid item xs={12}>
                <Box pt={2}>
                  <Button
                    size="large"
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={isLoading}
                  >
                    {
                      isLoading ? <CircularProgress color="info" size={26} /> : 'Save'
                    }
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={7}>
            <ImageUploadCard updateFile={updateFile} link={initialValues?.link} setIsRemoved={setIsRemoved}/>
          </Grid>
        </Grid>
      </Form>
    </Formik>
  )
}



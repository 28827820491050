import { useMutation, UseMutationOptions } from '@tanstack/react-query'
import { Entities } from 'src/enums'
import { ProductRequest, UpdateProductRequestPayload } from 'src/api'
import { useApiInstance } from './useApiInstance'

interface Payload {
  id: number
  payload: UpdateProductRequestPayload
}

interface RequestParams {
  options?: Omit<
    UseMutationOptions<ProductRequest, Error, Payload>,
    'mutationKey' | 'mutationFn'
  >;
}

export const useEditProductRequestRequest = ({ options }: RequestParams = {}) => {
  const api = useApiInstance()

  return useMutation<ProductRequest, Error, Payload>(
    [Entities.ProductRequest, 'edit'],
    async ({ id, payload }) => {
      const { data } = await api.v1AdminProductsRequestsProductRequestIdPut(
        id,
        payload,
      )
      return data as ProductRequest
    },
    options,
  )
}

import { useMutation, UseMutationOptions } from '@tanstack/react-query'
import { Entities } from 'src/enums'
import { useApiInstance } from './useApiInstance'

interface RequestParams {
  options?: Omit<
    UseMutationOptions<unknown, Error, { id: number }, boolean>,
    'mutationKey' | 'mutationFn'
  >;
}

export const useDeleteManufacturerAnnouncementTypeRequest = ({ options }: RequestParams = {}) => {
  const api = useApiInstance()

  return useMutation<unknown, Error, { id: number }, boolean>(
    [Entities.ManufacturerAnnouncementTypes, 'delete'],
    async ({ id }) => {
      const { data } = await api.v1AdminManufacturersAnnouncementsTypesTypeIdDelete(id)
      return data
    },
    { ...options },
  )
}

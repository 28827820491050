import { FC, useState, PropsWithChildren } from 'react'
import { ImagesViewerStateContext, ImagesListItem } from 'src/contexts'

export const ImagesViewerStateProvider: FC<PropsWithChildren> = ({ children }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [images, setImages] = useState<ImagesListItem[]>([])
  const [currentImageIndex, setCurrentImageIndex] = useState<number>(0)
  const openViewer = () => setIsOpen(true)
  const closeViewer = () => {
    setCurrentImageIndex(0)
    setIsOpen(false)
  }

  return (
    <ImagesViewerStateContext.Provider value={{
      isOpen,
      images,
      currentImageIndex,
      openViewer,
      closeViewer,
      setImages,
      setCurrentImageIndex,
    }}>
      {children}
    </ImagesViewerStateContext.Provider>
  )
}

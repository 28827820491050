import React, { FC, useState, PropsWithChildren } from 'react'
import { DialogStateContext } from 'src/contexts'
import { DialogNames } from 'src/enums'

export const DialogStateProvider: FC<PropsWithChildren> = ({ children }) => {
  const [dialogState, setDialogState] = useState({})
  const updateState = (name: DialogNames | string, state: boolean) => {
    setDialogState((prevState) => ({
      ...prevState,
      [name]: state,
    }))
  }

  return (
    <DialogStateContext.Provider value={[dialogState, updateState]}>
      {children}
    </DialogStateContext.Provider>
  )
}

import React, { FC } from 'react'
import {
  FormControlLabel,
  FormControlLabelProps as FormControlLabelPropsInterface,
  Checkbox as MuiCheckbox,
  CheckboxProps as MuiCheckboxProps,
} from '@mui/material'

export interface CheckboxProps {
  label: string;
  name: string;
  required?: boolean;
  disabled?: boolean;
  CheckboxProps?: Omit<MuiCheckboxProps, 'name' | 'required' | 'disabled'>;
  FormControlLabelProps?: Omit<
    FormControlLabelPropsInterface,
    'name' | 'checked' | 'value' | 'disabled' | 'control' | 'label'
  >;
}

export const Checkbox: FC<CheckboxProps> = ({
  label,
  name,
  required = false,
  disabled = false,
  CheckboxProps,
  FormControlLabelProps,
}) => {
  return (
    <FormControlLabel
      {...FormControlLabelProps}
      disabled={disabled}
      checked={CheckboxProps?.checked || false}
      value={CheckboxProps?.value || ''}
      name={name}
      label={label}
      control={
        <MuiCheckbox
          {...CheckboxProps}
          name={name}
          required={required}
          disabled={disabled}
        />
      }
    />
  )
}

import React, { FC, useCallback } from "react"
import { Grid, List, ListItem, ListItemText, Typography } from "@mui/material"
import format from "date-fns/format"
import { CustomSingleEvent } from "src/api"
import { InnerLink } from "src/components/ui"
import { RapidRHPacketDecoder } from "./helpers"

interface RapidRHConnectedInfoProps {
  event: CustomSingleEvent
}

export const RapidRHConnectedInfo: FC<RapidRHConnectedInfoProps> = ({ event }) => {
  const advPacket = event.eventData?.advPacket as unknown
  const dataGrabberSignalStrength = event.eventData?.dataGrabberSignalStrength as unknown
  const repeaterSignalStrength = event.eventData?.repeaterSignalStrength as unknown
  const sensorUniqueIds: string[] = useCallback(() => {
    const currentSection = (event.sections || []).find((section) => section.current)
    if (!currentSection) return []

    return (currentSection.sensors || [])
      .map((sensor) => sensor.sensorUniqueId || '')
      .filter((value) => Boolean(value))
  }, [event])()
  const decodedAdvPacket: { name: string, value: any }[] = useCallback(() => {
    if (!advPacket) return []

    const decoder = new RapidRHPacketDecoder(advPacket as string)
    const data = decoder.transformRawDataToArray()
    const decodedData = [
      { value: decoder.getLastTemperatureF(data), name: 'Temperature, F' },
      { value: decoder.getLastHumidity(data), name: 'Humidity, %' },
      { value: format(decoder.getLastReadingTime(data), 'PPpp'), name: 'Reading time' },
      { value: decoder.getDataGrabberBatteryLevel(data) || '-', name: 'Data Grabber battery level, %' },
      { value: decoder.getRepeaterBatteryLevel(data) || '-', name: 'Repeater battery level, %' },
      { value: decoder.getDeviceType(data), name: 'Device type' },
    ]
    return decodedData
  }, [advPacket])()

  return (
    <Grid container>
      <Grid item xs={12} sx={{ py: 1 }}>
        <Typography variant="body1" component="div">
          <Typography variant="body1" fontWeight={500} display="inline">Raw packet:</Typography> {advPacket as string || ''}
        </Typography>
      </Grid>
      <Grid item xs={12} sx={{ py: 1 }}>
        <Typography variant="body1" fontWeight={500}>Decoded data:</Typography>
        <List sx={{ py: 0 }}>
          {decodedAdvPacket.map(({ name, value }, index) => (
            <ListItem key={index} divider sx={{ pl: 2 }}>
              <ListItemText
                primary={<Typography variant="body2">{name}</Typography>}
                secondary={value}
              />
            </ListItem>
          ))}
        </List>
      </Grid>
      <Grid item xs={12} sx={{ py: 1 }}>
        <Typography variant="body1" component="div">
          <Typography variant="body1" fontWeight={500} display="inline">Rapid RH ID:</Typography> {event.wagnerProbeId || '-'}
        </Typography>
      </Grid>
      <Grid item xs={12} sx={{ py: 1 }}>
        <Typography variant="body1" component="div">
          <Typography variant="body1" fontWeight={500} display="inline">Rapid RH Probe Device ID: </Typography>
          {
            event.wagnerProbeDeviceId
              ? <InnerLink to={`/wagner-probes/${event.wagnerProbeDeviceId}`}>{event.wagnerProbeDeviceId}</InnerLink>
              : '-'
          }
        </Typography>
      </Grid>
      <Grid item xs={12} sx={{ py: 1 }}>
        <Typography variant="body1" component="div">
          <Typography variant="body1" fontWeight={500} display="inline">Data Grabber Signal Strength, dBm:</Typography> {dataGrabberSignalStrength as string || '-'}
        </Typography>
      </Grid>
      <Grid item xs={12} sx={{ py: 1 }}>
        <Typography variant="body1" component="div">
          <Typography variant="body1" fontWeight={500} display="inline">Repeater Signal Strength, dBm:</Typography> {repeaterSignalStrength as string || '-'}
        </Typography>
      </Grid>
      <Grid item xs={12} sx={{ py: 1 }}>
        <Typography variant="body1" fontWeight={500}>Sensors:</Typography>
        {
          sensorUniqueIds.length > 0
            ? (
              <List sx={{ py: 0 }}>
                {sensorUniqueIds.map((sensorUniqueId, index) => (
                  <ListItem key={index} divider sx={{ pl: 2 }}>
                    <ListItemText
                      primary="Unique ID"
                      secondary={<InnerLink to={`/sensors/${sensorUniqueId}`}>{sensorUniqueId}</InnerLink>}
                    />
                  </ListItem>
                ))}
              </List>
            )
            : <Typography variant="body2">No sensors</Typography>
        }
      </Grid>
    </Grid>
  )
}

import React, { FC } from "react"
import Grid from "@mui/material/Grid"
import { HasSidebarLayout } from "src/components"
import { ProductDraftListTable } from "src/components/sections/productDraft/table/table"
import { ProductDraftsProvider } from "../contexts/productDraftsContext"
import { ProductDraftListTitle } from "../components/sections/productDraft/title"
import { ProductDraftListActionsPanel } from "../components/sections/productDraft/actionsPanel"

const ProductDraftList: FC = () => {
  document.title = 'Floorcloud Admin Panel - Product drafts'
  return (
    <HasSidebarLayout>
      <ProductDraftsProvider>
        <Grid container flexDirection="column" width="100%" mb={3}>
          <Grid item>
            <ProductDraftListTitle />
          </Grid>
          <Grid item>
            <ProductDraftListActionsPanel />
          </Grid>
        </Grid>
        <ProductDraftListTable />
      </ProductDraftsProvider>
    </HasSidebarLayout>
  )
}

export default ProductDraftList

import { ProductImportStatusEnum } from "src/api"

export const getProductImportStatus = (status: ProductImportStatusEnum): string => {
  switch (status) {
  case ProductImportStatusEnum.Success:
    return "Success"
  case ProductImportStatusEnum.ErrorsDetected:
    return "Partially imported, errors detected"
  case ProductImportStatusEnum.Failed:
    return "Failure"
  default:
    return 'Unknown'
  }
}

import { FC, SyntheticEvent, useState } from "react"
import { Form, useFormik, FormikProvider } from "formik"
import * as Yup from "yup"
import { Autocomplete, Box, Button, CircularProgress, Divider, Grid, TextField as MuiTextField } from "@mui/material"
import { useQueryClient } from '@tanstack/react-query'
import { FormCheckbox, FormDropdown, TextField } from "src/components/ui"
import { useDeleteManufacturerAnnouncementRequest, useEditManufacturerAnnouncementRequest, useUploadPhotoRequest } from "src/hooks/api"
import { Manufacturer, ManufacturerAnnouncement, ManufacturerAnnouncementCategory, ManufacturerAnnouncementType, UpdateManufacturerAnnouncementPayload, UpdateManufacturerAnnouncementPostPayload, UploadPhotoResult } from "src/api"
import { useAddPopupMessage, useDialog, useDropdownItemFactory } from "src/hooks/ui"
import { useNavigate } from "react-router-dom"
import { DialogNames, Entities } from "src/enums"
import { DeleteManufacturerAnnouncementModal } from "src/components/sections"
import { ManufacturerAnnouncementPostPayload } from "./manufacturerAnnouncementNewForm"
import { ManufacturerAnnouncementPostNewForm } from "./manufacturerAnnouncementPostNewForm"
import { hasDuplicates } from "./helpers"

interface EditManufacturerAnnouncementPayload {
  name: string
  typeId: number
  categoryIds: number[]
  email?: string
  postName: string
  isVisibleContactMeButton: boolean
}

interface Props {
  announcement: ManufacturerAnnouncement
  announcementTypes: ManufacturerAnnouncementType[]
  announcementCategories: ManufacturerAnnouncementCategory[]
  manufacturers: Manufacturer[]
  searchManufacturer: (name: string) => void
}

const validationSchema = Yup.object().shape({
  name: Yup.string(),
  email: Yup.string().email('Please provide a valid email'),
  typeId: Yup.number().required('Post type is required'),
  categoryIds: Yup.array().of(Yup.number()).required('Category is required').min(1, 'Category is required'),
  postName: Yup.string(),
})

const initialValues = {
  name: "",
  email: "",
  typeId: 0,
  categoryIds: [],
  postName: "",
  isVisibleContactMeButton: true,
}

export const ManufacturerAnnouncementEditForm: FC<Props> = ({ announcement, announcementCategories, announcementTypes, manufacturers, searchManufacturer }) => {
  const [posts, setPosts] = useState<ManufacturerAnnouncementPostPayload[]>((announcement.posts || []).map((post) => ({
    id: post.id as number,
    title: post.title || '',
    description: post.description || '',
    videoLink: post.videoLink || '',
    link: post.photo || '',
  })) || [])
  const [selectedManufacturer, setSelectedManufacturer] = useState<Manufacturer | null>(announcement.manufacturer || null)
  const [lastUsedPostId, setLastUsedPostId] = useState<number>(0)
  const photoUploadRequest = useUploadPhotoRequest()
  const editManufacturerAnnouncementRequest = useEditManufacturerAnnouncementRequest({ id: announcement.id || 0 })
  const deleteManufacturerAnnouncementRequest = useDeleteManufacturerAnnouncementRequest({ id: announcement.id || 0 })
  const addMessage = useAddPopupMessage()
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const { openDialog: openDeleteDialog, closeDialog: closeDeleteDialog } = useDialog(DialogNames.DeleteManufacturerAnnouncement)
  const { openDialog: openCancelDialog } = useDialog(DialogNames.CancelManufacturerAnnouncement)

  const editAnnouncement = (data: UpdateManufacturerAnnouncementPayload) => {
    editManufacturerAnnouncementRequest.mutate({
      ...data,
      manufacturerId: selectedManufacturer ? selectedManufacturer.id : undefined,
      email: data.email || selectedManufacturer?.email || ''
    }, {
      onSuccess: () => {
        queryClient.invalidateQueries([Entities.ManufacturerAnnouncement, announcement.id])
        addMessage({ text: 'Manufacturer announcement was successfully edited', type: 'success' })
        navigate(-1)
      },
      onError: ({ message: text }) => {
        addMessage({ text, type: 'error' })
      }
    })
  }
  const onSubmit = async (data: EditManufacturerAnnouncementPayload) => {
    if (!selectedManufacturer) {
      addMessage({ text: "Please select a manufacturer", type: 'warning' })
      return
    }
    if (posts.length === 0) {
      addMessage({ text: "Please add at least one post", type: 'warning' })
      return
    }
    if (hasDuplicates(posts)) {
      addMessage({ text: "There are posts with the same names in the announcement", type: 'error' })
      return
    }

    let preparedPosts: UpdateManufacturerAnnouncementPostPayload[] = []
    const imagesToUpload: File[] = []

    for await (const post of posts) {
      if (post.photo) {
        imagesToUpload.push(post.photo)
      } else if (post.photo === null) {
        preparedPosts.push({
          title: post.title || '',
          description: post.description || '',
          videoLink: post.videoLink || '',
          image: {
            bucket: "",
            fileName: ""
          },
          ...(typeof post.id === 'string'
            ? {}
            : { id: post.id as number }
          )
        })
      } else {
        preparedPosts.push({
          ...(typeof post.id === 'string'
            ? {}
            : { id: post.id as number }
          ),
          title: post.title || '',
          description: post.description || '',
          videoLink: post.videoLink || '',
        })
      }
    }
    
    if (imagesToUpload.length > 0) {
      photoUploadRequest.mutate({
        photos: imagesToUpload,
      }, {
        onSuccess: (result) => {
          (result as UploadPhotoResult[]).forEach((image) => {
            const post = posts.find((item) => item.photo?.name === image.originalFileName)
            if (post) {
              preparedPosts.push({
                ...(typeof post.id === 'string'
                  ? {}
                  : { id: post.id as number }
                ),
                title: post.title,
                description: post.description,
                videoLink: post.videoLink || '',
                image: image.original,
              })
            }
          })

          editAnnouncement({
            id: announcement.id as number,
            name: data.name || '',
            typeId: data.typeId as number,
            categoryIds: data.categoryIds,
            isVisibleContactMeButton: data.isVisibleContactMeButton,
            posts: preparedPosts
          })
        }
      })
    } else {
      editAnnouncement({
        id: announcement.id as number,
        name: data.name || '',
        typeId: data.typeId as number,
        categoryIds: data.categoryIds,
        isVisibleContactMeButton: data.isVisibleContactMeButton,
        posts: preparedPosts
      })
    }
  }
  const formik = useFormik({
    initialValues: {
      ...initialValues,
      name: announcement.name || '',
      typeId: announcement.typeId as number,
      categoryIds: announcement.categoryIds || [],
      email: announcement.email || '',
      isVisibleContactMeButton: announcement.isVisibleContactMeButton as boolean,
    },
    onSubmit: onSubmit,
    validationSchema,
  })
  const { values, setValues, submitForm } = formik

  const getNewPostId = (): string => {
    const newPostId: number = lastUsedPostId + 1
    return `manual_id_${newPostId}`
  }

  const addPost = () => {
    const id = getNewPostId()
    const isDuplicate = posts.some((post) => {
      const preparedPostName = (post.title || '').toLowerCase().replace(' ', '_')
      return preparedPostName !== '' && values.postName !== '' && preparedPostName === values.postName
    })
    if (isDuplicate) {
      addMessage({ text: "The post with this name already exists", type: 'warning' })
      return
    }
    setPosts([
      ...posts,
      { id, title: values.postName || '', description: '', videoLink: '' }
    ])
    setValues({
      ...values,
      postName: ""
    })
    setLastUsedPostId(lastUsedPostId + 1)
  }
  const updatePost = (post: Partial<ManufacturerAnnouncementPostPayload>) => {
    const index = posts.findIndex((item) => item.id === post.id)
    if (index === -1) return

    const isDuplicate = posts.filter((item) => item.id !== post.id).some((item) => {
      const preparedPostName = (item.title || '').toLowerCase().replace(' ', '_')
      const preparedUpdatedPostName = (post.title || '').toLowerCase().replace(' ', '_')
      return preparedPostName !== '' && preparedUpdatedPostName !== '' && preparedPostName === preparedUpdatedPostName
    })
    if (isDuplicate) {
      addMessage({ text: "The post with this name already exists", type: 'warning' })
    }
    const copy = posts.slice()
    copy[index] = {
      ...copy[index],
      ...post,
    }
    setPosts(copy)
  }
  const deletePost = (id: string | number) => {
    const filteredPosts = posts.filter((post) => post.id !== id)
    setPosts(filteredPosts)
  }
  const deleteAnnouncement = () => {
    deleteManufacturerAnnouncementRequest.mutate({}, {
      onSuccess: () => {
        closeDeleteDialog()
        addMessage({ text: 'Announcement was successfully deleted', type: 'success' })
        navigate(-1)
      },
      onError: ({ message: text }) => {
        addMessage({ text, type: 'error' })
      }
    })
  }
  const dropdownItemListMap = useDropdownItemFactory({
    params: { labelProp: 'displayName', valueProp: 'id' },
  })
  const onChangeManufacturerHandler = (event: SyntheticEvent, manufacturer: Manufacturer | null) => {
    setSelectedManufacturer(manufacturer)
  }

  return (
    <FormikProvider
      value={formik}
    >
      <Form>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Autocomplete
              id="manufacturers"
              options={manufacturers || []}
              getOptionLabel={(option) => option.name || ''}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              style={{ width: 500 }}
              renderInput={(params) => (
                <MuiTextField
                  {...params}
                  label="Manufacturer"
                  placeholder="Manufacturer"
                />
              )}
              value={selectedManufacturer}
              onChange={onChangeManufacturerHandler}
              onInputChange={(_, newValue) => searchManufacturer(newValue)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              name="name"
              label="Main header"
              TextFieldProps={{ placeholder: "Main header" }}
            />
          </Grid>
          <Grid item container>
            <Grid item xs={12}>
              {posts.map((post) => (
                <ManufacturerAnnouncementPostNewForm
                  key={post.id}
                  defaultValues={post}
                  onChangePost={updatePost}
                  onDeletePost={deletePost}
                />
              ))}
            </Grid>
            <Grid
              item
              container
              alignItems="center"
              justifyContent="space-between"
              spacing={1}
              xs={12}
              position="relative"
            >
              <Grid item xs={12}>
                <TextField
                  name="postName"
                  label="Subheader"
                  TextFieldProps={{ placeholder: "Subheader" }}
                />
              </Grid>
              <Grid
                item
                xs={2}
                position="absolute"
                right={-100}
              >
                <Button
                  size="large"
                  variant="outlined"
                  color="primary"
                  onClick={addPost}
                >
                  Add
                </Button>
              </Grid>
            </Grid>
            <Grid item xs={12} my={5}>
              <Divider />
            </Grid>
            <DeleteManufacturerAnnouncementModal
              announcementName={announcement.name || ""}
              onCancel={() => closeDeleteDialog()}
              onDelete={deleteAnnouncement}
            />
          </Grid>
          <Grid item xs={12}>
            <FormDropdown
              name="typeId"
              label="Post type"
              list={announcementTypes}
              menuItemFactory={dropdownItemListMap}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <FormDropdown
              name="categoryIds"
              label="Category"
              list={announcementCategories}
              menuItemFactory={dropdownItemListMap}
              multiple
              required
            />
          </Grid>
          <Grid item xs={12}>
            <FormCheckbox
              name="isVisibleContactMeButton"
              label="Have A Local Rep Contact Me button"
              required
              view="switch"
            />
          </Grid>
          <Grid item xs={12} mt={5} mb={2}>
            <Divider />
          </Grid>
          <Grid item container xs={12}>
            <Grid item xs={3}>
              <Box>
                <Button
                  size="large"
                  variant="contained"
                  color="primary"
                  type="button"
                  onClick={submitForm}
                  disabled={photoUploadRequest.isLoading || editManufacturerAnnouncementRequest.isLoading}
                >
                  {
                    photoUploadRequest.isLoading || editManufacturerAnnouncementRequest.isLoading ? <CircularProgress color="info" size={26} /> : 'Save'
                  }
                </Button>
              </Box>
            </Grid>
            <Grid item xs={3}>
              <Box>
                <Button
                  size="large"
                  variant="outlined"
                  color="primary"
                  type="button"
                  onClick={openCancelDialog}
                >
                  Cancel
                </Button>
              </Box>
            </Grid>
            <Grid container item xs={6} justifyContent="end">
              <Box>
                <Button
                  size="large"
                  variant="text"
                  color="error"
                  type="button"
                  onClick={openDeleteDialog}
                >
                  Delete announcement
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Form>
    </FormikProvider>
  )
}

import { useMutation, UseMutationOptions } from '@tanstack/react-query'
import { Entities } from 'src/enums'
import { UpdateFirmwarePayload, WagnerDeviceFirmware } from 'src/api'
import { useApiInstance } from './useApiInstance'

interface RequestParams {
  id: number;
  options?: Omit<
    UseMutationOptions<WagnerDeviceFirmware, Error, UpdateFirmwarePayload>,
    'mutationKey' | 'mutationFn'
  >;
}

export const useEditWagnerDeviceFirmwareRequest = ({ id, options }: RequestParams) => {
  const api = useApiInstance()

  return useMutation<WagnerDeviceFirmware, Error, UpdateFirmwarePayload>(
    [Entities.WagnerDeviceFirmware, 'edit-wagner-device-firmware', id],
    async (payload: UpdateFirmwarePayload) => {
      const { data } = await api.v1AdminWagnerProbesFirmwaresFirmwareIdPut(id, payload)
      return data as WagnerDeviceFirmware
    },
    options,
  )
}

import React, { FC, useCallback } from "react"
import { SensorConfiguration, SensorDefaultConfiguration, SensorLastSentConfiguration } from "src/api"
import * as Yup from "yup"
import {
  Button,
  Grid,
  Box,
  Typography,
  Chip,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  CircularProgress,
} from "@mui/material"
import { useFormik, FormikProvider, Form } from 'formik'
import { TextField } from "src/components/ui"
import { useSensorEditConfiguration } from "src/hooks/ui"

interface SensorConfigurationProps {
  sensorConfiguration: SensorLastSentConfiguration | SensorDefaultConfiguration;
  onSubmit: (formState: SensorConfiguration) => void;
  isLoading: boolean;
  description?: string;
}

const validationSchema = Yup.object({
  firmware: Yup.string().nullable(),
  device: Yup.object().shape({
    commMaxAwakeDuration: Yup.number().nullable(),
    commMinAwakeDuration: Yup.number().nullable(),
    commSynchPeriod: Yup.number().nullable(),
    commTypeUpdate: Yup.string().nullable(),
  }).nullable(),
  ble: Yup.object({
    monitorPeriod: Yup.number().nullable(),
    opMode: Yup.number().nullable(),
    reportOffset: Yup.number().nullable(),
    reportPeriod: Yup.number().nullable(),
    reportType: Yup.number().nullable(),
    scanDuration: Yup.number().nullable(),
    minSigStr: Yup.number().nullable(),
    dataMode: Yup.number().nullable(),
    maxNumScan: Yup.number().nullable(),
    manufacturerId: Yup.number().nullable(),
  }).nullable(),
  temperature: Yup.object({
    monitorPeriod: Yup.number().nullable(),
    opMode: Yup.number().nullable(),
    reportOffset: Yup.number().nullable(),
    reportPeriod: Yup.number().nullable(),
    reportType: Yup.number().nullable(),
  }).nullable(),
  humidity: Yup.object({
    monitorPeriod: Yup.number().nullable(),
    opMode: Yup.number().nullable(),
    reportOffset: Yup.number().nullable(),
    reportPeriod: Yup.number().nullable(),
    reportType: Yup.number().nullable(),
  }).nullable(),
  pressure: Yup.object({
    monitorPeriod: Yup.number().nullable(),
    opMode: Yup.number().nullable(),
    reportOffset: Yup.number().nullable(),
    reportPeriod: Yup.number().nullable(),
    reportType: Yup.number().nullable(),
  }).nullable(),
  light: Yup.object({
    monitorPeriod: Yup.number().nullable(),
    opMode: Yup.number().nullable(),
    reportOffset: Yup.number().nullable(),
    reportPeriod: Yup.number().nullable(),
    reportType: Yup.number().nullable(),
  }).nullable(),
  location: Yup.object({
    monitorPeriod: Yup.number().nullable(),
    opMode: Yup.number().nullable(),
    reportOffset: Yup.number().nullable(),
    reportPeriod: Yup.number().nullable(),
    reportType: Yup.number().nullable(),
  }).nullable(),
  accelerometer: Yup.object({
    monitorPeriod: Yup.number().nullable(),
    opMode: Yup.number().nullable(),
    reportOffset: Yup.number().nullable(),
    reportPeriod: Yup.number().nullable(),
    reportType: Yup.number().nullable(),
  }).nullable(),
  gyro: Yup.object({
    monitorPeriod: Yup.number().nullable(),
    opMode: Yup.number().nullable(),
    reportOffset: Yup.number().nullable(),
    reportPeriod: Yup.number().nullable(),
    reportType: Yup.number().nullable(),
  }).nullable(),
  battery: Yup.object({
    monitorPeriod: Yup.number().nullable(),
    opMode: Yup.number().nullable(),
    reportOffset: Yup.number().nullable(),
    reportPeriod: Yup.number().nullable(),
    reportType: Yup.number().nullable(),
  }).nullable(),
  rfSignal: Yup.object({
    monitorPeriod: Yup.number().nullable(),
    opMode: Yup.number().nullable(),
    reportOffset: Yup.number().nullable(),
    reportPeriod: Yup.number().nullable(),
    reportType: Yup.number().nullable(),
  }).nullable()
})

export interface SensorConfigurationEditState extends SensorConfiguration {
  firmware?: string;
}
const defaultInitial: SensorConfigurationEditState = {
  firmware: void 0,
  device: {
    commMaxAwakeDuration: void 0,
    commMinAwakeDuration: void 0,
    commSynchPeriod: void 0,
    commTypeUpdate: '',
  },
  ble: {
    monitorPeriod: void 0,
    opMode: void 0,
    reportOffset: void 0,
    reportPeriod: void 0,
    reportType: void 0,
    scanDuration: void 0,
    minSigStr: void 0,
    dataMode: void 0,
    maxNumScan: void 0,
    manufacturerId: void 0,
  },
  temperature: {
    monitorPeriod: void 0,
    reportOffset: void 0,
    reportPeriod: void 0,
    reportType: void 0,
    opMode: void 0,
  },
  humidity: {
    monitorPeriod: void 0,
    reportOffset: void 0,
    reportPeriod: void 0,
    reportType: void 0,
    opMode: void 0,
  },
  pressure: {
    monitorPeriod: void 0,
    reportOffset: void 0,
    reportPeriod: void 0,
    reportType: void 0,
    opMode: void 0,
  },
  light: {
    monitorPeriod: void 0,
    reportOffset: void 0,
    reportPeriod: void 0,
    reportType: void 0,
    opMode: void 0,
  },
  location: {
    monitorPeriod: void 0,
    reportOffset: void 0,
    reportPeriod: void 0,
    reportType: void 0,
    opMode: void 0,
  },
  accelerometer: {
    monitorPeriod: void 0,
    reportOffset: void 0,
    reportPeriod: void 0,
    reportType: void 0,
    opMode: void 0,
  },
  gyro: {
    monitorPeriod: void 0,
    reportOffset: void 0,
    reportPeriod: void 0,
    reportType: void 0,
    opMode: void 0,
  },
  battery: {
    monitorPeriod: void 0,
    reportOffset: void 0,
    reportPeriod: void 0,
    reportType: void 0,
    opMode: void 0,
  },
  rfSignal: {
    monitorPeriod: void 0,
    reportOffset: void 0,
    reportPeriod: void 0,
    reportType: void 0,
    opMode: void 0,
  },
}

export const SensorConfigurationEdit: FC<SensorConfigurationProps> = ({ sensorConfiguration, onSubmit, isLoading, description }) => {
  const hasFirmwareConfiguration = useCallback(() => {
    return sensorConfiguration && typeof sensorConfiguration?.firmware === 'boolean'
  }, [sensorConfiguration])()
  const { makeSensorEditConfiguration, makeNullableEmptyValues } = useSensorEditConfiguration()

  const initialValues: SensorConfigurationEditState = makeSensorEditConfiguration(sensorConfiguration)

  const formik = useFormik({
    initialValues: { ...defaultInitial, ...initialValues },
    onSubmit: (data) => {
      const withNullableValues: SensorConfigurationEditState = makeNullableEmptyValues(data)
      delete withNullableValues.firmware
      onSubmit(withNullableValues)
    },
    validationSchema: validationSchema,
    enableReinitialize: true,
  })

  const { values, setFieldValue } = formik

  const opModeOptions = [
    { name: 'None', value: '' },
    { name: 'Disabled', value: 1 },
    { name: 'Enabled', value: 2 },
  ]
  
  const reportTypeOptions = [
    { name: 'None', value: '' },
    { name: 'Disabled', value: 1 },
    { name: 'One Time', value: 2 },
    { name: 'Periodic', value: 3 },
    { name: 'Periodic On Change', value: 4 },
  ]
  
  const bleDataModeOptions = [
    { name: 'None', value: '' },
    { name: 'BTM250T Advertisement Data', value: 1 },
    { name: 'Manufacturer Advertisement Packet', value: 2 },
    { name: 'BLE(X) Advertisement Data', value: 3 },
  ]

  const handleOpMode = (event: SelectChangeEvent, key: string) => {
    const { target: { value } } = event
    setFieldValue(
      `${key}.opMode`,
      value === '' ? null : parseInt(value, 10) - 1
    )
  }

  const handleReportType = (event: SelectChangeEvent, key: string) => {
    const { target: { value } } = event
    setFieldValue(
      `${key}.reportType`,
      value === '' ? null : parseInt(value, 10) - 1
    )
  }

  const handleBleDataMode = (event: SelectChangeEvent) => {
    const { target: { value } } = event
    setFieldValue(
      'ble.dataMode',
      value === '' ? null : parseInt(value, 10) - 1
    )
  }

  return (
    <FormikProvider value={formik}>
      <Form>
        <Grid container spacing={1}>
          <Grid item>
            <Typography variant="body2" sx={{ mb: 2 }}>{description}</Typography>
          </Grid>
          <Grid item container>
            <Typography variant="h5" sx={{ mb: 2 }}>General configuration</Typography>
            <Grid item xs={12}>
              <TextField name="device.commMaxAwakeDuration" label="Common maximum awake duration, sec" TextFieldProps={{
                type: "number"
              }} />
            </Grid>
            <Grid item xs={12}>
              <TextField name="device.commMinAwakeDuration" label="Common minimal awake duration, sec" TextFieldProps={{
                type: "number"
              }} />
            </Grid>
            <Grid item xs={12}>
              <TextField name="device.commSynchPeriod" label="Common synchronization period, sec" TextFieldProps={{
                type: "number"
              }} />
            </Grid>
            <Grid item xs={12}>
              <TextField name="device.commTypeUpdate" label="Common type update" />
            </Grid>
            {
              hasFirmwareConfiguration
                ? (
                  <Grid item xs={12}>
                    <TextField name="firmware" label="Status of the sensor firmware" TextFieldProps={{
                      disabled: true,
                    }} />
                  </Grid>
                ) : null
            }
          </Grid>
          <Grid item container>
            <Typography variant="h5" sx={{ mb: 2 }}>BLE configuration</Typography>
            <Grid item xs={12}>
              <TextField name="ble.reportPeriod" label="Report period, sec" TextFieldProps={{
                type: "number"
              }} />
            </Grid>
            <Grid item xs={12}>
              <TextField name="ble.monitorPeriod" label="Monitor period, sec" TextFieldProps={{
                type: "number"
              }} />
            </Grid>
            <Grid item xs={12}>
              <FormControl sx={{ width: 500, my: 1 }}>
                <InputLabel id="ble-operation-mode-chip-label" sx={{ fontSize: 14 }}>Operation mode</InputLabel>
                <Select
                  labelId="ble-operation-mode-chip-label"
                  id="ble-operation-mode-chip"
                  value={String(values.ble && values.ble.opMode !== null && values.ble.opMode !== undefined ? values.ble.opMode + 1 : '')}
                  onChange={(event: SelectChangeEvent) => handleOpMode(event, 'ble')}
                  input={<OutlinedInput id="ble-select-chip" label="Operation mode" />}
                  renderValue={(selected) => {
                    const opModeItem = opModeOptions.find((item) => String(item.value) === selected)
                    return (
                      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                        <Chip key={opModeItem?.value} label={opModeItem?.name} />
                      </Box>
                    )
                  }}
                >
                  {
                    opModeOptions.map((opModeItem) => (
                      <MenuItem
                        key={opModeItem?.value}
                        value={opModeItem?.value}
                      >
                        {opModeItem?.name}
                      </MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <TextField name="ble.reportOffset" label="Report offset, sec" TextFieldProps={{
                type: "number"
              }} />
            </Grid>
            <Grid item xs={12}>
              <FormControl sx={{ width: 500, my: 1 }}>
                <InputLabel id="report-type-chip-label" sx={{ fontSize: 14 }}>Report type</InputLabel>
                <Select
                  labelId="report-type-chip-label"
                  id="report-type-chip"
                  value={String(values.ble && values.ble.reportType !== null && values.ble.reportType !== undefined ? values.ble.reportType + 1 : '')}
                  onChange={(event: SelectChangeEvent) => handleReportType(event, 'ble')}
                  input={<OutlinedInput id="select-chip" label="Report type" />}
                  renderValue={(selected) => {
                    const reportTypeItem = reportTypeOptions.find((item) => String(item.value) === selected)
                    return (
                      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                        <Chip key={reportTypeItem?.value} label={reportTypeItem?.name} />
                      </Box>
                    )
                  }}
                >
                  {
                    reportTypeOptions.map((reportTypeItem) => (
                      <MenuItem
                        key={reportTypeItem?.value}
                        value={reportTypeItem?.value}
                      >
                        {reportTypeItem?.name}
                      </MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <TextField name="ble.scanDuration" label="Scan duration" TextFieldProps={{
                type: "number"
              }} />
            </Grid>
            <Grid item xs={12}>
              <TextField name="ble.minSigStr" label="Minimal signal strength" TextFieldProps={{
                type: "number"
              }} />
            </Grid>
            <Grid item xs={12}>
              <FormControl sx={{ width: 500, my: 1 }}>
                <InputLabel id="data-mode-chip-label" sx={{ fontSize: 14 }}>Data mode</InputLabel>
                <Select
                  labelId="data-mode-chip-label"
                  id="data-mode-chip"
                  value={String(values.ble && values.ble.dataMode !== null && values.ble.dataMode !== undefined ? values.ble.dataMode + 1 : '')}
                  onChange={(event: SelectChangeEvent) => handleBleDataMode(event)}
                  input={<OutlinedInput id="select-chip" label="Data mode" />}
                  renderValue={(selected) => {
                    const dataModeItem = bleDataModeOptions.find((item) => String(item.value) === selected)
                    return (
                      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                        <Chip key={dataModeItem?.value} label={dataModeItem?.name} />
                      </Box>
                    )
                  }}
                >
                  {
                    bleDataModeOptions.map((dataModeItem) => (
                      <MenuItem
                        key={dataModeItem?.value}
                        value={dataModeItem?.value}
                      >
                        {dataModeItem?.name}
                      </MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <TextField name="ble.maxNumScan" label="Maximum number of scan" TextFieldProps={{
                type: "number"
              }} />
            </Grid>
            <Grid item xs={12}>
              <TextField name="ble.manufacturerId" label="Manufacturer ID" TextFieldProps={{
                type: "number"
              }} />
            </Grid>
          </Grid>
          <Grid item container>
            <Typography variant="h5" sx={{ mb: 2 }}>Measurement values</Typography>
            <Grid item container>
              <Typography variant="h6" sx={{ mb: 2 }}>Temperature</Typography>
              <Grid item xs={12}>
                <TextField name="temperature.monitorPeriod" label="Monitor period, sec" TextFieldProps={{
                  type: "number"
                }} />
              </Grid>
              <Grid item xs={12}>
                <FormControl sx={{ width: 500, my: 1 }}>
                  <InputLabel id="temperature-operation-mode-chip-label" sx={{ fontSize: 14 }}>Operation mode</InputLabel>
                  <Select
                    labelId="temperature-operation-mode-chip-label"
                    id="temperature-operation-mode-chip"
                    value={String(values.temperature && values.temperature.opMode !== null && values.temperature.opMode !== undefined ? values.temperature.opMode + 1 : '')}
                    onChange={(event: SelectChangeEvent) => handleOpMode(event, 'temperature')}
                    input={<OutlinedInput id="temperature-select-chip" label="Operation mode" />}
                    renderValue={(selected) => {
                      const opModeItem = opModeOptions.find((item) => String(item.value) === selected)
                      return (
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                          <Chip key={opModeItem?.value} label={opModeItem?.name} />
                        </Box>
                      )
                    }}
                  >
                    {
                      opModeOptions.map((opModeItem) => (
                        <MenuItem
                          key={opModeItem?.value}
                          value={opModeItem?.value}
                        >
                          {opModeItem?.name}
                        </MenuItem>
                      ))
                    }
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <TextField name="temperature.reportOffset" label="Report offset, sec" TextFieldProps={{
                  type: "number"
                }} />
              </Grid>
              <Grid item xs={12}>
                <TextField name="temperature.reportPeriod" label="Report period, sec" TextFieldProps={{
                  type: "number"
                }} />
              </Grid>
              <Grid item xs={12}>
                <FormControl sx={{ width: 500, my: 1 }}>
                  <InputLabel id="temperature-report-type-chip-label" sx={{ fontSize: 14 }}>Report type</InputLabel>
                  <Select
                    labelId="temperature-report-type-chip-label"
                    id="temperature-report-type-chip"
                    value={String(values.temperature && values.temperature.reportType !== null && values.temperature.reportType !== undefined ? values.temperature.reportType + 1 : '')}
                    onChange={(event: SelectChangeEvent) => handleReportType(event, 'temperature')}
                    input={<OutlinedInput id="temperature-select-chip" label="Report type" />}
                    renderValue={(selected) => {
                      const reportTypeItem = reportTypeOptions.find((item) => String(item.value) === selected)
                      return (
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                          <Chip key={reportTypeItem?.value} label={reportTypeItem?.name} />
                        </Box>
                      )
                    }}
                  >
                    {
                      reportTypeOptions.map((reportTypeItem) => (
                        <MenuItem
                          key={reportTypeItem?.value}
                          value={reportTypeItem?.value}
                        >
                          {reportTypeItem?.name}
                        </MenuItem>
                      ))
                    }
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Grid item container>
              <Typography variant="h6" sx={{ my: 2 }}>Humidity</Typography>
              <Grid item xs={12}>
                <TextField name="humidity.monitorPeriod" label="Monitor period, sec" TextFieldProps={{
                  type: "number"
                }} />
              </Grid>
              <Grid item xs={12}>
                <FormControl sx={{ width: 500, my: 1 }}>
                  <InputLabel id="humidity-operation-mode-chip-label" sx={{ fontSize: 14 }}>Operation mode</InputLabel>
                  <Select
                    labelId="humidity-operation-mode-chip-label"
                    id="humidity-operation-mode-chip"
                    value={String(values.humidity && values.humidity.opMode !== null && values.humidity.opMode !== undefined ? values.humidity.opMode + 1 : '')}
                    onChange={(event: SelectChangeEvent) => handleOpMode(event, 'humidity')}
                    input={<OutlinedInput id="humidity-select-chip" label="Operation mode" />}
                    renderValue={(selected) => {
                      const opModeItem = opModeOptions.find((item) => String(item.value) === selected)
                      return (
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                          <Chip key={opModeItem?.value} label={opModeItem?.name} />
                        </Box>
                      )
                    }}
                  >
                    {
                      opModeOptions.map((opModeItem) => (
                        <MenuItem
                          key={opModeItem?.value}
                          value={opModeItem?.value}
                        >
                          {opModeItem?.name}
                        </MenuItem>
                      ))
                    }
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <TextField name="humidity.reportOffset" label="Report offset, sec" TextFieldProps={{
                  type: "number"
                }} />
              </Grid>
              <Grid item xs={12}>
                <TextField name="humidity.reportPeriod" label="Report period, sec" TextFieldProps={{
                  type: "number"
                }} />
              </Grid>
              <Grid item xs={12}>
                <FormControl sx={{ width: 500, my: 1 }}>
                  <InputLabel id="humidity-report-type-chip-label" sx={{ fontSize: 14 }}>Report type</InputLabel>
                  <Select
                    labelId="humidity-report-type-chip-label"
                    id="humidity-report-type-chip"
                    value={String(values.humidity && values.humidity.reportType !== null && values.humidity.reportType !== undefined ? values.humidity.reportType + 1 : '')}
                    onChange={(event: SelectChangeEvent) => handleReportType(event, 'humidity')}
                    input={<OutlinedInput id="humidity-select-chip" label="Report type" />}
                    renderValue={(selected) => {
                      const reportTypeItem = reportTypeOptions.find((item) => String(item.value) === selected)
                      return (
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                          <Chip key={reportTypeItem?.value} label={reportTypeItem?.name} />
                        </Box>
                      )
                    }}
                  >
                    {
                      reportTypeOptions.map((reportTypeItem) => (
                        <MenuItem
                          key={reportTypeItem?.value}
                          value={reportTypeItem?.value}
                        >
                          {reportTypeItem?.name}
                        </MenuItem>
                      ))
                    }
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Grid item container>
              <Typography variant="h6" sx={{ my: 2 }}>Pressure</Typography>
              <Grid item xs={12}>
                <TextField name="pressure.monitorPeriod" label="Monitor period, sec" TextFieldProps={{
                  type: "number"
                }} />
              </Grid>
              <Grid item xs={12}>
                <FormControl sx={{ width: 500, my: 1 }}>
                  <InputLabel id="pressure-operation-mode-chip-label" sx={{ fontSize: 14 }}>Operation mode</InputLabel>
                  <Select
                    labelId="pressure-operation-mode-chip-label"
                    id="pressure-operation-mode-chip"
                    value={String(values.pressure && values.pressure.opMode !== null && values.pressure.opMode !== undefined ? values.pressure.opMode + 1 : '')}
                    onChange={(event: SelectChangeEvent) => handleOpMode(event, 'pressure')}
                    input={<OutlinedInput id="pressure-select-chip" label="Operation mode" />}
                    renderValue={(selected) => {
                      const opModeItem = opModeOptions.find((item) => String(item.value) === selected)
                      return (
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                          <Chip key={opModeItem?.value} label={opModeItem?.name} />
                        </Box>
                      )
                    }}
                  >
                    {
                      opModeOptions.map((opModeItem) => (
                        <MenuItem
                          key={opModeItem?.value}
                          value={opModeItem?.value}
                        >
                          {opModeItem?.name}
                        </MenuItem>
                      ))
                    }
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <TextField name="pressure.reportOffset" label="Report offset, sec" TextFieldProps={{
                  type: "number"
                }} />
              </Grid>
              <Grid item xs={12}>
                <TextField name="pressure.reportPeriod" label="Report period, sec" TextFieldProps={{
                  type: "number"
                }} />
              </Grid>
              <Grid item xs={12}>
                <FormControl sx={{ width: 500, my: 1 }}>
                  <InputLabel id="pressure-report-type-chip-label" sx={{ fontSize: 14 }}>Report type</InputLabel>
                  <Select
                    labelId="pressure-report-type-chip-label"
                    id="pressure-report-type-chip"
                    value={String(values.pressure && values.pressure.reportType !== null && values.pressure.reportType !== undefined ? values.pressure.reportType + 1 : '')}
                    onChange={(event: SelectChangeEvent) => handleReportType(event, 'pressure')}
                    input={<OutlinedInput id="pressure-select-chip" label="Report type" />}
                    renderValue={(selected) => {
                      const reportTypeItem = reportTypeOptions.find((item) => String(item.value) === selected)
                      return (
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                          <Chip key={reportTypeItem?.value} label={reportTypeItem?.name} />
                        </Box>
                      )
                    }}
                  >
                    {
                      reportTypeOptions.map((reportTypeItem) => (
                        <MenuItem
                          key={reportTypeItem?.value}
                          value={reportTypeItem?.value}
                        >
                          {reportTypeItem?.name}
                        </MenuItem>
                      ))
                    }
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Grid item container>
              <Typography variant="h6" sx={{ my: 2 }}>Light</Typography>
              <Grid item xs={12}>
                <TextField name="light.monitorPeriod" label="Monitor period, sec" TextFieldProps={{
                  type: "number"
                }} />
              </Grid>
              <Grid item xs={12}>
                <FormControl sx={{ width: 500, my: 1 }}>
                  <InputLabel id="light-operation-mode-chip-label" sx={{ fontSize: 14 }}>Operation mode</InputLabel>
                  <Select
                    labelId="light-operation-mode-chip-label"
                    id="light-operation-mode-chip"
                    value={String(values.light && values.light.opMode !== null && values.light.opMode !== undefined ? values.light.opMode + 1 : '')}
                    onChange={(event: SelectChangeEvent) => handleOpMode(event, 'light')}
                    input={<OutlinedInput id="light-select-chip" label="Operation mode" />}
                    renderValue={(selected) => {
                      const opModeItem = opModeOptions.find((item) => String(item.value) === selected)
                      return (
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                          <Chip key={opModeItem?.value} label={opModeItem?.name} />
                        </Box>
                      )
                    }}
                  >
                    {
                      opModeOptions.map((opModeItem) => (
                        <MenuItem
                          key={opModeItem?.value}
                          value={opModeItem?.value}
                        >
                          {opModeItem?.name}
                        </MenuItem>
                      ))
                    }
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <TextField name="light.reportOffset" label="Report offset, sec" TextFieldProps={{
                  type: "number"
                }} />
              </Grid>
              <Grid item xs={12}>
                <TextField name="light.reportPeriod" label="Report period, sec" TextFieldProps={{
                  type: "number"
                }} />
              </Grid>
              <Grid item xs={12}>
                <FormControl sx={{ width: 500, my: 1 }}>
                  <InputLabel id="light-report-type-chip-label" sx={{ fontSize: 14 }}>Report type</InputLabel>
                  <Select
                    labelId="light-report-type-chip-label"
                    id="light-report-type-chip"
                    value={String(values.light && values.light.reportType !== null && values.light.reportType !== undefined ? values.light.reportType + 1 : '')}
                    onChange={(event: SelectChangeEvent) => handleReportType(event, 'light')}
                    input={<OutlinedInput id="light-select-chip" label="Report type" />}
                    renderValue={(selected) => {
                      const reportTypeItem = reportTypeOptions.find((item) => String(item.value) === selected)
                      return (
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                          <Chip key={reportTypeItem?.value} label={reportTypeItem?.name} />
                        </Box>
                      )
                    }}
                  >
                    {
                      reportTypeOptions.map((reportTypeItem) => (
                        <MenuItem
                          key={reportTypeItem?.value}
                          value={reportTypeItem?.value}
                        >
                          {reportTypeItem?.name}
                        </MenuItem>
                      ))
                    }
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Grid item container>
              <Typography variant="h6" sx={{ my: 2 }}>Location</Typography>
              <Grid item xs={12}>
                <TextField name="location.monitorPeriod" label="Monitor period, sec" TextFieldProps={{
                  type: "number"
                }} />
              </Grid>
              <Grid item xs={12}>
                <FormControl sx={{ width: 500, my: 1 }}>
                  <InputLabel id="location-operation-mode-chip-label" sx={{ fontSize: 14 }}>Operation mode</InputLabel>
                  <Select
                    labelId="location-operation-mode-chip-label"
                    id="location-operation-mode-chip"
                    value={String(values.location && values.location.opMode !== null && values.location.opMode !== undefined ? values.location.opMode + 1 : '')}
                    onChange={(event: SelectChangeEvent) => handleOpMode(event, 'location')}
                    input={<OutlinedInput id="location-select-chip" label="Operation mode" />}
                    renderValue={(selected) => {
                      const opModeItem = opModeOptions.find((item) => String(item.value) === selected)
                      return (
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                          <Chip key={opModeItem?.value} label={opModeItem?.name} />
                        </Box>
                      )
                    }}
                  >
                    {
                      opModeOptions.map((opModeItem) => (
                        <MenuItem
                          key={opModeItem?.value}
                          value={opModeItem?.value}
                        >
                          {opModeItem?.name}
                        </MenuItem>
                      ))
                    }
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <TextField name="location.reportOffset" label="Report offset, sec" TextFieldProps={{
                  type: "number"
                }} />
              </Grid>
              <Grid item xs={12}>
                <TextField name="location.reportPeriod" label="Report period, sec" TextFieldProps={{
                  type: "number"
                }} />
              </Grid>
              <Grid item xs={12}>
                <FormControl sx={{ width: 500, my: 1 }}>
                  <InputLabel id="location-report-type-chip-label" sx={{ fontSize: 14 }}>Report type</InputLabel>
                  <Select
                    labelId="location-report-type-chip-label"
                    id="location-report-type-chip"
                    value={String(values.location && values.location.reportType !== null && values.location.reportType !== undefined ? values.location.reportType + 1 : '')}
                    onChange={(event: SelectChangeEvent) => handleReportType(event, 'location')}
                    input={<OutlinedInput id="location-select-chip" label="Report type" />}
                    renderValue={(selected) => {
                      const reportTypeItem = reportTypeOptions.find((item) => String(item.value) === selected)
                      return (
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                          <Chip key={reportTypeItem?.value} label={reportTypeItem?.name} />
                        </Box>
                      )
                    }}
                  >
                    {
                      reportTypeOptions.map((reportTypeItem) => (
                        <MenuItem
                          key={reportTypeItem?.value}
                          value={reportTypeItem?.value}
                        >
                          {reportTypeItem?.name}
                        </MenuItem>
                      ))
                    }
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Grid item container>
              <Typography variant="h6" sx={{ my: 2 }}>Accelerometer</Typography>
              <Grid item xs={12}>
                <TextField name="accelerometer.monitorPeriod" label="Monitor period, sec" TextFieldProps={{
                  type: "number"
                }} />
              </Grid>
              <Grid item xs={12}>
                <FormControl sx={{ width: 500, my: 1 }}>
                  <InputLabel id="accelerometer-operation-mode-chip-label" sx={{ fontSize: 14 }}>Operation mode</InputLabel>
                  <Select
                    labelId="accelerometer-operation-mode-chip-label"
                    id="accelerometer-operation-mode-chip"
                    value={String(values.accelerometer && values.accelerometer.opMode !== null && values.accelerometer.opMode !== undefined ? values.accelerometer.opMode + 1 : '')}
                    onChange={(event: SelectChangeEvent) => handleOpMode(event, 'accelerometer')}
                    input={<OutlinedInput id="accelerometer-select-chip" label="Operation mode" />}
                    renderValue={(selected) => {
                      const opModeItem = opModeOptions.find((item) => String(item.value) === selected)
                      return (
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                          <Chip key={opModeItem?.value} label={opModeItem?.name} />
                        </Box>
                      )
                    }}
                  >
                    {
                      opModeOptions.map((opModeItem) => (
                        <MenuItem
                          key={opModeItem?.value}
                          value={opModeItem?.value}
                        >
                          {opModeItem?.name}
                        </MenuItem>
                      ))
                    }
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <TextField name="accelerometer.reportOffset" label="Report offset, sec" TextFieldProps={{
                  type: "number"
                }} />
              </Grid>
              <Grid item xs={12}>
                <TextField name="accelerometer.reportPeriod" label="Report period, sec" TextFieldProps={{
                  type: "number"
                }} />
              </Grid>
              <Grid item xs={12}>
                <FormControl sx={{ width: 500, my: 1 }}>
                  <InputLabel id="accelerometer-report-type-chip-label" sx={{ fontSize: 14 }}>Report type</InputLabel>
                  <Select
                    labelId="accelerometer-report-type-chip-label"
                    id="accelerometer-report-type-chip"
                    value={String(values.accelerometer && values.accelerometer.reportType !== null && values.accelerometer.reportType !== undefined ? values.accelerometer.reportType + 1 : '')}
                    onChange={(event: SelectChangeEvent) => handleReportType(event, 'accelerometer')}
                    input={<OutlinedInput id="accelerometer-select-chip" label="Report type" />}
                    renderValue={(selected) => {
                      const reportTypeItem = reportTypeOptions.find((item) => String(item.value) === selected)
                      return (
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                          <Chip key={reportTypeItem?.value} label={reportTypeItem?.name} />
                        </Box>
                      )
                    }}
                  >
                    {
                      reportTypeOptions.map((reportTypeItem) => (
                        <MenuItem
                          key={reportTypeItem?.value}
                          value={reportTypeItem?.value}
                        >
                          {reportTypeItem?.name}
                        </MenuItem>
                      ))
                    }
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Grid item container>
              <Typography variant="h6" sx={{ my: 2 }}>Gyroscope</Typography>
              <Grid item xs={12}>
                <TextField name="gyro.monitorPeriod" label="Monitor period, sec" TextFieldProps={{
                  type: "number"
                }} />
              </Grid>
              <Grid item xs={12}>
                <FormControl sx={{ width: 500, my: 1 }}>
                  <InputLabel id="gyro-operation-mode-chip-label" sx={{ fontSize: 14 }}>Operation mode</InputLabel>
                  <Select
                    labelId="gyro-operation-mode-chip-label"
                    id="gyro-operation-mode-chip"
                    value={String(values.gyro && values.gyro.opMode !== null && values.gyro.opMode !== undefined ? values.gyro.opMode + 1 : '')}
                    onChange={(event: SelectChangeEvent) => handleOpMode(event, 'gyro')}
                    input={<OutlinedInput id="gyro-select-chip" label="Operation mode" />}
                    renderValue={(selected) => {
                      const opModeItem = opModeOptions.find((item) => String(item.value) === selected)
                      return (
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                          <Chip key={opModeItem?.value} label={opModeItem?.name} />
                        </Box>
                      )
                    }}
                  >
                    {
                      opModeOptions.map((opModeItem) => (
                        <MenuItem
                          key={opModeItem?.value}
                          value={opModeItem?.value}
                        >
                          {opModeItem?.name}
                        </MenuItem>
                      ))
                    }
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <TextField name="gyro.reportOffset" label="Report offset, sec" TextFieldProps={{
                  type: "number"
                }} />
              </Grid>
              <Grid item xs={12}>
                <TextField name="gyro.reportPeriod" label="Report period, sec" TextFieldProps={{
                  type: "number"
                }} />
              </Grid>
              <Grid item xs={12}>
                <FormControl sx={{ width: 500, my: 1 }}>
                  <InputLabel id="gyro-report-type-chip-label" sx={{ fontSize: 14 }}>Report type</InputLabel>
                  <Select
                    labelId="gyro-report-type-chip-label"
                    id="gyro-report-type-chip"
                    value={String(values.gyro && values.gyro.reportType !== null && values.gyro.reportType !== undefined ? values.gyro.reportType + 1 : '')}
                    onChange={(event: SelectChangeEvent) => handleReportType(event, 'gyro')}
                    input={<OutlinedInput id="gyro-select-chip" label="Report type" />}
                    renderValue={(selected) => {
                      const reportTypeItem = reportTypeOptions.find((item) => String(item.value) === selected)
                      return (
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                          <Chip key={reportTypeItem?.value} label={reportTypeItem?.name} />
                        </Box>
                      )
                    }}
                  >
                    {
                      reportTypeOptions.map((reportTypeItem) => (
                        <MenuItem
                          key={reportTypeItem?.value}
                          value={reportTypeItem?.value}
                        >
                          {reportTypeItem?.name}
                        </MenuItem>
                      ))
                    }
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Grid item container>
              <Typography variant="h6" sx={{ my: 2 }}>Battery</Typography>
              <Grid item xs={12}>
                <TextField name="battery.monitorPeriod" label="Monitor period, sec" TextFieldProps={{
                  type: "number"
                }} />
              </Grid>
              <Grid item xs={12}>
                <FormControl sx={{ width: 500, my: 1 }}>
                  <InputLabel id="battery-operation-mode-chip-label" sx={{ fontSize: 14 }}>Operation mode</InputLabel>
                  <Select
                    labelId="battery-operation-mode-chip-label"
                    id="battery-operation-mode-chip"
                    value={String(values.battery && values.battery.opMode !== null && values.battery.opMode !== undefined ? values.battery.opMode + 1 : '')}
                    onChange={(event: SelectChangeEvent) => handleOpMode(event, 'battery')}
                    input={<OutlinedInput id="battery-select-chip" label="Operation mode" />}
                    renderValue={(selected) => {
                      const opModeItem = opModeOptions.find((item) => String(item.value) === selected)
                      return (
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                          <Chip key={opModeItem?.value} label={opModeItem?.name} />
                        </Box>
                      )
                    }}
                  >
                    {
                      opModeOptions.map((opModeItem) => (
                        <MenuItem
                          key={opModeItem?.value}
                          value={opModeItem?.value}
                        >
                          {opModeItem?.name}
                        </MenuItem>
                      ))
                    }
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <TextField name="battery.reportOffset" label="Report offset, sec" TextFieldProps={{
                  type: "number"
                }} />
              </Grid>
              <Grid item xs={12}>
                <TextField name="battery.reportPeriod" label="Report period, sec" TextFieldProps={{
                  type: "number"
                }} />
              </Grid>
              <Grid item xs={12}>
                <FormControl sx={{ width: 500, my: 1 }}>
                  <InputLabel id="battery-report-type-chip-label" sx={{ fontSize: 14 }}>Report type</InputLabel>
                  <Select
                    labelId="battery-report-type-chip-label"
                    id="battery-report-type-chip"
                    value={String(values.battery && values.battery.reportType !== null && values.battery.reportType !== undefined ? values.battery.reportType + 1 : '')}
                    onChange={(event: SelectChangeEvent) => handleReportType(event, 'battery')}
                    input={<OutlinedInput id="battery-select-chip" label="Report type" />}
                    renderValue={(selected) => {
                      const reportTypeItem = reportTypeOptions.find((item) => String(item.value) === selected)
                      return (
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                          <Chip key={reportTypeItem?.value} label={reportTypeItem?.name} />
                        </Box>
                      )
                    }}
                  >
                    {
                      reportTypeOptions.map((reportTypeItem) => (
                        <MenuItem
                          key={reportTypeItem?.value}
                          value={reportTypeItem?.value}
                        >
                          {reportTypeItem?.name}
                        </MenuItem>
                      ))
                    }
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Grid item container>
              <Typography variant="h6" sx={{ my: 2 }}>Signal Strength</Typography>
              <Grid item xs={12}>
                <TextField name="rfSignal.monitorPeriod" label="Monitor period, sec" TextFieldProps={{
                  type: "number"
                }} />
              </Grid>
              <Grid item xs={12}>
                <FormControl sx={{ width: 500, my: 1 }}>
                  <InputLabel id="rfSignal-operation-mode-chip-label" sx={{ fontSize: 14 }}>Operation mode</InputLabel>
                  <Select
                    labelId="rfSignal-operation-mode-chip-label"
                    id="rfSignal-operation-mode-chip"
                    value={String(values.rfSignal && values.rfSignal.opMode !== null && values.rfSignal.opMode !== undefined ? values.rfSignal.opMode + 1 : '')}
                    onChange={(event: SelectChangeEvent) => handleOpMode(event, 'rfSignal')}
                    input={<OutlinedInput id="rfSignal-select-chip" label="Operation mode" />}
                    renderValue={(selected) => {
                      const opModeItem = opModeOptions.find((item) => String(item.value) === selected)
                      return (
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                          <Chip key={opModeItem?.value} label={opModeItem?.name} />
                        </Box>
                      )
                    }}
                  >
                    {
                      opModeOptions.map((opModeItem) => (
                        <MenuItem
                          key={opModeItem?.value}
                          value={opModeItem?.value}
                        >
                          {opModeItem?.name}
                        </MenuItem>
                      ))
                    }
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <TextField name="rfSignal.reportOffset" label="Report offset, sec" TextFieldProps={{
                  type: "number"
                }} />
              </Grid>
              <Grid item xs={12}>
                <TextField name="rfSignal.reportPeriod" label="Report period, sec" TextFieldProps={{
                  type: "number"
                }} />
              </Grid>
              <Grid item xs={12}>
                <FormControl sx={{ width: 500, my: 1 }}>
                  <InputLabel id="rfSignal-report-type-chip-label" sx={{ fontSize: 14 }}>Report type</InputLabel>
                  <Select
                    labelId="rfSignal-report-type-chip-label"
                    id="rfSignal-report-type-chip"
                    value={String(values.rfSignal && values.rfSignal.reportType !== null && values.rfSignal.reportType !== undefined ? values.rfSignal.reportType + 1 : '')}
                    onChange={(event: SelectChangeEvent) => handleReportType(event, 'rfSignal')}
                    input={<OutlinedInput id="rfSignal-select-chip" label="Report type" />}
                    renderValue={(selected) => {
                      const reportTypeItem = reportTypeOptions.find((item) => String(item.value) === selected)
                      return (
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                          <Chip key={reportTypeItem?.value} label={reportTypeItem?.name} />
                        </Box>
                      )
                    }}
                  >
                    {
                      reportTypeOptions.map((reportTypeItem) => (
                        <MenuItem
                          key={reportTypeItem?.value}
                          value={reportTypeItem?.value}
                        >
                          {reportTypeItem?.name}
                        </MenuItem>
                      ))
                    }
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Box pt={2}>
              <Button
                size="large"
                variant="contained"
                color="primary"
                type="submit"
                disabled={isLoading}
              >
                {
                  isLoading ? <CircularProgress color="info" size={26} /> : 'Save'
                }
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Form>
    </FormikProvider>
  )
}

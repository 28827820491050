import { useMutation, UseMutationOptions } from '@tanstack/react-query'
import { Entities } from 'src/enums'
import { UserNotificationPayloadByProject, UserNotificationResponseItemByProject } from 'src/api'
import { useApiInstance } from './useApiInstance'

interface RequestParams {
  userId: number
  options?: Omit<
  UseMutationOptions<unknown, Error, UserNotificationPayloadByProject, UserNotificationResponseItemByProject[]>,
    'queryKey' | 'queryFn'
  >;
}

export const useSetupUserProjectNotificationsRequest = ({
  userId,
  options,
}: RequestParams) => {
  const api = useApiInstance()

  return useMutation<unknown, Error, UserNotificationPayloadByProject, UserNotificationResponseItemByProject[]>(
    [Entities.UserNotifications, 'update-by-project', userId],
    async (payload: UserNotificationPayloadByProject) => {
      const { data } = await api.v1AdminUsersUserIdNotificationsByProjectPut(userId, payload)
      return data
    },
    {
      ...options,
    },
  )
}

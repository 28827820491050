import { FC, useState } from "react"
import * as Yup from "yup"
import { Button, Grid, Box, CircularProgress } from "@mui/material"
import { Formik, Form } from "formik"
import { TextField, PhoneInput, FormCheckbox } from "src/components/ui"
import { ManufacturerCreation } from "src/api"
import { ImageUploadCard } from "./imageUploadCard"
import { formatPhone } from "../../../utils/formatters"
import { phoneNullableValidation } from "../../../utils/validators"

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  address: Yup.string(),
  phone: phoneNullableValidation,
  email: Yup.string().email("Email is invalid"),
})

export interface ManufacturerNewState extends ManufacturerCreation {
  photos?: Array<any> | null
}

const defaultInitial: ManufacturerNewState = {
  name: "",
  address1: void 0,
  phone: void 0,
  email: void 0,
  isVerified: false,
  photo: undefined,
  photos: []
}

interface ManufacturerNewFormProps {
  initialValues?: Partial<ManufacturerNewState> | null;
  isLoading?: boolean;
  onSubmit: (formState: ManufacturerNewState, logo: File | null) => void;
}

export const ManufacturerNewForm: FC<ManufacturerNewFormProps> = ({
  initialValues,
  isLoading = false,
  onSubmit,
}) => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null)

  const updateFile = (value: File | null) => {
    setSelectedFile(value)
  }

  return (
    <Formik
      initialValues={{ ...defaultInitial, ...initialValues }}
      onSubmit={(data) => {
        onSubmit({ ...data, phone: formatPhone(data?.phone)}, selectedFile)
      }}
      validationSchema={validationSchema}
    >
      <Form>
        <Grid container spacing={2}>
          <Grid item xs={5}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <TextField name="name" label="Name" required />
              </Grid>
              <Grid item xs={12}>
                <TextField name="address1" label="Address" />
              </Grid>
              <Grid item xs={12}>
                <TextField name="email" label="Email" />
              </Grid>
              <Grid item xs={12}>
                <PhoneInput name="phone" label="Phone number" />
              </Grid>
              <Grid item xs={12}>
                <FormCheckbox name="isVerified" label="Is verified manufacturer" />
              </Grid>
              <Grid item xs={12}>
                <Box pt={2}>
                  <Button
                    size="large"
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={isLoading}
                  >
                    {
                      isLoading ? <CircularProgress color="info" size={26} /> : 'Save'
                    }
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={7}>
            <ImageUploadCard updateFile={updateFile} />
          </Grid>
        </Grid>
      </Form>
    </Formik>
  )
}

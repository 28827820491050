import React, { FC } from "react"
import {
  HasSidebarLayout,
  ListHeader,
  TabGroupFilter,
  ManufacturerAnnouncementPostTypeList,
  ManufacturerAnnouncementCategoryList,
} from "src/components"
import { SearchParamsKeys } from "src/enums"

const getUserListTabs = () => [
  {
    status: "Post types",
    content: <ManufacturerAnnouncementPostTypeList />,
  },
  {
    status: "Categories",
    content: <ManufacturerAnnouncementCategoryList />,
  },
]

const SettingsTabs: FC = () => {
  const tabs = getUserListTabs()
  return <TabGroupFilter name={SearchParamsKeys.Tab} statusList={tabs} />
}

const SettingsList: FC = () => {
  document.title = 'Floorcloud Admin Panel - GangBox Settings'
  return (
    <HasSidebarLayout>
      <ListHeader title="GangBox Settings" />
      <SettingsTabs />
    </HasSidebarLayout>
  )
}

export default SettingsList

import React, { FC } from 'react'
import { Grid } from '@mui/material'
import { useFormik, FormikProvider, Form } from 'formik'
import {
  FormDropdown,
  ComplexDropdownItemFactory,
  AutocompleteTextField,
  FormCheckbox,
} from 'src/components/ui'
import { useQueryParams } from 'src/hooks/ui'
import { SearchParamsKeys } from 'src/enums'
import { ProjectType, Manufacturer } from 'src/api'

const factory = ComplexDropdownItemFactory({
  labelProp: 'name',
  valueProp: 'id',
})

interface ProductListFiltersProps {
  currentManufacturer?: Manufacturer | null;
  projectTypes?: ProjectType[];
  manufacturers?: Manufacturer[];
  manufacturersLoading?: boolean;
  findManufacturersByName?: (name: string) => void;
}

export const ProductListFilters: FC<ProductListFiltersProps> = ({
  projectTypes = [],
  manufacturers = [],
  manufacturersLoading = false,
  findManufacturersByName = () => {},
  currentManufacturer = null,
}) => {
  const [queryParams, setQueryParams] = useQueryParams()

  const formik = useFormik({
    initialValues: {
      [SearchParamsKeys.Manufacturer]:
        queryParams[SearchParamsKeys.Manufacturer] || null,
      [SearchParamsKeys.Type]: queryParams[SearchParamsKeys.Type] || null,
      [SearchParamsKeys.NoShelfLife]:
        queryParams[SearchParamsKeys.Type] === 'true',
      [SearchParamsKeys.NoRanges]:
        queryParams[SearchParamsKeys.NoRanges] === 'true',
      [SearchParamsKeys.NoTips]:
        queryParams[SearchParamsKeys.NoTips] === 'true',
      [SearchParamsKeys.InUse]:
        queryParams[SearchParamsKeys.InUse] === 'true',
    },
    onSubmit: async (formState) => {
      const type = formState[SearchParamsKeys.Type] || null
      const manufacturer = formState[SearchParamsKeys.Manufacturer] || null
      const noShelfLife = formState[SearchParamsKeys.NoShelfLife] || false
      const noRanges = formState[SearchParamsKeys.NoRanges] || false
      const noTips = formState[SearchParamsKeys.NoTips] || false
      const inUse = formState[SearchParamsKeys.InUse] || false
      await setQueryParams(
        {
          ...(type ? { [SearchParamsKeys.Type]: `${type}` } : {}),
          ...(manufacturer
            ? { [SearchParamsKeys.Manufacturer]: `${manufacturer}` }
            : {}),
          ...(noShelfLife ? { noShelfLife: `${noShelfLife}` } : {}),
          ...(noRanges ? { noRanges: `${noRanges}` } : {}),
          ...(noTips ? { noTips: `${noTips}` } : {}),
          ...(inUse ? { inUse: `${inUse}` } : {}),
        },
        [
          SearchParamsKeys.Type,
          SearchParamsKeys.Manufacturer,
          SearchParamsKeys.NoShelfLife,
          SearchParamsKeys.NoRanges,
          SearchParamsKeys.NoTips,
          SearchParamsKeys.InUse,
        ]
      )
    },
  })

  const { submitForm } = formik

  return (
    <FormikProvider value={formik}>
      <Form>
        <Grid container alignItems="center" spacing={4}>
          <Grid item>
            <AutocompleteTextField
              label="Manufacturer"
              name={SearchParamsKeys.Manufacturer}
              labelField="name"
              valueField="id"
              options={manufacturers}
              onInputChange={findManufacturersByName}
              isLoading={manufacturersLoading}
              onChange={submitForm}
              initialValue={currentManufacturer}
            />
          </Grid>
          <Grid item>
            <FormDropdown
              label="Type"
              menuItemFactory={factory}
              list={[{ id: null, name: 'All' }, ...projectTypes]}
              name={SearchParamsKeys.Type}
              SelectProps={{ sx: { width: 200 }, defaultValue: null }}
              onChange={submitForm}
            />
          </Grid>
          <Grid item>
            <FormCheckbox
              label="No Shelf Life"
              name={SearchParamsKeys.NoShelfLife}
              onChange={submitForm}
            />
          </Grid>
          <Grid item>
            <FormCheckbox
              label="No Tips"
              name={SearchParamsKeys.NoTips}
              onChange={submitForm}
            />
          </Grid>
          <Grid item>
            <FormCheckbox
              label="No Ranges"
              name={SearchParamsKeys.NoRanges}
              onChange={submitForm}
            />
          </Grid>
          <Grid item>
            <FormCheckbox
              label="In Use"
              name={SearchParamsKeys.InUse}
              onChange={submitForm}
            />
          </Grid>
        </Grid>
      </Form>
    </FormikProvider>
  )
}

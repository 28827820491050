import { FC, useState, useMemo } from 'react'
import Paper from '@mui/material/Paper'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { DataGridPremium, GridColDef, GridPaginationModel, useGridApiRef } from '@mui/x-data-grid-premium'
import ImagesViewer from "react-images-viewer"
import { Event, EventTypeName } from 'src/api'
import { useEventListRequest, useEventTypeListRequest } from 'src/hooks/api'
import { TableToolbar } from 'src/components/ui'
import { useImagesViewer } from 'src/hooks/ui'
import { ImagesViewerStateProvider } from 'src/components/providers'
import { defaultRowsValuePerPage } from 'src/config'
import { EventColumns } from '../event'

type SensorEventsProps = {
  events: Event[]
  eventCount: number
  isLoading: boolean
  page: number
  pageSize: number
  setPage: (page: number) => void
  setPageSize: (pageSize: number) => void
}

const defaultRowsPerPageOptions = defaultRowsValuePerPage

const SensorEvents: FC<SensorEventsProps> = (props) => {
  const { events, isLoading, eventCount, page, pageSize, setPage, setPageSize } = props
  const apiRef = useGridApiRef()
  const {
    isOpen: isOpenedGallery,
    images,
    currentImageIndex,
    openGallery,
    closeGallery,
    goToNext,
    goToPrev,
  } = useImagesViewer()
  const columns = useMemo((): GridColDef<Event>[] => ([
    EventColumns.Name,
    EventColumns.Contractor,
    EventColumns.Project,
    EventColumns.Section,
    EventColumns.Author,
    EventColumns.CreatedAt,
    EventColumns.Location,
    EventColumns.Device,
    EventColumns.AppVersion,
    EventColumns.Photo(openGallery),
  ]), [openGallery])

  const handleChangePaginationModel = async (pagination: GridPaginationModel): Promise<void> => {
    setPage(pagination.page)
    setPageSize(pagination.pageSize)
  }

  return (
    <>
      <Grid container spacing={2} alignItems="center" mb={1}>
        <Grid item>
          <Typography variant="h6">
            Events
          </Typography>
        </Grid>
      </Grid>
      <Paper sx={{ height: '500px' }}>
        <DataGridPremium<Event>
          apiRef={apiRef}
          pagination
          autoHeight={false}
          disableRowSelectionOnClick
          rows={events || []}
          columns={columns}
          rowCount={eventCount || 0}
          loading={isLoading}
          paginationModel={{
            page: page as number,
            pageSize: pageSize as number
          }}
          rowThreshold={2}
          columnThreshold={2}
          paginationMode="server"
          pageSizeOptions={defaultRowsPerPageOptions}
          onPaginationModelChange={handleChangePaginationModel}
          localeText={{
            columnMenuSortAsc: "Sort A-Z",
            columnMenuSortDesc: "Sort Z-A",
          }}
          slots={{
            toolbar: () => <TableToolbar exportedFileName={`${document.title}_events`} />
          }}
          sx={(theme) => ({
            border: "none",
            "& .MuiDataGrid-columnHeaderTitleContainerContent": {
              paddingLeft: "5px",
              overflow: "visible",
            },
            "& .MuiDataGrid-columnHeaderTitle": {
              whiteSpace: "normal",
              lineHeight: 1,
              fontSize: "13px",
              fontWeight: 400,
              color: "rgba(0, 0, 0, 0.6)",
              overflow: "visible",
            },
          })}
        />
      </Paper>
      <ImagesViewer
        imgs={images}
        isOpen={isOpenedGallery}
        onClickPrev={goToPrev}
        onClickNext={goToNext}
        onClose={closeGallery}
        currImg={currentImageIndex}
      />
    </>
  )
}

const SensorEventsLoadable: FC<{ sensorId: number }> = ({ sensorId }) => {
  const [page, setPage] = useState<number>(0)
  const [pageSize, setPageSize] = useState<number>(defaultRowsPerPageOptions[0])
  const { data: eventTypesResponse, isLoading: isLoadingEventTypes } = useEventTypeListRequest({
    params: { limit: 1000 }
  })
  const eventTypes = eventTypesResponse?.rows || []
  const requiredEventTypes = eventTypes.filter(
    (eventType) => ([EventTypeName.SensorConnected, EventTypeName.SensorDisconnected, EventTypeName.CheckConnectivity] as string[]).includes(eventType.name)
  ).map((eventType) => eventType.id)
  const { data: eventsResponse, isInitialLoading } = useEventListRequest({
    params: {
      limit: pageSize,
      offset: page * pageSize,
      sort: 'createdAt:desc',
      sensorId,
      eventTypes: requiredEventTypes.join(','),
    },
    options: {
      enabled: !isLoadingEventTypes,
    }
  })

  return (
    <ImagesViewerStateProvider>
      <SensorEvents
        events={eventsResponse?.rows || []}
        eventCount={eventsResponse?.count || 0}
        isLoading={isInitialLoading}
        page={page}
        pageSize={pageSize}
        setPage={setPage}
        setPageSize={setPageSize}
      />
    </ImagesViewerStateProvider>
  )
}

export {
  SensorEventsLoadable as SensorEvents,
}

import { TableCell } from "@mui/material"
import { format } from "date-fns"
import React from "react"
import { WagnerProbe } from "src/api"
import { DynamicColumn } from "src/components/ui"
import { capitalizeFirstLetter } from "src/utils/formatters"
import { AppWagnerProbeReading } from "../sections/wagnerProbes"

export namespace WagnerProbeColumns {
  export const ProbeID: DynamicColumn<WagnerProbe> = {
    field: "probeId",
    headerName: "Probe ID",
    type: "string",
  }

  export const Label: DynamicColumn<WagnerProbe> = {
    field: "label",
    headerName: "Label",
    type: "string",
  }

  export const Project: DynamicColumn<WagnerProbe> = {
    field: "project.name",
    headerName: "Project",
    type: "string",
    innerLink: (row) => `/projects/${row.project?.id}`,
  }

  export const ProjectSection: DynamicColumn<WagnerProbe> = {
    field: "projectSection.name",
    headerName: "Project Section",
    type: "string",
    innerLink: (row) => `/sections/${row.projectSection?.id}`,
  }

  export const Contractor: DynamicColumn<WagnerProbe> = {
    field: "contractor.name",
    headerName: "Contractor",
    type: "string",
    innerLink: (row) => `/contractors/${row.contractor?.id}`,
  }

  export const VerizonIMEI: DynamicColumn<WagnerProbe> = {
    field: "currentData.verizonGatId",
    headerName: "Sensor",
    type: "string",
    innerLink: (row) => row.verizonSensor?.uniqueId ? `/sensors/${row.verizonSensor?.uniqueId}` : '',
  }

  export const LastDeviceType: DynamicColumn<WagnerProbe> = {
    field: "currentData.deviceType",
    headerName: "Last Device Type",
    type: "custom",
    component: ({ row, cellProps }) => {
      const value = row.currentData?.deviceType ? capitalizeFirstLetter(row.currentData?.deviceType).replace("_", " ") : "-"
      return <TableCell align={cellProps?.align}>{value}</TableCell>
    },
  }
}

export namespace WagnerProbeLastReadingsColumns {
  export const ReadingName: DynamicColumn<AppWagnerProbeReading> = {
    field: "name",
    headerName: "Reading",
    type: "string",
  }

  export const ReadingValue: DynamicColumn<AppWagnerProbeReading> = {
    field: "value",
    headerName: "Value",
    type: "digit",
  }

  export const ReadingTime: DynamicColumn<AppWagnerProbeReading> = {
    field: "readingTime",
    headerName: "Reading Time",
    type: "date",
    format: (value) => (value ? format(new Date(value), "PPpp") : ""),
  }
}

import { FC } from "react"
import { Typography, Tooltip } from "@mui/material"
import { GridRenderCellParams } from "@mui/x-data-grid-premium"

interface Props extends GridRenderCellParams<any, any> {}

export const RequestInlineCell: FC<Props> = ({
  value,
}) => {
  return (
    <Tooltip title={value}>
      <Typography variant="body2" textOverflow="ellipsis" overflow="hidden">{value}</Typography>
    </Tooltip>
  )
}



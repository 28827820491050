import { FC } from 'react'
import { Grid, Typography } from '@mui/material'
import { Table, DynamicColumn } from 'src/components/ui'
import { UsageDashboardStatistics } from 'src/api'
import { UsageStatisticsDashboardColumns } from 'src/components/columns/dashboard'

interface UsageStatisticsDashboardProps {
  usageStatistics: UsageDashboardStatistics;
}

const columns1: DynamicColumn<UsageDashboardStatistics>[] = [
  UsageStatisticsDashboardColumns.MonitoredSectionsNumber,
  UsageStatisticsDashboardColumns.MonitoredHoursNumber,
  UsageStatisticsDashboardColumns.AlertsNumber,
  UsageStatisticsDashboardColumns.SharedData,
  UsageStatisticsDashboardColumns.ReportsNumber,
  UsageStatisticsDashboardColumns.NotesNumber,
  UsageStatisticsDashboardColumns.PhotosNumber,
]

const columns2: DynamicColumn<UsageDashboardStatistics>[] = [
  UsageStatisticsDashboardColumns.TasksNumber,
  UsageStatisticsDashboardColumns.InstalledProductsNumber,
]

export const UsageStatisticsDashboard: FC<UsageStatisticsDashboardProps> = (props) => {
  const { usageStatistics } = props
  return (
    <Grid container spacing={2}>
      <Grid item>
        <Typography variant="h5">Usage Statistics</Typography>
      </Grid>
      <Grid item container>
        <Table
          columns={columns1}
          data={[usageStatistics]}
          showFooter={false}
          sx={{ width: '100%' }}
        />
      </Grid>
      <Grid item container>
        <Table
          columns={columns2}
          data={[usageStatistics]}
          showFooter={false}
          sx={{ width: '100%' }}
        />
      </Grid>
    </Grid>

  )
}

import { useMutation, UseMutationOptions } from '@tanstack/react-query'
import { Entities } from 'src/enums'
import { ProjectType, CreateProjectTypePayload } from 'src/api'
import { useApiInstance } from './useApiInstance'

interface RequestParams {
  options?: Omit<
    UseMutationOptions<unknown, Error, CreateProjectTypePayload, ProjectType>,
    'mutationKey' | 'mutationFn'
  >;
}

export const useNewProjectTypeRequest = ({ options }: RequestParams = {}) => {
  const api = useApiInstance()

  return useMutation<unknown, Error, CreateProjectTypePayload, ProjectType>(
    [Entities.ProjectTypes, 'new'],
    async (payload: CreateProjectTypePayload) => {
      const { data } = await api.v1AdminProjectsTypesPost(payload)
      return data
    },
    { ...options },
  )
}

import { useMutation, UseMutationOptions } from '@tanstack/react-query'
import { Entities } from 'src/enums'
import { ProductType, ProductTypeUpdate } from 'src/api'
import { useApiInstance } from './useApiInstance'

interface RequestParams {
  id: number;
  options?: Omit<
    UseMutationOptions<unknown, Error, ProductTypeUpdate, ProductType>,
    'mutationKey' | 'mutationFn'
  >;
}

export const useEditProductTypeRequest = ({ id, options }: RequestParams) => {
  const api = useApiInstance()

  return useMutation<unknown, Error, ProductTypeUpdate, ProductType>(
    [Entities.ProductTypes, 'edit'],
    async (payload: ProductTypeUpdate) => {
      const { data } = await api.v1AdminProductsTypesProductTypeIdPut(
        id,
        payload,
      )
      return data
    },
    {
      ...options,
    },
  )
}

import { createContext, RefObject } from 'react'
import { Map as MMap } from 'mapbox-gl'

interface HiddenMapContextState {
  map: RefObject<MMap | null>,
  mapContainer: RefObject<string | HTMLElement>,

}

export const HiddenMapContext = createContext<HiddenMapContextState>({
  map: { current: null },
  mapContainer: { current: null },
})

import { FC } from 'react'
import {
  DialogTitle,
  DialogActions,
  DialogContent,
  Button,
  Box,
  Typography,
  CircularProgress,
} from '@mui/material'
import { useDialog } from 'src/hooks/ui'
import { DialogNames } from 'src/enums'

interface ConfirmContractorDeactivationDialogProps {
  contractor: string
  onSubmit: () => void;
  isLoading?: boolean;
}

export const ConfirmContractorDeactivationDialog: FC<ConfirmContractorDeactivationDialogProps> = ({
  contractor,
  onSubmit,
  isLoading = false,
}) => {
  const { closeDialog } = useDialog(DialogNames.ConfirmContractorDeactivation)

  return (
    <>
      <DialogTitle>Contractor deactivation</DialogTitle>
      <DialogContent>
        <Box width="400px">
          <Typography>
            Are you sure you want to deactivate contractor <b>{contractor}</b>?
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={() => closeDialog()}>
          Cancel
        </Button>
        <Button
          variant="contained"
          color="error"
          disabled={isLoading}
          onClick={onSubmit}
        >
          { isLoading ? <CircularProgress color="info" size={26} /> : 'Deactivate'}
        </Button>
      </DialogActions>
    </>
  )
}

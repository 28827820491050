import React, { FC, useCallback, useEffect, useState } from 'react'
import { Paper } from '@mui/material'
import {
  DataGridPremium,
  GridColDef,
  useGridApiRef,
  gridFilterModelSelector,
  gridFilteredTopLevelRowCountSelector,
  GridPaginationModel,
  GridPinnedColumns,
  GRID_CHECKBOX_SELECTION_COL_DEF,
} from '@mui/x-data-grid-premium'
import ImagesViewer from "react-images-viewer"
import { TableToolbar } from 'src/components/ui'
import { Event } from 'src/api'
import { useImagesViewer } from 'src/hooks/ui'
import { SearchParamsKeys } from 'src/enums'
import { defaultRowsValuePerPage } from 'src/config'
import { EventColumns } from './eventColumns'

const defaultRowsPerPageOptions = defaultRowsValuePerPage

interface EventListProps {
  events: Event[]
  eventCount: number
  isLoadingEvents: boolean
  queryParams: Record<string, string>
  setQueryParams: (params: Record<string, string>, paramsToRemove?: string[]) => void
  autoHeight?: boolean
  height?: string
  eventColumns?: GridColDef<Event>[]
  exportedFileName?: string
}

export const EventList: FC<EventListProps> = ({
  eventCount,
  events,
  isLoadingEvents,
  setQueryParams,
  queryParams,
  autoHeight = false,
  height = '100%',
  eventColumns,
  exportedFileName,
}) => {
  const apiRef = useGridApiRef()
  const {
    isOpen: isOpenedGallery,
    images,
    currentImageIndex,
    openGallery,
    closeGallery,
    goToNext,
    goToPrev,
  } = useImagesViewer()
  const defaultColumns = useCallback((): GridColDef<Event>[] => ([
    EventColumns.Name,
    EventColumns.Type,
    EventColumns.Section,
    EventColumns.Author,
    EventColumns.CreatedAt,
    EventColumns.Distance,
    EventColumns.Device,
    EventColumns.AppVersion,
    EventColumns.Photo(openGallery),
  ]), [openGallery])
  const columns = eventColumns && eventColumns.length ? eventColumns : defaultColumns()
  const [rowCountState, setRowCountState] = useState(eventCount)
  const [pinnedColumns, setPinnedColumns] = useState<GridPinnedColumns>({
    left: [GRID_CHECKBOX_SELECTION_COL_DEF.field, ""],
  })

  useEffect(() => {
    setRowCountState((prevRowCountState) =>
      eventCount !== undefined ? eventCount : prevRowCountState
    )
  }, [eventCount, setRowCountState])

  const handlePinnedColumnsChange = useCallback(
    (updatedPinnedColumns: GridPinnedColumns) => {
      setPinnedColumns(updatedPinnedColumns)
    },
    []
  )

  const handleChangePaginationModel = async (pagination: GridPaginationModel): Promise<void> => {
    const { page, pageSize } = pagination
    const newPage = page + 1
    if (setQueryParams) {
      setQueryParams({
        [SearchParamsKeys.Page]: `${newPage}`,
        [SearchParamsKeys.RowsPerPage]: `${pageSize}`,
      }, [
        SearchParamsKeys.Page,
        SearchParamsKeys.RowsPerPage,
      ])
    }
  }

  const handleChangeState = async () => {
    const filterModel = gridFilterModelSelector(apiRef.current.state)
    if (filterModel.items.length > 0 && filterModel.items[0].value) {
      const visibleRowCount = gridFilteredTopLevelRowCountSelector(apiRef.current.state)
      if (visibleRowCount) setRowCountState(visibleRowCount)
    } else {
      setRowCountState(eventCount)
    }
  }

  const page = queryParams["page"] ? parseInt(queryParams["page"], 10) - 1 : undefined
  const pageSize = queryParams["rowsPerPage"]
    ? parseInt(queryParams["rowsPerPage"], 10)
    : undefined

  return (
    <Paper sx={{ flexGrow: 1, minHeight: '200px', height, width: "100%" }}>
      <DataGridPremium<Event>
        apiRef={apiRef}
        pagination
        autoHeight={autoHeight}
        pinnedColumns={pinnedColumns}
        disableRowSelectionOnClick
        rows={events}
        columns={columns}
        rowCount={rowCountState}
        loading={isLoadingEvents}
        paginationModel={{
          page: page as number,
          pageSize: pageSize as number
        }}
        rowThreshold={2}
        columnThreshold={2}
        paginationMode="server"
        pageSizeOptions={defaultRowsPerPageOptions}
        onPaginationModelChange={handleChangePaginationModel}
        onStateChange={handleChangeState}
        localeText={{
          columnMenuSortAsc: "Sort A-Z",
          columnMenuSortDesc: "Sort Z-A",
        }}
        slots={{
          toolbar: () => <TableToolbar exportedFileName={exportedFileName} />
        }}
        onPinnedColumnsChange={handlePinnedColumnsChange}
        sx={(theme) => ({
          border: "none",
          "& .MuiDataGrid-columnHeaderTitleContainerContent": {
            paddingLeft: "5px",
            overflow: "visible",
          },
          "& .MuiDataGrid-columnHeaderTitle": {
            whiteSpace: "normal",
            lineHeight: 1,
            fontSize: "13px",
            fontWeight: 400,
            color: "rgba(0, 0, 0, 0.6)",
            overflow: "visible",
          },
        })}
      />
      <ImagesViewer
        imgs={images}
        isOpen={isOpenedGallery}
        onClickPrev={goToPrev}
        onClickNext={goToNext}
        onClose={closeGallery}
        currImg={currentImageIndex}
      />
    </Paper>
  )
}

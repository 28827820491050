import { useMutation, UseMutationOptions } from '@tanstack/react-query'
import { Entities } from 'src/enums'
import { ReportComponent, CombinedReportComponentUpdatePayload } from 'src/api'
import { useApiInstance } from './useApiInstance'

interface RequestParams {
  id: number;
  options?: Omit<
    UseMutationOptions<ReportComponent, Error, CombinedReportComponentUpdatePayload>,
    'mutationKey' | 'mutationFn'
  >;
}

export const useEditReportComponentRequest = ({ id, options }: RequestParams) => {
  const api = useApiInstance()

  return useMutation<ReportComponent, Error, CombinedReportComponentUpdatePayload>(
    [Entities.CombinedReportComponent, 'edit', id],
    async (payload: CombinedReportComponentUpdatePayload) => {
      const { data } = await api.v1AdminReportsCombinedReportsComponentsReportComponentIdPatch(
        id,
        payload,
      )
      return data as ReportComponent
    },
    options,
  )
}

import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { Entities } from 'src/enums'
import { ConfigurationParameter } from 'src/api'
import { useApiInstance } from './useApiInstance'
import {
  buildRequestParams,
  ObjectType,
  transformPage,
  transformPerPage,
} from './helpers'

interface RequestParams {
  params?: Partial<AdminConfigurationGetParams>;
  paramsTransform?: Array<(params: Record<string, any>) => Record<string, any>>;
  options?: Omit<
    UseQueryOptions<unknown, Error, ConfigurationParameter[]>,
    'queryKey' | 'queryFn'
  >;
}

interface AdminConfigurationGetParams {
  limit: number;
  offset: number;
  sort: string;
}

const schema: ObjectType['schema'] = {
  limit: 'number',
  offset: 'number',
  sort: 'string',
}

export const useConfigurationListRequest = ({
  params = {},
  paramsTransform = [],
  options,
}: RequestParams = {}) => {
  const api = useApiInstance()

  const transformedParams = [
    transformPerPage,
    transformPage,
    ...paramsTransform,
  ].reduce((param, transform) => transform(param), params)

  const queryParams = buildRequestParams<AdminConfigurationGetParams>(
    transformedParams,
    schema,
  )

  return useQuery<unknown, Error, ConfigurationParameter[]>(
    [Entities.Configuration, JSON.stringify(transformedParams)],
    async () => {
      const { limit, offset, sort } = queryParams
      const { data } = await api.v1AdminConfigurationGet(limit, offset, sort)
      return data
    },
    {
      enabled: Object.keys(transformedParams).length > 0,
      ...options,
    },
  )
}

import React, { FC } from 'react'
import { Grid, Box, Typography } from '@mui/material'
import distanceInWords from 'date-fns/formatDistance'
import { Sensor } from 'src/api'
import { Providers } from 'src/components/providers'
import { InnerLink } from 'src/components/ui'
import { SignalStrengths } from 'src/enums'
import { getSignalStrengthColor } from './sensorsMapLegend'

const weakSignalColor = getSignalStrengthColor(SignalStrengths.Weak)
const fairSignalColor = getSignalStrengthColor(SignalStrengths.Fair)
const goodSignalColor = getSignalStrengthColor(SignalStrengths.Good)

interface SensorMarkerProps {
  sensor: Sensor;
}

export const SensorMarker: FC<SensorMarkerProps> = ({ sensor }) => {
  const getSignalColor = (signalStrength?: number): string => {
    if (!signalStrength) return 'black'
    if (signalStrength <= -100) {
      return weakSignalColor
    } else if (signalStrength <= -85) {
      return fairSignalColor
    } else {
      return goodSignalColor
    }
  }

  return (
    <Providers>
      <Box pt={1} pl={2} pr={2}>
        <Grid container flexWrap="nowrap" spacing={2}>
          <Grid item container direction="column" alignItems="stretch">
            <Grid item>
              <Typography variant="caption" color="gray">
                Sensor ID:{' '}
              </Typography>
              <Typography variant="body1">
                <InnerLink to={`/sensors/${sensor.uniqueId}`} target="_blank">
                  {sensor.uniqueId}
                </InnerLink>
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="caption" color="gray">
                Last Ping:{' '}
              </Typography>
              <Typography variant="body1">
                {sensor.currentData?.updatedAt
                  ? distanceInWords(
                    new Date(sensor.currentData?.updatedAt),
                    new Date()
                  )
                  : '-'}
              </Typography>
            </Grid>
          </Grid>
          <Grid item container direction="column" alignItems="stretch">
            <Grid item>
              <Typography variant="caption" color="gray">
                IMEI:{' '}
              </Typography>
              <Typography variant="body1">{sensor.imei}</Typography>
            </Grid>
            <Grid item>
              <Typography variant="caption" color="gray">
                Battery Level:{' '}
              </Typography>
              <Typography variant="body1">
                {sensor.currentData?.batteryLevel
                  ? `${sensor.currentData?.batteryLevel}%`
                  : '-'}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="caption" color="gray">
                Signal Strength:{' '}
              </Typography>
              <Typography variant="body1" color={getSignalColor(sensor.currentData?.signalStrength)}>
                {sensor.currentData?.signalStrength
                  ? `${sensor.currentData?.signalStrength} dBm`
                  : '-'}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Providers>
  )
}

import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { Entities } from 'src/enums'
import { Release } from 'src/api'
import { useApiInstance } from './useApiInstance'

interface RequestParams {
  id: number;
  options?: Omit<
    UseQueryOptions<unknown, Error, Release>,
    'queryKey' | 'queryFn'
  >;
}

export const useReleaseSingleRequest = ({ id, options }: RequestParams) => {
  const api = useApiInstance()

  return useQuery<unknown, Error, Release>(
    [Entities.Release, id],
    async () => {
      const { data } = await api.v1AdminReleasesReleaseIdGet(id)
      return data
    },
    { ...options },
  )
}

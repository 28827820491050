import React from "react"
import { Grid, Typography } from "@mui/material"
import { styled } from "@mui/material/styles"
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp"
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion"
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from "@mui/material/AccordionSummary"
import MuiAccordionDetails from "@mui/material/AccordionDetails"
import { ArrowRight } from "@mui/icons-material"
import { FloatTextField, NumberTextField, TextField } from "../../ui"

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(() => ({
  border: "none",

  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}))

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor: "rgba(0, 0, 0, .03)",
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: "4px",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  paddingLeft: theme.spacing(1),
  paddingRight: theme.spacing(1),
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}))

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(0),
}))

export const AdditionalFields = () => {
  return (
    <Accordion>
      <AccordionSummary expandIcon={<ArrowRight />}>
        <Typography>Additional fields</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={1}>
          <Grid item xs={12} mt={2}>
            <TextField
              name="highSlabMVER"
              label="Slab MVER (CaCl) Max"
              TextFieldProps={{
                InputProps: {
                  inputComponent: NumberTextField as any,
                },
                placeholder: "lbs",
              }}
            />
          </Grid>
          <Grid item container spacing={2} xs={12}>
            <Grid item xs={6}>
              <TextField
                name="lowSlabRH"
                label="Slab RH% Min"
                TextFieldProps={{
                  InputProps: {
                    inputComponent: NumberTextField as any,
                  },
                  placeholder: "%",
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                name="highSlabRH"
                label="Slab RH% Max"
                TextFieldProps={{
                  InputProps: {
                    inputComponent: NumberTextField as any,
                  },
                  placeholder: "%",
                }}
              />
            </Grid>
          </Grid>
          <Grid item container spacing={2} xs={12}>
            <Grid item xs={6}>
              <TextField
                name="lowSurfaceTempF"
                label="Surface Temp Min"
                TextFieldProps={{
                  InputProps: {
                    inputComponent: NumberTextField as any,
                  },
                  placeholder: "°F",
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                name="highSurfaceTempF"
                label="Surface Temp Max"
                TextFieldProps={{
                  InputProps: {
                    inputComponent: NumberTextField as any,
                  },
                  placeholder: "°F",
                }}
              />
            </Grid>
          </Grid>
          <Grid item container spacing={2} xs={12}>
            <Grid item xs={6}>
              <TextField
                name="lowSlabPH"
                label="pH Min"
                TextFieldProps={{
                  InputProps: {
                    inputComponent: NumberTextField as any,
                  },
                  placeholder: "pH",
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                name="highSlabPH"
                label="pH Max"
                TextFieldProps={{
                  InputProps: {
                    inputComponent: NumberTextField as any,
                  },
                  placeholder: "pH",
                }}
              />
            </Grid>
          </Grid>
          <Grid item container spacing={2} xs={12}>
            <Grid item xs={6}>
              <TextField
                name="boardWidth"
                label="Board Width"
                TextFieldProps={{
                  InputProps: {
                    inputComponent: FloatTextField as any,
                  },
                  placeholder: "width",
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  )
}

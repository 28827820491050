import React, { FC, Fragment, useState, useCallback } from 'react'
import { Grid, Typography, Divider } from '@mui/material'
import { DataLoad } from 'src/components/ui'
import { useConfigurationListRequest, useStagingDevicesRequest } from 'src/hooks/api'
import { ConfigurationParameterTypeEnum } from 'src/api'
import { EditableConfigurationParam } from './editableConfigurationParam'
import { InlineConfigurationParam } from './inlineConfigurationParam'
import { EditableMobileSessionParam } from './editableMobileSessionParam'
import { StagingDevicesList } from './stagingDevicesList'
import { EditableStagingDevices } from './editableStagingDevices'

export const ConfigurationList: FC = () => {
  const [editId, setEditId] = useState<number | null>(null)
  const [editStagingDevices, setEditStagingDevices] = useState<boolean>(false)
  const { data, isInitialLoading, isError, error, refetch } = useConfigurationListRequest({
    params: { limit: 50 }
  })
  const { data: stagingDevices, refetch: refetchStagingDevices } = useStagingDevicesRequest()

  const privacyConfigurationList = useCallback(() => {
    return data?.filter(
      (param) => param.type === ConfigurationParameterTypeEnum.PrivacyPolicyLink || param.type === ConfigurationParameterTypeEnum.TermsOfUseLink
    ) || []
  }, [data])()

  const supportConfigurationList = useCallback(() => {
    return data?.filter(
      (param) => param.type === ConfigurationParameterTypeEnum.FloorcloudEmail
        || param.type === ConfigurationParameterTypeEnum.FloorcloudPhone
        || param.type === ConfigurationParameterTypeEnum.FloorcloudHowToLink
        || param.type === ConfigurationParameterTypeEnum.ProductRequestedEmail
        || param.type === ConfigurationParameterTypeEnum.GangboxEmail
    ) || []
  }, [data])()

  const sessionConfigurationList = useCallback(() => {
    return data?.filter(
      (param) => param.type === ConfigurationParameterTypeEnum.MobileSessionLimit
    ) || []
  }, [data])()

  const nwfaConfigurationList = useCallback(() => {
    return data?.filter(
      (param) => param.type === ConfigurationParameterTypeEnum.NwfaEmail
        || param.type === ConfigurationParameterTypeEnum.NwfaPhone
    ) || []
  }, [data])()

  const rapidRhConfigurationList = useCallback(() => {
    return data?.filter(
      (param) => param.type === ConfigurationParameterTypeEnum.RapidRhTimeValidationThresholdHours
    ) || []
  }, [data])()

  const saveChanges = () => {
    setEditId(null)
    refetch()
  }

  const saveStagingDevicesChanges = () => {
    setEditStagingDevices(false)
    refetchStagingDevices()
  }

  return (
    <Grid container direction="column" spacing={4}>
      <Grid item>
        <DataLoad
          isLoading={isInitialLoading}
          isError={isError}
          errorMessage={error?.message}
        >
          <Grid container direction="column" spacing={2}>
            <Grid item sx={{ pb: 2, pt: 2 }}>
              <Grid item sx={{ pb: 1 }}>
                <Typography variant="h6">Privacy</Typography>
              </Grid>
              {privacyConfigurationList.map(({ id, name, value }, idx, list) => (
                <Fragment key={idx}>
                  <Grid item container spacing={1} alignItems="center" sx={{ pb: 2 }}>
                    {editId === id ? (
                      <EditableConfigurationParam
                        id={id}
                        value={value || ''}
                        configParamName={name}
                        onChange={saveChanges}
                        onCancel={() => setEditId(null)}
                      />
                    ) : (
                      <InlineConfigurationParam
                        id={id}
                        name={name || ''}
                        value={value || ''}
                        onEdit={setEditId}
                      />
                    )}
                  </Grid>
                </Fragment>
              ))}
            </Grid>
          </Grid>
          <Divider flexItem={true} sx={{ mb: 2 }} />
          <Grid container direction="column" spacing={2}>
            <Grid item sx={{ pb: 2, pt: 2 }}>
              <Grid item sx={{ pb: 1 }}>
                <Typography variant="h6">Support</Typography>
              </Grid>
              {supportConfigurationList.map(({ id, name, value }, idx, list) => (
                <Fragment key={idx}>
                  <Grid item container spacing={1} alignItems="center" sx={{ pb: 2 }}>
                    {editId === id ? (
                      <EditableConfigurationParam
                        id={id}
                        value={value || ''}
                        configParamName={name}
                        onChange={saveChanges}
                        onCancel={() => setEditId(null)}
                      />
                    ) : (
                      <InlineConfigurationParam
                        id={id}
                        name={name || ''}
                        value={value || ''}
                        onEdit={setEditId}
                      />
                    )}
                  </Grid>
                </Fragment>
              ))}
            </Grid>
          </Grid>
          <Divider flexItem={true} sx={{ mb: 2 }} />
          <Grid container direction="column" spacing={2}>
            <Grid item sx={{ pb: 2, pt: 2 }}>
              <Grid item sx={{ pb: 1 }}>
                <Typography variant="h6">Sessions</Typography>
              </Grid>
              {sessionConfigurationList.map(({ id, name, value }, idx, list) => (
                <Fragment key={idx}>
                  <Grid item container spacing={1} alignItems="center" sx={{ pb: 2 }}>
                    {editId === id ? (
                      <EditableMobileSessionParam
                        id={id}
                        value={value || ''}
                        configParamName={name}
                        onChange={saveChanges}
                        onCancel={() => setEditId(null)}
                      />
                    ) : (
                      <InlineConfigurationParam
                        id={id}
                        name={name || ''}
                        value={value || ''}
                        onEdit={setEditId}
                      />
                    )}
                  </Grid>
                </Fragment>
              ))}
            </Grid>
          </Grid>
          <Divider flexItem={true} sx={{ mb: 2 }} />
          <Grid container direction="column" spacing={2}>
            <Grid item sx={{ pb: 2, pt: 2 }}>
              <Grid item sx={{ pb: 1 }}>
                <Typography variant="h6">NWFA</Typography>
              </Grid>
              {nwfaConfigurationList.map(({ id, name, value, type }, idx, list) => (
                <Fragment key={idx}>
                  <Grid item container spacing={1} alignItems="center" sx={{ pb: 2 }}>
                    {editId === id ? (
                      <EditableConfigurationParam
                        id={id}
                        value={value || ''}
                        configParamName={name}
                        onChange={saveChanges}
                        onCancel={() => setEditId(null)}
                        isRequiredField={type !== ConfigurationParameterTypeEnum.NwfaEmail}
                      />
                    ) : (
                      <InlineConfigurationParam
                        id={id}
                        name={name || ''}
                        value={value || ''}
                        onEdit={setEditId}
                      />
                    )}
                  </Grid>
                </Fragment>
              ))}
            </Grid>
          </Grid>
          <Divider flexItem={true} sx={{ mb: 2 }} />
          <Grid container direction="column" spacing={2}>
            <Grid item sx={{ pb: 2, pt: 2 }}>
              <Grid item sx={{ pb: 1 }}>
                <Typography variant="h6">RapidRH</Typography>
              </Grid>
              {rapidRhConfigurationList.map(({ id, name, value, type }, idx, list) => (
                <Fragment key={idx}>
                  <Grid item container spacing={1} alignItems="center" sx={{ pb: 2 }}>
                    {editId === id ? (
                      <EditableConfigurationParam
                        id={id}
                        value={value || ''}
                        configParamName={name}
                        onChange={saveChanges}
                        onCancel={() => setEditId(null)}
                        isRequiredField
                      />
                    ) : (
                      <InlineConfigurationParam
                        id={id}
                        name={name || ''}
                        value={value || ''}
                        onEdit={setEditId}
                      />
                    )}
                  </Grid>
                </Fragment>
              ))}
            </Grid>
          </Grid>
          <Divider flexItem={true} sx={{ mb: 2 }} />
          <Grid container direction="column" spacing={2}>
            <Grid item sx={{ pb: 2, pt: 2 }}>
              <Grid item sx={{ pb: 1 }}>
                <Typography variant="h6">Staging Devices</Typography>
              </Grid>
              {editStagingDevices ? (
                <EditableStagingDevices
                  stagingDevices={stagingDevices || []}
                  onCancel={() => setEditStagingDevices(false)}
                  onChange={saveStagingDevicesChanges}
                />
              ) : (
                <StagingDevicesList
                  stagingDevices={stagingDevices || []}
                  onEdit={() => setEditStagingDevices(true)}
                />
              )}
            </Grid>
          </Grid>
        </DataLoad>
      </Grid>
    </Grid>
  )
}

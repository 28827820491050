import { FC, useEffect, useState } from "react"
import Grid from "@mui/material/Grid"
import Typography from "@mui/material/Typography"
import JsonView from '@uiw/react-json-view'
import {
  HasSidebarLayout,
  DataLoad,
  EntityHeader,
} from "src/components"
import { useQueryParams } from "src/hooks/ui"

interface SensorRawUplinkContentProps {
  fileName: string
  jsonData: Record<string, any>
}

const SensorRawUplinkContent: FC<SensorRawUplinkContentProps> = ({ jsonData, fileName }) => {
  const title = `Sensor raw uplink - ${fileName}`

  return (
    <>
      <Grid container direction="column" spacing={4}>
        <Grid item>
          <EntityHeader
            title={title}
            showBackButton={false}
          />
        </Grid>
        <Grid item>
          {
            jsonData && Object.keys(jsonData).length > 0 ? (
              <JsonView value={jsonData} />
            ) : (
              <Typography variant="body1">No data</Typography>
            )
          }
        </Grid>
      </Grid>
    </>
  )
}

const SensorRawUplink: FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isError, setIsError] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [jsonData, setJsonData] = useState<Record<string, any>>({})
  const [queryParams] = useQueryParams()
  const { fileName, link } = queryParams
  document.title = 'Sensor raw uplink'

  useEffect(() => {
    if (link) downloadUplink()
  }, [link])

  const downloadUplink = async () => {
    try {
      setIsLoading(true)
      const response = await fetch(link)
      const uplink = await response.json()
      setJsonData(uplink)
    } catch (error) {
      setIsError(true)
      setErrorMessage((error as any).message)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <HasSidebarLayout>
      <DataLoad
        isLoading={isLoading}
        isError={isError}
        errorMessage={errorMessage}
      >
        <SensorRawUplinkContent jsonData={jsonData} fileName={fileName} />
      </DataLoad>
    </HasSidebarLayout>
  )
}

export default SensorRawUplink

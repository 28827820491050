import React, { FC, PropsWithChildren } from 'react'
import { CircularProgress, Typography } from '@mui/material'
import { Centralize } from './centralize'

interface DataLoadProps {
  isError: boolean;
  isLoading: boolean;
  errorMessage?: string;
}

export const DataLoad: FC<PropsWithChildren<DataLoadProps>> = ({
  isError,
  isLoading,
  errorMessage = 'Load error',
  children,
}) => {
  return (
    <>
      {isLoading && (
        <Centralize>
          <CircularProgress />
        </Centralize>
      )}
      {isError && <Typography>{errorMessage}</Typography>}
      {!isLoading && !isError && children}
    </>
  )
}

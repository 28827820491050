import { FC } from 'react'
import { Grid, Typography } from '@mui/material'
import { Table, DynamicColumn } from 'src/components/ui'
import { ProductDashboardStatistics } from 'src/api'
import { ProductDashboardColumns } from 'src/components/columns/dashboard'

interface ProductDashboardProps {
  productStatistics: ProductDashboardStatistics;
}

const columns: DynamicColumn<ProductDashboardStatistics>[] = [
  ProductDashboardColumns.ManfacturersNumber,
  ProductDashboardColumns.ProductsNumber,
  ProductDashboardColumns.PublishedProductsNumber,
  ProductDashboardColumns.InUseProductsNumber,
]

export const ProductDashboard: FC<ProductDashboardProps> = (props) => {
  const { productStatistics } = props
  return (
    <Grid container spacing={2}>
      <Grid item>
        <Typography variant="h5">Products</Typography>
      </Grid>
      <Grid item container>
        <Table
          columns={columns}
          data={[productStatistics]}
          showFooter={false}
          sx={{ width: '100%' }}
        />
      </Grid>
    </Grid>

  )
}

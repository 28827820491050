import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from "react"
import { ApexOptions } from "apexcharts"
import { Box, Button, Grid, IconButton, Typography } from "@mui/material"
import ArrowBackIcon from '@mui/icons-material/ArrowBackRounded'
import ArrowForwardIcon from '@mui/icons-material/ArrowForwardRounded'
import CloseIcon from '@mui/icons-material/CloseRounded'
import format from 'date-fns/format'
import endOfWeek from 'date-fns/endOfWeek'
import startOfWeek from 'date-fns/startOfWeek'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { TabGroupFilter, SensorChart, getCustomTooltip, OuterLink } from "src/components"
import { Sensor } from "src/api"
import { SearchParamsKeys } from "src/enums"
import { useSensorReadoutRequest } from "src/hooks/api"
import { useOutsideAlerter, useQueryParamsState } from "src/hooks/ui"
import { ReadingType } from "src/interfaces"
import { getGoogleMapsLink } from "src/helpers"
import { SignalStrengthChart } from "./sensorChart"

type ApexChartDataObj = {
  x: any;
  y: any;
  fillColor?: string;
  strokeColor?: string;
  meta?: any;
  goals?: any;
};

type ApexChartData =
  | (number | null)[]
  | ApexChartDataObj[]
  | [number, number | null][]
  | [number, (number | null)[]][];

type SelectedLocation = {
  latitude: number
  longitude: number
}

const chartOfReadingsTabs = ({
  humidityData,
  temperatureData,
  batteryData,
  signalStrength,
  lightData,
  pressureData,
  cellularLocationData,
  gpsLocationData,
  isLoading,
  setSelectedLocation,
  selectedLocation
}: {
  humidityData: ApexOptions["series"];
  temperatureData: ApexOptions["series"];
  batteryData: ApexOptions["series"];
  signalStrength: ApexOptions["series"];
  lightData: ApexOptions["series"];
  pressureData: ApexOptions["series"];
  cellularLocationData: ApexOptions["series"];
  gpsLocationData: ApexOptions["series"];
  isLoading: boolean;
  setSelectedLocation: (location: SelectedLocation) => void;
  selectedLocation: SelectedLocation | null;
}) => {
  return [
    {
      status: "Temperature",
      content: <SensorChart
        data={temperatureData}
        isLoading={isLoading}
        options={{
          tooltip: {
            custom: getCustomTooltip
          },
        }}
      />,
    },
    {
      status: "Humidity",
      content: (
        <SensorChart
          data={humidityData}
          options={{
            yaxis: {
              min: 0,
              max: 100,
            },
            tooltip: {
              custom: getCustomTooltip
            }
          }}
          isLoading={isLoading}
        />
      ),
    },
    {
      status: "Battery",
      content: (
        <SensorChart
          data={batteryData}
          options={{
            yaxis: {
              min: 0,
              max: 100,
            },
            tooltip: {
              custom: getCustomTooltip
            }
          }}
          isLoading={isLoading}
        />
      ),
    },
    {
      status: "Signal Strength",
      content: <SignalStrengthChart
        data={signalStrength}
        options={{
          tooltip: {
            custom: getCustomTooltip
          }
        }}
      />,
    },
    {
      status: "Light",
      content: <SensorChart
        data={lightData}
        isLoading={isLoading}
        options={{
          tooltip: {
            custom: getCustomTooltip
          }
        }}
      />,
    },
    {
      status: "Pressure",
      content: (
        <SensorChart
          data={pressureData}
          options={{
            yaxis: {
              min: 850,
              max: 1200,
            },
            tooltip: {
              custom: getCustomTooltip
            }
          }}
          isLoading={isLoading}
        />
      ),
    },
    {
      status: "Cellular Location",
      content: (
        <>
          <Typography align="left" component="p">
            <Typography variant="body2" fontWeight={500} component="span">
            Cellular Location - 
            </Typography>
            <Typography variant="body2" component="span">
              {
                selectedLocation
                  ? <OuterLink to={getGoogleMapsLink(selectedLocation.latitude, selectedLocation.longitude)}>{` Latitude: ${selectedLocation.latitude}, Longitude: ${selectedLocation.longitude}`}</OuterLink>
                  : " Select a point to view location"}
            </Typography>
          </Typography>
          <SensorChart
            data={cellularLocationData}
            options={{
              yaxis: {
                min: 0,
                max: 100,
              },
              tooltip: {
                custom: getCustomTooltip
              },
              chart: {
                events: {
                  dataPointSelection(e, chart, options) {
                    const { dataPointIndex }: { dataPointIndex: number } = options
                    const data = chart.w.globals.initialSeries[0].data[dataPointIndex]
                    setSelectedLocation({ latitude: data.meta.latitude, longitude: data.meta.longitude })
                  },
                }
              }
            }}
            isLoading={isLoading}
          />
        </>
      ),
    },
    {
      status: "GPS Location",
      content: (
        <>
          <Typography align="left" component="p">
            <Typography variant="body2" fontWeight={500} component="span">
              GPS Location - 
            </Typography>
            <Typography variant="body2" component="span">
              {
                selectedLocation
                  ? <OuterLink to={getGoogleMapsLink(selectedLocation.latitude, selectedLocation.longitude)}>{` Latitude: ${selectedLocation.latitude}, Longitude: ${selectedLocation.longitude}`}</OuterLink>
                  : " Select a point to view location"}
            </Typography>
          </Typography>
          <SensorChart
            data={gpsLocationData}
            options={{
              yaxis: {
                min: 0,
                max: 100,
              },
              tooltip: {
                custom: getCustomTooltip
              },
              chart: {
                events: {
                  dataPointSelection(e, chart, options) {
                    const { dataPointIndex }: { dataPointIndex: number } = options
                    const data = chart.w.globals.initialSeries[0].data[dataPointIndex]
                    setSelectedLocation({ latitude: data.meta.latitude, longitude: data.meta.longitude })
                  },
                }
              }
            }}
            isLoading={isLoading}
          />
        </>
      ),
    },
  ]
}

interface SensorChartTabsProps {
  sensor: Sensor;
}

const STEP = 7

export const SensorChartTabs: FC<SensorChartTabsProps> = ({ sensor }) => {
  const queryParams = useQueryParamsState()
  const [timeFrom, setTimeFrom] = useState<Date>(startOfWeek(new Date(), { weekStartsOn: 1 }))
  const [timeTo, setTimeTo] = useState<Date>(endOfWeek(new Date(), { weekStartsOn: 1 }))
  const [datetime, setDatetime] = useState<Date | null>(startOfWeek(new Date(), { weekStartsOn: 1 }))
  const [isOpenDatePicker, setIsOpenDatePicker] = useState(false)
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const [selectedLocation, setSelectedLocation] = useState<SelectedLocation | null>(null)
  const wrapperRef = useRef(null)
  useOutsideAlerter({ ref: wrapperRef, callback: () => setIsOpenDatePicker(false) })
  const readingType: ReadingType | undefined = useMemo(() => {
    const readingTab = queryParams.chart
    const map: Record<string, ReadingType> = {
      'Humidity': 'humidity',
      'Temperature': 'temperature',
      'Battery': 'battery',
      'SignalStrength': 'signalStrength',
      'Light': 'light',
      'Pressure': 'pressure',
      'CellularLocation': 'cellularLocation',
      'GPSLocation': 'gpsLocation',
    }
    return map[readingTab]
  }, [queryParams])
  const { data: sensorReadoutResponse, refetch: refetchReadout, isRefetching, isInitialLoading } = useSensorReadoutRequest({
    sensorUniqueId: sensor.uniqueId,
    params: { timeFrom: timeFrom.toISOString(), timeTo: timeTo.toISOString(), reading: readingType },
    options: { enabled: false }
  })
  const [sensorReadout, setSensorReadout] = useState(sensorReadoutResponse)

  useEffect(() => {
    setSensorReadout(sensorReadoutResponse)
  }, [sensorReadoutResponse])

  useEffect(() => {
    if (timeFrom && timeTo) refetchReadout()
  }, [timeFrom, timeTo, readingType, refetchReadout])

  const getColor = ({
    isCharging,
    isDelayed,
  }: {
    isDelayed: boolean;
    isCharging: boolean;
  }): string => {
    if (isDelayed && !isCharging) {
      return "#a8dafe" // light blue
    }
    if (!isDelayed && isCharging) {
      return "#f77d25" // orange
    }
    if (isDelayed && isCharging) {
      return "#fab17c" // light orange
    }
    return "#26a2fc"
  }

  const getSensorBarChartData = useCallback(
    ({
      readout,
      length,
    }: {
      readout: {
        time: string;
        value: number;
        isDelayed: boolean;
        isCharging: boolean;
        contractor: string | null;
        project: string | null;
        projectSection: string | null;
        latitude?: number;
        longitude?: number;
      }[];
      length: number;
    }): ApexChartData => {
      const data: ApexChartDataObj[] = []
      for (let i = 0; i <= length; i++) {
        const shiftedTime = new Date(new Date(timeTo).setUTCHours(new Date(timeTo).getUTCHours() - i, 59, 59))
        const reading = readout.find((_reading) => {
          const readingTime = new Date(_reading.time).getTime()
          const diff = shiftedTime.getTime() - readingTime
          const diffInMinutes = Number((diff / 1000 / 60).toFixed(0))
          return diffInMinutes >= 0 && diffInMinutes < 60
        })
        data.unshift({
          x: shiftedTime.getTime(),
          y: reading?.value?.toFixed(2) || 0,
          fillColor: getColor({
            isCharging: Boolean(reading?.isCharging),
            isDelayed: Boolean(reading?.isDelayed),
          }),
          meta: {
            contractor: reading?.contractor,
            project: reading?.project,
            projectSection: reading?.projectSection,
            latitude: reading?.latitude,
            longitude: reading?.longitude,
            isLocation: Boolean(reading?.latitude) && Boolean(reading?.longitude)
          }
        })
      }
      return data
    },
    [timeTo]
  )

  const getHumidityChartData = (): ApexOptions["series"] => {
    const data = getSensorBarChartData({
      readout: (sensorReadout?.humidity || []).map((reading) => ({
        time: reading.time,
        value: Number(reading.humidity),
        isDelayed: reading.isDelayed || false,
        isCharging: reading.isCharging || false,
        contractor: reading.contractor?.name || null,
        project: reading.project?.name || null,
        projectSection: reading.projectSection?.name || null,
      })),
      length: STEP * 24,
    })
    return [
      {
        name: "Humidity",
        data,
      },
    ]
  }

  const getTemperatureChartData = (): ApexOptions["series"] => {
    const data = getSensorBarChartData({
      readout: (sensorReadout?.temperature || []).map((reading) => ({
        time: reading.time,
        value: Number(reading.temperatureF),
        isDelayed: reading.isDelayed || false,
        isCharging: reading.isCharging || false,
        contractor: reading.contractor?.name || null,
        project: reading.project?.name || null,
        projectSection: reading.projectSection?.name || null,
      })),
      length: STEP * 24,
    })
    return [
      {
        name: "Temperature",
        data,
      },
    ]
  }

  const getBatteryChartData = (): ApexOptions["series"] => {
    const data = getSensorBarChartData({
      readout: (sensorReadout?.battery || []).map((reading) => ({
        time: reading.time,
        value: Number(reading.battery),
        isDelayed: reading.isDelayed || false,
        isCharging: reading.isCharging || false,
        contractor: reading.contractor?.name || null,
        project: reading.project?.name || null,
        projectSection: reading.projectSection?.name || null,
      })),
      length: STEP * 24,
    })
    return [
      {
        name: "Battery",
        data,
      },
    ]
  }

  const getSignalStrengthChartData = (): ApexOptions["series"] => {
    const data = getSensorBarChartData({
      readout: (sensorReadout?.signalStrength || []).map((reading) => ({
        time: reading.time,
        value: Number(reading.signalStrength),
        isDelayed: reading.isDelayed || false,
        isCharging: reading.isCharging || false,
        contractor: reading.contractor?.name || null,
        project: reading.project?.name || null,
        projectSection: reading.projectSection?.name || null,
      })),
      length: STEP * 24,
    })
    return [
      {
        name: "Signal Strength",
        data,
      },
    ]
  }

  const getLightChartData = (): ApexOptions["series"] => {
    const data = getSensorBarChartData({
      readout: (sensorReadout?.light || []).map((reading) => ({
        time: reading.time,
        value: Number(reading.light),
        isDelayed: reading.isDelayed || false,
        isCharging: reading.isCharging || false,
        contractor: reading.contractor?.name || null,
        project: reading.project?.name || null,
        projectSection: reading.projectSection?.name || null,
      })),
      length: STEP * 24,
    })
    return [
      {
        name: "Light",
        data,
      },
    ]
  }

  const getPressureChartData = (): ApexOptions["series"] => {
    const data = getSensorBarChartData({
      readout: (sensorReadout?.pressure || []).map((reading) => ({
        time: reading.time,
        value: Number(reading.pressure),
        isDelayed: reading.isDelayed || false,
        isCharging: reading.isCharging || false,
        contractor: reading.contractor?.name || null,
        project: reading.project?.name || null,
        projectSection: reading.projectSection?.name || null,
      })),
      length: STEP * 24,
    })
    return [
      {
        name: "Pressure",
        data,
      },
    ]
  }

  const getCellularLocationChartData = (): ApexOptions["series"] => {
    const data = getSensorBarChartData({
      readout: (sensorReadout?.cellularLocation || []).map((reading) => ({
        time: reading.time,
        value: 100,
        latitude: reading.latitude,
        longitude: reading.longitude,
        isDelayed: reading.isDelayed || false,
        isCharging: reading.isCharging || false,
        contractor: reading.contractor?.name || null,
        project: reading.project?.name || null,
        projectSection: reading.projectSection?.name || null,
      })),
      length: STEP * 24,
    })
    return [
      {
        name: "Cellular Location",
        data,
      },
    ]
  }

  const getGpsLocationChartData = (): ApexOptions["series"] => {
    const data = getSensorBarChartData({
      readout: (sensorReadout?.gpsLocation || []).map((reading) => ({
        time: reading.time,
        value: 100,
        latitude: reading.latitude,
        longitude: reading.longitude,
        isDelayed: reading.isDelayed || false,
        isCharging: reading.isCharging || false,
        contractor: reading.contractor?.name || null,
        project: reading.project?.name || null,
        projectSection: reading.projectSection?.name || null,
      })),
      length: STEP * 24,
    })
    return [
      {
        name: "GPS Location",
        data,
      },
    ]
  }

  const isLastWeek = useCallback(() => {
    const endOfWeekDate = endOfWeek(new Date(), { weekStartsOn: 1 })
    return timeTo.getDate() === endOfWeekDate.getDate()
      && timeTo.getMonth() === endOfWeekDate.getMonth()
      && timeTo.getFullYear() === endOfWeekDate.getFullYear()
  }, [timeTo])()

  const handleGoBack = () => {
    if (isRefetching) return

    const newTimeFrom = new Date(new Date(timeFrom).setDate(new Date(timeFrom).getDate() - STEP))
    const newTimeTo = new Date(new Date(timeTo).setDate(new Date(timeTo).getDate() - STEP))
    setTimeFrom(newTimeFrom)
    setTimeTo(newTimeTo)
    setDatetime(newTimeFrom)
  }

  const handleGoForward = () => {
    if (isLastWeek || isRefetching) return

    const newTimeFrom = new Date(new Date(timeFrom).setDate(new Date(timeFrom).getDate() + STEP))
    const newTimeTo = new Date(new Date(timeTo).setDate(new Date(timeTo).getDate() + STEP))
    setTimeFrom(newTimeFrom)
    setTimeTo(newTimeTo)
    setDatetime(newTimeFrom)
  }

  const resetRange = () => {
    const newTimeFrom = startOfWeek(new Date(), { weekStartsOn: 1 })
    const newTimeTo = endOfWeek(new Date(), { weekStartsOn: 1 })
    setTimeFrom(newTimeFrom)
    setTimeTo(newTimeTo)
    setDatetime(newTimeFrom)
  }

  const onAcceptDatetime = (newDatetime: Date | null) => {
    const startOfWeekDate = startOfWeek(newDatetime || timeFrom, { weekStartsOn: 1 })
    const endOfWeekDate = endOfWeek(newDatetime || timeTo, { weekStartsOn: 1 })
    setDatetime(startOfWeekDate)
    setTimeFrom(startOfWeekDate)
    setTimeTo(endOfWeekDate)
    setIsOpenDatePicker(false)
  }

  const toggleDatePicker = (event: React.MouseEvent<HTMLButtonElement>) => {
    setIsOpenDatePicker(!isOpenDatePicker)
    setAnchorEl(!isOpenDatePicker ? event.currentTarget : null)
  }

  return (
    <Box>
      <Grid>
        <Grid container alignItems="center">
          <Grid item>
            <IconButton aria-label="go-back" color="primary" onClick={handleGoBack} disabled={isRefetching}>
              <ArrowBackIcon />
            </IconButton>
          </Grid>
          <Grid item px={1}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                open={isOpenDatePicker}
                value={datetime}
                onChange={() => {}}
                onAccept={onAcceptDatetime}
                renderInput={(props: any) => (
                  <Button variant="text" onClick={toggleDatePicker}>
                    <Typography variant="body1">
                      {`${format(timeFrom, 'PP')} - ${format(timeTo, 'PP')}`}
                    </Typography>
                  </Button>
                )}
                PopperProps={{
                  placement: "bottom-end",
                  anchorEl: anchorEl,
                  ref: wrapperRef,
                }}
                disableFuture
                closeOnSelect
                disableHighlightToday
              />
            </LocalizationProvider>
          </Grid>
          <Grid item>
            <IconButton aria-label="go-forward" color="primary" onClick={handleGoForward} disabled={isLastWeek || isRefetching}>
              <ArrowForwardIcon />
            </IconButton>
          </Grid>
          <Grid item>
            <IconButton aria-label="reset" color="primary" onClick={resetRange} disabled={isLastWeek || isRefetching}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </Grid>
      <Grid>
        <TabGroupFilter
          name={SearchParamsKeys.Chart}
          statusList={chartOfReadingsTabs({
            humidityData: getHumidityChartData(),
            temperatureData: getTemperatureChartData(),
            batteryData: getBatteryChartData(),
            signalStrength: getSignalStrengthChartData(),
            lightData: getLightChartData(),
            pressureData: getPressureChartData(),
            cellularLocationData: getCellularLocationChartData(),
            gpsLocationData: getGpsLocationChartData(),
            isLoading: isRefetching || isInitialLoading,
            setSelectedLocation,
            selectedLocation,
          })}
          paramsToRemove={[SearchParamsKeys.Page, SearchParamsKeys.RowsPerPage]}
          onChange={() => setSelectedLocation(null)}
        />
      </Grid>
    </Box>
  )
}

import React, { FC, ChangeEvent, FocusEvent } from 'react'
import {
  TextField as MuiTextField,
  TextFieldProps as MuiTextFieldProps,
  Typography,
} from '@mui/material'
import { ErrorMessage, useField } from 'formik'

interface TextFieldProps {
  name: string;
  label?: string;
  required?: boolean;
  error?: string;
  TextFieldProps?: MuiTextFieldProps;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (event: FocusEvent<HTMLInputElement>) => void;
}

export const TextField: FC<TextFieldProps> = ({
  name,
  label,
  error: errorMessage,
  required = false,
  TextFieldProps,
  onChange,
  onBlur,
}) => {
  const [field, { touched, error }] = useField(name)
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    field.onChange(event)
    if (onChange) onChange(event)
  }
  const handleBlur = (event: FocusEvent<HTMLInputElement>) => {
    field.onBlur(event)
    if (onBlur) onBlur(event)
  }
  return (
    <>
      <MuiTextField
        variant="outlined"
        fullWidth
        margin="dense"
        InputLabelProps={{ shrink: true }}
        {...TextFieldProps}
        {...field}
        onChange={handleChange}
        onBlur={handleBlur}
        value={field.value !== null && field.value !== undefined ? field.value : ''}
        required={required}
        label={label}
        error={touched && !!error}
      />
      <ErrorMessage
        name={name}
        render={(schemeError: string) => (
          <Typography
            variant="caption"
            sx={{ color: 'error.main', display: 'block' }}
          >
            {errorMessage || schemeError}
          </Typography>
        )}
      />
    </>
  )
}

import React, { FC, useCallback, useContext, useMemo } from "react"
import * as Yup from "yup"
import {
  Button,
  Grid,
  Typography,
  DialogActions,
  DialogTitle,
} from "@mui/material"
import { useFormik, FormikContext, Form, FormikHelpers } from "formik"
import {
  TextField,
  FormDropdown,
  NumberTextField,
  Dialog,
} from "src/components/ui"
import { useDropdownItemFactory } from "src/hooks/ui"
import {
  ProductTipCreation,
  ProductTipEditing,
  ProductDraftCreation,
} from "src/api"
import {
  commonTipOptions,
  convertProductTipsToEditing,
  safetyTipOptions,
} from "src/utils/tips"
import { ProductDraftCommonTipTypeEnum, ProductDraftSafetyTipTypeEnum } from "src/enums"
import { ProductTipForm } from "./productTipForm"
import { AdditionalFields } from "./additionalFields"
import { ProductDraftsContext } from "../../../contexts/productDraftsContext"

const validationSchema = Yup.object().shape(
  {
    name: Yup.string().required("Name is required"),
    manufacturerId: Yup.number().required("Manufacturer is required"),
    typeId: Yup.number().nullable().required("Category is required"),
    packageTypeId: Yup.number().nullable(),
    shelfLife: Yup.number()
      .nullable()
      .integer("Shelf Life must be a positive number")
      .positive("Shelf Life must be a positive number"),
    temperatureFrom: Yup.number()
      .integer()
      .nullable()
      .when("temperatureTo", (temperatureTo, schema) =>
        Number.isNaN(parseInt(temperatureTo, 10))
          ? schema
          : schema.max(temperatureTo, "Low Temp should be less than High Temp")
      ),
    temperatureTo: Yup.number()
      .integer()
      .nullable()
      .when("temperatureFrom", (temperatureFrom, schema) =>
        Number.isNaN(parseInt(temperatureFrom, 10))
          ? schema
          : schema.min(
            temperatureFrom,
            "High Temp should be greater than Low Temp"
          )
      ),
    humidityFrom: Yup.number()
      .integer()
      .nullable()
      .moreThan(0, "Humidity must be greater than 0")
      .lessThan(101, "Humidity must be less or equal 100")
      .when("humidityTo", (humidityTo, schema) =>
        Number.isNaN(parseInt(humidityTo, 10))
          ? schema
          : schema.max(
            humidityTo,
            "Humidity Low should be less than Humidity High"
          )
      ),
    humidityTo: Yup.number()
      .integer()
      .nullable()
      .moreThan(0, "Humidity must be greater than 0")
      .lessThan(101, "Humidity must be less or equal 100")
      .when("humidityFrom", (humidityFrom, schema) =>
        Number.isNaN(parseInt(humidityFrom, 10))
          ? schema
          : schema.min(
            humidityFrom,
            "Humidity High should be greater than Humidity Low"
          )
      ),
    lowSlabRH: Yup.number()
      .integer()
      .nullable()
      .moreThan(0, "Humidity must be greater than 0")
      .lessThan(101, "Humidity must be less or equal 100")
      .when("highSlabRH", (highSlabRH, schema) =>
        Number.isNaN(parseInt(highSlabRH, 10))
          ? schema
          : schema.max(
            highSlabRH,
            "Humidity Low should be less than Humidity High"
          )
      ),
    highSlabRH: Yup.number()
      .integer()
      .nullable()
      .moreThan(0, "Humidity must be greater than 0")
      .lessThan(101, "Humidity must be less or equal 100")
      .when("lowSlabRH", (lowSlabRH, schema) =>
        Number.isNaN(parseInt(lowSlabRH, 10))
          ? schema
          : schema.min(
            lowSlabRH,
            "Humidity High should be greater than Humidity Low"
          )
      ),
    lowSurfaceTempF: Yup.number()
      .integer()
      .nullable()
      .when("highSurfaceTempF", (highSurfaceTempF, schema) =>
        Number.isNaN(parseInt(highSurfaceTempF, 10))
          ? schema
          : schema.max(
            highSurfaceTempF,
            "Low Surface Temp should be less than High Surface Temp"
          )
      ),
    highSurfaceTempF: Yup.number()
      .integer()
      .nullable()
      .when("lowSurfaceTempF", (lowSurfaceTempF, schema) =>
        Number.isNaN(parseInt(lowSurfaceTempF, 10))
          ? schema
          : schema.min(
            lowSurfaceTempF,
            "High Surface Temp should be greater than Low Surface Temp"
          )
      ),
    lowSlabPH: Yup.number()
      .integer()
      .nullable()
      .when("highSlabPH", (highSlabPH, schema) =>
        Number.isNaN(parseInt(highSlabPH, 10))
          ? schema
          : schema.max(
            highSlabPH,
            "Low Slab Ph should be less than High Slab Ph"
          )
      ),
    highSlabPH: Yup.number()
      .integer()
      .nullable()
      .when("lowSlabPH", (lowSlabPH, schema) =>
        Number.isNaN(parseInt(lowSlabPH, 10))
          ? schema
          : schema.min(
            lowSlabPH,
            "High Slab Ph should be greater than Low Slab Ph"
          )
      ),
    boardWidth: Yup.number()
      .nullable()
      .positive("Board Width must be a positive number"),
    highSlabMVER: Yup.number()
      .nullable()
      .integer("High Slab MVER must be a positive number")
      .positive("High Slab MVER must be a positive number"),
    commonTipList: Yup.array().of(
      Yup.object().shape({
        content: Yup.string().required("Tip field should be filled"),
      })
    ),
    safetyTipList: Yup.array().of(
      Yup.object().shape({
        content: Yup.string().required("Safety tip field should be filled"),
      })
    ),
    newInstallationTip: Yup.string(),
    newSafetyTip: Yup.string(),
  },
  [
    ["humidityTo", "humidityFrom"],
    ["temperatureFrom", "temperatureTo"],
    ["lowSlabRH", "highSlabRH"],
    ["lowSurfaceTempF", "highSurfaceTempF"],
    ["lowSlabPH", "highSlabPH"]
  ]
)

export interface ProductDraftNewState {
  name: string;
  manufacturerId?: number;
  typeId?: number;
  packageTypeId?: number;
  shelfLife?: number;
  temperatureFrom?: number;
  temperatureTo?: number;
  humidityFrom?: number;
  humidityTo?: number;
  highSlabMVER?: number;
  lowSlabRH?: number;
  highSlabRH?: number;
  lowSurfaceTempF?: number;
  highSurfaceTempF?: number;
  lowSlabPH?: number;
  highSlabPH?: number;
  boardWidth?: number;
  commonTipType: ProductDraftCommonTipTypeEnum;
  safetyTipType: ProductDraftSafetyTipTypeEnum;
  commonTipList: ProductTipCreation[] | ProductTipEditing[];
  safetyTipList: ProductTipCreation[] | ProductTipEditing[];
  commonNewTip?: string;
  safetyNewTip?: string;
}

const defaultInitial: ProductDraftNewState = {
  name: "",
  manufacturerId: void 0,
  typeId: void 0,
  packageTypeId: void 0,
  shelfLife: void 0,
  temperatureFrom: void 0,
  temperatureTo: void 0,
  humidityFrom: void 0,
  humidityTo: void 0,
  highSlabMVER: void 0,
  lowSlabRH: void 0,
  highSlabRH: void 0,
  lowSurfaceTempF: void 0,
  highSurfaceTempF: void 0,
  lowSlabPH: void 0,
  highSlabPH: void 0,
  boardWidth: void 0,
  safetyTipType: ProductDraftSafetyTipTypeEnum.Default,
  commonTipType: ProductDraftCommonTipTypeEnum.Default,
  commonNewTip: "",
  safetyNewTip: "",
  commonTipList: [],
  safetyTipList: [],
}

interface ProductDraftNewFormProps {
  open: boolean;
  defaultValues: Partial<ProductDraftNewState>;
  onSubmit: (formState: ProductDraftCreation) => Promise<unknown>;
  onCancel: () => void;
}

export const ProductDraftNewDialogForm: FC<ProductDraftNewFormProps> = ({
  open,
  defaultValues = {},
  onSubmit: formOnSubmit = () => { },
  onCancel,
}) => {
  const initialValues = { ...defaultInitial, ...defaultValues }

  const {
    manufacturers,
    productTypes,
    defaultTips,
    productPackageTypes,
    commonTipTypeId,
    safetyTipTypeId,
  } = useContext(ProductDraftsContext)

  const dropdownItemListMap = useDropdownItemFactory({
    params: { labelProp: "name", valueProp: "id" },
  })

  const onSubmit = async (
    data: ProductDraftNewState,
    formikHelpers: FormikHelpers<ProductDraftNewState>
  ) => {
    if (!data.name || !data.manufacturerId) {
      return
    }

    const commonCustomTips =
      data.commonTipType === ProductDraftCommonTipTypeEnum.Custom
        ? data.commonTipList
        : []
    const safetyCustomTips =
      data.safetyTipType === ProductDraftSafetyTipTypeEnum.Custom
        ? data.safetyTipList
        : []

    const result = {
      name: data.name,
      manufacturerId: data.manufacturerId,
      typeId: data.typeId,
      packageTypeId: data.packageTypeId,
      shelfLife: data.shelfLife,
      lowTempF: data.temperatureFrom,
      highTempF: data.temperatureTo,
      lowHumidity: data.humidityFrom,
      highHumidity: data.humidityTo,
      highSlabMVER: data.highSlabMVER,
      lowSlabRH: data.lowSlabRH,
      highSlabRH: data.highSlabRH,
      lowSurfaceTempF: data.lowSurfaceTempF,
      highSurfaceTempF: data.highSurfaceTempF,
      lowSlabPH: data.lowSlabPH,
      highSlabPH: data.highSlabPH,
      boardWidth: data.boardWidth,
      commonTipType: data.commonTipType,
      safetyTipType: data.safetyTipType,
      commonCustomTips,
      safetyCustomTips,
    }

    const response = await formOnSubmit(result)
    if (response) {
      onCancel()
      formikHelpers.resetForm()
    }
  }

  const formik = useFormik({
    validationSchema: validationSchema,
    initialValues,
    onSubmit,
    enableReinitialize: true,
    validateOnChange: true,
    validateOnBlur: true,
    validateOnMount: true,
  })

  const {
    values: formValues,
    errors,
    setFieldValue,
    submitForm,
    resetForm,
    isSubmitting,
  } = formik

  const getDefaultTipListByType = useCallback(
    (productTypeId?: number, typeId?: number) =>
      defaultTips.filter(
        (tip) => tip.typeId === typeId && tip.productTypeId === productTypeId
      ),
    [defaultTips]
  )

  const commonDefaultTipList = useMemo(
    () => getDefaultTipListByType(formValues.typeId, commonTipTypeId),
    [getDefaultTipListByType, commonTipTypeId, formValues.typeId]
  )

  const safetyDefaultTipList = useMemo(
    () => getDefaultTipListByType(formValues.typeId, safetyTipTypeId),
    [getDefaultTipListByType, safetyTipTypeId, formValues.typeId]
  )

  const handleChangeProductType = (productTypeId: number) => {
    const newCommonTips = getDefaultTipListByType(
      productTypeId,
      commonTipTypeId
    )
    const newSafetyTips = getDefaultTipListByType(
      productTypeId,
      safetyTipTypeId
    )

    setFieldValue("commonTipList", convertProductTipsToEditing(newCommonTips))
    setFieldValue("safetyTipList", convertProductTipsToEditing(newSafetyTips))
  }

  const handleClose = () => {
    onCancel()
    resetForm()
  }

  return (
    <FormikContext.Provider value={formik}>
      <Form>
        <Dialog
          open={open}
          title={<DialogTitle>New Product</DialogTitle>}
          scroll="paper"
          actions={
            <DialogActions>
              <Button
                size="large"
                variant="contained"
                color="primary"
                type="submit"
                disabled={Object.keys(errors).length > 0 || isSubmitting}
                onClick={submitForm}
              >
                Save
              </Button>
              <Button
                variant="outlined"
                size="large"
                type="button"
                onClick={handleClose}
              >
                Cancel
              </Button>
            </DialogActions>
          }
        >
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography variant="h6" sx={{ mt: 2 }}>
                Product information
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <FormDropdown
                list={manufacturers}
                name="manufacturerId"
                label="Manufacturer"
                required
                menuItemFactory={dropdownItemListMap}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="name"
                label="Name"
                required
                TextFieldProps={{
                  helperText:
                    "Specify package size in product name to differentiate this entry from similar items",
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <FormDropdown
                list={productTypes}
                name="typeId"
                label="Category*"
                menuItemFactory={dropdownItemListMap}
                onChange={([e]) => handleChangeProductType(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <FormDropdown
                list={productPackageTypes}
                name="packageTypeId"
                label="Package type"
                menuItemFactory={dropdownItemListMap}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="shelfLife"
                label="Shelf Life"
                TextFieldProps={{
                  InputProps: {
                    inputComponent: NumberTextField as any,
                  },
                  placeholder: "Days",
                }}
              />
            </Grid>
            <Grid item container spacing={2} xs={12}>
              <Grid item xs={6}>
                <TextField
                  name="temperatureFrom"
                  label="Ambient Min Install Temp"
                  TextFieldProps={{
                    InputProps: {
                      inputComponent: NumberTextField as any,
                    },
                    placeholder: "°F",
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  name="temperatureTo"
                  label="Ambient Max Install Temp"
                  TextFieldProps={{
                    InputProps: {
                      inputComponent: NumberTextField as any,
                    },
                    placeholder: "°F",
                  }}
                />
              </Grid>
            </Grid>
            <Grid item container spacing={2} xs={12}>
              <Grid item xs={6}>
                <TextField
                  name="humidityFrom"
                  label="Ambient RH% Min"
                  TextFieldProps={{
                    InputProps: {
                      inputComponent: NumberTextField as any,
                    },
                    placeholder: "%",
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  name="humidityTo"
                  label="Ambient RH% Max"
                  TextFieldProps={{
                    InputProps: {
                      inputComponent: NumberTextField as any,
                    },
                    placeholder: "%",
                  }}
                />
              </Grid>
            </Grid>
            {commonTipTypeId && (
              <Grid item xs={12} mt={1}>
                <ProductTipForm
                  type="common"
                  typeId={commonTipTypeId}
                  typeOptions={commonTipOptions}
                  label="Installation Tips"
                  defaultTipList={commonDefaultTipList}
                  checkIfEditable={(v) =>
                    v === ProductDraftCommonTipTypeEnum.Custom
                  }
                  checkIfDefault={(v) =>
                    v === ProductDraftCommonTipTypeEnum.Default
                  }
                />
              </Grid>
            )}
            {safetyTipTypeId && (
              <Grid item xs={12} mt={1}>
                <ProductTipForm
                  type="safety"
                  typeId={safetyTipTypeId}
                  typeOptions={safetyTipOptions}
                  label="Safety Tips"
                  defaultTipList={safetyDefaultTipList}
                  checkIfEditable={(v) =>
                    v === ProductDraftSafetyTipTypeEnum.Custom
                  }
                  checkIfDefault={(v) =>
                    v === ProductDraftSafetyTipTypeEnum.Default
                  }
                />
              </Grid>
            )}
            <Grid item mt={1}>
              <AdditionalFields />
            </Grid>
          </Grid>
        </Dialog>
      </Form>
    </FormikContext.Provider>
  )
}

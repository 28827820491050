import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { Entities } from 'src/enums'
import { Readout } from 'src/api'
import { ReadingType } from 'src/interfaces'
import { useApiInstance } from './useApiInstance'

interface RequestParams {
  sensorUniqueId: string;
  params?: {
    timeFrom?: string;
    timeTo?: string;
    reading?: ReadingType;
  };
  options?: Omit<
    UseQueryOptions<Readout, Error>,
    'queryKey' | 'queryFn'
  >;
}

export const useSensorReadoutRequest = ({
  sensorUniqueId,
  params = {},
  options,
}: RequestParams) => {
  const api = useApiInstance()
  return useQuery<Readout, Error>(
    [Entities.SensorReadout, sensorUniqueId],
    async () => {
      const { timeFrom, timeTo, reading } = params
      const { data } = await api.v1AdminSensorsUniqueIdReadoutGet(sensorUniqueId, timeFrom, timeTo, reading)
      return data as Readout
    },
    options,
  )
}

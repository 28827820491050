import { useMutation, UseMutationOptions } from '@tanstack/react-query'
import { Entities } from 'src/enums'
import { CombinedReportIncludedEventUpdatePayload, CombinedReportIncludedEvent } from 'src/api'
import { useApiInstance } from './useApiInstance'

interface RequestParams {
  options?: Omit<
    UseMutationOptions<CombinedReportIncludedEvent[], Error, CombinedReportIncludedEventUpdatePayload>,
    'mutationKey' | 'mutationFn'
  >;
}

export const useEditCombinedReportIncludedEventsRequest = ({ options }: RequestParams) => {
  const api = useApiInstance()

  return useMutation<CombinedReportIncludedEvent[], Error, CombinedReportIncludedEventUpdatePayload>(
    [Entities.CombinedReportIncludedEvent, 'edit'],
    async (payload: CombinedReportIncludedEventUpdatePayload) => {
      const { data } = await api.v1AdminReportsCombinedReportsIncludedEventsPut(payload)
      return data as CombinedReportIncludedEvent[]
    },
    {
      ...options,
    },
  )
}

import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { APIList } from 'src/interfaces'
import { Entities } from 'src/enums'
import { Project } from 'src/api'
import { useApiInstance } from './useApiInstance'
import {
  buildRequestParams,
  ObjectType,
  transformPage,
  transformPerPage,
} from './helpers'

interface RequestParams {
  params?: Partial<AdminProjectsGetParams>;
  paramsTransform?: Array<(params: Record<string, any>) => Record<string, any>>;
  options?: Omit<
    UseQueryOptions<unknown, Error, APIList<Project>>,
    'queryKey' | 'queryFn'
  >;
}

export interface AdminProjectsGetParams {
  limit: number;
  offset: number;
  sort: string;
  contractorId: number;
  typeId: number;
  city: string;
  productId: number;
  userId: number;
  search: string;
  hideArchivedProjects: boolean;
}

const schema: ObjectType['schema'] = {
  limit: 'number',
  offset: 'number',
  sort: 'string',
  contractorId: 'number',
  typeId: 'number',
  city: 'string',
  productId: 'number',
  userId: 'number',
  search: 'string',
  hideArchivedProjects: 'boolean',
}

export const useProjectListRequest = ({
  params = {},
  paramsTransform = [],
  options,
}: RequestParams = {}) => {
  const api = useApiInstance()

  const transformedParams = [
    transformPerPage,
    transformPage,
    ...paramsTransform,
  ].reduce((param, transform) => transform(param), params)

  const queryParams = buildRequestParams<AdminProjectsGetParams>(
    transformedParams,
    schema,
  )

  return useQuery<unknown, Error, APIList<Project>>(
    [Entities.Projects, JSON.stringify(transformedParams)],
    async () => {
      const {
        limit,
        offset,
        sort,
        contractorId,
        typeId,
        city,
        productId,
        userId,
        search,
        hideArchivedProjects,
      } = queryParams
      const { data } = await api.v1AdminProjectsGet(
        limit,
        offset,
        sort,
        contractorId,
        typeId,
        city,
        productId,
        userId,
        search,
        typeof hideArchivedProjects === 'boolean' ? hideArchivedProjects : undefined,
      )
      return data
    },
    {
      enabled: Object.keys(transformedParams).length > 0,
      ...options,
    },
  )
}

import { Box, Grid, Typography } from "@mui/material"
import { GridColDef } from "@mui/x-data-grid-premium"
import format from "date-fns/format"
import { Task } from "src/api"
import { ClosedEye } from "src/components/icons"
import { InnerLink } from "src/components/ui"
import { ImagesListItem } from "src/contexts"

export namespace TaskColumnsDataGrid {
  export const NameWithIcon: GridColDef<Task> = {
    field: 'name',
    headerName: 'Name',
    type: 'string',
    minWidth: 200,
    renderCell: ({ row }) => {
      const isArchived = row.isActive === false
      return (
        <Grid container alignItems="center" spacing={1}>
          <Grid item>
            <Typography variant="body2">{row.name}</Typography>
          </Grid>
          {isArchived && (
            <Grid container item title="Archived task" xs={2}>
              <ClosedEye />
            </Grid>
          )}
        </Grid>
      )
    }
  }

  export const Description: GridColDef<Task> = {
    field: 'description',
    headerName: 'Description',
    type: 'string',
    minWidth: 200,
    valueFormatter: ({ value }) => value || '-',
  }

  export const Author: GridColDef<Task> = {
    field: 'author.name',
    headerName: 'Author',
    type: 'string',
    minWidth: 200,
    valueGetter: ({row}) => row.author?.name || null,
    renderCell: ({ row, value }) => {
      if (value) {
        const id = row.authorId
        const name = value
        return <InnerLink to={`/users/${id}`}>{name}</InnerLink>
      }
      return '-'
    },
  }

  export const Assignee: GridColDef<Task> = {
    field: 'assignee.name',
    headerName: 'Assignee',
    type: 'string',
    minWidth: 200,
    valueGetter: ({row}) => row.assignee?.name || null,
    renderCell: ({ row, value }) => {
      if (value) {
        const id = row.assigneeId
        const name = value
        return <InnerLink to={`/users/${id}`}>{name}</InnerLink>
      }
      return '-'
    },
  }

  export const Project: GridColDef<Task> = {
    field: 'project.name',
    headerName: 'Project',
    type: 'string',
    minWidth: 200,
    valueGetter: ({row}) => row.project?.name || null,
    renderCell: ({ row, value }) => {
      if (value) {
        const id = row.projectId
        const name = value
        return <InnerLink to={`/projects/${id}`}>{name}</InnerLink>
      }
      return '-'
    },
  }

  export const ProjectSection: GridColDef<Task> = {
    field: 'projectSection.name',
    headerName: 'Section',
    type: 'string',
    minWidth: 200,
    valueGetter: ({row}) => row.projectSection?.name || null,
    renderCell: ({ row, value }) => {
      if (value) {
        const id = row.projectSectionId
        const name = value
        return <InnerLink to={`/sections/${id}`}>{name}</InnerLink>
      }
      return '-'
    },
  }

  export const Contractor: GridColDef<Task> = {
    field: 'contractor.name',
    headerName: 'Contractor',
    type: 'string',
    minWidth: 200,
    valueGetter: ({row}) => row.contractor?.name || null,
    renderCell: ({ row, value }) => {
      if (value) {
        const id = row.contractorId
        const name = value
        return <InnerLink to={`/contractors/${id}`}>{name}</InnerLink>
      }
      return '-'
    },
  }

  export const Status: GridColDef<Task> = {
    field: 'status',
    headerName: 'Status',
    type: 'string',
    minWidth: 100,
  }

  export const CreatedAt: GridColDef<Task> = {
    field: 'createdAt',
    headerName: 'Created At',
    type: 'string',
    minWidth: 200,
    valueFormatter: ({ value }) => value ? format(new Date(value), 'PPpp') : '-'
  }

  export const Photo = (openGallery: (imgs: ImagesListItem[]) => void): GridColDef<Task> => ({
    field: "photo",
    headerName: "Photo",
    type: "string",
    minWidth: 150,
    editable: false,
    renderCell: ({ row }) => {
      const photoAmount = row.photos?.length || 0
      if (!row.photos || photoAmount === 0) {
        return <Typography variant="body2">-</Typography>
      }

      const images: { src: string }[] = row.photos
        .map((photo) => ({ src: photo.original.link || '' }))
        .filter((photo) => Boolean(photo.src))
      return (
        <Box onClick={() => openGallery(images)}>
          <Typography
            variant="body2"
            color='#037BEB'
            sx={{ cursor: 'pointer' }}
          >
            {images.length} photo
          </Typography>
        </Box>
      )
    },
  })
}
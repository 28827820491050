import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { Entities } from 'src/enums'
import { ReportType } from 'src/api'
import { useApiInstance } from './useApiInstance'
import {
  buildRequestParams,
  ObjectType,
  transformPage,
  transformPerPage,
} from './helpers'

interface RequestParams {
  params?: Partial<AdminReportTypesGetParams>;
  paramsTransform?: Array<(params: Record<string, any>) => Record<string, any>>;
  options?: Omit<
    UseQueryOptions<ReportType[], Error>,
    'queryKey' | 'queryFn'
  >;
}

interface AdminReportTypesGetParams {
  sort: string;
}

const schema: ObjectType['schema'] = {
  sort: 'string',
}

export const useReportTypeListRequest = ({
  params = {},
  paramsTransform = [],
  options,
}: RequestParams = {}) => {
  const api = useApiInstance()

  const transformedParams = [
    transformPerPage,
    transformPage,
    ...paramsTransform,
  ].reduce((param, transform) => transform(param), params)

  const queryParams = buildRequestParams<AdminReportTypesGetParams>(
    transformedParams,
    schema,
  )

  return useQuery<ReportType[], Error>(
    [Entities.ReportTypes, JSON.stringify(transformedParams)],
    async () => {
      const {
        sort,
      } = queryParams
      const { data } = await api.v1AdminReportsTypesGet(sort)
      return data as ReportType[]
    },
    options,
  )
}

import React, { FC, useState, Fragment } from 'react'
import {
  Grid,
  Divider,
} from '@mui/material'
import { ReportType } from 'src/api'

import { InlineReportType } from './inlineReportType'
import { EditableReportType } from './editableReportType'

interface ReportTypeListProps {
  data: ReportType[];
  onChange: () => void;
}

export const ReportTypeList: FC<ReportTypeListProps> = ({ data, onChange }) => {
  const [editId, setEditId] = useState<number | null>(null)
  const updateEditId = (id: number | null) => {
    setEditId(id)
  }

  const saveChanges = () => {
    updateEditId(null)
    onChange()
  }

  return (
    <>
      <Grid container direction="column" spacing={2}>
        {data.map(({ id, displayName, description, isActive }, idx, list) => (
          <Fragment key={idx}>
            <Grid item container spacing={1} alignItems="center">
              {editId === id ? (
                <EditableReportType
                  id={id}
                  name={displayName || ''}
                  reportTypeDescription={description}
                  onChange={saveChanges}
                  onCancel={() => updateEditId(null)}
                />
              ) : (
                <InlineReportType
                  id={id as number}
                  name={displayName || ''}
                  description={description || ''}
                  isVisible={isActive}
                  onEdit={updateEditId}
                />
              )}
            </Grid>
            {idx !== list.length - 1 && (
              <Divider flexItem={true} sx={{ ml: 2, mt: 2 }} />
            )}
          </Fragment>
        ))}
      </Grid>
    </>
  )
}
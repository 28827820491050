import React, { FC } from "react"
import {
  Typography,
  DialogActions,
  Button,
  Box,
  DialogTitle,
} from "@mui/material"
import { Dialog } from "src/components/ui"

interface ProductDeleteConfirmationModalProps {
  open: boolean;
  productDraftName: string;
  onDelete: () => void;
  onCancel: () => void;
}

export const ProductDeleteConfirmationModal: FC<ProductDeleteConfirmationModalProps> =
  ({ open, productDraftName, onDelete, onCancel }) => {
    return (
      <Dialog
        open={open}
        onClose={onCancel}
        title={
          <DialogTitle>
            Removing the product
          </DialogTitle>
        }
        DialogProps={{ fullWidth: true, maxWidth: "xs" }}
        actions={
          <DialogActions>
            <Button variant="contained" onClick={onDelete}>
              Delete
            </Button>
            <Button variant="outlined" onClick={onCancel}>
              Cancel
            </Button>
          </DialogActions>
        }
      >
        <Typography>
          The product <Typography component="span" fontStyle="italic" fontWeight={600}>{productDraftName}</Typography> will be deleted completely.
        </Typography>
        <Box mt={2} />
        <Typography fontWeight={600}>
          Do you want to proceed?
        </Typography>
      </Dialog>
    )
  }

import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { Entities } from 'src/enums'
import { AdminMobileSettings } from 'src/api'
import { useApiInstance } from './useApiInstance'

interface RequestParams {
  options?: Omit<
    UseQueryOptions<unknown, Error, AdminMobileSettings>,
    'queryKey' | 'queryFn'
  >;
}

export const useMobileSettingsRequest = ({ options }: RequestParams = {}) => {
  const api = useApiInstance()

  return useQuery<unknown, Error, AdminMobileSettings>(
    [Entities.MobileSettings],
    async () => {
      const { data } = await api.v1AdminConfigurationMobileSettingsGet()
      return data
    },
    {
      ...options,
    },
  )
}

import { FC } from 'react'
import {
  Button,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  Typography,
  List,
  ListItem,
  ListItemText,
} from '@mui/material'
import { DialogNames } from 'src/enums'
import { StatefulDialog } from 'src/components/ui'
import { Contractor, User } from 'src/api'

interface Props {
  onEditContractor: () => void
  onCloseDialog: () => void
  contractorName: string
  branches: Contractor[]
  users: User[]
}

export const ChangeContractorTypeModal: FC<Props> = ({
  onEditContractor,
  onCloseDialog,
  contractorName,
  branches,
  users,
}) => {
  return (
    <StatefulDialog
      name={DialogNames.ChangeContractorType}
      onClose={onCloseDialog}
      actions={
        <DialogActions>
          <Button onClick={onCloseDialog}>
            Close
          </Button>
          <Button
            onClick={onEditContractor}
            color='error'
          >
            Save changes
          </Button>
        </DialogActions>
      }
    >
      <DialogTitle id="alert-dialog-title">
        HQ contractor changes
      </DialogTitle>
      <DialogContent>
        <DialogContentText component='div' sx={{ mb: 2 }}>
          <Typography variant="body1" component="div">
            <Typography component="span">The contractor "{contractorName}" is the headquarters, you are going to make it a regular one, this will entail the </Typography>
            <Typography component="span" fontWeight={600}>following changes:</Typography>
          </Typography>
          <List sx={{ listStyleType: "disc", paddingLeft: '15px' }}>
            <ListItem sx={{ display: "list-item" }}>
              <ListItemText primary="All branches will be unassigned" />
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              <ListItemText primary={`"${contractorName}" users will lose access to all projects of branches`} />
            </ListItem>
          </List>
          <Typography variant="body1" fontWeight={600}>
            The list of branches:
          </Typography>
          <List sx={{ listStyleType: "disc", paddingLeft: '15px' }}>
            {branches.map((branch) => (
              <ListItem key={branch.id} sx={{ display: "list-item" }}>
                <ListItemText primary={branch.name} />
              </ListItem>
            ))}
          </List>
          <Typography variant="body1" fontWeight={600}>
            The list of affected users:
          </Typography>
          <List sx={{ listStyleType: "disc", paddingLeft: '15px' }}>
            {users.map((user) => (
              <ListItem key={user.id} sx={{ display: "list-item" }}>
                <ListItemText primary={user.name} />
              </ListItem>
            ))}
          </List>
        </DialogContentText>
      </DialogContent>
    </StatefulDialog>
  )
}

import React, { FC, forwardRef } from 'react'
import { useLocation } from 'react-router-dom'
import { Link as MuiLink, LinkProps } from '@mui/material'

export const StyledOuterLink: FC<LinkProps & { navigate?: () => void }> = forwardRef(
  ({ navigate, href, children, onClick, ...restProps }, ref) => {
    const { pathname } = useLocation()
    return (
      <MuiLink
        ref={ref}
        href={href}
        onClick={(event) => {
          if (onClick) onClick(event)
          if (href !== pathname && navigate) navigate()
        }}
        {...restProps}
      >
        {children}
      </MuiLink>
    )
  }
)

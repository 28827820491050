import { useMutation, UseMutationOptions } from '@tanstack/react-query'
import { Entities } from 'src/enums'
import { Product, SensorUpdateState } from 'src/api'
import { useApiInstance } from './useApiInstance'

interface RequestParams {
  options?: Omit<
    UseMutationOptions<
      unknown,
      Error,
      { uniqueId: string; payload: SensorUpdateState },
      Product
    >,
    'mutationKey' | 'mutationFn'
  >;
}

export const usePatchSensorRequest = ({ options }: RequestParams = {}) => {
  const api = useApiInstance()

  return useMutation<
    unknown,
    Error,
    { uniqueId: string; payload: SensorUpdateState },
    Product
  >(
    [Entities.Products],
    async ({ uniqueId, payload }) => {
      const { data } = await api.v1AdminSensorsUniqueIdPatch(uniqueId, payload)
      return data
    },
    { ...options },
  )
}

import React, { FC, ChangeEvent, FocusEvent, useState, useEffect } from 'react'
import {
  TextField as MuiTextField,
  TextFieldProps as MuiTextFieldProps,
} from '@mui/material'

interface TextFieldProps {
  value: string;
  label?: string;
  required?: boolean;
  error?: string;
  TextFieldProps?: MuiTextFieldProps;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (event: FocusEvent<HTMLInputElement>) => void;
}

export const SimpleTextField: FC<TextFieldProps> = ({
  value,
  label,
  required = false,
  TextFieldProps,
  onChange,
  onBlur,
}) => {
  const [inputValue, setInputValue] = useState<string>('')
  useEffect(() => {
    setInputValue(value)
  }, [value])

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value)
    if (onChange) onChange(event)
  }
  const handleBlur = (event: FocusEvent<HTMLInputElement>) => {
    setInputValue(event.target.value)
    if (onBlur) onBlur(event)
  }
  return (
    <>
      <MuiTextField
        variant="outlined"
        fullWidth
        margin="dense"
        InputLabelProps={{ shrink: true }}
        {...TextFieldProps}
        onChange={handleChange}
        onBlur={handleBlur}
        value={inputValue}
        required={required}
        label={label}
      />
    </>
  )
}

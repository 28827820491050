import { useMutation, UseMutationOptions } from '@tanstack/react-query'
import { Entities } from 'src/enums'
import { useApiInstance } from './useApiInstance'

interface Payload {
  message: string
  recipient: string
  productRequestId: number
}

interface RequestParams {
  options?: Omit<
    UseMutationOptions<boolean, Error, Payload>,
    'mutationKey' | 'mutationFn'
  >;
}

export const useProductRequestSendSummaryRequest = ({ options }: RequestParams = {}) => {
  const api = useApiInstance()

  return useMutation<boolean, Error, Payload>(
    [Entities.ProductRequest, 'sendSummary'],
    async (payload) => {
      const { productRequestId, ...rest } = payload
      const { data } = await api.v1AdminProductsRequestsProductRequestIdSendSummaryPost(productRequestId, rest)
      return data as unknown as boolean
    },
    options,
  )
}

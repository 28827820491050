import React, { FC, cloneElement, ReactElement, PropsWithChildren } from 'react'

const patchProps = (props: Record<string, any>) => (child: ReactElement, key?: number) =>
  cloneElement(child, {
    ...(key !== void 0 ? { key } : void 0),
    ...(props as Record<string, any>),
  })

interface PropsInjectorProps {
  props?: Record<string, any>;
}

export const PropsInjector: FC<PropsWithChildren<PropsInjectorProps>> = ({ children, props }) => {
  if (
    !props ||
    children === void 0 ||
    children === null ||
    typeof children === 'boolean'
  )
    return <>{children}</>

  return (
    <>
      {Array.isArray(children)
        ? children.map(patchProps(props))
        : patchProps(props)(children as ReactElement)}
    </>
  )
}

import { useMutation, UseMutationOptions } from '@tanstack/react-query'
import { Entities } from 'src/enums'
import { Sensor } from 'src/api'
import { useApiInstance } from './useApiInstance'

interface RequestParams {
  options?: Omit<
    UseMutationOptions<unknown, Error, string, Sensor>,
    'mutationKey' | 'mutationFn'
  >;
}

export const useSensorConfiguring = ({ options }: RequestParams = {}) => {
  const api = useApiInstance()

  return useMutation<unknown, Error, string, Sensor>(
    [Entities.ProductTypesTips, 'update'],
    async (uniqueId: string) => {
      const { data } = await api.v1AdminSensorsUniqueIdConfigurePost(uniqueId)
      return data
    },
    {
      ...options,
    },
  )
}

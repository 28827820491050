import { FC } from 'react'
import { FirmwareHistoryItem } from 'src/api'
import {
  DataGridPremium,
  useGridApiRef,
} from '@mui/x-data-grid-premium'
import { SensorFirmwareHistory as SensorFirmwareHistoryColumns } from "src/components/columns/sensor"

interface Props {
  history: FirmwareHistoryItem[]
}

const getColumns = () => [
  SensorFirmwareHistoryColumns.Firmware,
  SensorFirmwareHistoryColumns.DateRange,
]

export const SensorFirmwareHistory: FC<Props> = ({ history }) => {
  const apiRef = useGridApiRef()
  return (
    <DataGridPremium<FirmwareHistoryItem>
      apiRef={apiRef}
      autoHeight
      disableRowSelectionOnClick
      rows={history}
      columns={getColumns()}
      rowThreshold={2}
      columnThreshold={2}
      localeText={{
        columnMenuSortAsc: "Sort A-Z",
        columnMenuSortDesc: "Sort Z-A",
      }}
      sx={(theme) => ({
        border: "none",
        "& .MuiDataGrid-columnHeaderTitleContainerContent": {
          paddingLeft: "5px",
          overflow: "visible",
        },
        "& .MuiDataGrid-columnHeaderTitle": {
          whiteSpace: "normal",
          lineHeight: 1,
          fontSize: "13px",
          fontWeight: 400,
          color: "rgba(0, 0, 0, 0.6)",
          overflow: "visible",
        },
      })}
    />
  )
}

import React, { FC } from 'react'
import { Project } from 'src/api'
import { DynamicColumn, Table, TableProps as TablePropsInterface } from '../../ui'
import ProjectColumns from '../../columns/project'

const defaultColumns = [
  ProjectColumns.Name,
  ProjectColumns.Location,
  ProjectColumns.ContractorName,
  ProjectColumns.UserName,
  ProjectColumns.ProjectTypeName,
  ProjectColumns.Size,
  ProjectColumns.ConnectedSensors,
]

interface ProjectListProps {
  loading?: boolean;
  count?: number;
  data?: Project[];
  noResultText?: string;
  showFooter?: boolean;
  TableProps?: Omit<
    TablePropsInterface<Project>,
    'loading' | 'data' | 'columns' | 'count'
  >;
  columns?: DynamicColumn<Project>[];
}

export const ProjectList: FC<ProjectListProps> = ({
  loading = false,
  data = [],
  count = 0,
  noResultText,
  showFooter,
  TableProps,
  columns,
}) => {
  return (
    <Table
      {...TableProps}
      loading={loading}
      data={data}
      columns={columns || defaultColumns}
      count={count}
      noResultText={noResultText}
      showFooter={showFooter}
    />
  )
}
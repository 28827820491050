import { FC } from 'react'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import Box from '@mui/material/Box'
import { FloorcloudSensorLocationType } from 'src/enums'
import { useDownloadingFile } from 'src/hooks/ui'

export const ExportSensorLocationData: FC = () => {
  const { downloadFile, isLoading } = useDownloadingFile()
  const downloadGpxData = async () => {
    const types: FloorcloudSensorLocationType[] = [FloorcloudSensorLocationType.GPS, FloorcloudSensorLocationType.CELLULAR]
    for (const type of types) {
      const urlPath = `v1/admin/sensors/gpx-waypoints-data`
      const queryParams = { type }
      await downloadFile({ urlPath, queryParams })
    }
  }

  return (
    <Box mx={2} display="inline">
      <Button
        onClick={downloadGpxData}
        disabled={isLoading}
        variant="contained"
      >
        {isLoading ? <CircularProgress size={22} /> : "Export location data"}
      </Button>
    </Box>
  )
}

import { Grid } from "@mui/material"
import React, { FC } from "react"
import { TimeseriesGapChart } from "./timeseriesGapChart"


export const DiagnosticsPerformanceTab: FC = () => {
  return (
    <Grid container direction="column" spacing={4}>
      <Grid item>
        <TimeseriesGapChart />
      </Grid>
    </Grid>
  )
}

import React, { FC, useState, PropsWithChildren } from 'react'
import { NewProductRequestNumberContext } from 'src/contexts'

export const NewProductRequestNumberProvider: FC<PropsWithChildren> = ({ children }) => {
  const [newProductRequestNumber, setNewProductRequestNumber] = useState<number>(0)

  return (
    <NewProductRequestNumberContext.Provider value={{ newProductRequestNumber, setNewProductRequestNumber }}>
      {children}
    </NewProductRequestNumberContext.Provider>
  )
}

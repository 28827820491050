import React, { FC, useRef, useCallback } from 'react'
import { Grid, Button, IconButton, InputAdornment } from '@mui/material'
import { Close } from '@mui/icons-material'
import { FormikContext, Form, useFormik } from 'formik'
import { TextField } from 'src/components'
import { SearchParamsKeys } from 'src/enums'
import { useQueryParams } from 'src/hooks/ui'

interface SearchFormProps {
  placeholder?: string;
}

export const SearchForm: FC<SearchFormProps> = ({
  placeholder = 'Enter at least three symbols to start searching',
}) => {
  const inputRef = useRef<HTMLInputElement | null>(null)

  const [queryParams, setQueryParams, removeQueryParams] = useQueryParams()

  const initialValues = { search: queryParams[SearchParamsKeys.Search] || '' }

  const onSubmit = useCallback(
    async ({ search }: any) => {
      await setQueryParams({
        [SearchParamsKeys.Search]: search,
        [SearchParamsKeys.Page]: "1"
      })
    },
    [setQueryParams]
  )

  const formik = useFormik({
    initialValues,
    onSubmit,
  })

  const { values, submitForm, setFieldValue } = formik

  const onEnterPress = useCallback(
    ({ keyCode, target }: any) => {
      if (keyCode === 13 && target?.value?.length >= 3) submitForm()
    },
    [submitForm]
  )

  const onReset = useCallback(async () => {
    setFieldValue(SearchParamsKeys.Search, '')
    await removeQueryParams([SearchParamsKeys.Search])
    if (inputRef?.current) inputRef.current.focus()
  }, [setFieldValue, removeQueryParams])

  return (
    <FormikContext.Provider value={formik}>
      <Form>
        <Grid container spacing={4} alignItems="center">
          <Grid item>
            <TextField
              name="search"
              TextFieldProps={{
                autoFocus: true,
                autoComplete: 'off',
                onKeyUp: onEnterPress,
                placeholder,
                sx: { width: 450 },
                inputRef,
                InputProps: {
                  endAdornment: (
                    <InputAdornment position="end">
                      {(values?.search?.length > 0 ||
                        queryParams[SearchParamsKeys.Search]?.length > 0) && (
                        <IconButton size="small" onClick={onReset}>
                          <Close fontSize="small" />
                        </IconButton>
                      )}
                    </InputAdornment>
                  ),
                },
              }}
            />
          </Grid>
          <Grid item>
            <Button
              type="submit"
              variant="contained"
              size="large"
              disabled={values?.search?.length < 3}
            >
              Search
            </Button>
          </Grid>
        </Grid>
      </Form>
    </FormikContext.Provider>
  )
}

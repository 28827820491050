import React, { FC } from "react"
import { useNavigate } from "react-router-dom"
import { HasSidebarLayout, ContractorNewForm } from "src/components"
import { useNewContractorRequest } from "src/hooks/api"
import { useAddPopupMessage } from "src/hooks/ui"
import { ContractorCreationV2 } from "../api"

const NewContractor: FC = () => {
  document.title = 'Floorcloud Admin Panel - New contractor'
  const navigate = useNavigate()
  const addMessage = useAddPopupMessage()
  const request = useNewContractorRequest({
    options: {
      onSuccess: () => {
        navigate(-1)
      },
      onError: ({ message: text }) => {
        addMessage({ text, type: "error" })
      },
    },
  })

  const onSubmit = (data: ContractorCreationV2, photo: File | null) => {
    if (photo) {
      request.mutate({...data, photos: [photo]})
    } else {
      request.mutate(data)
    }
  }
  return (
    <HasSidebarLayout>
      <ContractorNewForm onSubmit={onSubmit} isLoading={request.isLoading} />
    </HasSidebarLayout>
  )
}

export default NewContractor

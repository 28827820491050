import { IconButton } from "@mui/material"
import { Settings } from "@mui/icons-material"
import { Link } from "react-router-dom"
import React, { FC } from "react"

interface ManufacturerSettingsIconButtonProps {
  manufacturerId: number;
}

export const ManufacturerSettingsIconButton: FC<ManufacturerSettingsIconButtonProps> =
  ({ manufacturerId }) => {
    return (
      <Link to={`/manufacturers/${manufacturerId}/edit`}>
        <IconButton>
          <Settings />
        </IconButton>
      </Link>
    )
  }

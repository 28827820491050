import { useMutation, UseMutationOptions } from '@tanstack/react-query'
import { Entities } from 'src/enums'
import { Product } from 'src/api'
import { useApiInstance } from './useApiInstance'

interface RequestParams {
  options?: Omit<UseMutationOptions<unknown, Error, File, Product>, 'mutationKey' | 'mutationFn'>
}

export const useUploadProductRequest = ({
  options,
}: RequestParams = {}) => {
  const api = useApiInstance()

  return useMutation<unknown, Error, File, Product>(
    [Entities.Products, 'upload'],
    async (file: File) => {
      const { data } = await api.v1AdminProductsUploadPut(file)
      return data
    },
    {
      ...options,
    },
  )
}

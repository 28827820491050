import React, { useContext } from "react"
import {
  GridActionsColDef,
  GridColDef,
  GridRenderCellParams,
  GridActionsCellItem,
} from "@mui/x-data-grid-premium"
import format from "date-fns/format"
import MoreVertIcon from "@mui/icons-material/MoreVert"
import { TableCellWithTooltip } from "./tableCellWithTooltip"
import { SimpleTableCell } from "./simpleTableCell"
import { BoardWidthEditInputCell } from "./tableEditBoardWidthCell"
import { ProductDraftsContext } from "../../../../contexts/productDraftsContext"
import { Product, ProductDraft, ProductType } from "../../../../api"

const createCellRendererWithPrevValue =
  (field: string) => (params: GridRenderCellParams<ProductDraft, any, any>) => {
    const original = params.row.original

    const isProductKey = (x: string): x is keyof Product => {
      return original?.hasOwnProperty(x) || false
    }

    const isChanged =
      isProductKey(field) && original && original[field] !== params.value

    if (isChanged) {
      return (
        <TableCellWithTooltip
          title={isChanged ? `Prev value: ${original[field] ?? "-"}` : ""}
        >
          {params.value ?? "-"}
        </TableCellWithTooltip>
      )
    }

    return <SimpleTableCell>{params.value ?? "-"}</SimpleTableCell>
  }

const tipsCellRenderer = (
  params: GridRenderCellParams<ProductDraft, any, any>
) => {
  return <SimpleTableCell capitalize>{params.value ?? "-"}</SimpleTableCell>
}

const renderProductTypeCell =
  (productTypes: ProductType[]) =>
    (params: GridRenderCellParams<ProductDraft, any, any>) => {
      const original = params.row.original

      const getProductTypeName = (typeId?: number) =>
        productTypes.find(({ id }) => id === typeId)?.name || "-"

      const isChanged = original && original?.typeId !== params.value
      if (isChanged) {
        return (
          <TableCellWithTooltip
            title={
              isChanged
                ? `Prev value: ${getProductTypeName(original.typeId)}`
                : ""
            }
          >
            {getProductTypeName(params.value)}
          </TableCellWithTooltip>
        )
      }

      return (
        <SimpleTableCell>{getProductTypeName(params.value)}</SimpleTableCell>
      )
    }

const renderCommentCell = (
  params: GridRenderCellParams<ProductDraft, any, any>
) => {
  return (
    <TableCellWithTooltip
      title={params.value ? `Comment: ${params.value}` : ""}
    >
      {params.value ? "Edit" : "Add"}
    </TableCellWithTooltip>
  )
}

const renderManufacturerCommentCell = (
  params: GridRenderCellParams<ProductDraft, any, any>
) => {
  return (
    <TableCellWithTooltip
      title={params.value ? `Manufacturer comment: ${params.value}` : ""}
    >
      {params.value ? "Edit" : "Add"}
    </TableCellWithTooltip>
  )
}

export const useTableColumns = (
  handleOpenMenu: (
    event: React.MouseEvent<HTMLElement>,
    row: ProductDraft
  ) => void
): GridActionsColDef<ProductDraft>[] | GridColDef<ProductDraft>[] => {
  const { selectedProductDraftIds, productTypes } =
    useContext(ProductDraftsContext)

  return [
    {
      field: "actions",
      headerName: "",
      type: "actions",
      width: 30,
      getActions: (params) => [
        <GridActionsCellItem
          icon={<MoreVertIcon />}
          disabled={selectedProductDraftIds.length > 0}
          label="Actions"
          className="textPrimary"
          onClick={(e: any) => handleOpenMenu(e, params.row)}
          color="inherit"
        />,
      ],
    },
    {
      field: "name",
      headerName: "Product Name",
      type: "string",
      minWidth: 180,
      editable: true,
      renderCell: createCellRendererWithPrevValue("name"),
    },
    {
      field: "status",
      headerName: "Status",
      type: "string",
      editable: false,
      renderCell: (params) => (
        <div
          style={{
            color: params.value === "updated" ? "#C71616" : "#000",
            textTransform: "capitalize",
          }}
        >
          {params.value}
        </div>
      ),
    },
    {
      field: "typeId",
      headerName: "Category",
      type: "singleSelect",
      minWidth: 180,
      valueOptions: productTypes.map(({ id, name }) => ({
        value: id,
        label: name,
      })),
      editable: true,

      renderCell: renderProductTypeCell(productTypes),
    },
    {
      field: "shelfLife",
      headerName: "Shelf Life, days",
      type: "number",
      editable: true,
      renderCell: createCellRendererWithPrevValue("shelfLife"),
    },
    {
      field: "lowTempF",
      headerName: "Ambient Min Install Temp, ℉",
      width: 120,
      type: "number",
      editable: true,
      renderCell: createCellRendererWithPrevValue("lowTempF"),
    },
    {
      field: "highTempF",
      headerName: "Ambient Max Install Temp, ℉",
      width: 120,
      type: "number",
      editable: true,
      renderCell: createCellRendererWithPrevValue("highTempF"),
    },
    {
      field: "lowHumidity",
      headerName: "Ambient RH% Min",
      width: 120,
      type: "number",
      editable: true,
      renderCell: createCellRendererWithPrevValue("lowHumidity"),
    },
    {
      field: "highHumidity",
      headerName: "Ambient RH% Max",
      width: 120,
      type: "number",
      editable: true,
      renderCell: createCellRendererWithPrevValue("highHumidity"),
    },
    {
      field: "commonTipType",
      headerName: "Installation Tips",
      type: "string",
      editable: false,
      renderCell: tipsCellRenderer,
    },
    {
      field: "safetyTipType",
      headerName: "Safety Tips",
      type: "string",
      editable: false,
      renderCell: tipsCellRenderer,
    },
    {
      field: "highSlabMVER",
      headerName: "Slab MVER (CaCl) Max, lbs",
      width: 140,
      type: "number",
      editable: true,
      renderCell: createCellRendererWithPrevValue("highSlabMVER"),
    },
    {
      field: "lowSlabRH",
      headerName: "Slab RH% Min",
      width: 120,
      type: "number",
      editable: true,
      renderCell: createCellRendererWithPrevValue("lowSlabRH"),
    },
    {
      field: "highSlabRH",
      headerName: "Slab RH% Max",
      width: 120,
      type: "number",
      editable: true,
      renderCell: createCellRendererWithPrevValue("highSlabRH"),
    },
    {
      field: "lowSurfaceTempF",
      headerName: "Surface Temp Min, ℉",
      width: 120,
      type: "number",
      editable: true,
      renderCell: createCellRendererWithPrevValue("lowSurfaceTempF"),
    },
    {
      field: "highSurfaceTempF",
      headerName: "Surface Temp Max, ℉",
      width: 120,
      type: "number",
      editable: true,

      renderCell: createCellRendererWithPrevValue("highSurfaceTempF"),
    },
    {
      field: "lowSlabPH",
      headerName: "pH Min",
      width: 100,
      type: "number",
      editable: true,
      renderCell: createCellRendererWithPrevValue("lowSlabPH"),
    },
    {
      field: "highSlabPH",
      headerName: "pH Max",
      width: 100,
      type: "number",
      editable: true,
      renderCell: createCellRendererWithPrevValue("highSlabPH"),
    },
    {
      field: "boardWidthFraction",
      headerName: "Board Width",
      width: 200,
      type: "string",
      editable: true,
      renderCell: createCellRendererWithPrevValue("boardWidthFraction"),
      renderEditCell: (params) => <BoardWidthEditInputCell {...params} />,
    },
    {
      field: "comment",
      headerName: "Internal Comments",
      type: "string",
      editable: false,
      renderCell: renderCommentCell,
    },
    {
      field: "manufacturerComment",
      headerName: "Manufacturer Comments",
      type: "string",
      editable: false,
      renderCell: renderManufacturerCommentCell,
    },
    {
      field: "editor",
      headerName: "Last edited by",
      width: 150,
      valueGetter: (params) => params.row.editor?.name || "-",
      editable: false,
      renderCell: ({ value }) => <SimpleTableCell>{value}</SimpleTableCell>,
    },
    {
      field: "publisher",
      headerName: "Last published by",
      width: 150,
      valueGetter: (params) => params.row.publisher?.name || "-",
      editable: false,
      renderCell: ({ value }) => <SimpleTableCell>{value}</SimpleTableCell>,
    },
    {
      field: "updatedAt",
      headerName: "Last updated at",
      width: 200,
      valueGetter: (params) => params.row.updatedAt || "-",
      editable: false,
      renderCell: ({ value }) => <SimpleTableCell>{value ? format(new Date(value), 'PPpp') : value}</SimpleTableCell>,
    },
    {
      field: "createdAt",
      headerName: "Created/imported at",
      width: 200,
      valueGetter: (params) => params.row.createdAt || null,
      editable: false,
      renderCell: ({ value }) => <SimpleTableCell>{value ? format(new Date(value), 'PPpp') : '-'}</SimpleTableCell>,
    }
  ]
}

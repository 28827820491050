import { createContext } from 'react'

export type NewProductRequestNumberContextState = {
  newProductRequestNumber: number;
  setNewProductRequestNumber: (value: number) => void;
};

export const NewProductRequestNumberContext = createContext<NewProductRequestNumberContextState>({
  newProductRequestNumber: 0,
  setNewProductRequestNumber: () => {},
})

import React, { FC, useState } from 'react'
import { Switch } from 'src/components'
import { useEditReportTypeRequest } from 'src/hooks/api'

interface ReportTypeVisibilitySwitchProps {
  name: string
  reportTypeId: number
  isVisible: boolean
}

export const ReportTypeVisibilitySwitch: FC<ReportTypeVisibilitySwitchProps> = ({
  name,
  reportTypeId,
  isVisible,
}) => {
  const [checked, setChecked] = useState(isVisible)

  const editReportTypeRequest = useEditReportTypeRequest({ id: reportTypeId })

  const onChange = (_: any, _checked: boolean) => {
    setChecked(_checked)
    editReportTypeRequest.mutate({ isActive: _checked })
  }

  return (
    <Switch
      name={`${name}-visibility`}
      label={checked ? 'Yes' : 'No'}
      SwitchProps={{ checked, onChange }}
    />
  )
}
import React, { FC } from 'react'
import { Session, User } from 'src/api'
import { useDeleteUserSessionRequest, useUserActiveSessionListRequest } from 'src/hooks/api'
import { UserActiveSessionColumns } from 'src/components/columns/user'
import { useAddPopupMessage } from 'src/hooks/ui'
import { Table } from '../../ui'

type UserActiveSessionListProps = {
  user: User;
}

const columns = [
  UserActiveSessionColumns.PlatformName,
  UserActiveSessionColumns.DeviceType,
  UserActiveSessionColumns.DeviceBrand,
  UserActiveSessionColumns.DeviceModel,
  UserActiveSessionColumns.ClientName,
  UserActiveSessionColumns.StartDate,
  UserActiveSessionColumns.DeleteUserSession,
]
export interface EnrichedUserSession extends Session {
  delete: () => void
}

export const UserActiveSessionList: FC<UserActiveSessionListProps> = ({
  user,
}) => {
  const { data, isInitialLoading, refetch } = useUserActiveSessionListRequest({
    userId: user.id
  })
  const addMessage = useAddPopupMessage()
  const deleteUserSessionRequest = useDeleteUserSessionRequest()
  const enrichedUserSessions: EnrichedUserSession[] = data?.map((session) => {
    return {
      ...session,
      delete: () => {
        deleteUserSessionRequest.mutate({
          userId: session.userId,
          session,
        }, {
          onSuccess: () => {
            refetch()
          },
          onError: ({ message: text }) => {
            addMessage({ text, type: 'error' })
            refetch()
          },
        })
      }
    }
  }) || []

  return (
    <Table
      loading={isInitialLoading}
      data={enrichedUserSessions || []}
      columns={columns}
      noResultText="No active sessions"
      showFooter={false}
    />
  )
}

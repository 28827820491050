import { FC, useCallback } from "react"
import { Grid } from "@mui/material"
import { GridColDef } from "@mui/x-data-grid-premium"
import {
  HasSidebarLayout,
  ListHeader,
  EventList,
  ImagesViewerStateProvider,
  EventColumns,
  EventListFilters,
} from "src/components"
import { Event } from "src/api"
import { useEventListRequest } from "src/hooks/api"
import { useImagesViewer, useQueryParams, useQueryParamsState } from "src/hooks/ui"

interface EventsViewProps {
  events: Event[]
  eventCount: number
  isLoading: boolean
  isError: boolean
  errorMsg?: string
  queryParams: Record<string, string>
  setQueryParams: (params: Record<string, string>, paramsToRemove?: string[]) => void
  columns?: GridColDef<Event>[]
}

const defaultPerPage = 25
const defaultPage = 1
const initialQueryParams = {
  page: `${defaultPage}`,
  rowsPerPage: `${defaultPerPage}`,
}

const EventsView: FC<EventsViewProps> = ({
  eventCount,
  events,
  isLoading,
  queryParams,
  setQueryParams,
}) => {
  const { openGallery } = useImagesViewer()
  const columns = useCallback((): GridColDef<Event>[] => ([
    EventColumns.Name,
    EventColumns.Type,
    EventColumns.Contractor,
    EventColumns.Project,
    EventColumns.Section,
    EventColumns.Author,
    EventColumns.CreatedAt,
    EventColumns.Distance,
    EventColumns.Device,
    EventColumns.AppVersion,
    EventColumns.Photo(openGallery),
  ]), [openGallery])

  return (
    <>
      <Grid container direction="column" alignItems="stretch">
        <ListHeader title="Events" />
      </Grid>
      <Grid item container direction="column" alignItems="stretch" spacing={2} mb={2}>
        <Grid item>
          <EventListFilters />
        </Grid>
      </Grid>
      <EventList
        events={events}
        eventCount={eventCount}
        isLoadingEvents={isLoading}
        setQueryParams={setQueryParams}
        queryParams={queryParams}
        eventColumns={columns()}
        autoHeight={false}
      />
    </>
  )
}

const Events: FC = () => {
  document.title = 'Floorcloud Admin Panel - Events'
  const params = useQueryParamsState()
  const { data, isInitialLoading, isError, error } = useEventListRequest({
    params,
  })
  const [queryParams, setQueryParams] = useQueryParams(initialQueryParams)

  return (
    <HasSidebarLayout>
      <ImagesViewerStateProvider>
        <EventsView
          events={data?.rows || []}
          eventCount={data?.count || 0}
          isLoading={isInitialLoading}
          isError={isError}
          errorMsg={error?.message}
          setQueryParams={setQueryParams}
          queryParams={queryParams}
        />
      </ImagesViewerStateProvider>
    </HasSidebarLayout>
  )
}

export default Events

import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { APIList } from 'src/interfaces'
import { Entities } from 'src/enums'
import { UserMessageChannel } from 'src/api'
import { useApiInstance } from './useApiInstance'
import {
  buildRequestParams,
  ObjectType,
  transformPage,
  transformPerPage,
} from './helpers'

interface RequestParams {
  params?: Partial<AdminUserMessageChannelGetParams>;
  paramsTransform?: Array<(params: Record<string, any>) => Record<string, any>>;
  options?: Omit<
    UseQueryOptions<APIList<UserMessageChannel>, Error>,
    'queryKey' | 'queryFn'
  >;
}

interface AdminUserMessageChannelGetParams {
  limit: number;
  offset: number;
  sort: string;
}

const schema: ObjectType['schema'] = {
  limit: 'number',
  offset: 'number',
  sort: 'string',
}

export const useUserMessageChannelListRequest = ({
  params = {},
  paramsTransform = [],
  options,
}: RequestParams = {}) => {
  const api = useApiInstance()

  const transformedParams = [
    transformPerPage,
    transformPage,
    ...paramsTransform,
  ].reduce((param, transform) => transform(param), params)

  const queryParams = buildRequestParams<AdminUserMessageChannelGetParams>(
    transformedParams,
    schema,
  )

  return useQuery<APIList<UserMessageChannel>, Error>(
    [Entities.UserMessageChannels, JSON.stringify(transformedParams)],
    async () => {
      const { limit, offset, sort } = queryParams
      const { data } = await api.v1AdminUsersMessageChannelsGet(limit, offset, sort)
      return data as APIList<UserMessageChannel>
    },
    {
      enabled: Object.keys(transformedParams).length > 0,
      ...options,
    },
  )
}

import { useMutation, UseMutationOptions } from '@tanstack/react-query'
import { Entities } from 'src/enums'
import { ProductDraft, ProductDraftCreation } from 'src/api'
import { useApiInstance } from './useApiInstance'

interface RequestParams {
  options?: Omit<
    UseMutationOptions<unknown, Error, ProductDraftCreation, ProductDraft>,
    'mutationKey' | 'mutationFn'
  >;
}

export const useNewProductDraftRequest = ({ options }: RequestParams) => {
  const api = useApiInstance()

  return useMutation<unknown, Error, ProductDraftCreation, ProductDraft>(
    [Entities.ProductsDrafts],
    async (payload: ProductDraftCreation) => {
      const { data } = await api.v1AdminProductsDraftsPost(payload)
      return data
    },
    { ...options },
  )
}

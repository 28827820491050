import React, { FC, useState } from 'react'
import { Switch } from 'src/components'
import { useEditReportComponentRequest } from 'src/hooks/api'

interface Props {
  name: string
  reportComponentId: number
  isVisible: boolean
}

export const ReportComponentVisibilitySwitch: FC<Props> = ({
  name,
  reportComponentId,
  isVisible,
}) => {
  const [checked, setChecked] = useState(isVisible)

  const editReportComponentRequest = useEditReportComponentRequest({ id: reportComponentId })

  const onChange = (_: any, _checked: boolean) => {
    setChecked(_checked)
    editReportComponentRequest.mutate({ isVisible: _checked })
  }

  return (
    <Switch
      name={`${name}-visibility`}
      label={checked ? 'Yes' : 'No'}
      SwitchProps={{ checked, onChange }}
    />
  )
}
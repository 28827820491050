import React, { FC } from "react"
import { Grid, Button } from "@mui/material"
import { useProductListRequest } from "src/hooks/api"
import { useQueryParamsState } from "src/hooks/ui"
import { Product } from "src/api"
import { ProductColumns } from "src/components/columns/product"
import { ManufacturerProjectFilters } from "src/components/forms"
import { Table, DataLoad, DynamicColumn, InnerLink } from "../../ui"

const columns: DynamicColumn<Product>[] = [
  ProductColumns.Name,
  ProductColumns.ProductName,
  ProductColumns.ShelfLife,
  ProductColumns.Tips,
  ProductColumns.Ranges,
  ProductColumns.IsProductInUse,
]

interface ProductsOfManufacturerProps {
  manufacturerId: number;
}

export const ProductsOfManufacturer: FC<ProductsOfManufacturerProps> = ({
  manufacturerId,
}) => {
  const params = useQueryParamsState()
  const { isInitialLoading, isError, data, error } = useProductListRequest({
    params: {
      ...params,
      manufacturerId,
    },
  })

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <Grid container alignItems="center" spacing={2}>
          <Grid item>
            <InnerLink to={`/db-management?manufacturerId=${manufacturerId}`}>
              <Button variant="contained">Add product</Button>
            </InnerLink>
          </Grid>
          <Grid item>
            <ManufacturerProjectFilters />
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <DataLoad
          isLoading={false}
          isError={isError}
          errorMessage={error?.message}
        >
          <Table
            loading={isInitialLoading}
            count={data?.count}
            data={data?.rows || []}
            columns={columns}
          />
        </DataLoad>
      </Grid>
    </Grid>
  )
}

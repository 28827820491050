import React, { FC, PropsWithChildren, useEffect } from 'react'
import * as Yup from 'yup'
import { Button, Grid, Box, Typography } from '@mui/material'
import { Form, useFormikContext, useFormik, useField, ErrorMessage, FormikContext } from 'formik'
import { TextField, Checkbox, OuterLink } from 'src/components'

const getValidationSchema = (termsAndPrivacyAccepted: boolean) =>
  termsAndPrivacyAccepted
    ? Yup.object().shape({
      code: Yup.string()
        .trim()
        .required('Verification code is required')
        .matches(/^\d+$/, 'Verification code should contains only digits'),
    })
    : Yup.object().shape({
      code: Yup.string()
        .trim()
        .required('Verification code is required')
        .matches(/^\d+$/, 'Verification code should contains only digits'),
      termsAndPrivacyAccepted: Yup.boolean()
        .isTrue('Terms of use and Privacy policy is required to accept')
        .required(),
    })

export interface SendCodeFormState {
  code: string;
  termsAndPrivacyAccepted?: boolean;
}

const getDefaultValues = (termsAndPrivacyAccepted: boolean) =>
  termsAndPrivacyAccepted
    ? {
      code: '',
    }
    : {
      code: '',
      termsAndPrivacyAccepted: false,
    }

interface SendCodeFormProps {
  privacyPolicyLink?: string;
  termsOfUseLink?: string;
  termsAndPrivacyAccepted: boolean;
  onSubmit: (formState: SendCodeFormState) => void;
}

const SubmitButton: FC<PropsWithChildren> = ({ children }) => {
  const { errors } = useFormikContext()
  return (
    <Button
      size="large"
      variant="contained"
      color="primary"
      fullWidth
      type="submit"
      disabled={Object.keys(errors).length > 0}
    >
      {children}
    </Button>
  )
}

interface TermsAndPrivacyCheckboxProps {
  termsOfUseLink: string;
  privacyPolicyLink: string;
}

const TermsAndPrivacyCheckbox: FC<TermsAndPrivacyCheckboxProps> = ({
  privacyPolicyLink,
  termsOfUseLink,
}) => {
  const [field] = useField({
    name: 'termsAndPrivacyAccepted',
    type: 'checkbox',
  })
  return (
    <>
      <Grid container flexWrap="nowrap" alignItems="center" sx={{ ml: 0 }}>
        <Grid item sx={{ ml: 2 }}>
          <Checkbox name={field.name} label="" CheckboxProps={{ ...field }} />
        </Grid>
        <Grid item>
          <Typography>
            I agree with{' '}
            <OuterLink to={termsOfUseLink}>Terms of service</OuterLink> <br />
            and <OuterLink to={privacyPolicyLink}>Privacy Policy</OuterLink>
          </Typography>
        </Grid>
      </Grid>
      <Grid container item>
        <ErrorMessage
          name={field.name}
          render={(schemeError: string) => {
            console.log(schemeError)
            return (
              <Typography variant="caption" sx={{ color: 'error.main' }}>
                {schemeError}
              </Typography>
            )
          }}
        />
      </Grid>
    </>
  )
}

export const SendCodeForm: FC<SendCodeFormProps> = ({
  termsAndPrivacyAccepted,
  privacyPolicyLink,
  termsOfUseLink,
  onSubmit,
}) => {
  const formik = useFormik({
    initialValues: getDefaultValues(termsAndPrivacyAccepted),
    onSubmit: (data) => {
      onSubmit(data)
    },
    validationSchema: getValidationSchema(termsAndPrivacyAccepted)
  })
  const { values, submitForm } = formik
  useEffect(() => {
    if (values.code.length === 6) {
      submitForm()
    }
  }, [values, submitForm])
  return (
    <FormikContext.Provider value={formik}>
      <Form>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <TextField
              name="code"
              required
              TextFieldProps={{
                placeholder: 'Verification code',
                autoComplete: 'off',
                autoFocus: true,
              }}
            />
          </Grid>
          {!termsAndPrivacyAccepted && (
            <Grid item xs={12}>
              <TermsAndPrivacyCheckbox
                privacyPolicyLink={privacyPolicyLink || '#'}
                termsOfUseLink={termsOfUseLink || '#'}
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <Box pt={2}>
              <SubmitButton>Verify</SubmitButton>
            </Box>
          </Grid>
        </Grid>
      </Form>
    </FormikContext.Provider>
  )
}

import { GridColDef } from "@mui/x-data-grid-premium"
import format from "date-fns/format"
import { InnerLink } from "src/components/ui"
import { SectionRapidRHRow } from './rapidRHInfo'

export const useSectionRapidRHColumns = (): GridColDef<SectionRapidRHRow>[] => ([
  {
    field: "deviceId",
    headerName: "Probe ID",
    type: "string",
    minWidth: 200,
    editable: false,
    renderCell: ({ value }) => (
      <InnerLink to={`/wagner-probes/${value}`}>{value}</InnerLink>
    )
  },
  {
    field: "event",
    headerName: "Event",
    type: "string",
    minWidth: 200,
    editable: false,
  },
  {
    field: "time",
    headerName: "Datetime",
    type: "string",
    minWidth: 200,
    editable: false,
    valueFormatter: ({ value }) => value ? format(new Date(value), "PPpp") : "-",
  },
])

import { useMutation, UseMutationOptions } from '@tanstack/react-query'
import { useApiInstance } from './useApiInstance'

interface SignOutRequestParams {
  options?: Omit<
    UseMutationOptions<boolean, unknown>,
    'mutationKey' | 'mutationFn'
  >;
}

export const useSignOutRequest = ({
  options,
}: SignOutRequestParams = {}) => {
  const api = useApiInstance()
  return useMutation(
    async () => {
      const { data }: { data: unknown } = await api.v1AdminUsersSignOutPost()
      return data as boolean
    },
    { ...options },
  )
}

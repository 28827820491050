import React, { FC } from 'react'
import { Grid } from '@mui/material'
import { useAddPopupMessage } from 'src/hooks/ui'
import { useNewBuildingTypeRequest } from 'src/hooks/api'
import { BuildingTypeNewForm } from 'src/components/forms'

interface NewBuildingTypeProps {
  onCreate: () => void;
}

export const NewBuildingType: FC<NewBuildingTypeProps> = ({ onCreate }) => {
  const addMessage = useAddPopupMessage()

  const newBuildingTypeRequest = useNewBuildingTypeRequest()
  const createBuildingType = ({ displayName }: { displayName: string }) => {
    newBuildingTypeRequest.mutate(
      { displayName },
      {
        onSuccess: () => onCreate(),
        onError: (error) => addMessage({ type: 'error', text: error.message }),
      }
    )
  }

  return (
    <>
      <Grid item flexGrow={1} sx={{ pt: 1, pb: 2 }}>
        <BuildingTypeNewForm onSubmit={createBuildingType} />
      </Grid>
    </>
  )
}
import { useMutation, UseMutationOptions } from '@tanstack/react-query'
import { Entities } from 'src/enums'
import { ProductTypeCreation, ProductType } from 'src/api'
import { useApiInstance } from './useApiInstance'

interface RequestParams {
  options?: Omit<
    UseMutationOptions<unknown, Error, ProductTypeCreation, ProductType>,
    'mutationKey' | 'mutationFn'
  >;
}

export const useNewProductTypeRequest = ({ options }: RequestParams = {}) => {
  const api = useApiInstance()

  return useMutation<unknown, Error, ProductTypeCreation, ProductType>(
    [Entities.ProductTypes, 'new'],
    async (payload: ProductTypeCreation) => {
      const { data } = await api.v1AdminProductsTypesPost(payload)
      return data
    },
    { ...options },
  )
}

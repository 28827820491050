import React, { FC, PropsWithChildren } from "react"
import { useQueryParamsState } from "../../../../hooks/ui"
import { SearchParamsKeys } from "../../../../enums"
import {
  useContractorRequest,
  useFilteredContractorListRequest,
} from "../../../../hooks/api"
import { DataLoad, PropsInjector } from "../../../ui"

export const ContractorsDataLoader: FC<PropsWithChildren> = ({ children }) => {
  const queryParams = useQueryParamsState()

  const contractorId = queryParams[SearchParamsKeys.Contractor]

  const currentContractorRequest = useContractorRequest({
    id: parseInt(contractorId, 10),
    options: {
      enabled: Boolean(contractorId),
    },
  })

  const contractorsRequest = useFilteredContractorListRequest()

  const findContractorsByName = (name: string) => {
    if (name?.length >= 3) contractorsRequest.mutate({ name })
  }

  const isLoading = currentContractorRequest.isInitialLoading
  const isError =
    contractorsRequest.isError || currentContractorRequest.isError
  const error = contractorsRequest.error || currentContractorRequest.error

  const props = {
    contractors: contractorsRequest.data?.rows || [],
    contractorsLoading: contractorsRequest.isLoading,
    currentContractor: currentContractorRequest?.data || null,
    findContractorsByName,
  }

  return (
    <DataLoad
      isLoading={isLoading}
      isError={isError}
      errorMessage={error?.message}
    >
      <PropsInjector props={props}>{children}</PropsInjector>
    </DataLoad>
  )
}

import React, { FC } from 'react'
import { Grid } from '@mui/material'
import { Table, DynamicColumn } from 'src/components/ui'
import { WagnerProbe } from 'src/api'
import { WagnerProbeColumns } from 'src/components/columns/wagnerProbe'

const columns: DynamicColumn<WagnerProbe>[] = [
  WagnerProbeColumns.ProbeID,
  WagnerProbeColumns.Label,
  WagnerProbeColumns.Contractor,
  WagnerProbeColumns.Project,
  WagnerProbeColumns.ProjectSection,
  WagnerProbeColumns.VerizonIMEI,
]

interface WagnerProbeInfoProps {
  wagnerProbe: WagnerProbe;
}

export const WagnerProbeInfo: FC<WagnerProbeInfoProps> = ({ wagnerProbe }) => {
  return (
    <Grid container spacing={4}>
      <Grid item container>
        <Table
          columns={columns}
          data={[wagnerProbe]}
          showFooter={false}
          sx={{ width: '100%' }}
        />
      </Grid>
    </Grid>
  )
}

import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { APIList } from 'src/interfaces'
import { Entities } from 'src/enums'
import { UnstableSensor } from 'src/api'
import { useApiInstance } from './useApiInstance'
import {
  buildRequestParams,
  ObjectType,
  transformPage,
  transformPerPage,
} from './helpers'

interface RequestParams {
  params?: Partial<AdminUnstableSensorsGetParams>;
  paramsTransform?: Array<(params: Record<string, any>) => Record<string, any>>;
  options?: Omit<
    UseQueryOptions<APIList<UnstableSensor>, Error>,
    'queryKey' | 'queryFn'
  >;
}

interface AdminUnstableSensorsGetParams {
  limit: number;
  offset: number;
  sort: string;
  search: string;
  datetimeFrom?: string;
  datetimeTo?: string;
}

const schema: ObjectType['schema'] = {
  limit: 'number',
  offset: 'number',
  sort: 'string',
  search: 'string',
  datetimeFrom: 'string',
  datetimeTo: 'string',
}

export const useUnstableSensorsListRequest = ({
  params = {},
  paramsTransform = [],
  options,
}: RequestParams = {}) => {
  const api = useApiInstance()

  const transformedParams = [
    transformPerPage,
    transformPage,
    ...paramsTransform,
  ].reduce((param, transform) => transform(param), params)

  const queryParams = buildRequestParams<AdminUnstableSensorsGetParams>(
    transformedParams,
    schema,
  )

  return useQuery<APIList<UnstableSensor>, Error>(
    [Entities.Sensors, JSON.stringify(transformedParams)],
    async () => {
      const {
        limit,
        offset,
        sort,
        search,
        datetimeFrom,
        datetimeTo,
      } = queryParams
      const { data } = await api.v1AdminSensorsUnstableSensorsGet(
        limit,
        offset,
        sort,
        search || undefined,
        datetimeFrom || undefined,
        datetimeTo || undefined,
      )
      return data as APIList<UnstableSensor>
    },
    options,
  )
}

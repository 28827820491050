import React, { PropsWithChildren } from "react"
import { styled } from "@mui/material/styles"
import { Grid, Tooltip, TooltipProps, tooltipClasses } from "@mui/material"

const BootstrapTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  whiteSpace: "pre-line",

  ".MuiTooltip-tooltipPlacementBottom": {
    top: "-7px",
  },

  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
  },
}))

const Content = styled(Grid)`
  width: 100%;
  height: 100%;
  cursor: pointer;
`

interface TableCellWithTooltipProps {
  title: string;
  capitalize?: boolean;
}

export const TableCellWithTooltip: React.FC<PropsWithChildren<TableCellWithTooltipProps>> = ({
  title,
  capitalize,
  children,
}) => {
  return (
    <BootstrapTooltip title={title}>
      <Content container alignItems="center">
        {children}
      </Content>
    </BootstrapTooltip>
  )
}

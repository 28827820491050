import React, { FC, useState } from "react"
import { Menu, MenuItem } from "@mui/material"
import { GridActionsCellItem, GridColDef } from "@mui/x-data-grid-premium"
import MoreVertIcon from "@mui/icons-material/MoreVert"
import { SensorColumnsDataGrid } from "src/components/columns/sensor"
import { Sensor, SensorState } from "src/api"
import {
  SensorListFilters,
  StatefulDialog,
  AssignSensorToContractor,
  SensorsMapView,
} from "src/components"
import {
  SearchParamsKeys,
  DialogNames,
  SensorStateList,
  ViewTypes,
  SensorFilters,
  SensorButtons,
} from "src/enums"
import { useAssignSensorRequest } from "src/hooks/api"
import { useQueryParamsState, useDialog } from "src/hooks/ui"
import { CommonSensorList } from "./commonSensorsList"
import { ContractorsDataLoader } from "./contractorsDataLoader"
import { SensorMapFilters } from "../../../forms/sensor/sensorMapFilters"

interface AssignedSensorListProps {
  isLoading: boolean;
  count: number;
  data: Sensor[];
  states: SensorState[];
  refetchSensorList: () => void;
}

export const AssignedSensorList: FC<AssignedSensorListProps> = ({
  data,
  isLoading,
  count,
  states,
  refetchSensorList,
}) => {
  const [currentRow, setCurrentRow] = useState<Sensor | null>(null)
  const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLElement | null>(null)
  const queryParams = useQueryParamsState()
  const viewType = queryParams[SearchParamsKeys.ViewType] || ViewTypes.List
  const [sensorUniqueId, setSensorUniqueId] = useState<string | null>(null)
  const { openDialog, closeDialog } = useDialog(
    DialogNames.AssignSensorToContractor
  )

  const assignSensorRequest = useAssignSensorRequest({
    options: {
      onSettled: () => closeDialog(),
      onSuccess: () => refetchSensorList(),
    },
  })

  const assignSensor = (contractorId: number | null) => {
    if (sensorUniqueId !== null) {
      assignSensorRequest.mutate({
        uniqueId: sensorUniqueId,
        payload: { contractorId },
      })
    }
  }

  const handleOpenMenu = (
    event: React.MouseEvent<HTMLElement>,
    row: Sensor
  ) => {
    setCurrentRow(row)
    setMenuAnchorEl(event.currentTarget)
  }

  const handleCloseMenu = () => {
    setCurrentRow(null)
    setMenuAnchorEl(null)
  }

  const columns: GridColDef<Sensor>[] = [
    SensorColumnsDataGrid.Id,
    SensorColumnsDataGrid.Model,
    SensorColumnsDataGrid.IMEI,
    SensorColumnsDataGrid.DevEUI,
    SensorColumnsDataGrid.Contractor,
    SensorColumnsDataGrid.LastPing,
    SensorColumnsDataGrid.BatteryLevel,
    SensorColumnsDataGrid.DateAssigned,
    {
      field: "actions",
      headerName: "",
      type: "actions",
      width: 30,
      getActions: ({ row }) => [
        <GridActionsCellItem
          icon={<MoreVertIcon />}
          label="Actions"
          className="textPrimary"
          onClick={(e: any) => handleOpenMenu(e, row)}
          color="inherit"
        />,
      ],
    },
  ]

  const assignedState = states.find(
    (state) => state.name === SensorStateList.Assigned
  )

  return (
    <>
      {viewType === ViewTypes.List ? (
        <>
          <CommonSensorList
            columns={columns}
            count={count}
            data={data}
            isLoading={isLoading}
            filters={
              <ContractorsDataLoader>
                <SensorListFilters
                  activeFilters={[
                    SensorFilters.LowBattery,
                    SensorFilters.WeakSignal,
                    SensorFilters.LastPingMoreThanHour,
                    SensorFilters.SyncPeriod,
                    SensorFilters.ReadingsAge,
                    SensorFilters.CurrentSensorConfiguration,
                    SensorFilters.BleStatus,
                    SensorFilters.Contractor,
                  ]}
                />
              </ContractorsDataLoader>
            }
            buttons={[SensorButtons.DownloadLabels, SensorButtons.GetHistoricalData, SensorButtons.ManageSensorBleState]}
            checkboxSelection
          />
          <Menu
            anchorEl={menuAnchorEl}
            open={!!menuAnchorEl}
            onClose={handleCloseMenu}
          >
            <MenuItem
              disabled={!assignedState}
              onClick={() => {
                if (assignedState) {
                  setSensorUniqueId(currentRow?.uniqueId as string)
                  openDialog()
                  handleCloseMenu()
                }
              }}
            >
              Reassign
            </MenuItem>
          </Menu>
        </>
      ) : (
        <SensorsMapView
          data={data}
          isLoading={isLoading}
          filters={
            <ContractorsDataLoader>
              <SensorMapFilters
                activeFilters={[
                  SensorFilters.Contractor,
                  SensorFilters.LowBattery,
                  SensorFilters.WeakSignal,
                  SensorFilters.LastPingMoreThanHour,
                  SensorFilters.SyncPeriod,
                  SensorFilters.ReadingsAge,
                  SensorFilters.LocationType,
                ]}
              />
            </ContractorsDataLoader>
          }
        />
      )}
      <StatefulDialog
        name={DialogNames.AssignSensorToContractor}
        wrapContent={false}
      >
        <AssignSensorToContractor onSubmit={assignSensor} />
      </StatefulDialog>
    </>
  )
}

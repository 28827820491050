import React, { FC } from 'react'
import { Grid, Typography } from '@mui/material'
import { useReportTypeListRequest } from 'src/hooks/api'
import { DataLoad } from 'src/components/ui'
import { ReportTypeList } from './reportTypeList'

export const LoadableReportTypeList: FC = () => {
  const { data, error, isInitialLoading, isError, refetch } = useReportTypeListRequest()

  return (
    <Grid container direction="column" spacing={4}>
      <Grid item>
        <Grid item sx={{ pb: 2 }}>
          <Grid item>
            <Typography variant="h6">Report Type List</Typography>
          </Grid>
        </Grid>
        <Grid item>
          <DataLoad
            isLoading={isInitialLoading}
            isError={isError}
            errorMessage={error?.message}
          >
            <ReportTypeList data={data || []} onChange={refetch} />
          </DataLoad>
        </Grid>
      </Grid>
    </Grid>
  )
}

import React, { FC } from "react"
import { Box } from "@mui/material"
import { Manufacturer } from "src/api"
import { Dropdown } from "src/components"
import { useDropdownItemFactory } from "src/hooks/ui"

interface ManufacturerDropdownProps {
  value: number | undefined;
  list: Manufacturer[];
  onChange: (value: number) => void;
}

export const ManufacturerDropdown: FC<ManufacturerDropdownProps> = ({
  value,
  list,
  onChange,
}) => {
  const dropdownItemListMap = useDropdownItemFactory({
    params: { labelProp: "name", valueProp: "id" },
  })

  return (
    <Box width="300px">
      <Dropdown
        value={value}
        list={list}
        name="manufacturers-list"
        label="Manufacturer"
        menuItemFactory={dropdownItemListMap}
        onChange={(_, v) => onChange(v)}
        onBlur={(_, v) => onChange(v)}
      />
    </Box>
  )
}

import React, { FC } from 'react'
import { ProjectSection } from 'src/api'
import { Table } from 'src/components'
import { DefaultSectionColumns } from 'src/components/columns/sections'

const sectionColumns = [
  DefaultSectionColumns.ProjectName,
  DefaultSectionColumns.ContractorName,
  DefaultSectionColumns.Status,
  DefaultSectionColumns.NumberOfSensors,
  DefaultSectionColumns.NumberOfRapidRH,
]

interface SectionInfoProps {
  section: ProjectSection;
}

export const SectionInfo: FC<SectionInfoProps> = ({ section }) => {
  return (
    <Table
      columns={sectionColumns}
      data={[section]}
      showFooter={false}
      sx={{ width: '100%' }}
    />
  )
}

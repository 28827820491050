import React, { FC } from "react"
import Box from "@mui/system/Box"
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import format from "date-fns/format"
import { CustomSingleEvent, EventTag } from "src/api"
import { Grid, Typography } from "@mui/material"
import { capitalizeFirstLetter } from "src/utils/formatters"
import { InnerLink } from "src/components/ui"
import { SourceInformation } from "./sourceInfo"

interface EventInfoProps {
  event: CustomSingleEvent
}

export const EventInfo: FC<EventInfoProps> = ({ event }) => {
  const {
    eventCardRenderingData: { eventDatetime, eventDetails, tags, photos, note, author },
    appDevice,
    appVersion,
    distance,
    type,
    projectSectionId,
    productId,
    sensorUniqueId,
    wagnerProbeDeviceId,
    authorId,
  } = event

  const generalInformation = [
    { name: 'Type', value: type?.displayName || '-' },
    { name: 'Created at', value: eventDatetime ? format(new Date(eventDatetime), 'PPpp') : '-' },
    { name: 'Description', value: eventDetails || '-' },
    { name: 'Note', value: note || '-' },
  ]
  const metaInformation = [
    { name: 'Author', value: author ? <InnerLink to={`/users/${authorId}`}>{author}</InnerLink> : '-' },
    { name: 'Device', value: appDevice || '-' },
    { name: 'App Version', value: appVersion || '-' },
    { name: 'Distance', value: distance ? `${distance} miles` : '-' },
  ]
  
  const getTagValue = (tag: EventTag): React.ReactNode | string => {
    const dictionary: Record<string, { url: string, id: number | string | undefined }> = {
      product: { url: 'products', id: productId },
      sensor: { url: 'sensors', id: sensorUniqueId },
      section: { url: 'sections', id: projectSectionId },
      wagnerProbe: { url: 'wagner-probes', id: wagnerProbeDeviceId }
    }
    const entity = dictionary[tag.tagType]
    if (entity !== undefined && entity.id) {
      return (
        <InnerLink to={`/${entity.url}/${entity.id}`}>{tag.tagValue}</InnerLink>
      )
    }
    return tag.tagValue
  }

  return (
    <Box width="100%" pt={2}>
      <Grid container spacing={4}>
        <Grid container xs={12} md={6} item spacing={2}>
          <Box width="100%" pl="16px">
            <Grid item xs={12} sx={{ py: 1 }}>
              <Typography variant="h6" fontWeight={500}>General information</Typography>
            </Grid>
            {generalInformation.map((item, index) => (
              <Grid key={index} item xs={12} sx={{ py: 0.5 }}>
                <Typography variant="body1" component="div">
                  <Typography variant="body1" fontWeight={500} display="inline">{item.name}:</Typography> {item.value}
                </Typography>
              </Grid>
            ))}
            {
              tags?.length
                ? (
                  <Grid item xs={12} sx={{ py: 0.5 }}>
                    <Typography variant="body1" fontWeight={500}>Tags:</Typography>
                    <List sx={{ py: 0 }}>
                      {tags?.map((tag, index) => (
                        <ListItem key={index} divider sx={{ pl: 2 }}>
                          <ListItemText
                            primary={capitalizeFirstLetter(tag.tagType)}
                            secondary={getTagValue(tag)}
                          />
                        </ListItem>
                      ))}
                    </List>
                  </Grid>
                )
                : null
            }
          </Box>
        </Grid>
        <Grid container xs={12} md={6} item spacing={2}>
          <Box width="100%" pl="16px">
            <Grid item xs={12} sx={{ py: 1 }}>
              <Typography variant="h6" fontWeight={500}>Meta information</Typography>
            </Grid>
            {metaInformation.map((item, index) => (
              <Grid key={index} item xs={12} sx={{ py: 0.5 }}>
                <Typography variant="body1" component="div">
                  <Typography variant="body1" fontWeight={500} display="inline">{item.name}:</Typography> {item.value}
                </Typography>
              </Grid>
            ))}
          </Box>
        </Grid>
        <Grid container xs={12} item spacing={2}>
          <Box width="100%" pl="16px">
            <SourceInformation event={event} />
          </Box>
        </Grid>
        <Grid container xs={12} item spacing={2}>
          {
            photos?.length
              ? (
                <Grid container item xs={12} spacing={2} overflow="auto">
                  {photos.map((photo, index) => (
                    <Grid item key={index} xs={12} sm={6} md={4} xl={3}>
                      <img
                        style={{
                          objectFit: "scale-down",
                          width: "100%",
                        }}
                        src={photo.originalLink}
                        alt="event media"
                      />
                    </Grid>
                  ))}
                </Grid>
              )
              : null
          }
        </Grid>
      </Grid>
    </Box>
  )
}

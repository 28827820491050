import axios, { AxiosResponseTransformer } from 'axios'
import { AdminAPIApi } from 'src/api'
import { config } from 'src/config'
import { useAuthorizedState, useSignInRedirect } from 'src/hooks/ui'

export const useApiInstance = () => {
  const { apiPath, host } = config
  const basePath = `${host}/${apiPath}`
  const token = useAuthorizedState()
  const redirect = useSignInRedirect()
  const axiosInstance = axios.create({
    transformResponse: [
      ...(axios.defaults.transformResponse as AxiosResponseTransformer[]),
      ({ error, result, statusCode }) => {
        if (error) {
          if (statusCode === 401) redirect()
          throw new Error(error)
        }
        return result as unknown
      },
    ],
    headers: {
      'client-platform': 'admin',
      ...(token ? { Authorization: token } : {}),
    },
  })

  return new AdminAPIApi(undefined, basePath, axiosInstance)
}

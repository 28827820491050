import { useMutation, UseMutationOptions } from '@tanstack/react-query'
import { Entities } from 'src/enums'
import { Session } from 'src/api'
import { useApiInstance } from './useApiInstance'

interface RequestParams {
  options?: Omit<
    UseMutationOptions<unknown, Error, { userId: number, session: Session }>,
    'mutationKey' | 'mutationFn'
  >;
}

export const useDeleteUserSessionRequest = ({ options }: RequestParams = {}) => {
  const api = useApiInstance()

  return useMutation<unknown, Error, { userId: number, session: Session }>(
    [Entities.UserSessions, 'delete'],
    async ({ userId, session }) => {
      const { data } = await api.v1AdminUsersUserIdSessionsDelete(userId, session)
      return data
    },
    { ...options },
  )
}

import React from 'react'
import { TableCell, Typography } from '@mui/material'
import { GridColDef } from "@mui/x-data-grid-premium"
import { Product } from 'src/api'
import { DynamicColumn, InnerLink } from 'src/components/ui'

export namespace ProductColumns {
  export const Name: DynamicColumn<Product> = {
    field: 'name',
    headerName: 'Name',
    type: 'string',
    innerLink: ({ id }) => `/products/${id}`,
    sort: true,
  }
  export const ManufacturerName: DynamicColumn<Product> = {
    field: 'manufacturer.name',
    headerName: 'Manufacturer',
    type: 'string',
    innerLink: ({ manufacturerId }) => `/manufacturers/${manufacturerId}`
  }
  export const ProductTypeName: DynamicColumn<Product> = {
    field: 'productType.name',
    headerName: 'Type',
    type: 'string'
  }
  export const ShelfLife: DynamicColumn<Product> = {
    field: 'shelfLife',
    headerName: 'Shelf Life',
    type: 'custom',
    cellProps: { align: 'left' },
    component: ({ row: { shelfLife }, cellProps }) => {
      let value = '-'
      if (shelfLife !== null) {
        const dayCount = `${shelfLife}`
        value = `${dayCount} Day${dayCount.slice(dayCount.length - 1) !== '1' ? 's' : ''}`
      }
      return <TableCell align={cellProps?.align}>{value}</TableCell>
    },
  }
  export const Tips: DynamicColumn<Product> = {
    field: 'tips',
    headerName: 'Tips',
    type: 'custom',
    cellProps: { align: 'left' },
    component: ({ row: { tips }, cellProps }) => {
      return (
        <TableCell align={cellProps?.align}>
          {tips?.length ? 'Yes' : '-'}
        </TableCell>
      )
    },
  }
  export const Ranges: DynamicColumn<Product> = {
    field: 'ranges',
    headerName: 'Ranges',
    type: 'custom',
    cellProps: { align: 'right' },
    component: ({
      row: { lowHumidity, lowTempF, highHumidity, highTempF },
      cellProps,
    }) => {
      return (
        <TableCell align={cellProps?.align}>
          {lowHumidity && lowTempF && highHumidity && highTempF ? 'Yes' : '-'}
        </TableCell>
      )
    },
  }
  export const IsProductInUse: DynamicColumn<Product> = {
    field: 'ranges',
    headerName: 'In Use',
    type: 'custom',
    cellProps: { align: 'right' },
    component: ({
      row: { productsProjects },
      cellProps,
    }) => {
      return (
        <TableCell align={cellProps?.align}>
          {productsProjects && productsProjects?.length > 0 ? 'Yes' : '-'}
        </TableCell>
      )
    },
  }
  export const ProductPackageTypeName: DynamicColumn<Product> = {
    field: 'productPackageType.name',
    headerName: 'Package Type',
    type: 'string',
  }
  export const LowTempF: DynamicColumn<Product> = {
    field: 'lowTempF',
    headerName: 'Ambient Min Temp',
    type: 'custom',
    cellProps: { align: 'left' },
    component: ({ row: { lowTempF }, cellProps }) => {
      const value = lowTempF === null ? '-' : `${lowTempF} F`
      return <TableCell align={cellProps?.align}>{value}</TableCell>
    },
  }
  export const HighTempF: DynamicColumn<Product> = {
    field: 'highTempF',
    headerName: 'Ambient Max Temp',
    type: 'custom',
    cellProps: { align: 'left' },
    component: ({ row: { highTempF }, cellProps }) => {
      const value = highTempF === null ? '-' : `${highTempF} F`
      return <TableCell align={cellProps?.align}>{value}</TableCell>
    },
  }
  export const LowHumidity: DynamicColumn<Product> = {
    field: 'lowHumidity',
    headerName: 'Ambient Min Humidity',
    type: 'custom',
    cellProps: { align: 'left' },
    component: ({ row: { lowHumidity }, cellProps }) => {
      const value = lowHumidity === null ? '-' : `${lowHumidity}%`
      return <TableCell align={cellProps?.align}>{value}</TableCell>
    },
  }
  export const HighHumidity: DynamicColumn<Product> = {
    field: 'highHumidity',
    headerName: 'Ambient Max Humidity',
    type: 'custom',
    cellProps: { align: 'left' },
    component: ({ row: { highHumidity }, cellProps }) => {
      const value = highHumidity === null ? '-' : `${highHumidity}%`
      return <TableCell align={cellProps?.align}>{value}</TableCell>
    },
  }
  export const ProductName: DynamicColumn<Product> = {
    field: 'productType.name',
    headerName: 'Type',
    type: 'string',
  }
}

export namespace ProductColumnsDataGrid {
  export const Name: GridColDef<Product> = {
    field: 'name',
    headerName: 'Name',
    type: 'string',
    minWidth: 150,
    editable: false,
    renderCell: ({ value, row }) => (
      <InnerLink to={`/products/${row.id}`}>{value}</InnerLink>
    )
  }

  export const ManufacturerName: GridColDef<Product> = {
    field: 'manufacturer.name',
    headerName: 'Manufacturer',
    type: 'string',
    minWidth: 150,
    editable: false,
    valueGetter: ({ row }) => (row.manufacturer?.name),
    renderCell: ({ value, row }) => (
      <InnerLink to={`/manufacturers/${row.manufacturerId}`}>{value}</InnerLink>
    )
  }

  export const ProductTypeName: GridColDef<Product> = {
    field: 'productType.name',
    headerName: 'Type',
    type: 'string',
    minWidth: 150,
    editable: false,
    valueGetter: ({ row }) => (row.productType?.name),
  }

  export const ShelfLife: GridColDef<Product> = {
    field: 'shelfLife',
    headerName: 'Shelf Life',
    type: 'string',
    minWidth: 100,
    editable: false,
    valueGetter: ({ row }) => {
      let value = '-'
      if (row.shelfLife !== null) {
        const dayCount = `${row.shelfLife}`
        value = `${dayCount} Day${dayCount.slice(dayCount.length - 1) !== '1' ? 's' : ''}`
      }
      return value
    },
    renderCell: ({ value }) => (
      <Typography textAlign="left">{value}</Typography>
    ),
  }

  export const Tips: GridColDef<Product> = {
    field: 'tips',
    headerName: 'Tips',
    type: 'string',
    minWidth: 100,
    editable: false,
    valueGetter: ({ row: { tips } }) => (tips?.length ? 'Yes' : '-'),
    renderCell: ({ value }) => (
      <Typography textAlign="left">{value}</Typography>
    )
  }

  export const Ranges: GridColDef<Product> = {
    field: 'ranges',
    headerName: 'Ranges',
    type: 'string',
    valueGetter: ({
      row: { lowHumidity, lowTempF, highHumidity, highTempF },
    }) => (lowHumidity && lowTempF && highHumidity && highTempF ? 'Yes' : '-'),
    renderCell: ({ value }) => (
      <Typography textAlign="left">{value}</Typography>
    ),
  }

  export const IsProductInUse: GridColDef<Product> = {
    field: 'in_use',
    headerName: 'In Use',
    type: 'string',
    minWidth: 100,
    editable: false,
    valueGetter: ({ row: { productsProjects } }) => (productsProjects && productsProjects?.length > 0 ? 'Yes' : '-'),
    renderCell: ({ value }) => (
      <Typography textAlign="left">{value}</Typography>
    ),
  }

  export const ProductPackageTypeName: GridColDef<Product> = {
    field: 'productPackageType.name',
    headerName: 'Package Type',
    type: 'string',
    minWidth: 150,
    editable: false,
    valueGetter: ({ row }) => (row.productPackageType?.name),
  }

  export const LowTempF: GridColDef<Product> = {
    field: 'lowTempF',
    headerName: 'Ambient Min Temp',
    type: 'string',
    minWidth: 150,
    editable: false,
    valueFormatter: ({ value }) => (value === null ? '-' : `${value} F`),
    renderCell: ({ value }) => (
      <Typography textAlign="left">{value}</Typography>
    ),
  }

  export const HighTempF: GridColDef<Product> = {
    field: 'highTempF',
    headerName: 'Ambient Max Temp',
    type: 'string',
    minWidth: 150,
    editable: false,
    valueFormatter: ({ value }) => (value === null ? '-' : `${value} F`),
    renderCell: ({ value }) => (
      <Typography textAlign="left">{value}</Typography>
    ),
  }

  export const LowHumidity: GridColDef<Product> = {
    field: 'lowHumidity',
    headerName: 'Ambient Min Humidity',
    type: 'string',
    minWidth: 150,
    editable: false,
    valueFormatter: ({ value }) => (value === null ? '-' : `${value} F`),
    renderCell: ({ value }) => (
      <Typography textAlign="left">{value}</Typography>
    ),
  }

  export const HighHumidity: GridColDef<Product> = {
    field: 'highHumidity',
    headerName: 'Ambient Max Humidity',
    type: 'string',
    minWidth: 150,
    editable: false,
    valueFormatter: ({ value }) => (value === null ? '-' : `${value} F`),
    renderCell: ({ value }) => (
      <Typography textAlign="left">{value}</Typography>
    ),
  }

  export const ProductName: GridColDef<Product> = {
    field: 'productType.name',
    headerName: 'Type',
    type: 'string',
    minWidth: 150,
    editable: false,
    valueGetter: ({ row }) => (row.productType?.name),
  }
}

export default ProductColumns
import { Grid, Typography } from "@mui/material"
import format from "date-fns/format"
import { GridColDef } from "@mui/x-data-grid-premium"
import { Manufacturer } from "src/api"
import { InnerLink } from "src/components/ui"
import { Verified } from "../icons/verified"

export namespace ManufacturerColumnsDataGrid {
  export const Photo: GridColDef<Manufacturer> = {
    field: "photo",
    headerName: "Photo",
    minWidth: 80,
    editable: false,
    align: "center",
    renderCell: ({ value, row }) => (
      <InnerLink to={`/manufacturers/${row.id}`}>
        <Grid container alignItems="center">
          <img
            style={{
              objectFit: "scale-down",
              width: 60,
              height: 60
            }}
            alt="Manufacturer logo"
            src={value || "no_image.png"}
          />
        </Grid>
      </InnerLink>
    ),
  }

  export const Name: GridColDef<Manufacturer> = {
    field: "name",
    headerName: "Name",
    type: "string",
    minWidth: 150,
    editable: false,
    renderCell: ({ value, row }) => (
      <InnerLink to={`/manufacturers/${row.id}`}>{value}</InnerLink>
    )
  }

  export const NameWithIcon: GridColDef<Manufacturer> = {
    field: "name",
    headerName: "Name",
    type: "string",
    minWidth: 200,
    editable: false,
    renderCell: ({ row: { id, name, isVerified } }) => (
      <Grid container alignItems="center" spacing={1}>
        <Grid item>
          <InnerLink to={`/manufacturers/${id}`}>{name}</InnerLink>
        </Grid>
        {isVerified && (
          <Grid item>
            <Grid container alignItems="center">
              <Verified />
            </Grid>
          </Grid>
        )}
      </Grid>
    ),
  }

  export const Address: GridColDef<Manufacturer> = {
    field: "address1",
    headerName: "Address",
    type: "string",
    minWidth: 200,
    editable: false,
  }

  export const Phone: GridColDef<Manufacturer> = {
    field: "phone",
    headerName: "Phone Number",
    type: "string",
    minWidth: 150,
    editable: false,
  }

  export const Email: GridColDef<Manufacturer> = {
    field: "email",
    headerName: "Email",
    type: "string",
    minWidth: 180,
    editable: false,
  }

  export const ProductsAmount: GridColDef<Manufacturer> = {
    field: "productsAmount",
    headerName: "Products",
    type: "number",
    minWidth: 150,
    editable: false,
  }

  export const ProductsStatistic: GridColDef<Manufacturer & any> = {
    field: "shelfLife",
    headerName: "Products, in use/all",
    type: "string",
    minWidth: 150,
    editable: false,
    valueGetter: ({ row: { productsAmount, productsInUseCount } }) => {
      let value = "-"
      if (productsAmount !== 0) {
        value = `${productsInUseCount}/${productsAmount}`
      }
      return value
    },
    renderCell: ({ value }) => (
      <Typography textAlign="right">{value}</Typography>
    ),
  }

  export const CreatedAt: GridColDef<Manufacturer> = {
    field: "createdAt",
    headerName: "Created At",
    type: "string",
    valueFormatter: ({ value }) => value ? format(new Date(value), 'PPpp') : '',
    minWidth: 200,
    editable: false,
  }
}

import { useMutation, UseMutationOptions } from '@tanstack/react-query'
import { Entities } from 'src/enums'
import { ManageSensorBLEStatePayload, ManageSensorBLEStateResponse } from 'src/api'
import { useApiInstance } from './useApiInstance'

interface EditSensorsBleStatePayload extends ManageSensorBLEStatePayload {
  isEnabledBle: boolean
}

interface RequestParams {
  options?: Omit<
    UseMutationOptions<ManageSensorBLEStateResponse[], Error, EditSensorsBleStatePayload>,
    'mutationKey' | 'mutationFn'
  >;
}

export const useEditSensorsBleStateRequest = ({ options }: RequestParams = {}) => {
  const api = useApiInstance()

  return useMutation<ManageSensorBLEStateResponse[], Error, EditSensorsBleStatePayload>(
    [Entities.Sensors, 'edit-ble-state'],
    async (payload: EditSensorsBleStatePayload) => {
      const { isEnabledBle, sensorImeis } = payload
      if (isEnabledBle) {
        const { data } = await api.v1AdminSensorsTurnOnBlePost({ sensorImeis })
        return data as ManageSensorBLEStateResponse[]
      }

      const { data } = await api.v1AdminSensorsTurnOffBlePost({ sensorImeis })
      return data as ManageSensorBLEStateResponse[]
    },
    options,
  )
}

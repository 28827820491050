import React, { FC } from 'react'
import { Typography, Grid, Button } from '@mui/material'
import { ReportComponentVisibilitySwitch } from './reportComponentVisibilitySwitch'

interface Props {
  id: number
  name: string
  description?: string
  isVisible: boolean
  onEdit: (id: number) => void
}

export const InlineReportComponent: FC<Props> = (props) => {
  const { id, name, description, isVisible, onEdit } = props

  return (
    <>
      <Grid item xs={8}>
        <Grid container flexDirection='column'>
          <Grid item xs={12}>
            <Typography>{name}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2">{description || ''}</Typography>
          </Grid>
        </Grid>   
      </Grid>
      <Grid container item xs={3} justifyContent="flex-end" alignContent="center" spacing={2}>
        <Grid item alignSelf="center">
          <Typography variant='body1'>Is active?</Typography>
        </Grid>
        <Grid item>
          <ReportComponentVisibilitySwitch
            name={name}
            reportComponentId={id as number}
            isVisible={isVisible}
          />
        </Grid>
      </Grid>
      <Grid container item xs={1} justifyContent="flex-end">
        <Button
          variant="contained"
          onClick={() => onEdit(id)}
        >
          Edit
        </Button>
      </Grid>
    </>
  )
}
import Box from '@mui/material/Box'
import { FC } from 'react'
import { DataLoad, RapidRHColumnsDataGrid } from 'src/components'
import { useWagnerProbeListRequest } from 'src/hooks/api'
import { useQueryParams } from 'src/hooks/ui'
import { WagnerProbeList } from 'src/pages/WagnerProbe.list'

interface ProjectWagnerProbeListProps {
  projectId: number;
}

const defaultPerPage = 50
const defaultPage = 1
const initialQueryParams = {
  page: `${defaultPage}`,
  rowsPerPage: `${defaultPerPage}`,
}
const rapidRhColumns = [
  RapidRHColumnsDataGrid.ProbeId,
  RapidRHColumnsDataGrid.IsOnline,
  RapidRHColumnsDataGrid.ReadingTime,
  RapidRHColumnsDataGrid.Label,
  RapidRHColumnsDataGrid.ReaderId,
  RapidRHColumnsDataGrid.VerizonGAT,
  RapidRHColumnsDataGrid.Contractor,
  RapidRHColumnsDataGrid.ProjectSection,
  RapidRHColumnsDataGrid.LastDeviceType,
  RapidRHColumnsDataGrid.LastSignalStrength,
  RapidRHColumnsDataGrid.LastBatteryLevel,
  RapidRHColumnsDataGrid.ReceivedFromDataGrabber,
  RapidRHColumnsDataGrid.ReceivedFromMiniGrabber,
  RapidRHColumnsDataGrid.ReceivedFromTotalReader,
  RapidRHColumnsDataGrid.ReceivedFromRepeater,
  RapidRHColumnsDataGrid.ReceivedViaPhone,
  RapidRHColumnsDataGrid.ReceivedViaGAT,
  RapidRHColumnsDataGrid.CreatedAt,
]

export const ProjectWagnerProbeList: FC<ProjectWagnerProbeListProps> = ({
  projectId,
}) => {
  const [queryParams, setQueryParams] = useQueryParams(initialQueryParams)
  const { isInitialLoading, isError, error, data } = useWagnerProbeListRequest({
    params: {
      projectId,
      ...queryParams,
    },
  })
  const exportedFileName = `${document.title}_rapid_rh_probes`

  return (
    <DataLoad isLoading={false} isError={isError} errorMessage={error?.message}>
      <Box height="500px">
        <WagnerProbeList
          wagnerProbeCount={data?.count || 0}
          wagnerProbes={data?.rows || []}
          isLoadingWagnerProbes={isInitialLoading}
          setQueryParams={setQueryParams}
          queryParams={queryParams}
          exportedFileName={exportedFileName}
          tableColumns={rapidRhColumns}
        />
      </Box>
    </DataLoad>
  )
}

const opModeOptions = [
  { name: 'None', value: '' },
  { name: 'Disabled', value: 0 },
  { name: 'Enabled', value: 1 },
]

const reportTypeOptions = [
  { name: 'None', value: '' },
  { name: 'Disabled', value: 0 },
  { name: 'One Time', value: 1 },
  { name: 'Periodic', value: 2 },
  { name: 'Periodic On Change', value: 3 },
]

const bleDataModeOptions = [
  { name: 'None', value: '' },
  { name: 'BTM250T Advertisement Data', value: 0 },
  { name: 'Manufacturer Advertisement Packet', value: 1 },
  { name: 'BLE(X) Advertisement Data', value: 2 },
]

export const getOpModeName = (opModeValue: number | undefined | null): string => {
  if (opModeValue === undefined || opModeValue === null) return '-'
  const opModeItem = opModeOptions.find((item) => item.value === opModeValue)
  return opModeItem?.name || '-'
}

export const getReportTypeName = (reportTypeValue: number | undefined | null): string => {
  if (reportTypeValue === undefined || reportTypeValue === null) return '-'
  const reportTypeItem = reportTypeOptions.find((item) => item.value === reportTypeValue)
  return reportTypeItem?.name || '-'
}

export const getBleDataModeName = (dataModeValue: number | undefined | null): string => {
  if (dataModeValue === undefined || dataModeValue === null) return '-'

  const dataModeItem = bleDataModeOptions.find((item) => item.value === dataModeValue)
  return dataModeItem?.name || '-'
}

import { FC, useState } from 'react'
import { Switch } from 'src/components/ui'
import { SearchParamsKeys } from 'src/enums'
import { useQueryParams } from 'src/hooks/ui'

export const HideArchivedTasksFilter: FC = () => {
  const [params, setQueryParams, removeQueryParams] = useQueryParams()
  const [checked, setChecked] = useState(() => {
    return params[SearchParamsKeys.HideArchivedTasks] === 'true'
  })
  const onChangeStatus = async (_checked: boolean) => {
    setChecked(_checked)
    if (_checked === false) {
      removeQueryParams([SearchParamsKeys.HideArchivedTasks])
    }
    if (_checked === true) {
      await setQueryParams({
        [SearchParamsKeys.HideArchivedTasks]: 'true',
      })
    }
  }

  return (
    <Switch
      name={SearchParamsKeys.HideArchivedTasks}
      label="Hide Archived Tasks"
      SwitchProps={{
        checked,
        onChange: (e) => onChangeStatus(e.target.checked),
      }}
    />
  )
}

import { parsePhoneNumberFromString } from 'libphonenumber-js'
import * as Yup from 'yup'
import { formatPhone } from './formatters'

const parsePhoneNumber = (value: string | undefined) => {
  if (!value) {
    return null
  }
  const formattedValue = value[0] === '+' ? value : `+${value}`
  return parsePhoneNumberFromString(formattedValue)
}

export const phoneRequiredValidation = Yup.string()
  .required('Phone number is required')
  .test(function (value) {
    const phoneNumber = parsePhoneNumber(value)
    return (
      (phoneNumber && phoneNumber.isValid())
      || this.createError({
        path: this.path,
        message: 'Phone number is invalid',
      })
    )
  })

export const phoneNullableValidation = Yup.string().nullable().test(function (value) {
  if (!value) return true
  const formattedValue = formatPhone(value)
  if (formattedValue) {
    const phoneNumber = parsePhoneNumber(value)
    if (!phoneNumber || !phoneNumber.isValid()) {
      return this.createError({
        path: this.path,
        message: 'Phone number is invalid',
      })
    }
  }

  return true
})

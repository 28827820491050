import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { APIList } from 'src/interfaces'
import { Entities } from 'src/enums'
import { User } from 'src/api'
import { useApiInstance } from './useApiInstance'
import {
  buildRequestParams,
  ObjectType,
  transformPage,
  transformPerPage,
} from './helpers'

interface RequestParams {
  params?: Partial<AdminForemansGetParams>;
  paramsTransform?: Array<(params: Record<string, any>) => Record<string, any>>;
  options?: Omit<
    UseQueryOptions<unknown, Error, APIList<User>>,
    'queryKey' | 'queryFn'
  >;
}

interface AdminForemansGetParams {
  limit: number;
  offset: number;
  sort: string;
  contractorId: number;
}

const schema: ObjectType['schema'] = {
  limit: 'number',
  offset: 'number',
  sort: 'string',
  contractorId: 'number'
}

export const useForemanListRequest = ({
  params = {},
  paramsTransform = [],
  options,
}: RequestParams = {}) => {
  const api = useApiInstance()

  const transformedParams = [
    transformPerPage,
    transformPage,
    ...paramsTransform,
  ].reduce((param, transform) => transform(param), params)

  const queryParams = buildRequestParams<AdminForemansGetParams>(
    transformedParams,
    schema
  )

  return useQuery<unknown, Error, APIList<User>>(
    [Entities.Users, JSON.stringify(transformedParams)],
    async () => {
      const { limit, offset, sort, contractorId } = queryParams
      const { data } = await api.v1AdminUsersForemansGet(contractorId, limit, offset, sort)
      return data
    },
    {
      ...options,
    }
  )
}

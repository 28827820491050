import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { APIList } from 'src/interfaces'
import { Entities } from 'src/enums'
import { Sensor } from 'src/api'
import { useApiInstance } from './useApiInstance'
import {
  buildRequestParams,
  ObjectType,
  transformPage,
  transformPerPage,
} from './helpers'

interface RequestParams {
  params?: Partial<AdminSensorsGetParams>;
  paramsTransform?: Array<(params: Record<string, any>) => Record<string, any>>;
  options?: Omit<
    UseQueryOptions<unknown, Error, APIList<Sensor>>,
    'queryKey' | 'queryFn'
  >;
}

interface AdminSensorsGetParams {
  limit: number;
  offset: number;
  sort: string;
  projectId: number;
  contractorId: number;
  stateId: number;
  lastHeard: string;
  lastPing: string;
  synchronizationPeriod: string;
  accelerationTime: string;
  batteryTime: string;
  dewpointTime: string;
  humidityTime: string;
  lightTime: string;
  locationTime: string;
  locationGpsTime: string;
  orientationTime: string;
  pressureTime: string;
  signalStrengthTime: string;
  temperatureTime: string;
  batteryLevel: number;
  signalStrength: number;
  search: string;
  currentSensorConfiguration: string;
  isBleEnabled: boolean;
}

const schema: ObjectType['schema'] = {
  limit: 'number',
  offset: 'number',
  sort: 'string',
  projectId: 'number',
  contractorId: 'number',
  stateId: 'number',
  lastHeard: 'string',
  lastPing: 'string',
  synchronizationPeriod: 'string',
  accelerationTime: 'string',
  batteryTime: 'string',
  dewpointTime: 'string',
  humidityTime: 'string',
  lightTime: 'string',
  locationTime: 'string',
  locationGpsTime: 'string',
  orientationTime: 'string',
  pressureTime: 'string',
  signalStrengthTime: 'string',
  temperatureTime: 'string',
  batteryLevel: 'number',
  signalStrength: 'number',
  search: 'string',
  currentSensorConfiguration: 'string',
  isBleEnabled: 'boolean',
}

export const useSensorListRequest = ({
  params = {},
  paramsTransform = [],
  options,
}: RequestParams = {}) => {
  const api = useApiInstance()

  const transformedParams = [
    transformPerPage,
    transformPage,
    ...paramsTransform,
  ].reduce((param, transform) => transform(param), params)
  const queryParams = buildRequestParams<AdminSensorsGetParams>(
    transformedParams,
    schema,
  )
  return useQuery<unknown, Error, APIList<Sensor>>(
    [Entities.Sensors, JSON.stringify(params)],
    async () => {
      const {
        limit,
        offset,
        sort,
        projectId,
        contractorId,
        stateId,
        lastHeard,
        lastPing,
        synchronizationPeriod,
        accelerationTime,
        batteryTime,
        dewpointTime,
        humidityTime,
        lightTime,
        locationTime,
        locationGpsTime,
        orientationTime,
        pressureTime,
        signalStrengthTime,
        temperatureTime,
        batteryLevel,
        signalStrength,
        search,
        currentSensorConfiguration,
        isBleEnabled,
      } = queryParams
      const { data } = await api.v1AdminSensorsGet(
        limit,
        offset,
        sort,
        projectId,
        contractorId,
        stateId,
        lastHeard,
        lastPing,
        synchronizationPeriod,
        accelerationTime,
        batteryTime,
        dewpointTime,
        humidityTime,
        lightTime,
        locationTime,
        locationGpsTime,
        orientationTime,
        pressureTime,
        signalStrengthTime,
        temperatureTime,
        batteryLevel,
        signalStrength,
        search,
        currentSensorConfiguration,
        isBleEnabled,
      )
      return data
    },
    {
      enabled: Object.keys(transformedParams).length > 0,
      ...options,
    },
  )
}

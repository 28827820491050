import { useMutation, UseMutationOptions } from '@tanstack/react-query'
import { Entities } from 'src/enums'
import { Contractor } from 'src/api'
import { useApiInstance } from './useApiInstance'

interface RequestParams {
  options?: Omit<
    UseMutationOptions<Contractor, Error, { id: number }, Contractor>,
    'mutationKey' | 'mutationFn'
  >;
}

export const useDeactivateContractor = ({ options }: RequestParams = {}) => {
  const api = useApiInstance()

  return useMutation<Contractor, Error, { id: number }, Contractor>(
    [Entities.Contractors, 'deactivate'],
    async ({ id }) => {
      const { data } = await api.v1AdminContractorsContractorIdDeactivatePost(
        id,
      )
      return data as Contractor
    },
    { ...options },
  )
}

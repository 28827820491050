import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { Entities } from 'src/enums'
import { Project, SensorState } from 'src/api'
import { useApiInstance } from './useApiInstance'

interface RequestParams {
  sensorUniqueId: string;
  params?: {
    sort?: string;
  };
  options?: Omit<
    UseQueryOptions<Project[], Error, SensorState[]>,
    'queryKey' | 'queryFn'
  >;
}

export const useSensorPastProjectListRequest = ({
  sensorUniqueId,
  params = {},
  options,
}: RequestParams) => {
  const api = useApiInstance()
  return useQuery<Project[], Error, SensorState[]>(
    [Entities.SensorPastProjects, sensorUniqueId],
    async () => {
      const { sort } = params
      const { data } = await api.v1AdminSensorsUniqueIdPastProjectsGet(sensorUniqueId, sort)
      return data as Project[]
    },
    options,
  )
}

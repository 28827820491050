import { FC } from 'react'
import { Grid, Typography } from '@mui/material'
import { Table, DynamicColumn } from 'src/components/ui'
import { EventDashboardStatistics, EventTypeDashboardStatistics } from 'src/api'
import { EventTypeDashboardColumns } from 'src/components/columns/dashboard'

interface EventDashboardProps {
  eventStatistics: EventDashboardStatistics
}

const columns: DynamicColumn<{ name: string, eventsNumber: number }>[] = [
  EventTypeDashboardColumns.EventName,
  EventTypeDashboardColumns.EventsNumber,
]

export const EventDashboard: FC<EventDashboardProps> = (props) => {
  const { eventStatistics } = props
  const data: EventTypeDashboardStatistics[] = [
    { name: 'Total', eventsNumber: eventStatistics.eventsNumber },
    ...eventStatistics.types,
  ]
  return (
    <Grid container spacing={2}>
      <Grid item>
        <Typography variant="h5">Job File Events</Typography>
      </Grid>
      <Grid item container>
        <Table
          columns={columns}
          data={data}
          showFooter={false}
          sx={{ width: '100%' }}
        />
      </Grid>
    </Grid>

  )
}

/* eslint-disable jsx-a11y/alt-text */
import { CardContent, Grid, Button, Card, Typography } from "@mui/material"
import { FC, useState, ChangeEvent } from "react"
import { FileIcon } from "src/components/icons"
import { OuterLink } from "src/components/ui"
import { ImageAllowedTypes } from "src/enums"
import { useAddPopupMessage } from "src/hooks/ui"

const allowedTypes = [
  ImageAllowedTypes.ZIP,
  ImageAllowedTypes.OCTET,
  ImageAllowedTypes.APPLE_IMAGE,
]

export enum MainState {
  INITIAL = "initial",
  UPLOADED = "uploaded"
}

interface FileUploadCardProps {
  updateFile: (value: File | null) => void;
  link?: string;
  fileName?: string;
  setIsRemoved?: (value: boolean) => void;
  buttonTitle?: string;
}

export const FileUploadCard: FC<FileUploadCardProps> = ({
  updateFile,
  link,
  setIsRemoved,
  buttonTitle = 'Upload file',
  fileName,
}) => {
  const [mainState, setMainState] = useState<MainState>(link ? MainState.UPLOADED : MainState.INITIAL)
  const addMessage = useAddPopupMessage()
  const [selectedFile, setSelectedFile] = useState<(string | ArrayBuffer | null)[] | null>(null)
  const [originalFileName, setOriginalFileName] = useState<string | null>(fileName || null)

  const handleUploadClick = (event: ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target
    if (!files || files?.length === 0) return
    const [file] = Array.from(files)

    const isTypeAllowed = allowedTypes.some((type) => file.type === type)
    console.log('---------file.type', file.type)
    if (!isTypeAllowed) {
      addMessage({ text: 'Incorrect file type', type: 'error' })
    } else {
      const reader = new FileReader()
      reader.readAsDataURL(file)

      reader.onloadend = (e) => {
        setSelectedFile([reader.result])
        setOriginalFileName(file.name)
      }

      setMainState(MainState.UPLOADED)
      updateFile(file)
    }
  }

  const fileResetHandler = () => {
    setMainState(MainState.INITIAL)
    if (typeof setIsRemoved === 'function') setIsRemoved(true)
  }

  const renderInitialState = () => {
    return (
      <>
        <CardContent>
          <Button
            component="label"
            variant="contained"
          >
            {buttonTitle}
            <input accept=".zip,.img" id="fileInput" type="file" hidden onChange={handleUploadClick} />
          </Button>
        </CardContent>
      </>
    )
  }

  const renderUploadedState = () => {
    return (
      <>
        <CardContent sx={{ width: "100%" }}>
          <Button style={{ float: "right" }} onClick={fileResetHandler}>
            Delete file
          </Button>
          <Grid container justifyContent="flex-start" alignItems="center">
            {
              selectedFile || link ? (
                <>
                  <Grid item>
                    <FileIcon />
                  </Grid>
                  <Grid item>
                    {
                      link ? (
                        <OuterLink to={link}>
                          <Typography variant="body1" sx={{ ml: 1 }}>
                            {originalFileName}
                          </Typography>
                        </OuterLink>
                      ) : (
                        <Typography variant="body1" sx={{ ml: 1 }}>
                          {originalFileName}
                        </Typography>
                      )
                    }
                  </Grid>
                </>
              ) : null
            }
          </Grid>
        </CardContent>
      </>
    )
  }

  return (
    <>
      <Card>
        <Grid container justifyContent="center">
          {(mainState === MainState.INITIAL && renderInitialState()) ||
            (mainState === MainState.UPLOADED &&
              renderUploadedState())}
        </Grid>
      </Card>
    </>
  )
}

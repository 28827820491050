/* eslint-disable jsx-a11y/alt-text */
import { CardContent, Grid, Button, Card } from "@mui/material"
import { FC, useState, ChangeEvent } from "react"
import { ImageAllowedTypes } from "src/enums"
import { useAddPopupMessage } from "src/hooks/ui"

const allowedTypes = [
  ImageAllowedTypes.PNG,
  ImageAllowedTypes.JPEG,
  ImageAllowedTypes.JPG,
  ImageAllowedTypes.HEIC,
  ImageAllowedTypes.HEIF,
  ImageAllowedTypes.HEIC_SEQUENCE,
  ImageAllowedTypes.HEIF_SEQUENCE
]

export enum MainState {
  INITIAL = "initial",
  UPLOADED = "uploaded"
}

interface ImageUploadCardProps {
  updateFile: (value: File | null) => void;
  link?: string;
  setIsRemoved?: (value: boolean) => void;
  buttonTitle?: string;
}

export const ImageUploadCard: FC<ImageUploadCardProps> = ({
  updateFile,
  link,
  setIsRemoved,
  buttonTitle = 'Upload logo',
}) => {
  const [mainState, setMainState] = useState<MainState>(link ? MainState.UPLOADED : MainState.INITIAL)
  const addMessage = useAddPopupMessage()
  const [selectedFile, setSelectedFile] = useState<(string | ArrayBuffer | null)[] | null>(null)

  const handleUploadClick = (event: ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target
    if (!files || files?.length === 0) return
    const [file] = Array.from(files)

    const isTypeAllowed = allowedTypes.some((type) => file.type === type)
    if (!isTypeAllowed) {
      addMessage({ text: 'Incorrect file type', type: 'error' })
    } else {
      const reader = new FileReader()
      reader.readAsDataURL(file)

      reader.onloadend = (e) => {
        setSelectedFile([reader.result])
      }

      setMainState(MainState.UPLOADED)
      updateFile(file)
    }
  }

  const renderInitialState = () => {
    return (
      <>
        <CardContent>
          <Button
            component="label"
            variant="contained"
          >
            {buttonTitle}
            <input accept="image/*" id="fileInput" type="file" hidden onChange={handleUploadClick} />
          </Button>
        </CardContent>
      </>
    )
  }

  const renderUploadedState = () => {
    return (
      <>
        <CardContent sx={{ width: "100%" }}>
          <Button style={{ float: "right" }} onClick={imageResetHandler}>
            Delete photo
          </Button>
          <Grid container justifyContent="flex-start">
            <img
              style={{
                objectFit: "scale-down",
                width: 400,
                height: 400,
                padding: 5
              }}
              src={selectedFile !== null ? String(selectedFile) : (link ? link : undefined)}
            />
          </Grid>
        </CardContent>
      </>
    )
  }

  const imageResetHandler = () => {
    setMainState(MainState.INITIAL)
    if (typeof setIsRemoved === 'function') setIsRemoved(true)
  }


  return (
    <>
      <Card>
        <Grid container justifyContent="center">
          {(mainState === MainState.INITIAL && renderInitialState()) ||
            (mainState === MainState.UPLOADED &&
              renderUploadedState())}
        </Grid>
      </Card>
    </>
  )

}
import React, { FC, useMemo, useState } from "react"
import { Button, Grid } from "@mui/material"
import { Link } from "react-router-dom"
import { DataLoad, Dropdown, HasSidebarLayout, ListHeader } from "src/components"
import { ReleaseCard } from "src/components/sections/release"
import { useConfigurationListRequest, useEditConfigurationParamRequest, useReleaseListRequest } from "src/hooks/api"
import { ConfigurationParameter, Release } from "src/api"
import { useAddPopupMessage, useDropdownItemFactory } from "src/hooks/ui"

interface ReleaseListProps {
  releases: Release[]
  configParamLatestAvailableRelease?: ConfigurationParameter
}

const ReleaseList: FC<ReleaseListProps> = ({
  releases,
  configParamLatestAvailableRelease,
}) => {
  const [latestRelease, setLatestRelease] = useState<string>(configParamLatestAvailableRelease?.value || "")
  const addMessage = useAddPopupMessage()
  const editConfigurationParamRequest = useEditConfigurationParamRequest({ id: configParamLatestAvailableRelease?.id || 0 })
  const update = (value: string) => {
    const isExistValue = releases.find((release) => release.number === value)
    if (!isExistValue) return addMessage({ type: 'error', text: 'Release not found' })

    const _latestRelease = latestRelease
    setLatestRelease(value)
    editConfigurationParamRequest.mutate(
      { value },
      {
        onSuccess: () => addMessage({ type: 'success', text: 'Updated successfully' }),
        onError: (error) => {
          addMessage({ type: 'error', text: error.message })
          setLatestRelease(_latestRelease)
        },
      }
    )
  }

  return (
    <Grid container direction="column" alignItems="stretch">
      <ListHeader title="What’s new release notes">
        <Grid container alignItems="center" spacing={2}>
          <Grid item width="200px">
            <Dropdown
              list={releases}
              name="release"
              label="Latest available release"
              value={latestRelease}
              menuItemFactory={useDropdownItemFactory({
                params: { labelProp: 'number', valueProp: 'number' },
              })}
              onChange={(_, value) => update(value)}
              onBlur={(_, value) => update(value)}
            />
          </Grid>
          <Grid item>
            <Link to="/releases/new" style={{ textDecoration: "none" }}>
              <Button role="link" variant="contained">
                Add new release
              </Button>
            </Link>
          </Grid>
        </Grid>
      </ListHeader>
      <Grid container flexDirection="column" mt={2}>
        {releases.map((release) => (
          <ReleaseCard release={release} key={release.id} />
        ))}
      </Grid>
    </Grid>
  )
}

const ReleaseListLoader: FC = () => {
  document.title = 'Floorcloud Admin Panel - Releases'
  const { isInitialLoading, isError, error, data } = useReleaseListRequest({
    params: { limit: 100, sort: 'id:desc' }
  })
  const { data: configListData } = useConfigurationListRequest({
    params: { limit: 100, sort: 'id:desc'}
  })
  const releases = data || []
  const latestAvailableRelease = useMemo(
    () => (configListData || []).find((config) => config.type === 'latest_available_release'),
    [configListData]
  )

  return (
    <HasSidebarLayout>
      <DataLoad
        isLoading={isInitialLoading}
        isError={isError}
        errorMessage={error?.message || ''}
      >
        <ReleaseList
          releases={releases}
          configParamLatestAvailableRelease={latestAvailableRelease}
        />
      </DataLoad>
    </HasSidebarLayout>
  )
}

export default ReleaseListLoader

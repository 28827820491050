import { SensorLastSentConfiguration } from 'src/api'
import { SensorConfigurationEditState } from 'src/interfaces'

export const useSensorEditConfiguration = () => {
  const makeSensorEditConfiguration = (
    lastSensorConfiguration: SensorLastSentConfiguration,
  ): SensorConfigurationEditState => {
    const hasFirmwareConfiguration = lastSensorConfiguration && typeof lastSensorConfiguration?.firmware === 'boolean'
    const editState: SensorConfigurationEditState = lastSensorConfiguration ? {
      ...(
        hasFirmwareConfiguration
          ? { firmware: lastSensorConfiguration.firmware === true ? "Requested" : "Not requested" }
          : {}
      ),
      device: {
        commMaxAwakeDuration: lastSensorConfiguration?.commMaxAwakeDuration,
        commMinAwakeDuration: lastSensorConfiguration?.commMinAwakeDuration,
        commSynchPeriod: lastSensorConfiguration?.commSynchPeriod,
        commTypeUpdate: lastSensorConfiguration?.commTypeUpdate,
      },
      ble: {
        monitorPeriod: lastSensorConfiguration?.bleMonitorPeriod,
        opMode: lastSensorConfiguration?.bleOpMode,
        reportOffset: lastSensorConfiguration?.bleReportOffset,
        reportPeriod: lastSensorConfiguration?.bleReportPeriod,
        reportType: lastSensorConfiguration?.bleReportType,
        scanDuration: lastSensorConfiguration?.bleScanDuration,
        minSigStr: lastSensorConfiguration?.bleMinSigStr,
        dataMode: lastSensorConfiguration?.bleDataMode,
        maxNumScan: lastSensorConfiguration?.bleMaxNumScan,
        manufacturerId: lastSensorConfiguration?.bleManufacturerId,
      },
      temperature: {
        monitorPeriod: lastSensorConfiguration?.temperatureMonitorPeriod,
        reportOffset: lastSensorConfiguration?.temperatureReportOffset,
        reportPeriod: lastSensorConfiguration?.temperatureReportPeriod,
        reportType: lastSensorConfiguration?.temperatureReportType,
        opMode: lastSensorConfiguration?.temperatureOpMode,
      },
      humidity: {
        monitorPeriod: lastSensorConfiguration?.humidityMonitorPeriod,
        reportOffset: lastSensorConfiguration?.humidityReportOffset,
        reportPeriod: lastSensorConfiguration?.humidityReportPeriod,
        reportType: lastSensorConfiguration?.humidityReportType,
        opMode: lastSensorConfiguration?.humidityOpMode,
      },
      pressure: {
        monitorPeriod: lastSensorConfiguration?.pressureMonitorPeriod,
        reportOffset: lastSensorConfiguration?.pressureReportOffset,
        reportPeriod: lastSensorConfiguration?.pressureReportPeriod,
        reportType: lastSensorConfiguration?.pressureReportType,
        opMode: lastSensorConfiguration?.pressureOpMode,
      },
      light: {
        monitorPeriod: lastSensorConfiguration?.lightMonitorPeriod,
        reportOffset: lastSensorConfiguration?.lightReportOffset,
        reportPeriod: lastSensorConfiguration?.lightReportPeriod,
        reportType: lastSensorConfiguration?.lightReportType,
        opMode: lastSensorConfiguration?.lightOpMode,
      },
      location: {
        monitorPeriod: lastSensorConfiguration?.locationMonitorPeriod,
        reportOffset: lastSensorConfiguration?.locationReportOffset,
        reportPeriod: lastSensorConfiguration?.locationReportPeriod,
        reportType: lastSensorConfiguration?.locationReportType,
        opMode: lastSensorConfiguration?.locationOpMode,
      },
      accelerometer: {
        monitorPeriod: lastSensorConfiguration?.accelerometerMonitorPeriod,
        reportOffset: lastSensorConfiguration?.accelerometerReportOffset,
        reportPeriod: lastSensorConfiguration?.accelerometerReportPeriod,
        reportType: lastSensorConfiguration?.accelerometerReportType,
        opMode: lastSensorConfiguration?.accelerometerOpMode,
      },
      gyro: {
        monitorPeriod: lastSensorConfiguration?.gyroMonitorPeriod,
        reportOffset: lastSensorConfiguration?.gyroReportOffset,
        reportPeriod: lastSensorConfiguration?.gyroReportPeriod,
        reportType: lastSensorConfiguration?.gyroReportType,
        opMode: lastSensorConfiguration?.gyroOpMode,
      },
      battery: {
        monitorPeriod: lastSensorConfiguration?.batteryMonitorPeriod,
        reportOffset: lastSensorConfiguration?.batteryReportOffset,
        reportPeriod: lastSensorConfiguration?.batteryReportPeriod,
        reportType: lastSensorConfiguration?.batteryReportType,
        opMode: lastSensorConfiguration?.batteryOpMode,
      },
      rfSignal: {
        monitorPeriod: lastSensorConfiguration?.rfSignalMonitorPeriod,
        reportOffset: lastSensorConfiguration?.rfSignalReportOffset,
        reportPeriod: lastSensorConfiguration?.rfSignalReportPeriod,
        reportType: lastSensorConfiguration?.rfSignalReportType,
        opMode: lastSensorConfiguration?.rfSignalOpMode,
      },
    } : {}

    return editState
  }

  const makeNullableEmptyValues = (editState: SensorConfigurationEditState): SensorConfigurationEditState => {
    const copy = { ...editState }
    for (const rootValue of Object.values(copy)) {
      if (typeof rootValue !== 'object') continue
      for (const [childKey, childValue] of Object.entries(rootValue)) {
        if (childValue === '') {
          Object.assign(rootValue, { [childKey]: null })
        }
      }
    }

    return copy
  }

  return {
    makeSensorEditConfiguration,
    makeNullableEmptyValues,
  }
}

import { GridColDef } from '@mui/x-data-grid-premium'
import { format } from "date-fns"
import { InnerLink } from "src/components/ui"
import { ProductImport, ProductImportStatusEnum } from "src/api"
import { getProductImportStatus } from '../sections/productImport/helpers'

export namespace ProductImportColumns {
  export const Name: GridColDef<ProductImport> = {
    field: "fileName",
    headerName: "File name",
    type: "string",
    minWidth: 250,
    editable: false,
    renderCell: ({ row, value }) => {
      if (row && row.id && value) {
        return <InnerLink to={`/product-imports/${row.id}`}>{value}</InnerLink>
      }
      return "-"
    }
  }

  export const Author: GridColDef<ProductImport> = {
    field: "author.name",
    headerName: "Author",
    minWidth: 150,
    editable: false,
    valueGetter: ({ row }) => row.author?.name || null,
    renderCell: ({ value, row }) => {
      const { author } = row
      if (author && author.id && value) {
        return <InnerLink to={`/users/${author.id}`}>{value}</InnerLink>
      }
      return "-"
    }
  }

  export const Status: GridColDef<ProductImport> = {
    field: "status",
    headerName: "Status",
    minWidth: 270,
    editable: false,
    valueFormatter: ({ value }) => {
      return value ? getProductImportStatus(value as ProductImportStatusEnum) : null
    }
  }

  export const CreatedAt: GridColDef<ProductImport> = {
    field: "createdAt",
    headerName: "Imported at",
    minWidth: 200,
    editable: false,
    valueFormatter: ({ value }) => value ? format(new Date(value), 'PPpp') : null
  }
}

export default ProductImportColumns

import { useMutation, UseMutationOptions } from '@tanstack/react-query'
import { Entities } from 'src/enums'
import { Release, ReleaseEditing } from 'src/api'
import { useApiInstance } from './useApiInstance'

interface RequestParams {
  id: number;
  options?: Omit<
    UseMutationOptions<unknown, Error, ReleaseEditing, Release>,
    'mutationKey' | 'mutationFn'
  >;
}

export const useEditReleaseRequest = ({ id, options }: RequestParams) => {
  const api = useApiInstance()

  return useMutation<unknown, Error, ReleaseEditing, Release>(
    [Entities.Release, id],
    async (payload: ReleaseEditing) => {
      const { data } = await api.v1AdminReleasesReleaseIdPut(id, payload)
      return data
    },
    { ...options },
  )
}

import React, { FC, useState, useEffect } from "react"
import { Paper, Box, CircularProgress, Button } from "@mui/material"
import { useLocation, useNavigate } from "react-router-dom"
import {
  FullscreenLayout,
  Centralize,
  LogoVertical,
  GetCodeForm,
  GetCodeFormState,
  SendCodeForm,
  SendCodeFormState,
} from "src/components"
import {
  useSignInGetCodeRequest,
  useSignInSendCodeRequest,
} from "src/hooks/api"
import { useAddPopupMessage } from "src/hooks/ui"
import { config } from "src/config"
import { LocalStorageKey } from "src/enums"
import { formatPhone } from "../utils/formatters"

interface TimerProps {
  title: string;
  date: Date;
  resetTimer: () => void;
}

const Timer: FC<TimerProps> = ({ title, date, resetTimer }) => {
  const [seconds, setSeconds] = useState(
    Math.round((date.getTime() - Date.now()) / 1000)
  )
  useEffect(() => {
    const timeout = setTimeout(() => {
      const remain = seconds - 1
      remain <= 0 ? resetTimer() : setSeconds(remain)
    }, 1000)
    return () => {
      clearTimeout(timeout)
    }
  }, [resetTimer, seconds])
  return (
    <>
      {title} {seconds} sec.
    </>
  )
}

const SignIn: FC = () => {
  document.title = 'Floorcloud Admin Panel - Sign In'
  const navigate = useNavigate()
  const location = useLocation()
  const addPopupMessage = useAddPopupMessage()
  const [currentForm, setCurrentForm] = useState<"getCode" | "sendCode">(
    "getCode"
  )
  const [userPhone, setUserPhone] = useState("")

  const [nextSubmitDate, setNextSubmitDate] = useState<Date | null>(() => {
    const result = localStorage.getItem(LocalStorageKey.NextSignInDate)
    const date = new Date(result as string)
    return date instanceof Date && !Number.isNaN(date) ? date : null
  })

  const resetTimer = () => {
    localStorage.removeItem(LocalStorageKey.NextSignInDate)
    setNextSubmitDate(null)
  }

  const getCodeRequestHandler = useSignInGetCodeRequest({
    options: {
      onSuccess: (data, { to }) => {
        const newSubmitDate = new Date(Date.now() + config.signInTimeout)
        localStorage.setItem(
          LocalStorageKey.NextSignInDate,
          newSubmitDate.toISOString()
        )
        setNextSubmitDate(newSubmitDate)
        setUserPhone(to)
        setCurrentForm("sendCode")
      },
      onError: (error) => {
        addPopupMessage({ text: (error as Error).message, type: "error" })
      },
    },
  })

  const onGetCodeSubmit = ({ phone }: GetCodeFormState) => {
    getCodeRequestHandler.mutate({ to: formatPhone(phone) || "" })
  }

  const sendCodeRequestHandler = useSignInSendCodeRequest({
    options: {
      onSettled: () => {
        resetTimer()
      },
      onSuccess: () => {
        setUserPhone("")
        setCurrentForm("getCode")
        const { search } = location
        navigate(`/${search ? `${search}` : ""}`, { replace: true })
      },
      onError: (error) => {
        addPopupMessage({ text: (error as Error).message, type: "error" })
      },
    },
  })

  const onSendCodeSubmit = ({
    code,
    termsAndPrivacyAccepted,
  }: SendCodeFormState) => {
    sendCodeRequestHandler.mutate({
      to: userPhone,
      code,
      termsAndPrivacyAccepted,
    })
  }

  const backToPhone = () => {
    setCurrentForm("getCode")
    resetTimer()
  }

  return (
    <FullscreenLayout>
      <Centralize>
        <Paper>
          <Box p={3} width="350px">
            <Box
              pb={3}
              px={5}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <LogoVertical />
            </Box>
            {getCodeRequestHandler.isLoading ||
            sendCodeRequestHandler.isLoading ? (
                <Box display="flex" alignItems="center" justifyContent="center">
                  <CircularProgress />
                </Box>
              ) : (
                <>
                  {currentForm === "getCode" && (
                    <GetCodeForm
                      disableSubmit={
                        nextSubmitDate !== null &&
                      nextSubmitDate.getTime() > Date.now()
                      }
                      onSubmit={onGetCodeSubmit}
                      SubmitContent={
                        nextSubmitDate !== null &&
                      nextSubmitDate.getTime() > Date.now()
                          ? () => (
                            <Timer
                              date={nextSubmitDate as Date}
                              title="Resend code after"
                              resetTimer={resetTimer}
                            />
                          )
                          : void 0
                      }
                    />
                  )}
                  {currentForm === "sendCode" && (
                    <>
                      <SendCodeForm
                        termsAndPrivacyAccepted={
                          getCodeRequestHandler.data?.termsAndPrivacyAccepted ||
                        false
                        }
                        privacyPolicyLink={
                          getCodeRequestHandler.data?.privacyPolicyLink
                        }
                        termsOfUseLink={
                          getCodeRequestHandler.data?.termsOfUseLink
                        }
                        onSubmit={onSendCodeSubmit}
                      />
                      <Box
                        mt={2}
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                      >
                        <Button
                          size="small"
                          onClick={backToPhone}
                        >
                        Change phone
                        </Button>
                      </Box>
                    </>
                  )}
                </>
              )}
          </Box>
        </Paper>
      </Centralize>
    </FullscreenLayout>
  )
}

export default SignIn

import React, { FC } from "react"
import { useNavigate } from 'react-router-dom'
import { Button, Grid } from "@mui/material"
import { DataLoad, Table, DynamicColumn } from "src/components/ui"
import { useUserListRequest } from "src/hooks/api"
import { useQueryParams } from "src/hooks/ui"
import { User, UserRole } from "src/api"
import { UserRoles, SearchParamsKeys } from "src/enums"
import { UserColumns } from "src/components/columns/user"

const getUserColumns: (userRoles: UserRole[]) => DynamicColumn<User>[] = (
  userRoles
) => {
  const pmRoleId = userRoles.find((role) => role.name === UserRoles.PM)?.id
  const ownerRoleId = userRoles.find(
    (role) => role.name === UserRoles.Owner
  )?.id
  return [
    UserColumns.NameWithIcon(pmRoleId, ownerRoleId),
    UserColumns.Email,
    UserColumns.Phone,
    UserColumns.LastActive,
    UserColumns.CreatedAt,
    UserColumns.LastAction,
  ]
}

interface UsersOfContractorProps {
  contractorId: number;
  userRoles: UserRole[];
}

export const UsersOfContractor: FC<UsersOfContractorProps> = ({
  contractorId,
  userRoles,
}) => {
  const navigate = useNavigate()
  const [params, setQueryParams] = useQueryParams()
  const { tab, ...restParams } = params
  const { data, isInitialLoading, isError, error } = useUserListRequest({
    params: {
      ...restParams,
      contractorIds: [contractorId],
    },
  })

  const onAddUser = async () => {
    await setQueryParams({ [SearchParamsKeys.Contractor]: `${contractorId}` })
    navigate("/users/new")
  }

  return (
    <Grid container direction="column" justifyContent="stretch" spacing={2}>
      <Grid item>
        <Button variant="contained" onClick={onAddUser}>Add User</Button>
      </Grid>
      <Grid item>
        <DataLoad
          isLoading={false}
          isError={isError}
          errorMessage={error?.message}
        >
          <Table
            loading={isInitialLoading}
            data={data?.rows || []}
            columns={getUserColumns(userRoles)}
            count={data?.count || 0}
          />
        </DataLoad>
      </Grid>
    </Grid>
  )
}

import React, { FC } from "react"
import { Grid, Button, Typography, Paper } from "@mui/material"
import {
  DataGridPremium,
  useGridApiRef,
  GridPaginationModel,
  GridColDef,
} from '@mui/x-data-grid-premium'
import {
  HasSidebarLayout,
  DataLoad,
  ListHeader,
  InnerLink,
  SearchForm,
  TableToolbar,
} from "src/components"
import { useManufacturerListRequest } from "src/hooks/api"
import { useQueryParams } from "src/hooks/ui"
import { Manufacturer } from "src/api"
import { ManufacturerColumnsDataGrid } from "src/components/columns/manufacturer"
import { SearchParamsKeys } from "src/enums"
import { defaultRowsValuePerPage } from "src/config"

const columns: GridColDef<Manufacturer>[] = [
  ManufacturerColumnsDataGrid.Photo,
  ManufacturerColumnsDataGrid.NameWithIcon,
  ManufacturerColumnsDataGrid.Address,
  ManufacturerColumnsDataGrid.Phone,
  ManufacturerColumnsDataGrid.Email,
  ManufacturerColumnsDataGrid.ProductsStatistic,
  ManufacturerColumnsDataGrid.CreatedAt,
]
const defaultPerPage = 50
const defaultPage = 1
const defaultRowsPerPageOptions = defaultRowsValuePerPage
const initialQueryParams = {
  page: `${defaultPage}`,
  rowsPerPage: `${defaultPerPage}`,
}

const ManufacturerList: FC = () => {
  document.title = 'Floorcloud Admin Panel - Manufacturers'
  const apiRef = useGridApiRef()
  const [queryParams, setQueryParams] = useQueryParams(initialQueryParams)
  const { isInitialLoading, isError, data } = useManufacturerListRequest({
    params: queryParams,
  })
  const manufacturers: Manufacturer[] = data?.rows || []
  const count: number = data?.count || 0

  const productsAmount = manufacturers.reduce(
    (acc, manufacturer) => (acc += manufacturer.productsAmount || 0),
    0
  )
  const handleChangePaginationModel = async (pagination: GridPaginationModel): Promise<void> => {
    const newPage = pagination.page + 1
    setQueryParams({
      [SearchParamsKeys.Page]: `${newPage}`,
      [SearchParamsKeys.RowsPerPage]: `${pagination.pageSize}`,
    }, [
      SearchParamsKeys.Page,
      SearchParamsKeys.RowsPerPage,
    ])
  }

  const page = queryParams["page"] ? parseInt(queryParams["page"], 10) - 1 : defaultPage - 1
  const pageSize = queryParams["rowsPerPage"]
    ? parseInt(queryParams["rowsPerPage"], 10)
    : defaultPerPage

  return (
    <HasSidebarLayout>
      <DataLoad isLoading={false} isError={isError}>
        <Grid container direction="column" alignItems="stretch">
          <ListHeader title="Manufacturers">
            <Grid container alignItems="center" spacing={2}>
              <Grid item>
                <InnerLink to="/manufacturers/new">
                  <Button role="link" variant="contained">
                    New Manufacturer
                  </Button>
                </InnerLink>
              </Grid>
            </Grid>
          </ListHeader>
        </Grid>
        <Grid item>
          <SearchForm />
        </Grid>
        <Grid item>
          <Typography>Total amount of products: {productsAmount}</Typography>
        </Grid>
        <Paper sx={{ flexGrow: 1, minHeight: '200px', width: "100%", mt: 2 }}>
          <DataGridPremium<Manufacturer>
            apiRef={apiRef}
            pagination
            disableRowSelectionOnClick
            rows={manufacturers}
            columns={columns}
            rowCount={count}
            loading={isInitialLoading}
            paginationModel={{
              page: page as number,
              pageSize: pageSize as number
            }}
            rowThreshold={2}
            columnThreshold={2}
            rowHeight={65}
            paginationMode="server"
            pageSizeOptions={defaultRowsPerPageOptions}
            onPaginationModelChange={handleChangePaginationModel}
            localeText={{
              columnMenuSortAsc: "Sort A-Z",
              columnMenuSortDesc: "Sort Z-A",
            }}
            slots={{
              toolbar: TableToolbar
            }}
            sx={(theme) => ({
              border: "none",
              "& .MuiDataGrid-columnHeaderTitleContainerContent": {
                paddingLeft: "5px",
                overflow: "visible",
              },
              "& .MuiDataGrid-columnHeaderTitle": {
                whiteSpace: "normal",
                lineHeight: 1,
                fontSize: "13px",
                fontWeight: 400,
                color: "rgba(0, 0, 0, 0.6)",
                overflow: "visible",
              },
            })}
          />
        </Paper>
      </DataLoad>
    </HasSidebarLayout>
  )
}

export default ManufacturerList

import { ManufacturerAnnouncementPostPayload } from './manufacturerAnnouncementNewForm'

export function hasDuplicates(posts: ManufacturerAnnouncementPostPayload[]) {
  const arrLength = posts.length
  for (let i = 0; i < arrLength; i++) {
    const currentFeatureName = (posts[i].title || '').toLowerCase().replace(' ', '_')
    for (let j = i + 1; j < arrLength; j++) {
      const nextFeatureName = (posts[j].title || '').toLowerCase().replace(' ', '_')
      if (currentFeatureName !== '' && nextFeatureName !== '' && currentFeatureName === nextFeatureName) return true
    }
  }
  return false
}

import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { Entities } from 'src/enums'
import { DashboardStatistics } from 'src/api'
import { useApiInstance } from './useApiInstance'

interface RequestParams {
  options?: Omit<
    UseQueryOptions<DashboardStatistics, Error>,
    'queryKey' | 'queryFn'
  >;
}

export const useDashboardRequest = ({ options }: RequestParams = {}) => {
  const api = useApiInstance()

  return useQuery<DashboardStatistics, Error>(
    [Entities.Dashboard],
    async () => {
      const { data } = await api.v1AdminDashboardGet()
      return data as DashboardStatistics
    },
    options,
  )
}

import { useMutation, UseMutationOptions } from '@tanstack/react-query'
import { Entities } from 'src/enums'
import { UserGlobalNotification, UserGlobalNotificationPayloadByType } from 'src/api'
import { useApiInstance } from './useApiInstance'

interface RequestParams {
  userId: number
  options?: Omit<
  UseMutationOptions<unknown, Error, UserGlobalNotificationPayloadByType, UserGlobalNotification[]>,
    'queryKey' | 'queryFn'
  >;
}

export const useSetupUserGlobalNotificationsRequest = ({
  userId,
  options,
}: RequestParams) => {
  const api = useApiInstance()

  return useMutation<unknown, Error, UserGlobalNotificationPayloadByType, UserGlobalNotification[]>(
    [Entities.UserNotifications, 'update-by-type', userId],
    async (payload: UserGlobalNotificationPayloadByType) => {
      const { data } = await api.v1AdminUsersUserIdNotificationsByTypePut(userId, payload)
      return data
    },
    {
      ...options,
    },
  )
}

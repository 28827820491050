import React, { FC } from 'react'
import { Grid } from '@mui/material'
import { useAddPopupMessage } from 'src/hooks/ui'
import { useEditReportComponentRequest } from 'src/hooks/api'
import { ReportComponentNewForm } from 'src/components/forms'

interface Props {
  id: number;
  name: string;
  reportComponentDescription?: string;
  onChange: () => void;
  onCancel: () => void;
}

export const EditableReportComponent: FC<Props> = ({
  id,
  name,
  reportComponentDescription,
  onChange,
  onCancel,
}) => {
  const addMessage = useAddPopupMessage()
  const editReportComponentRequest = useEditReportComponentRequest({ id })

  const update = ({ displayName, description }: { displayName: string, description?: string }) => {
    editReportComponentRequest.mutate(
      { displayName, description: description || '' },
      {
        onSuccess: onChange,
        onError: (error) => addMessage({ type: 'error', text: error.message }),
      }
    )
  }

  return (
    <>
      <Grid item flexGrow={1}>
        <ReportComponentNewForm
          onSubmit={update}
          initialValues={{ displayName: name, description: reportComponentDescription }}
          onCancel={onCancel}
          isLoading={editReportComponentRequest.isLoading}
        />
      </Grid>
    </>
  )
}
import { FC } from 'react'
import { Typography, DialogActions, Button, Box } from '@mui/material'
import { StatefulDialog } from 'src/components/ui'
import { DialogNames } from 'src/enums'

interface Props {
  onConfirm: () => void
  onBack: () => void
}

export const CancelManufacturerAnnouncementModal: FC<Props> = ({
  onConfirm,
  onBack,
}) => {
  return (
    <StatefulDialog
      name={DialogNames.CancelManufacturerAnnouncement}
      actions={
        <Box mb={2} mx={2}>
          <DialogActions>
            <Button variant="outlined" onClick={() => onBack()}>
              Back
            </Button>
            <Button variant="contained" color="error" onClick={() => onConfirm()}>
              Cancel changes
            </Button>
          </DialogActions>
        </Box>
      }
    >
      <Box minWidth="300px">
        <Typography variant="h6">Are you sure you want to cancel all changes?</Typography>
      </Box>
    </StatefulDialog>
  )
}

import React, { FC } from 'react'
import {
  DataGridPremium,
  useGridApiRef,
  GridPaginationModel,
  GridColDef,
} from '@mui/x-data-grid-premium'
import Paper from '@mui/material/Paper'
import { Product } from 'src/api'
import { useQueryParams } from 'src/hooks/ui'
import { SearchParamsKeys } from 'src/enums'
import { defaultRowsValuePerPage } from 'src/config'
import { TableToolbar } from '../../ui'
import { ProductColumnsDataGrid } from '../../columns/product'

const columns: GridColDef[] = [
  ProductColumnsDataGrid.Name,
  ProductColumnsDataGrid.ManufacturerName,
  ProductColumnsDataGrid.ProductTypeName,
  ProductColumnsDataGrid.ShelfLife,
  ProductColumnsDataGrid.Tips,
  ProductColumnsDataGrid.Ranges,
  ProductColumnsDataGrid.IsProductInUse,
]
const defaultPerPage = 50
const defaultPage = 1
const defaultRowsPerPageOptions = defaultRowsValuePerPage
const initialQueryParams = {
  page: `${defaultPage}`,
  rowsPerPage: `${defaultPerPage}`,
}

interface ProductListProps {
  loading: boolean;
  count: number;
  data: Product[];
  noResultText?: string;
  showFooter?: boolean;
}

export const ProductList: FC<ProductListProps> = ({
  loading,
  data,
  count,
}) => {
  const apiRef = useGridApiRef()
  const [queryParams, setQueryParams] = useQueryParams(initialQueryParams)
  const handleChangePaginationModel = async (pagination: GridPaginationModel): Promise<void> => {
    const newPage = pagination.page + 1
    setQueryParams({
      [SearchParamsKeys.Page]: `${newPage}`,
      [SearchParamsKeys.RowsPerPage]: `${pagination.pageSize}`,
    }, [
      SearchParamsKeys.Page,
      SearchParamsKeys.RowsPerPage,
    ])
  }

  const exportedFileName = `${document.title}_products`
  const page = queryParams["page"] ? parseInt(queryParams["page"], 10) - 1 : defaultPage - 1
  const pageSize = queryParams["rowsPerPage"]
    ? parseInt(queryParams["rowsPerPage"], 10)
    : defaultPerPage

  return (
    <Paper sx={{ flexGrow: 1, minHeight: '200px', height: '100%', width: "100%" }}>
      <DataGridPremium<Product>
        apiRef={apiRef}
        pagination
        disableRowSelectionOnClick
        rows={data}
        columns={columns}
        rowCount={count}
        loading={loading}
        paginationModel={{
          page: page as number,
          pageSize: pageSize as number
        }}
        rowThreshold={2}
        columnThreshold={2}
        rowHeight={65}
        paginationMode="server"
        pageSizeOptions={defaultRowsPerPageOptions}
        onPaginationModelChange={handleChangePaginationModel}
        localeText={{
          columnMenuSortAsc: "Sort A-Z",
          columnMenuSortDesc: "Sort Z-A",
        }}
        slots={{
          toolbar: () => <TableToolbar exportedFileName={exportedFileName} />
        }}
        sx={(theme) => ({
          border: "none",
          "& .MuiDataGrid-columnHeaderTitleContainerContent": {
            paddingLeft: "5px",
            overflow: "visible",
          },
          "& .MuiDataGrid-columnHeaderTitle": {
            whiteSpace: "normal",
            lineHeight: 1,
            fontSize: "13px",
            fontWeight: 400,
            color: "rgba(0, 0, 0, 0.6)",
            overflow: "visible",
          },
        })}
      />
    </Paper>
  )
}

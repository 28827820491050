import React, { FC, useRef, ChangeEvent } from 'react'
import { Grid, IconButton, InputAdornment } from '@mui/material'
import { Close } from '@mui/icons-material'
import * as Yup from 'yup'
import { useFormik, FormikProvider, Form } from 'formik'
import {
  AutocompleteTextField,
  NumberTextField,
  TextField,
} from 'src/components/ui'
import { useQueryParams } from 'src/hooks/ui'
import { SearchParamsKeys } from 'src/enums'
import { Contractor } from 'src/api'

const validationSchema = Yup.object().shape({
  [SearchParamsKeys.Contractor]: Yup.string().nullable(),
  [SearchParamsKeys.LastActive]: Yup.number()
    .integer().min(0).max(366).nullable(),
})

interface UserListFiltersProps {
  currentContractor?: Contractor | null;
  contractors?: Contractor[];
  contractorsLoading?: boolean;
  findContractorsByName?: (name: string) => void;
}

export const UserListFilters: FC<UserListFiltersProps> = ({
  contractors = [],
  contractorsLoading = false,
  findContractorsByName = () => {},
  currentContractor = null,
}) => {
  const resetTimer = useRef<NodeJS.Timeout | null>(null)
  const [queryParams, setQueryParams] = useQueryParams()

  const formik = useFormik({
    validationSchema,
    initialValues: {
      [SearchParamsKeys.Contractor]:
        queryParams[SearchParamsKeys.Contractor] || null,
      [SearchParamsKeys.LastActive]:
        queryParams[SearchParamsKeys.LastActive] || null,
    },
    onSubmit: async (formState) => {
      const lastActive = formState[SearchParamsKeys.LastActive] || null
      const contractor = formState[SearchParamsKeys.Contractor] || null
      await setQueryParams(
        {
          ...(lastActive
            ? { [SearchParamsKeys.LastActive]: `${lastActive}` }
            : {}),
          ...(contractor
            ? { [SearchParamsKeys.Contractor]: `${contractor}` }
            : {}),
        },
        [SearchParamsKeys.LastActive, SearchParamsKeys.Contractor]
      )
    },
  })

  const { submitForm, values, setFieldValue } = formik

  const lastActive = values[SearchParamsKeys.LastActive] || ''

  const onReset = (key: string) => () => {
    setFieldValue(key, null)
    submitForm()
  }

  const onActiveDaysChange = ({
    target: { value },
  }: ChangeEvent<HTMLInputElement>) => {
    if (resetTimer?.current) clearTimeout(resetTimer.current)
    const cb = () => {
      resetTimer.current = null
      setFieldValue(SearchParamsKeys.LastActive, value)
      submitForm()
    }
    resetTimer.current = setTimeout(cb, 200)
  }

  return (
    <FormikProvider value={formik}>
      <Form>
        <Grid container alignItems="flex-start" spacing={4}>
          <Grid item>
            <AutocompleteTextField
              label="Organization"
              name={SearchParamsKeys.Contractor}
              labelField="name"
              valueField="id"
              options={contractors}
              onInputChange={findContractorsByName}
              isLoading={contractorsLoading}
              onChange={submitForm}
              initialValue={currentContractor}
            />
          </Grid>
          <Grid item>
            <TextField
              name={SearchParamsKeys.LastActive}
              label="Last active, days"
              onChange={onActiveDaysChange}
              TextFieldProps={{
                InputProps: {
                  inputComponent: NumberTextField as any,
                  endAdornment: (
                    <InputAdornment position="end" sx={{ width: 30 }}>
                      {lastActive.length > 0 && (
                        <IconButton
                          size="small"
                          onClick={onReset(SearchParamsKeys.LastActive)}
                        >
                          <Close fontSize="small" />
                        </IconButton>
                      )}
                    </InputAdornment>
                  ),
                  placeholder: 'Days count',
                }
              }}
            />
          </Grid>
        </Grid>
      </Form>
    </FormikProvider>
  )
}

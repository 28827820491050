import React, { ChangeEvent, FC, useCallback, useState } from 'react'
import {
  Button,
  Grid,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  CircularProgress,
  TextField,
  Typography,
} from '@mui/material'
import { useAddPopupMessage, useDialog } from 'src/hooks/ui'
import { DialogNames } from 'src/enums'
import { StatefulDialog } from 'src/components/ui'
import { WagnerProbe } from 'src/api'

interface DisconnectProbeModalProps {
  onDisconnectProbe: (probeId: string) => void
  isLoading?: boolean
  rapidRhProbe: WagnerProbe
}

const VERIFICATION_WORD = 'delete permanently'

export const DisconnectProbeModal: FC<DisconnectProbeModalProps> = ({ onDisconnectProbe, isLoading = false, rapidRhProbe }) => {
  const [verificationTestCase, setVerificationTestCase] = useState<string>('')
  const dialogName = DialogNames.DisconnectProbe
  const { openDialog, closeDialog } = useDialog(dialogName)
  const addMessage = useAddPopupMessage()

  const handleOpen = () => {
    openDialog()
  }
  const handleClose = () => {
    closeDialog()
  }
  const disconnectProbe = async () => {
    if (!isCorrectVerificationWord) {
      addMessage({ text: 'Please type verification word', type: 'error' })
      return
    }
    onDisconnectProbe(rapidRhProbe.probeId)
  }
  const onChangeVerificationWord = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    setVerificationTestCase(value)
  }
  const isCorrectVerificationWord = useCallback(() => (
    verificationTestCase === VERIFICATION_WORD
  ), [verificationTestCase])()

  return (
    <Grid>
      <Button
        variant="outlined"
        onClick={handleOpen}
        size="large"
        sx={{ width: 210 }}
        color='error'
      >
        Delete Probe
      </Button>
      <StatefulDialog
        name={dialogName}
        onClose={handleClose}
        actions={
          <DialogActions>
            <Button onClick={handleClose} disabled={isLoading}>
              Close
            </Button>
            <Button
              onClick={disconnectProbe}
              color='error'
              disabled={isLoading || !isCorrectVerificationWord}
            >
              {isLoading ? <CircularProgress size={22} /> : "Delete"}
            </Button>
          </DialogActions>
        }
      >
        <DialogTitle id="alert-dialog-title">
          Delete Probe
        </DialogTitle>
        <DialogContent>
          <DialogContentText component='div' sx={{ mb: 2 }}>
            <Typography variant="body1" gutterBottom>You are going to <strong>delete</strong> the rapid RH probe {rapidRhProbe.probeId}:</Typography>
            <Typography variant="body1" gutterBottom><strong>Contractor:</strong> {rapidRhProbe.contractor?.name}</Typography>
            <Typography variant="body1" gutterBottom><strong>Project:</strong> {rapidRhProbe.project?.name}</Typography>
            <Typography variant="body1" gutterBottom><strong>Section:</strong> {rapidRhProbe.projectSection?.name}</Typography>
            <Typography variant="body1" gutterBottom><strong>Label:</strong> {rapidRhProbe.label}</Typography>
            <Typography variant="body1" gutterBottom>This action is <strong>irreversible</strong>, the probe and all related events will be deleted permanently but the readings will remain.</Typography>
            <Typography variant="body1">
              If you really want to delete rapid RH probe please type verification word - <strong>{VERIFICATION_WORD}</strong>
            </Typography>
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="verification-word"
            label="Verification word"
            type="text"
            fullWidth
            placeholder={VERIFICATION_WORD}
            onChange={onChangeVerificationWord}
            value={verificationTestCase}
            variant="standard"
          />
        </DialogContent>
      </StatefulDialog>
    </Grid>
  )
}

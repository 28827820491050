import { FC, useMemo } from 'react'
import { GridColDef } from '@mui/x-data-grid-premium'
import { Document } from "src/api"
import { DocumentList } from 'src/components'
import { useDocumentListRequest } from 'src/hooks/api'
import { useQueryParams } from 'src/hooks/ui'
import Grid from '@mui/material/Grid'
import { DocumentColumns } from '../document/documentColumns'

interface Props {
  contractorId: number;
}

const defaultPerPage = 50
const defaultPage = 1
const initialQueryParams = {
  page: `${defaultPage}`,
  rowsPerPage: `${defaultPerPage}`,
}

export const ContractorDocumentList: FC<Props> = ({
  contractorId,
}) => {
  const [queryParams, setQueryParams] = useQueryParams(initialQueryParams)
  const { isInitialLoading, data } = useDocumentListRequest({
    params: {
      ...queryParams,
      contractorId,
    },
  })
  const exportedFileName = `${document.title}_documents`
  const columns = useMemo((): GridColDef<Document>[] => ([
    DocumentColumns.Name,
    DocumentColumns.Description,
    DocumentColumns.Type,
    DocumentColumns.Links,
    DocumentColumns.Project,
    DocumentColumns.Author,
    DocumentColumns.CreatedAt,
  ]), [])

  return (
    <Grid container flexDirection="column" spacing={2}>
      <Grid item xs={12}>
        <DocumentList
          documentCount={data?.count || 0}
          documents={data?.rows || []}
          documentColumns={columns}
          isLoadingDocuments={isInitialLoading}
          setQueryParams={setQueryParams}
          queryParams={queryParams}
          height='700px'
          exportedFileName={exportedFileName}
        />
      </Grid>
    </Grid>
  )
}

import Box from '@mui/material/Box'
import Link from '@mui/material/Link'
import Typography from '@mui/material/Typography'
import { GridRenderCellParams } from '@mui/x-data-grid-premium'
import { useState } from 'react'

export const ExpandableCell = ({ value }: GridRenderCellParams) => {
  const [expanded, setExpanded] = useState(false)

  return (
    <Box>
      {expanded ? value : value.slice(0, 90)}&nbsp;
      {value.length > 90 && (
        <Link
          type="button"
          component="button"
          sx={{ fontSize: 'inherit' }}
          onClick={() => setExpanded(!expanded)}
        >
          <Typography variant="body2">{expanded ? 'view less' : 'view more'}</Typography>
        </Link>
      )}
    </Box>
  )
}

import { FC, useMemo } from 'react'
import Grid from '@mui/material/Grid'
import { GridColDef } from '@mui/x-data-grid-premium'
import { AlertList } from 'src/components'
import { useAlertListRequest } from 'src/hooks/api/useAlertList'
import { useQueryParams } from 'src/hooks/ui'
import { Alert } from 'src/api'
import { AlertColumns } from '../alert/alertColumns'

interface Props {
  projectId: number;
}

const defaultPerPage = 50
const defaultPage = 1
const initialQueryParams = {
  page: `${defaultPage}`,
  rowsPerPage: `${defaultPerPage}`,
}

export const ProjectAlertList: FC<Props> = ({
  projectId,
}) => {
  const [queryParams, setQueryParams] = useQueryParams(initialQueryParams)
  const { isInitialLoading, data } = useAlertListRequest({
    params: {
      ...queryParams,
      projectId,
    },
  })
  const exportedFileName = `${document.title}_alerts`
  const columns = useMemo((): GridColDef<Alert>[] => ([
    AlertColumns.Name,
    AlertColumns.Status,
    AlertColumns.Type,
    AlertColumns.Contractor,
    AlertColumns.Section,
    AlertColumns.Sensor,
    AlertColumns.RealtedProducts,
    AlertColumns.StartedAt,
    AlertColumns.EndedAt,
    AlertColumns.Duration,
  ]), [])

  return (
    <Grid container flexDirection="column" spacing={2}>
      <Grid item xs={12}>
        <AlertList
          alerts={data?.rows || []}
          alertCount={data?.count || 0}
          isLoadingAlerts={isInitialLoading}
          setQueryParams={setQueryParams}
          queryParams={queryParams}
          height='700px'
          alertColumns={columns}
          exportedFileName={exportedFileName}
        />
      </Grid>
    </Grid>
  )
}

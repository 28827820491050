import React, { FC } from 'react'
import { LinkProps } from '@mui/material'
import { OuterLink } from './outerLink'

interface EmailLinkProps {
  value: string;
}

export const EmailLink: FC<EmailLinkProps & Omit<LinkProps, 'value'>> = ({
  value,
  ...rest
}) => {
  return (
    <OuterLink {...rest} to={`mailto:${value}`} target="_blank">
      {value}
    </OuterLink>
  )
}

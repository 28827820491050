import { useMutation, UseMutationOptions } from '@tanstack/react-query'
import { Entities } from 'src/enums'
import { FillMissedReadingsBulkPayload, FillMissedReadingsResponse } from 'src/api'
import { useApiInstance } from './useApiInstance'

interface RequestParams {
  options?: Omit<UseMutationOptions<FillMissedReadingsResponse, Error, FillMissedReadingsBulkPayload>, 'mutationKey' | 'mutationFn'>
}

export const useFillSensorMissedReadingsBulkRequest = ({
  options,
}: RequestParams) => {
  const api = useApiInstance()

  return useMutation<FillMissedReadingsResponse, Error, FillMissedReadingsBulkPayload>(
    [Entities.SensorMissedReadings],
    async (payload: FillMissedReadingsBulkPayload) => {
      const { data } = await api.v1AdminSensorsFillMissedReadingsPost(payload)
      return data as FillMissedReadingsResponse
    },
    options,
  )
}

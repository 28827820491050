import React, { FC, PropsWithChildren } from "react"
import { useParams } from "react-router-dom"
import { Button, Grid, Typography } from "@mui/material"
import {
  HasSidebarLayout,
  DataLoad,
  EntityHeader,
  ProjectInfo,
  ProjectUsers,
  PropsInjector,
  ProjectSensors,
  TabGroupFilter,
  ProjectProductList,
  OuterLink,
  ProjectEventList,
  ClosedEye,
  ProjectReports,
  ProjectTasks,
  ProjectDocumentList,
  ProjectAlertList,
} from "src/components"
import { SearchParamsKeys } from "src/enums"
import { useProjectRequest, useUserRoleListRequest } from "src/hooks/api"
import { Project, UserRole } from "src/api"
import { config } from "src/config"
import { ProjectWagnerProbeList } from "src/components/sections/project/projectWagnerProbeList"
import { ProjectSections } from "src/components/sections/project/projectSections"

const getTabs = ({
  project,
  updateProject,
  userRoles,
}: {
  project: Project;
  updateProject: () => void;
  userRoles: UserRole[];
}) => [
  {
    status: "Users",
    content: (
      <ProjectUsers
        users={project.users || []}
        project={project}
        updateProject={updateProject}
        userRoles={userRoles}
      />
    ),
  },
  {
    status: "Sections",
    content: <ProjectSections projectId={project.id} />,
  },
  {
    status: "Sensors",
    content: <ProjectSensors projectId={project.id} />,
  },
  {
    status: "Products",
    content: <ProjectProductList projectId={project.id} />,
  },
  {
    status: "Wagner Probes",
    content: <ProjectWagnerProbeList projectId={project.id} />,
  },
  {
    status: "Events",
    content: <ProjectEventList projectId={project.id} />,
  },
  {
    status: "Reports",
    content: <ProjectReports projectId={project.id} />
  },
  {
    status: "Tasks",
    content: <ProjectTasks projectId={project.id} />,
  },
  {
    status: "Documents",
    content: <ProjectDocumentList projectId={project.id} />,
  },
  {
    status: "Alerts",
    content: <ProjectAlertList projectId={project.id} />,
  }
]

interface ProjectShowContentProps {
  project?: Project;
  updateProject?: () => void;
  userRoles?: UserRole[];
}

const ProjectShowContent: FC<ProjectShowContentProps> = ({
  project = null,
  updateProject,
  userRoles = [],
}) => {
  if (!project) return null

  const { name, id, isActive } = project
  document.title = `Floorcloud Admin Panel - Project ${name}`
  const link = `${config.webAppHost}/#/projects/${id}/sections/`

  const getRightIcon = () => {
    if (!isActive) {
      return (
        <Grid container item title="Archived project">
          <ClosedEye />
        </Grid>
      )
    }
    return null
  }

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <EntityHeader
          title={name}
          rightIcon={isActive ? null : getRightIcon()}
          actionBlockOnTheRight={<OuterLink
            to={link}
            style={{ textDecoration: "none" }}
          >
            <Button variant="contained">Open in web app</Button>
          </OuterLink>}
        />
      </Grid>
      <Grid item container spacing={4} direction="column">
        <Grid item container spacing={2} direction="column">
          <Grid item>
            <Typography variant="h6" sx={{ mb: 1 }}>
              Project Information
            </Typography>
          </Grid>
          <Grid item>
            <ProjectInfo project={project} />
          </Grid>
        </Grid>
        <Grid item container spacing={2} direction="column">
          <Grid item width="100%">
            <TabGroupFilter
              name={SearchParamsKeys.Tab}
              paramsToRemove={[
                SearchParamsKeys.Page,
                SearchParamsKeys.RowsPerPage,
              ]}
              statusList={getTabs({
                project,
                updateProject: updateProject as () => void,
                userRoles,
              })}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

const ProjectDataLoader: FC<PropsWithChildren<{ projectId: number }>> = ({
  projectId,
  children,
}) => {
  const { isInitialLoading, isError, data, refetch } = useProjectRequest({
    id: projectId,
  })
  const { data: userRolesResponse } = useUserRoleListRequest({
    params: { limit: 9999 },
  })

  const props = {
    project: data || null,
    updateProject: refetch,
    userRoles: userRolesResponse?.rows || []
  }

  return (
    <DataLoad isLoading={isInitialLoading} isError={isError}>
      <PropsInjector props={props}>{children}</PropsInjector>
    </DataLoad>
  )
}

const ProjectShow: FC = () => {
  const params = useParams()
  const { id } = params as { id: string }

  return (
    <HasSidebarLayout>
      <ProjectDataLoader projectId={parseInt(id, 10)}>
        <ProjectShowContent />
      </ProjectDataLoader>
    </HasSidebarLayout>
  )
}

export default ProjectShow

import { FC, Fragment, useMemo, useState } from 'react'
import Grid from '@mui/material/Grid'
import { ConfigurationParameterTypeEnum } from 'src/api'
import { useConfigurationListRequest } from 'src/hooks/api'
import { DataLoad } from 'src/components/ui'
import { EditableConfigurationParam } from '../settings/configuration/editableConfigurationParam'
import { InlineConfigurationParam } from '../settings/configuration/inlineConfigurationParam'

export const WagnerDeviceFirmwareSettings: FC = () => {
  const [editId, setEditId] = useState<number | null>(null)
  const { data, isInitialLoading, isError, error, refetch } = useConfigurationListRequest({
    params: { limit: 50 }
  })
  const firmwareConfigurationList = useMemo(() => (
    data?.filter(
      (param) => param.type === ConfigurationParameterTypeEnum.WagnerDeviceFirmwareUpdateListeningTimeout || param.type === ConfigurationParameterTypeEnum.WagnerDeviceFirmwareUpdateSignalStrengthThreshold
    ) || []
  ), [data])
  const saveChanges = () => {
    setEditId(null)
    refetch()
  }

  return (
    <DataLoad
      isLoading={isInitialLoading}
      isError={isError}
      errorMessage={error?.message}
    >
      <Grid container direction="column" spacing={2}>
        {firmwareConfigurationList.map(({ id, name, value }, idx, list) => (
          <Fragment key={idx}>
            <Grid item container spacing={1} alignItems="center" sx={{ pb: 2 }}>
              {editId === id ? (
                <EditableConfigurationParam
                  id={id}
                  value={value || ''}
                  configParamName={name}
                  onChange={saveChanges}
                  onCancel={() => setEditId(null)}
                />
              ) : (
                <InlineConfigurationParam
                  id={id}
                  name={name || ''}
                  value={value || ''}
                  onEdit={setEditId}
                />
              )}
            </Grid>
          </Fragment>
        ))}
      </Grid>
    </DataLoad>
  )
}

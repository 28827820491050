import React, { FC, useCallback, useEffect, useState } from "react"
import { Grid, Button, Paper } from "@mui/material"
import { Link } from "react-router-dom"
import {
  HasSidebarLayout,
  DataLoad,
  ListHeader,
  ContractorListFilters,
  SearchForm,
  contractorTableColumns,
  TableToolbar,
} from "src/components"
import {
  DataGridPremium,
  useGridApiRef,
  GridPaginationModel,
  GridPinnedColumns,
  GridColDef,
} from '@mui/x-data-grid-premium'
import { useContractorListRequest } from "src/hooks/api"
import { useQueryParams } from "src/hooks/ui"
import { Contractor } from "src/api"
import { ContractorFilters, SearchParamsKeys } from "src/enums"
import { transformSubscriptionStatus } from "src/hooks/api/helpers"
import { defaultRowsValuePerPage } from "src/config"

interface ContractorListProps {
  contractors: Contractor[]
  contractorCount: number
  isLoadingContractors: boolean
  queryParams: Record<string, string>
  setQueryParams: (params: Record<string, string>, paramsToRemove?: string[]) => void
  autoHeight: boolean
  defaultColumns?: GridColDef<Contractor>[]
}

const defaultPerPage = 50
const defaultPage = 1
const defaultRowsPerPageOptions = defaultRowsValuePerPage
const initialQueryParams = {
  page: `${defaultPage}`,
  rowsPerPage: `${defaultPerPage}`,
}

export const ContractorList: FC<ContractorListProps> = ({
  contractors,
  contractorCount,
  isLoadingContractors,
  setQueryParams,
  queryParams,
  autoHeight,
  defaultColumns
}) => {
  const apiRef = useGridApiRef()
  const columns = defaultColumns || contractorTableColumns()
  const [rowCountState, setRowCountState] = useState(contractorCount)
  const [pinnedColumns, setPinnedColumns] = useState<GridPinnedColumns>({
    left: [],
  })

  useEffect(() => {
    setRowCountState((prevRowCountState) =>
      contractorCount !== undefined ? contractorCount : prevRowCountState
    )
  }, [contractorCount, setRowCountState])

  const handlePinnedColumnsChange = useCallback(
    (updatedPinnedColumns: GridPinnedColumns) => {
      setPinnedColumns(updatedPinnedColumns)
    },
    []
  )
  
  const handleChangePaginationModel = async (pagination: GridPaginationModel): Promise<void> => {
    const newPage = pagination.page + 1
    setQueryParams({
      [SearchParamsKeys.Page]: `${newPage}`,
      [SearchParamsKeys.RowsPerPage]: `${pagination.pageSize}`,
    }, [
      SearchParamsKeys.Page,
      SearchParamsKeys.RowsPerPage,
    ])
  }

  const page = queryParams["page"] ? parseInt(queryParams["page"], 10) - 1 : defaultPage - 1
  const pageSize = queryParams["rowsPerPage"]
    ? parseInt(queryParams["rowsPerPage"], 10)
    : defaultPerPage

  return (
    <Paper sx={{ flexGrow: 1, minHeight: '200px', width: "100%" }}>
      <DataGridPremium<Contractor>
        apiRef={apiRef}
        pagination
        autoHeight={autoHeight}
        pinnedColumns={pinnedColumns}
        disableRowSelectionOnClick
        rows={contractors}
        columns={columns}
        rowCount={rowCountState}
        loading={isLoadingContractors}
        paginationModel={{
          page: page as number,
          pageSize: pageSize as number
        }}
        rowThreshold={2}
        columnThreshold={2}
        rowHeight={65}
        paginationMode="server"
        pageSizeOptions={defaultRowsPerPageOptions}
        onPaginationModelChange={handleChangePaginationModel}
        localeText={{
          columnMenuSortAsc: "Sort A-Z",
          columnMenuSortDesc: "Sort Z-A",
        }}
        slots={{
          toolbar: TableToolbar
        }}
        onPinnedColumnsChange={handlePinnedColumnsChange}
        sx={(theme) => ({
          border: "none",
          "& .MuiDataGrid-columnHeaderTitleContainerContent": {
            paddingLeft: "5px",
            overflow: "visible",
          },
          "& .MuiDataGrid-columnHeaderTitle": {
            whiteSpace: "normal",
            lineHeight: 1,
            fontSize: "13px",
            fontWeight: 400,
            color: "rgba(0, 0, 0, 0.6)",
            overflow: "visible",
          },
        })}
      />
    </Paper>
  )
}

const ContractorListPage: FC = () => {
  document.title = 'Floorcloud Admin Panel - Contractors'
  const [queryParams, setQueryParams] = useQueryParams(initialQueryParams)

  const { isInitialLoading, isError, data, error } = useContractorListRequest({
    params: queryParams,
    paramsTransform: [
      transformSubscriptionStatus()
    ]
  })

  return (
    <HasSidebarLayout>
      <DataLoad
        isLoading={false}
        isError={isError}
        errorMessage={error?.message}
      >
        <Grid container direction="column" alignItems="stretch">
          <ListHeader title="Contractors">
            <Link to="/contractors/new" style={{ textDecoration: "none" }}>
              <Button role="link" variant="contained">
                Add New Contractor
              </Button>
            </Link>
          </ListHeader>
        </Grid>
        <Grid container direction="column" alignItems="stretch" spacing={2} sx={{ my: 2 }}>
          <Grid item>
            <SearchForm />
          </Grid>
          <Grid item>
            <ContractorListFilters
              activeFilters={[
                ContractorFilters.Subscription
              ]}
            />
          </Grid>
        </Grid>
        <ContractorList
          contractors={data?.rows || []}
          contractorCount={data?.count || 0}
          isLoadingContractors={isInitialLoading}
          setQueryParams={setQueryParams}
          queryParams={queryParams}
          autoHeight={false}
        />
      </DataLoad>
    </HasSidebarLayout>
  )
}

export default ContractorListPage

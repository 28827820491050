import { createContext } from 'react'

export type QueryParamsContextState = [
  Record<string, string>,
  (state: Record<string, string>, removeKeys?: string[]) => Promise<void>,
  (keysToRemove?: string[]) => Promise<void>,
  () => Promise<void>
];

export const QueryParamsContext = createContext<QueryParamsContextState>([
  {},
  () => Promise.resolve(),
  () => Promise.resolve(),
  () => Promise.resolve(),
])

import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid-premium"
import Typography from "@mui/material/Typography"
import { Button, List, ListItem, Tooltip } from "@mui/material"
import { SystemMessageHistory, SystemMessageHistoryMessageAudienceEnum } from "src/api"
import { DownloadingFilePayload } from "src/hooks/ui/useDownloadingFile"
import { ExtendedSystemMessageHistory } from "src/pages/MessageHistory"
import { ExpandableCell } from "./expandableCell"
import { InnerLink } from "../ui"

export namespace SystemMessageHistoryColumns {
  export const Author: GridColDef<SystemMessageHistory> = {
    field: "author",
    headerName: "Author",
    minWidth: 170,
    editable: false,
    valueGetter: ({ row }) => row.author?.name || null,
    renderCell: ({ value, row }) => {
      if (typeof row.authorId === "number" && value) {
        return <InnerLink to={`/users/${row.authorId}`}>{value}</InnerLink>
      }
      return "-"
    }
  }

  export const Status: GridColDef<SystemMessageHistory> = {
    field: "status",
    headerName: "Status",
    minWidth: 100,
    editable: false,
    valueFormatter: ({ value }) => value ? value.charAt(0).toUpperCase() + value.slice(1) : null
  }

  export const Title: GridColDef<SystemMessageHistory> = {
    field: "title",
    headerName: "Title",
    minWidth: 150,
    editable: false,
    renderCell: ({ value }) => (
      <Tooltip title={value}>
        <Typography variant="body2" textOverflow="ellipsis" overflow="hidden">{value}</Typography>
      </Tooltip>
    ),
  }

  export const Message: GridColDef<SystemMessageHistory> = {
    field: "message",
    headerName: "Message",
    minWidth: 150,
    editable: false,
    renderCell: (params: GridRenderCellParams) => <ExpandableCell {...params} />,
  }

  export const MessageType: GridColDef<SystemMessageHistory> = {
    field: "messageType",
    headerName: "Message type",
    minWidth: 150,
    editable: false,
    valueFormatter: ({ value }) => value ? value.charAt(0).toUpperCase() + value.slice(1) : null
  }

  export const TransportType: GridColDef<SystemMessageHistory> = {
    field: "transportType",
    headerName: "Transport type",
    minWidth: 150,
    editable: false,
    valueFormatter: ({ value }) => value ? value.charAt(0).toUpperCase() + value.slice(1) : null
  }

  export const MessageAudience: GridColDef<SystemMessageHistory> = {
    field: "messageAudience",
    headerName: "Message audience",
    minWidth: 170,
    editable: false,
    valueFormatter: ({ value }) => {
      if (value === SystemMessageHistoryMessageAudienceEnum.All) {
        return "All users"
      }
      if (value === SystemMessageHistoryMessageAudienceEnum.ContractorUsers) {
        return "Contractor users"
      }
      if (value === SystemMessageHistoryMessageAudienceEnum.CustomSet) {
        return "Custom set"
      }
      if (value === SystemMessageHistoryMessageAudienceEnum.ManufacturerProductUsers) {
        return "Manufacturer product users"
      }
      if (value === SystemMessageHistoryMessageAudienceEnum.UsersBySpecificProduct) {
        return "Users by specific product"
      }
      return null
    }
  }

  export const CustomParams: GridColDef<SystemMessageHistory> = {
    field: "customParams",
    headerName: "Custom params",
    minWidth: 200,
    editable: false,
    valueFormatter: ({ value }) => value ? JSON.stringify(value) : null,
    renderCell: ({ row }) => (
      <List>
        {Object.entries(row.customParams || {}).map(([key, value]) => {
          return (
            <ListItem key={key}>
              <Typography variant="body1" display="block">{key}: {JSON.stringify(value)}</Typography>
            </ListItem>
          )
        })}
      </List>
    )
  }

  export const Recipients = (downloadFile: (payload: DownloadingFilePayload) => void): GridColDef<ExtendedSystemMessageHistory> => ({
    field: "recipientsCount",
    headerName: "Recipients",
    minWidth: 200,
    editable: false,
    valueFormatter: ({ value }) => value ? `Export ${value} users` : '-',
    renderCell: ({ row, value }) => {
      const urlPath: string = row.downloadUsers?.urlPath || ''
      const queryParams: Record<string, string> = row.downloadUsers?.queryParams || {}
      return (
        <Button
          onClick={() => downloadFile({ urlPath, queryParams })}
          variant="contained"
          disabled={!value}
        >
          {`Export ${value} users`}
        </Button>
      )
    }
  })
}
import { FC } from "react"
import { Grid } from "@mui/material"
import {
  HasSidebarLayout,
  DataLoad,
  ListHeader,
  ProductImportList,
} from "src/components"
import { useProductImportListRequest } from "src/hooks/api"
import { ProductImport } from "src/api"
import { useQueryParams } from "src/hooks/ui"

interface ProductImportListProps {
  productImports: ProductImport[];
  productImportCount: number;
  isLoading: boolean;
  queryParams: Record<string, string>
  setQueryParams: (params: Record<string, string>, paramsToRemove?: string[]) => void
}

const defaultPerPage = 50
const defaultPage = 1
const initialQueryParams = {
  page: `${defaultPage}`,
  rowsPerPage: `${defaultPerPage}`,
}

export const ProductImportListView: FC<ProductImportListProps> = ({
  productImports,
  productImportCount,
  setQueryParams,
  queryParams,
  isLoading,
}) => {
  return (
    <ProductImportList
      productImports={productImports}
      productImportCount={productImportCount}
      isLoadingProductImports={isLoading}
      queryParams={queryParams}
      setQueryParams={setQueryParams}
      autoHeight={false}
    />
  )
}

const ProductImports: FC = () => {
  document.title = 'Floorcloud Admin Panel - Product imports'
  const [queryParams, setQueryParams] = useQueryParams(initialQueryParams)
  const { isInitialLoading, isError, error, data: productImports } = useProductImportListRequest({
    params: queryParams
  })

  return (
    <HasSidebarLayout>
      <DataLoad
        isLoading={isInitialLoading}
        isError={isError}
        errorMessage={error?.message}
      >
        <Grid container direction="column" spacing={3}>
          <Grid item>
            <ListHeader title="Import history" />
          </Grid>
        </Grid>
        <ProductImportListView
          productImports={productImports?.rows || []}
          productImportCount={productImports?.count || 0}
          isLoading={isInitialLoading}
          setQueryParams={setQueryParams}
          queryParams={queryParams}
        />
      </DataLoad>
    </HasSidebarLayout>
  )
}

export default ProductImports

import { FC, useState } from 'react'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import { format } from "date-fns"
import { ParsedAdvPacket } from 'src/api'
import { useParseAdvertisementPacketRequest } from 'src/hooks/api'
import { useAddPopupMessage } from 'src/hooks/ui'
import { EntityHeader, HasSidebarLayout } from 'src/components'
import CircularProgress from '@mui/material/CircularProgress'

export const AdvertisementPacketDecoder: FC = () => {
  const [advPacket, setAdvPacket] = useState<string>('')
  const [decodedAdvPacket, setDecodedAdvPacket] = useState<ParsedAdvPacket | null>(null)
  const addMessage = useAddPopupMessage()
  const parseAdvPacketRequest = useParseAdvertisementPacketRequest({
    options: {
      onSuccess: (data) => {
        setDecodedAdvPacket(data as ParsedAdvPacket)
      },
      onError: (error) => {
        addMessage({ text: error.message, type: 'error' })
      },
    }
  })
  const parseAdvPacket = () => {
    if (!advPacket) {
      addMessage({ text: 'Please provide an Advertisement Packet', type: 'error' })
      return
    }
    setDecodedAdvPacket(null)
    parseAdvPacketRequest.mutate({ advPacket })
  }
  const clear = () => {
    setAdvPacket('')
    setDecodedAdvPacket(null)
  }

  return (
    <HasSidebarLayout>
      <Grid container>
        <Grid item>
          <EntityHeader
            title="Advertisement Packet Decoder"
          />
        </Grid>
        <Grid item container alignItems="center" spacing={2} pt={2} pb={2}>
          <Grid item xs={12} lg={6}>
            <TextField
              label="Advertisement Packet"
              variant="outlined"
              value={advPacket}
              onChange={(e) => setAdvPacket(e.target.value)}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} lg={2}>
            <Button
              onClick={parseAdvPacket}
              variant="contained"
              color="primary"
              size="large"
              disabled={!advPacket || parseAdvPacketRequest.isLoading}
              fullWidth
            >
              {
                parseAdvPacketRequest.isLoading ? <CircularProgress color="info" size={26} /> : 'Decode'
              }
            </Button>
          </Grid>
          <Grid item xs={12} lg={2}>
            <Button
              onClick={clear}
              variant="contained"
              color="primary"
              size="large"
              disabled={!advPacket}
              fullWidth
            >
              Clear
            </Button>
          </Grid>
          <Grid item container flexDirection="column">
            {decodedAdvPacket && (
              <>
                <Grid item>
                  <Typography variant="h6" mb={2}>Decoding results</Typography>
                </Grid>
                <Grid item>
                  <Typography variant="body1" fontWeight={500} display="inline">ID: </Typography>
                  <Typography variant="body1" fontWeight={400} display="inline">{decodedAdvPacket.serialNumber}</Typography>
                </Grid>
                <Grid item>
                  <Typography variant="body1" fontWeight={500} display="inline">Reading time: </Typography>
                  <Typography variant="body1" fontWeight={400} display="inline">{format(new Date(decodedAdvPacket.readingTime), 'PPpp')}</Typography>
                </Grid>
                <Grid item>
                  <Typography variant="body1" fontWeight={500} display="inline">Device time: </Typography>
                  <Typography variant="body1" fontWeight={400} display="inline">{format(new Date(decodedAdvPacket.deviceTime), 'PPpp')}</Typography>
                </Grid>
                <Grid item>
                  <Typography variant="body1" fontWeight={500} display="inline">Temperature F: </Typography>
                  <Typography variant="body1" fontWeight={400} display="inline">{decodedAdvPacket.temperatureF}</Typography>
                </Grid>
                <Grid item>
                  <Typography variant="body1" fontWeight={500} display="inline">Temperature C: </Typography>
                  <Typography variant="body1" fontWeight={400} display="inline">{decodedAdvPacket.temperatureC}</Typography>
                </Grid>
                <Grid item>
                  <Typography variant="body1" fontWeight={500} display="inline">Humidity: </Typography>
                  <Typography variant="body1" fontWeight={400} display="inline">{decodedAdvPacket.humidity}</Typography>
                </Grid>
                <Grid item>
                  <Typography variant="body1" fontWeight={500} display="inline">Acclimation time: </Typography>
                  <Typography variant="body1" fontWeight={400} display="inline">
                    {decodedAdvPacket.acclimationTime ? format(new Date(decodedAdvPacket.acclimationTime), 'PPpp') : '-'}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="body1" fontWeight={500} display="inline">Acclimation temperature F: </Typography>
                  <Typography variant="body1" fontWeight={400} display="inline">
                    {decodedAdvPacket.acclimationTemperatureF !== null ? decodedAdvPacket.acclimationTemperatureF : '-'}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="body1" fontWeight={500} display="inline">Acclimation humidity: </Typography>
                  <Typography variant="body1" fontWeight={400} display="inline">
                    {decodedAdvPacket.acclimationHumidity !== null ? decodedAdvPacket.acclimationHumidity : '-'}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="body1" fontWeight={500} display="inline">Ambient temperature F: </Typography>
                  <Typography variant="body1" fontWeight={400} display="inline">
                    {decodedAdvPacket.ambientTemperatureF !== null ? decodedAdvPacket.ambientTemperatureF : '-'}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="body1" fontWeight={500} display="inline">Ambient humidity: </Typography>
                  <Typography variant="body1" fontWeight={400} display="inline">
                    {decodedAdvPacket.ambientHumidity !== null ? decodedAdvPacket.ambientHumidity : '-'}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="body1" fontWeight={500} display="inline">Data grabber battery level: </Typography>
                  <Typography variant="body1" fontWeight={400} display="inline">{decodedAdvPacket.dataGrabberBatteryLevel || '-'}</Typography>
                </Grid>
                <Grid item>
                  <Typography variant="body1" fontWeight={500} display="inline">Repeater battery level: </Typography>
                  <Typography variant="body1" fontWeight={400} display="inline">{decodedAdvPacket.repeaterBatteryLevel || '-'}</Typography>
                </Grid>
                <Grid item>
                  <Typography variant="body1" fontWeight={500} display="inline">Device type: </Typography>
                  <Typography variant="body1" fontWeight={400} display="inline">{decodedAdvPacket.deviceType || '-'}</Typography>
                </Grid>
                <Grid item>
                  <Typography variant="body1" fontWeight={500} display="inline">Firmware: </Typography>
                  <Typography variant="body1" fontWeight={400} display="inline">{decodedAdvPacket.firmwareVersion || '-'}</Typography>
                </Grid>
                <Grid item>
                  <Typography variant="body1" fontWeight={500} display="inline">Is scan response: </Typography>
                  <Typography variant="body1" fontWeight={400} display="inline">{decodedAdvPacket.isScanResponse === true ? 'Yes' : 'No'}</Typography>
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
      </Grid>
    </HasSidebarLayout>
  )
}

export default AdvertisementPacketDecoder

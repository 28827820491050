import React, { FC } from 'react'
import { Typography } from '@mui/material'
import { DataLoad } from 'src/components'
import { useEventTypeListRequest } from 'src/hooks/api'
import { EventTypeList } from './eventTypeList'

export const LoadableEventTypeList: FC = () => {
  const { isInitialLoading, isError, data, error } = useEventTypeListRequest({
    params: { limit: 9999 },
  })

  return (
    <DataLoad
      isLoading={isInitialLoading}
      isError={isError}
      errorMessage={error?.message}
    >
      <Typography color="gray" sx={{ mb: 2, textAlign: 'right' }}>
        Changes of visibility will be applied immediately.
      </Typography>
      <EventTypeList
        data={data?.rows || []}
        count={data?.count || 0}
        showFooter={false}
      />
    </DataLoad>
  )
}

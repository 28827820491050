import React, { FC, PropsWithChildren } from "react"
import { useAuthorizedState, useNewProductRequestNumber } from "./hooks/ui"
import { useNewProductRequestNumberRequest } from "./hooks/api"

export const Bootstrap: FC<PropsWithChildren> = ({ children }) => {
  const authorized = useAuthorizedState()
  const { setNewProductRequestNumber } = useNewProductRequestNumber()
  useNewProductRequestNumberRequest({
    options: {
      enabled: Boolean(authorized),
      onSuccess: setNewProductRequestNumber,
    },
  })
  return (
    <>
      {children}
    </>
  )
}

import React, { FC, PropsWithChildren, useEffect } from "react"
import { PropsInjector, DataLoad } from 'src/components'
import { ThingspaceSensorColumns } from "src/components/columns/sensor"
import { useQueryParams, useQueryParamsState } from "src/hooks/ui"
import {
  SearchParamsKeys,
  SensorFilters,
  ViewTypes,
} from "src/enums"
import { SensorListFilters } from "src/components/forms/sensor"
import { useThingspaceSensorsListRequest } from "src/hooks/api/useThingspaceSensors"
import { ThingspaceSensor } from "src/api"
import { CommonSensorList } from "./commonSensorsList"

const getColumns = () => ([
  ThingspaceSensorColumns.Id,
  ThingspaceSensorColumns.Model,
  ThingspaceSensorColumns.IMEI,
  ThingspaceSensorColumns.AvailableInSystem,
  ThingspaceSensorColumns.Status,
  ThingspaceSensorColumns.BatteryLevel,
  ThingspaceSensorColumns.LastPing,
  ThingspaceSensorColumns.ActivationStatus,
  ThingspaceSensorColumns.SIMOTADate,
  ThingspaceSensorColumns.ServicePlan,
  ThingspaceSensorColumns.CreatedAtInThingspace,
])

interface ThingspaceSensorsListProps {
  data?: ThingspaceSensor[];
  count?: number;
  isLoading?: boolean;
  refetchSensorList?: () => void;
}

const SensorsList: FC<ThingspaceSensorsListProps> = ({
  data = [],
  count = 0,
  isLoading = false,
}) => {
  return <CommonSensorList
    columns={getColumns()}
    count={count}
    data={data}
    isLoading={isLoading}
    filters={<SensorListFilters activeFilters={[SensorFilters.AvailabilityInSystem]} />}
  />
}

const ThingspaceSensorsListLoader: FC<PropsWithChildren> = ({ children }) => {
  const { isAvailableInSystem, ...state } = useQueryParamsState()

  const { data, error, isInitialLoading, isError, refetch } = useThingspaceSensorsListRequest({
    params: {
      ...state,
      ...(!isAvailableInSystem || isAvailableInSystem === 'null'
        ? {}
        : { isAvailableInSystem }
      )
    },
  })

  const props = {
    data: data?.rows || [],
    count: data?.count || 0,
    isLoading: isInitialLoading,
    refetchSensorList: refetch,
  }

  return (
    <DataLoad isLoading={false} isError={isError} errorMessage={error?.message}>
      <PropsInjector props={props}>{children}</PropsInjector>
    </DataLoad>
  )
}

export const ThingspaceSensorsList: FC = () => {
  const [params, setParams] = useQueryParams()
  useEffect(() => {
    setParams({
      ...params,
      [SearchParamsKeys.ViewType]: ViewTypes.List,
    })
  }, [])
  return (
    <ThingspaceSensorsListLoader>
      <SensorsList />
    </ThingspaceSensorsListLoader>
  )
}

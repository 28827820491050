import { useMutation, UseMutationOptions } from '@tanstack/react-query'
import { Entities } from 'src/enums'
import { BuildingType, CreateBuildingTypePayload } from 'src/api'
import { useApiInstance } from './useApiInstance'

interface RequestParams {
  options?: Omit<
    UseMutationOptions<unknown, Error, CreateBuildingTypePayload, BuildingType>,
    'mutationKey' | 'mutationFn'
  >;
}

export const useNewBuildingTypeRequest = ({ options }: RequestParams = {}) => {
  const api = useApiInstance()

  return useMutation<unknown, Error, CreateBuildingTypePayload, BuildingType>(
    [Entities.BuildingTypes, 'new'],
    async (payload: CreateBuildingTypePayload) => {
      const { data } = await api.v1AdminBuildingTypesPost(payload)
      return data
    },
    { ...options },
  )
}

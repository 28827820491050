import React, { FC, useCallback } from 'react'
import {
  Button,
  Grid,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
} from '@mui/material'
import { useRemoveForemanRequest } from 'src/hooks/api'
import { useAddPopupMessage, useDialog } from 'src/hooks/ui'
import { DialogNames } from 'src/enums'
import { StatefulDialog } from 'src/components/ui'
import { Project, User } from 'src/api'

interface RemoveForemanProps {
  user: User;
}
interface EnrichedProject extends Project {
  update?: () => {},
  countOfUsers?: number,
}

export const RemoveForeman: FC<RemoveForemanProps> = ({
  user,
}) => {
  const project: EnrichedProject | null = user?.projects?.length ? user.projects[0] : null
  const dialogName = `${DialogNames.RemoveForemanFromProject}_user_${user.id}`
  const { openDialog, closeDialog } = useDialog(dialogName)
  const addMessage = useAddPopupMessage()
  const request = useRemoveForemanRequest({
    options: {
      onSuccess: () => {
        if (typeof project?.update === 'function') {
          project.update()
          closeDialog()
        } else {
          addMessage({ text: 'Something went wrong', type: 'error' })
        }
      },
      onError: ({ message }: { message: string }) => {
        addMessage({ text: message, type: 'error' })
      },
    },
  })
  const handleOpen = () => {
    openDialog()
  }
  const handleClose = () => {
    closeDialog()
  }
  const removeForeman = async () => {
    request.mutate({
      projectId: project?.id || 0,
      userIds: [user.id],
    })
  }
  const isDisabledButton = useCallback(() => {
    return ((project?.countOfUsers || 2) < 2)
  }, [project])()

  return (
    <Grid>
      <Button
        variant="outlined"
        onClick={handleOpen}
        disabled={isDisabledButton}
      >
        Remove User
      </Button>
      <StatefulDialog
        key={user.id}
        name={dialogName}
        onClose={handleClose}
        actions={
          <DialogActions>
            <Button onClick={handleClose}>
              No
            </Button>
            <Button
              onClick={removeForeman}
            >
              Yes
            </Button>
          </DialogActions>
        }
      >
        <DialogTitle id="alert-dialog-title">
          Confirmation
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Do you realy want to remove the user {user.name} from the project?
          </DialogContentText>
        </DialogContent>
      </StatefulDialog>
    </Grid>
  )
}

import { useMutation, UseMutationOptions } from '@tanstack/react-query'
import { Entities } from 'src/enums'
import { ManufacturerAnnouncementType, UpdateManufacturerAnnouncementTypePayload } from 'src/api'
import { useApiInstance } from './useApiInstance'

interface RequestParams {
  id: number;
  options?: Omit<
    UseMutationOptions<unknown, Error, UpdateManufacturerAnnouncementTypePayload, ManufacturerAnnouncementType>,
    'mutationKey' | 'mutationFn'
  >;
}

export const useEditManufacturerAnnouncementTypeRequest = ({ id, options }: RequestParams) => {
  const api = useApiInstance()

  return useMutation<unknown, Error, UpdateManufacturerAnnouncementTypePayload, ManufacturerAnnouncementType>(
    [Entities.ManufacturerAnnouncementTypes, 'edit', id],
    async (payload: UpdateManufacturerAnnouncementTypePayload) => {
      const { data } = await api.v1AdminManufacturersAnnouncementsTypesTypeIdPut(
        id,
        payload,
      )
      return data
    },
    options,
  )
}

import buildVersion from './buildVersion.json'

export type Stand = 'staging' | 'production' | 'dev'

export interface Config {
  apiPath: string;
  host?: string;
  signInTimeout: number;
  popupMessageTimeout: number;
  mapboxKey: string;
  muiLicenseKey: string;
  webAppHost: string;
  buildVersion: number | string;
  stand: Stand;
}

const signInTimeout = parseInt(
  process.env.REACT_APP_API_SIGN_IN_TIMEOUT as string,
  10,
)

const popupMessageTimeout = parseInt(
  process.env.REACT_APP_POPUP_MESSAGE_TIMEOUT as string,
  10,
)

export const config: Readonly<Config> = {
  apiPath: process.env.REACT_APP_API_PATH || 'api',
  host: process.env.REACT_APP_API_HOST || 'http://localhost:8080',
  signInTimeout: (Number.isNaN(signInTimeout) ? 30 : signInTimeout) * 1000,
  popupMessageTimeout:
    (Number.isNaN(popupMessageTimeout) ? 10 : popupMessageTimeout) * 1000,
  mapboxKey: process.env.REACT_APP_MAPBOX_KEY || '',
  muiLicenseKey: process.env.REACT_APP_MUI_LICENSE_KEY || '',
  buildVersion: buildVersion.version,
  webAppHost: process.env.REACT_APP_WEB_APP_HOST || '',
  stand: process.env.REACT_APP_STAND as Stand
}

export const ValuesToResize = {
  manufacturer: 160,
  contractor: 1000,
}

export const defaultRowsValuePerPage = [25, 50, 200, 500, 1000, 1500]

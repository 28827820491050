import React, { FC } from 'react'
import * as Yup from 'yup'
import { Button, Grid, Box } from '@mui/material'
import { Formik, Form } from 'formik'
import { TextField } from 'src/components'

const validationSchema = Yup.object().shape({
  email: Yup.string().required('Email is required').email('Email is invalid'),
  password: Yup.string()
    .required('Password is required')
    .min(6, 'Password must be at least 6 characters')
    .max(40, 'Password must not exceed 40 characters'),
})

export interface EmailAndPasswordFormState {
  email: string;
  password: string;
}

const initialValues: EmailAndPasswordFormState = {
  email: '',
  password: '',
}

interface EmailAndPasswordFormProps {
  onSubmit: (formState: EmailAndPasswordFormState) => void;
}

export const EmailAndPasswordForm: FC<EmailAndPasswordFormProps> = ({
  onSubmit,
}) => {
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(data) => {
        onSubmit(data)
      }}
      validationSchema={validationSchema}
    >
      <Form>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <TextField
              name="email"
              required
              TextFieldProps={{
                placeholder: 'Email',
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              name="password"
              required
              TextFieldProps={{
                type: 'password',
                placeholder: 'Password',
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Box pt={2}>
              <Button
                size="large"
                variant="contained"
                color="primary"
                fullWidth
                type="submit"
              >
                Sign In
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Form>
    </Formik>
  )
}

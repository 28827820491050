import React, { FC, useState } from 'react'
import {
  Button,
  Grid,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  InputLabel,
  Select,
  OutlinedInput,
  Box,
  Chip,
  MenuItem,
  SelectChangeEvent,
} from '@mui/material'
import { useAssignForemanRequest, useForemanListRequest } from 'src/hooks/api'
import { useAddPopupMessage, useDialog } from 'src/hooks/ui'
import { DialogNames } from 'src/enums'
import { StatefulDialog } from 'src/components/ui'

interface AssignForemanProps {
  contractorId: number;
  projectId: number;
  updateProject: () => void;
}

export const AssignForeman: FC<AssignForemanProps> = ({
  contractorId,
  projectId,
  updateProject,
}) => {
  const [selectedForemans, setSelectedForemans] = useState<string[]>([])
  const { openDialog, closeDialog } = useDialog(DialogNames.AssignForemanToProject)
  const addMessage = useAddPopupMessage()
  const { data, refetch } = useForemanListRequest({
    params: {
      contractorId,
      limit: 100,
    },
    options: {
      refetchOnMount: true,
      refetchOnWindowFocus: false,
    }
  })
  const foremans = data?.rows || []
  const request = useAssignForemanRequest({
    options: {
      onSuccess: () => {
        updateProject()
        closeDialog()
      },
      onError: ({ message }: { message: string }) => {
        addMessage({ text: message, type: 'error' })
      },
    },
  })
  const handleOpen = () => {
    refetch()
    openDialog()
  }
  const handleClose = () => {
    closeDialog()
  }
  const handleChange = (event: SelectChangeEvent<typeof selectedForemans>) => {
    const {
      target: { value },
    } = event
    setSelectedForemans(
      typeof value === 'string' ? value.split(',') : value,
    )
  }
  const assignForemans = async () => {
    if (selectedForemans.length === 0) return
    request.mutate({
      projectId,
      userIds: selectedForemans.map((id) => parseInt(id, 10))
    })
  }

  return (
    <Grid>
      <Button variant="contained" onClick={handleOpen}>
        Assign user
      </Button>
      <StatefulDialog
        name={DialogNames.AssignForemanToProject}
        onClose={handleClose}
        actions={
          <DialogActions>
            <Button onClick={handleClose}>
              Close
            </Button>
            <Button
              onClick={assignForemans}
              disabled={selectedForemans.length === 0}
            >
              Assign
            </Button>
          </DialogActions>
        }
      >
        <DialogTitle id="alert-dialog-title">
          Choose users
        </DialogTitle>
        <DialogContent>
          <FormControl sx={{ m: 1, width: 400 }}>
            <InputLabel id="demo-multiple-chip-label">Users</InputLabel>
            <Select
              labelId="demo-multiple-chip-label"
              id="demo-multiple-chip"
              multiple
              value={selectedForemans}
              onChange={handleChange}
              MenuProps={{
                autoFocus: false,
                PaperProps: {
                  sx: {
                    overflowX: "auto",
                    overflowY: "auto",
                    width: "280px",
                    height: "280px"
                  }
                }
              }}
              input={<OutlinedInput id="select-multiple-chip" label="User" />}
              renderValue={(selected) => (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                  {selected.map((value) => {
                    const foreman = foremans.find((_foreman) => _foreman.id === parseInt(value, 10))
                    return <Chip key={foreman?.id} label={foreman?.name} />
                  })}
                </Box>
              )}
            >
              {
                foremans.length > 0
                  ? foremans.map((foreman) => (
                    <MenuItem
                      key={foreman.id}
                      value={foreman.id}
                    >
                      {foreman.name}, {foreman.email}
                    </MenuItem>
                  ))
                  : <MenuItem disabled value="">
                    <em>No users found</em>
                  </MenuItem>
              }
            </Select>
          </FormControl>
        </DialogContent>
      </StatefulDialog>
    </Grid>
  )
}

import React, { FC } from 'react'
import { DialogActions, Button, Box, Typography } from '@mui/material'
import { StatefulDialog } from 'src/components/ui'
import { DialogNames } from 'src/enums'

interface Props {
  dialogName: DialogNames;
  name: string;
  onDelete: () => void;
  onCancel: () => void;
}

export const RemoveTypeDialog: FC<Props> = ({
  dialogName,
  name,
  onDelete,
  onCancel,
}) => {
  return (
    <StatefulDialog
      name={dialogName}
      onClose={onCancel}
      actions={
        <DialogActions>
          <Button variant="outlined" onClick={onCancel}>
            Cancel
          </Button>
          <Button variant="contained" color="error" onClick={onDelete}>
            Delete
          </Button>
        </DialogActions>
      }
    >
      <Box minWidth="300px">
        <Typography variant="h6">Delete {name} entity?</Typography>
      </Box>
    </StatefulDialog>
  )
}
import { useMutation, UseMutationOptions } from '@tanstack/react-query'
import { Entities } from 'src/enums'
import { Product, SensorAssignToContractorPayload } from 'src/api'
import { useApiInstance } from './useApiInstance'

interface RequestParams {
  options?: Omit<
    UseMutationOptions<
      unknown,
      Error,
      { uniqueId: string; payload: SensorAssignToContractorPayload },
      Product
    >,
    'mutationKey' | 'mutationFn'
  >;
}

export const useAssignSensorRequest = ({ options }: RequestParams = {}) => {
  const api = useApiInstance()

  return useMutation<
    unknown,
    Error,
    { uniqueId: string; payload: SensorAssignToContractorPayload },
    Product
  >(
    [Entities.Sensors, 'assign'],
    async ({ uniqueId, payload }) => {
      try {
        const { data } = await api.v1AdminSensorsUniqueIdAssignToContractorPost(uniqueId, payload)
        return data
      } catch (e) {
        return e
      }
    },
    {
      ...options,
    },
  )
}

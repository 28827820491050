import { useMutation, UseMutationOptions } from '@tanstack/react-query'
import { Entities } from 'src/enums'
import { Release, ReleaseCreation } from 'src/api'
import { useApiInstance } from './useApiInstance'

interface RequestParams {
  options?: Omit<UseMutationOptions<unknown, Error, ReleaseCreation, Release>, 'mutationKey' | 'mutationFn'>
}

export const useNewReleaseRequest = ({
  options = {},
}: RequestParams = {}) => {
  const api = useApiInstance()

  return useMutation<unknown, Error, ReleaseCreation, Release>(
    [Entities.Release, 'create'],
    async (payload: ReleaseCreation) => {
      const { data } = await api.v1AdminReleasesPost(payload)
      return data
    },
    { ...options },
  )
}

import { useMutation, UseMutationOptions } from '@tanstack/react-query'
import { UnarchiveResponse } from 'src/api'
import { useApiInstance } from './useApiInstance'

interface UnarchiveUserParams {
  userId: number;
}

interface UnarchiveUserRequestParams {
  options?: Omit<
    UseMutationOptions<UnarchiveResponse, Error | null, UnarchiveUserParams, UnarchiveResponse>,
    'mutationKey' | 'mutationFn'
  >;
}

export const useUnarchiveUserRequest = ({
  options,
}: UnarchiveUserRequestParams = {}) => {
  const api = useApiInstance()
  return useMutation(
    async ({ userId }: UnarchiveUserParams) => {
      const { data } = await api.v1AdminUsersUserIdUnarchivePost(userId)
      return data as UnarchiveResponse
    },
    { ...options },
  )
}

import { useMutation, UseMutationOptions } from '@tanstack/react-query'
import { Entities } from 'src/enums'
import { SensorConfiguration, DefaultResponse } from 'src/api'
import { useApiInstance } from './useApiInstance'

interface RequestParams {
  uniqueId: string;
  options?: Omit<
    UseMutationOptions<unknown, Error, SensorConfiguration, DefaultResponse>,
    'mutationKey' | 'mutationFn'
  >;
}

export const useEditSensorConfigurationRequest = ({ uniqueId, options }: RequestParams) => {
  const api = useApiInstance()

  return useMutation<unknown, Error, SensorConfiguration, DefaultResponse>(
    [Entities.SensorConfiguration, 'edit', uniqueId],
    async (payload: SensorConfiguration) => {
      const { data } = await api.v1AdminSensorsUniqueIdConfigurationPost(
        uniqueId,
        payload,
      )
      return data
    },
    {
      ...options,
    },
  )
}

import { FC } from 'react'
import { Grid, Typography } from '@mui/material'
import { Table, DynamicColumn } from 'src/components/ui'
import { ManufacturerAnnouncementDashboardStatistics } from 'src/api'
import { ManufacturerAnnouncementDashboardColumns } from 'src/components/columns/dashboard'

interface Props {
  manufacturerAnnouncementStatistics: ManufacturerAnnouncementDashboardStatistics
}

const columns: DynamicColumn<ManufacturerAnnouncementDashboardStatistics>[] = [
  ManufacturerAnnouncementDashboardColumns.Posts,
  ManufacturerAnnouncementDashboardColumns.UniqueManufacturers
]

export const ManufacturerAnnouncementDashboard: FC<Props> = (props) => {
  const { manufacturerAnnouncementStatistics } = props
  return (
    <Grid container spacing={2}>
      <Grid item>
        <Typography variant="h5">GangBox</Typography>
      </Grid>
      <Grid item container>
        <Table
          columns={columns}
          data={[manufacturerAnnouncementStatistics]}
          showFooter={false}
          sx={{ width: '100%' }}
        />
      </Grid>
    </Grid>

  )
}

import { useMutation, UseMutationOptions } from '@tanstack/react-query'
import { Entities } from 'src/enums'
import { CreateFirmwarePayload, WagnerDeviceFirmware } from 'src/api'
import { useApiInstance } from './useApiInstance'

interface RequestParams {
  options?: Omit<
    UseMutationOptions<WagnerDeviceFirmware, Error, CreateFirmwarePayload>,
    'mutationKey' | 'mutationFn'
  >;
}

export const useNewWagnerDeviceFirmwareRequest = ({ options }: RequestParams = {}) => {
  const api = useApiInstance()

  return useMutation<WagnerDeviceFirmware, Error, CreateFirmwarePayload>(
    [Entities.WagnerDeviceFirmware, 'new'],
    async (payload: CreateFirmwarePayload) => {
      const { data } = await api.v1AdminWagnerProbesFirmwaresPost(payload)
      return data as WagnerDeviceFirmware
    },
    { ...options },
  )
}

import React, { FC } from 'react'
import { Grid, Button, List, ListItem, ListItemText } from '@mui/material'

interface StagingDevicesListProps {
  stagingDevices: string[];
  onEdit: () => void;
}

export const StagingDevicesList: FC<StagingDevicesListProps> = (props) => {
  const { stagingDevices, onEdit } = props

  return (
    <Grid container item spacing={2}>
      <Grid item flexGrow={1}>
        <List dense>
          {(stagingDevices || []).map((imei) => (
            <ListItem key={imei}>
              <ListItemText>{imei}</ListItemText>
            </ListItem>
          ))}
        </List>
      </Grid>
      <Grid item>
        <Button
          variant="contained"
          onClick={onEdit}
        >
          Edit
        </Button>
      </Grid>
    </Grid>
  )
}

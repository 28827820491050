import { useMutation, UseMutationOptions } from '@tanstack/react-query'
import { Entities } from 'src/enums'
import { UploadPhotoResult } from 'src/api'
import axios, { AxiosResponseTransformer } from 'axios'
import { useApiInstance } from './useApiInstance'
import { useSignInRedirect } from '../ui'

interface RequestParams {
  options?: Omit<UseMutationOptions<unknown, Error, RequestUpload, Array<UploadPhotoResult>>, 'mutationKey' | 'mutationFn'>
}

interface RequestUpload {
  photos?: File[]
}

export const useUploadPhotoRequest = ({
  options = {},
}: RequestParams = {}) => {
  const api = useApiInstance()
  const redirect = useSignInRedirect()

  return useMutation<unknown, Error, RequestUpload, Array<UploadPhotoResult>>(
    [Entities.Photo, 'upload'],
    async (payload: RequestUpload) => {
      const { photos } = payload
      let photoResult: Array<UploadPhotoResult> = []
      if (photos && photos.length > 0) {
        await api.v1MediaPhotoPut(photos, undefined, {
          transformResponse: [
            ...(axios.defaults.transformResponse as AxiosResponseTransformer[]),
            ({ error, result, statusCode }) => {
              if (error) {
                if (statusCode === 401) redirect()
                throw new Error(error)
              }
              photoResult = result
            },
          ],
        })
      }
      return photoResult
    },
    {
      ...options,
    },
  )
}

import React, { FC, useCallback, useState } from 'react'
import {
  Button,
  Grid,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  CircularProgress,
  TextField,
} from '@mui/material'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { useAddPopupMessage, useDialog } from 'src/hooks/ui'
import { DialogNames } from 'src/enums'
import { StatefulDialog } from 'src/components/ui'

interface SensorHistoricalDataModalProps {
  onFillMissedUplinks: (date: string) => void
  isLoading?: boolean
}

export const SensorHistoricalDataModal: FC<SensorHistoricalDataModalProps> = ({ onFillMissedUplinks, isLoading = false }) => {
  const [date, setDate] = useState<Date | null>(new Date())
  const dialogName = DialogNames.GetSensorHistoricalData
  const { openDialog, closeDialog } = useDialog(dialogName)
  const addMessage = useAddPopupMessage()
  const handleOpen = () => {
    openDialog()
  }
  const handleClose = () => {
    closeDialog()
  }
  const fillMissedReadings = async () => {
    if (!date) {
      addMessage({ text: 'Please select a date', type: 'warning' })
      return
    }
    onFillMissedUplinks(date.toISOString())
  }
  const onChangeDate = (newDate: Date | null) => {
    const preparedDate = newDate
      ? new Date(new Date(newDate).setHours(0, 0, 0))
      : newDate
    setDate(preparedDate)
  }
  const isFutureDate = useCallback(() => {
    return date !== null && date.valueOf() > Date.now()
  }, [date])

  return (
    <Grid>
      <Button
        variant="outlined"
        onClick={handleOpen}
        size="large"
        sx={{ width: 210 }}
      >
        Get Historical Data
      </Button>
      <StatefulDialog
        name={dialogName}
        onClose={handleClose}
        actions={
          <DialogActions>
            <Button onClick={handleClose} disabled={isLoading}>
              Close
            </Button>
            <Button
              onClick={fillMissedReadings}
              disabled={isLoading || !date || isFutureDate()}
            >
              {isLoading ? <CircularProgress size={22} /> : "Get data"}
            </Button>
          </DialogActions>
        }
      >
        <DialogTitle id="alert-dialog-title">
          Get historical data
        </DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ mb: 2 }}>
            Please select a date
          </DialogContentText>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              value={date}
              onChange={onChangeDate}
              renderInput={(props: any) => <TextField {...props} />}
              disableFuture
            />
          </LocalizationProvider>
        </DialogContent>
      </StatefulDialog>
    </Grid>
  )
}

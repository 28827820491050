import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { Entities } from 'src/enums'
import { WagnerProbe } from 'src/api'
import { useApiInstance } from './useApiInstance'

interface RequestParams {
  probeId: string;
  options?: Omit<
    UseQueryOptions<unknown, Error, WagnerProbe>,
    'queryKey' | 'queryFn'
  >;
}

export const useWagnerProbeSingleRequest = ({ probeId, options }: RequestParams) => {
  const api = useApiInstance()

  return useQuery<unknown, Error, WagnerProbe>(
    [Entities.WagnerProbes, probeId],
    async () => {
      const { data } = await api.v1AdminWagnerProbesProbeIdGet(probeId)
      return data
    },
    { ...options },
  )
}

import { useContext } from 'react'
import { NewProductRequestNumberContext } from 'src/contexts'

export const useNewProductRequestNumber = () => {
  const {
    newProductRequestNumber,
    setNewProductRequestNumber,
  } = useContext(NewProductRequestNumberContext)
  return {
    newProductRequestNumber,
    setNewProductRequestNumber,
  }
}

import { FC } from 'react'
import { Report } from 'src/api'
import { useQueryParams } from 'src/hooks/ui'
import { useReportListRequest } from 'src/hooks/api'
import { Box } from '@mui/material'
import { defaultRowsValuePerPage } from 'src/config'
import { ReportColumnsDataGrid } from '../reports/tableColumns'
import { ReportList } from '../reports'

type Props = {
  reports: Report[]
  count: number
  queryParams: Record<string, string>
  setQueryParams: (params: Record<string, string>, paramsToRemove?: string[]) => void
}

const defaultPerPage = 25
const defaultPage = 1
const defaultRowsPerPageOptions = defaultRowsValuePerPage
const initialQueryParams = {
  page: `${defaultPage}`,
  rowsPerPage: `${defaultPerPage}`,
}
const columns = [
  ReportColumnsDataGrid.Name,
  ReportColumnsDataGrid.Type,
  ReportColumnsDataGrid.ReportSection,
  ReportColumnsDataGrid.DateFrom,
  ReportColumnsDataGrid.DateTo,
  ReportColumnsDataGrid.Duration,
  ReportColumnsDataGrid.DateOfGeneration,
  ReportColumnsDataGrid.TimeOfGeneration,
  ReportColumnsDataGrid.DayOfWeek,
  ReportColumnsDataGrid.Author,
  ReportColumnsDataGrid.ProjectArchivationDate,
  ReportColumnsDataGrid.AvailabilitySummary,
  ReportColumnsDataGrid.NameHadChanged,
  ReportColumnsDataGrid.EventsIncluded,
  ReportColumnsDataGrid.EventsAmount,
  ReportColumnsDataGrid.EventsWithPhoto,
  ReportColumnsDataGrid.EventsWithNotes,
]

const ProjectReportsContent: FC<Props> = ({
  reports,
  count,
  queryParams,
  setQueryParams
}) => {
  return (
    <Box height="500px">
      <ReportList
        data={reports}
        columns={columns}
        count={count}
        defaultRowsPerPageOptions={defaultRowsPerPageOptions}
        queryParams={queryParams}
        setQueryParams={setQueryParams}
      />
    </Box>
  )
}

export const ProjectReports: FC<{ projectId: number }> = ({
  projectId,
}) => {
  const [queryParams, setQueryParams] = useQueryParams(initialQueryParams)
  const projectReportsRequest = useReportListRequest({
    params: { ...queryParams, projectId },
  })

  return (
    <ProjectReportsContent
      reports={projectReportsRequest.data?.rows || []}
      count={projectReportsRequest.data?.count || 0}
      queryParams={queryParams}
      setQueryParams={setQueryParams}
    />
  )
}

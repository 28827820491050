import { createTheme } from '@mui/material/styles'

const theme = createTheme({
  palette: {
    primary: {
      main: '#037BEB',
    },
    error: {
      main: '#F04848',
    },
    info: {
      main: '#ffffff',
    },
  },
  typography: {
    htmlFontSize: 16,
    fontFamily: '"Poppins", "Roboto", "Helvetica", "Arial", sans-serif',
    h4: {
      fontSize: '1.75rem',
      lineHeight: '1.875rem',
    },
    h6: {
      fontSize: '1.25rem',
      lineHeight: '1.875rem',
      fontWeight: 'normal',
    },
    body1: {
      lineHeight: '1.875rem',
    },
  },
  components: {
    MuiLink: {
      styleOverrides: {
        root: {
          textDecoration: 'none',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'capitalize',
          borderRadius: '22px',
        },
      },
      variants: [
        {
          props: { size: 'large' },
          style: {
            paddingLeft: '32px',
            paddingRight: '32px',
          },
        },
      ],
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          padding: 0,
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          fontSize: '1rem',
          lineHeight: '1.875rem',
          verticalAlign: 'top',
        },
        head: {
          fontWeight: 'normal',
          opacity: 0.6,
          verticalAlign: 'bottom',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          backgroundColor: '#fff',
        },
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          backgroundColor: '#fff',
          ':hover': {
            backgroundColor: '#ebf5fe',
          },
          '&.Mui-selected': {
            backgroundColor: '#ebf5fe',
            ':hover': {
              backgroundColor: '#ebf5fe',
            },
          },
        },
      },
    },
  },
})

export default theme

import { GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid-premium'
import { FC, PropsWithChildren } from 'react'

type Props = PropsWithChildren & {
  exportedFileName?: string
}

export const TableToolbar: FC<Props> = ({ children, exportedFileName }) => {
  const fileName = exportedFileName || document.title
  return (
    <GridToolbarContainer>
      <GridToolbarExport
        csvOptions={{ fileName }}
        excelOptions={{ fileName }}
      />
      {children}
    </GridToolbarContainer>
  )
}

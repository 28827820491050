import React, { FC } from 'react'
import { DataLoad } from 'src/components'
import { useProductListRequest } from 'src/hooks/api'
import { useQueryParamsState } from 'src/hooks/ui'
import { ProductList } from '../product'

interface ProjectProductListProps {
  projectId: number;
}

export const ProjectProductList: FC<ProjectProductListProps> = ({
  projectId,
}) => {
  const params = useQueryParamsState()
  const { isInitialLoading, isError, error, data } = useProductListRequest({
    params: {
      projectId,
      ...params,
    },
  })
  return (
    <DataLoad isLoading={false} isError={isError} errorMessage={error?.message}>
      <ProductList
        loading={isInitialLoading}
        count={data?.count || 0}
        data={data?.rows || []}
        noResultText="No product belongs to the selected project"
      />
    </DataLoad>
  )
}

import React, { FC, useState, useEffect } from 'react'
import { useLocation } from "react-router-dom"
import { Grid, Typography, Box } from '@mui/material'
import {
  HasSidebarLayout,
  ListHeader,
  UploadXlsxButton,
  Table,
  DynamicColumn,
} from 'src/components'
import { UploadXlsxProduct, XlsxSheetProblem } from 'src/api'

type LocationState = { uploadResult: UploadXlsxProduct[] };

interface Statistic extends Omit<UploadXlsxProduct, 'sheetProblems'> {
  issues: number;
}

interface ManufacturerIssue {
  name: string;
  issues: XlsxSheetProblem[];
}

const buildKey = (idx: number) => (Math.random() * idx).toString(16).slice(2)

const columns: DynamicColumn<Statistic>[] = [
  { field: 'name', headerName: 'Name', type: 'string' },
  {
    field: 'processedProducts',
    headerName: 'Processed Products',
    type: 'digit',
  },
  { field: 'updatedProducts', headerName: 'Updated Products', type: 'digit' },
  { field: 'createdProducts', headerName: 'Created Products', type: 'digit' },
  { field: 'issues', headerName: 'Issues', type: 'digit' },
]

interface IssuesProps {
  issues: XlsxSheetProblem[];
}

const Issues: FC<IssuesProps> = ({ issues }) => {
  return (
    <>
      {issues.map(({ row, reasons }, idx) => {
        return (
          <Box mb={2} key={buildKey(idx)}>
            <Typography variant="body1" component="p" mb={-1}>
              {row !== void 0
                ? `Row ${row}: Product hasn’t been saved`
                : 'Product list hasn’t been saved'}
            </Typography>
            {reasons && (
              <>
                <Typography variant="body1" component="p">
                  Reasons:
                </Typography>
                {reasons?.map((reason, reasonIdx) => (
                  <Typography key={buildKey(reasonIdx)} variant="body2" component="p">
                    {reason}
                  </Typography>
                ))}
              </>
            )}
          </Box>
        )
      })}
    </>
  )
}

interface IssueDetailsProps {
  manufacturerIssues: ManufacturerIssue[];
}

const IssueDetails: FC<IssueDetailsProps> = ({ manufacturerIssues }) => {
  return (
    <Box my={1}>
      {manufacturerIssues.map(({ name, issues }, idx) => {
        return (
          <Grid key={buildKey(idx)} container direction="column">
            <Grid item>
              <Typography variant="h5" sx={{ mt: 2 }}>
                {name}
              </Typography>
            </Grid>
            <Grid item>
              <Issues issues={issues} />
            </Grid>
          </Grid>
        )
      })}
    </Box>
  )
}

const Products: FC = () => {
  document.title = 'Floorcloud Admin Panel - Upload products'
  const [statistic, setStatistic] = useState<Statistic[] | null>(null)
  const [issues, setIssues] = useState<ManufacturerIssue[]>([])
  const location = useLocation()

  useEffect(() => {
    if ((location?.state as LocationState)?.uploadResult) {
      const { uploadResult } = location.state as LocationState
      const statisticList = uploadResult.map(({ sheetProblems, ...rest }) => ({
        issues: sheetProblems.length,
        ...rest,
      }))
      const issuesList = uploadResult
        .map(({ sheetProblems, name }) => ({
          name,
          issues: sheetProblems,
        }))
        .filter((el) => el.issues.length > 0)
      setStatistic(statisticList)
      setIssues(issuesList)
    }
  }, [location.state])

  return (
    <HasSidebarLayout>
      <Grid container direction="column" alignItems="stretch">
        <ListHeader
          title={
            statistic === null ? 'Products Upload' : 'Products Upload Result'
          }
        >
          <Grid container alignItems="center" spacing={2}>
            <Grid item>
              <UploadXlsxButton />
            </Grid>
          </Grid>
        </ListHeader>
      </Grid>
      <Grid item container sx={{ my: 2 }}>
        {statistic === null ? (
          <Typography>
            Please, upload .xlsx or .xls documents to update products
          </Typography>
        ) : (
          <>
            <Table
              columns={columns}
              data={statistic}
              count={statistic.length}
              showFooter={false}
            />
            <IssueDetails manufacturerIssues={issues} />
          </>
        )}
      </Grid>
    </HasSidebarLayout>
  )
}

export default Products

import React, { FC, PropsWithChildren } from 'react'
import { TableCell } from '@mui/material'

export interface DigitCellProps {
  value: number;
  first: boolean;
  Link?: FC<PropsWithChildren>;
  empty?: string;
}

export const DigitCell: FC<DigitCellProps> = ({
  value,
  first,
  Link,
  empty = '-',
}) => {
  const align = first ? 'left' : 'right'
  return (
    <TableCell scope="row" align={align}>
      {value === null || value === void 0 ? (
        empty
      ) : Link ? (
        <Link>{value}</Link>
      ) : (
        value
      )}
    </TableCell>
  )
}

import React, { FC, forwardRef } from "react"
import NumberFormat from "react-number-format"

interface NumberFormatCustomProps {
  name: string;
  inputRef?: (instance: NumberFormat | null) => void;
  onChange: (event: {
    target: { value: number | undefined; name: string };
  }) => void;
}

export const NumberTextField: FC<NumberFormatCustomProps> = forwardRef(
  (props, ref) => {
    const { onChange, ...other } = props
    return (
      <NumberFormat
        {...other}
        getInputRef={ref}
        onValueChange={(values) => {
          onChange({
            target: {
              value: values.value
                ? parseInt(values.value, 10) || undefined
                : undefined,
              name: other.name,
            },
          })
        }}
        isNumericString
      />
    )
  }
)

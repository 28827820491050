import React, { FC } from 'react'
import {
  Typography,
  Box,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
} from '@mui/material'
import { Circle } from '@mui/icons-material'
import { ProductTip } from 'src/api'

interface ProductTipsProps {
  title: string;
  tips: ProductTip[];
}

export const ProductTips: FC<ProductTipsProps> = ({ title, tips }) => {
  return (
    <Box>
      <Typography variant="h6">{title}</Typography>
      <List>
        {tips.map(({ content }, idx) => (
          <ListItem key={idx} sx={{ alignItems: 'baseline' }}>
            <ListItemIcon sx={{ minWidth: 6, width: 6, height: 10, pr: '0.875rem' }}>
              <Circle sx={{ width: 'inherit', height: 'inherit', color: 'grey.400' }} />
            </ListItemIcon>
            <ListItemText primary={content} />
          </ListItem>
        ))}
      </List>
    </Box>
  )
}

import React from 'react'

export const LogoHorizontalWhite = () => (
  <svg width="194" height="30" viewBox="0 0 1406 305" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1369.25 105.638V107.252C1369.25 107.82 1369.52 108.105 1370.06 108.105H1374.44V119.102C1374.44 119.317 1374.51 119.517 1374.67 119.701C1374.84 119.87 1375.04 119.955 1375.29 119.955H1378.36C1378.59 119.955 1378.79 119.87 1378.95 119.701C1379.12 119.517 1379.21 119.317 1379.21 119.102V108.105H1383.61C1384.17 108.105 1384.44 107.82 1384.44 107.252V105.638C1384.44 105.069 1384.17 104.785 1383.61 104.785H1370.06C1369.52 104.785 1369.25 105.069 1369.25 105.638Z" fill="white"/>
    <path d="M1385.59 105.638V119.102C1385.59 119.317 1385.67 119.517 1385.83 119.701C1385.98 119.87 1386.18 119.955 1386.42 119.955H1389.4C1389.63 119.955 1389.83 119.87 1390 119.701C1390.17 119.517 1390.25 119.317 1390.25 119.102V110.456L1393.76 118.963C1394.09 119.624 1394.53 119.955 1395.07 119.955H1396.41C1396.93 119.955 1397.37 119.624 1397.72 118.963L1401.2 110.456V119.102C1401.2 119.317 1401.29 119.517 1401.46 119.701C1401.64 119.87 1401.84 119.955 1402.06 119.955H1405.03C1405.29 119.955 1405.5 119.87 1405.65 119.701C1405.81 119.517 1405.88 119.317 1405.88 119.102V105.638C1405.88 105.069 1405.6 104.785 1405.03 104.785H1400.33C1399.79 104.785 1399.37 105.115 1399.08 105.776L1395.74 114.053L1392.4 105.776C1392.1 105.115 1391.69 104.785 1391.15 104.785H1386.42C1385.87 104.785 1385.59 105.069 1385.59 105.638Z" fill="white"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M606.214 189.716C597.504 197.359 584.696 201.18 567.789 201.18C550.925 201.18 538.116 197.359 529.364 189.716C520.612 182.031 516.236 169.885 516.236 153.277C516.236 136.669 520.612 124.544 529.364 116.901C538.116 109.259 550.925 105.438 567.789 105.438C584.696 105.438 597.504 109.259 606.214 116.901C614.966 124.544 619.342 136.669 619.342 153.277C619.342 169.885 614.966 182.031 606.214 189.716ZM588.73 130.414C584.418 125.291 577.438 122.729 567.789 122.729C558.183 122.729 551.202 125.291 546.847 130.414C542.535 135.537 540.379 143.18 540.379 153.341C540.379 163.459 542.535 171.08 546.847 176.204C551.202 181.284 558.183 183.825 567.789 183.825C577.438 183.825 584.418 181.284 588.73 176.204C593.043 171.08 595.199 163.459 595.199 153.341C595.199 143.18 593.043 135.537 588.73 130.414Z" fill="white"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M678.837 201.18C695.744 201.18 708.552 197.359 717.261 189.716C726.014 182.031 730.39 169.885 730.39 153.277C730.39 136.669 726.014 124.544 717.261 116.901C708.552 109.259 695.744 105.438 678.837 105.438C661.972 105.438 649.164 109.259 640.412 116.901C631.66 124.544 627.283 136.669 627.283 153.277C627.283 169.885 631.66 182.031 640.412 189.716C649.164 197.359 661.972 201.18 678.837 201.18ZM678.837 122.729C688.486 122.729 695.466 125.291 699.778 130.414C704.09 135.537 706.246 143.18 706.246 153.341C706.246 163.459 704.09 171.08 699.778 176.204C695.466 181.284 688.486 183.825 678.837 183.825C669.23 183.825 662.25 181.284 657.895 176.204C653.583 171.08 651.427 163.459 651.427 153.341C651.427 143.18 653.583 135.537 657.895 130.414C662.25 125.291 669.23 122.729 678.837 122.729Z" fill="white"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M1097.67 201.18C1114.57 201.18 1127.38 197.359 1136.09 189.716C1144.84 182.031 1149.22 169.885 1149.22 153.277C1149.22 136.669 1144.84 124.544 1136.09 116.901C1127.38 109.259 1114.57 105.438 1097.67 105.438C1080.8 105.438 1067.99 109.259 1059.24 116.901C1050.49 124.544 1046.11 136.669 1046.11 153.277C1046.11 169.885 1050.49 182.031 1059.24 189.716C1067.99 197.359 1080.8 201.18 1097.67 201.18ZM1097.67 122.729C1107.32 122.729 1114.3 125.291 1118.61 130.414C1122.92 135.537 1125.08 143.18 1125.08 153.341C1125.08 163.459 1122.92 171.08 1118.61 176.204C1114.3 181.284 1107.32 183.825 1097.67 183.825C1088.06 183.825 1081.08 181.284 1076.73 176.204C1072.41 171.08 1070.26 163.459 1070.26 153.341C1070.26 143.18 1072.41 135.537 1076.73 130.414C1081.08 125.291 1088.06 122.729 1097.67 122.729Z" fill="white"/>
    <path d="M1161.13 162.883V113.443C1161.13 111.778 1161.71 110.348 1162.86 109.152C1164.06 107.957 1165.53 107.359 1167.28 107.359H1179.26C1180.88 107.359 1182.29 107.957 1183.48 109.152C1184.68 110.348 1185.28 111.778 1185.28 113.443V163.011C1185.28 169.287 1186.64 173.962 1189.37 177.036C1192.15 180.11 1196.42 181.647 1202.18 181.647H1212.43C1218.19 181.647 1222.44 180.11 1225.17 177.036C1227.95 173.962 1229.34 169.287 1229.34 163.011V113.443C1229.34 111.778 1229.91 110.348 1231.07 109.152C1232.26 107.957 1233.69 107.359 1235.36 107.359H1247.27C1249.02 107.359 1250.49 107.957 1251.69 109.152C1252.88 110.348 1253.48 111.778 1253.48 113.443V162.883C1253.48 174.581 1250.04 183.654 1243.17 190.101C1236.3 196.505 1226.26 199.707 1213.07 199.707H1201.54C1188.31 199.707 1178.25 196.505 1171.38 190.101C1164.55 183.654 1161.13 174.581 1161.13 162.883Z" fill="white"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M1270.51 193.303V113.571C1270.51 111.821 1271.11 110.348 1272.31 109.152C1273.5 107.957 1274.93 107.359 1276.6 107.359H1312.4C1330.93 107.359 1344.4 111.074 1352.81 118.502C1361.26 125.888 1365.49 137.501 1365.49 153.341C1365.49 169.138 1361.26 180.729 1352.81 188.115C1344.4 195.501 1330.93 199.194 1312.4 199.194H1276.6C1274.93 199.194 1273.5 198.639 1272.31 197.529C1271.11 196.377 1270.51 194.968 1270.51 193.303ZM1294.66 181.071H1311.69C1323.18 181.071 1330.99 178.979 1335.13 174.795C1339.27 170.611 1341.34 163.459 1341.34 153.341C1341.34 143.18 1339.27 136.007 1335.13 131.823C1330.99 127.596 1323.18 125.483 1311.69 125.483H1294.66V181.071Z" fill="white"/>
    <path d="M956.84 193.303V113.571C956.84 111.821 957.438 110.348 958.633 109.152C959.829 107.957 961.259 107.359 962.924 107.359H974.964C976.629 107.359 978.038 107.957 979.191 109.152C980.386 110.348 980.984 111.821 980.984 113.571V181.071H1036.76C1038.43 181.071 1039.84 181.668 1040.99 182.864C1042.19 184.059 1042.78 185.49 1042.78 187.155V193.175C1042.78 194.84 1042.19 196.27 1040.99 197.465C1039.84 198.618 1038.43 199.194 1036.76 199.194H962.924C961.259 199.194 959.829 198.639 958.633 197.529C957.438 196.377 956.84 194.968 956.84 193.303Z" fill="white"/>
    <path d="M844.384 153.341C844.384 169.138 848.333 180.815 856.231 188.371C864.172 195.928 876.063 199.707 891.902 199.707H907.528C917.22 199.707 925.78 197.209 933.209 192.214C940.638 187.176 944.352 180.281 944.352 171.529C944.352 169.778 943.754 168.156 942.559 166.661C941.406 165.167 939.827 164.42 937.82 164.42H930.199C926.228 164.42 923.154 166.79 920.977 171.529C917.86 178.274 912.63 181.647 905.287 181.647H892.543C883.193 181.647 876.853 179.64 873.522 175.627C870.192 171.571 868.527 164.121 868.527 153.277C868.527 142.432 870.192 135.004 873.522 130.99C876.853 126.977 883.193 124.971 892.543 124.971H903.942C911.286 124.971 916.537 128.343 919.696 135.089C921.874 139.785 924.926 142.134 928.854 142.134H936.475C937.884 142.134 939.336 141.6 940.83 140.533C942.324 139.423 943.071 137.608 943.071 135.089C943.071 126.337 939.336 119.442 931.864 114.404C924.435 109.366 915.875 106.847 906.184 106.847H891.902C876.063 106.847 864.172 110.647 856.231 118.246C848.333 125.803 844.384 137.501 844.384 153.341Z" fill="white"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M838.94 196.441C838.94 198.277 837.851 199.194 835.674 199.194H820.304C818.682 199.194 816.889 198.618 814.925 197.465C813.003 196.27 811.53 194.84 810.506 193.175L799.619 176.14C795.136 169.138 789.714 165.637 783.352 165.637H767.726V193.175C767.726 194.84 767.128 196.27 765.933 197.465C764.78 198.618 763.371 199.194 761.706 199.194H749.666C748.001 199.194 746.571 198.618 745.376 197.465C744.18 196.27 743.582 194.84 743.582 193.175V113.443C743.582 111.778 744.159 110.348 745.312 109.152C746.464 107.957 747.873 107.359 749.538 107.359H802.372C812.149 107.359 820.155 109.985 826.388 115.236C832.664 120.488 835.802 127.575 835.802 136.498C835.802 148.922 827.754 156.714 811.658 159.873C814.22 160.812 816.59 162.435 818.767 164.74C820.944 167.003 823.442 170.312 826.26 174.667L837.915 193.175C838.599 194.37 838.94 195.459 838.94 196.441ZM767.726 147.513H795.008C799.405 147.513 803.269 146.638 806.599 144.887C809.972 143.137 811.658 140.34 811.658 136.498C811.658 132.655 809.972 129.859 806.599 128.109C803.269 126.358 799.405 125.483 795.008 125.483H767.726V147.513Z" fill="white"/>
    <path d="M426.962 113.571V193.303C426.962 194.968 427.56 196.377 428.755 197.529C429.951 198.639 431.381 199.194 433.046 199.194H506.886C508.551 199.194 509.96 198.618 511.112 197.465C512.308 196.27 512.905 194.84 512.905 193.175V187.155C512.905 185.49 512.308 184.059 511.112 182.864C509.96 181.668 508.551 181.071 506.886 181.071H451.106V113.571C451.106 111.821 450.508 110.348 449.312 109.152C448.16 107.957 446.751 107.359 445.086 107.359H433.046C431.381 107.359 429.951 107.957 428.755 109.152C427.56 110.348 426.962 111.821 426.962 113.571Z" fill="white"/>
    <path d="M330.516 193.046V113.315C330.516 111.65 331.113 110.241 332.309 109.088C333.504 107.936 334.934 107.359 336.6 107.359H410.439C412.104 107.359 413.513 107.957 414.666 109.152C415.861 110.348 416.459 111.778 416.459 113.443V119.463C416.459 121.128 415.861 122.558 414.666 123.754C413.513 124.906 412.104 125.483 410.439 125.483H354.659V148.025H400.065C401.73 148.025 403.139 148.623 404.291 149.819C405.487 151.014 406.084 152.423 406.084 154.045V160.129C406.084 161.794 405.487 163.225 404.291 164.42C403.139 165.573 401.73 166.149 400.065 166.149H354.659V193.046C354.659 194.797 354.062 196.27 352.866 197.465C351.713 198.618 350.304 199.194 348.639 199.194H336.6C334.934 199.194 333.504 198.618 332.309 197.465C331.113 196.27 330.516 194.797 330.516 193.046Z" fill="white"/>
    <path d="M234.197 44.5078L119.176 17.5166L119.176 46.6657L236.341 80.9083C237.165 80.0891 237.663 78.9609 237.663 77.7383L237.673 48.8736C237.674 46.7934 236.235 44.9859 234.197 44.5078Z" fill="white"/>
    <path d="M119.173 53.4909L0.675781 81.2976L48.5754 92.5378L119.173 75.9714L119.176 105.12L236.328 139.359C237.16 138.539 237.663 137.405 237.663 136.177L237.673 107.329C237.674 105.249 236.235 103.442 234.197 102.964L189.775 92.5379L234.189 82.1008C234.997 81.911 235.71 81.5121 236.277 80.9702L119.173 53.4909Z" fill="white"/>
    <path d="M0.675781 139.754L119.175 111.946L236.264 139.422C235.699 139.956 234.991 140.35 234.191 140.539L189.76 150.997L234.199 161.425C236.236 161.903 237.675 163.71 237.675 165.79L237.676 194.666C237.676 195.883 237.183 197.007 236.366 197.825L119.176 163.575L119.176 134.434L48.5908 150.997L0.675781 139.754Z" fill="white"/>
    <path d="M119.176 170.4L236.304 197.886C235.734 198.435 235.015 198.839 234.2 199.03L189.762 209.458L234.198 219.885C236.235 220.363 237.674 222.17 237.674 224.25L237.675 253.126C237.675 254.348 237.178 255.475 236.356 256.294L119.176 222.047L119.175 192.894L48.5886 209.458L0.676883 198.215L119.176 170.4Z" fill="white"/>
    <path d="M119.175 284.482L0.675781 256.675L119.175 228.872L236.293 256.355C235.725 256.9 235.009 257.3 234.199 257.49L119.175 284.482Z" fill="white"/>
  </svg>
)

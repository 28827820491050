import React, { FC, useState } from "react"
import { Grid } from "@mui/material"
import { useParams } from "react-router-dom"
import {
  HasSidebarLayout,
  DataLoad,
  EntityHeader,
  EventInfo,
} from "src/components"
import { useEventRequest } from "src/hooks/api"
import { CustomSingleEvent } from "src/api"
import { useAddPopupMessage } from "src/hooks/ui"

interface EventShowContentProps {
  event: CustomSingleEvent;
}

const EventShowContent: FC<EventShowContentProps> = ({
  event,
}) => {
  if (!event) return null

  const { project, projectSection, type } = event
  const title = [
    type?.displayName,
    'in',
    [project?.name, projectSection?.name].filter((item) => Boolean(item)).join(', '),
  ].filter((item) => Boolean(item)).join(' ')
  return (
    <Grid container direction="column">
      <Grid item>
        <EntityHeader title={title} />
      </Grid>
      <Grid item container>
        <EventInfo event={event} />
      </Grid>
    </Grid>
  )
}

const EventShow: FC = () => {
  const addPopupMessage = useAddPopupMessage()
  const [event, setEvent] = useState<CustomSingleEvent>()
  document.title = `Floorcloud Admin Panel - Event ${event?.eventCardRenderingData.eventName || ''}`
  const params = useParams()
  const id = parseInt((params as any).id, 10)
  const { isInitialLoading, isError, error } = useEventRequest({
    id,
    options: {
      onSuccess: (data) => setEvent(data as CustomSingleEvent),
      onError: ({ message: text }) => addPopupMessage({ text, type: "error" }),
    },
  })

  return (
    <HasSidebarLayout>
      <DataLoad
        isLoading={isInitialLoading}
        isError={isError}
        errorMessage={error?.message}
      >
        <EventShowContent
          event={event as CustomSingleEvent}
        />
      </DataLoad>
    </HasSidebarLayout>
  )
}

export default EventShow

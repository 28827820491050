import { FC, useState, useEffect } from "react"
import { Paper } from "@mui/material"
import {
  DataGridPremium,
  useGridApiRef,
  gridFilterModelSelector,
  gridFilteredTopLevelRowCountSelector,
  GridPaginationModel,
  gridClasses,
} from '@mui/x-data-grid-premium'
import { Project } from "src/api"
import { SearchParamsKeys } from "src/enums"
import { TableToolbar } from "src/components/ui"
import { ProjectColumnsDataGrid } from "./tableColumns"

const columns = [
  ProjectColumnsDataGrid.NameWithIcon,
  ProjectColumnsDataGrid.Location,
  ProjectColumnsDataGrid.ContractorName,
  ProjectColumnsDataGrid.UserName,
  ProjectColumnsDataGrid.ProjectTypeName,
  ProjectColumnsDataGrid.Size,
  ProjectColumnsDataGrid.ConnectedSensors,
  ProjectColumnsDataGrid.CreatedAt,
]

interface NewProjectListProps {
    count?: number;
    data?: Project[];
    isLoading?: boolean;
    autoHeight?: boolean;
    queryParams?: Record<string, string>;
    setQueryParams?: (params: Record<string, string>, paramsToRemove?: string[]) => void;
    defaultRowsPerPageOptions?: number[]
}

export const NewProjectList: FC<NewProjectListProps> = ({
  data = [],
  count = 0,
  isLoading = false,
  autoHeight = false,
  setQueryParams,
  queryParams,
  defaultRowsPerPageOptions
}) => {

  const [rowCountState, setRowCountState] = useState(count)
  const apiRef = useGridApiRef()

  useEffect(() => {
    setRowCountState((prevRowCountState) =>
      count !== undefined ? count : prevRowCountState
    )
  }, [count, setRowCountState])


  const handleChangePaginationModel = async (pagination: GridPaginationModel): Promise<void> => {
    const { page, pageSize } = pagination
    const newPage = page + 1
    if (setQueryParams) {
      setQueryParams({
        [SearchParamsKeys.Page]: `${newPage}`,
        [SearchParamsKeys.RowsPerPage]: `${pageSize}`,
      }, [
        SearchParamsKeys.Page,
        SearchParamsKeys.RowsPerPage,
      ])
    }
  }

  const handleChangeState = async () => {
    const filterModel = gridFilterModelSelector(apiRef.current.state)
    if (filterModel.items.length > 0 && filterModel.items[0].value) {
      const visibleRowCount = gridFilteredTopLevelRowCountSelector(apiRef.current.state)
      if (visibleRowCount) setRowCountState(visibleRowCount)
    }
    else setRowCountState(count)
  }

  const page = queryParams?.page ? parseInt(queryParams.page, 10) - 1 : undefined
  const pageSize = queryParams?.rowsPerPage ? parseInt(queryParams.rowsPerPage, 10) : undefined
  return (
    <Paper sx={{ flexGrow: 1, marginTop: 3, minHeight: '200px', height: '100%' }} >
      <DataGridPremium<Project>
        apiRef={apiRef}
        pagination
        autoHeight={autoHeight}
        disableRowSelectionOnClick
        rows={data}
        columns={columns}
        rowCount={rowCountState}
        loading={isLoading}
        paginationModel={{
          page: page as number,
          pageSize: pageSize as number
        }}
        rowThreshold={2}
        columnThreshold={2}
        paginationMode="server"
        pageSizeOptions={defaultRowsPerPageOptions}
        onPaginationModelChange={handleChangePaginationModel}
        onStateChange={handleChangeState}
        getRowHeight={() => 'auto'}
        localeText={{
          columnMenuSortAsc: "Sort A-Z",
          columnMenuSortDesc: "Sort Z-A",
        }}
        slots={{
          toolbar: TableToolbar
        }}
        sx={(theme) => ({
          border: "none",
          "& .MuiDataGrid-columnHeaderTitleContainerContent": {
            paddingLeft: "5px",
            overflow: "visible",
          },
          "& .MuiDataGrid-columnHeaderTitle": {
            whiteSpace: "normal",
            lineHeight: 1,
            fontSize: "13px",
            fontWeight: 400,
            color: "rgba(0, 0, 0, 0.6)",
            overflow: "visible",
          },
          [`& .${gridClasses.cell}`]: {
            py: 1.5,
          },
        })}
      />
    </Paper >
  )
}
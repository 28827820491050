import React, { useState } from 'react'
import { createRoot } from 'react-dom/client'
import { QRCodeSVG } from 'qrcode.react'
import { useAddPopupMessage } from './useAddPopupMessage'

export const useQRCode = () => {
  const [loading, setLoading] = useState(false)
  const addMessage = useAddPopupMessage()

  const generateCode = async (text: string) => {
    setLoading(true)
    try {
      const qrContainer = document.createElement('div')
      const root = createRoot(qrContainer!)
      root.render(
        <QRCodeSVG
          value={text}
          level="H"
          version="3"
          size={400}
        />
      )
      const newTab = window.open()
      if (newTab) {
        if (newTab.document.getElementsByTagName('body').item(0)) {
          newTab.document.getElementsByTagName('body').item(0)?.appendChild(qrContainer)
        }
        newTab.document.close()
      }
    } catch (err) {
      addMessage({ type: 'error', text: (err as Error).message })
    } finally {
      setLoading(false)
    }
  }

  return {
    loading,
    generateCode,
  }
}

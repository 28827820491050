import { FC, useState, useEffect } from "react"
import { Paper } from "@mui/material"
import {
  DataGridPremium,
  useGridApiRef,
  GridPaginationModel,
  gridFilteredTopLevelRowCountSelector,
  gridFilterModelSelector,
  gridClasses,
  GridColDef,
} from '@mui/x-data-grid-premium'
import ImagesViewer from "react-images-viewer"
import { TableToolbar } from "src/components/ui"
import { Task } from "src/api"
import { SearchParamsKeys } from "src/enums"
import { useImagesViewer } from "src/hooks/ui"
import { ImagesListItem } from "src/contexts"
import { TaskColumnsDataGrid } from "./tableColumns"

const defaultColumns = ({
  openGallery,
}: {
  openGallery: (imgs: ImagesListItem[]) => void
}) => [
  TaskColumnsDataGrid.NameWithIcon,
  TaskColumnsDataGrid.Description,
  TaskColumnsDataGrid.Status,
  TaskColumnsDataGrid.Author,
  TaskColumnsDataGrid.Assignee,
  TaskColumnsDataGrid.Contractor,
  TaskColumnsDataGrid.Project,
  TaskColumnsDataGrid.ProjectSection,
  TaskColumnsDataGrid.CreatedAt,
  TaskColumnsDataGrid.Photo(openGallery)
]

interface ListProps {
  count?: number
  data?: Task[]
  isLoading?: boolean
  autoHeight?: boolean
  queryParams?: Record<string, string>
  setQueryParams?: (params: Record<string, string>, paramsToRemove?: string[]) => void
  defaultRowsPerPageOptions?: number[]
  columns?: GridColDef<Task>[]
}

export const TaskList: FC<ListProps> = ({
  data = [],
  count = 0,
  isLoading = false,
  autoHeight = false,
  columns,
  setQueryParams,
  queryParams,
  defaultRowsPerPageOptions
}) => {
  const apiRef = useGridApiRef()
  const {
    isOpen: isOpenedGallery,
    images,
    currentImageIndex,
    openGallery,
    closeGallery,
    goToNext,
    goToPrev,
  } = useImagesViewer()
  const [rowCountState, setRowCountState] = useState(count)
  columns = columns || defaultColumns({ openGallery })

  useEffect(() => {
    setRowCountState((prevRowCountState) =>
      count !== undefined ? count : prevRowCountState
    )
  }, [count, setRowCountState])

  const handleChangePaginationModel = async (pagination: GridPaginationModel): Promise<void> => {
    if (!setQueryParams) return

    const { page, pageSize } = pagination
    const newPage = page + 1
    setQueryParams({
      [SearchParamsKeys.Page]: `${newPage}`,
      [SearchParamsKeys.RowsPerPage]: `${pageSize}`,
    }, [
      SearchParamsKeys.Page,
      SearchParamsKeys.RowsPerPage,
    ])
  }

  const handleChangeState = async () => {
    const filterModel = gridFilterModelSelector(apiRef.current.state)
    if (filterModel.items.length > 0 && filterModel.items[0].value) {
      const visibleRowCount = gridFilteredTopLevelRowCountSelector(apiRef.current.state)
      if (visibleRowCount) setRowCountState(visibleRowCount)
    }
    else setRowCountState(count)
  }

  const page = queryParams?.page ? parseInt(queryParams.page, 10) - 1 : undefined
  const pageSize = queryParams?.rowsPerPage ? parseInt(queryParams.rowsPerPage, 10) : undefined

  return (
    <Paper sx={{ flexGrow: 1, marginTop: 3, minHeight: '200px', height: '100%' }} >
      <DataGridPremium<Task>
        apiRef={apiRef}
        pagination
        autoHeight={autoHeight}
        disableRowSelectionOnClick
        rows={data}
        columns={columns}
        rowCount={rowCountState}
        loading={isLoading}
        paginationModel={{
          page: page as number,
          pageSize: pageSize as number
        }}
        rowThreshold={2}
        getRowHeight={() => 'auto'}
        columnThreshold={2}
        disableVirtualization
        paginationMode="server"
        pageSizeOptions={defaultRowsPerPageOptions}
        onPaginationModelChange={handleChangePaginationModel}
        onStateChange={handleChangeState}
        localeText={{
          columnMenuSortAsc: "Sort A-Z",
          columnMenuSortDesc: "Sort Z-A",
        }}
        slots={{
          toolbar: TableToolbar
        }}
        sx={(theme) => ({
          border: "none",
          "& .MuiDataGrid-columnHeaderTitleContainerContent": {
            paddingLeft: "5px",
            overflow: "visible",
          },
          "& .MuiDataGrid-columnHeaderTitle": {
            whiteSpace: "normal",
            lineHeight: 1,
            fontSize: "13px",
            fontWeight: 400,
            color: "rgba(0, 0, 0, 0.6)",
            overflow: "visible",
          },
          [`& .${gridClasses.cell}`]: {
            py: 1,
          },
        })}
      />
      <ImagesViewer
        imgs={images}
        isOpen={isOpenedGallery}
        onClickPrev={goToPrev}
        onClickNext={goToNext}
        onClose={closeGallery}
        currImg={currentImageIndex}
      />
    </Paper >
  )
}
import React from "react"
import { TableCell, Grid, Switch, Typography, Button } from "@mui/material"
import { Engineering } from "@mui/icons-material"
import distanceInWords from "date-fns/formatDistance"
import format from "date-fns/format"
import { DynamicColumn, InnerLink } from "src/components/ui"
import { Session, User, UserGlobalNotification, UserNotificationResponseItemByProject } from "src/api"
import { RemoveForeman } from "../forms"
import {
  EnrichedAdminNotification,
  EnrichedUserSession,
} from "../sections"
import { ClosedEye, Crown } from "../icons"
import { EnrichedUserGlobalNotification } from "../sections/user/userGlobalNotifications"
import { EnrichedUserProjectNotification } from "../sections/user/userProjectNotifications"

export namespace UserColumns {
  export const Name: DynamicColumn<User> = {
    field: "name",
    headerName: "Name",
    type: "string",
    sort: true,
  }

  export const NameWithIcon: (
    pmRoleId: number | undefined,
    ownerRoleId: number | undefined
  ) => DynamicColumn<User> = (pmRoleId, ownerRoleId) => ({
    field: "name",
    sort: true,
    headerName: "Name",
    cellProps: { align: "left" },
    type: "custom",
    component: ({ row: { id, name, roleId, isArchived }, cellProps }) => {
      const isPM = pmRoleId === roleId
      const isOwner = ownerRoleId === roleId
      return (
        <TableCell align={cellProps?.align}>
          <Grid container alignItems="center" spacing={1}>
            <Grid item>
              <InnerLink to={`/users/${id}`}>{name}</InnerLink>
            </Grid>
            {isArchived && (
              <Grid container item title="Archived User" xs={2}>
                <ClosedEye />
              </Grid>
            )}
            {isPM && (
              <Grid container item title="Project Manager" xs={2}>
                <Engineering />
              </Grid>
            )}
            {isOwner && (
              <Grid container item title="Owner" xs={2}>
                <Crown />
              </Grid>
            )}
          </Grid>
        </TableCell>
      )
    },
  })
  export const Email: DynamicColumn<User> = {
    field: "email",
    headerName: "Email",
    type: "email",
    sort: true,
  }
  export const Phone: DynamicColumn<User> = {
    field: "phone",
    headerName: "Phone",
    type: "phone",
    sort: true,
  }
  export const LastActive: DynamicColumn<User> = {
    field: "lastActive",
    headerName: "Last Active",
    type: "date",
    format: (value) => distanceInWords(new Date(value), new Date()),
    empty: "Never",
  }
  export const CreatedAt: DynamicColumn<User> = {
    field: "createdAt",
    headerName: "Created At",
    type: "date",
    format: (value) => value ? format(new Date(value), 'PPpp') : '',
    empty: "Never",
    sort: true,
  }
  export const LastAction: DynamicColumn<User> = {
    field: "lastAction",
    headerName: "Last Action",
    type: "custom",
    empty: "Never",
    cellProps: {
      sx: {
        wordWrap: "break-word",
        width: 300,
      }
    },
    component: ({ row }) => {
      return (<TableCell>
        <Typography variant="body1" sx={{ textTransform: "capitalize" }}>
          {row.lastAction ? `${row.lastAction.action} (${distanceInWords(new Date(row.lastAction.date), new Date())})` : "-"}
        </Typography>
      </TableCell>
      )
    },
  }

  export const RemoveFromProject: DynamicColumn<User> = {
    field: "",
    headerName: "",
    type: "custom",
    component: ({ row, cellProps }) => {
      return (
        <TableCell align={cellProps?.align}>
          <Grid container alignItems="center" justifyContent="flex-end">
            <RemoveForeman user={row} />
          </Grid>
        </TableCell>
      )
    },
  }
  export const ContractorName: DynamicColumn<User> = {
    field: "contractor.name",
    headerName: "Organization",
    type: "string",
    innerLink: ({ contractor }) => `/contractors/${contractor?.id}`,
  }
  export const LastAppVersion: DynamicColumn<User> = {
    field: "lastVersionApp",
    headerName: "Mobile App Version",
    type: "string",
  }
}

export namespace UserGlobalNotificationColumns {
  export const NotificationName: DynamicColumn<UserGlobalNotification> = {
    field: "globalNotification.displayName",
    headerName: "Notification",
    type: "string",
  }

  export const Active: DynamicColumn<EnrichedUserGlobalNotification> = {
    field: "isActive",
    headerName: "Active",
    type: "custom",
    component: ({ row }) => {
      return (
        <TableCell>
          <Switch checked={row.isActive} onChange={row.toggle} />
        </TableCell>
      )
    },
  }
}

export namespace UserProjectNotificationColumns {
  export const Project: DynamicColumn<UserNotificationResponseItemByProject> = {
    field: "project.name",
    headerName: "Project",
    type: "string",
  }

  export const Active: DynamicColumn<EnrichedUserProjectNotification> = {
    field: "value",
    headerName: "Active",
    type: "custom",
    component: ({ row }) => {
      return (
        <TableCell>
          <Switch checked={row.value} onChange={row.toggle} />
        </TableCell>
      )
    },
  }
}

export namespace AdminNotificationColumns {
  export const NotificationName: DynamicColumn<EnrichedAdminNotification> = {
    field: "notificationTypeDisplayName",
    headerName: "Notification",
    type: "string",
  }

  export const Active: DynamicColumn<EnrichedAdminNotification> = {
    field: "isActive",
    headerName: "Active",
    type: "custom",
    component: ({ row }) => {
      return (
        <TableCell>
          <Switch checked={row.isActive} onChange={row.toggle} />
        </TableCell>
      )
    },
  }
}

export namespace UserActiveSessionColumns {
  export const PlatformName: DynamicColumn<Session> = {
    field: "platform",
    headerName: "Platform",
    type: "custom",
    component: ({ row }) => {
      return (
        <TableCell>
          <Typography variant="body1" sx={{ textTransform: "capitalize" }}>
            {row.platform}
          </Typography>
        </TableCell>
      )
    }
  }

  export const StartDate: DynamicColumn<Session> = {
    field: "createdAt",
    headerName: "Start session",
    type: "date",
    format: (value) => format(new Date(value), "PPpp"),
  }

  export const OsName: DynamicColumn<Session> = {
    field: "device.os.name",
    headerName: "Operation system",
    type: "custom",
    component: ({ row }) => {
      return (
        <TableCell>
          <Typography variant="body1" sx={{ textTransform: "capitalize" }}>
            {row.device.os.name || '-'}
          </Typography>
        </TableCell>
      )
    }
  }

  export const ClientName: DynamicColumn<Session> = {
    field: "device.client.name",
    headerName: "User's client name",
    type: "custom",
    component: ({ row }) => {
      return (
        <TableCell>
          <Typography variant="body1">
            {
              row.device.client.name && row.device.client.version
                ? `${row.device.client.name}, v${row.device.client.version}`
                : row.device.client.name || '-'
            }
          </Typography>
        </TableCell>
      )
    }
  }

  export const DeviceType: DynamicColumn<Session> = {
    field: "device.device.type",
    headerName: "User's device type",
    type: "custom",
    component: ({ row }) => {
      return (
        <TableCell>
          <Typography variant="body1" sx={{ textTransform: "capitalize" }}>
            {row.device.device.type || '-'}
          </Typography>
        </TableCell>
      )
    }
  }

  export const DeviceBrand: DynamicColumn<Session> = {
    field: "device.device.brand",
    headerName: "User's device brand",
    type: "custom",
    component: ({ row }) => {
      return (
        <TableCell>
          <Typography variant="body1" sx={{ textTransform: "capitalize" }}>
            {row.device.device.brand || '-'}
          </Typography>
        </TableCell>
      )
    }
  }

  export const DeviceModel: DynamicColumn<Session> = {
    field: "device.device.model",
    headerName: "User's device model",
    type: "custom",
    component: ({ row }) => {
      return (
        <TableCell>
          <Typography variant="body1" sx={{ textTransform: "capitalize" }}>
            {row.device.device.model || '-'}
          </Typography>
        </TableCell>
      )
    }
  }

  export const DeleteUserSession: DynamicColumn<EnrichedUserSession> = {
    field: "",
    headerName: "Management",
    type: "custom",
    cellProps: {
      align: "right"
    },
    component: ({ row }) => {
      return (
        <TableCell align="right">
          <Button variant="contained" onClick={row.delete}>Terminate</Button>
        </TableCell>
      )
    },
  }
}

export default UserColumns

import { FC } from 'react'
import {
  FormControlLabel,
  FormControlLabelProps as FormControlLabelPropsInterface,
  Radio as MuiRadio,
  RadioGroup as MuiRadioGroup,
  RadioProps as MuiRadioProps,
  FormControl,
  FormLabel,
  Typography,
} from '@mui/material'
import { useField, ErrorMessage } from 'formik'

interface RadioGroupProps {
  label: string
  name: string
  error?: string
  RadioProps?: Omit<MuiRadioProps, 'name' | 'required' | 'disabled'>
  FormControlLabelProps?: Omit<
    FormControlLabelPropsInterface,
    'name' | 'checked' | 'value' | 'disabled' | 'control' | 'label'
  >
  options: {
    value: string | number
    label: string
    required?: boolean
    disabled?: boolean
  }[]
}

export const RadioGroup: FC<RadioGroupProps> = ({
  label,
  name,
  error: errorMessage,
  RadioProps,
  FormControlLabelProps,
  options = [],
}) => {
  const [field, { touched, error }] = useField(name)
  return (
    <FormControl error={touched && !!error}>
      <FormLabel id="radio-buttons-group">{label}</FormLabel>
      <MuiRadioGroup
        aria-labelledby="radio-buttons-group"
        {...field}
      >
        {options.map((option) => (
          <FormControlLabel
            key={option.value}
            {...FormControlLabelProps}
            label={option.label}
            value={option.value}
            control={
              <MuiRadio
                {...RadioProps}
                disabled={option.disabled || false}
                required={option.required || false}
              />
            }
            
          />
        ))}
      </MuiRadioGroup>
      <ErrorMessage
        name={name}
        render={(schemeError: string) => (
          <Typography
            variant="caption"
            sx={{ color: 'error.main', display: 'block' }}
          >
            {errorMessage || schemeError}
          </Typography>
        )}
      />
    </FormControl>
  )
}

import React, { FC, useState } from "react"
import {
  Box,
  Chip,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
} from "@mui/material"
import { useFormik, FormikProvider, Form } from "formik"
import {
  AutocompleteTextField,
  ComplexDropdownItemFactory,
  FormCheckbox,
  FormDropdown,
} from "src/components/ui"
import { useQueryParams } from "src/hooks/ui"
import {
  LocationTypes,
  MapLegendTypes,
  SearchParamsKeys,
  SensorFilters,
} from "src/enums"
import { Contractor } from "src/api"
import { FormButtonGroup } from "../../ui/entityListFilters/formButtonGroup"

const legendTypes = [
  { name: "Based on battery level", value: MapLegendTypes.BatteryLevel },
  { name: "Based on signal strength", value: MapLegendTypes.SignalStrength },
]

const factory = ComplexDropdownItemFactory({
  labelProp: "name",
  valueProp: "value",
})

const readings = [
  { name: "Acceleration", value: "accelerationTime" },
  { name: "Battery", value: "batteryTime" },
  { name: "Dew Point", value: "dewpointTime" },
  { name: "Humidity", value: "humidityTime" },
  { name: "Light", value: "lightTime" },
  { name: "Location Cellular", value: "locationTime" },
  { name: "Location GPS", value: "locationGpsTime" },
  { name: "Orientation", value: "orientationTime" },
  { name: "Pressure", value: "pressureTime" },
  { name: "Signal Strength", value: "signalStrengthTime" },
  { name: "Temperature", value: "temperatureTime" },
]

const locationTypes = [
  { displayName: "Cellural", value: LocationTypes.Cellural },
  { displayName: "GPS", value: LocationTypes.GPS },
]

interface SensorMapFiltersProps {
  currentContractor?: Contractor | null;
  contractors?: Contractor[];
  contractorsLoading?: boolean;
  findContractorsByName?: (name: string) => void;
  activeFilters?: SensorFilters[];
}

export const SensorMapFilters: FC<SensorMapFiltersProps> = ({
  contractors = [],
  contractorsLoading = false,
  findContractorsByName = () => {},
  currentContractor = null,
  activeFilters = [],
}) => {
  const [queryParams, setQueryParams] = useQueryParams()
  const [hasContractorFilter] = useState(
    activeFilters.indexOf(SensorFilters.Contractor) !== -1
  )
  const [hasLocationTypeFilter] = useState(
    activeFilters.indexOf(SensorFilters.LocationType) !== -1
  )
  const [hasBatteryLevelFilter] = useState(
    activeFilters.indexOf(SensorFilters.LowBattery) !== -1
  )
  const [hasSignalLevelFilter] = useState(
    activeFilters.indexOf(SensorFilters.WeakSignal) !== -1
  )
  const [hasLastPingFilter] = useState(
    activeFilters.indexOf(SensorFilters.LastPingMoreThanHour) !== -1
  )
  const [hasSyncPeriodFilter] = useState(
    activeFilters.indexOf(SensorFilters.SyncPeriod) !== -1
  )
  const [hasReadingsAgeFilter] = useState(
    activeFilters.indexOf(SensorFilters.ReadingsAge) !== -1
  )

  const formik = useFormik({
    initialValues: {
      [SearchParamsKeys.MapLegendType]:
        queryParams[SearchParamsKeys.MapLegendType] ||
        MapLegendTypes.BatteryLevel,
      ...(hasLocationTypeFilter
        ? {
          [SearchParamsKeys.LocationType]:
              queryParams[SearchParamsKeys.LocationType] ||
              LocationTypes.Cellural,
        }
        : {}),
      ...(hasContractorFilter
        ? {
          [SearchParamsKeys.Contractor]:
              queryParams[SearchParamsKeys.Contractor] || null,
        }
        : null),
      ...(hasBatteryLevelFilter
        ? {
          [SearchParamsKeys.LowBattery]:
              queryParams[SearchParamsKeys.LowBattery] || false,
        }
        : null),
      ...(hasSignalLevelFilter
        ? {
          [SearchParamsKeys.WeakSignal]:
              queryParams[SearchParamsKeys.WeakSignal] || false,
        }
        : null),
      ...(hasLastPingFilter
        ? {
          [SearchParamsKeys.LastPingMoreThanHour]:
              queryParams[SearchParamsKeys.LastPingMoreThanHour] || false,
        }
        : null),
      ...(hasSyncPeriodFilter
        ? {
          [SearchParamsKeys.SyncPeriodLessThanHour]:
              queryParams[SearchParamsKeys.SyncPeriodLessThanHour] || false,
        }
        : null),
      ...(hasReadingsAgeFilter
        ? {
          [SearchParamsKeys.AgeOfReadingsMoreThanHour]:
              queryParams[SearchParamsKeys.AgeOfReadingsMoreThanHour]?.split(
                ","
              ) || [],
        }
        : null),
    },
    onSubmit: async (formState) => {
      const legendType = formState[SearchParamsKeys.MapLegendType] || null
      const contractor = formState[SearchParamsKeys.Contractor] || null
      const locationType = formState[SearchParamsKeys.LocationType] || null
      const batteryLevel = formState[SearchParamsKeys.LowBattery] || false
      const signalStrength = formState[SearchParamsKeys.WeakSignal] || false
      const lastPing =
        formState[SearchParamsKeys.LastPingMoreThanHour] || false
      const syncPeriod =
        formState[SearchParamsKeys.SyncPeriodLessThanHour] || false
      const readingsAge =
        formState[SearchParamsKeys.AgeOfReadingsMoreThanHour] || []
      await setQueryParams(
        {
          ...(legendType
            ? { [SearchParamsKeys.MapLegendType]: legendType }
            : {}),
          ...(locationType && hasLocationTypeFilter
            ? { [SearchParamsKeys.LocationType]: locationType }
            : {}),
          ...(contractor && hasContractorFilter
            ? { [SearchParamsKeys.Contractor]: `${contractor}` }
            : {}),

          ...(batteryLevel && hasBatteryLevelFilter
            ? { [SensorFilters.LowBattery]: `${batteryLevel}` }
            : {}),
          ...(signalStrength && hasSignalLevelFilter
            ? { [SensorFilters.WeakSignal]: `${signalStrength}` }
            : {}),
          ...(lastPing && hasLastPingFilter
            ? { [SearchParamsKeys.LastPingMoreThanHour]: `${lastPing}` }
            : {}),
          ...(syncPeriod && hasSyncPeriodFilter
            ? { [SearchParamsKeys.SyncPeriodLessThanHour]: `${syncPeriod}` }
            : {}),
          ...(readingsAge.length > 0 && hasReadingsAgeFilter
            ? {
              [SearchParamsKeys.AgeOfReadingsMoreThanHour]:
                  readingsAge.join(","),
            }
            : {}),
        },
        [
          SearchParamsKeys.MapLegendType,
          SearchParamsKeys.LocationType,
          SearchParamsKeys.Type,
          SearchParamsKeys.Contractor,
          SearchParamsKeys.LowBattery,
          SearchParamsKeys.WeakSignal,
          SearchParamsKeys.LastPingMoreThanHour,
          SearchParamsKeys.SyncPeriodLessThanHour,
          SearchParamsKeys.AgeOfReadingsMoreThanHour,
        ]
      )
    },
  })

  const { submitForm, values, setValues } = formik

  const handleSelectChange = (
    event: SelectChangeEvent<typeof values["ageOfReadingsMoreThanHour"]>
  ) => {
    const {
      target: { value },
    } = event
    setValues({
      ...values,
      ageOfReadingsMoreThanHour:
        typeof value === "string" ? value.split(",") : value,
    })
  }
  const handleSelectClose = () => submitForm()

  return (
    <FormikProvider value={formik}>
      <Form>
        <Grid container flexDirection="column" alignItems="stretch">
          <Grid item mb={1}>
            <FormDropdown
              list={legendTypes}
              name={SearchParamsKeys.MapLegendType}
              label="Map legend type"
              menuItemFactory={factory}
              onChange={submitForm}
            />
          </Grid>
          {hasReadingsAgeFilter && (
            <Grid item mb={1}>
              <FormControl sx={{ width: "100%" }}>
                <InputLabel id="readings-multiple-chip-label">
                  Readings
                </InputLabel>
                <Select
                  labelId="readings-multiple-chip-label"
                  id="readings-multiple-chip"
                  multiple
                  value={values.ageOfReadingsMoreThanHour}
                  onChange={handleSelectChange}
                  onClose={handleSelectClose}
                  input={
                    <OutlinedInput id="select-multiple-chip" label="Readings" />
                  }
                  renderValue={(selected) => (
                    <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                      {selected.map((value) => {
                        const reading = readings.find(
                          (item) => item?.value === value
                        )
                        return (
                          <Chip key={reading?.value} label={reading?.name} />
                        )
                      })}
                    </Box>
                  )}
                >
                  {readings.map((reading) => (
                    <MenuItem key={reading?.value} value={reading?.value}>
                      {reading?.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          )}
          {hasContractorFilter && (
            <Grid item mb={1}>
              <AutocompleteTextField
                label="Contractor"
                name={SearchParamsKeys.Contractor}
                labelField="name"
                valueField="id"
                width="auto"
                options={contractors}
                onInputChange={findContractorsByName}
                isLoading={contractorsLoading}
                onChange={submitForm}
                initialValue={currentContractor}
              />
            </Grid>
          )}
          {hasLocationTypeFilter && (
            <Grid item mb={2}>
              <FormButtonGroup
                name={SearchParamsKeys.LocationType}
                list={locationTypes}
                onChange={submitForm}
              />
            </Grid>
          )}
          {hasBatteryLevelFilter && (
            <Grid item>
              <FormCheckbox
                view="switch"
                label="Low battery"
                name={SearchParamsKeys.LowBattery}
                onChange={submitForm}
              />
            </Grid>
          )}
          {hasSignalLevelFilter && (
            <Grid item>
              <FormCheckbox
                view="switch"
                label="Weak signal"
                name={SearchParamsKeys.WeakSignal}
                onChange={submitForm}
              />
            </Grid>
          )}
          {hasLastPingFilter && (
            <Grid item>
              <FormCheckbox
                view="switch"
                label="Last ping more than hour ago"
                name={SearchParamsKeys.LastPingMoreThanHour}
                onChange={submitForm}
              />
            </Grid>
          )}
          {hasSyncPeriodFilter && (
            <Grid item>
              <FormCheckbox
                view="switch"
                label="Synchronization period less than hour"
                name={SearchParamsKeys.SyncPeriodLessThanHour}
                onChange={submitForm}
              />
            </Grid>
          )}
        </Grid>
      </Form>
    </FormikProvider>
  )
}

import { createContext } from 'react'

export type ImagesListItem = {
  src: string
}

export type ImagesViewerContextState = {
  isOpen: boolean
  images: ImagesListItem[]
  currentImageIndex: number
  openViewer: () => void
  closeViewer: () => void
  setImages: (images: ImagesListItem[]) => void
  setCurrentImageIndex: (idx: number) => void
}

export const ImagesViewerStateContext = createContext<ImagesViewerContextState>({
  isOpen: false,
  images: [],
  currentImageIndex: 0,
  openViewer: () => {},
  closeViewer: () => {},
  setImages: () => {},
  setCurrentImageIndex: () => {},
})

import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { Entities } from 'src/enums'
import { ManufacturerAnnouncement } from 'src/api'
import { APIList } from 'src/interfaces'
import { useApiInstance } from './useApiInstance'
import {
  buildRequestParams,
  ObjectType,
  transformPage,
  transformPerPage,
} from './helpers'

interface RequestParams {
  params?: Partial<AdminManufacturerAnnouncementsGetParams>
  paramsTransform?: Array<(params: Record<string, any>) => Record<string, any>>;
  options?: Omit<
    UseQueryOptions<unknown, Error, APIList<ManufacturerAnnouncement>>,
    'queryKey' | 'queryFn'
  >;
}

export interface AdminManufacturerAnnouncementsGetParams {
  limit: number
  offset: number
  sort: string
}

const schema: ObjectType['schema'] = {
  limit: 'number',
  offset: 'number',
  sort: 'string',
}

export const useManufacturerAnnouncementListRequest = ({
  params = {},
  paramsTransform = [],
  options,
}: RequestParams = {}) => {
  const api = useApiInstance()

  const transformedParams = [
    transformPerPage,
    transformPage,
    ...paramsTransform,
  ].reduce((param, transform) => transform(param), params)

  const queryParams = buildRequestParams<AdminManufacturerAnnouncementsGetParams>(
    transformedParams,
    schema,
  )

  return useQuery<unknown, Error, APIList<ManufacturerAnnouncement>>(
    [Entities.ManufacturerAnnouncement, JSON.stringify(transformedParams)],
    async () => {
      const {
        limit,
        offset,
      } = queryParams
      const { data } = await api.v1AdminManufacturersAnnouncementsGet(
        limit,
        offset,
      )
      return data
    },
    {
      ...options,
    },
  )
}

import { FC } from "react"
import Grid from "@mui/material/Grid"
import Typography from "@mui/material/Typography"
import JsonView from '@uiw/react-json-view'
import { useParams } from "react-router-dom"
import {
  HasSidebarLayout,
  DataLoad,
  EntityHeader,
} from "src/components"
import { SensorExtendedDiagnostics } from "src/api"
import { useSensorDiagnostics } from "src/hooks/api"

interface SensorRawUplinkContentProps {
  diagnostics: SensorExtendedDiagnostics
}

const SensorExtendedDiagnosticsContent: FC<SensorRawUplinkContentProps> = ({ diagnostics }) => {
  return (
    <Grid container direction="column" spacing={4}>
      <Grid item>
        {
          diagnostics && Object.keys(diagnostics).length > 0 ? (
            <JsonView value={diagnostics} />
          ) : (
            <Typography variant="body1">No data</Typography>
          )
        }
      </Grid>
    </Grid>
  )
}

const SensorDiagnostics: FC = () => {
  document.title = 'Sensor extended diagnostics'
  const params = useParams()
  const { uniqueId } = params as { uniqueId: string }
  const { data, isInitialLoading, isError, error } = useSensorDiagnostics({ uniqueId })
  const title = `Sensor extended diagnostics - ${uniqueId}`

  return (
    <HasSidebarLayout>
      <EntityHeader
        title={title}
        showBackButton={false}
      />
      <DataLoad
        isLoading={isInitialLoading}
        isError={isError}
        errorMessage={error?.message}
      >
        <SensorExtendedDiagnosticsContent diagnostics={data as SensorExtendedDiagnostics} />
      </DataLoad>
    </HasSidebarLayout>
  )
}

export default SensorDiagnostics

import { FC } from 'react'
import { Grid, Typography } from '@mui/material'
import { EmailLink, PhoneLink } from 'src/components/ui'

interface ManufacturerInfoProps {
  address?: string;
  email?: string;
  phone?: string;
  photo?: string;
}

export const ManufacturerInfo: FC<ManufacturerInfoProps> = (props) => {
  const { address, email, phone, photo } = props
  return (
    <Grid container direction="row" spacing={2}>
      <Grid item>
        <img
          style={{
            objectFit: "scale-down",
            width: 100,
            height: 100
          }}
          src={photo ? photo : "../../no_image.png"}
          alt="Manufacturer logo"
        />
      </Grid>
      <Grid item>
        <Grid container direction="column" alignItems="stretch" spacing={2}>
          {address && (
            <Grid item>
              <Typography>{address}</Typography>
            </Grid>
          )}
          {email && (
            <Grid item>
              <EmailLink value={email} />
            </Grid>
          )}
          {phone && (
            <Grid item>
              <PhoneLink value={phone} />
            </Grid>
          )}
        </Grid></Grid>

    </Grid>

  )
}

import React, { FC, PropsWithChildren } from 'react'
import Box from '@mui/material/Box'
import {
  HasSidebarLayout,
  DataLoad,
  ListHeader,
  PropsInjector,
  SensorList,
  SearchForm,
  SwitchView,
  ExportSensorLocationData,
} from 'src/components'
import { useSensorStatesRequest } from 'src/hooks/api'
import { HiddenMapProvider } from 'src/components/providers'
import { useQueryParamsState } from 'src/hooks/ui'

const SensorStateLoader: FC<PropsWithChildren> = ({ children }) => {
  const { data, isInitialLoading, isError } = useSensorStatesRequest()
  return (
    <DataLoad isLoading={isInitialLoading} isError={isError}>
      <PropsInjector props={{ states: data || [] }}>{children}</PropsInjector>
    </DataLoad>
  )
}

const SensorListPage: FC = () => {
  const queryParams = useQueryParamsState()
  const state = queryParams.state
  const isVisibleSwitchView: boolean = !['thingspace', 'diagnostics'].includes(state)
  document.title = 'Floorcloud Admin Panel - Sensors'
  return (
    <HasSidebarLayout>
      <ListHeader title="Sensors">
        <ExportSensorLocationData />
        {isVisibleSwitchView ? <SwitchView /> : null}
      </ListHeader>
      <HiddenMapProvider>
        <Box mb={2}>
          <SearchForm />
        </Box>
        <SensorStateLoader>
          <SensorList />
        </SensorStateLoader>
      </HiddenMapProvider>
    </HasSidebarLayout>
  )
}

export default SensorListPage

export class RapidRHPacketDecoder {
  private advPacket = ''

  constructor(advPacket: string) {
    this.advPacket = advPacket
  }

  transformRawDataToArray(): string[] {
    let byte = ''
    const dataArr: string[] = []
    for (let i = 1; i <= this.advPacket.length; i++) {
      byte += this.advPacket.charAt(i - 1)
      if (i % 2 === 0) {
        dataArr.push(byte)
        byte = ''
      }
    }
    return dataArr
  }

  private getValueFromRawData(
    data: string[],
    start: number,
    end: number,
  ): number {
    const reversed = data.slice(start, end).reverse()
    const hexString = reversed.join('')
    return parseInt(hexString, 16)
  }

  private hasServiceInfo(data: string[]): boolean {
    return !['BD', 'BC', '01'].includes(data[0])
  }

  getLastTemperatureF(data: string[]): number {
    const hasServiceInfo = this.hasServiceInfo(data)
    const start = hasServiceInfo ? 19 : 8
    const end = hasServiceInfo ? 21 : 10
    return this.getValueFromRawData(data, start, end) / 10
  }

  getLastHumidity(data: string[]): number {
    const hasServiceInfo = this.hasServiceInfo(data)
    const start = hasServiceInfo ? 17 : 6
    const end = hasServiceInfo ? 19 : 8
    return this.getValueFromRawData(data, start, end) / 10
  }

  getLastReadingTime(data: string[]): Date {
    const hasServiceInfo = this.hasServiceInfo(data)
    const start = hasServiceInfo ? 13 : 2
    const end = hasServiceInfo ? 17 : 6
    return new Date(this.getValueFromRawData(data, start, end) * 1000)
  }

  getSerialNumber(data: string[]): string {
    const hasServiceInfo = this.hasServiceInfo(data)
    const start = hasServiceInfo ? 21 : 10
    const end = hasServiceInfo ? 25 : 14
    const reversed = data.slice(start, end).reverse()
    const serial = reversed.join('')
    return serial
  }

  getRepeaterBatteryLevel(data: string[]): number | null {
    const deviceType = this.getDeviceType(data)
    if (deviceType === 'repeater') {
      const hasServiceInfo = this.hasServiceInfo(data)
      const start = hasServiceInfo ? 26 : 15
      const end = hasServiceInfo ? 27 : 16
      return this.getValueFromRawData(data, start, end) || null
    }
    return null
  }

  getDataGrabberBatteryLevel(data: string[]): number | null {
    const deviceType = this.getDeviceType(data)
    if (deviceType === 'repeater') {
      const hasServiceInfo = this.hasServiceInfo(data)
      const start = hasServiceInfo ? 25 : 14
      const end = hasServiceInfo ? 26 : 15
      return this.getValueFromRawData(data, start, end) || null
    }
    return null
  }

  getDeviceType(data: string[]): 'mini_grabber' | 'data_grabber' | 'repeater' | 'total_reader' {
    const hasServiceInfo = this.hasServiceInfo(data)
    const start = hasServiceInfo ? 11 : 0
    const end = hasServiceInfo ? 12 : 1
    const deviceCode = data.slice(start, end).join('')
    switch (deviceCode) {
    case 'BD':
      return 'mini_grabber'
    case 'BC':
      return 'data_grabber'
    case '01':
      return 'repeater'
    default:
      return 'total_reader'
    }
  }
}

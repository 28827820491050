import { useMutation, UseMutationOptions } from '@tanstack/react-query'
import { User } from 'src/api'
import { useApiInstance } from './useApiInstance'

interface ArchiveUserParams {
  userId: number;
}

interface ArchiveUserRequestParams {
  options?: Omit<
    UseMutationOptions<unknown, Error | null, ArchiveUserParams, User>,
    'mutationKey' | 'mutationFn'
  >;
}

export const useArchiveUserRequest = ({
  options,
}: ArchiveUserRequestParams = {}) => {
  const api = useApiInstance()
  return useMutation(
    ({ userId }: ArchiveUserParams) => api.v1AdminUsersUserIdArchivePost(userId),
    { ...options },
  )
}

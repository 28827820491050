import React, { FC } from 'react'
import { useLocation } from 'react-router-dom'
import Typography from '@mui/material/Typography'
import Badge, { BadgeProps } from '@mui/material/Badge'
import { styled } from '@mui/system'
import { InnerLink, InnerLinkProps } from '../ui/link'

const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
  '& .MuiBadge-badge': {
    right: -12,
    top: 5,
    border: `1px solid ${theme.palette.background.paper}`,
  },
}))

export interface SidebarLinkProps extends InnerLinkProps {
  title: string;
  badgeContent?: number;
  isVisibleBadge?: boolean;
  nestedElements?: SidebarLinkProps[];
}

export const SidebarLink: FC<SidebarLinkProps> = ({
  title,
  to,
  nestedElements,
  badgeContent = 0,
  isVisibleBadge = true,
}) => {
  const { pathname } = useLocation()
  const sameOrNestedRoute = pathname === to
    || (Array.isArray(nestedElements) && nestedElements.some((nested) => nested.to && pathname.includes(nested.to)))

  const linkTitle = (
    <StyledBadge
      badgeContent={badgeContent}
      invisible={!isVisibleBadge || !badgeContent}
      color="success"
    >
      <Typography
        color={sameOrNestedRoute ? 'rgba(255, 255, 255, 1)' : 'rgba(255, 255, 255, 0.6)'}
        component="span"
        fontSize="15px"
        lineHeight="22.5px"
        fontWeight="500"
        sx={{ cursor: 'pointer' }}
      >
        {title}
      </Typography>
    </StyledBadge>
  )

  const content = sameOrNestedRoute || !to ? (
    linkTitle
  ) : (
    <InnerLink to={to} sx={{ display: 'block' }}>{linkTitle}</InnerLink>
  )

  return content
}

import React, { FC, useState, useEffect, FocusEvent } from "react"
import { Box, List, ListItem, IconButton, Grid, Button, CircularProgress } from "@mui/material"
import DeleteIcon from "@mui/icons-material/Delete"
import { SimpleTextField } from 'src/components/ui'
import { useEditStagingDevicesRequest } from "src/hooks/api"
import { useAddPopupMessage } from "src/hooks/ui"

interface EditableStagingDevicesProps {
  stagingDevices: string[]
  onChange: () => void
  onCancel: () => void
}

export const EditableStagingDevices: FC<EditableStagingDevicesProps> = (props) => {
  const { stagingDevices, onCancel, onChange } = props
  const [imeis, setImeis] = useState<string[]>([])
  const [newImei, setNewImei] = useState<string>('')
  const editStagingDevicesRequest = useEditStagingDevicesRequest()
  const addMessage = useAddPopupMessage()

  useEffect(() => {
    setImeis(stagingDevices)
  }, [stagingDevices])

  const onDelete = (imei: string) => {
    setImeis(imeis.filter((item) => item !== imei))
  }

  const addNewImei = () => {
    setImeis([...imeis, newImei])
    setNewImei('')
  }

  const onBlurImei = (event: FocusEvent<HTMLInputElement>, index: number) => {
    const { value } = event.target
    const copy = [...imeis]
    copy[index] = value
    setImeis(copy)
  }

  const saveStagingDevices = () => {
    editStagingDevicesRequest.mutate({
      stagingDevices: imeis,
    }, {
      onSuccess: () => {
        addMessage({ text: 'List of staging devices succefully saved', type: 'success' })
        onChange()
      },
      onError: (error) => {
        addMessage({ text: error.message, type: 'error' })
      }
    })
  }

  return (
    <Box>
      <Grid container spacing={2} direction="column">
        <Grid item>
          <List dense>
            {imeis.map((imei, index) => (
              <ListItem
                key={imei}
                secondaryAction={
                  <IconButton
                    edge="end"
                    aria-label="delete"
                    onClick={() => onDelete(imei)}
                  >
                    <DeleteIcon />
                  </IconButton>
                }
              >
                <SimpleTextField
                  value={imei}
                  label="IMEI"
                  required
                  TextFieldProps={{ placeholder: "IMEI" }}
                  onBlur={(event) => onBlurImei(event, index)}
                />
              </ListItem>
            ))}
          </List>
        </Grid>
        <Grid container item spacing={2} alignItems="center">
          <Grid item flexGrow={1}>
            <SimpleTextField
              value={newImei}
              label="IMEI"
              TextFieldProps={{ placeholder: "Add new Staging device IMEI" }}
              onChange={(event) => setNewImei(event.target.value)}
            />
          </Grid>
          <Grid item>
            <Button
              variant="outlined"
              onClick={addNewImei}
            >
              Add
            </Button>
          </Grid>
        </Grid>
        <Grid item flexShrink={0}>
          <Grid container spacing={1}>
            <Grid item>
              <Box pt={2}>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={editStagingDevicesRequest.isLoading}
                  onClick={saveStagingDevices}
                >
                  {
                    editStagingDevicesRequest.isLoading ? <CircularProgress color="info" size={26} /> : 'Save'
                  }
                </Button>
              </Box>
            </Grid>
            {onCancel && <Grid item>
              <Box pt={2}>
                <Button
                  variant="outlined"
                  onClick={onCancel}
                >
                  Cancel
                </Button>
              </Box>
            </Grid>}
          </Grid>
        </Grid>
      </Grid>
    </Box>
  )
}

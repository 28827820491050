import React, { FC } from 'react'
import { Typography, DialogActions, Button, Box } from '@mui/material'
import { StatefulDialog } from 'src/components/ui'
import { DialogNames } from 'src/enums'

interface DeleteManufacturerModalProps {
  manufacturerName: string;
  onDelete: () => void;
  onCancel: () => void;
}

export const DeleteManufacturerModal: FC<DeleteManufacturerModalProps> = ({
  manufacturerName,
  onDelete,
  onCancel,
}) => {
  return (
    <StatefulDialog
      name={DialogNames.DeleteManufacturer}
      actions={
        <DialogActions>
          <Button variant="outlined" onClick={onCancel}>
            Cancel
          </Button>
          <Button variant="contained" color="error" onClick={onDelete}>
            Delete
          </Button>
        </DialogActions>
      }
    >
      <Box minWidth="300px">
        <Typography variant="h6">Are you sure you want to delete the manufacturer {manufacturerName}?</Typography>
      </Box>
    </StatefulDialog>
  )
}

import { useMutation, UseMutationOptions } from '@tanstack/react-query'
import { Entities } from 'src/enums'
import { ManufacturerAnnouncement, UpdateManufacturerAnnouncementPayload } from 'src/api'
import { useApiInstance } from './useApiInstance'

interface RequestParams {
  id: number;
  options?: Omit<
    UseMutationOptions<unknown, Error, UpdateManufacturerAnnouncementPayload, ManufacturerAnnouncement>,
    'mutationKey' | 'mutationFn'
  >;
}

export const useEditManufacturerAnnouncementRequest = ({ id, options }: RequestParams) => {
  const api = useApiInstance()

  return useMutation<unknown, Error, UpdateManufacturerAnnouncementPayload, ManufacturerAnnouncement>(
    [Entities.ManufacturerAnnouncement, id],
    async (payload: UpdateManufacturerAnnouncementPayload) => {
      const { data } = await api.v1AdminManufacturersAnnouncementsManufacturerAnnouncementIdPut(id, payload)
      return data
    },
    { ...options },
  )
}

import React, { FC } from 'react'
import { Grid, Typography } from '@mui/material'
import { ReportComponentList } from './reportComponentList'
import { CombinedReportEvents } from '../includedEvents'

export const CombinedReportSettings: FC = () => {
  return (
    <Grid container direction="column" spacing={4}>
      <Grid item>
        <Grid item sx={{ pb: 2 }}>
          <Grid item>
            <Typography variant="h6">Combined report components</Typography>
          </Grid>
        </Grid>
        <Grid item>
          <ReportComponentList />
        </Grid>
      </Grid>
      <Grid item>
        <Grid item sx={{ pb: 2 }}>
          <Grid item>
            <Typography variant="h6">Combined report events</Typography>
          </Grid>
        </Grid>
        <Grid item>
          <CombinedReportEvents />
        </Grid>
      </Grid>
    </Grid>
  )
}

import React, { FC } from "react"
import { DialogActions, Button, DialogTitle, Typography, CircularProgress } from "@mui/material"
import { Dialog } from "src/components/ui"
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import { ProductDraft } from "src/api"
import { ProductDraftStatusEnum } from "src/enums"

interface MergeProductsConfirmationModalProps {
  open: boolean;
  selectedProductDraft: ProductDraft | null;
  mergedProductDrafts: ProductDraft[];
  onSave: () => void;
  onCancel: () => void;
  isLoading: boolean;
}

export const MergeProductsConfirmationModal: FC<MergeProductsConfirmationModalProps> = ({
  open,
  onSave,
  onCancel,
  mergedProductDrafts,
  selectedProductDraft,
  isLoading,
}) => {
  return (
    <Dialog
      open={open}
      title={<DialogTitle>Merge products</DialogTitle>}
      scroll="paper"
      DialogProps={{ fullWidth: true, maxWidth: "md" }}
      actions={
        <DialogActions sx={{ paddingX: 3, paddingBottom: 3 }}>
          <Button variant="contained" size="large" onClick={onSave} disabled={isLoading}>
            {
              isLoading ? <CircularProgress color="info" size={26} /> : 'Confirm'
            }
          </Button>
          <Button variant="outlined" size="large" onClick={onCancel}>
            Cancel
          </Button>
        </DialogActions>
      }
    >
      <Typography variant="subtitle1">
        This change will affect real end users’ projects.
        You are going to replace the following products: 
      </Typography>
      <List>
        {mergedProductDrafts.map((productDraft) => (
          <ListItem key={productDraft.id}>
            <ListItemText
              primary={`${productDraft.original?.manufacturer?.name}, ${productDraft.name}`}
              sx={{
                fontStyle: "italic"
              }}
            />
          </ListItem>
        ))}
      </List>
      <Typography variant="subtitle1" mb={2}>
        with the <strong>{`${selectedProductDraft?.original?.manufacturer?.name}, ${selectedProductDraft?.name}`}</strong>.
      </Typography>
      {
        selectedProductDraft?.status === ProductDraftStatusEnum.Draft && (
          <Typography variant="subtitle1" mb={2}>
            <strong>{selectedProductDraft?.name}</strong> is currently a draft and will be published.
          </Typography>
        )
      }
      {
        selectedProductDraft?.status === ProductDraftStatusEnum.Updated && (
          <Typography variant="subtitle1" mb={2}>
            <strong>{selectedProductDraft?.name}</strong> has unpublished changes. These changes will be published.
          </Typography>
        )
      }
      <Typography variant="subtitle1">
        Do you want to continue?
      </Typography>
    </Dialog>
  )
}

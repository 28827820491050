import { useMutation, UseMutationOptions } from '@tanstack/react-query'
import { Entities } from 'src/enums'
import { RemoveForeman } from 'src/api'
import { useApiInstance } from './useApiInstance'

interface RequestParams {
  options?: Omit<UseMutationOptions<unknown, Error, RemoveForeman>, 'mutationKey' | 'mutationFn'>
}

export const useRemoveForemanRequest = ({
  options,
}: RequestParams) => {
  const api = useApiInstance()

  return useMutation<unknown, Error, RemoveForeman>(
    [Entities.Projects, 'deleteForeman'],
    async (payload: RemoveForeman) => {
      const { data } = await api.v1AdminUsersForemansRemoveFromProjectPost(payload)
      return data
    },
    { ...options },
  )
}

import { FC } from 'react'
import { GridColDef } from '@mui/x-data-grid-premium'
import { DataLoad } from 'src/components'
import { useEventListRequest } from 'src/hooks/api'
import { useQueryParams } from 'src/hooks/ui'
import { Event } from 'src/api'
import { EventColumns, EventList } from '../event'

interface UserEventListProps {
  userId: number;
}

const defaultPerPage = 50
const defaultPage = 1
const initialQueryParams = {
  page: `${defaultPage}`,
  rowsPerPage: `${defaultPerPage}`,
}
const columns: GridColDef<Event>[] = [
  EventColumns.Contractor,
  EventColumns.Project,
  EventColumns.Name,
  EventColumns.Type,
  EventColumns.Section,
  EventColumns.CreatedAt,
  EventColumns.Distance,
  EventColumns.Device,
]

export const UserEventList: FC<UserEventListProps> = ({
  userId,
}) => {
  const [queryParams, setQueryParams] = useQueryParams(initialQueryParams)
  const { isInitialLoading, isError, error, data } = useEventListRequest({
    params: {
      userId,
      ...queryParams,
    },
  })
  const exportedFileName = `${document.title}_events`

  return (
    <DataLoad isLoading={false} isError={isError} errorMessage={error?.message}>
      <EventList
        eventCount={data?.count || 0}
        events={data?.rows || []}
        isLoadingEvents={isInitialLoading}
        setQueryParams={setQueryParams}
        queryParams={queryParams}
        eventColumns={columns}
        exportedFileName={exportedFileName}
      />
    </DataLoad>
  )
}

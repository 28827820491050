import { useMutation, UseMutationOptions } from '@tanstack/react-query'
import { Entities } from 'src/enums'
import { AdminNotification, ManageAdminNotification } from 'src/api'
import { useApiInstance } from './useApiInstance'

interface RequestParams {
  options?: Omit<
  UseMutationOptions<unknown, Error, ManageAdminNotification, AdminNotification>,
    'queryKey' | 'queryFn'
  >;
}

export const useUserMeAdminNotificationRequest = ({
  options,
}: RequestParams) => {
  const api = useApiInstance()

  return useMutation<unknown, Error, ManageAdminNotification, AdminNotification>(
    [Entities.Users, 'adminNotificationsManage'],
    async (payload: ManageAdminNotification) => {
      const { data } = await api.v1AdminUsersAdminNotificationsManagePut(payload)
      return data
    },
    {
      ...options,
    },
  )
}

import React, { FC, useState, Fragment } from 'react'
import {
  Grid,
  Divider,
} from '@mui/material'
import { useCombinedReportComponentListRequest } from 'src/hooks/api'
import { DataLoad } from 'src/components/ui'
import { InlineReportComponent } from './inlineReportComponent'
import { EditableReportComponent } from './editableReportComponent'

export const ReportComponentList: FC = () => {
  const { data, refetch, isInitialLoading, isError, error } = useCombinedReportComponentListRequest()
  const [editId, setEditId] = useState<number | null>(null)
  const updateEditId = (id: number | null) => {
    setEditId(id)
  }

  const saveChanges = () => {
    updateEditId(null)
    refetch()
  }

  return (
    <DataLoad
      isLoading={isInitialLoading}
      isError={isError}
      errorMessage={error?.message}
    >
      <Grid container direction="column" spacing={2}>
        {(data || []).map(({ id, displayName, description, isVisible }, idx, list) => (
          <Fragment key={idx}>
            <Grid item container spacing={1} alignItems="center">
              {editId === id ? (
                <EditableReportComponent
                  id={id}
                  name={displayName || ''}
                  reportComponentDescription={description}
                  onChange={saveChanges}
                  onCancel={() => updateEditId(null)}
                />
              ) : (
                <InlineReportComponent
                  id={id as number}
                  name={displayName || ''}
                  description={description || ''}
                  isVisible={isVisible as boolean}
                  onEdit={updateEditId}
                />
              )}
            </Grid>
            {idx !== list.length - 1 && (
              <Divider flexItem={true} sx={{ ml: 2, mt: 2 }} />
            )}
          </Fragment>
        ))}
      </Grid>
    </DataLoad>
  )
}
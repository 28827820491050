import { FC } from 'react'
import { Box, Typography } from "@mui/material"
import { AdminMobileSettings } from 'src/api'
import { DataLoad } from 'src/components/ui'
import { useMobileSettingsRequest } from 'src/hooks/api/useMobileSettings'
import { MobileSettingsEdit } from 'src/components/forms/mobileSettings/mobileSettingsEdit'
import { useEditMobileSettingsRequest } from 'src/hooks/api/useEditMobileSettings'
import { useAddPopupMessage } from 'src/hooks/ui'

export const MobileSettingsParams: FC = () => {
  const {
    data: mobileSettings,
    isInitialLoading,
    isError,
    refetch: refetchMobileSettings
  } = useMobileSettingsRequest()
  const request = useEditMobileSettingsRequest()
  const addPopupMessage = useAddPopupMessage()
  
  const updateMobileSettings = (settings: AdminMobileSettings) => {
    request.mutate(
      settings,
      {
        onSuccess: async () => {
          addPopupMessage({ text: "The mobile settings was successfully updated", type: "success" })
          await refetchMobileSettings()
        },
        onError: ({ message: text }) => {
          addPopupMessage({ text, type: "error" })
        }
      }
    )
  }

  if (!mobileSettings) return null

  return (
    <DataLoad isLoading={isInitialLoading} isError={isError}>
      <Typography variant="h4">Mobile Settings</Typography>
      <Box width="500px" mt={2}>
        <MobileSettingsEdit
          mobileSettings={mobileSettings}
          onSubmit={updateMobileSettings}
          isLoading={request.isLoading}
        />
      </Box>
    </DataLoad>
  )
}

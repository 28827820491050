import { FC } from 'react'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import { ImportedProductItem } from 'src/api'
import { ImportedProduct } from './importedProduct'

type Props = {
  importedProducts: ImportedProductItem[]
}

export const ImportedProductList: FC<Props> = ({ importedProducts }) => {
  const sortedProducts = importedProducts.sort((a, b) => {
    const sortFieldA = a.manufacturerName || a.productName || ''
    const sortFieldB = b.manufacturerName || b.productName || ''
    return sortFieldA.localeCompare(sortFieldB)
  })

  return (
    <List sx={{ listStyleType: "disc", paddingLeft: '15px' }}>
      {
        sortedProducts.map((importedProduct) => (
          <ListItem key={importedProduct.productName} sx={{ display: 'list-item' }}>
            <ImportedProduct importedProduct={importedProduct} />
          </ListItem>
        ))
      }
    </List>
  )
}

import React, { FC } from 'react'
import { Grid } from '@mui/material'
import { useAddPopupMessage } from 'src/hooks/ui'
import { useEditReportTypeRequest } from 'src/hooks/api'
import { ReportTypeNewForm } from 'src/components/forms'

interface EditableReportTypeProps {
  id: number;
  name: string;
  reportTypeDescription?: string;
  onChange: () => void;
  onCancel: () => void;
}

export const EditableReportType: FC<EditableReportTypeProps> = ({
  id,
  name,
  reportTypeDescription,
  onChange,
  onCancel,
}) => {
  const addMessage = useAddPopupMessage()
  const editReportTypeRequest = useEditReportTypeRequest({ id })

  const update = ({ displayName, description }: { displayName: string, description?: string }) => {
    editReportTypeRequest.mutate(
      { displayName, description: description || null },
      {
        onSuccess: onChange,
        onError: (error) => addMessage({ type: 'error', text: error.message }),
      }
    )
  }

  return (
    <>
      <Grid item flexGrow={1}>
        <ReportTypeNewForm
          onSubmit={update}
          initialValues={{ displayName: name, description: reportTypeDescription }}
          onCancel={onCancel}
          isLoading={editReportTypeRequest.isLoading}
        />
      </Grid>
    </>
  )
}
import { TextFieldProps, Typography } from "@mui/material"
import React, { FocusEvent } from "react"
import MuiPhoneNumber from "material-ui-phone-number"
import { ErrorMessage, useField } from "formik"

interface PhoneInputProps {
  name: string;
  label?: string;
  placeholder?: string;
  required?: boolean;
  fullWidth?: boolean;
  variant?: TextFieldProps["variant"];
}

export const PhoneInput = ({
  name,
  fullWidth = true,
  variant = "outlined",
  ...props
}: PhoneInputProps) => {
  const [field, { error, touched }] = useField(name)

  const handleChange = (value: unknown) => {
    if (typeof value === "string") {
      field.onChange(field.name)(value || "")
    }
  }
  const handleBlur = (event: FocusEvent<HTMLInputElement>) => {
    field.onBlur(field.name)(event)
  }

  return (
    <>
      <MuiPhoneNumber
        {...props}
        defaultCountry="us"
        value={field.value}
        fullWidth={fullWidth}
        variant={variant}
        error={touched && !!error}
        disableAreaCodes
        onChange={handleChange}
        onBlur={handleBlur}
      />
      <ErrorMessage
        name={name}
        render={(schemeError: string) => (
          <Typography
            variant="caption"
            sx={{ color: "error.main", display: "block" }}
          >
            {error || schemeError}
          </Typography>
        )}
      />
    </>
  )
}

import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { Entities } from 'src/enums'
import { Sensor } from 'src/api'
import { useApiInstance } from './useApiInstance'

interface RequestParams {
  uniqueId: string;
  options?: Omit<
    UseQueryOptions<unknown, Error, Sensor>,
    'queryKey' | 'queryFn'
  >;
}

export const useSensorRequest = ({
  uniqueId,
  options,
}: RequestParams) => {
  const api = useApiInstance()

  return useQuery<unknown, Error, Sensor>(
    [Entities.Sensors, uniqueId],
    async () => {
      const { data } = await api.v1AdminSensorsUniqueIdGet(uniqueId)
      return data
    },
    {
      ...options,
    },
  )
}

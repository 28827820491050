import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { Entities } from 'src/enums'
import { CustomSingleEvent } from 'src/api'
import { useApiInstance } from './useApiInstance'

interface RequestParams {
  id: number;
  options?: Omit<
    UseQueryOptions<CustomSingleEvent, Error>,
    'queryKey' | 'queryFn'
  >;
}

export const useEventRequest = ({ id, options }: RequestParams) => {
  const api = useApiInstance()

  return useQuery<CustomSingleEvent, Error>(
    [Entities.Events, id],
    async () => {
      const { data } = await api.v1AdminEventsEventIdGet(id)
      return data as CustomSingleEvent
    },
    options,
  )
}

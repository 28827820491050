import React, { MouseEvent } from "react"
import { GridActionsCellItem, GridColDef, GridFilterItem, getGridStringOperators } from "@mui/x-data-grid-premium"
import format from "date-fns/format"
import { Typography, Box } from "@mui/material"
import MoreVertIcon from "@mui/icons-material/MoreVert"
import { ProductRequest, ProductRequestStatus } from "src/api"
import { EmailLink, InnerLink } from "src/components/ui"
import { ImagesListItem } from "src/pages/ProductRequest.list"
import { StatusEditInputCell } from "./editStatus"
import { StatusInlineCell } from "./inlineStatus"
import { CommentInlineCell } from "./inlineComment"
import { RequestInlineCell } from "./inlineRequest"
import { SummaryStatusInlineCell } from "./inlineSummaryStatus"

export const productRequestTableColumns = ({
  statuses,
  statusDropdownItemListMap,
  handleOpenMenu,
  openGallery
}: {
  statuses: ProductRequestStatus[],
  statusDropdownItemListMap: any,
  handleOpenMenu: (
    event: MouseEvent<HTMLElement>,
    row: ProductRequest
  ) => void
  openGallery: (images: ImagesListItem[]) => void
}): GridColDef<ProductRequest>[] => {
  return [
    {
      field: "author.name",
      headerName: "User name",
      type: "string",
      minWidth: 130,
      editable: false,
      valueGetter: (params) => params.row?.author?.name || null,
      renderCell: ({ value, row }) => {
        if (!value) return '-'
        return <InnerLink to={`/users/${row.author?.id}`}>{value}</InnerLink>
      }
    },
    {
      field: "author.email",
      headerName: "User email",
      type: "string",
      minWidth: 100,
      editable: false,
      valueGetter: (params) => params.row?.author?.email || null,
      renderCell: ({ value }) => {
        if (!value) return '-'
        return <EmailLink value={value}>{value}</EmailLink>
      }
    },
    {
      field: "contractor.name",
      headerName: "Contractor",
      type: "string",
      minWidth: 150,
      editable: false,
      valueGetter: (params) => params.row?.contractor?.name || null,
      renderCell: ({ row, value }) => {
        if (!value) return '-'
        return <InnerLink to={`/contractors/${row.contractor?.id}`}>{value}</InnerLink>
      }
    },
    {
      field: "project.name",
      headerName: "Project",
      type: "string",
      minWidth: 150,
      editable: false,
      valueGetter: (params) => params.row?.project?.name || null,
      renderCell: ({ row, value }) => {
        if (!value) return '-'
        return <InnerLink to={`/projects/${row.project?.id}`}>{value}</InnerLink>
      }
    },
    {
      field: "request",
      headerName: "Request",
      type: "string",
      minWidth: 200,
      editable: false,
      renderCell: (params) => (
        <RequestInlineCell { ...params } />
      ),
    },
    {
      field: "photo",
      headerName: "Photo",
      type: "string",
      minWidth: 150,
      editable: false,
      valueGetter: ({ row }) => {
        const photoAmount = row.attachments?.length || 0
        return `${photoAmount} photo`
      },
      renderCell: ({ value, row }) => {
        const photoAmount = row.attachments?.length || 0
        if (!row.attachments || photoAmount === 0) {
          return <Typography variant="body2">-</Typography>
        }

        const images: ImagesListItem[] = row.attachments.map((attach) => ({ src: attach.link || '' }))
        return (
          <Box onClick={() => openGallery(images)}>
            <Typography
              variant="body2"
              color='#037BEB'
              sx={{ cursor: 'pointer' }}
            >
              {value}
            </Typography>
          </Box>
        )
      },
    },
    {
      field: "createdAt",
      headerName: "Datetime",
      type: "string",
      valueGetter: (params) => params.row?.createdAt || null,
      valueFormatter: ({ value }) => value ? format(new Date(value), "PPpp") : "-",
      minWidth: 180,
      editable: false,
    },
    {
      field: "statusId",
      headerName: "Status",
      type: "string",
      minWidth: 150,
      editable: true,
      renderCell: (params) => (
        <StatusInlineCell { ...params } statuses={statuses} />
      ),
      renderEditCell: (params) => (
        <StatusEditInputCell { ...params } statusDropdownItemListMap={statusDropdownItemListMap} statuses={statuses} />
      ),
      filterOperators: getGridStringOperators().map((operator) => ({
        ...operator,
        getApplyFilterFn: (filterItem: GridFilterItem) => {
          if (!filterItem.field || !filterItem.value || !filterItem.operator) {
            return null
          }
          return (params): boolean => {
            const status = statuses.find((_status) => _status.id === params.value)
            if (!status) {
              return filterItem.operator === 'isEmpty'
            }

            if (filterItem.operator === 'isNotEmpty') {
              return true
            } else if (filterItem.operator === 'contains') {
              return status.displayName.toLowerCase().includes(filterItem.value.toLowerCase())
            } else if (filterItem.operator === 'equals') {
              return status.displayName.toLowerCase() === filterItem.value.toLowerCase()
            } else if (filterItem.operator === 'startsWith') {
              return status.displayName.toLowerCase().startsWith(filterItem.value.toLowerCase())
            } else if (filterItem.operator === 'endsWith') {
              return status.displayName.toLowerCase().endsWith(filterItem.value.toLowerCase())
            }
            return status.displayName.toLowerCase().includes(filterItem.value.toLowerCase())
          }
        }
      }))
    },
    {
      field: "doneResponseStatus",
      headerName: "Summary status",
      type: "string",
      minWidth: 100,
      editable: false,
      renderCell: (params) => (
        <SummaryStatusInlineCell { ...params } />
      ),
    },
    {
      field: "comment",
      headerName: "Internal comment",
      type: "string",
      minWidth: 180,
      editable: true,
      renderCell: (params) => (
        <CommentInlineCell { ...params } />
      ),
    },
    {
      field: "actions",
      headerName: "",
      type: "actions",
      width: 30,
      getActions: ({ row }) => [
        <GridActionsCellItem
          icon={<MoreVertIcon />}
          label="Actions"
          className="textPrimary"
          onClick={(e: any) => handleOpenMenu(e, row)}
          color="inherit"
        />,
      ],
    },
  ]
}

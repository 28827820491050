import React, { FC, forwardRef } from "react"
import NumberFormat from "react-number-format"

interface FloatFormatCustomProps {
    name: string;
    inputRef?: (instance: NumberFormat | null) => void;
    onChange: (event: {
        target: { value: number | undefined; name: string };
    }) => void;
}

export const FloatTextField: FC<FloatFormatCustomProps> = forwardRef(
  (props, ref) => {
    const { onChange, ...other } = props
    return (
      <NumberFormat
        {...other}
        decimalSeparator='.'
        decimalScale={2}
        allowLeadingZeros
        getInputRef={ref}
        onValueChange={(values) => {
          onChange({
            target: {
              value: values.value
                ? parseFloat(Number(values.value).toFixed(2)) || undefined
                : undefined,
              name: other.name,
            },
          })
        }}
        isNumericString
      />
    )
  }
)
import { useMutation, UseMutationOptions } from '@tanstack/react-query'
import { Entities } from 'src/enums'
import { ManufacturerAnnouncementCategory, CreateManufacturerAnnouncementCategoryPayload } from 'src/api'
import { useApiInstance } from './useApiInstance'

interface RequestParams {
  options?: Omit<
    UseMutationOptions<unknown, Error, CreateManufacturerAnnouncementCategoryPayload, ManufacturerAnnouncementCategory>,
    'mutationKey' | 'mutationFn'
  >;
}

export const useNewManufacturerAnnouncementCategoryRequest = ({ options }: RequestParams = {}) => {
  const api = useApiInstance()

  return useMutation<unknown, Error, CreateManufacturerAnnouncementCategoryPayload, ManufacturerAnnouncementCategory>(
    [Entities.ManufacturerAnnouncementCategory, 'new'],
    async (payload: CreateManufacturerAnnouncementCategoryPayload) => {
      const { data } = await api.v1AdminManufacturersAnnouncementsCategoriesPost(payload)
      return data
    },
    { ...options },
  )
}

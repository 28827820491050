import { FC, useState } from "react"
import { Box } from "@mui/material"
import { useNavigate, useParams } from "react-router-dom"
import { HasSidebarLayout, EntityHeader, DataLoad } from "src/components"
import {
  useEditManufacturerRequest,
  useManufacturerRequest,
} from "src/hooks/api"
import { useAddPopupMessage } from "src/hooks/ui"
import {
  ManufacturerEditForm,
  ManufacturerEditState,
} from "src/components/forms/manufacturer/edit"

const EditManufacturer: FC = () => {
  const addMessage = useAddPopupMessage()
  const params  = useParams()
  const { id: manufacturerId } = params as { id: string }
  const id = parseInt(manufacturerId, 10)
  const { data, isInitialLoading, isError } = useManufacturerRequest({ id })
  document.title = `Floorcloud Admin Panel - Edit manufacturer ${data?.name || ''}`
  const navigate = useNavigate()

  const request = useEditManufacturerRequest({
    id,
    options: {
      onSuccess: () => {
        navigate(-1)
      },
      onError: ({ message: text }) => {
        addMessage({ text, type: "error" })
      },
    },
  })

  const onSubmit = async (submitData: ManufacturerEditState, photo: File | null) => {
    if (photo) {
      request.mutate({ ...submitData, photos: [photo] })
    } else {
      request.mutate({ ...submitData, photos: isRemoved ? null : undefined})
    }
  }

  const initialValues: ManufacturerEditState | null =
    !isInitialLoading && !isError && data
      ? {
        name: data?.name,
        address1: data?.address1 || void 0,
        phone: data?.phone || void 0,
        email: data?.email || void 0,
        isVerified: data?.isVerified || false,
        link: data?.photo || undefined
      }
      : null


  const [isRemoved, setIsRemoved] = useState<boolean>(false)

  return (
    <HasSidebarLayout>
      <EntityHeader title="Edit Manufacturer" onBack={() => navigate(-1)} />
      <DataLoad isLoading={isInitialLoading} isError={isError}>
        <Box width="800px" mt={2}>
          <ManufacturerEditForm
            initialValues={initialValues}
            onSubmit={onSubmit}
            setIsRemoved={setIsRemoved}
            isLoading={request.isLoading}
          />
        </Box>
      </DataLoad>
    </HasSidebarLayout>
  )
}

export default EditManufacturer

import React, { FC } from 'react'
import { Box, Typography } from "@mui/material"
import { SensorConfiguration } from 'src/api'
import { useSensorDefaultConfigurationRequest, useEditSensorDefaultConfigurationRequest } from 'src/hooks/api'
import { DataLoad } from 'src/components/ui'
import { SensorConfigurationEdit } from 'src/components/forms'
import { useAddPopupMessage } from 'src/hooks/ui'

export const SensorParams: FC = () => {
  const {
    data: sensorDefaultConfiguration,
    isInitialLoading,
    isError,
    refetch: refetchDefaultConfiguration
  } = useSensorDefaultConfigurationRequest()
  const request = useEditSensorDefaultConfigurationRequest()
  const addPopupMessage = useAddPopupMessage()
  
  const updateDefaultConfiguration = (configuration: SensorConfiguration) => {
    request.mutate(
      configuration,
      {
        onSuccess: async () => {
          addPopupMessage({ text: "The sensor default configuration was successfully updated", type: "success" })
          await refetchDefaultConfiguration()
        },
        onError: ({ message: text }) => {
          addPopupMessage({ text, type: "error" })
        }
      }
    )
  }

  if (!sensorDefaultConfiguration) return null

  return (
    <DataLoad isLoading={isInitialLoading} isError={isError}>
      <Typography variant="h4">Sensor Default Configuration</Typography>
      <Box width="500px" mt={2}>
        <SensorConfigurationEdit
          sensorConfiguration={sensorDefaultConfiguration}
          onSubmit={updateDefaultConfiguration}
          isLoading={request.isLoading}
        />
      </Box>
    </DataLoad>
  )
}

import { Box, Grid, Typography } from "@mui/material"
import {
  GridColDef,
} from "@mui/x-data-grid-premium"
import format from "date-fns/format"
import { Fragment } from "react"
import { Project } from "src/api"
import { ClosedEye } from "src/components/icons"
import { InnerLink } from "src/components/ui"
import { SensorStateList } from "src/enums"

export namespace ProjectColumnsDataGrid {
    export const Name: GridColDef<Project> = {
      field: 'name',
      headerName: 'Name',
      type: 'string',
      minWidth: 200,
      renderCell: ({ row }) => {
        return <InnerLink to={`/projects/${row.id}`}>{row.name}</InnerLink>
      },
    }
    export const NameWithIcon: GridColDef<Project> = {
      field: "name",
      headerName: "Name",
      type: "string",
      minWidth: 220,
      renderCell: ({ row: { id, name, isActive } }) => {
        const isArchived = !isActive
        return (
          <Grid container alignItems="center" spacing={1}>
            <Grid item>
              <InnerLink to={`/projects/${id}`}>{name}</InnerLink>
            </Grid>
            {isArchived && (
              <Grid container item title="Archived project" xs={2}>
                <ClosedEye />
              </Grid>
            )}
          </Grid>
        )
      },
    }
    export const Location: GridColDef<Project> = {
      field: 'fullAddress',
      headerName: 'Location',
      type: 'string',
      minWidth: 350,
    }
    export const ContractorName: GridColDef<Project> = {
      field: 'contractor.name',
      headerName: 'Contractor',
      type: 'string',
      minWidth: 250,
      valueGetter: ({row}) => row.contractor?.name || null,
      renderCell: ({ row }) => {
        if (row?.contractor) {
          const contractorId = row?.contractor.id
          const contractorName = row?.contractor.name
          return <InnerLink to={`/contractors/${contractorId}`}>{contractorName}</InnerLink>
        }
        return null
      },
    }
    export const UserName: GridColDef<Project> = {
      field: 'users',
      headerName: 'User',
      type: 'string',
      editable: false,
      minWidth: 200,
      valueGetter: ({ row: { users } }) => {
        if (!users || users.length === 0) {
          return "-"
        }
        return users.map((user) => user.name).join(',')
      },
      renderCell: ({ row: { users } }) => {
        if (!users || users.length === 0)
          return <Typography variant="body2">-</Typography>
        else
          return (
            <Box>
              {users.map(({ id, name }, idx) => (
                <Fragment key={id}>
                  <InnerLink to={`/users/${id}`}>{name}</InnerLink>
                  {idx !== users.length - 1 && <br />}
                </Fragment>
              ))}
            </Box>
          )
      },
    }
    export const ProjectTypeName: GridColDef<Project> = {
      field: 'projectType.displayName',
      valueGetter: ({row}) => row.projectType?.displayName || null,
      headerName: 'Type',
      type: 'string',
    }
    export const Size: GridColDef<Project> = {
      field: 'area',
      headerName: 'Size',
      type: 'digit',
    }

    export const ConnectedSensors: GridColDef<Project> = {
      field: 'sections.sensors',
      headerName: 'Sensors Connected',
      type: 'number',
      align: 'left',
      valueGetter: ({row}) => {
        const list = []
        for (const section of row.sections || []) {
          for (const sensor of section.sensors || []) {
            const { projectSectionsSensors = [] } = sensor
            const index = projectSectionsSensors.findIndex(
              (el) => el.projectId === row.id && el.isActive
            )
            if (index !== -1 && sensor.state?.name === SensorStateList.InUse)
              list.push(sensor.uniqueId)
          }
        }
        const count = list.filter(
          (uid, index, arr) => arr.indexOf(uid) === index
        ).length
        return count
      },
      renderCell: ({ row }) => {
        const list = []
        for (const section of row.sections || []) {
          for (const sensor of section.sensors || []) {
            const { projectSectionsSensors = [] } = sensor
            const index = projectSectionsSensors.findIndex(
              (el) => el.projectId === row.id && el.isActive
            )
            if (index !== -1 && sensor.state?.name === SensorStateList.InUse)
              list.push(sensor.uniqueId)
          }
        }
        const count = list.filter(
          (uid, index, arr) => arr.indexOf(uid) === index
        ).length
        return <Typography variant="body2">{count}</Typography>
      },
    }

    export const CreatedAt: GridColDef<Project> = {
      field: 'createdAt',
      headerName: 'Created At',
      type: 'string',
      minWidth: 200,
      valueFormatter: ({ value }) => value ? format(new Date(value), 'PPpp') : null,
    }
}
import { useEffect } from 'react'
import { useSignInRedirect } from 'src/hooks/ui'

export const SignInRedirect = () => {
  const redirect = useSignInRedirect()
  useEffect(() => {
    redirect()
  })
  return null
}

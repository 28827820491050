import React, { useContext, useEffect, useRef, useState } from "react"
import { Product, ProductDraft } from "src/api"
import {
  DataGridPremium,
  GridCellParams,
  useGridApiRef,
  gridFilterModelSelector,
  gridFilteredTopLevelRowCountSelector,
  GridPaginationModel,
  GridPinnedColumns,
  GRID_CHECKBOX_SELECTION_COL_DEF,
} from '@mui/x-data-grid-premium'
import { Menu, MenuItem, Paper } from "@mui/material"
import { useAddPopupMessage, useQueryParams } from "src/hooks/ui"
import { SearchParamsKeys } from "src/enums"
import { TableToolbar } from "src/components/ui"
import { defaultRowsValuePerPage } from "src/config"
import { useTableColumns } from "./useTableColumns"
import {
  EditProductTipsModal,
  EditProductTipsState,
} from "../../../forms/productDraft/editProductTipsModal"
import { EditProductCommentModal } from "../editProductCommentModal"
import { EditProductManufacturerCommentModal } from "../editProductManufacturerCommentModal"
import { ProductDraftsContext } from "../../../../contexts/productDraftsContext"
import { ProductPublishConfirmationModal } from "../productPublishConfirmationModal"
import { ProductDeleteConfirmationModal } from '../productDeleteConfirmationModal'

const defaultPerPage = 200
const defaultPage = 1
const defaultRowsPerPageOptions = defaultRowsValuePerPage

const initialQueryParams = {
  page: `${defaultPage}`,
  rowsPerPage: `${defaultPerPage}`,
}

export const ProductDraftListTable = () => {
  const {
    currentManufacturer,
    productDrafts,
    productDraftsCount,
    isProductDraftsLoading,
    selectedProductDraftIds,
    setSelectedProductDraftIds,
    publishProductDrafts,
    editProductDrafts,
    deleteProductDraft,
  } = useContext(ProductDraftsContext)
  const addPopupMessage = useAddPopupMessage()

  const [params, setParams] = useQueryParams(initialQueryParams)

  const [rowCountState, setRowCountState] = React.useState(productDraftsCount)
  const [currentRow, setCurrentRow] = useState<ProductDraft | null>(null)
  const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLElement | null>(null)

  const [updating, setUpdating] = useState(false)
  const [editTipsDialogOpened, setEditTipsDialogOpened] = useState(false)
  const [editCommentDialogOpened, setEditCommentDialogOpened] = useState(false)
  const [editManufacturerCommentDialogOpened, setEditManufacturerCommentDialogOpened] = useState(false)
  const [confirmationModalOpened, setConfirmationModalOpened] = useState(false)
  const [deletingModalOpened, setDeletingModalOpened] = useState(false)

  const [pinnedColumns, setPinnedColumns] = React.useState<GridPinnedColumns>({
    left: [GRID_CHECKBOX_SELECTION_COL_DEF.field, "name"],
    right: ["actions"],
  })

  const fieldToEdit = useRef<string | null>(null)
  const apiRef = useGridApiRef()

  useEffect(() => {
    setRowCountState((prevRowCountState) =>
      productDraftsCount !== undefined ? productDraftsCount : prevRowCountState
    )
  }, [productDraftsCount, setRowCountState])

  const handleChangePaginationModel = async (pagination: GridPaginationModel): Promise<void> => {
    const { page, pageSize } = pagination
    const newPage = page + 1
    setParams({
      [SearchParamsKeys.Page]: `${newPage}`,
      [SearchParamsKeys.RowsPerPage]: `${pageSize}`,
    }, [
      SearchParamsKeys.Page,
      SearchParamsKeys.RowsPerPage,
    ])
  }

  const handlePinnedColumnsChange = React.useCallback(
    (updatedPinnedColumns: GridPinnedColumns) => {
      setPinnedColumns(updatedPinnedColumns)
    },
    []
  )

  const handleOpenMenu = (
    event: React.MouseEvent<HTMLElement>,
    row: ProductDraft
  ) => {
    setCurrentRow(row)
    setMenuAnchorEl(event.currentTarget)
  }
  const handleCloseMenu = () => {
    setCurrentRow(null)
    setMenuAnchorEl(null)
  }

  const handleEditTips = () => {
    setEditTipsDialogOpened(true)
  }

  const deleteProduct = () => {
    setDeletingModalOpened(true)
  }

  const columns = useTableColumns(handleOpenMenu)

  const page = params["page"] ? parseInt(params["page"], 10) - 1 : defaultPage - 1
  const pageSize = params["rowsPerPage"]
    ? parseInt(params["rowsPerPage"], 10)
    : defaultPerPage

  const handleSaveProductTips = (data: EditProductTipsState) => {
    editProductDrafts([data], true)
    handleCloseMenu()
    setEditTipsDialogOpened(false)
  }

  const handleCloseProductTips = () => {
    handleCloseMenu()
    setEditTipsDialogOpened(false)
  }

  const handlePublishProduct = () => {
    setConfirmationModalOpened(true)
  }

  const handleConfirmPublishProduct = () => {
    if (currentRow) {
      publishProductDrafts([currentRow.id])
    }
    handleCloseMenu()
    setConfirmationModalOpened(false)
  }

  const handleRejectPublishProduct = () => {
    setConfirmationModalOpened(false)
  }

  const handleConfrmDeletingProduct = () => {
    if (currentRow) {
      deleteProductDraft(currentRow.id)
    }
    handleCloseMenu()
    setDeletingModalOpened(false)
  }
  const handleRejectDeletingProduct = () => {
    setDeletingModalOpened(false)
    handleCloseMenu()
  }

  const handleChangeState = async () => {
    const filterModel = gridFilterModelSelector(apiRef.current.state)
    if (filterModel.items.length > 0 && filterModel.items[0].value) {
      const visibleRowCount = gridFilteredTopLevelRowCountSelector(apiRef.current.state)
      if (visibleRowCount) setRowCountState(visibleRowCount)
    }
    else setRowCountState(productDraftsCount)
  }

  const handleSaveProductComment = ({
    id,
    comment,
  }: {
    id: number;
    comment: string;
  }) => {
    editProductDrafts([{ id, comment }], true)

    setEditCommentDialogOpened(false)
  }

  const handleSaveProductManufacturerComment = ({
    id,
    comment,
  }: {
    id: number;
    comment: string;
  }) => {
    editProductDrafts([{ id, manufacturerComment: comment }], true)

    setEditManufacturerCommentDialogOpened(false)
  }

  const processRowUpdate = async (
    newRow: ProductDraft,
    oldRow: ProductDraft
  ) => {
    const field = fieldToEdit.current

    const isValidField = (x: string): x is keyof ProductDraft => {
      return newRow.hasOwnProperty(x) && oldRow.hasOwnProperty(x)
    }

    if (!field || !isValidField(field) || newRow[field] === oldRow[field]) {
      return oldRow
    }
    setUpdating(true)
    const result = await editProductDrafts([
      {
        id: newRow.id,
        [field]: newRow[field],
      },
    ], true)
    setUpdating(false)

    return result.length > 0 ? result[0] : oldRow
  }

  if (!currentManufacturer) {
    return null
  }

  return (
    <>
      <Paper sx={{ flexGrow: 1, minHeight: '200px', width: '100%' }}>
        <DataGridPremium<ProductDraft>
          apiRef={apiRef}
          pagination
          pinnedColumns={pinnedColumns}
          checkboxSelection
          rows={productDrafts}
          columns={columns}
          rowCount={rowCountState}
          loading={isProductDraftsLoading}
          onRowSelectionModelChange={setSelectedProductDraftIds}
          rowSelectionModel={selectedProductDraftIds}
          paginationModel={{
            page: page as number,
            pageSize: pageSize as number
          }}
          rowThreshold={2}
          columnThreshold={2}
          paginationMode="server"
          pageSizeOptions={defaultRowsPerPageOptions}
          onPaginationModelChange={handleChangePaginationModel}
          onStateChange={handleChangeState}
          isCellEditable={() => !updating}
          localeText={{
            columnMenuSortAsc: "Sort A-Z",
            columnMenuSortDesc: "Sort Z-A",
          }}
          slots={{
            toolbar: TableToolbar
          }}
          getCellClassName={({ field, row }) => {
            const original = row.original

            const isProductDraftKey = (x: string): x is keyof ProductDraft => {
              return row.hasOwnProperty(x)
            }

            const isProductKey = (x: string): x is keyof Product => {
              return original?.hasOwnProperty(x) || false
            }

            const hasChanges =
              original &&
              isProductKey(field) &&
              isProductDraftKey(field) &&
              row[field] !== original[field]

            if (!["status", "comment", "createdAt"].includes(field) && hasChanges) {
              return "MuiDataGrid-cell--changed"
            }
            return ""
          }}
          onCellClick={(_params: GridCellParams) => {
            if (_params.field === "comment") {
              setCurrentRow(_params.row)
              setEditCommentDialogOpened(true)
            } else if (_params.field === "manufacturerComment") {
              setCurrentRow(_params.row)
              setEditManufacturerCommentDialogOpened(true)
            } else if (
              _params.field === "safetyTipType" ||
              _params.field === "commonTipType"
            ) {
              setCurrentRow(_params.row)
              setEditTipsDialogOpened(true)
            }
          }}
          sx={(theme) => ({
            border: "none",
            "& .MuiDataGrid-columnHeaderTitleContainerContent": {
              paddingLeft: "5px",
              overflow: "visible",
            },
            "& .MuiDataGrid-columnHeaderTitle": {
              whiteSpace: "normal",
              lineHeight: 1,
              fontSize: "13px",
              fontWeight: 400,
              color: "rgba(0, 0, 0, 0.6)",
              overflow: "visible",
            },
            "& .MuiDataGrid-cell--changed": {
              backgroundColor: "#FFEBDF",
            },
            "&.MuiDataGrid-root .MuiDataGrid-cell:focus": {
              outline: "none",
            },
            "& .MuiDataGrid-cell.MuiDataGrid-cell--editing": {
              padding: 0,
              border: "none",
              outline: "none",
              "&.MuiDataGrid-cell--error": {
                outlineColor: theme.palette.error.main,
              },
            },
          })}
          onCellEditStart={(_params, event) => {
            if (event.type !== "dblclick") {
              event.defaultMuiPrevented = true
              return
            }
            fieldToEdit.current = _params.field
          }}
          processRowUpdate={processRowUpdate}
          onProcessRowUpdateError={(error) => {
            if (error) addPopupMessage({ text: error, type: "error" })
          }}
          onPinnedColumnsChange={handlePinnedColumnsChange}
        />
      </Paper>
      <Menu
        anchorEl={menuAnchorEl}
        open={!!menuAnchorEl}
        onClose={handleCloseMenu}
      >
        {currentRow?.status !== "published" && (
          <MenuItem onClick={handlePublishProduct}>
            Publish product to DB
          </MenuItem>
        )}
        <MenuItem onClick={handleEditTips}>Edit tips</MenuItem>
        <MenuItem onClick={deleteProduct}>Delete</MenuItem>
      </Menu>
      <EditProductTipsModal
        open={editTipsDialogOpened}
        productDraft={currentRow}
        onSave={handleSaveProductTips}
        onCancel={handleCloseProductTips}
      />
      <EditProductCommentModal
        open={editCommentDialogOpened}
        productDraft={currentRow}
        onSave={handleSaveProductComment}
        onCancel={() => setEditCommentDialogOpened(false)}
      />
      <EditProductManufacturerCommentModal
        open={editManufacturerCommentDialogOpened}
        productDraft={currentRow}
        onSave={handleSaveProductManufacturerComment}
        onCancel={() => setEditManufacturerCommentDialogOpened(false)}
      />
      <ProductPublishConfirmationModal
        open={confirmationModalOpened}
        productsCount={1}
        onSave={handleConfirmPublishProduct}
        onCancel={handleRejectPublishProduct}
      />
      <ProductDeleteConfirmationModal
        open={deletingModalOpened}
        productDraftName={currentRow?.name || ''}
        onDelete={handleConfrmDeletingProduct}
        onCancel={handleRejectDeletingProduct}
      />
    </>
  )
}

import React, { FC, PropsWithChildren } from 'react'
import Box from '@mui/material/Box'
import { Sensor } from 'src/api'
import { DataLoad, PropsInjector } from 'src/components'
import { SensorColumnsDataGrid } from 'src/components/columns/sensor'
import { useSensorListRequest } from 'src/hooks/api'
import { SensorButtons } from 'src/enums'
import { useQueryParamsState } from 'src/hooks/ui'
import { CommonSensorList } from '../sensor/sensorList/commonSensorsList'

const columns = [
  SensorColumnsDataGrid.Id,
  SensorColumnsDataGrid.IMEI,
  SensorColumnsDataGrid.DevEUI,
  SensorColumnsDataGrid.Model,
  SensorColumnsDataGrid.ProjectSection,
  SensorColumnsDataGrid.IsActive,
  SensorColumnsDataGrid.DateAddedToProject,
  SensorColumnsDataGrid.DateDisconnectedFromProject,
  SensorColumnsDataGrid.LastPing,
  SensorColumnsDataGrid.BatteryLevel,
  SensorColumnsDataGrid.TimeOnProject,
  SensorColumnsDataGrid.Status,
  SensorColumnsDataGrid.EnabledBluetooth,
]

interface ProjectSensorsTableProps {
  data?: Sensor[];
  isLoading?: boolean;
  count?: number;
}

const ProjectSensorsTable: FC<ProjectSensorsTableProps> = ({
  data = [],
  isLoading = false,
  count = 0,
}) => {
  const exportedFileName = `${document.title}_sensors`
  return (
    <Box height="500px">
      <CommonSensorList
        columns={columns}
        count={count}
        data={data}
        isLoading={isLoading}
        exportedFileName={exportedFileName}
        buttons={[SensorButtons.ManageSensorBleState]}
        checkboxSelection
      />
    </Box>
  )
}

interface ProjectSensorsLoaderProps {
  projectId: number;
}

const ProjectSensorsLoader: FC<PropsWithChildren<ProjectSensorsLoaderProps>> = ({
  projectId,
  children,
}) => {
  const params = useQueryParamsState()
  const { data, error, isInitialLoading, isError } = useSensorListRequest({
    params: { projectId, ...params },
  })

  const props = {
    isLoading: isInitialLoading,
    data: data?.rows || [],
    count: data?.count || 0,
  }

  return (
    <DataLoad isLoading={false} isError={isError} errorMessage={error?.message}>
      <PropsInjector props={props}>{children}</PropsInjector>
    </DataLoad>
  )
}

interface ProjectSensorsProps {
  projectId: number;
}

export const ProjectSensors: FC<ProjectSensorsProps> = ({ projectId }) => {
  return (
    <ProjectSensorsLoader projectId={projectId}>
      <ProjectSensorsTable />
    </ProjectSensorsLoader>
  )
}

import React, { FC } from "react"
import { Grid, Typography } from "@mui/material"
import {
  useProductsTipTypesRequest,
  useProductTypeListRequest,
} from "src/hooks/api"
import { DataLoad } from "src/components/ui"
import { NewProductType } from "./newProductType"
import { ProductTypeList } from "./productTypeList"

export const LoadableProductTypeList: FC = () => {
  const {
    data: productTypesList,
    error,
    isInitialLoading,
    isError,
    refetch,
  } = useProductTypeListRequest({
    params: { limit: 9999, withTips: "true" },
  })

  const { data: productTipTypes } = useProductsTipTypesRequest({
    params: { limit: 99 },
  })

  return (
    <Grid container direction="column" spacing={4}>
      <Grid item container direction="column">
        <Grid item>
          <Typography variant="h6" sx={{ pt: 2 }}>
            Create New Product Category
          </Typography>
        </Grid>
        <Grid item>
          <NewProductType onCreate={refetch} />
        </Grid>
      </Grid>
      <Grid item>
        <Grid item sx={{ pb: 2 }}>
          <Grid item>
            <Typography variant="h6">Product Category List</Typography>
          </Grid>
          <Grid>
            <Typography color="gray">
              Forbidden to edit or delete the product category if it used in any
              product
            </Typography>
          </Grid>
        </Grid>
        <Grid item>
          <DataLoad
            isLoading={isInitialLoading}
            isError={isError}
            errorMessage={error?.message}
          >
            <ProductTypeList
              productTypes={productTypesList?.rows || []}
              productTipTypes={productTipTypes?.rows || []}
              onChange={refetch}
            />
          </DataLoad>
        </Grid>
      </Grid>
    </Grid>
  )
}

import { useState } from 'react'
import { renderToString } from 'react-dom/server'
import { useAddPopupMessage } from './useAddPopupMessage'
import SensorLabel from '../../components/icons/sensor-label'

export const useSensorLabel = () => {
  const [generatingLabel, setGeneratingLabel] = useState(false)
  const addMessage = useAddPopupMessage()

  const saveSvg = (svgData: string, filename: string) => {
    const preface = '<?xml version="1.0" standalone="no"?>\r\n'
    const svgBlob = new Blob([preface, svgData], {
      type: 'image/svg+xml;charset=utf-8',
    })
    const url = URL.createObjectURL(svgBlob)

    const downloadLink = document.createElement('a')
    downloadLink.href = url
    downloadLink.download = filename

    document.body.appendChild(downloadLink)
    downloadLink.click()
    document.body.removeChild(downloadLink)
  }

  const downloadLabel = async (imei: string) => {
    setGeneratingLabel(true)
    try {
      const svgData = renderToString(
        <SensorLabel imei={imei} />
      )
      saveSvg(svgData, `${imei}.svg`)
    } catch (err) {
      addMessage({ type: 'error', text: (err as Error).message })
    } finally {
      setGeneratingLabel(false)
    }
  }

  return {
    generatingLabel,
    downloadLabel,
  }
}

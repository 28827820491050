import React, { FC } from "react"
import {
  LinkProps as RouterLinkProp,
} from "react-router-dom"
import { Link as MuiLink, LinkProps } from "@mui/material"
import { useQueryParamsReset } from "src/hooks/ui"
import { StyledRouterLink } from './styledRouterLink'

export interface InnerLinkProps {
  to: string;
  target?: RouterLinkProp["target"];
}
export const InnerLink: FC<InnerLinkProps & Omit<LinkProps, "color">> = ({
  to,
  target,
  children,
  ...rest
}) => {
  const reset = useQueryParamsReset()
  return (
    <MuiLink
      to={to}
      target={target}
      onClick={() => {
        reset()
      }}
      component={StyledRouterLink}
      {...rest}
    >
      {children}
    </MuiLink>
  )
}

import { FC, useEffect, useState } from 'react'
import { Paper } from '@mui/material'
import {
  DataGridPremium,
  useGridApiRef,
  GridPaginationModel,
} from '@mui/x-data-grid-premium'
import { ProjectSection } from 'src/api'
import { DataLoad } from 'src/components'
import SectionColumns from 'src/components/columns/sections'
import { SearchParamsKeys } from 'src/enums'
import { useSectionListRequest } from 'src/hooks/api/useSectionsList'
import { useQueryParams } from 'src/hooks/ui'
import { defaultRowsValuePerPage } from 'src/config'

const columns = [
  SectionColumns.Name,
  SectionColumns.DateCreated,
  SectionColumns.Status,
  SectionColumns.DateArchived,
  SectionColumns.AmountOfFloorcloudSensors,
  SectionColumns.AmountOfRapidRHSensors,
]

interface ProjectSectionsTableProps {
  data?: ProjectSection[];
  isLoading?: boolean;
  count?: number;
  queryParams: Record<string, string>
  setQueryParams: (params: Record<string, string>, paramsToRemove?: string[]) => void
  autoHeight?: boolean
}

const defaultPerPage = 50
const defaultPage = 1
const defaultRowsPerPageOptions = defaultRowsValuePerPage
const initialQueryParams = {
  page: `${defaultPage}`,
  rowsPerPage: `${defaultPerPage}`,
}

const ProjectSectionsTable: FC<ProjectSectionsTableProps> = ({
  data = [],
  isLoading = false,
  count = 0,
  setQueryParams,
  queryParams,
  autoHeight = false
}) => {
  const apiRef = useGridApiRef()
  const [rowCountState, setRowCountState] = useState(count)

  useEffect(() => {
    setRowCountState((prevRowCountState) =>
      count !== undefined ? count : prevRowCountState
    )
  }, [count, setRowCountState])

  const handleChangePaginationModel = async (pagination: GridPaginationModel): Promise<void> => {
    const { page, pageSize } = pagination
    const newPage = page + 1
    setQueryParams({
      [SearchParamsKeys.Page]: `${newPage}`,
      [SearchParamsKeys.RowsPerPage]: `${pageSize}`,
    }, [
      SearchParamsKeys.Page,
      SearchParamsKeys.RowsPerPage,
    ])
  }

  const page = queryParams["page"] ? parseInt(queryParams["page"], 10) - 1 : defaultPage - 1
  const pageSize = queryParams["rowsPerPage"]
    ? parseInt(queryParams["rowsPerPage"], 10)
    : defaultPerPage



  return (
    <Paper sx={{ flexGrow: 1, minHeight: '200px', maxHeight: '500px', height: '100%' }}>
      <DataGridPremium<ProjectSection>
        apiRef={apiRef}
        rows={data}
        pagination
        autoHeight={autoHeight}
        disableRowSelectionOnClick
        loading={isLoading}
        columns={columns}
        rowCount={rowCountState}
        paginationModel={{
          page: page as number,
          pageSize: pageSize as number
        }}
        rowThreshold={2}
        columnThreshold={2}
        paginationMode="server"
        pageSizeOptions={defaultRowsPerPageOptions}
        onPaginationModelChange={handleChangePaginationModel}
        localeText={{
          columnMenuSortAsc: "Sort A-Z",
          columnMenuSortDesc: "Sort Z-A",
        }}

        sx={{
          width: '100%',
          "& .MuiDataGrid-columnHeaderTitle": {
            whiteSpace: "normal",
            lineHeight: 1,
            fontSize: "13px",
            fontWeight: 400,
            color: "rgba(0, 0, 0, 0.6)",
            overflow: "visible",
          },
        }}
      />
    </Paper>

  )
}

interface ProjectSectionsProps {
  projectId: number;
}

export const ProjectSections: FC<ProjectSectionsProps> = ({ projectId }) => {
  const [queryParams, setQueryParams] = useQueryParams(initialQueryParams)

  const { data, error, isInitialLoading, isError } = useSectionListRequest({
    params: { projectId },
  })
  return (
    <DataLoad
      isLoading={false}
      isError={isError}
      errorMessage={error?.message}
    >
      <ProjectSectionsTable
        isLoading={isInitialLoading}
        data={data?.rows || []}
        count={data?.count || 0}
        setQueryParams={setQueryParams}
        queryParams={queryParams}
      />
    </DataLoad >
  )
}

import React, { FC, ChangeEvent } from 'react'
import { useNavigate } from 'react-router-dom'
import { Button, CircularProgress } from '@mui/material'
import { useAddPopupMessage } from 'src/hooks/ui'
import { useUploadProductRequest } from 'src/hooks/api'
import { XlsxAllowedTypes } from 'src/enums'

const allowedTypes = [XlsxAllowedTypes.Xls, XlsxAllowedTypes.Xlsx]

export const UploadXlsxButton: FC = () => {
  const navigate = useNavigate()
  const addMessage = useAddPopupMessage()
  const { isLoading, mutate } = useUploadProductRequest({
    options: {
      onSuccess: (uploadResult) => {
        navigate('/db-management/upload', { state: { uploadResult } })
      },
      onError: ({ message: text }) => {
        addMessage({ text, type: 'error' })
      },
    },
  })
  const onFileChange = async (event: ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target
    if (!files || files?.length === 0) return
    const [file] = Array.from(files)
    const isTypeAllowed = allowedTypes.some((type) => file.type === type)
    if (isTypeAllowed) mutate(file)
    else addMessage({ text: 'Incorrect file type', type: 'error' })
  }

  return (
    <Button
      component="label"
      endIcon={
        isLoading ? (
          <CircularProgress size={15} sx={{ color: 'white' }} />
        ) : null
      }
      variant="contained"
      disabled={isLoading}
    >
      Import Products
      {!isLoading && <input type="file" hidden onChange={onFileChange} />}
    </Button>
  )
}

import { FC, PropsWithChildren, useCallback, useMemo, useState } from "react"
import { useParams } from "react-router-dom"
import { Button, Grid, Typography, CircularProgress } from "@mui/material"
import { Link } from "react-router-dom"
import format from "date-fns/format"
import {
  HasSidebarLayout,
  DataLoad,
  EntityHeader,
  SensorInfo,
  PropsInjector,
  TabGroupFilter,
  ProjectsCurrentProject,
  SensorChartTabs,
  SensorLastReadings,
  SensorCurrentConfig,
  SensorConfigurationInfo,
  SensorPastProjects,
  SensorHistoricalDataModal,
  SensorFirmwareHistory,
  SensorGpxDataModal,
  SensorStagingLabel,
  StatefulDialog,
  AssignSensorToContractor,
  ManageSensorBleModal,
  SensorBackupUplinks,
  OuterLink,
  SensorArchivedLabel,
  UnarchiveSensorModal,
  ArchiveSensorModal,
} from "src/components"
import { useAssignSensorRequest, useEditSensorConfigurationRequest, useSensorConfiguration, useSensorFirmware, useSensorRequest, useSensorRetrieveFirmwareRequest, useSensorStatesRequest } from "src/hooks/api"
import { useAddPopupMessage, useDialog, useFillMissedUplinks, useQRCode, useSensorEditConfiguration, useSensorLabel, useSensorStateUpdate } from "src/hooks/ui"
import { Sensor, SensorLastSentConfiguration, SensorState } from "src/api"
import { DialogNames, SearchParamsKeys, SensorStateList } from "src/enums"
import { useSensorConfiguring } from "src/hooks/api/useSensorConfiguring"
import { capitalizeFirstLetter } from "src/helpers"
import { BleManageState } from "src/interfaces"
import { SensorEvents } from "src/components/sections/sensor/sensorEvents"

interface SensorShowContentProps {
  sensor?: Sensor;
  refetchSensor?: () => void;
  sensorStates?: SensorState[];
}
interface SensorStatusProps {
  sensor: Sensor
  sensorStates: SensorState[]
  updateState: (payload: {
    uniqueId: string
    state: SensorState
    onSuccess?: () => void
    onError?: () => void
  }) => void
  openDialog: () => void
  refetchSensor?: () => void
}

const SensorStatus: FC<SensorStatusProps> = ({ sensor, sensorStates, updateState, openDialog, refetchSensor }) => {
  const getNextAction: { action: () => void } = useCallback(() => {
    const currentState: string = sensor?.state?.name || ''
    let _nextAction: () => void = () => {}
    switch (currentState) {
    case 'new':
      const nextState = sensorStates.find((state) => state.name === 'available')
      _nextAction = () => updateState({
        uniqueId: sensor.uniqueId as string,
        state: nextState as SensorState,
        onSuccess: refetchSensor,
      })
      break
    case 'available':
    case 'assigned':
      _nextAction = openDialog
      break
    default:
      break
    }
    return {
      action: _nextAction
    }
  }, [sensor, sensorStates, updateState, openDialog, refetchSensor])()

  const updateStateButton = () => {
    const labelMap: Record<string, string> = {
      new: 'Make Ready to use',
      available: 'Assign',
      assigned: 'Reassign'
    }
    const actionLabel: string = labelMap[sensor?.state?.name || '']

    return actionLabel ? `(${actionLabel})` : ''
  }

  return (
    <Typography variant="h6">
      {(capitalizeFirstLetter(sensor.state?.name || ''))} {updateStateButton() ? <Button onClick={getNextAction.action} variant="text">{updateStateButton()}</Button> : null}
    </Typography>
  )
}

const getStatusList = ({ projectId, uniqueId }: { projectId: number | null, uniqueId: string }) => {
  return [
    {
      status: "Current Project",
      content: (
        <ProjectsCurrentProject
          projectId={projectId}
          noResultText="Current sensor no belongs to any project"
          showFooter={false}
        />
      ),
    },
    {
      status: "Past Projects",
      content: (
        <SensorPastProjects
          sensorUniqueId={uniqueId}
          noResultText="Current sensor does not have past projects"
          showFooter={false}
        />
      ),
    },
  ]
}

const SensorShowContent: FC<SensorShowContentProps> = ({ sensor = null, refetchSensor, sensorStates }) => {
  const [sensorBleState, setSensorBleState] = useState<BleManageState>(BleManageState.OFF)
  const { loading: loadingQRCode, generateCode } = useQRCode()
  const { generatingLabel, downloadLabel } = useSensorLabel()
  const addPopupMessage = useAddPopupMessage()
  const { makeSensorEditConfiguration, makeNullableEmptyValues } = useSensorEditConfiguration()
  const { openDialog: openAssignSensorDialog, closeDialog: closeAssignSensorDialog } = useDialog(
    DialogNames.AssignSensorToContractor
  )
  const { openDialog: openSensorBleDialog, closeDialog: closeSensorBleDialog } = useDialog(
    DialogNames.ManageSensorBle
  )
  const editSensorConfigurationRequest = useEditSensorConfigurationRequest({
    uniqueId: sensor?.uniqueId || '',
    options: {
      onSuccess: () => {
        addPopupMessage({ text: "The sensor BLE configuration was successfully sent", type: "success" })
        closeSensorBleDialog()
      },
      onError: ({ message: text }) => {
        addPopupMessage({ text, type: "error" })
      },
    },
  })
  const assignSensorRequest = useAssignSensorRequest({
    options: {
      onSettled: () => closeAssignSensorDialog(),
      onSuccess: refetchSensor,
    },
  })
  const { fillMissedUplinks, isLoading: isLoadingFillMissedUplinksRequest } = useFillMissedUplinks()
  const {
    isInitialLoading: isLoadingFirmwareVersion,
    refetch: refetchFirmwareVersion,
  } = useSensorFirmware({
    uniqueId: sensor?.uniqueId || '',
    options: {
      enabled: false,
      retry: false,
    },
  })
  const {
    isInitialLoading: isLoadingSensorConfiguration,
    refetch: refetchSensorConfiguration,
  } = useSensorConfiguration({
    uniqueId: sensor?.uniqueId || '',
    options: {
      enabled: false,
      retry: false,
    },
  })
  const sensorConfiguringRequest = useSensorConfiguring()
  const sensorRetrieveFirmwareRequest = useSensorRetrieveFirmwareRequest()
  const {
    openSensorUnarchivingDialog,
    closeSensorUnarchivingDialog,
    openSensorArchivingDialog,
    closeSensorArchivingDialog,
    updateSensorState,
    isLoadingChangingSensorState,
  } = useSensorStateUpdate()
  const archivedState = useMemo(() => (sensorStates || []).find(
    (state) => state.name === SensorStateList.Archived
  ), [sensorStates])
  const newState = useMemo(() => (sensorStates || []).find(
    (state) => state.name === SensorStateList.New
  ), [sensorStates])
  const isArchived = useMemo(() => (row: Sensor) => row && row.stateId === archivedState?.id, [archivedState])

  if (!sensor) return null

  const { uniqueId, deviceId, sensorModel, projectSectionsSensors } = sensor
  const projectId =
    projectSectionsSensors && projectSectionsSensors.length
      ? projectSectionsSensors[0].projectId
      : null

  const getSensorFirmware = async () => {
    const { isSuccess, isError, error } = await refetchFirmwareVersion()
    if (isSuccess) {
      addPopupMessage({ text: "The sensor firmware version was successfully received", type: "success" })
      if (typeof refetchSensor === 'function') refetchSensor()
    } else if (isError) {
      addPopupMessage({ text: error?.message, type: 'error' })
    }
  }

  const configureSensor = async (sensorUniqueId: string) => {
    sensorConfiguringRequest.mutate(
      sensorUniqueId,
      {
        onSuccess: (data) => {
          addPopupMessage({ text: "The sensor was succesfully configured", type: "success" })
        },
        onError: ({ message: text }) => {
          addPopupMessage({ text, type: "error" })
        },
      }
    )
  }

  const retrieveSensorFirmware = () => {
    sensorRetrieveFirmwareRequest.mutate(
      uniqueId,
      {
        onSuccess: (data) => {
          addPopupMessage({ text: "The sensor firmware version requested", type: "success" })
        },
        onError: ({ message: text }) => {
          addPopupMessage({ text, type: "error" })
        },
      }
    )
  }

  const getSensorConfiguration = async () => {
    const { isSuccess, isError, error } = await refetchSensorConfiguration()
    if (isSuccess) {
      addPopupMessage({ text: "The sensor configuration was successfully received", type: "success" })
      if (typeof refetchSensor === 'function') refetchSensor()
    } else if (isError) {
      addPopupMessage({ text: error?.message, type: "error" })
    }
  }

  const onFillMissedUplinks = (date: string) => {
    fillMissedUplinks(date, sensor.uniqueId)
  }

  const assignSensor = (contractorId: number | null) => {
    assignSensorRequest.mutate({
      uniqueId: sensor.uniqueId,
      payload: { contractorId },
    })
  }

  const enableBle = () => {
    if (!sensor.lastSentConfig) {
      addPopupMessage({ text: "The sensor configuration is not available", type: "error" })
      return
    }

    setSensorBleState(BleManageState.ON)
    openSensorBleDialog()
  }

  const disableBle = () => {
    if (!sensor.lastSentConfig) {
      addPopupMessage({ text: "The sensor configuration is not available", type: "error" })
      return
    }

    setSensorBleState(BleManageState.OFF)
    openSensorBleDialog()
  }

  const manageSensorBle = (state: BleManageState) => {
    const sensorConfiguration = makeSensorEditConfiguration(sensor.lastSentConfig as SensorLastSentConfiguration)
    const data = makeNullableEmptyValues(sensorConfiguration)
    if (data.ble !== undefined) {
      data.ble.opMode = state === BleManageState.ON ? 1 : 0
      data.ble.reportType = state === BleManageState.ON ? 2 : 0
    }
    delete data.firmware
    editSensorConfigurationRequest.mutate(data)
  }

  const getRightIcon = (): React.ReactNode => {
    const nodes: React.ReactNode[] = []
    if (sensor.isStagingSensor) {
      nodes.push(<SensorStagingLabel size="medium" key="staging-label" sx={{ mr: 1 }} />)
    }
    if (sensor.state?.name === SensorStateList.Archived) {
      nodes.push(<SensorArchivedLabel size="medium" key="archived-lable" />)
    }
    return nodes
  }

  const title = `${sensorModel?.displayName || "Sensor"}: ${uniqueId}`
  return (
    <>
      <Grid container direction="column" spacing={4}>
        <Grid item>
          <EntityHeader
            title={title}
            rightIcon={getRightIcon()}
            actionBlockOnTheRight={
              <SensorStatus
                sensor={sensor}
                sensorStates={sensorStates || []}
                updateState={updateSensorState}
                openDialog={openAssignSensorDialog}
                refetchSensor={refetchSensor}
              />
            }
          >
            <Grid container spacing={2}>
              <Grid item>
                <Button
                  variant="outlined"
                  size="large"
                  onClick={() => configureSensor(uniqueId)}
                  sx={{ width: 210 }}
                  disabled={sensorConfiguringRequest.isLoading}
                >
                  {sensorConfiguringRequest.isLoading ? <CircularProgress size={26} /> : "Configure sensor"}
                </Button>
              </Grid>
              <Grid item>
                <Link to={`/sensors/configuration/edit/${uniqueId}`} style={{ textDecoration: "none" }}>
                  <Button
                    role="link"
                    variant="outlined"
                    size="large"
                    sx={{ width: 210 }}
                  >
                    Edit configuration
                  </Button>
                </Link>
              </Grid>
              <Grid item>
                <Button
                  variant="outlined"
                  size="large"
                  onClick={retrieveSensorFirmware}
                  sx={{ width: 260 }}
                  disabled={sensorRetrieveFirmwareRequest.isLoading}
                >
                  {sensorRetrieveFirmwareRequest.isLoading ? <CircularProgress size={26} /> : "Ask Sensor to send its Firmware Version"}
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="outlined"
                  size="large"
                  onClick={getSensorFirmware}
                  sx={{ width: 260 }}
                  disabled={isLoadingFirmwareVersion}
                >
                  {isLoadingFirmwareVersion ? <CircularProgress size={26} /> : "Get firmware version from ThingSpace"}
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="outlined"
                  size="large"
                  onClick={() => generateCode(deviceId || uniqueId)}
                  sx={{ width: 210 }}
                  disabled={loadingQRCode}
                >
                  {loadingQRCode ? <CircularProgress size={26} /> : "Generate QR code"}
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="outlined"
                  size="large"
                  onClick={() => downloadLabel(deviceId as string)}
                  sx={{ width: 210 }}
                  disabled={generatingLabel}
                >
                  {generatingLabel ? <CircularProgress size={26} /> : "Download label"}
                </Button>
              </Grid>
              <Grid item>
                <SensorHistoricalDataModal
                  onFillMissedUplinks={onFillMissedUplinks}
                  isLoading={isLoadingFillMissedUplinksRequest}
                />
              </Grid>
              <Grid item>
                <SensorGpxDataModal
                  sensorUniqueId={uniqueId}
                />
              </Grid>
              <Grid item>
                <Button
                  variant="outlined"
                  size="large"
                  onClick={enableBle}
                  sx={{ width: 210 }}
                >
                  Turn On BLE
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="outlined"
                  size="large"
                  onClick={disableBle}
                  sx={{ width: 210 }}
                >
                  Turn Off BLE
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="outlined"
                  size="large"
                  onClick={isArchived(sensor) ? openSensorUnarchivingDialog : openSensorArchivingDialog}
                  sx={{ width: 210 }}
                >
                  {isArchived(sensor) ? "Unarchive" : "Archive"}
                </Button>
              </Grid>
            </Grid>
          </EntityHeader>
        </Grid>
        <Grid container item flexDirection="column">
          <Grid container item alignItems="center" spacing={2} mb={1}>
            <Grid item>
              <Typography variant="h6">
                Sensor Information
              </Typography>
            </Grid>
            <Grid item>
              <Button variant="text">
                <OuterLink to={`/sensors/${uniqueId}/extended-diagnostics`}>
                  Get Extended Info
                </OuterLink>
              </Button>
            </Grid>
          </Grid>
          <Grid item>
            <SensorInfo sensor={sensor} />
          </Grid>
        </Grid>
        <Grid item>
          <TabGroupFilter
            name={SearchParamsKeys.Tab}
            statusList={getStatusList({ projectId, uniqueId })}
            paramsToRemove={[
              SearchParamsKeys.Page,
              SearchParamsKeys.RowsPerPage,
            ]}
          />
        </Grid>
        <Grid item>
          <Typography variant="h6" sx={{ mb: 1 }}>
            Sensor Chart Of Readings
          </Typography>
          <SensorChartTabs sensor={sensor} />
        </Grid>
        <Grid item>
          <Typography variant="h6" sx={{ mb: 1 }}>
            Sensor Last Readings
          </Typography>
          <SensorLastReadings sensor={sensor} />
        </Grid>
        <Grid item>
          <SensorEvents sensorId={sensor.id} />
        </Grid>
        <Grid item>
          <SensorBackupUplinks sensorUniqueId={sensor.uniqueId} />
        </Grid>
        <Grid item>
          <Typography variant="h6" sx={{ mb: 1 }}>
            Sensor Current Configuration (updated automatically)
          </Typography>
          <SensorCurrentConfig sensor={sensor} />
        </Grid>
        <Grid item>
          <Grid item container alignItems="center">
            <Typography variant="h6" sx={{ mr: 4 }}>
              Sensor Configuration Info
              {sensor.currentConfig?.time ? ` (last requested on ${format(new Date(sensor.currentConfig?.time), "PPpp")})` : '-'}
            </Typography>
            <Button
              variant="outlined"
              size="large"
              onClick={getSensorConfiguration}
              disabled={isLoadingSensorConfiguration}
              sx={{ width: 260 }}
            >
              {
                isLoadingSensorConfiguration ? <CircularProgress size={26} /> : 'Request info update'
              }
            </Button>
          </Grid>
          <SensorConfigurationInfo sensor={sensor} />
        </Grid>
        <Grid item>
          <Typography variant="h6" sx={{ mb: 1 }}>
            Sensor Firmware History
          </Typography>
          <SensorFirmwareHistory history={sensor.firmwareHistory || []} />
        </Grid>
      </Grid>
      <StatefulDialog
        name={DialogNames.AssignSensorToContractor}
        wrapContent={false}
      >
        <AssignSensorToContractor onSubmit={assignSensor} />
      </StatefulDialog>
      <ManageSensorBleModal
        onSubmit={manageSensorBle}
        onClose={closeSensorBleDialog}
        bleState={sensorBleState}
        isLoading={editSensorConfigurationRequest.isLoading}
        deviceId={sensor.deviceId || ''}
      />
      <UnarchiveSensorModal
        sensorDeviceId={sensor.deviceId as string}
        isLoading={isLoadingChangingSensorState}
        onCancel={() => closeSensorUnarchivingDialog()}
        onUnarchive={() => {
          updateSensorState({
            uniqueId: sensor.uniqueId as string,
            state: newState as SensorState,
            onSuccess: refetchSensor,
          })
        }}
      />
      <ArchiveSensorModal
        sensorDeviceId={sensor.deviceId as string}
        isLoading={isLoadingChangingSensorState}
        onCancel={() => closeSensorArchivingDialog()}
        onArchive={() => {
          updateSensorState({
            uniqueId: sensor.uniqueId as string,
            state: archivedState as SensorState,
            onSuccess: refetchSensor,
          })
        }}
      />
    </>
  )
}

const SensorDataLoader: FC<PropsWithChildren<{ sensorUniqueId: string }>> = ({
  sensorUniqueId,
  children,
}) => {
  const { isInitialLoading, isError, data, error, refetch } = useSensorRequest({
    uniqueId: sensorUniqueId,
    options: {
      notifyOnChangeProps: ["isLoading", "isError", "isRefetching"],
    },
  })
  document.title = `Floorcloud Admin Panel - Sensor ${data?.deviceId || ''}`
  const { data: sensorStateData } = useSensorStatesRequest()

  const props = {
    sensor: data || null,
    refetchSensor: refetch,
    sensorStates: sensorStateData || []
  }

  return (
    <DataLoad
      isLoading={isInitialLoading}
      isError={isError}
      errorMessage={error?.message}
    >
      <PropsInjector props={props}>{children}</PropsInjector>
    </DataLoad>
  )
}

const SensorShow: FC = () => {
  const params = useParams()
  const { uniqueId } = params as { uniqueId: string }

  return (
    <HasSidebarLayout>
      <SensorDataLoader sensorUniqueId={uniqueId}>
        <SensorShowContent />
      </SensorDataLoader>
    </HasSidebarLayout>
  )
}

export default SensorShow

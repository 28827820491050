import React, { FC } from "react"
import { List, ListItem, ListItemText, Typography, Box } from "@mui/material"
import { CustomSingleEvent } from "src/api"

interface ProjectUpdatedInfoProps {
  event: CustomSingleEvent
}

export const ProjectUpdatedInfo: FC<ProjectUpdatedInfoProps> = ({ event }) => {
  const { difference } = event
  if (!difference) return null

  return (
    <Box>
      <Typography variant="body2">Difference</Typography>
      <ListItem sx={{ pl: 2 }}>
        <List>
          {difference.map(({ key, name, nextValue, prevValue }) => (
            <ListItem key={key} divider>
              <ListItemText
                primary={<Typography variant="body2">{name}</Typography>}
                secondary={<Box component="span">
                  <Typography variant="body2" component="span" fontWeight={500}>Previous value: </Typography>
                  <Typography variant="body2" component="span">{prevValue || '-'}</Typography>
                  <br />
                  <Typography variant="body2" component="span" fontWeight={500}>Next value: </Typography>
                  <Typography variant="body2" component="span">{nextValue || '-'}</Typography>
                </Box>}
              />
            </ListItem>
          ))}
        </List>
      </ListItem>
    </Box>
  )
}

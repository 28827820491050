import React, { FC } from "react"
import { Grid } from "@mui/material"
import {
  UnstableSensorsChart,
  UnstableSensorsTable,
} from "src/components"

export const UnstableSensorsContent: FC = () => {
  return (
    <Grid container direction="column" spacing={4}>
      <Grid item>
        <UnstableSensorsChart />
      </Grid>
      <Grid item>
        <UnstableSensorsTable />
      </Grid>
    </Grid>
  )
}

import React, { FC, PropsWithChildren } from 'react'
import { TableCell } from '@mui/material'

export interface TextCellProps {
  value: string;
  last: boolean;
  Link?: FC<PropsWithChildren>;
  empty?: string;
}

export const TextCell: FC<TextCellProps> = ({
  value,
  last,
  Link,
  empty = '-',
}) => {
  const align = last ? 'right' : 'left'
  return (
    <TableCell scope="row" align={align}>
      {value ? Link ? <Link>{value}</Link> : value : empty}
    </TableCell>
  )
}

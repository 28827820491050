import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { Paper } from '@mui/material'
import {
  DataGridPremium,
  GridColDef,
  useGridApiRef,
  gridFilterModelSelector,
  gridFilteredTopLevelRowCountSelector,
  GridPaginationModel,
  GridPinnedColumns,
  GRID_CHECKBOX_SELECTION_COL_DEF,
} from '@mui/x-data-grid-premium'
import { TableToolbar } from 'src/components/ui'
import { Document } from 'src/api'
import { SearchParamsKeys } from 'src/enums'
import { defaultRowsValuePerPage } from 'src/config'
import { DocumentColumns } from './documentColumns'

const defaultRowsPerPageOptions = defaultRowsValuePerPage

interface DocumentListProps {
  documents: Document[]
  documentCount: number
  isLoadingDocuments: boolean
  queryParams: Record<string, string>
  setQueryParams: (params: Record<string, string>, paramsToRemove?: string[]) => void
  autoHeight?: boolean
  height?: string
  documentColumns?: GridColDef<Document>[]
  exportedFileName?: string
}

export const DocumentList: FC<DocumentListProps> = ({
  documentCount,
  documents,
  isLoadingDocuments,
  setQueryParams,
  queryParams,
  autoHeight = false,
  height = '100%',
  documentColumns,
  exportedFileName,
}) => {
  const apiRef = useGridApiRef()
  const defaultColumns = useMemo((): GridColDef<Document>[] => ([
    DocumentColumns.Name,
    DocumentColumns.Description,
    DocumentColumns.Type,
    DocumentColumns.Links,
    DocumentColumns.Contractor,
    DocumentColumns.Project,
    DocumentColumns.Author,
    DocumentColumns.CreatedAt,
  ]), [])
  const columns = documentColumns?.length ? documentColumns : defaultColumns
  const [rowCountState, setRowCountState] = useState(documentCount)
  const [pinnedColumns, setPinnedColumns] = useState<GridPinnedColumns>({
    left: [GRID_CHECKBOX_SELECTION_COL_DEF.field, ""],
  })

  useEffect(() => {
    setRowCountState((prevRowCountState: number) =>
      documentCount !== undefined ? documentCount : prevRowCountState
    )
  }, [documentCount, setRowCountState])

  const handlePinnedColumnsChange = useCallback(
    (updatedPinnedColumns: GridPinnedColumns) => {
      setPinnedColumns(updatedPinnedColumns)
    },
    []
  )

  const handleChangePaginationModel = async (pagination: GridPaginationModel): Promise<void> => {
    const { page, pageSize } = pagination
    const newPage = page + 1
    if (setQueryParams) {
      setQueryParams({
        [SearchParamsKeys.Page]: `${newPage}`,
        [SearchParamsKeys.RowsPerPage]: `${pageSize}`,
      }, [
        SearchParamsKeys.Page,
        SearchParamsKeys.RowsPerPage,
      ])
    }
  }

  const handleChangeState = async () => {
    const filterModel = gridFilterModelSelector(apiRef.current.state)
    if (filterModel.items.length > 0 && filterModel.items[0].value) {
      const visibleRowCount = gridFilteredTopLevelRowCountSelector(apiRef.current.state)
      if (visibleRowCount) setRowCountState(visibleRowCount)
    } else {
      setRowCountState(documentCount)
    }
  }

  const page = queryParams["page"] ? parseInt(queryParams["page"], 10) - 1 : undefined
  const pageSize = queryParams["rowsPerPage"]
    ? parseInt(queryParams["rowsPerPage"], 10)
    : undefined

  return (
    <Paper sx={{ flexGrow: 1, minHeight: '200px', height, width: "100%" }}>
      <DataGridPremium<Document>
        apiRef={apiRef}
        pagination
        autoHeight={autoHeight}
        pinnedColumns={pinnedColumns}
        disableRowSelectionOnClick
        rows={documents}
        columns={columns}
        rowCount={rowCountState}
        loading={isLoadingDocuments}
        paginationModel={{
          page: page as number,
          pageSize: pageSize as number
        }}
        getRowHeight={() => 'auto'}
        rowThreshold={2}
        columnThreshold={2}
        paginationMode="server"
        pageSizeOptions={defaultRowsPerPageOptions}
        onPaginationModelChange={handleChangePaginationModel}
        onStateChange={handleChangeState}
        localeText={{
          columnMenuSortAsc: "Sort A-Z",
          columnMenuSortDesc: "Sort Z-A",
        }}
        slots={{
          toolbar: () => <TableToolbar exportedFileName={exportedFileName} />
        }}
        onPinnedColumnsChange={handlePinnedColumnsChange}
        sx={(theme) => ({
          border: "none",
          "& .MuiDataGrid-columnHeaderTitleContainerContent": {
            paddingLeft: "5px",
            overflow: "visible",
          },
          "& .MuiDataGrid-columnHeaderTitle": {
            whiteSpace: "normal",
            lineHeight: 1,
            fontSize: "13px",
            fontWeight: 400,
            color: "rgba(0, 0, 0, 0.6)",
            overflow: "visible",
          },
        })}
      />
    </Paper>
  )
}

import React, { FC, PropsWithChildren } from 'react'
import { useDialog } from 'src/hooks/ui'
import { DialogNames } from 'src/enums'
import { Dialog, DialogProps } from './dialog'

interface StatefulDialogProps extends Omit<DialogProps, 'open' | 'onClose'> {
  name: DialogNames | string;
  scroll?: 'body' | 'paper';
  onClose?: () => void
}

export const StatefulDialog: FC<PropsWithChildren<StatefulDialogProps>> = ({
  name,
  onClose,
  children,
  ...rest
}) => {
  const { open, closeDialog } = useDialog(name)

  return (
    <Dialog {...rest} open={open} onClose={() => closeDialog(onClose)}>
      {children}
    </Dialog>
  )
}

import React, { FC } from 'react'
import { Grid } from '@mui/material'
import { useFormik, FormikProvider, Form } from 'formik'
import { FormCheckbox } from 'src/components/ui'
import { useQueryParams } from 'src/hooks/ui'
import { SearchParamsKeys } from 'src/enums'

export const ManufacturerProjectFilters: FC = () => {
  const [queryParams, setQueryParams] = useQueryParams()

  const formik = useFormik({
    initialValues: {
      [SearchParamsKeys.InUse]: queryParams[SearchParamsKeys.InUse] === 'true',
    },
    onSubmit: async (formState) => {
      const inUse = formState[SearchParamsKeys.InUse] || false
      await setQueryParams(
        {
          ...(inUse ? { inUse: `${inUse}` } : {}),
        },
        [SearchParamsKeys.InUse]
      )
    },
  })

  const { submitForm } = formik

  return (
    <FormikProvider value={formik}>
      <Form>
        <Grid container alignItems="center" spacing={4}>
          <Grid item>
            <FormCheckbox
              label="In Use"
              name={SearchParamsKeys.InUse}
              onChange={submitForm}
            />
          </Grid>
        </Grid>
      </Form>
    </FormikProvider>
  )
}

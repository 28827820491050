import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { Entities } from 'src/enums'
import { useApiInstance } from './useApiInstance'

interface RequestParams {
  options?: Omit<
    UseQueryOptions<string[], Error>,
    'queryKey' | 'queryFn'
  >;
}

export const useStagingDevicesRequest = ({
  options,
}: RequestParams = {}) => {
  const api = useApiInstance()

  return useQuery<string[], Error>(
    [Entities.Configuration, 'get-staging-devices'],
    async () => {
      const { data } = await api.v1AdminConfigurationSensorAllocationGet()
      return data as string[]
    },
    options,
  )
}

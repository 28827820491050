import { FC } from 'react'
import { UserProjectNotificationColumns } from 'src/components/columns/user'
import { UserNotificationResponseItemByProject } from 'src/api'
import { useAddPopupMessage } from 'src/hooks/ui'
import { useSetupUserProjectNotificationsRequest, useUserProjectNotificationsRequest } from 'src/hooks/api'
import { Table } from '../../ui'

type Props = {
  userId: number
}

const columns = [
  UserProjectNotificationColumns.Project,
  UserProjectNotificationColumns.Active
]

export interface EnrichedUserProjectNotification extends UserNotificationResponseItemByProject {
  toggle: () => void
}

export const UserProjectNotificationList: FC<Props> = ({ userId }) => {
  const addMessage = useAddPopupMessage()
  const projectNotificationRequest = useUserProjectNotificationsRequest({ userId })
  const notifications: UserNotificationResponseItemByProject[] = projectNotificationRequest.data || []
  const setupProjectNotificationRequest = useSetupUserProjectNotificationsRequest({
    userId,
    options: {
      onSuccess: () => projectNotificationRequest.refetch(),
      onError: ({ message: text }) => {
        addMessage({ text, type: 'error' })
      },
    }
  })
  const toggleNotification = (notificationValue: {
    projectId: number
    value: boolean
  }) => {
    setupProjectNotificationRequest.mutate({
      projects: [notificationValue]
    })
  }
  const enrichedNotifications: EnrichedUserProjectNotification[] = notifications?.map<EnrichedUserProjectNotification>((notification) => ({
    ...notification,
    toggle: () => {
      toggleNotification({
        projectId: notification.project.id,
        value: !notification.value
      })
      notification.value = !notification.value
    }
  })) || []
  return (
    <Table
      loading={false}
      data={enrichedNotifications || []}
      columns={columns}
      showFooter={false}
    />
  )
}

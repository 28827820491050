import React, { FC, PropsWithChildren } from "react"
import { useParams } from "react-router-dom"
import { Button, Grid, Typography } from "@mui/material"
import {
  HasSidebarLayout,
  DataLoad,
  EntityHeader,
  SectionInfo,
  PropsInjector,
  SectionSensorInfo,
  SectionRapidRHInfo,
  OuterLink,
} from "src/components"
import { useSectionRequest } from "src/hooks/api"
import { ProjectSection } from "src/api"
import { config } from "src/config"

interface SectionShowContentProps {
  section?: ProjectSection;
}

const SectionShowContent: FC<SectionShowContentProps> = ({
  section = null,
}) => {
  if (!section) return null

  const { name, projectId, id } = section
  const link = `${config.webAppHost}/#/projects/${projectId}/sections/${id}`

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <EntityHeader
          title={name}
          actionBlockOnTheRight={<OuterLink
            to={link}
            style={{ textDecoration: "none" }}
          >
            <Button variant="contained">Open in web app</Button>
          </OuterLink>}
        />
      </Grid>
      <Grid item container spacing={4} direction="column">
        <Grid item container spacing={2} direction="column">
          <Grid item>
            <Typography variant="h6" sx={{ mb: 1 }}>
              Section Information
            </Typography>
          </Grid>
          <Grid item>
            <SectionInfo section={section} />
          </Grid>
        </Grid>
        <Grid item container spacing={2} direction="column">
          <Grid item>
            <Typography variant="h6" sx={{ mb: 1 }}>
              Floorcloud Sensors
            </Typography>
          </Grid>
          <Grid item>
            <SectionSensorInfo projectSectionsSenors={section.projectSectionsSensors || []} />
          </Grid>
        </Grid>
        <Grid item container spacing={2} direction="column">
          <Grid item>
            <Typography variant="h6" sx={{ mb: 1 }}>
              RapidRH Sensors
            </Typography>
          </Grid>
          <Grid item>
            <SectionRapidRHInfo rapidRHProbes={section.wagnerProbes || []} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

const SectionDataLoader: FC<PropsWithChildren<{ sectionId: number }>> = ({
  sectionId,
  children,
}) => {
  const { isInitialLoading, isError, data } = useSectionRequest({
    id: sectionId,
  })
  document.title = `Floorcloud Admin Panel - Project section ${data?.name || ''}`

  const props = {
    section: data || null,
  }

  return (
    <DataLoad isLoading={isInitialLoading} isError={isError}>
      <PropsInjector props={props}>{children}</PropsInjector>
    </DataLoad>
  )
}

const SectionShow: FC = () => {
  const params = useParams()
  const { id } = params as { id: string }

  return (
    <HasSidebarLayout>
      <SectionDataLoader sectionId={parseInt(id, 10)}>
        <SectionShowContent />
      </SectionDataLoader>
    </HasSidebarLayout>
  )
}

export default SectionShow
